class ConferencePlan {
  constructor(
    public sdUid: number,
    public syUid: number,
    public stUid: number,
    public cateCd: number,
    public dbStatCd: string,
    public preDbStatCd: string,
    public serviceTargetYear: string,
    public serviceTarget: string,
    public spyUid: number,
    public siUid: number,
    public dptUid: number,
    public stepCd: "돌봄계획",
    public serviceStatCd: "의뢰",
    public beginDate: string,
    public endDate: string,
    public times: number,
    public cost: number,
    public serviceDetail: string,
    public chngCnt: null,
    public chngRsn: null,
    public stopYn: "N",
    public stopRsn: null,
    public providerOpinion: null,
    public weekday: string,
    public cycleUnit: null,
    public totalCount: number,
    public dcaseMeetYn: null,
    public sprtMoreYn: null,
    public mbrUid: null,
    public serviceDecision: null,
    public serviceInfo: null,
    public resultEvalList: null,
    public servceBillList: null,
    public servicePlanItemList: null,
    public serviceOrgList: null,
    public serviceNm : string,
    public dbGubunCd : string
  ) {}
}
export default ConferencePlan;
