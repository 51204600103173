import { display } from "@mui/system";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import DollbomPlanAPI from "../../apis/DollbomPlanAPI";
import CloseIcon from "../../assets/ic_close.png";
import useMember from "../../hooks/useMember";
import ServiceTargetYear, {
  ServiceTargetYearCharacter,
} from "../../models/ServiceTargetYear";
import FileUtil from "../../utils/FileUtil";
import { DialogProps, useDialog } from "../DialogProvider";
import "./DollbomTargetInfoDialog.scss";
import { ValidCheck, nameValid, telValid } from "../../App";
import PostCodeDialog from "../PostCodeDialog";

/**
 * 작성자: 김덕현
 * 수정일: 2023.04.04
 *
 * 돌봄계획 페이지 대상자 수정 팝업
 */
interface DollbomPlanInfoDialogProps extends DialogProps {
  targetInfo?: ServiceTargetYear;
  syUid?: number;
  siUid?: number;
}

interface DollbomPlanFormInput {
  syUid: number;
  emgcNm: string; // 비상연락 성명
  emgcRel: string; // 비상연락 관계
  emgcTel1: string; // 비상연락처1
  emgcTel2: string; // 비상연락처2
  emgcTel3: string; // 비상연락처3
  trgterCd: string;
  trgterNm: string; // 대상자 이름
  brthdy: string; // 대상자 생년월일
  gender: string; // 성별
  telno1: string; // 전화번호1
  telno2: string; // 전화번호2
  telno3: string; // 전화번호3

  adres: string; // 주소
  adresDtl: string; // 상세주소

  homeMember: number;

  lat: number; // 위도
  lng: number; // 경도
  // 인구학적 특성
  characterJson: {
    demographicCharacteristics: {
      type: string; //특성 변경됨
      young: boolean;
      teen: boolean;
      single: boolean;
      singleType: string;
      old: boolean;
      oldType: string;
      disabled: boolean;
      disabledType: string;
      disabledTypeInput: string;
      disabledAdd: boolean;
      disabledAddType: string;
      disabledTypeAddInput: string;
    },
    usingServices?: {
      // 이용중인 서비스
      babysitting: boolean;
      longTermCareInsuranceForElderly: boolean;
      customizedElderlyCare: boolean;
      supportForDisabledActivities: boolean;
      others: boolean;
      othersInput: string;
    },
    needs?: {
      // 대상자요구 서비스
      integrated: boolean;
      integratedReason: string;
      gwangjuCare: boolean;
      emergencyCare: boolean;
      integratedType: string[];
      gwangjuCareType: string[];
    }
    // 어려움 상황 상세내용
    distress: string;
  }
  incomeLevel: string;
  incomeLevel2?: string;
}

const DollbomPlanInfoDialog = ({
  syUid,
  targetInfo,
  ...props
}: DollbomPlanInfoDialogProps) => {
  const navigate = useNavigate();
  const [income, setIncome] = useState("");
  const [lifeCycle, setLifeCycle] = useState("");
  const { member } = useMember();
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, watch, setValue, getValues, formState:{errors}, setError } =
    useForm<DollbomPlanFormInput>({
      defaultValues: {
        syUid: Number(syUid),
        trgterNm: targetInfo?.trgterNm,
        brthdy: moment(targetInfo?.brthdy).format("yyyy-MM-DD"),
        gender: targetInfo?.gender,
        telno1: targetInfo?.telno?.split("-")[0],
        telno2: targetInfo?.telno?.split("-")[1],
        telno3: targetInfo?.telno?.split("-")[2],
        adres: targetInfo?.adres,
        adresDtl: targetInfo?.adresDtl,
        homeMember: targetInfo?.homeMember,
        emgcNm: targetInfo?.emgcNm,
        emgcTel1: targetInfo?.emgcTel?.split("-")[0],
        emgcTel2: targetInfo?.emgcTel?.split("-")[1],
        emgcTel3: targetInfo?.emgcTel?.split("-")[2],
        emgcRel: targetInfo?.emgcRel,
        characterJson: targetInfo?.character,
        // young: targetInfo?.character.demographicCharacteristics.young,
        // teen: targetInfo?.character.demographicCharacteristics.teen,
        // single: targetInfo?.character.demographicCharacteristics.single,
        // singleType: targetInfo?.character.demographicCharacteristics.singleType,
        // old: targetInfo?.character.demographicCharacteristics.old,
        // oldType: targetInfo?.character.demographicCharacteristics.oldType,
        // disabled: targetInfo?.character.demographicCharacteristics.disabled,
        // disabledType:
        //   targetInfo?.character.demographicCharacteristics.disabledType,
        // disabledTypeInput:
        //   targetInfo?.character.demographicCharacteristics.disabledTypeInput,
        // disabledAdd: targetInfo?.character.demographicCharacteristics.disabledAdd,
        // disabledAddType: targetInfo?.character.demographicCharacteristics.disabledAddType,
        // disabledTypeAddInput: targetInfo?.character.demographicCharacteristics.disabledTypeAddInput,
        incomeLevel: targetInfo?.incomeLevel,
        incomeLevel2: targetInfo?.incomeLevel2,
        // 어려움 상황 상세내용
        // distress: targetInfo?.character.distress,
        // 이용중인 서비스
        // babysitting: targetInfo?.character.usingServices?.babysitting,
        // longTermCareInsuranceForElderly:
        //   targetInfo?.character.usingServices?.longTermCareInsuranceForElderly,
        // customizedElderlyCare:
        //   targetInfo?.character.usingServices?.customizedElderlyCare,
        // supportForDisabledActivities:
        //   targetInfo?.character.usingServices?.supportForDisabledActivities,
        // others: targetInfo?.character.usingServices?.others,
        // othersInput: targetInfo?.character.usingServices?.othersInput,
        // 대상자요구 서비스
        // integrated: targetInfo?.character.needs?.integrated,
        // integratedReason: targetInfo?.character.needs?.integratedReason,
        // gwangjuCare: targetInfo?.character.needs?.gwangjuCare,
        // emergencyCare: targetInfo?.character.needs?.emergencyCare,
        // integratedType: targetInfo?.character.needs?.integratedType,
        // gwangjuCareType: targetInfo?.character.needs?.gwangjuCareType,
      },
    });
  // useEffect(() => {
  //   console.log("ddasdasd!!", targetInfo);
  // }, []);
  const data = watch();
  const demoType = watch("characterJson.demographicCharacteristics.type")
  useEffect(() => {
    if(demoType && demoType === "장애인") {
      setValue("characterJson.demographicCharacteristics.disabledAdd", false)
      setValue("characterJson.demographicCharacteristics.disabledTypeAddInput", "")
    }
    if(demoType && demoType !== "장애인") {
      setValue("characterJson.demographicCharacteristics.disabledTypeInput", "")
    }
  }, [demoType])

  // useEffect(() => {
  //   console.log("aaaaaa!!!!!", targetInfo);
  //   console.log("json !!!!", targetInfo?.characterString);
  //   console.log("json !!!!", targetInfo?.character);
  // }, []);
  const [closeDialog, setCloseDialog] = useState(1);

  useEffect(() => {
    if (closeDialog == 1) {
      document.body.style.overflow = "hidden";
      console.log("a");
    } else {
      document.body.style.overflow = "unset";
      console.log("b");
    }
  }, [closeDialog]);

  const [inputCheck, setInputCheck] = useState<ValidCheck>({stat: true, msg: ""})
  const paramCheck = () => {
    // console.log("대상체크 : ", nameValid.test(getValues("trgterNm")))
    if(!nameValid.test(getValues("trgterNm"))) {
      return {stat: false, msg:"올바른 대상 이름을 입력하세요."}
    } else if(!telValid.test(getValues("telno2"))) {
      return {stat: false, msg: "올바른 대상 연락처를 입력하세요."}
    } else if(!telValid.test(getValues("telno3"))) {
      return {stat: false, msg: "올바른 대상 연락처를 입력하세요."}
    } else {
      return {stat: true, msg: ""}
    }
  }
  const nameValidation = (e:any) => {
    nameValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }
  const telValidation = (e:any) => {
    telValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }

  const handleClose = () => {
    setCloseDialog(2);
    console.log(closeDialog);
    props.onClose?.();
  };
  const onSubmit: SubmitHandler<DollbomPlanFormInput> = async (data) => {
    if(getValues("incomeLevel") === "85이하" && getValues("incomeLevel2") === null) {
      window.alert("상세 소득수준을 선택해주세요.")
      return
    }
    if(getValues("characterJson.demographicCharacteristics.type") === "1인가구") {
      if(!getValues("characterJson.demographicCharacteristics.singleType") || getValues("characterJson.demographicCharacteristics.singleType").length === 0) {
        window.alert("1인 가구 유형을 선택해주세요.")
        return
      }
    }
    if(getValues("characterJson.demographicCharacteristics.type") === "노인") {
      if(!getValues("characterJson.demographicCharacteristics.oldType") || getValues("characterJson.demographicCharacteristics.oldType").length === 0) {
        window.alert("장기요양 등급을 선택해주세요.")
        return
      }
    }
    if(getValues("characterJson.demographicCharacteristics.type") === "장애인") {
      if(!getValues("characterJson.demographicCharacteristics.disabledType") || getValues("characterJson.demographicCharacteristics.disabledType").length === 0) {
        window.alert("장애 정도를 선택해주세요.")
        return
      }
    }
    if(getValues("characterJson.demographicCharacteristics.disabledAdd")) {
      if(!getValues("characterJson.demographicCharacteristics.disabledAddType") || getValues("characterJson.demographicCharacteristics.disabledAddType").length === 0) {
        window.alert("장애 정도를 선택해주세요.")
        return
      }
    }
    let checkObj = paramCheck()
    if(!checkObj.stat) {
      window.alert(checkObj.msg)
      return
    }

    if (window.confirm("대상자 정보를 수정 하시겠습니까?")) {
      // console.log(data);
      if (data.incomeLevel === "85이하") {
        const success = await DollbomPlanAPI.updateTarget({
          ...data,
          telno: `${data.telno1}-${data.telno2}-${data.telno3}`,
          emgcTel: `${data.emgcTel1}-${data.emgcTel2}-${data.emgcTel3}`,
          trgterCd:
            data.trgterNm +
            data.brthdy.split("-").reduce((acc, c) => acc + c.slice(-2), ""),
          adres: data.adres,
          adresDtl: data.adresDtl,
          incomeLevel2: data.incomeLevel2,
          crtUid: 1,
          year: new Date().getFullYear(),
          characterJson: JSON.stringify(data.characterJson),
          // {
          //   distress: data.distress,
          //   demographicCharacteristics: {
          //     type: data.type,
          //     young: data.young,
          //     teen: data.teen,
          //     single: data.single,
          //     singleType: data.singleType,
          //     old: data.old,
          //     oldType: data.oldType,
          //     disabled: data.disabled,
          //     disabledType: data.disabledType,
          //     disabledTypeInput: data.disabledTypeInput,
          //     disabledAdd: data.disabledAdd,
          //     disabledAddType: data.disabledAddType,
          //     disabledTypeAddInput: data.disabledTypeAddInput,
          //   }
          //   usingServices: {
          //     babysitting: data.babysitting,
          //     longTermCareInsuranceForElderly:
          //     data.longTermCareInsuranceForElderly,
          //     customizedElderlyCare: data.customizedElderlyCare,
          //     supportForDisabledActivities: data.supportForDisabledActivities,
          //     others: data.others,
          //     othersInput: data.othersInput,
          //   },
          //   needs: {
          //     integrated: data.integrated,
          //     integratedReason: data.integratedReason,
          //     gwangjuCare: data.gwangjuCare,
          //     emergencyCare: data.emergencyCare,
          //     integratedType: data.integratedType,
          //     gwangjuCareType: data.gwangjuCareType,
          //   },
          // },
        });
        if (success) {
          setCloseDialog(2);
          window.alert("수정 완료")
          // console.log("성공");
          // console.log(closeDialog);
          props.onClose?.();
          navigate(0);
        } else {
          window.alert("수정 실패")
          // console.log("실패");
        }
      } else {
        const success = await DollbomPlanAPI.updateTarget({
          ...data,
          telno: `${data.telno1}-${data.telno2}-${data.telno3}`,
          emgcTel: `${data.emgcTel1}-${data.emgcTel2}-${data.emgcTel3}`,
          trgterCd:
            data.trgterNm +
            data.brthdy.split("-").reduce((acc, c) => acc + c.slice(-2), ""),
          adres: data.adres,
          adresDtl: data.adresDtl,
          incomeLevel2: "null",
          crtUid: 1,
          year: new Date().getFullYear(),
          characterJson: JSON.stringify(data.characterJson),
          // {
          //   distress: data.distress,
          //   demographicCharacteristics: {
          //     type: data.type,
          //     young: data.young,
          //     teen: data.teen,
          //     single: data.single,
          //     singleType: data.singleType,
          //     old: data.old,
          //     oldType: data.oldType,
          //     disabled: data.disabled,
          //     disabledType: data.disabledType,
          //     disabledTypeInput: data.disabledTypeInput,
          //     disabledAdd: data.disabledAdd,
          //     disabledAddType: data.disabledAddType,
          //     disabledTypeAddInput: data.disabledTypeAddInput,
          //   },
          //   usingServices: {
          //     babysitting: data.babysitting,
          //     longTermCareInsuranceForElderly:
          //       data.longTermCareInsuranceForElderly,
          //     customizedElderlyCare: data.customizedElderlyCare,
          //     supportForDisabledActivities: data.supportForDisabledActivities,
          //     others: data.others,
          //     othersInput: data.othersInput,
          //   },
          //   needs: {
          //     integrated: data.integrated,
          //     integratedReason: data.integratedReason,
          //     gwangjuCare: data.gwangjuCare,
          //     emergencyCare: data.emergencyCare,
          //     integratedType: data.integratedType,
          //     gwangjuCareType: data.gwangjuCareType,
          //   },
          // },
        });
        if (success) {
          setCloseDialog(2);
          window.alert("수정 완료")
          // console.log("성공");
          // console.log(closeDialog);
          props.onClose?.();
          navigate(0);
        } else {
          window.alert("수정 실패")
          // console.log("실패");
        }
      }
    }
  };

  const onError = () => {
    if(errors.homeMember) {
      window.alert(errors.homeMember.message)
      return
    }
    //  else if(errors.emgcNm) {
    //   window.alert(errors.emgcNm.message)
    //   return
    // } else if(errors.emgcRel) {
    //   window.alert(errors.emgcRel.message)
    //   return
    // } else if(errors.emgcTel1) {
    //   window.alert(errors.emgcTel1.message)
    //   return
    // } else if(errors.emgcTel2) {
    //   window.alert(errors.emgcTel2.message)
    //   return
    // } else if(errors.emgcTel3) {
    //   window.alert(errors.emgcTel3.message)
    //   return
    // }
     else if(errors.characterJson?.demographicCharacteristics?.type) {
      window.alert(errors.characterJson.demographicCharacteristics.type)
      return
    } else if(errors.incomeLevel) {
      window.alert(errors.incomeLevel.message)
      return
    }
  };

  // const onSubmit: SubmitHandler<DollbomPlanFormInput> = async (data) => {
  //   // TODO: 회원 정보 수정 구현
  //   console.log(data);

  //   setLoading(true);

  //   const success = await DollbomPlanAPI.insert({
  //     ...data,
  //     hMember: 2,
  //     emgcTel: `${data.emgcTel1}-${data.emgcTel2}-${data.emgcTel3}`,
  //     telno: `${data.telno1}-${data.telno2}-${data.telno3}`,
  //     adres: `${data.adres1} ${data.adres2}`,
  //     crtUid: 1,
  //     year: new Date().getFullYear(),
  //     demographic: [],
  //   });

  //   setLoading(false);

  //   if (success) {
  //     navigate(0);
  //   } else {
  //     console.log(">>> failure");
  //   }
  // };
  const { showDialog } = useDialog();

  // 주소 검색
  const handleSearchAddress = () => {
    showDialog(PostCodeDialog, {
      onComplete(data) {
        // console.log(data);

        setValue("adres", data.address);
      },
    })
  }

  return (
    <div
      className="DollbomTargetInfoDialog schedule_popup_inner popup_black"
      {...props}
    >
      <div
        className="schedule_popup_inner popup_white"
        style={{ overflowY: "visible" }}
      >
        <div className="popup_tit">
          <h3>돌봄대상자 정보</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="default_input mt20">
              <h4>대상자</h4>
              <div className="write_box">
                <div
                  className="name mr8 w50_8 w50_8"
                  style={{ position: "relative" }}
                >
                  <input
                    type="text"
                    className="essential input"
                    placeholder="성명"
                    list=""
                    role="combobox"
                    id="input1"
                    autoComplete="off"
                    // disabled
                    {...register("trgterNm", {
                      onChange: (e:any) => {
                        // nameValidation(e)
                      },
                      required: "대상자 이름을 입력해주세요."
                    })}
                  />
                  {/* list role="combobox" id="input1" name="browsers" autoComplete="off" */}
                  <datalist id="browsers1">
                    <option value="홍길동">홍길동</option>
                    <option value="홍길순">홍길순</option>
                    <option value="홍길">홍길</option>
                    <option value="박다혜">박다혜</option>
                  </datalist>
                </div>
                <div className="birth w50_8">
                  <input
                    type="date"
                    id="birth"
                    placeholder="생년월일"
                    max={"9999-12-31"}
                    // disabled
                    {...register("brthdy", {
                      required: "생년월일을 입력해주세요.",
                      onChange: (e) => {
                        if(moment().diff(moment(e.target.value), 'hours') < 24) {
                          window.alert("생년월일은 당일 일자 이전으로 설정해주세요.")
                          setValue("brthdy", moment().subtract(1, 'days').format('yyyy-MM-DD'))
                        }
                      },
                      onBlur: (e) => {
                        if(moment().diff(moment(e.target.value), 'hours') < 24) {
                          window.alert("생년월일은 당일 일자 이전으로 설정해주세요.")
                          setValue("brthdy", moment().subtract(1, 'days').format('yyyy-MM-DD'))
                        }
                      }
                    })}
                  />
                  {/* onfocus="(this.type='date')" onblur="(this.type='text')"  */}
                </div>
              </div>
            </div>
            <div className="default_input">
              <h4 />
              <div className="write_box">
                <label htmlFor="radio1" className="mr20">
                  <input
                    type="radio"
                    id="radio1"
                    value="남"
                    // defaultChecked
                    // disabled
                    {...register("gender")}
                  />
                  남성
                </label>
                <label htmlFor="radio2">
                  <input
                    type="radio"
                    id="radio2"
                    value="여"
                    // disabled
                    {...register("gender")}
                  />
                  여성
                </label>
              </div>
            </div>
            <div className="default_input tel">
              <h4 />
              <div className="write_box">
                <select
                  className="essential"
                  style={{ color: "#000" }}
                  {...register("telno1")}>
                  <option value="">선택</option>
                  <option value="010">010</option>
                  <option value="011">011</option>
                  <option value="017">017</option>
                  <option value="018">018</option>
                  <option value="019">019</option>
                </select>
                <span>-</span>
                <input type="tel" maxLength={4}
                  className="essential"
                  placeholder="연락처"
                  {...register("telno2", {
                    onChange: (e:any) => {
                      if(!telValid.test(e.target.value)) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                      // telValidation(e)
                    },
                    required: "대상자 번호를 입력해주세요."
                  })} />
                <span>-</span>
                <input type="tel" maxLength={4}
                  className="essential"
                  placeholder="연락처"
                  {...register("telno3", {
                    onChange: (e:any) => {
                      if(!telValid.test(e.target.value)) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                      // telValidation(e)
                    },
                    required: "대상자 번호를 입력해주세요."
                  })} />
              </div>
            </div>
            <div className="default_input mt8">
              <h4 />
              <div className="write_box">
                <div className="address">
                  <input
                    type="text"
                    className="mr8"
                    placeholder="주소 입력"
                    disabled
                    {...register("adres")}
                  />
                  <button type="button" onClick={handleSearchAddress}>주소검색</button>
                </div>
                <input
                  type="text"
                  className="mt8"
                  placeholder="상세주소 입력"
                  {...register("adresDtl")}
                />
              </div>
            </div>
            <div className="default_input mt8">
              <h4>가구원 수</h4>
              <div className="write_box">
                <input
                  type="number"
                  className="essential mr8"
                  placeholder="가구원 수"
                  style={{ width: 110 }}
                  {...register("homeMember", {
                    required: "가구원 수를 입력해주세요.",
                    onChange: (e:any) => {
                      if(!telValid.test(e.target.value)) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                      // telValidation(e)
                    }
                  })}
                />
                <span>명</span>
              </div>
            </div>
            {/* 인구학적 특성 */}
            <div className="default_input mt20">
              <h4>
                인구학적
                <br />
                특성
              </h4>
              <div className="write_box">
                <label htmlFor="check1-1" className="mr20">
                  <input
                    type="radio"
                    value="영유아"
                    id="check1"
                    {...register("characterJson.demographicCharacteristics.type")}
                  />
                  영유아
                </label>
                <label htmlFor="check1-2" className="mr20">
                  <input
                    type="radio"
                    value="아동청소년"
                    id="check1"
                    {...register("characterJson.demographicCharacteristics.type")}
                  />
                  아동·청소년
                </label>
                <label htmlFor="check1-3">
                  <input
                    type="radio"
                    value="1인가구"
                    id="check1"
                    {...register("characterJson.demographicCharacteristics.type")}
                  />
                  1인가구
                  <select
                    className={`${getValues("characterJson.demographicCharacteristics.type") === "1인가구" ? "essential" : ""}`}
                    style={{ width: 89, marginLeft: 8, color: getValues("characterJson.demographicCharacteristics.type") === "1인가구" ? "black" : "" }}
                    disabled={data.characterJson.demographicCharacteristics.type === "1인가구" ? false : true}
                    {...register("characterJson.demographicCharacteristics.singleType")}
                  >
                    <option value="청년">청년</option>
                    <option value="장년">장년</option>
                  </select>
                </label>
                <label
                  htmlFor="check1-4"
                  className="w100 mt12"
                >
                  <input
                    type="radio"
                    id="check1"
                    value="노인"
                    {...register("characterJson.demographicCharacteristics.type")}
                  />
                  노인
                  <select
                    className={`${getValues("characterJson.demographicCharacteristics.type") === "노인" ? "essential" : ""}`}
                    style={{ width: 275, marginLeft: 21, color: "#000" }}
                    disabled={data.characterJson.demographicCharacteristics.type === "노인" ? false : true}
                    {...register("characterJson.demographicCharacteristics.oldType")}
                  >
                    <option value="장기요양 등급 없음">
                      장기요양 등급 없음
                    </option>
                    <option value="장기요양 1등급">장기요양 1등급</option>
                    <option value="장기요양 2등급">장기요양 2등급</option>
                    <option value="장기요양 3등급">장기요양 3등급</option>
                    <option value="장기요양 4등급">장기요양 4등급</option>
                    <option value="장기요양 5등급">장기요양 5등급</option>
                    <option value="인지지원등급">인지지원등급</option>
                  </select>
                </label>
                <label
                  htmlFor="check1-5"
                  className="w100 mt12"
                >
                  <input
                    type="radio"
                    id="check1"
                    value="장애인"
                    {...register("characterJson.demographicCharacteristics.type")}
                  />
                  장애인
                  <select
                    style={{ width: "133.5px", marginLeft: 8, color: "#000" }}
                    disabled={data.characterJson.demographicCharacteristics.type === "장애인" ? false : true}
                    className={`mr8 ${getValues("characterJson.demographicCharacteristics.type") === "장애인" ? "essential" : ""}`}
                    {...register("characterJson.demographicCharacteristics.disabledType")}
                  >
                    <option value="심한 장애">심한 장애</option>
                    <option value="심하지 않은 장애">심하지 않은 장애</option>
                  </select>
                  <input
                    type="text"
                    className={getValues("characterJson.demographicCharacteristics.type") === "장애인" ? "essential" : ""}
                    style={{ width: "133.5px", opacity: getValues("characterJson.demographicCharacteristics.type") === "장애인" ? "1" : "0.5" }}
                    disabled={data.characterJson.demographicCharacteristics.type === "장애인" ? false : true}
                    placeholder="장애유형 입력"
                    {...register("characterJson.demographicCharacteristics.disabledTypeInput")}
                  />
                </label>
                <label htmlFor="check1-6" className="w100 mt12"
                style={{opacity: getValues("characterJson.demographicCharacteristics.type") === "장애인" ? "0.5" : "1"}}
                >
                  <input
                      type="checkbox"
                      id="check1-6"
                      {...register(
                        "characterJson.demographicCharacteristics.disabledAdd"
                      )}
                      disabled={getValues("characterJson.demographicCharacteristics.type") !== "장애인" &&
                      getValues("characterJson.demographicCharacteristics.type") !== null  ? false : true}
                  />
                  추가장애여부
                </label>
                <select
                    style={{ width: "133.5px", marginLeft: 8, color: getValues("characterJson.demographicCharacteristics.disabledAdd") ? "black" : "" }}
                    className={`mr8 ${getValues("characterJson.demographicCharacteristics.disabledAdd") ? "essential" : ""}`}
                    {...register(
                      "characterJson.demographicCharacteristics.disabledAddType"
                    )}
                    disabled={getValues("characterJson.demographicCharacteristics.disabledAdd") ? false : true}
                  >
                    <option value="">장애 정도</option>
                    <option value="심한 장애">심한 장애</option>
                    <option value="심하지 않은 장애">심하지 않은 장애</option>
                </select>
                <input
                    type="text"
                    className={getValues("characterJson.demographicCharacteristics.disabledAdd") ? "essential" : ""}
                    style={{ width: "133.5px", opacity: getValues("characterJson.demographicCharacteristics.disabledAdd") ? "1" : "0.5" }}
                    placeholder="장애유형 입력"
                    {...register(
                      "characterJson.demographicCharacteristics.disabledTypeAddInput"
                    )}
                    disabled={getValues("characterJson.demographicCharacteristics.disabledAdd") ? false : true}
                />
              </div>
            </div>
            <div className="default_input mt20">
              <h4>소득수준</h4>
              <div className="write_box">
                <select
                  className="essential"
                  style={{ color: "#000" }}
                  {...register("incomeLevel", {
                    required: "소득수준을 선택해주세요."
                  })}
                >
                  <option value="">선택</option>
                  <option value="85이하">기준중위소득 85% 이하</option>
                  <option value="100이하">
                    기준중위소득 85% 초과 ~ 100% 이하
                  </option>
                  <option value="100초과">기준중위소득 100% 초과</option>
                </select>
                <ul
                  className="selectUl"
                  style={{
                    display: data.incomeLevel === "85이하" ? "block" : "none",
                  }}
                >
                  <li>
                    <label htmlFor="radio1-1" className="mr20">
                      <input
                        type="radio"
                        id="radio1"
                        value="수급자"
                        disabled={
                          data.incomeLevel === "100이하" ||
                            data.incomeLevel === "100초과"
                            ? true
                            : false
                        }
                        {...register("incomeLevel2")}
                      />
                      수급자
                    </label>
                  </li>
                  <li>
                    <label htmlFor="radio1-2" className="mr20">
                      <input
                        type="radio"
                        id="radio1"
                        value="차상위"
                        disabled={
                          data.incomeLevel === "100이하" ||
                            data.incomeLevel === "100초과"
                            ? true
                            : false
                        }
                        {...register("incomeLevel2")}
                      />
                      차상위
                    </label>
                  </li>
                  <li>
                    <label htmlFor="radio1-3">
                      <input
                        type="radio"
                        id="radio1"
                        value="85이하"
                        disabled={
                          data.incomeLevel === "100이하" ||
                            data.incomeLevel === "100초과"
                            ? true
                            : false
                        }
                        {...register("incomeLevel2")}
                      />
                      기준중위소득 85% 이하
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            {/* 인구학적 특성 end */}
            <div className="default_input mt20">
              <h4>비상연락</h4>
              <div className="write_box">
                <input
                  type="text"
                  className="essential mr8 w50_8"
                  placeholder="성명"
                  {...register("emgcNm", {
                    // required: "비상연락인 성명을 입력해주세요.",
                    onChange: (e:any) => {
                      // nameValidation(e)
                    }
                  })}
                />
                <select
                  className="w50_8"
                  style={{ color: "#000" }}
                  {...register("emgcRel")}
                >
                  <option value="">관계 선택</option>
                  <option value="본인">본인</option>
                  <option value="가족">가족</option>
                  <option value="이웃(지인)">이웃(지인)</option>
                  <option value="시설 등 종사자">시설 등 종사자</option>
                </select>
              </div>
            </div>
            <div
              className="default_input tel mt8"
            >
              <h4 />
              <div className="write_box">
                <select
                  style={{ color: "#000" }}
                  {...register("emgcTel1")}>
                  <option value="">선택</option>
                  <option value="010">010</option>
                  <option value="011">011</option>
                  <option value="017">017</option>
                  <option value="018">018</option>
                  <option value="019">019</option>
                </select>
                <span>-</span>
                <input type="tel" maxLength={4} {...register("emgcTel2", {
              // required: "비상연락처를 입력해주세요.",
              onChange: (e:any) => {
                if(!telValid.test(e.target.value)) {
                  e.target.value = String(e.target.value).slice(0, -1)
                }
                telValidation(e)
              }
            })} />
                <span>-</span>
                <input type="tel" maxLength={4} {...register("emgcTel3", {
              // required: "비상연락처를 입력해주세요.",
              onChange: (e:any) => {
                if(!telValid.test(e.target.value)) {
                  e.target.value = String(e.target.value).slice(0, -1)
                }
                telValidation(e)
              }
            })} />
              </div>
            </div>
            <div className="btn_wrap">
              <button
                type="button"
                className="cancel"
                onClick={handleClose}
              >
                취소
              </button>
              <button type="submit" className="submit">
                저장
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DollbomPlanInfoDialog;
