import React, { useEffect, useRef, useState } from "react";
import { Navigate, NavigateFunction, useNavigate } from "react-router-dom";

import useMember from "../../../hooks/useMember";
import styles from "./JoinPage.module.css";
import MainImage from "../../../assets/img_default.png"
import MemberAPI from "../../../apis/MemberAPI";
import { SubmitHandler, useForm } from "react-hook-form";
import Join from "../../../models/form/Join";
import { engValid, nameValid, telValid, ValidCheck } from "../../../App";
import FileUtil from "../../../utils/FileUtil";
import FileUploadAPI from "../../../apis/FileUploadAPI";

export type DepartItem = {
  label: string,
  value: number,
}

const JoinPage = () => {
  const { member } = useMember();
  const navigate = useNavigate();

  const [step, setStep] = useState(0)
  const [belong, setBelong] = useState("")

  const handleNextStep = (belong?: string) => {
    setStep((cur) => cur + 1)
    if(belong !== undefined) {
      setBelong(belong)
    }
  }

  const handlePrevStep = () => {
    setStep((cur) => cur - 1)
  }

  if (member) {
    return <Navigate to="/home" />;
  }

  return (
    <div id={styles["Wrapper"]}>
      <div className={styles.wrapper}>
        <div className={styles.visual}>
          <div className={styles.title_inner}>
            <h1>Log In</h1>
            <p>
              진행 방식에 따라 정보를 입력하여
              <br />
              회원가입 해주세요.
            </p>
          </div>
          <div className={styles.logo_inner}>
            <a href="#" aria-label="홈으로" className={styles.logo}>
              AI토탈케어플랫폼
            </a>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.content_inner}>
            <JoinStep step={step} />
            {step === 0 ?
              <TermBox navigate={navigate} handleNextStep={handleNextStep} />
            :
              null
            }
            {step === 1 ?
              <TypeBox navigate={navigate} handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} />
            :
              null
            }
            {step === 2 ?
              <WriteBox navigate={navigate} handlePrevStep={handlePrevStep} belong={belong} />
            :
              null
            }

          </div>
        </div>
      </div>
    </div>
  );
};

const JoinStep = ({step} : {step : number}) => {

  return (
    <div className={styles.progress_box}>
      {/* on이 붙습니다 */}
      <button type="button" className={`${styles.terms} ${step === 0 ? styles.on : null}`}>
        약관동의
      </button>
      <button type="button" className={`${styles.type} ${step === 1 ? styles.on : null}`}>
        분류선택
      </button>
      <button type="button" className={`${styles.write} ${step === 2 ? styles.on : null}`}>
        정보입력
      </button>
    </div>
  )
}

const TermBox = ({navigate, handleNextStep} : {navigate: NavigateFunction, handleNextStep:Function}) => {
  const [checkItem, setCheckItem] = useState([] as number[])
  const handleSingleCheck = (checked:any, id:number) => {
    if(checked) {
      setCheckItem(cur => [...cur, id])
    } else {
      setCheckItem(checkItem.filter((item) => item !== id))
    }
  }
  
  const handleAllCheck = (checked:any) => {
    if(checked) {
      setCheckItem([1,2])
    } else {
      setCheckItem([])
    }
  }

  const handleCancel = () => {
    // navigate("/login");
    window.location.href = `https://dev.nubiz.co.kr:11160/`
  };

  const handleNext = () => {
    if(checkItem.length === 2) {
      // console.log("모두 동의함")
      handleNextStep(true)
    } else {
      window.alert("필수항목에 모두 동의해주십시오.")
    }
  }


  return (
    <div className={styles.terms_box}>
      <form>
        <div className={styles.cont}>
          <ul>
            <li>
              <label htmlFor="check1-1">
                {/* checkbox가 체크될시 label에 on 클래스 붙여주세요 */}
                <input type="checkbox" id="check1-1" name="cbx_chkAll" onChange={(e) => handleAllCheck(e.target.checked)} checked={checkItem.length === 2 ? true : false} />
                전체 동의하기
              </label>
            </li>
            <li>
              <label htmlFor="check1-2">
                {/* checkbox가 체크될시 label에 on 클래스 붙여주세요 */}
                <input type="checkbox" id="check1-2" onChange={(e) => handleSingleCheck(e.target.checked, 1)} checked={checkItem.includes(1) ? true : false} />
                서비스 이용약관 동의
              </label>
              <div className={styles.terms_cont}>
                <p>
                  제 1장 총 칙 1. 개인정보란 생존하는 개인에 관한
                  정보로서 당해 정보에 포함 되어 있는 성명, 주민등록번호
                  등의 사항에 의하여 당해 개인을 식별할 수 있는 정보
                  (당해 정
                </p>
              </div>
            </li>
            <li>
              <label htmlFor="check1-3">
                {/* checkbox가 체크될시 label에 on 클래스 붙여주세요 */}
                <input type="checkbox" id="check1-3" onChange={(e) => handleSingleCheck(e.target.checked, 2)} checked={checkItem.includes(2) ? true : false} />
                개인정보보호를위한 이용자 동의
              </label>
              <div className={styles.terms_cont}>
                <p>
                  제 1장 총 칙 1. 개인정보란 생존하는 개인에 관한
                  정보로서 당해 정보에 포함 되어 있는 성명, 주민등록번호
                  등의 사항에 의하여 당해 개인을 식별할 수 있는 정보
                  (당해 정
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className={styles.btn}>
          <button type="button" className={styles.cancel} onClick={handleCancel}>
            취소
          </button>
          <button type="button" className={styles.next} onClick={handleNext}>
            다음
          </button>
        </div>
      </form>
    </div>
  )
}

const TypeBox = ({navigate, handlePrevStep, handleNextStep} : {navigate : NavigateFunction, handlePrevStep: Function, handleNextStep: Function}) => {
  const handleCancel = () => {
    navigate("/login");
  };
  const handlePrev = () => {
    handlePrevStep()
  }
  const handleNext = (belong:string) => {
    if(belong.length === 0) {
      window.alert("소속을 선택해주세요.")
      return
    }
    handleNextStep(belong)
  }
  const [belong, setBelong] = useState("")
  const onChange = (e:any) => {
    // console.log(e.target.value)
    setBelong(e.target.value)
  }
  return (
    <div className={styles.type_box}>
      <form>
        <div className={styles.cont}>
          <ul>
            <li>
              <label htmlFor="radio1-1">
                <input type="radio" id="radio1-1" name="radio" value="GU" onChange={(e) => onChange(e)} />구
              </label>
            </li>
            {/* <li>
              <label htmlFor="radio1-2">
                <input type="radio" id="radio1-2" name="radio" value="GU" onChange={(e) => onChange(e)}/>구
                담당자
              </label>
            </li> */}
            <li>
              <label htmlFor="radio1-3">
                <input type="radio" id="radio1-3" name="radio" value="DONG" onChange={(e) => onChange(e)}/>동
              </label>
            </li>
            {/* <li>
              <label htmlFor="radio1-4">
                <input type="radio" id="radio1-4" name="radio" value="DONG" onChange={(e) => onChange(e)}/>동
                담당자
              </label>
            </li> */}
            <li>
              <label htmlFor="radio1-5">
                <input type="radio" id="radio1-5" name="radio" value="ORG" onChange={(e) => onChange(e)}/>
                제공기관
              </label>
            </li>
            {/* <li>
              <label htmlFor="radio1-6">
                <input type="radio" id="radio1-6" name="radio" value="ORG" onChange={(e) => onChange(e)}/>
                제공기관 담당자
              </label>
            </li> */}
            {/* <li>
              <label htmlFor="radio1-7">
                <input type="radio" id="radio1-7" name="radio" />
                사회서비스원 팀장
              </label>
            </li>
            <li>
              <label htmlFor="radio1-8">
                <input type="radio" id="radio1-8" name="radio" />
                사회서비스원 담당자
              </label>
            </li> */}
          </ul>
        </div>
        <div className={styles.btn}>
          <button type="button" className={styles.cancel} onClick={handleCancel}>
            취소
          </button>
          <button type="button" className={styles.previous} onClick={handlePrev}>
            이전
          </button>
          <button type="button" className={styles.next} onClick={() => handleNext(belong)}>
            다음
          </button>
        </div>
      </form>
    </div>
  )
}

const WriteBox = ({navigate, handlePrevStep, belong} : {navigate : NavigateFunction, handlePrevStep: Function, belong: string}) => {
  const handleCancel = () => {
    navigate("/login");
  };
  const handlePrev = () => {
    handlePrevStep()
  }
  const {register, handleSubmit, getValues, setError, formState: { errors }} = useForm<Join>({
    defaultValues: {
      gender: "남",
      tel1: "010",
    }
  })

  const paramCheck = () => {
    if(!engValid.test(getValues("userId"))) {
      // setInputCheck({stat: false, msg: "올바른 아이디를 입력하세요."})
      return {stat: false, msg: "올바른 아이디를 입력하세요."}
    } else if(!telValid.test(getValues("tel2"))) {
      // setInputCheck({stat: false, msg: "올바른 연락처를 입력하세요."})
      return {stat: false, msg: "올바른 연락처를 입력하세요."}
    } else if(!telValid.test(getValues("tel3"))) {
      // setInputCheck({stat: false, msg: "올바른 연락처를 입력하세요."})
      return {stat: false, msg: "올바른 연락처를 입력하세요."}
    } else {
      // setInputCheck({stat: true, msg: ""})
      return {stat: true, msg: ""}
    }
  }
  const [departList, setDepartList] = useState([] as DepartItem[])
  useEffect(() => {
    setError("userId", {type: "text"})
    setError("userPw", {type: "text"})
    setError("certPw", {type: "text"})
    setError("gender", {type: "text"})
    setError("tel1", {type: "text"})
    setError("tel2", {type: "text"})
    setError("tel3", {type: "text"})
    setError("role", {type: "text"})
    setError("dptUidString", {type: "text"})
    MemberAPI.getDeptList({deptCd: belong}).then((res) => {
      let tempList:DepartItem[] = []
      res.list.map((item) => {
        tempList.push({label: item.departName, value: item.dptUid})
      })
      setDepartList(tempList)
    })
  }, [])
  const [idValid, setIdValid] = useState(false)
  const [hide, setHide] = useState(true)
  const idCheck = async () => {
    if(getValues("userId").length === 0) {
      window.alert("아이디를 입력해주세요.")
      return
    }
    if (getValues("userId").length < 8 || getValues("userId").length > 16) {
      window.alert("아이디는 8자~16자만 입력 가능합니다.")
      return
    }
    await MemberAPI.idCheck({userId : getValues("userId")}).then((res) => {
      window.alert(res.message)
      if(res.success) {
        setIdValid(true)
      } else {
        setIdValid(false)
      }
    })
  }
  const onSubmit:SubmitHandler<Join> = async (data) => {
    let checkObj = paramCheck()
    if(!checkObj.stat) {
      window.alert(checkObj.msg)
      return
    }
    type YN = "Y" | "N"
    let caseChargeYn:YN = "N"
    let srvcSupplyYn:YN = "N"
    let srvcManageYn:YN = "N"
    if(belong === "GU") { //구
      if(data.role ==="leader") { //팀장
        caseChargeYn = 'N'
      }
    } else if (belong === "DONG") { //동
      if(data.role ==="leader") { //팀장
        caseChargeYn = 'Y'
      } else {  //담당자
        caseChargeYn = 'N'
      }
    } else {  //제공기관
      if(data.role ==="leader") { //팀장
        srvcManageYn = 'Y'
      } else {  //서비스제공자
        srvcSupplyYn = 'Y'
      }
    }

    if(!idValid) {
      window.alert("아이디 중복체크를 해주세요.")
      return
    }
    if(getValues("userPw") !== getValues("certPw")) {
      window.alert("비밀번호와 비밀번호 확인이 일치하지 않습니다.")
      return
    }
    if (getValues("userId").length < 8 || getValues("userId").length > 16) {
      window.alert("아이디는 8자~16자만 입력 가능합니다.")
      return
    }
    if(getValues("userPw").length < 8 || getValues("userPw").length > 20) {
      window.alert("비밀번호는 8자~20자만 입력 가능합니다.")
      return
    }
    let suc = await MemberAPI.join({
      ...data,
      tel: `${data.tel1}-${data.tel2}-${data.tel3}`,
      phone: `${data.tel1}-${data.tel2}-${data.tel3}`,
      dptUid: parseInt(data.dptUidString),
      caseChargeYn: caseChargeYn,
      srvcSupplyYn: srvcSupplyYn,
      srvcManageYn: srvcManageYn,
    }).then((res) => {
      return res
    })

    if(suc.success) {
      if(profileImage) {
        FileUploadAPI.fileUpload({
          attachFile: profileImage,
          folder: "USER",
          fileGbn: "IMAGE",
          uid: suc.data.mbrUid,
        })
      }
      window.alert(suc.message)
      handleCancel()
    }
  }

  const onError = () => {
    if(getValues("userId").length === 0) {
      window.alert(errors.userId?.message)
      return
    } else if(getValues("userPw").length === 0) {
      window.alert(errors.userPw?.message)
      return
    } else if(getValues("certPw").length === 0) {
      window.alert(errors.certPw?.message)
      return
    } else if(getValues("userName").length === 0) {
      window.alert(errors.userName?.message)
      return
    } else if(getValues("gender").length === 0) {
      window.alert(errors.gender?.message)
      return
    } else if(getValues("tel1").length === 0) {
      window.alert(errors.tel1?.message)
      return
    } else if(getValues("tel2").length < 3 || getValues("tel2").length > 4) {
      window.alert(errors.tel2?.message)
      return
    } else if(getValues("tel3").length !== 4) {
      window.alert(errors.tel3?.message)
      return
    } else if(getValues("role").length === 0) {
      window.alert(errors.role?.message)
      return
    } else if(getValues("dptUidString").length === 0) {
      window.alert(errors.dptUidString?.message)
      return
    }
  }

  const [inputCheck, setInputCheck] = useState<ValidCheck>({stat: false, msg: "비정상적 값 입력됨"})
  const nameValidation = (e:any) => {
    nameValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }
  const telValidation = (e:any) => {
    telValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }
  const idValidation = (e:any) => {
    engValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }
  const [profileImage, setProfileImage] = useState<File>();
  const [profileThumb, setProfileThumb] = useState<string>();
  const handleProfileImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files?.[0]) {
      FileUtil.getFilePath(e.target.files?.[0]).then((res) => {
        // console.log(res)
        setProfileThumb(res)
      })
    }
    setProfileImage(e.target.files?.[0]);
  }

  return (
    <div className={styles.write_box}>
      <form>
        <div className={styles.cont}>
          <div className={styles.info_img}>
            <div className={styles.img_wrap}>
              <div className={styles.img}>
                <img src={profileThumb ? profileThumb : MainImage} alt="프로필 이미지" />
              </div>
              <a href="#" className={styles.cam} onClick={() => setHide(!hide)} />
              <div className={`${styles.cam_popup} ${hide ? styles.hide : ""}`}>
                <span className={styles["ico-arrow"]} />
                <span className={styles["ico-arrow2"]} />
                <label htmlFor="img_register">
                  <input
                    type="file"
                    id="img_register"
                    style={{ display: "none" }}
                    onChange={handleProfileImage}
                    accept="image/*"
                  />
                  사진 등록
                </label>
                <label htmlFor="img_delete">
                  <input
                    type="button"
                    id="img_delete"
                    style={{ display: "none" }}
                  />
                  사진 삭제
                </label>
              </div>
            </div>
          </div>
          <div className={styles.writing}>
            <ul>
              <li>
                <div>
                  <h4>아이디</h4>
                    <input type="text" placeholder="아이디를 입력하세요(8자~16자)" style={{width: "calc(100% - 100px)"}}
                      {...register("userId", {
                        required: "아이디를 확인해주세요. (8자~16자)",
                        onChange: (e:any) => {
                          idValidation(e)
                          if(!engValid.test(e.target.value)) {
                            e.target.value = String(e.target.value).slice(0, -1)
                          }
                        },
                      })}
                    />
                    <button type="button" onClick={idCheck}>중복확인</button>
                </div>
              </li>
              <li>
                <div>
                  <h4>비밀번호</h4>
                    <input type="password" placeholder="비밀번호를 입력하세요(8~20자리)"
                      {...register("userPw", {
                        required: "비밀번호를 입력해주세요. (8~20자리)"
                      })}
                    />
                </div>
              </li>
              <li>
                <div>
                  <h4>비밀번호 확인</h4>
                    <input type="password" placeholder="비밀번호를 입력하세요(8~20자리)"
                      {...register("certPw", {
                        required: "비밀번호 확인을 입력해주세요."
                      })}
                    />
                </div>
              </li>
              <li>
                <div>
                  <h4>분류</h4>
                      {belong === "GU" ? 
                      (
                        <select {...register("role", {required: "분류를 선택해주세요."})}>
                          <option value="leader">구 관리자</option>
                        </select>
                      )  
                      :
                      belong === "DONG" ? 
                      (
                        <select {...register("role", {required: "분류를 선택해주세요."})}>
                          <option value="">선택</option>
                          <option value="leader">동 관리자</option>
                          <option value="manager">동 담당자</option>
                        </select>
                      )
                      :
                      <select {...register("role", {required: "분류를 선택해주세요."})}>
                          <option value="">선택</option>
                          <option value="leader">제공기관 팀장</option>
                          <option value="manager">제공기관 서비스제공자</option>
                        </select>
                    }
                </div>
              </li>
              <li>
                <div>
                  <h4>이름</h4>
                  <input type="text" placeholder="이름을 입력하세요"
                    {...register("userName", {
                      required: "이름을 입력해주세요.",
                      onChange: (e:any) => {nameValidation(e)}
                    })}
                  />
                </div>
              </li>
              <li>
                <div>
                  <h4>성별</h4>
                  <ul className={styles.radio_select}>
                    <li style={{ marginRight: 20 }}>
                      <label htmlFor="radio2-1">
                        <input
                          type="radio"
                          id="radio2-1"
                          value="남"
                          {...register("gender", {
                            required: "성별을 선택해주세요."
                          })}
                        />
                        남성
                      </label>
                    </li>
                    <li>
                      <label htmlFor="radio2-2">
                        <input
                          type="radio"
                          id="radio2-2"
                          value="여"
                          {...register("gender", {
                            required: "성별을 선택해주세요."
                          })}
                        />
                        여성
                      </label>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div>
                  <h4>기관</h4>
                  <select {...register("dptUidString", {required: "소속기관을 선택해주세요.",
                })}>
                    <option value="">선택</option>
                    {departList.map((item, idx) => (
                      <option key={idx} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  {/* <select style={{ marginTop: 10 }}>
                    <option>선택</option>
                    <option selected>구 팀장</option>
                  </select> */}
                </div>
              </li>
              <li>
                <div>
                  <h4>연락처</h4>
                  <div className={styles.tel}>
                    <select {...register("tel1", {
                      required: "연락처를 입력해주세요."
                    })}>
                      <option value="">선택</option>
                      <option value="010">
                        010
                      </option>
                      <option value="011">011</option>
                      <option value="017">017</option>
                      <option value="018">018</option>
                      <option value="019">019</option>
                    </select>
                    <span>-</span>
                    <input type="tel" maxLength={4} {...register("tel2", {
                      required: "올바른 연락처를 입력해주세요.",
                      onChange: (e:any) => {
                        // telValidation(e)
                        if(!telValid.test(e.target.value)) {
                          e.target.value = String(e.target.value).slice(0, -1)
                        }
                      }
                    })} />
                    <span>-</span>
                    <input type="tel" maxLength={4} {...register("tel3", {
                      required: "올바른 연락처를 입력해주세요.",
                      onChange: (e:any) => {
                        // telValidation(e)
                        if(!telValid.test(e.target.value)) {
                          e.target.value = String(e.target.value).slice(0, -1)
                        }
                      }
                    })} />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.btn}>
          <button
            type="button"
            className={styles.cancel}
            onClick={handleCancel}
          >
            취소
          </button>
          <button type="button" className={styles.previous} onClick={handlePrev}>
            이전
          </button>
          <button type="button" className={styles.next} onClick={handleSubmit(onSubmit, onError)}>
            가입
          </button>
        </div>
      </form>
    </div>
  )
}

export default JoinPage;
