import React, { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import DollbomPlanSupportAPI from "../../apis/DollbomPlanSupportAPI";
import ServicePlanAPI from "../../apis/ServicePlanAPI";
import CloseIcon from "../../assets/ic_close.png";
import { SearchFormInput } from "../../components/common/SearchBar";
import useMember from "../../hooks/useMember";
import ServiceTargetYear from "../../models/ServiceTargetYear";
import FileUtil from "../../utils/FileUtil";
import { DialogProps } from "../DialogProvider";
import "./DollbomSupportDialog.scss";
import SprtMoreData from "../../models/SprtMoreData";

/**
 * 작성자: 김덕현
 * 작성일: 2023.03.14
 *
 * 추가지원검토서 팝업
 */
interface DollbomSupportFormInput {
  syUid: number;
  caseContent: string;
  sprtMoreNssr: string;
  sprtMoreUse: string;
  expnNss: string;
  expnUse: string;
  mtOpnn: string;
  cfmYn: string;
  cfmRsn: string;
}

const DollbomSupportDialog = (props: DialogProps) => {
  const navigate = useNavigate();
  const { member } = useMember();
  const [isLoading, setLoading] = useState(false);
  const [close, setClose] = useState(0);
  const [targetList, setTargetList] = useState<ServiceTargetYear[]>([]);
  const [onFocus, setOnFocus] = useState(false);
  // select 선택시 대상자 정보 넣기
  const [targetSyUid, setTargetSyUid] = useState(Number);
  const [name, setName] = useState("");
  const [brthdy, setBrthdy] = useState("");
  const [gender, setGender] = useState("");
  const [tel1, setTel1] = useState("");
  const [tel2, setTel2] = useState("");
  const [tel3, setTel3] = useState("");
  const [adres, setAdres] = useState("");
  const [adresDtl, setAdresDtl] = useState("");

  const [selectedMember, setSelectedMember] = useState<ServiceTargetYear>();
  const { register, handleSubmit, watch, setValue } = useForm<DollbomSupportFormInput>();
  const data = watch();
  const syUid = watch("syUid");
  const handleClose = () => {
    props.onClose?.();
  };
  const handleFocus = () => {
    setOnFocus(true);
  }
  const handleBlur = () => {
    setOnFocus(false);
  }
  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (event) => {
    // console.log(event.currentTarget.value);
    DollbomPlanSupportAPI.getTargetList({
      keyword: event.currentTarget.value
    }).then((res) => {
      setTargetList(res);
      // console.log(res);
    });
  };

  const onSubmit: SubmitHandler<DollbomSupportFormInput> = async (data) => {
    if (!name) {
      alert('"대상자"를 입력해 주세요.');
    } else if (!data.caseContent) {
      alert('"사례내용"을 입력해 주세요.');
    } else if (!data.sprtMoreNssr) {
      alert('"지원한도액 추가 필요성"을 입력해 주세요.');
    } else if (!data.sprtMoreUse) {
      alert('"추가 지원액 주요 사용처"를 입력해 주세요.');
    } else if (!data.expnNss) {
      alert('"사례관리 사업비 필요성"을 입력해 주세요.');
    } else if (!data.expnUse) {
      alert('"사례관리 사업비 주요 사용처"를 입력해 주세요.');
    } else {
      if (window.confirm('"추가지원검토서"를 등록하시겠습니까?')) {
        setLoading(true);

        const success = await DollbomPlanSupportAPI.insert({
          ...data,
          syUid: syUid
        });

        setLoading(false);

        if (success) {
          alert("추가지원 검토서가 등록되었습니다.");
          // console.log(">>> success");
          navigate(0);
        } else {
          // console.log(">>> failure");
        }
      }
    }
    
  };
  const handleMemberClick = (list: ServiceTargetYear) => {
    DollbomPlanSupportAPI.getSprtMoreData({
      syUid: list.syUid,
    }).then((res) => {
      targetParamSetting(res, list)
    })
  };

  const targetParamSetting = async (sprtMoreData: SprtMoreData, serviceTargerYear: ServiceTargetYear) => {
    if(sprtMoreData) {
      window.alert("이미 추가지원 신청한 대상입니다.")
      return
    }
    let history: { success: boolean, message: string } = {success: true, message: ""}
    await DollbomPlanSupportAPI.pastSprtHistory({
      stUid: serviceTargerYear.stUid,
      syUid: serviceTargerYear.syUid
    }).then((res) => {
      history = res
    })
    if (!history.success) {
      window.alert(history.message)
      return
    }
    setSelectedMember(serviceTargerYear);
    setValue("syUid", serviceTargerYear.syUid);
    setName(serviceTargerYear.trgterNm);
    setBrthdy(serviceTargerYear.brthdy);
    setGender(serviceTargerYear.gender);
    setTel1(serviceTargerYear.telno.split("-")[0]);
    setTel2(serviceTargerYear.telno.split("-")[1]);
    setTel3(serviceTargerYear.telno.split("-")[2]);
    setAdres(serviceTargerYear.adres);
    setAdresDtl(serviceTargerYear.adresDtl);

    setOnFocus(false);
  }
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    // console.log("asdasdasdas", e.target.value)
  };
  return (
    <div
      className="DollbomSupportDialog schedule_popup_inner popup_black"
      {...props}
    >
      <div
        className="schedule_popup_inner popup_white"
        style={{ overflowY: "visible" }}
      >
        <div className="popup_tit">
          <h3>추가지원 신청</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="default_input mt20">
              <h4>대상자</h4>
              <div className="write_box">
                <div
                  className="name mr8 w50_8 w50_8"
                  style={{ position: "relative" }}
                >
                  <input type="hidden" value={syUid}
                    {...register("syUid")} />
                  <input
                    type="text"
                    className="essential input"
                    placeholder="성명"
                    list=""
                    role="combobox"
                    id="input"
                    name="browsers"
                    autoComplete="off"
                    style={{ borderRadius: onFocus ? "5px 5px 0px 0px" : "5px 5px 5px 5px" }}
                    value={name}
                    onFocus={handleFocus}
                    onKeyUp={handleKeyUp}
                    onChange={handleNameChange}
                  // {...register("trgterNm")}
                  />
                  {/* list role="combobox" id="input1" name="browsers" autoComplete="off" */}
                  <datalist id="browsers" style={{ display: onFocus ? "block" : "none" }}>
                    {targetList.map((list) => (
                      <option
                        key={list.stUid}
                        onClick={() => handleMemberClick(list)}
                        onBlur={() => handleBlur}
                      >{list.trgterNm}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className="birth w50_8">
                  <input
                    type="date"
                    id="birth"
                    placeholder="생년월일"
                    value={brthdy}
                    readOnly
                  // {...register("brthdy")}
                  />
                  {/* onfocus="(this.type='date')" onblur="(this.type='text')"  */}
                </div>
              </div>
            </div>
            <div className="default_input">
              <h4 />
              <div className="write_box">
                <label htmlFor="radio1" className="mr20">
                  <input
                    type="radio"
                    id="radio1"
                    name="gender"
                    value="남"
                    defaultChecked
                    checked={gender === "남"}
                    readOnly
                  // {...register("gender")}
                  />
                  남성
                </label>
                <label htmlFor="radio2">
                  <input
                    type="radio"
                    id="radio2"
                    name="gender"
                    value="여"
                    checked={gender === "여"}
                    readOnly
                  // {...register("gender")}
                  />
                  여성
                </label>
              </div>
            </div>
            <div className="default_input tel">
              <h4 />
              <div className="write_box">
                <select
                  style={{ color: "#000" }}
                  value={tel1}
                  disabled={true}
                // {...register("telno1")}
                >
                  <option value="">선택</option>
                  <option value="010">010</option>
                  <option value="011">011</option>
                  <option value="017">017</option>
                  <option value="018">018</option>
                  <option value="019">019</option>
                </select>
                <span>-</span>
                <input type="tel" maxLength={4}
                  value={tel2}
                  disabled={true}
                // {...register("telno2")} 
                />
                <span>-</span>
                <input type="tel" maxLength={4}
                  value={tel3}
                  disabled={true}
                // {...register("telno3")}
                />
              </div>
            </div>
            <div className="default_input mt8">
              <h4 />
              <div className="write_box">
                <div className="address">
                  <input
                    type="text"
                    className="mr8"
                    placeholder="주소"
                    value={adres}
                    disabled={true}
                  // {...register("adres")}
                  />
                  {/* <button type="button">주소검색</button> */}
                </div>
                <input
                  type="text"
                  className="mt8"
                  placeholder="상세주소"
                  value={adresDtl}
                  disabled={true}
                // {...register("adresDtl")}
                />
              </div>
            </div>
            <div className="default_input mt20">
              <h4>
                사례내용
              </h4>
              <div className="write_box">
                <textarea
                  className="essential"
                  placeholder="사례내용 입력"
                  {...register("caseContent")}
                />
              </div>
            </div>
            {/* 인구학적 특성 */}
            <div className="default_input mt20">
              <h4>
                연간 지원한도액
                <br />
                추가 지원 검토의견
              </h4>
              <div className="write_box">
                <textarea
                  className="essential"
                  placeholder="필요성 입력"
                  {...register("sprtMoreNssr")}
                />
                <textarea
                  placeholder="추가 지원액 주요 사용처 입력"
                  className="mt10 essential"
                  {...register("sprtMoreUse")}
                />
              </div>
            </div>

            <div className="default_input mt20 essential">
              <h4>
                광주다움 통합돌봄
                <br />
                사례관리 사업비 사용
                <br />
                검토의견
              </h4>
              <div className="write_box">
                <textarea
                  className="essential"
                  placeholder="필요성 입력"
                  {...register("expnNss")}
                />
                <textarea
                  placeholder="사례관리 사업비 주요 사용처 입력"
                  className="mt10 essential"
                  {...register("expnUse")}
                />
              </div>
            </div>

            {/* 인구학적 특성 end */}


            <div className="btn_wrap">
              <button
                type="button"
                className="cancel"
                onClick={handleClose}
              >
                취소
              </button>
              <button type="submit" className="submit">
                등록
              </button>
            </div>
          </form>
        </div>
      </div >
    </div >

  );
};

export default DollbomSupportDialog;
