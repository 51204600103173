import { Type } from "class-transformer";
import CounselResult from "./CounselResult";
import NeedsAssessmentJsonData from "./NeedsAssessmentJsonData";

class NeedsAssessmentJoin {
  @Type(() => NeedsAssessmentJsonData)
  public jsonData: NeedsAssessmentJsonData;
  @Type(() => CounselResult)
  public counselResult: CounselResult;
  
  constructor(
    jsonData: NeedsAssessmentJsonData,
    counselResult: CounselResult,
  ) {
    this.jsonData = jsonData;
    this.counselResult = counselResult;
  }
}

export default NeedsAssessmentJoin;
