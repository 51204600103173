import React, { useEffect, useState } from "react";
import moment from "moment";

import ServiceAPI from "../../apis/ServiceAPI";
import ServicePlanAPI from "../../apis/ServicePlanAPI";
import { useDialog } from "../../dialogs/DialogProvider";
import NeedsAssessmentDialog from "../../dialogs/service/NeedsAssessmentDialog";
import NaEvaluationInfo from "../../models/NaEvaluationInfo";
import ServiceDetail from "../../models/ServiceDetail";
import OfficialPowerStopDialog from "../../dialogs/service/OfficialPowerStopDialog";
import EmergencyIcon from "../../assets/긴급돌봄아이콘.png";
import useMember from "../../hooks/useMember";
import DataTable, { createRow, createTextCell } from "./DataTable";
import ServiceMenuList, { SpyList } from "../../models/ServiceMenuList";
import { Tab } from "@mui/material";
import DataTableDetail from "./DataTableDetail";
import SpyListDataTable, { createSpyListButtonCell, createSpyListRow, createSpyListTextCell } from "./SpyListDataTable";
import ServiceDetailList from "./serviceDetailList";
import AssignServiceManagerDialog from "../../dialogs/service/AssignServiceManagerDialog ";
import CaseManager from "../../models/CaseManager";
import { useNavigate } from "react-router-dom";
import Member from "../../models/Member";
import { DepartVO } from "../../apis/MemberAPI";

/**
 * 작성자: 김혜정
 * 작성일: 2023.03.010
 *
 * 서비스 페이지 목록 > 서비스 상세
 **/

const ServiceSpyList = ({
  spyList,
  statCd,
}: {
  spyList: SpyList[];
  statCd: string,
}) => {
  const [serviceMenuList, setServiceMenuList] = useState([] as SpyList[]);
  // const { showDialog } = useDialog();
  const {member} = useMember()

 

  // 서비스 상세 불러오기
  useEffect(() => {
    setServiceMenuList(spyList);
    // spyList
  },[]);

  return(
    <Table
      list={spyList}
      deptCd={(member as Member).depart!.deptCd}
      statCd={statCd}
      // page={Number(page)}
      // totalPageCount={Math.ceil(count / 10)}
      // onPageChange={handlePageChange}
      // member={member as Member}
    />
  );
};

   const Table =({
    list,
    deptCd,
    statCd,
    // page,
    // onPageChange,
    // totalPageCount,
   }: {
    list: SpyList[];
    deptCd: string,
    statCd: string,
    // page: number;
    // totalPageCount: number;
    // onPageChange: (page: number) => void;
   }) => {
     
const { showDialog } = useDialog();
  const [stopReqSpy, setStopReqSpy] = useState([] as number[])

  const isStopReq = (spyUid: number) => {
    setStopReqSpy((cur) => [...cur, spyUid])
  }

  const handleAssignServiceManager = (service: SpyList) => {
    if(service.status === "서비스접수" || (service.status === "서비스중" && service.currentTimes === 0)) {
      showDialog(AssignServiceManagerDialog, {
        spyUid: service.spyUid,
        syUid: service.syUid,
        initialManagers: service.mbrName ? [{mbrUid : service.mbrUid, userName : service.mbrName}] : [],
        depart: service.departName,
        dptUid: service.dptUid,
      });
    }
  };

  const navigate = useNavigate();
  const handleAction = (service: SpyList) => {
    const trgterCd = service.trgterNm + service.brthdy.replaceAll('-', '').slice(2)
    const params = new URLSearchParams({
      spyUid: `${service.spyUid}`,
      trgterCd: `${trgterCd}`,
      siUid: `${service.siUid}`,
      mbrUid: `${service.mbrUid}`,
    })
    switch (service.status) {
      case "서비스중":
        navigate(`/service/serviceResult?${params}`);
        break;
    }
  };
    return (
      
        // 구
        <>
        {deptCd !== "SI" ?
        list.length > 0 ?
        <SpyListDataTable
          spyUid={list[0].spyUid}
          expandable
          columnTitles={[
            "서비스종류",
            "제공기관",
            "지원시간",
            "지원기간",
            "제공횟수",
            "서비스비용",
            "담당자",
            "상태",
            "목표달성여부",
          ]}
          rows={list.map((spyList) => createSpyListRow({
            cells: [
              createSpyListTextCell({
                isEmergency: spyList.cateCd === "8" ? true : false,
                text: spyList.serviceNm,
                isStop: spyList.status === "중단" ? true : false
              }),
              createSpyListTextCell({ text: spyList.departName }),
              createSpyListTextCell({
                text: spyList.cycleUnit && spyList.weekDay !== null
                  ? `${spyList?.cycleUnit} / ${spyList?.weekDay}`
                  : "-"
              }),
              createSpyListTextCell({
                text: spyList.beginDt || spyList.endDt === null
                  ? "-"
                  : `${spyList?.beginDt} ~ ${spyList?.endDt}`
              }),
              createSpyListTextCell({ text: `${spyList?.currentTimes} / ${spyList?.totalCount !== 0 ? spyList?.totalCount : spyList?.currentTimes}` }),
              createSpyListTextCell({ text: spyList.incomeLevel }),
              createSpyListTextCell({
                text: spyList.mbrName !== null
                  ? spyList.mbrName
                  : "-"
              }),
              createSpyListTextCell({ text: spyList.status }),
              createSpyListTextCell({
                text: spyList.status === "중단" ?
                  (spyList.stopRsn === null ? spyList.status : `${spyList.status} > ${spyList.stopRsn}`) :
                  (spyList.status === "서비스제공완료" ? "완료" : "-")
              }), // 목표달성여부
            ],
            expandContent: (
                <ServiceDetailList
                  spyUid={spyList.spyUid}
                  csUid={spyList.csUid}
                  isStopReq={isStopReq} />
            ),
            isStopReq: stopReqSpy.find((spy) => spy === spyList.spyUid) ? true : false,
          })
          )}
          statCd={statCd} /> : null
          :
          list.length > 0 ?
          <SpyListDataTable
          spyUid={list[0].spyUid}
          expandable
          columnTitles={[
            "서비스종류",
            "제공기관",
            "지원시간",
            "지원기간",
            "제공횟수",
            "서비스비용",
            "담당자",
            "상태",
            "",
            "목표달성여부",
          ]}
          rows={list.map((spyList) => createSpyListRow({
            cells: [
              createSpyListTextCell({
                isEmergency: spyList.cateCd === "8" ? true : false,
                text: spyList.serviceNm,
                isStop: spyList.status === "중단" ? true : false
              }),
              createSpyListTextCell({ text: spyList.departName }),
              createSpyListTextCell({
                text: spyList.cycleUnit && spyList.weekDay !== null
                  ? `${spyList?.cycleUnit} / ${spyList?.weekDay}`
                  : "-"
              }),
              createSpyListTextCell({
                text: spyList.beginDt || spyList.endDt === null
                  ? "-"
                  : `${spyList?.beginDt} ~ ${spyList?.endDt}`
              }),
              createSpyListTextCell({ text: `${spyList?.currentTimes} / ${spyList?.totalCount !== 0 ? spyList?.totalCount : spyList?.currentTimes}` }),
              createSpyListTextCell({ text: spyList.incomeLevel }),
              // createSpyListTextCell({
              //   text: spyList.mbrName !== null
              //     ? spyList.mbrName
              //     : "-"
              // }),
              createSpyListButtonCell({
              type:
              spyList.mbrName === null
                  ? "outlined"
                  : "underlined",
              label: spyList.mbrName
                ? spyList.mbrName!
                : "배정",
              onClick: () => handleAssignServiceManager(spyList),
            }),
              createSpyListTextCell({ text: spyList.status }),
              createSpyListButtonCell({
                type:
                spyList.status === "서비스중"
                    ? "outlined"
                    : undefined,
                label: spyList.status === "서비스중" ? "결과보고" : "",
                onClick: () => handleAction(spyList),
              }),
              createSpyListTextCell({
                text: spyList.status === "중단" ?
                  (spyList.stopRsn === null ? spyList.status : `${spyList.status} > ${spyList.stopRsn}`) :
                  (spyList.status === "서비스제공완료" ? "완료" : "-")
              }), // 목표달성여부
            ],
            expandContent: (
                <ServiceDetailList
                  spyUid={spyList.spyUid}
                  csUid={spyList.csUid}
                  isStopReq={isStopReq} />
            ),
            isStopReq: stopReqSpy.find((spy) => spy === spyList.spyUid) ? true : false,
          })
          )}
          statCd={statCd} /> : null
        }
        </>
        
        
        
    )
  
  
  }



export default ServiceSpyList;
