import React from 'react';
import styles from "./SelectLocation.module.css"
import Arrow from "../../../assets/ic_arrow.png"
import useMember from '../../../hooks/useMember';
import { useNavigate } from "react-router-dom";
import { LocationType } from './LoginPage';

const SelectLocation = (
    {
        setSelectedLocation,
    }
    :
    {
        setSelectedLocation: Function
    }
) => {
const { member, selectLocation, logout } = useMember()
const navigate = useNavigate()

const onClick = (location:LocationType) => {
    switch (location) {
        case "SEOGU" :
            setSelectedLocation(location)
            break;
        default :
            break;
    }
}
    
    return (
            <div id={styles["Wrapper"]}>
                <div className={styles.wrapper}>
                    <div className={styles.visual}>
                        <div className={styles.title_inner}>
                            <h1>Log In</h1>
                            <p>
                                로그인 할 지역을<br/>
                                선택해주세요.
                            </p>
                        </div>
                        <div className={styles.logo_inner}>
                            <a href="#" aria-label="홈으로" className={styles.logo}>AI토탈케어플랫폼</a>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.content_inner}>
                            <div className={styles.loginBtn_box}>
                                <a href="#" className={styles.login01}>접속하기<img src={Arrow} aria-label="바로가기 아이콘"></img></a>
                                <a href="#" className={styles.login02}>접속하기<img src={Arrow} aria-label="바로가기 아이콘"></img></a>
                                <a href="#" className={styles.login03}>접속하기<img src={Arrow} aria-label="바로가기 아이콘"></img></a>
                                <a href="#" className={styles.login04}>접속하기<img src={Arrow} aria-label="바로가기 아이콘"></img></a>
                                <a href="#" className={styles.login05} onClick={() => onClick("SEOGU")}>접속하기<img src={Arrow} aria-label="바로가기 아이콘"></img></a>
                            </div>
                            {/* <div className={styles.terms_box}>
                                <a href="#" onClick={logout}>로그아웃</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default SelectLocation;