/* eslint-disable react-hooks/rules-of-hooks */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSSProperties } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import DollbomPlanAPI, { PrevPlans } from "../../../apis/DollbomPlanAPI";
import DollbomPlanSupportAPI from "../../../apis/DollbomPlanSupportAPI";

import BackIcon from "../../../assets/ic_backlist.png";
import Popup from "../../../assets/ic_popup.png";
import { useDialog } from "../../../dialogs/DialogProvider";
import DollbomTargetInfoDialog from "../../../dialogs/service-plan/DollbomTargetInfoDialog";
import SprtMoreTargetInfoDialog from "../../../dialogs/sprt-more/SprtMoreTargetInfoDialog";
import NeedsAssessmentJoin from "../../../models/NeedsAssessmentJoin";
import ServicePlanDetails from "../../../models/ServicePlanDetails";
import SprtMoreData from "../../../models/SprtMoreData";
import "../sprt-more/SprtMorePage.scss";
import UseServiceTab from "../service-plan/DollbomPlanComponents/TabTableComponents/UseServiceTab";
import ServiceResult from "../service-plan/DollbomPlanComponents/ServiceResult";
import NeedAsTabTable1 from "../service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable1";
import NeedAsTabTable2 from "../service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable2";
import NeedAsTabTable3 from "../service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable3";
import NeedAsTabTable4 from "../service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable4";
import NeedAsTabTable5 from "../service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable5";
import NeedAsTabTable6 from "../service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable6";
import NeedAsBottom from "../service-plan/DollbomPlanComponents/TabTableComponents/NeedAsBottom";

/**
 * 작성자: 주하영
 *
 *
 * 추가지원검토 승인 페이지
 */
interface DollbomSupportFormInput {
  syUid?: number;
  caseContent?: string;
  sprtMoreNssr?: string;
  sprtMoreUse?: string;
  expnNss?: string;
  expnUse?: string;
  mtOpnn?: string;
  cfmYn?: string;
  cfmRsn?: string;
}

const SprtMoreAdmissionPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState(0);
  const [tab2, setTab2] = useState(0);
  const { page } = useParams();
  const [details, setDetails] = useState<ServicePlanDetails>();
  const [sprtData, setSprtData] = useState<SprtMoreData>();
  const [evaluationData, setEvaluationData] = useState<NeedsAssessmentJoin>();
  // 만 나이 계산
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);
  const [targetBrthdy, setTargetBrthdy] = useState(0);
  const [targetMonth, setTargetMonth] = useState(0);
  const [targetDay, setTargetDay] = useState(0);

  // 질병 및 증상 추가한거
  const [illAddList, setIllAddList] = useState<string[]>([]);
  //이전 돌봄계획 데이터
  const [prevPlans, setPrevPlans] = useState<PrevPlans>({} as PrevPlans);
  const [prevPlansLoading, setPrevPlansLoading] = useState(false)

  const syUid = searchParams.get("syUid");
  const csUid = searchParams.get("csUid");
  const stUid = searchParams.get("stUid");

  const { register, handleSubmit, watch, setValue } = useForm<DollbomSupportFormInput>();

  useEffect(() => {
    DollbomPlanAPI.getDetails({
      syUid: Number(syUid),
    }).then((res) => {
      setDetails(res);
      // console.log("details ::::::::: ", res)

      setTargetBrthdy(Number(res.targetInfo.brthdy.split("-")[0]));
      setTargetMonth(Number(res.targetInfo.brthdy.split("-")[1]));
      setTargetDay(Number(res.targetInfo.brthdy.split("-")[2]));
    })
  }, [page])

  // 돌봄필요도 평가지 데이터 불러오는 API
  useEffect(() => {
    DollbomPlanAPI.getEvaluationData({
      csUid: Number(csUid),
    }).then((res) => {
      setEvaluationData(res);
      setIllAddList(JSON.parse(res.jsonData.illAdd));
      // console.log(res.jsonData.illAdd);
      // console.log(res);
    });

    DollbomPlanAPI.prevPlans({
      stUid: Number(stUid),
      syUid: Number(syUid),
    }).then((res) => {
      // console.log("이전 돌봄계획 : ", res)
      setPrevPlans(res)
      setPrevPlansLoading(true)
    })
  }, []);

  // 만 나이 계산
  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = Number(("0" + (now.getMonth() + 1)).slice(-2));
    const day = Number(("0" + now.getDate()).slice(-2));
    setYear(year);
    setMonth(month);
    setDay(day);
  }, []);

  useEffect(() => {
    DollbomPlanSupportAPI.getSprtMoreData({
      syUid: Number(syUid),
    }).then((res) => {
      setSprtData(res)
      // console.log("sprtMoreData ::::::::: ", res);
    });
  }, [page])

  const onSubmit: SubmitHandler<DollbomSupportFormInput> = async (data) => {
    if (window.confirm('"추가지원검토서"를 등록하시겠습니까?')) {
      // TODO: 회원 정보 수정 구현
      // console.log(">>>> data >>>>", data)
      if (!data.cfmYn) {
        alert('"승인결과"를 선택해 주세요.')
        return;
      }
      const success = await DollbomPlanSupportAPI.update({
        ...data,
        syUid: Number(syUid)
      })
      if (success) {
        alert("추가지원 검토서가 등록되었습니다.");
        // console.log(">>> success");
        navigate(-1);
      } else {
        // console.log(">>> failure");
      }
    }
  };

  const handleBackPressed = () => {
    navigate(-1);
  };

  return (
    <main className="sprt-more">
      <section>
        <article className="detail_top">
          <div className="nav_list">
            <p>
              추가지원검토 / <span>승인</span>
            </p>
            <button type="button" className="mt8" onClick={handleBackPressed}>
              <span>
                <img src={BackIcon} alt="목록으로 돌아가기 아이콘" />
              </span>
              목록으로 돌아가기
            </button>
          </div>
          <div className="btn_wrap">
            <button type="button" className="submit" onClick={handleSubmit(onSubmit)}>
              저장
            </button>
          </div>
        </article>
        <Info
          syUid={Number(syUid)}
          targetInfo={details} sprtData={sprtData}
          year={year}
          month={month}
          day={day}
          targetBrthdy={targetBrthdy}
          targetMonth={targetMonth}
          targetDay={targetDay}
        />
        <article className="care_plan mt20">
          <div className="left mr20">
            {/* 추가지원 검토서 */}
            <Addsprt sprtData={sprtData} />
            {prevPlansLoading && 
            <div className="care_tab mt20">
              {/* 탭 */}
              <Tap tab={tab} setTab={setTab} />
              {/* 서비스 결과 및 비용 지원 */}
              {tab === 0 && (prevPlans.prevPlanBox ? <ServiceResult prevPlans={prevPlans} isSprtMore='N' />
               : <div className="service_result mt20">
                  <div className="padding">
                    이전 돌봄계획 없음
                  </div>
                </div>)}
              {/* 이용중인 서비스 */}
              {tab == 1 && <UseServiceTab details={details!}/>}
              {/* 돌봄필요도 평가 */}
              {tab == 2 && <CareTab tab2={tab2} setTab2={setTab2} evaluationData={evaluationData} illAddList={illAddList} />}
            </div>
            }
          </div>
          {/* 승인여부 */}
          <div className="right">
            <div className="support">
              <h4>승인여부</h4>
              <div className="text_input">
                <p>승인결과</p>
                <div className="input_wrap">
                  <div className="input_box" style={{ width: "100%" }}>
                    <label htmlFor="radio90" className="mr20">
                      <input
                        type="radio"
                        id="radio90"
                        value="Y"
                        {...register("cfmYn")}

                      />
                      지원승인
                    </label>
                    <label htmlFor="radio91">
                      <input
                        type="radio"
                        id="radio91"
                        value="N"
                        {...register("cfmYn")}

                      />
                      지원불가
                    </label>
                  </div>
                </div>
              </div>
              <div className="text_input mt20">
                <p>사유</p>
                <div className="input_wrap">
                  <div className="input_box" style={{ width: "100%" }}>
                    <textarea placeholder="필요시 사유 입력" defaultValue={""} {...register("cfmRsn")} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Support /> */}
          {/* <OpinionWrite /> */}
        </article>
      </section>
    </main>
  );
};
const Info = ({
  syUid,
  targetInfo,
  sprtData,
  year,
  month,
  day,
  targetBrthdy,
  targetMonth,
  targetDay
}: {
  syUid?: number;
  targetInfo?: ServicePlanDetails;
  sprtData?: SprtMoreData;
  year: number;
  month: number;
  day: number;
  targetBrthdy: number;
  targetMonth: number;
  targetDay: number;
}) => {
  const { showDialog } = useDialog();

  const handleSprtMoreTargetInfoDialog = (sprtData: SprtMoreData, targetInfo: ServicePlanDetails) => {
    showDialog(SprtMoreTargetInfoDialog, {
      sprtData,
      targetInfo,
      syUid: Number(syUid),
    });
  };
  return (
    <article className="info">
      <ul>
        {/* <li className="btn_edit">
          <button
            className="btn_gray"
            onClick={() =>
              handleSprtMoreTargetInfoDialog(
                sprtData as SprtMoreData,
                targetInfo as ServicePlanDetails
              )
            }
          >수정</button>
        </li> */}
        <li>
          <p className="gray">성명</p>
          <p>{targetInfo?.targetInfo.trgterNm}</p>
        </li>
        <li>
          <p className="gray">생년월일</p>
          <p>
            {targetInfo?.targetInfo.brthdy}
            {targetMonth >= month && targetDay >= day
              ? `(만 ${year - targetBrthdy - 1}세)`
              : `(만 ${year - targetBrthdy - 2}세)`
            }
          </p>
        </li>
        <li>
          <p className="gray">성별</p>
          <p>{targetInfo?.targetInfo.gender}</p>
        </li>
        <li>
          <p className="gray">연락처</p>
          <p>{targetInfo?.targetInfo.telno}</p>
        </li>
        <li className="x3">
          <p className="gray">주소</p>
          <p>{targetInfo?.targetInfo.fullAddress}</p>
        </li>
      </ul>
    </article>
  );
};
// 추가지원 검토서
const Addsprt = ({ sprtData }: { sprtData?: SprtMoreData; }) => {
  return (
    <div className="care_plan_write">
      <div className="padding">
        <h4>사례내용</h4>
        <table>
          <tbody>
            <tr>
              <th style={{ width: "280px" }}>사례내용</th>
              <td>{sprtData?.caseContent}</td>
            </tr>
          </tbody>
        </table>
        <h4 className="mt20">추가지원 검토서</h4>
        <table>
          <tbody>
            <tr>
              <th rowSpan={2} style={{ borderRight: "1px solid #e6e8ea" }}>
                연간 지원한도액
                <br />
                추가 지원 검토의견
              </th>
              <th>필요성</th>
              <td>{sprtData?.sprtMoreNssr}</td>
            </tr>
            <tr>
              <th>
                추가 지원액
                <br />
                주요 사용처
              </th>
              <td>{sprtData?.sprtMoreUse}</td>
            </tr>
            <tr>
              <th rowSpan={2} style={{ borderRight: "1px solid #e6e8ea" }}>
                광주다움 통합돌봄
                <br />
                사례관리 사업비
                <br />
                사용 검토의견
              </th>
              <th>필요성</th>
              <td>{sprtData?.expnNss}</td>
            </tr>
            <tr>
              <th>
                사례관리 사업비
                <br />
                주요 사용처
              </th>
              <td>{sprtData?.expnUse}</td>
            </tr>
          </tbody>
        </table>
        <h4 className="mt20">권역별 사례회의 의견</h4>
        <table>
          <tbody>
            <tr>
              <th style={{ width: 280 }}>주요의견</th>
              <td>{sprtData?.mtOpnn}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
// 탭 버튼
const Tap = ({
  tab,
  setTab,
}: {
  tab: number;
  setTab: (tab: number) => void;
}) => {
  return (
    // 탭
    <div className="tabs">
      {/* 탭 3개일때 tab_count3 4개일때 tab_count4 5개일때 tab_count5  */}
      <button
        type="button"
        className={`tab_count3 ${tab === 0 ? "tab_active" : "non_active"}`}
        onClick={() => setTab(0)}
        data-id={0}
      >
        서비스 결과 및 비용 지원
      </button>
      <button
        type="button"
        className={`tab_count3 ${tab === 1 ? "tab_active" : "non_active"}`}
        onClick={() => setTab(1)}
        data-id={1}
      >
        이용중인 서비스
      </button>
      <button
        type="button"
        className={`tab_count3 ${tab === 2 ? "tab_active" : "non_active"}`}
        onClick={() => setTab(2)}
        data-id={2}
      >
        돌봄필요도 평가
      </button>
    </div>
  );
};
// 돌봄필요도 평가
const CareTab = ({
  tab2,
  setTab2,
  evaluationData,
  illAddList,
}: {
  tab2: number;
  setTab2: (tab2: number) => void;
  evaluationData?: NeedsAssessmentJoin;
  illAddList: string[];
}) => {
  return (
    <div className="padding tab_content" data-id={2}>
      <div className="care_evaluation">
        {/* 탭 */}
        <div className="tabs2">
          <button
            type="button"
            className={`tabs2
                        ${tab2 === 0 ? "tab_active" : "non_active"} `}
            onClick={() => setTab2(0)}
            data-id={0}
          >
            신체적 영역
          </button>
          <button
            type="button"
            className={`tabs2 
                        ${tab2 === 1 ? "tab_active" : "non_active"} `}
            onClick={() => setTab2(1)}
            data-id={1}
          >
            정서적 영역
          </button>
          <button
            type="button"
            className={`tabs2 
                        ${tab2 === 2 ? "tab_active" : "non_active"} `}
            onClick={() => setTab2(2)}
            data-id={2}
          >
            추가정보(생활환경)
          </button>
          <button
            type="button"
            className={`tabs2 
                        ${tab2 === 3 ? "tab_active" : "non_active"} `}
            onClick={() => setTab2(3)}
            data-id={3}
          >
            추가정보(사전검사)
          </button>
          <button
            type="button"
            className={`tabs2 
                        ${tab2 === 4 ? "tab_active" : "non_active"} `}
            onClick={() => setTab2(4)}
            data-id={4}
          >
            광주+돌봄 지원대상 여부
          </button>
          <button
            type="button"
            className={`tabs2 
                        ${tab2 === 5 ? "tab_active" : "non_active"} `}
            onClick={() => setTab2(5)}
            data-id={5}
          >
            긴급돌봄 지원대상 여부
          </button>
        </div>
        {/* 신체적 영역 */}
        {tab2 === 0 && <NeedAsTabTable1 evaluationData={evaluationData} illAddList={illAddList} />}

        {/* 정서적 영역 */}
        {tab2 === 1 && <NeedAsTabTable2 evaluationData={evaluationData} />}

        {/* 추가정보(생활환경) */}
        {tab2 === 2 && <NeedAsTabTable3 evaluationData={evaluationData} />}

        {/* 추가정보(사전검사) */}
        {tab2 === 3 && <NeedAsTabTable4 evaluationData={evaluationData} />}

        {/* 광주+돌봄 지원대상 여부 */}
        {tab2 === 4 && <NeedAsTabTable5 evaluationData={evaluationData} />}

        {/* 긴급돌봄 지원대상 여부 */}
        {tab2 === 5 && <NeedAsTabTable6 evaluationData={evaluationData} />}

        <NeedAsBottom evaluationData={evaluationData!}/>
      </div>
    </div>
  );
};
// 추가승인
const Support = () => {
  return (
    <div className="right">
      <div className="support">
        <h4>승인여부</h4>
        <div className="text_input">
          <p>승인결과</p>
          <div className="input_wrap">
            <div className="input_box" style={{ width: "100%" }}>
              <label htmlFor="radio90" className="mr20">
                <input type="radio" id="radio90" name="radio" defaultChecked />
                지원승인
              </label>
              <label htmlFor="radio91">
                <input type="radio" id="radio91" name="radio" />
                지원불가
              </label>
            </div>
          </div>
        </div>
        <div className="text_input mt20">
          <p>사유</p>
          <div className="input_wrap">
            <div className="input_box" style={{ width: "100%" }}>
              <textarea placeholder="필요시 사유 입력" defaultValue={""} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SprtMoreAdmissionPage;
