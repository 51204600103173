import moment from "moment";
import NeedsAssessmentJoin from "../../../../../models/NeedsAssessmentJoin";

const NeedAsTabTable5 = ({
    evaluationData,
  }: {
    evaluationData?: NeedsAssessmentJoin;
  }) => {
    const bodyPt = Number(evaluationData?.counselResult.bodyAreaPt);
    const emtPt = Number(evaluationData?.counselResult.emtAreaPt);
    const beginDt = moment(evaluationData?.counselResult.beginDt).format(
      "YYYY.MM.DD(dd)"
    );
    return (
      <div className="tab_content" data-id={4}>
        <p className="date">평가일 : {beginDt}</p>
        <div className="answer_content mt20">
          <div className="cont_top">
            <div className="cont_tit">
              <h4>① 신체적·정서적 기능 저하</h4>
              <p>혼자 거동하기 어렵거나, 독립적인 일상생활 수행이 어려운 정도</p>
            </div>
            <div className="cont_input">
              <p>신체적 <span className={bodyPt >= 60 ? "yes" : ""}>{bodyPt}</span>점 / 정서적 <span className={emtPt >= 60 ? "yes" : ""}>{emtPt}</span>점</p>
              <div className="input_inner">
                <span>
                  <input type="radio" id="radio111-1" defaultChecked={true} disabled
                  />
                  <label htmlFor="radio111-1" defaultChecked>
                    충족
                  </label>
                </span>
                <span>
                  <input type="radio" id="radio111-2" disabled
                  />
                  <label htmlFor="radio111-2">
                    미충족
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div className="cont_bot mt10">
            <textarea
              placeholder="상세내용 입력"
              readOnly
              defaultValue={evaluationData?.jsonData.g1_content}
            />
          </div>
        </div>
        <div className="answer_result mt20">
          <div className="cont_top">
            <div className="cont_tit">
              <h4>② 돌볼 수 있는 사람 부재</h4>
              <p>돌볼 수 있는 가족이 없거나, 있어도 돌볼 수 없는지 여부</p>
            </div>
            <div className="cont_input">
              <p>{evaluationData?.jsonData.g2_type}</p>
            </div>
          </div>
          <div className="cont_bot mt10">
            <textarea
              placeholder="상세내용 입력"
              readOnly
              defaultValue={evaluationData?.jsonData.g2_content}
            />
          </div>
        </div>
        <div className="answer_result mt20">
          <div className="cont_top">
            <div className="cont_tit">
              <h4>③ 서비스 공백</h4>
              <p>
                기존 돌봄서비스를 이용할 수 없거나, 이용을 기다리고 있거나, 이용
                중 공백이 발생하였는지 여부
              </p>
            </div>
            <div className="cont_input">
              <p>{evaluationData?.jsonData.g3_type}</p>
            </div>
          </div>
          <div className="cont_bot mt10">
            <textarea
              placeholder="상세내용 입력"
              readOnly
              defaultValue={evaluationData?.jsonData.g3_content}
            />
          </div>
        </div>
      </div>
    );
  };

export default NeedAsTabTable5