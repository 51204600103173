import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import MemberAPI, { DepartVO, UpdResult } from "../apis/MemberAPI";
import { nameValid, telValid, ValidCheck } from "../App";

import CloseIcon from "../assets/ic_close.png";
import DefaultProfileImage from "../assets/img_default.png";
import useMember from "../hooks/useMember";
import Member from "../models/Member";
import FileUtil from "../utils/FileUtil";
import { DialogProps, useDialog } from "./DialogProvider";
import FileUploadAPI from "../apis/FileUploadAPI";
import ProfileImageDialog from "./ProfileImageDialog";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.21
 *
 * 프로필 수정 팝업
 */
interface EditProfileFormInput {
  files: FileList;
  mbrUid: number;
  dptUid: number;
  userName: string;
  caseChargeYn: string;
  tel1: string;
  tel2: string;
  tel3: string;
  depart: DepartVO;
}

interface EditProfileDialogProps extends DialogProps {
  member : Member,
}

const EditProfileDialog = ({member, ...props} : EditProfileDialogProps) => {
  const [image, setImage] = useState<string>();
  const [isCamOpen, setCamOpen] = useState(false);
  const [editImage, setEditImage] = useState<File>()
  const [loadFile, setLoadFile] = useState<File>()
  const { showDialog } = useDialog();

  const { register, handleSubmit, watch, reset, setError, formState : {errors}, getValues } =
    useForm<EditProfileFormInput>({
      defaultValues: {
        mbrUid: member.mbrUid,
        dptUid: member.dptUid,
        userName: member.userName,
        depart: member.depart,
        caseChargeYn: member.caseChargeYn,
        tel1: member.phone?.split('-')[0],
        tel2: member.phone?.split('-')[1],
        tel3: member.phone?.split('-')[2],
      },
    });
  const files = watch("files");
  const nickValid = /^[가-힣\s]+$/
  const [inputCheck, setInputCheck] = useState<ValidCheck>({stat: true, msg: ""})
  const paramCheck = () => {
    if(!nickValid.test(getValues("userName"))) {
      return {stat: false, msg: "올바른 닉네임을 입력해주세요."}
    } else if(!telValid.test(getValues("tel2"))) {
      return {stat: false, msg: "올바른 연락처를 입력해주세요."}
    } else if(!telValid.test(getValues("tel3"))) {
      return {stat: false, msg: "올바른 연락처를 입력해주세요."}
    } else {
      return {stat: true, msg : ""}
    }
  }
  const nameValidation = (e:any) => {
    nickValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }
  const telValidation = (e:any) => {
    telValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }
  const [departList, setDepartList] = useState([] as DepartVO[])
  const [loading, setLoading] = useState(true)

  const [filePath, setFilePath] = useState<string>()

  const {setMember} = useMember()
  const navigate = useNavigate();
  useEffect(() => {
    MemberAPI.getAllDept().then((res) => {
      // console.log(res)
      setDepartList(res)
      setLoading(false)
    })
    FileUploadAPI.getFile({
      uid: member?.mbrUid as number,
      folder: "USER",
    }).then((res) => {
      // console.log(res)
      setLoadFile(new File([res], "fileName", { type: res.type, lastModified: new Date().getTime() }))
      setImage(window.URL.createObjectURL(res))
    })
    FileUploadAPI.getFilePath({
      uid: member?.mbrUid as number,
      folder: "USER",
    }).then((res) => {
      // console.log(res)
      setFilePath(res)
    })
  }, [])

  const data = watch()
  useEffect(() => {
    // console.log(data)
  }, [data])

  // 파일 변화를 감지하여 이미지 설정
  useEffect(() => {
    const file = files?.item(0);
    if (file) {
      setEditImage(file)
      FileUtil.getFilePath(file).then(setImage);
    }
  }, [files]);

  const handleClose = () => {
    props.onClose?.();
  };

  const handleCamOpen = () => {
    setCamOpen(!isCamOpen);
  };

  const handleProfileClick = () => {
    showDialog(ProfileImageDialog, { image: editImage ?? loadFile });
  };

  const handleFileDelete = async () => {
    let resultNum = await FileUploadAPI.deleteFile({
      filePath: filePath,
      uid: member?.mbrUid as number,
      folder: "USER",
    })

    if(resultNum === 0) {
      window.alert("사진이 없습니다.")
      return
    } else {
      setFilePath(undefined)
    }

    // 파일 삭제 시 input 태그 내부에 남아있는 files 삭제
    const dataTransfer = new DataTransfer();
    reset({ files: dataTransfer.files });
    setImage(undefined);
  };

  const handleImageError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    e.currentTarget.src = DefaultProfileImage;
  };

  const onSubmit: SubmitHandler<EditProfileFormInput> = async (data) => {
    // TODO: 회원 정보 수정 구현
    // console.log(data);
    let checkObj = paramCheck()
    if(!checkObj.stat) {
      window.alert(checkObj.msg)
      return
    }

    let rst:UpdResult = await MemberAPI.update({
      mbrUid: data.mbrUid,
      userName: data.userName,
      dptUid: data.dptUid,
      phone: `${data.tel1}-${data.tel2}-${data.tel3}`,
      tel: `${data.tel1}-${data.tel2}-${data.tel3}`,
    })

    if(rst.success) {
      if(filePath) {
        handleFileDelete()
      }
      if(editImage) {
        FileUploadAPI.fileUpload({
          attachFile: editImage,
          folder: "USER",
          fileGbn: "IMAGE",
          uid: member.mbrUid,
        })
      }
      window.alert(rst.message)
      member.phone = `${data.tel1}-${data.tel2}-${data.tel3}`
      member.userName = data.userName
      localStorage.setItem("member", JSON.stringify(member))
      setMember(member)
      navigate(0);
      // console.log(member)
    } else {
      window.alert("수정 실패")
    }

  };

  const onError = () => {
    if(errors.userName) {
      window.alert(errors.userName.message)
    } else if(errors.tel2) {
      window.alert(errors.tel2?.message)
    } else if(errors.tel3) {
      window.alert(errors.tel3?.message)
    }
  }

  return (
    <div className="mypage_popup popup_black" {...props}>
      <div className="mypage_popup_inner popup_white">
        <div className="popup_tit">
          <h3>내 정보</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="info_img">
              <div className="img_wrap">
                <div className="img">
                  <img
                    src={image ?? DefaultProfileImage}
                    alt="프로필 이미지"
                    onError={handleImageError}
                    onClick={handleProfileClick}
                  />
                </div>
                <a href="#" className="cam" onClick={handleCamOpen}></a>
                <div className={`cam_popup ${isCamOpen ? "" : "hide"}`}>
                  <span className="ico-arrow"></span>
                  <span className="ico-arrow2"></span>
                  <label htmlFor="img_register">
                    <input
                      // ref={fileElement}
                      type="file"
                      id="img_register"
                      accept="image/*"
                      style={{ display: "none" }}
                      // onChange={handleFileChange}
                      {...register("files")}
                    />
                    사진 등록
                  </label>
                  <label htmlFor="img_delete">
                    <input
                      type="button"
                      id="img_delete"
                      style={{ display: "none" }}
                      onClick={handleFileDelete}
                    />
                    사진 삭제
                  </label>
                </div>
              </div>
            </div>
            {/* <div className="default_input group">
              <h4>분류</h4>
              <div className="write_box">
                <select className="readonly" disabled>
                  <option value="0">선택</option>
                  <option value="1">동관리자</option>
                  <option value="2" selected>
                    최고관리자
                  </option>
                </select>
              </div>
            </div> */}
            <div className="default_input name">
              <h4>이름</h4>
              <div className="write_box">
                <input
                  type="text"
                  placeholder="이름을 입력하세요"
                  {...register("userName", {
                    required: "이름을 입력해주세요.",
                      onChange: (e:any) => {
                        nameValidation(e)
                      }
                  })}
                />
              </div>
            </div>
            <div className="default_input institution">
              <h4>소속</h4>
              <div className="write_box">
                {loading ? null :
                  <select className="readonly" disabled {...register("depart.departName")}>
                    <option value="0">선택</option>
                    {departList.map((depart, idx) => (
                      <option key={idx} value={depart.departName}>{depart.departName}</option>
                    ))}
                    {/* <option value="SI">
                      시
                    </option>
                    <option value="GU">
                      구
                    </option>
                    <option value="DONG">동</option>
                    <option value="ORG">제공기관</option> */}
                  </select>
                }
                
                <select className="readonly mt8" disabled {...register("caseChargeYn")}>
                  <option value="0">선택</option>
                  <option value="Y" selected>
                    팀장
                  </option>
                  <option value="N">담당자</option>
                </select>
              </div>
            </div>
            <div className="default_input tel">
              <h4>연락처</h4>
              <div className="write_box">
                <select {...register("tel1")}>
                  <option value="010" selected>
                    010
                  </option>
                  <option value="011">011</option>
                  <option value="017">017</option>
                  <option value="018">018</option>
                  <option value="019">019</option>
                </select>
                <span>-</span>
                <input type="tel" maxLength={4} {...register("tel2", {
                  required: "연락처를 입력해주세요.",
                  onChange: (e:any) => {
                    if(!telValid.test(e.target.value)) {
                      e.target.value = String(e.target.value).slice(0, -1)
                    }
                    telValidation(e)
                  }
                })}/>
                <span>-</span>
                <input type="tel" maxLength={4} {...register("tel3", {
                  required: "연락처를 입력해주세요.",
                  onChange: (e:any) => {
                    if(!telValid.test(e.target.value)) {
                      e.target.value = String(e.target.value).slice(0, -1)
                    }
                    telValidation(e)
                  }
                })}/>
              </div>
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="submit" className="submit">
                저장
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProfileDialog;
