import React from 'react';
import NeedsAssessmentJoin from '../../../../../models/NeedsAssessmentJoin';

const NeedAsBottom = (
    {evaluationData}
    :
    {evaluationData : NeedsAssessmentJoin}
) => {
    return (
        <div className="short_answer2 mt20">
            <ul>
            <li>
                <h4>신체적 영역</h4>
                <p>
                {evaluationData?.counselResult?.bodyAreaPt != null
                    && evaluationData?.counselResult?.bodyAreaPt >= 60 ? (
                    <span className="yes">
                    {evaluationData?.counselResult.bodyAreaPt}
                    </span>
                ) : (
                    <span>{evaluationData?.counselResult.bodyAreaPt}</span>
                )}
                점
                </p>
            </li>
            <li>
                <h4>정서적 영역</h4>
                <p>
                {evaluationData?.counselResult?.emtAreaPt != null
                    && evaluationData?.counselResult?.emtAreaPt >= 60 ? (
                    <span className="yes">
                    {evaluationData?.counselResult.emtAreaPt}
                    </span>
                ) : (
                    <span>{evaluationData?.counselResult.emtAreaPt}</span>
                )}
                점
                </p>
            </li>
            <li>
                <h4>광주+돌봄 지원대상 여부</h4>
                <p>
                {evaluationData?.jsonData.gTargetYn === "지원대상" ? (
                    <span className="yes">
                    {evaluationData?.jsonData.gTargetYn}
                    </span>
                ) : (
                    <span>{evaluationData?.jsonData.gTargetYn}</span>
                )}
                </p>
            </li>
            <li>
                <h4>긴급돌봄 지원대상 여부</h4>
                <p>
                {evaluationData?.jsonData.eTargetYn === "지원대상" ? (
                    <span className="yes">
                    {evaluationData?.jsonData.eTargetYn}
                    </span>
                ) : (
                    <span>{evaluationData?.jsonData.eTargetYn}</span>
                )}
                </p>
            </li>
            </ul>
            <div className="opinion">
            <h4>종합의견</h4>
            <p>{evaluationData?.jsonData.general}</p>
            </div>
        </div>
    );
};

export default NeedAsBottom;