import { async } from "@firebase/util";
import axios from "axios";
import { plainToInstance } from "class-transformer";
import { response } from "express";
import { serialize } from "object-to-formdata";

import BaseDollbomTargetInfo from "../models/BaseDollbomTargetInfo";
import CareRequest from "../models/CareRequest";
import caseMeeting from "../models/CaseMeeting";
import carePlan from "../models/ConferencePlan";
import ConferencePlan from "../models/ConferencePlan";
import DongConference from "../models/DongConference";
import NeedsAssessmentJoin from "../models/NeedsAssessmentJoin";
import ServiceInfo from "../models/ServiceInfo";
import ServicePlanDetails from "../models/ServicePlanDetails";
import ServiceTargetYear from "../models/ServiceTargetYear";
import CareRequestFormInput from "../models/form/CareRequestFormInput";
import DollbomAreaConferenceFormInput from "../models/form/DollbomAreaConferenceFormInput";
import DollbomConfirmationFormInput from "../models/form/DollbomConfirmationFormInput";
import DollbomDongConferenceFormInput from "../models/form/DollbomDongConferenceFormInput";
import DollbomPlanFormInput from "../models/form/DollbomPlanFormInput";

type GetAllParams = {
  sdUid?: number;
  syUid?: number;
  csUid?: number;
};

const getAll = (params: GetAllParams) => {
  return axios
    .get("/api/servicePlan/carePlanServiceList", { params })
    .then((response) =>
      plainToInstance(ServicePlanDetails, response.data as ServicePlanDetails)
    );
};
const getEvaluationData = (params: GetAllParams) => {
  return axios
    .get("/api/servicePlan/evaluation/getEvaluationData", { params })
    .then((response) =>
      plainToInstance(NeedsAssessmentJoin, response.data as NeedsAssessmentJoin)
    );
};
const getDongConferencePlan = (params: GetAllParams) => {
  return axios
    .get("/api/servicePlan/getMeetingForm", { params })
    .then((response) =>
      plainToInstance(DongConference, response.data as DongConference)
    );
};
const getCareRequest = (params: GetAllParams) => {
  return axios
    .get("/api/servicePlan/getServiceList", { params })
    .then((response) =>
      plainToInstance(CareRequest, response.data as CareRequest)
    );
};

interface InsertParams {
  syUid: number;
  nextYn: string;
  stepCd: string;
  stepCdNext: string;
  servicePlanYears: DollbomConfirmationFormInput[];
}
interface insertConfirmation {
  syUid: number;
  nextYn: string;
  stepCd: string;
  stepCdNext: string;
  servicePlanYears: DollbomConfirmationFormInput[];
}
interface insertRequest {
  syUid: number;
  planYears: CareRequestFormInput[];
}
const insert = (params: InsertParams) => {
  // console.log(params);
  return axios
    .post("/api/servicePlan/planRegist", params)
    .then((response) => response.data.success as boolean);
};

const insertDongConference = (params: DollbomDongConferenceFormInput) => {
  // console.log(params);
  return axios
    .post("/api/servicePlan/meetingRegist", params)
    .then((response) => response.data.success as boolean);
};

const insertAreaConference = (params: DollbomDongConferenceFormInput) => {
  // console.log(params);
  return axios
    .post("/api/servicePlan/meetingRegist", params)
    .then((response) => response.data.success as boolean);
};
const insertConfirmation = (params: insertConfirmation) => {
  // console.log(params);
  return axios
    .post("/api/servicePlan/planRegist", params)
    .then((response) => response.data.success as boolean);
};
const insertRequest = (params: insertRequest) => {
  // console.log(params);
  return axios
    .post("/api/servicePlan/setOrigin", params)
    .then((response) => response.data.success as boolean);
};
interface UpdateParams {
  trgterNm: string;
  trgterCd?: string;
  brthdy: string;
  gender: string;
  telno: string;
  emgcTel?: string;
  adres: string;
  adresDtl: string;
  crtUid?: number;
  year?: number;
  incomeLevel?: string;
  incomeLevel2?: string | null;
}
interface UpdateTargetInfo extends UpdateParams {
  syUid: number;
  characterJson?: string;
}
const updateTarget = (params: UpdateTargetInfo) => {
  // params.characterJson = JSON.stringify(params.characterJson);
  // console.log("aaaa", params);
  return axios
    .post("/api/serviceTarget/update", serialize(params))
    .then((response) => response.data["success"] as boolean);
};

export type SimpleInfo = {
  trgterNm: string,
  trgterCd: string,
  brthdy: string,
  gender: string,
  telno: string,
  telno1: string,
  telno2: string,
  telno3: string,
  adres: string,
  adresDtl: string,
}

const updateSimpleInfo = (params: SimpleInfo) => {
  return axios
    .post("/api/serviceTarget/update", serialize(params))
    .then((res) => res.data["success"] as boolean);
};

//이전 돌봄계획 데이터 모델
export interface PrevPlans {
  lastSum: number,
  lastEmerSum: number,
  prevPlanInfos: PrevPlanInfo[],
  prevPlanBox: PrevPlan[][],
  safeSprtCharge: number,
  simpleFixCount: number,
  cleaningCount: number,
  pestControlCount: number,
  escortCount: number,
}

//이전 돌봄계획 건 당 가격 및 일자
export interface PrevPlanInfo {
  totalSum: number,
  totalEmerSum: number,
  prevDt: string,
  csUid: number,
}

//이전 돌봄계획 데이터
export interface PrevPlan {
  sdUid: number,
  spyUid: number,
  siUid: number,
  dptUid: number,
  stepCd: string,
  serviceStatCd: string,
  beginDate: string,
  endDate: string,
  cost: number,
  servieDetail: string,
  stopYn: string,
  providerOpinion: string,
  totalCount: number,
  cateCd: number,
}

interface PrevParams {
  stUid: number,
  syUid: number,
}

const prevPlans = (params: PrevParams) => {
  return axios
    .get("/api/servicePlan/prevPlans", {params})
    .then((res) => res.data as PrevPlans);
};

export interface ServiceDecisionVO {
  ServiceDecisionVO : {
    sdUid: number,
    syUid: number,
    stUid: number,
    dbStatCd: string,
    preDbStatCd: string,
    crtDt: string,
  }
}

const getCarePlanDate = (params: GetAllParams) => {
  return axios.get("/api/servicePlan/getCarePlanDate", {params})
  .then((res) => res.data as ServiceDecisionVO)
}

interface SiList {
  gjSiList: ConferencePlan[],
  emerSiList: ConferencePlan[],
  basicSiList: ConferencePlan[],
}

const getServiceInfo = () => {
  return axios.get("/api/servicePlan/getServiceInfo")
  .then((res) => res.data as SiList)
}

const DollbomPlanAPI = {
  getDetails: getAll,
  insert,
  getEvaluationData,
  insertDongConference,
  insertAreaConference,
  getDongConferencePlan,
  insertConfirmation,
  getCareRequest,
  insertRequest,
  updateTarget,
  updateSimpleInfo,
  prevPlans,
  getCarePlanDate,
  getServiceInfo,
};

export default DollbomPlanAPI;
