/* eslint-disable react-hooks/rules-of-hooks */
import clsx from "clsx";
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import ArrowIcon from "../../assets/ic_arrow.png";
import Pagination from "./Pagination";
import StopIcon from "../../assets/중단요청아이콘.png";
import DefaultProfileImage from "../../assets/img_default.png";
import FileUtil from "../../utils/FileUtil";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.23
 *
 * @example
 * <DataTable
 *   expandable
 *   columnTitles={['제목', '내용']}
 *   rows={[
 *     createTableRow({
 *       cells: [
 *         createTextCell({ text: '제목' })
 *         createTextCell({ text: '내용' })
 *       ],
 *       expandContent: (<div>펼쳐졌을 때 보여줄 내용</div>)
 *     })
 *   ]}
 * />
 * @end
 *
 * 한 행을 나타내는 interface
 * @param expandContent 행이 펼쳐졌을 때 보여줄 내용
 * @param cells 행이 가진 셀
 */
export interface DataTableRow {
  expandContent?: React.ReactNode;
  cells: DataTableCell[];
}
export const createRow = (row: DataTableRow) => row;

/**
 * 셀의 타입은 Text 또는 Button 둘 중 하나여야 함
 */
export type DataTableCell = DataTableTextCell | DataTableButtonCell;

/**
 * 셀이 텍스트인 경우에 사용하는 interface
 * @param text 셀 내용
 * @param ellipsis 생략 사용 여부
 * @param d_day 디데이
 */
export interface DataTableTextCell {
  text: string;
  ellipsis?: boolean;
  d_day?: number;
  isStop? : boolean;
  isImg?: boolean
  imgFile?: File
  index?: number,
  imgArr?: {index: number, file: File}[],
}
export const createTextCell = (cell: DataTableTextCell) => cell;
const isTextCell = (cell: DataTableCell): cell is DataTableTextCell =>
  "text" in cell;

/**
 * 셀이 버튼인 경우에 사용하는 interface
 * @param type underlined: 검정색 밑줄 버튼, outlined: 파란색 외곽선 버튼, undefined: -
 * @param text 버튼 제목
 * @param onClick 버튼이 클릳되었을 때 호출되는 함수
 */
export interface DataTableButtonCell {
  type: "underlined" | "outlined" | undefined;
  label: string;
  onClick: () => void;
}
export const createButtonCell = (cell: DataTableButtonCell) => cell;
const isButtonCell = (cell: DataTableCell): cell is DataTableButtonCell =>
  "label" in cell;

/**
 * 모든 목록에서 사용할 수 있는 데이터 테이블 컴포넌트
 * @param expandable 펼쳐지는 행이 있는지 여부
 * @param columnTitles 열의 제목
 * @param rows 데이터 행
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
const DataTable = ({
  expandable = false,
  columnTitles,
  rows,
  page,
  totalPageCount,
  onPageChange,
}: {
  expandable?: boolean;
  columnTitles: string[];
  rows: DataTableRow[];
  page: number;
  totalPageCount?: number;
  onPageChange: (page: number) => any;
}) => {
  const [tableRender, setTableRender] = useState(true)
  const pageEdit = (newPage:number) => {
    setTableRender(false)
    onPageChange(newPage)
    setTimeout(() => setTableRender(true), 900)
  }
  return (
    <article className="list">
      <table>
        <thead>
          <tr>
            {expandable && <th className="first"></th>}
            {columnTitles.map((title, index) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        {tableRender ? 
        <tbody>
          {rows.map((row, index) => (
            <Row key={index} row={row} expandable={expandable} />
          ))}
        </tbody>
        :
        null  
      }
        
      </table>
      <Pagination
        page={page}
        totalPageCount={totalPageCount}
        onPageChange={pageEdit}
      />
    </article>
  );
};

/**
 * 데이터 테이블의 한 행을 나타내는 컴포넌트
 */
const Row = ({
  row,
  expandable,
}: {
  row: DataTableRow;
  expandable: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    if (!expandable) {
      return;
    }

    setExpanded(!expanded);
  };

  return (
    <>
      <tr
        className={clsx("table_title", expanded ? "on" : "off")}
        onClick={handleExpand}
      >
        {row.expandContent && (
          <td className="first">
            <img src={ArrowIcon} alt="접고 펼치기 아이콘" />
          </td>
        )}
        {row.cells.map((cell, index) => (
          <Cell key={index} cell={cell} />
        ))}
      </tr>
      {row.expandContent && (
        <tr
          className={clsx("table_content", expanded && "on")}
          style={{ display: expanded ? "table-row" : "none" }}
        >
          <td colSpan={100}>{row.expandContent}</td>
        </tr>
      )}
    </>
  );
};

/**
 * 데이터 테이블의 한 셀을 나타내는 컴포넌트
 */
const Cell = ({ cell }: { cell: DataTableCell }) => {
  const [imgPath, setImgPath] = useState<string>()
  const [imgLoad, setImgLoad] = useState(true)
  useEffect(() => {
    if(isTextCell(cell)) {
      if(cell.isImg) {
        if(cell.imgFile) {
          FileUtil.getFilePath(cell.imgFile).then(setImgPath)
          setImgLoad(false)
        }
      }
    }
  }, [imgPath])
  if (isTextCell(cell)) {
    if (cell.ellipsis) {
      return (
        <td className="address">
          <div className="ellipsis">
            <p>
              {cell.text}
              {<D_Day d_day={cell.d_day} />}
            </p>
          </div>
        </td>
      );
    } else if(cell.isImg) {
      return (
        <td>
          {imgLoad ? null :
          <div className="img_box">
            <img src={imgPath ?? DefaultProfileImage}/>
          </div>
          }
        </td>        
      )
    } else {
      return (
        <td>
          {cell.isStop 
          ? <img src={StopIcon}/>
          : ""  
        }
          {cell.text}
       
          {<D_Day d_day={cell.d_day} />}
        </td>
      );
    }
  } else {
    return (
      <td>
        {cell.type ? (
          <button
            type="button"
            className={clsx("btn_blue", cell.type === "underlined" && "edit")}
            onClick={(e) => {
              e.stopPropagation();
              cell.onClick();
            }}
          >
            {cell.label}
          </button>
        ) : (
          "-"
        )}
      </td>
    );
  }
};

/**
 * 디데이를 나타내는 컴포넌트
 */
const D_Day = ({ d_day }: { d_day?: number }) => {
  let sub = 3 - (d_day as number)
  let d_dayElement = <></>;
  if (d_day !== undefined) {
    if (d_day === 0) {
      d_dayElement = (
        <>
          <span className="yellow">(D-{sub})</span>
        </>
      );
    } else if (d_day >= 3) {
      d_dayElement = (
        <>
          <span className="red">(D-day)</span>
        </>
      )
    } else if (d_day < 3 && d_day > 0) {
      d_dayElement = (
        <>
          <span className="yellow">(D-{sub})</span>
        </>
      )
    }
  }
  return d_dayElement;
};

export default DataTable;
