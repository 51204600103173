import "es6-shim";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import "moment/locale/ko";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "reflect-metadata";

import App from "./App";
import MemberContextProvider from "./context/MemberContextProvider";
import DialogProvider from "./dialogs/DialogProvider";
import reportWebVitals from "./reportWebVitals";
import "./styles/base.css";
import "./styles/index.css";
import "./styles/layout.css";

const firebaseConfig = {
  apiKey: "AIzaSyDWCtP5_id1qlH59uBbkE0aHAvXUq_A4IU",
  authDomain: "gjdaum-44426.firebaseapp.com",
  projectId: "gjdaum-44426",
  storageBucket: "gjdaum-44426.appspot.com",
  messagingSenderId: "639209261861",
  appId: "1:639209261861:web:a64d9c7576b1d5242968cc",
  measurementId: "G-VTC4KMWTHZ",
};

const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(

    <BrowserRouter>
      <MemberContextProvider>
        <DialogProvider>
          <App />
        </DialogProvider>
      </MemberContextProvider>
    </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
