import { AxiosError } from "axios";
import { plainToInstance } from "class-transformer";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MemberAPI, { LoginBasic } from "../apis/MemberAPI";
import Member from "../models/Member";
import TimeUtil from "../utils/TimeUtil";
import MemberContext from "./MemberContext";

const getMember = () => {
  const jsonString = localStorage.getItem("member");
  if (jsonString) {
    return plainToInstance(Member, JSON.parse(jsonString) as Member);
  }
};

const MemberContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const [member, setMember] = useState<Member | undefined>(getMember());

  const login = async (snsId: string, snsType: string) => {
    try {
      const member = await MemberAPI.login({ userId: snsType + snsId });
      setMember(member);
      localStorage.setItem("member", JSON.stringify(member));

      await TimeUtil.delay(10);
      navigate("/home");
    } catch (e) {
      navigate("/join");
    }
  };

  const loginBasic = async (data:LoginBasic) => {
    try {
      const member = await MemberAPI.loginPost({...data})
      // console.log("member : ", member)
      if(member.success) {
        // console.log("로그인 성공")
        // console.log("login info : ", member)
        await MemberAPI.loginGet().then((res) => {
          // console.log("mem info : ", res)
          // setMember(res.data as Member);
          localStorage.setItem("member", JSON.stringify(res.data));
        })
        let checkMem:Member = JSON.parse(localStorage.getItem("member") as string)
        // console.log(checkMem)
        // window.alert(checkMem)
        if(checkMem.depart?.deptCd === "ORG" && checkMem.srvcManageYn === "N") { //기관 현장
          window.alert("권한이 없습니다.")
          localStorage.removeItem("member")
        } else {
          setMember(checkMem)
        }
      } else {
        // console.log("로그인 실패 : ", member.message)
        window.alert(member.message)
      }
    }
      // await TimeUtil.delay(10);
      // navigate("/home");
     catch (e) {
      navigate("/join");
    }
  };

  const selectLocation = () => {
    setMember(JSON.parse(localStorage.getItem("member") as string) as Member)
    navigate("/home");
  }

  const logout = async () => {
    setMember(undefined);
    localStorage.removeItem("member");

    await TimeUtil.delay(10);
    navigate("/login");
  };

  const debugLogin = (member: Member) => {
    setMember(member);
    localStorage.setItem("member", JSON.stringify(member));
    navigate("/home");
  };

  return (
    <MemberContext.Provider value={{ member, login, loginBasic, logout, debugLogin, selectLocation, setMember }}>
      {children}
    </MemberContext.Provider>
  );
};

export default MemberContextProvider;
