import moment, { Moment } from "moment";
import { ServiceList } from "../ServiceDetail";
import OrgDetailModel, { detail, servicePlanYearVO } from "./OrgDetailModel";

/**
 * 비용 모델
 * 
 * 작성자: 홍준표
 * 
 * 작성일: 2023.03.13
 * 
 */
class ExpenseModel {
    constructor (
        public hdong: string,
        public trgterNm: string,
        public brthdy: Moment,
        public gender: string,
        public adres: string,
        public adresDtl: string,
        public spyUid: number,  //돌봄계획 상세일련번호
        public siUid: number,   //서비스인포 일련번호
        public serviceStatCd: string,   //서비스 상태
        public cateCd: number,
        public totalCost: number,   //총금액
        public sbUid: number,   //비용청구서 일련번호
        public crtUid: number,  //등록자 일련번호
        public telno: string,
        public billStatus: "미청구" | "청구" | "수가수령",
        public serviceNm: string,
        public departName: string,
        public userName: string,
        public billDt: Moment,  //청구일시
        public payDt: Moment,   //지급일시
        public crtDt: Moment,   //등록일시
        public demander: string,    //청구인
        // public orgDetail: OrgDetailModel,  //상세
        public servicePlanYearVO: servicePlanYearVO,
        public servicePlanItemVO: ServiceList[],
        public detailInfo: OrgDetailModel,  //상세
    ) {}
}

//구체화 모델 테스트 데이터
//상세모델과 spy로 연결
// namespace ExpenseModel {
//     export const TestExpenseModel:ExpenseModel[] = [
//         {
//             sbUid: 1,
//             spyUid: 1,
//             crtUid: 1,
//             telno: "010-1111-1111",
//             billStatus: "미청구",
//             hdong: "상무1동",
//             trgterNm: "대상1",
//             serviceNm: "가사활동",
//             brthdy: moment("1960-01-14", "yyyy-MM-dd"),
//             gender: "여",
//             adres: "광주광역시 서구 상무평화로 89, 203호(치평동, 상무구영드림빌)",
//             departName: "OO기관",
//             userName: "구담당1",
//             billDt: moment("2023-03-25 12:00", "yyyy-MM-dd HH:mm"),
//             payDt: moment("2023-03-26 15:00", "yyyy-MM-dd HH:mm"),
//             crtDt: moment("2023-03-11 10:20", "yyyy-MM-dd HH:mm"),
//             demander: "기관담당1",
//             totalCost: 0
//         },
//         {
//             sbUid: 2,
//             spyUid: 2,
//             crtUid: 2,
//             telno: "010-2222-2222",
//             billStatus: "청구",
//             hdong: "치평동",
//             trgterNm: "대상2",
//             serviceNm: "영양음식 조리배달",
//             brthdy: moment("1960-01-14", "yyyy-MM-dd"),
//             gender: "남",
//             adres: "광주광역시 서구 상무평화로 89, 203호(치평동, 상무구영드림빌)",
//             departName: "XX기관",
//             userName: "구담당2",
//             billDt: moment("2023-03-25 12:00", "yyyy-MM-dd HH:mm"),
//             payDt: moment("2023-03-26 15:00", "yyyy-MM-dd HH:mm"),
//             crtDt: moment("2023-03-11 10:20", "yyyy-MM-dd HH:mm"),
//             demander: "기관담당2",
//             totalCost: 0
//         },
//         {
//             sbUid: 3,
//             spyUid: 3,
//             crtUid: 3,
//             telno: "010-3333-3333",
//             billStatus: "수가수령",
//             hdong: "상무2동",
//             trgterNm: "대상3",
//             serviceNm: "병원동행",
//             brthdy: moment("1960-01-14", "yyyy-MM-dd"),
//             gender: "남",
//             adres: "광주광역시 서구 상무평화로 89, 203호(치평동, 상무구영드림빌)",
//             departName: "OO기관",
//             userName: "구담당3",
//             billDt: moment("2023-03-25 12:00", "yyyy-MM-dd HH:mm"),
//             payDt: moment("2023-03-26 15:00", "yyyy-MM-dd HH:mm"),
//             crtDt: moment("2023-03-11 10:20", "yyyy-MM-dd HH:mm"),
//             demander: "기관담당3",
//             totalCost: 0
//         },
//         {
//             sbUid: 4,
//             spyUid: 4,
//             crtUid: 4,
//             telno: "010-4444-4444",
//             billStatus: "미청구",
//             hdong: "농성1동",
//             trgterNm: "대상4",
//             serviceNm: "방문진료",
//             brthdy: moment("1960-01-14", "yyyy-MM-dd"),
//             gender: "남",
//             adres: "광주광역시 서구 상무평화로 89, 203호(치평동, 상무구영드림빌)",
//             departName: "++기관",
//             userName: "구담당4",
//             billDt: moment("2023-03-25 12:00", "yyyy-MM-dd HH:mm"),
//             payDt: moment("2023-03-26 15:00", "yyyy-MM-dd HH:mm"),
//             crtDt: moment("2023-03-11 10:20", "yyyy-MM-dd HH:mm"),
//             demander: "기관담당4",
//             totalCost: 0
//         },
//         {
//             sbUid: 5,
//             spyUid: 5,
//             crtUid: 5,
//             telno: "010-5555-5555",
//             billStatus: "청구",
//             hdong: "농성2동",
//             trgterNm: "대상5",
//             serviceNm: "간단수리",
//             brthdy: moment("1960-01-14", "yyyy-MM-dd"),
//             gender: "여",
//             adres: "광주광역시 서구 상무평화로 89, 203호(치평동, 상무구영드림빌)",
//             departName: "--기관",
//             userName: "구담당5",
//             billDt: moment("2023-03-25 12:00", "yyyy-MM-dd HH:mm"),
//             payDt: moment("2023-03-26 15:00", "yyyy-MM-dd HH:mm"),
//             crtDt: moment("2023-03-11 10:20", "yyyy-MM-dd HH:mm"),
//             demander: "기관담당5",
//             totalCost: 0
//         },
//         {
//             sbUid: 6,
//             spyUid: 6,
//             crtUid: 6,
//             telno: "010-6666-6666",
//             billStatus: "청구",
//             hdong: "양동",
//             trgterNm: "대상5",
//             serviceNm: "대청소",
//             brthdy: moment("1960-01-14", "yyyy-MM-dd"),
//             gender: "남",
//             adres: "광주광역시 서구 상무평화로 89, 203호(치평동, 상무구영드림빌)",
//             departName: "--기관",
//             userName: "구담당6",
//             billDt: moment("2023-03-25 12:00", "yyyy-MM-dd HH:mm"),
//             payDt: moment("2023-03-26 15:00", "yyyy-MM-dd HH:mm"),
//             crtDt: moment("2023-03-11 10:20", "yyyy-MM-dd HH:mm"),
//             demander: "기관담당6",
//             totalCost: 0
//         },
//     ]
// }

export default ExpenseModel