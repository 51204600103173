import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import MemberAPI from "../../../apis/MemberAPI";

import ServicePlanAPI from "../../../apis/ServicePlanAPI";
import DataTable, {
  createButtonCell,
  createRow,
  createTextCell,
} from "../../../components/common/DataTable";
import SearchBar, {
  SearchField,
  SearchFormInput,
} from "../../../components/common/SearchBar";
import useMember from "../../../hooks/useMember";
import Member from "../../../models/Member";
import ServicePlan, {
  ServicePlanStatus,
  ServicePlanStatusValues,
} from "../../../models/ServicePlan";
import { ServiceTargetRegistrationTypeValues } from "../../../models/ServiceTarget";
import { ServiceTargetYearHdong } from "../../../models/ServiceTargetYear";
import { SearchTargetParam } from "../service-target/ServiceTargetPage";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.27
 *
 * 돌봄계획 페이지
 */
const ServicePlanPage = () => {
  const { member } = useMember();
  const navigate = useNavigate();
  const { page } = useParams();
  // const [searchQuery, setSearchQuery] = useState<SearchFormInput>({});
  //테스트용 데이터
  // const [servicePlans, setServicePlans] = useState([] as BaseServicePlan[]);
  // const [tempList, setTempList] = useState([] as BaseServicePlan[]);
  const [servicePlans, setServicePlans] = useState([] as ServicePlan[]);
  const [count, setCount] = useState(0);
  const [searchParam, setSearchParam] = useState({} as SearchFormInput);
  const [departList, setDepartList] = useState([] as string[])

  useEffect(() => {
    // TODO: 돌봄계획 목록 로직 구현
    MemberAPI.getDeptList({deptCd: "DONG"}).then((res) => {
      let tempList:string[] = []
      res.list.map((item) => {
        tempList.push(item.departName)
      })
      setDepartList(tempList)
    })
    if (member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU") {    //시 구
      ServicePlanAPI.getAll({
        page: Number(page),
        ...searchParam,
      }).then((res) => {
        // console.log(res);
        res.sort((a, b) => {
          if ((a.statCd === "돌봄계획") && (b.statCd !== "돌봄계획")) {
            if (b.statCd === "현장방문") { //돌 현
              let aUpd: Moment = moment(a.updDt)
              let bUpd: Moment = moment(b.updDt)
              return aUpd.diff(bUpd)
            } else {
              return -1
            }
          } else if ((a.statCd === "돌봄계획") && (b.statCd === "돌봄계획")) { //돌 돌
            let aUpd: Moment = moment(a.updDt)
            let bUpd: Moment = moment(b.updDt)
            return aUpd.diff(bUpd)
          } else if ((a.statCd === "현장방문") && (b.statCd !== "현장방문")) {
            if (b.statCd === "돌봄계획") { //현 돌
              let aUpd: Moment = moment(a.updDt)
              let bUpd: Moment = moment(b.updDt)
              return aUpd.diff(bUpd)
            } else {
              return -1
            }
          } else if ((a.statCd === "현장방문") && (b.statCd === "현장방문")) { //현 현
            let aUpd: Moment = moment(a.updDt)
            let bUpd: Moment = moment(b.updDt)
            return aUpd.diff(bUpd)
          }
          return 1
        })
        setServicePlans(res);
        // console.log("List : ", res)
      });
      ServicePlanAPI.getCount(searchParam).then((res) => {
        setCount(res);
      });
    } else { //동
      MemberAPI.getOneDept({
        dptUid: (member as Member).dptUid
      }).then((res) => {
        ServicePlanAPI.getAll({
          page: Number(page),
          ...searchParam,
          hdong: res.data.departName,
        }).then((res) => {
          // console.log(res);
          res.sort((a, b) => {
            if ((a.statCd === "돌봄계획") && (b.statCd !== "돌봄계획")) {
              if (b.statCd === "현장방문") { //돌 현
                let aUpd: Moment = moment(a.updDt)
                let bUpd: Moment = moment(b.updDt)
                return aUpd.diff(bUpd)
              } else {
                return -1
              }
            } else if ((a.statCd === "돌봄계획") && (b.statCd === "돌봄계획")) { //돌 돌
              let aUpd: Moment = moment(a.updDt)
              let bUpd: Moment = moment(b.updDt)
              return aUpd.diff(bUpd)
            } else if ((a.statCd === "현장방문") && (b.statCd !== "현장방문")) {
              if (b.statCd === "돌봄계획") { //현 돌
                let aUpd: Moment = moment(a.updDt)
                let bUpd: Moment = moment(b.updDt)
                return aUpd.diff(bUpd)
              } else {
                return -1
              }
            } else if ((a.statCd === "현장방문") && (b.statCd === "현장방문")) { //현 현
              let aUpd: Moment = moment(a.updDt)
              let bUpd: Moment = moment(b.updDt)
              return aUpd.diff(bUpd)
            }
            return 1
          })
          setServicePlans(res);
          // console.log("List : ", res)
        });
        ServicePlanAPI.getCount({
          ...searchParam,
          hdong: res.data.departName,
        }).then((res) => {
          setCount(res);
        });
      })
    }

  }, [page, searchParam]);

  // useEffect(() => console.log(servicePlans), [servicePlans]);

  const handlePageChange = (page: number) => {
    navigate(`/service-plan/${page}`);
  };

  const handleSearch = (data: SearchFormInput) => {
    let parsed: SearchTargetParam = data;
    let params: SearchTargetParam = {};
    if (parsed.sbscWay?.length !== 0) {
      params.sbscWay = parsed.sbscWay;
    }
    if (parsed.hdong?.length !== 0) {
      params.hdong = parsed.hdong;
    }
    if (parsed.statCd?.length !== 0) {
      params.statCd = parsed.statCd;
    }
    if (parsed.hing?.length !== 0 && parsed.keyword?.length !== 0) {
      switch (parsed.hing) {
        case "name":
          params.trgterNm = parsed.keyword;
          break;
        case "brthdy":
          params.trgterCd = parsed.keyword;
          break;
        case "telno":
          params.telno = parsed.keyword;
          break;
        case "adres":
          params.adres = parsed.keyword;
          break;
      }
    }
    setSearchParam(params);
  };

  const handleAction = (servicePlan: ServicePlan) => {
    const params = new URLSearchParams({
      sdUid: `${servicePlan.sdUid}`,
      csUid: `${servicePlan.csUid}`,
      syUid: `${servicePlan.syUid}`,
      stUid: `${servicePlan.stUid}`,
      trgterCd: `${servicePlan.trgterCd}`,
    });
    switch (servicePlan.statCd) {
      case "현장방문":
        navigate(`/service-plan/needs-assessment?${params}`);
        break;
      case "사례회의(구)":
        navigate(`/service-plan/conference?sdUid=${params}`);
        break;
      case "돌봄계획":
        navigate(`/service-plan/dollbomPlan?${params}`);
        break;
      case "사례회의(동)":
        navigate(`/service-plan/dongConference?${params}`);
        break;
      case "사례회의(권역)":
        navigate(`/service-plan/areaConference?${params}`);
        break;
      case "서비스의뢰":
        navigate(`/service-plan/careRequest?${params}`);
        break;
      case "돌봄계획확정":
        navigate(`/service-plan/confirmation?${params}`);
        break;
    }
  };

  if (!member) {
    return <Navigate to="/login" />;
  }

  /**
   * 검색 필드
   */
  const searchFields: SearchField[] = member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ?
    [
      {
        title: "등록구분",
        name: "sbscWay",
        type: "select",
        items: ServiceTargetRegistrationTypeValues.map((label) => ({ label })),
      },
      {
        title: "행정동",
        name: "hdong",
        type: "select",
        items: departList.map((label) => ({ label })),
      },
      {
        title: "상태",
        name: "statCd",
        type: "select",
        items: ServicePlanStatusValues.map((label) => ({ label })),
      },
      {
        title: "검색어",
        name: "hing",
        type: "search",
        items: [
          { label: "성명", value: "name" },
          { label: "성명+생년월일", value: "brthdy" },
          { label: "연락처", value: "telno" },
          { label: "주소", value: "adres" },
        ],
      },
    ] : //동
    [
      {
        title: "등록구분",
        name: "sbscWay",
        type: "select",
        items: ServiceTargetRegistrationTypeValues.map((label) => ({ label })),
      },
      {
        title: "상태",
        name: "statCd",
        type: "select",
        items: ServicePlanStatusValues.map((label) => ({ label })),
      },
      {
        title: "검색어",
        name: "hing",
        type: "search",
        items: [
          { label: "성명", value: "name" },
          { label: "성명+생년월일", value: "brthdy" },
          { label: "연락처", value: "telno" },
          { label: "주소", value: "adres" },
        ],
      },
    ]

  return (
    <main>
      <section>
        <SearchBar fields={searchFields} onSubmit={handleSearch} />
        <article className="list_top">
          <div className="total">
            <p>
              총 <span>{count}</span>건
            </p>
          </div>
        </article>
        <DataTable
          columnTitles={member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ?  //시 구
            [
              "등록구분",
              "행정동",
              "성명",
              "생년월일",
              "성별",
              "연락처",
              "주소",
              "상태(처리기한)",
              "이전 상태 완료일",
              "",
            ]
            : //동
            [
              "등록구분",
              "성명",
              "생년월일",
              "성별",
              "연락처",
              "주소",
              "상태(처리기한)",
              "이전 상태 완료일",
              "",
            ]
          }
          rows={servicePlans.map((servicePlan) =>
            createRow({

              cells: member.depart?.deptCd !== "SI" ? member.depart?.deptCd === "GU" ? [  //구
                createTextCell({ text: servicePlan.sbscWay }),
                createTextCell({ text: servicePlan.hdong }),
                createTextCell({ text: servicePlan.trgterNm }),
                createTextCell({ text: servicePlan.brthdy }),
                createTextCell({ text: servicePlan.gender }),
                createTextCell({ text: servicePlan.telno }),
                createTextCell({ text: servicePlan.adres }),
                createTextCell({ text: servicePlan.statCd, d_day: servicePlan.statCd === "돌봄계획" || servicePlan.statCd === "현장방문" ? moment().diff(moment(servicePlan.updDt), 'days') : -1 }),
                createTextCell({ text: moment(servicePlan.updDt).format("yyyy-MM-DD(dd)") }),
                // createButtonCell({
                //   type: "underlined",
                //   label: moment(servicePlan.updDt).format("yyyy-MM-DD(dd)"),
                //   onClick: () => handleEdit(servicePlan),
                // }),
                // createButtonCell({
                //   type: (servicePlan.action === "의뢰" || servicePlan.action === "계획확정") ? "outlined" : undefined,
                //   label: (servicePlan.action === "의뢰" || servicePlan.action === "계획확정") ? servicePlan.action : "-",
                //   onClick: (servicePlan.action === "의뢰" || servicePlan.action === "계획확정") ? () => handleAction(servicePlan) : () => {},
                // }),
                createButtonCell({
                  type: servicePlan.action === "계획확정" ? "outlined" : servicePlan.action === "의뢰" ? !servicePlan.isRequest ? "outlined" : undefined : undefined,
                  label: (servicePlan.action === "의뢰" || servicePlan.action === "계획확정") ? servicePlan.action : "-",
                  onClick: (servicePlan.action === "의뢰" || servicePlan.action === "계획확정") ? () => handleAction(servicePlan) : () => {},
                }),
              ] : //동
                [
                  createTextCell({ text: servicePlan.sbscWay }),
                  createTextCell({ text: servicePlan.trgterNm }),
                  createTextCell({ text: servicePlan.brthdy }),
                  createTextCell({ text: servicePlan.gender }),
                  createTextCell({ text: servicePlan.telno }),
                  createTextCell({ text: servicePlan.adres }),
                  createTextCell({ text: servicePlan.statCd, d_day: servicePlan.statCd === "돌봄계획" || servicePlan.statCd === "현장방문" ? moment().diff(moment(servicePlan.updDt), 'days') : -1 }),
                  createTextCell({ text: moment(servicePlan.updDt).format("yyyy-MM-DD(dd)") }),
                // createButtonCell({
                //   type: "underlined",
                //   label: moment(servicePlan.updDt).format("yyyy-MM-DD(dd)"),
                //   onClick: () => handleEdit(servicePlan),
                // }),
                  createButtonCell({
                    type: servicePlan.action !== "의뢰" && servicePlan.action !== "계획확정" ? "outlined" : undefined,
                    label: servicePlan.action !== "의뢰" && servicePlan.action !== "계획확정" ? servicePlan.action : "-",
                    onClick: servicePlan.action !== "의뢰" && servicePlan.action !== "계획확정" ? () => handleAction(servicePlan) : () => {},
                  }),
                ]
                : //시
                [
                  createTextCell({ text: servicePlan.sbscWay }),
                  createTextCell({ text: servicePlan.hdong }),
                  createTextCell({ text: servicePlan.trgterNm }),
                  createTextCell({ text: servicePlan.brthdy }),
                  createTextCell({ text: servicePlan.gender }),
                  createTextCell({ text: servicePlan.telno }),
                  createTextCell({ text: servicePlan.adres }),
                  createTextCell({ text: servicePlan.statCd, d_day: servicePlan.statCd === "돌봄계획" || servicePlan.statCd === "현장방문" ? moment().diff(moment(servicePlan.updDt), 'days') : -1 }),
                  createTextCell({ text: moment(servicePlan.updDt).format("yyyy-MM-DD(dd)") }),
                // createButtonCell({
                //   type: "underlined",
                //   label: moment(servicePlan.updDt).format("yyyy-MM-DD(dd)"),
                //   onClick: () => handleEdit(servicePlan),
                // }),
                  createButtonCell({
                    type: servicePlan.action === "의뢰" ? !servicePlan.isRequest ? "outlined" : undefined : "outlined",
                    label: servicePlan.action,
                    onClick: () => handleAction(servicePlan),
                  }),
                ]
              ,
            })
          )}
          page={Number(page)}
          totalPageCount={Math.ceil(count / 10)}
          onPageChange={handlePageChange}
        />
      </section>
    </main>
  );
};

export default ServicePlanPage;
