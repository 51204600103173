import moment from "moment";
import React, {
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import DollbomPlanAPI, { PrevPlans, ServiceDecisionVO } from "../../../apis/DollbomPlanAPI";
import BackIcon from "../../../assets/ic_backlist.png";
import { useDialog } from "../../../dialogs/DialogProvider";
import DollbomPlanInfoDialog from "../../../dialogs/service-plan/DollbomPlanInfoDialog";
import DollbomSupportDialog from "../../../dialogs/service-plan/DollbomSupportDialog";
import DollbomTargetInfoDialog from "../../../dialogs/service-plan/DollbomTargetInfoDialog";
import DongConference from "../../../models/DongConference";
import NeedsAssessmentJoin from "../../../models/NeedsAssessmentJoin";
import ServicePlanDetails from "../../../models/ServicePlanDetails";
import ServiceTargetYear from "../../../models/ServiceTargetYear";
import DollbomConfirmationFormInput from "../../../models/form/DollbomConfirmationFormInput";
import DollbomPlanFormInput from "../../../models/form/DollbomPlanFormInput";
import "../service-plan/DollbomConfirmationPage.scss";
import EditDollbomPlan from "./DollbomPlanComponents/EditDollbomPlan";
import ServiceResult from "./DollbomPlanComponents/ServiceResult";
import UseServiceTab from "./DollbomPlanComponents/TabTableComponents/UseServiceTab";
import NeedAsTabTable1 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable1";
import NeedAsTabTable2 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable2";
import NeedAsTabTable3 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable3";
import NeedAsTabTable4 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable4";
import NeedAsTabTable5 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable5";
import NeedAsTabTable6 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable6";
import NeedAsBottom from "./DollbomPlanComponents/TabTableComponents/NeedAsBottom";
import CarePlanTable from "./DollbomPlanComponents/CarePlanTable";
import InsertPlan from "./DollbomPlanComponents/InsertPlan";
import SprtMoreData from "../../../models/SprtMoreData";
import DollbomPlanSupportAPI from "../../../apis/DollbomPlanSupportAPI";
import SprtMoreTable from "./DollbomPlanComponents/TabTableComponents/SprtMoreTable";
import TargetInfoBar from "./DollbomPlanComponents/TargetInfoBar";

const DollbomConfirmationPage = () => {
  const [targetNm, setTargetNm] = useState("");
  const [brthdy, setBrthdy] = useState("");
  const [gender, setGender] = useState("");
  const [plans, setPlans] = useState<DollbomPlanFormInput[]>([]);
  const [isCaseMeetingNeeded, setCaseMeetingNeeded] = useState(true);
  const navigate = useNavigate();
  const [carePlan, setCarePlan] = useState<DongConference>();
  const [tabTable, setTabTable] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [tabTable2, setTabTable2] = useState(0);
  const [searchParams] = useSearchParams();
  // 추가지원 검토서 여부
  const [sprtMore, setSprtMore] = useState(false);
  // 동사례회의 기록지 여부
  const [dongConference, setDongConference] = useState(false);
  // 권역별 사례회의 기록지 여부
  const [areaConference, setAreaConference] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [details, setDetails] = useState<ServicePlanDetails>();
  const [detailLoad, setDetailLoad] = useState(true)
  const { page } = useParams();
  // 질병 및 증상 추가한거
  const [illAddList, setIllAddList] = useState<string[]>([]);
  // 만 나이 계산
  const [targetBrthdy, setTargetBrthdy] = useState(0);
  const [targetMonth, setTargetMonth] = useState(0);
  const [targetDay, setTargetDay] = useState(0);
  const [targetInfo, setTargetInfo] = useState<ServiceTargetYear>();
  const [evaluationData, setEvaluationData] = useState<NeedsAssessmentJoin>();
  const [newPlan, setNewPlan] = useState<DollbomConfirmationFormInput[]>([]);
  //이전 돌봄계획 데이터
  const [prevPlans, setPrevPlans] = useState<PrevPlans>({} as PrevPlans);
  const [prevPlansLoading, setPrevPlansLoading] = useState(false)
  const sdUid = searchParams.get("sdUid");
  const csUid = searchParams.get("csUid");
  const syUid = searchParams.get("syUid");
  const stUid = searchParams.get("stUid");

  //안전지원 비용, 주거편의 횟수
  const [safeSprtCharge, setSafeSprtCharge] = useState(0)
  const [simpleFixCount, setSimpleFixCount] = useState(0)
  const [cleaningCount, setCleaningCount] = useState(0)
  const [pestControlCount, setPestControlCount] = useState(0)
  const [escortCount, setEscortCount] = useState(0)

  //추가지원
  const [sprtMoreData, setSprtMoreData] = useState<SprtMoreData>()
  //추가지원 승인여부
  const [isSprtMore, setIsSprtMore] = useState<'Y' | 'N'>('N')
  //추가지원 로딩
  const [sprtLoad, setSprtLoad] = useState(false)

  useEffect(() => {
    if (openDialog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openDialog]);

  useEffect(() => {
    DollbomPlanAPI.getDetails({
      sdUid: Number(sdUid),
      csUid: Number(csUid),
    }).then((res) => {
      setDetails(res);
      // console.log(res);
      setTargetInfo(details?.targetInfo);
      // 만 나이 계산
      setTargetBrthdy(Number(res.targetInfo.brthdy.split("-")[0]));
      setTargetMonth(Number(res.targetInfo.brthdy.split("-")[1]));
      setTargetDay(Number(res.targetInfo.brthdy.split("-")[2]));
      setDetailLoad(false)
    });
  }, [page]);
  // useEffect(() => {}, [page]);

  useEffect(() => {
    DollbomPlanAPI.getEvaluationData({
      sdUid: Number(sdUid),
      csUid: Number(csUid),
    }).then((res) => {
      setEvaluationData(res);
      setIllAddList(JSON.parse(res.jsonData.illAdd));
      // console.log(res.jsonData.illAdd);
      // console.log(res);
    });

    DollbomPlanSupportAPI.getSprtMoreData({
      syUid: Number(syUid), 
    }).then((res) => {
      if(res) {
        setIsSprtMore(res.cfmYn)
        setSprtMoreData(res)
        setSprtMore(true)
      }
      setSprtLoad(true)
    })

    DollbomPlanAPI.prevPlans({
      stUid: Number(stUid),
      syUid: Number(syUid),
    }).then((res) => {
      // console.log("이전 돌봄계획 : ", res)
      setSafeSprtCharge(res.safeSprtCharge)
      setSimpleFixCount(res.simpleFixCount)
      setCleaningCount(res.cleaningCount)
      setPestControlCount(res.pestControlCount)
      setEscortCount(res.escortCount)
      setPrevPlans(res)
      setPrevPlansLoading(true)
    })

    //이전 추가지원 여부
    DollbomPlanSupportAPI.pastSprtHistory({
      syUid: Number(syUid),
      stUid: Number(stUid),
    }).then((res) => {
      // console.log("이전 추가지원 여부 : ", res)
      if (!res.success) {
        setIsSprtMore('Y')
      }
    })

  }, []);

  useEffect(() => {
    DollbomPlanAPI.getDongConferencePlan({
      syUid: Number(syUid),
      csUid: Number(csUid),
    }).then((res) => {
      setCarePlan(res);
      // console.log(">>> carePlan", res);
      // console.log("dongConference >>>>", res.dongMeeting);
      if (res.dongMeeting) {
        setDongConference(true);
      }
      if (res.guMeeting) {
        setAreaConference(true);
      }
    });
  }, []);
  // 저장버튼 눌렀을때 이벤트
  // 저장버튼 눌렀을때 테이블 서비스목록 저장
  const handleConfirm = async () => {
    if (window.confirm("저장 하시겠습니까?")) {
      let data: DollbomConfirmationFormInput[] = [];
      // 입력한 기존돌봄
      const basicList = document.querySelector<HTMLInputElement>(".basicList");
      const basicDetail =
        document.querySelector<HTMLInputElement>("#basicDetail");
      // 입력한 긴급돌봄
      const emerDay = document.querySelector<HTMLInputElement>("#emerDay");
      const emerBeginDt =
        document.querySelector<HTMLInputElement>(".emerBeginDt");
      const emerEndDt = document.querySelector<HTMLInputElement>(".emerEndDt");
      const emerTime = document.querySelector<HTMLInputElement>("#emerTime");
      const emerCost = document.querySelector<HTMLInputElement>("#emerCost");
      const rows =
        document.querySelectorAll<HTMLTableRowElement>(".addTable tr");
      data = InsertPlan(
        basicList,
        basicDetail,
        emerDay,
        emerBeginDt,
        emerEndDt,
        emerTime,
        emerCost,
        rows,
        carePlan!.emerList,
        carePlan!.basicList,
        carePlan!.gjList
      ) as DollbomConfirmationFormInput[]

      // console.log(">>>>> newPlan >>>>", data);
      const success = await DollbomPlanAPI.insertConfirmation({
        syUid: Number(syUid),
        nextYn: "Y",
        stepCd: "돌봄계획확정",
        stepCdNext: "서비스의뢰",
        servicePlanYears: data,
      });
      if (success) {
        navigate("/service-plan", { replace: true });
      } else {
        // console.log(">>>> failure");
        alert("서비스의뢰 실패")
        return
      }
    }
  };
  // useEffect(() => {
  //   console.log(newPlan);
  // }, [newPlan]);
  // 저장버튼 눌렀을때 이벤트 end

  const handleAddPlan = (plan: DollbomPlanFormInput) => {
    let planTemp = plan
    planTemp.times = Number(plan.times)
    planTemp.totalCount = Number(plan.totalCount)
    setPlans([planTemp, ...plans]);
  };
  // 새로 추가한 수립계획 삭제 버튼 이벤트
  const handleDeletePlan = (item: DollbomPlanFormInput) => {
    if (window.confirm("서비스를 삭제하시겠습니까?")) {
      setPlans(plans.filter((e) => e != item));
    }
  };
  const handleBackPressed = () => {
    navigate(-1);
  };
  const { showDialog } = useDialog();

  const handleDollbomSupport = () => {
    showDialog(DollbomSupportDialog);
  };
  const handleModifyPlan = (item: DollbomPlanFormInput) => {
    const testPlan = plans.filter((e) => e == item);
    setPlans(plans.filter((e) => e != item));
  };
  const handleDollbomPlanInfoDialog = () => {
    showDialog(DollbomPlanInfoDialog);
  };

  const handleFocus = (event: any) => {
    event.target.type = "date";
  };
  return (
    <main className="DollbomConfirmationPage">
      <section>
        <article className="detail_top">
          <div className="nav_list">
            <p>{targetNm}</p>
            <p>{brthdy}</p>
            <p>{gender}</p>
            <p>
              돌봄계획 / <span>계획확정</span>
            </p>
            <button
              type="button"
              className="mt8"
              onClick={handleBackPressed}
            >
              <span>
                <img src={BackIcon} alt="목록으로 돌아가기 아이콘" />
              </span>
              목록으로 돌아가기
            </button>
          </div>
          <div className="btn_wrap">
            <button
              type="submit"
              className="submit"
              onClick={handleConfirm}
            >
              저장
            </button>
          </div>
        </article>
        {!detailLoad && 
        <TargetInfoBar details={details!} targetMonth={targetMonth} targetBrthdy={targetBrthdy} targetDay={targetDay} />
        }   
        <article className="care_plan mt20">
          <div className="left mr20">
            <UseServiceTab details={details!}/>
            <div className="care_tab mt20">
              {/* 탭 */}
              <div className="tabs">
                {/* 탭 1개일때 tab_count1 2개일때 tab_count2 3개일때 tab_count3 4개일때 tab_count4 */}
                <button
                  type="button"
                  className={`tab 
                                ${tabTable === 0
                      ? "tab_active"
                      : "non_active"
                    } 
                                ${sprtMore &&
                    dongConference &&
                    areaConference &&
                    "tab_count4"
                    }
                                ${sprtMore &&
                    dongConference &&
                    !areaConference &&
                    "tab_count3"
                    }
                                ${sprtMore &&
                    !dongConference &&
                    areaConference &&
                    "tab_count3"
                    }
                                ${!sprtMore &&
                    dongConference &&
                    areaConference &&
                    "tab_count3"
                    }
                                ${sprtMore &&
                    !dongConference &&
                    !areaConference &&
                    "tab_count2"
                    }
                                ${!sprtMore &&
                    !dongConference &&
                    !areaConference &&
                    "tab_count1"
                    }
                                ${!sprtMore &&
                    !dongConference &&
                    areaConference &&
                    "tab_count2"
                    }
                                ${!sprtMore &&
                    dongConference &&
                    !areaConference &&
                    "tab_count2"
                    }
                                `}
                  onClick={() => setTabTable(0)}
                  data-id={4}
                >
                  돌봄필요도 평가
                </button>
                {sprtMore && (
                  <button
                    type="button"
                    className={`tab
                                ${tabTable === 1
                        ? "tab_active"
                        : "non_active"
                      } 
                                ${sprtMore &&
                      dongConference &&
                      areaConference &&
                      "tab_count4"
                      }
                                ${sprtMore &&
                      dongConference &&
                      !areaConference &&
                      "tab_count3"
                      }
                                ${sprtMore &&
                      !dongConference &&
                      areaConference &&
                      "tab_count3"
                      }
                                ${!sprtMore &&
                      dongConference &&
                      areaConference &&
                      "tab_count3"
                      }
                                ${sprtMore &&
                      !dongConference &&
                      !areaConference &&
                      "tab_count2"
                      }
                                ${!sprtMore &&
                      !dongConference &&
                      !areaConference &&
                      "tab_count1"
                      }
                                ${!sprtMore &&
                      !dongConference &&
                      areaConference &&
                      "tab_count2"
                      }
                                ${!sprtMore &&
                      dongConference &&
                      !areaConference &&
                      "tab_count2"
                      }`}
                    onClick={() => setTabTable(1)}
                    data-id={3}
                  >
                    추가지원 검토서
                  </button>
                )}
                {dongConference && (
                  <button
                    type="button"
                    className={`tab
                                ${tabTable === 2
                        ? "tab_active"
                        : "non_active"
                      } 
                                ${sprtMore &&
                      dongConference &&
                      areaConference &&
                      "tab_count4"
                      }
                                ${sprtMore &&
                      dongConference &&
                      !areaConference &&
                      "tab_count3"
                      }
                                ${sprtMore &&
                      !dongConference &&
                      areaConference &&
                      "tab_count3"
                      }
                                ${!sprtMore &&
                      dongConference &&
                      areaConference &&
                      "tab_count3"
                      }
                                ${sprtMore &&
                      !dongConference &&
                      !areaConference &&
                      "tab_count2"
                      }
                                ${!sprtMore &&
                      !dongConference &&
                      !areaConference &&
                      "tab_count1"
                      }
                                ${!sprtMore &&
                      !dongConference &&
                      areaConference &&
                      "tab_count2"
                      }
                                ${!sprtMore &&
                      dongConference &&
                      !areaConference &&
                      "tab_count2"
                      }`}
                    onClick={() => setTabTable(2)}
                    data-id={2}
                  >
                    동 사례회의 기록지
                  </button>
                )}
                {areaConference && (
                  <button
                    type="button"
                    className={`tab
                                ${tabTable === 3
                        ? "tab_active"
                        : "non_active"
                      } 
                                ${sprtMore &&
                      dongConference &&
                      areaConference &&
                      "tab_count4"
                      }
                                ${sprtMore &&
                      dongConference &&
                      !areaConference &&
                      "tab_count3"
                      }
                                ${sprtMore &&
                      !dongConference &&
                      areaConference &&
                      "tab_count3"
                      }
                                ${!sprtMore &&
                      dongConference &&
                      areaConference &&
                      "tab_count3"
                      }
                                ${sprtMore &&
                      !dongConference &&
                      !areaConference &&
                      "tab_count2"
                      }
                                ${!sprtMore &&
                      !dongConference &&
                      !areaConference &&
                      "tab_count1"
                      }
                                ${!sprtMore &&
                      !dongConference &&
                      areaConference &&
                      "tab_count2"
                      }
                                ${!sprtMore &&
                      dongConference &&
                      !areaConference &&
                      "tab_count2"
                      }`}
                    onClick={() => setTabTable(3)}
                    data-id={3}
                  >
                    권역별 사례회의 기록지
                  </button>
                )}
              </div>
              {/* 탭 뷰 */}
              {tabTable === 0 && <TabTable1 evaluationData={evaluationData} illAddList={illAddList} />}
              {tabTable === 1 && sprtMoreData && <SprtMoreTable sprtMoreData={sprtMoreData} />}
              {tabTable === 2 && <TabTable3 dongMeeting={carePlan} />}
              {tabTable === 3 && <TabTable4 guMeeting={carePlan} />}
              {/* 탭 뷰 종료 */}
              {/* 돌봄필요도 평가 */}

              {/* 추가지원 검토서 */}

              {/* 동 사례회의 기록지 */}

              {/* 권역별 사례회의 기록지 */}
            </div>
          </div>
          {prevPlansLoading && 
          <div className="right">
            <div className="care_plan_write">
              {/* 탭 */}
              <div className="tabs3">
                {/* 탭 1개일때 tab_count1 2개일때 tab_count2 3개일때 tab_count3 4개일때 tab_count4 */}
                <button
                  type="button"
                  className={`tab3 ${tabTable2 === 0
                    ? "tab_active"
                    : "non_active"} tab_count2`}
                  onClick={() => setTabTable2(0)}
                  data-id={0}
                >
                  돌봄계획서(초안)
                </button>
                <button
                  type="button"
                  className={`tab3 ${tabTable2 === 1
                    ? "tab_active"
                    : "non_active"} tab_count2`}
                  onClick={() => setTabTable2(1)}
                  data-id={1}
                >
                  돌봄계획서(확정안)
                </button>
              </div>
              {/* 탭 뷰 */}
              {tabTable2 === 0 && sprtLoad ? <TabContent1 
              lastSum={prevPlans.lastSum ?? undefined}
              lastEmerSum={prevPlans.lastEmerSum ?? undefined}
              isSprtMore={isSprtMore}
              /> : null}
              {tabTable2 === 1 && (
                <EditDollbomPlan
                  services={details?.gjList ?? []}
                  basicServices={details?.basicList ?? []}
                  emerServices={details?.emerList ?? []}
                  plans={plans}
                  onPlanAdded={handleAddPlan}
                  onPlanModify={handleModifyPlan}
                  onPlanDeleted={handleDeletePlan}
                  lastSum={prevPlans.lastSum ?? undefined}
                  lastEmerSum={prevPlans.lastEmerSum ?? undefined}
                  safeSprtCharge={safeSprtCharge}
                  simpleFixCount={simpleFixCount}
                  cleaningCount={cleaningCount}
                  pestControlCount={pestControlCount}
                  escortCount={escortCount}
                  isSprtMore={isSprtMore}
                />
              )}
              {/* 탭 뷰 종료 */}
              {/* 돌봄계획서(초안) */}

              {/* 돌봄계획서(확정안) */}
            </div>
            {prevPlans.prevPlanBox && <ServiceResult prevPlans={prevPlans} isSprtMore={isSprtMore} />}
          </div>
          }
        </article>
      </section>
    </main>
  );
};

const TabTable1 = ({
  evaluationData,
  illAddList,
}: {
  evaluationData?: NeedsAssessmentJoin;
  illAddList: string[];
}) => {
  const [tabTable2, setTabTable2] = useState(0);
  const [subEvaluationData, setEvaluationData] =
    useState<NeedsAssessmentJoin>();
  useEffect(() => {
    setEvaluationData(evaluationData);
  }, []);
  return (
    <div className="padding tab_content" data-id={2}>
      {/* <div className="padding tab_content hidden" data-id={2}> */}
      <div className="care_evaluation">
        {/* 탭 */}
        <div className="tabs2">
          <button
            type="button"
            className={`tabs2 
                        ${tabTable2 === 0
                ? "tab_active"
                : "non_active"
              } `}
            onClick={() => setTabTable2(0)}
            data-id={0}
          >
            신체적 영역
          </button>
          <button
            type="button"
            className={`tabs2 
                        ${tabTable2 === 1
                ? "tab_active"
                : "non_active"
              } `}
            onClick={() => setTabTable2(1)}
            data-id={1}
          >
            정서적 영역
          </button>
          <button
            type="button"
            className={`tabs2 
                        ${tabTable2 === 2
                ? "tab_active"
                : "non_active"
              } `}
            onClick={() => setTabTable2(2)}
            data-id={2}
          >
            추가정보(생활환경)
          </button>
          <button
            type="button"
            className={`tabs2 
                        ${tabTable2 === 3
                ? "tab_active"
                : "non_active"
              } `}
            onClick={() => setTabTable2(3)}
            data-id={3}
          >
            추가정보(사전검사)
          </button>
          <button
            type="button"
            className={`tabs2 
                        ${tabTable2 === 4
                ? "tab_active"
                : "non_active"
              } `}
            onClick={() => setTabTable2(4)}
            data-id={4}
          >
            광주+돌봄 지원대상 여부
          </button>
          <button
            type="button"
            className={`tabs2 
                        ${tabTable2 === 5
                ? "tab_active"
                : "non_active"
              } `}
            onClick={() => setTabTable2(5)}
            data-id={5}
          >
            긴급돌봄 지원대상 여부
          </button>
        </div>
        {/* 탭 뷰 */}
        {tabTable2 === 0 && <NeedAsTabTable1 evaluationData={evaluationData} illAddList={illAddList} />}
        {tabTable2 === 1 && <NeedAsTabTable2 evaluationData={evaluationData} />}
        {tabTable2 === 2 && <NeedAsTabTable3 evaluationData={evaluationData} />}
        {tabTable2 === 3 && <NeedAsTabTable4 evaluationData={evaluationData} />}
        {tabTable2 === 4 && <NeedAsTabTable5 evaluationData={evaluationData} />}
        {tabTable2 === 5 && <NeedAsTabTable6 evaluationData={evaluationData} />}
        {/* 탭 뷰 종료 */}
        {/* 신체적 영역 */}

        {/* 정서적 영역 */}

        {/* 추가정보(생활환경) */}

        {/* 광주+돌봄 지원대상 여부 */}

        {/* 긴급돌봄 지원대상 여부 */}
        <NeedAsBottom evaluationData={evaluationData!}/>
      </div>
    </div>
  );
};
const TabTable3 = ({ dongMeeting }: { dongMeeting?: DongConference }) => {
  const crtDt = moment(dongMeeting?.dongMeeting.crtDt).format("YYYY.MM.DD(dd)");
  const mtDt = moment(dongMeeting?.dongMeeting.mtDt).format(
    "YYYY-MM-DD(dd) A HH:MM"
  );
  return (
    <div className="padding tab_content" data-id={4}>
      <p className="date">작성일 : {crtDt}</p>
      <div className="meeting">
        <h4>동 사례회의 기록지</h4>
        <div className="meet01">
          <div className="text_input">
            <p>회의 개요</p>
            <div className="input_wrap">
              <div
                className="input_box mr8"
                style={{ width: "50%" }}
              >
                <input
                  type="text"
                  className="w100"
                  placeholder="회의장소 입력"
                  value={dongMeeting?.dongMeeting.mtPlace}
                  disabled
                />
              </div>
              <div className="input_box" style={{ width: "30%" }}>
                <input
                  type="text"
                  id="birth"
                  className="w100"
                  placeholder="회의일시"
                  value={mtDt}
                  disabled
                />
                {/* onFocus="(this.type='date')" onblur="(this.type='text')" */}
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>참석자</p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <input
                  type="text"
                  className="w100"
                  placeholder="참석자 입력(콤마로 구분하여 입력)"
                  value={dongMeeting?.dongMeeting.attnd}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>사례내용</p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="돌봄필요도 평가, 돌봄계획 등에 따른 사례의 상황, 주요 욕구 등"
                  defaultValue={""}
                  value={dongMeeting?.dongMeeting.caseContent}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>
              검토사항
              <br />
              (주요안건)
            </p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="사례회의에서 다룰 주요 쟁점"
                  defaultValue={""}
                  value={dongMeeting?.dongMeeting.agenda}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>
              회의내용 및<br />
              결정사항
            </p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="회의내용 및 결정사항 입력"
                  style={{ height: 110 }}
                  defaultValue={""}
                  value={dongMeeting?.dongMeeting.mtContent}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>향후계획</p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="향후계획 입력"
                  style={{ height: 90 }}
                  defaultValue={""}
                  value={dongMeeting?.dongMeeting.puturePlan}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TabTable4 = ({ guMeeting }: { guMeeting?: DongConference }) => {
  const crtDt = moment(guMeeting?.guMeeting.crtDt).format("YYYY.MM.DD(dd)");
  const mtDt = moment(guMeeting?.guMeeting.mtDt).format(
    "YYYY-MM-DD(dd) A HH:MM"
  );
  return (
    <div className="padding tab_content" data-id={4}>
      <p className="date">작성일 : {crtDt}</p>
      <div className="meeting">
        <h4>권역별 사례회의 기록지</h4>
        <div className="meet01">
          <div className="text_input">
            <p>회의 개요</p>
            <div className="input_wrap">
              <div
                className="input_box mr8"
                style={{ width: "50%" }}
              >
                <input
                  type="text"
                  className="w100"
                  placeholder="회의장소 입력"
                  value={guMeeting?.guMeeting.mtPlace}
                  disabled
                />
              </div>
              <div className="input_box" style={{ width: "30%" }}>
                <input
                  type="text"
                  id="birth"
                  className="w100"
                  placeholder="회의일시"
                  value={mtDt}
                  disabled
                />
                {/* onfocus="(this.type='date')" onblur="(this.type='text')"  */}
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>참석자</p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <input
                  type="text"
                  className="w100"
                  placeholder="참석자 입력(콤마로 구분하여 입력)"
                  value={guMeeting?.guMeeting.attnd}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>사례내용</p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="돌봄필요도 평가, 돌봄계획 등에 따른 사례의 상황, 주요 욕구 등"
                  defaultValue={""}
                  value={guMeeting?.guMeeting.caseContent}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>
              검토사항
              <br />
              (주요안건)
            </p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="사례회의에서 다룰 주요 쟁점"
                  defaultValue={""}
                  value={guMeeting?.guMeeting.agenda}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>
              회의내용 및<br />
              결정사항
            </p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="회의내용 및 결정사항 입력"
                  style={{ height: 110 }}
                  defaultValue={""}
                  value={guMeeting?.guMeeting.mtContent}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>향후계획</p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="향후계획 입력"
                  style={{ height: 90 }}
                  defaultValue={""}
                  value={guMeeting?.guMeeting.puturePlan}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TabContent1 = ({
  lastSum,
  lastEmerSum,
  isSprtMore,
} :
{
  lastSum?: number,
  lastEmerSum?: number,
  isSprtMore: 'Y' | 'N'
}) => {
  const [selected, setSelected] = useState([]);
  const [searchParams] = useSearchParams();
  const [carePlan, setCarePlan] = useState<DongConference>();
  // 기존돌봄 불러오는 State
  const [basicValue, setBasicValue] = useState(String);
  const [basicValueInfo, setBasicValueInfo] = useState(String);
  // 기존돌봄 여부
  const [basicBoolean, setBasicBoolean] = useState(false);
  // 긴급돌봄 불러오는 State
  const [emerCount, setEmerCount] = useState(Number);
  const [emerBeginDt, setEmerBeginDt] = useState(String);
  const [emerEndDt, setEmerEndDt] = useState(String);
  const [emerTime, setEmerTime] = useState(Number);
  const [emerCost, setEmerCost] = useState(Number);
  // 긴급돌봄 여부
  const [emerBoolean, setEmerBoolean] = useState(false);
  // 광주 + 돌봄 여부
  const [gjBoolean, setGjBoolean] = useState(false);
  // 광주+돌봄 계획비용 State
  const [totalCost, setTotalCost] = useState(0);
  // 긴급돌봄 잔액 State
  const [emerChange, setEmerChange] = useState(600000);
  // 광주+돌봄 잔액 State
  const [gjChange, setGjChange] = useState(1500000);
  //작성일
  const [carePlanDate, setCarePlanDate] = useState({} as ServiceDecisionVO)
  const [dateLoad, setDateLoad] = useState(false)
  const sdUid = searchParams.get("sdUid");
  const csUid = searchParams.get("csUid");
  const syUid = searchParams.get("syUid");
  const { register, watch, getValues, setValue, control, reset } =
    useForm<DollbomPlanFormInput>({
      defaultValues: {
        sdUid: Number(sdUid),
        syUid: Number(syUid),
        serviceStatCd: "의뢰",
        stopYn: "N",
        modifyCheck: 0,
      },
    });
  useEffect(() => {
    DollbomPlanAPI.getCarePlanDate({
      syUid: Number(syUid),
    }).then((res) => {
      setCarePlanDate(res)
      setDateLoad(true)
    })

    DollbomPlanAPI.getDongConferencePlan({
      syUid: Number(syUid),
      csUid: Number(csUid),
    }).then((res) => {
      // console.log(">>> carePlan", res);
      setCarePlan(res);
      const gjList = res.carePlan.filter((service) => service.cateCd >= 1 && service.cateCd <= 7).length
      // console.log(gjList);
      if (gjList > 0) {
        // console.log("광주돌봄");
        setGjBoolean(true);
      }
      if (res.carePlan.filter((service) => service.cateCd == 0).length >= 1) {
        setBasicBoolean(true);
        setBasicValue(
          res.carePlan
            .filter((service) => service.cateCd == 0)[0]
            .serviceDetail.split("/")[0]
        );
        setBasicValueInfo(
          res.carePlan
            .filter((service) => service.cateCd == 0)[0]
            .serviceDetail.split("/")[1]
        );
      }

      if (res.carePlan.filter((service) => service.cateCd == 8).length >= 1) {
        setEmerBoolean(true);
        setEmerCount(
          Number(
            res.carePlan.filter((service) => service.cateCd == 8)[0]
              .totalCount ?? 0
          )
        );
        setEmerBeginDt(
          res.carePlan.filter((service) => service.cateCd == 8)[0].beginDate
        );
        setEmerEndDt(
          res.carePlan.filter((service) => service.cateCd == 8)[0].endDate
        );
        setEmerTime(
          res.carePlan.filter((service) => service.cateCd == 8)[0].times
        );
        setEmerCost(
          res.carePlan.filter((service) => service.cateCd == 8)[0].cost
        );
      }
    });
    if(lastSum) {
      setGjChange(1500000 - lastSum)
    }
    if(lastEmerSum) {
      setEmerChange(600000 - lastEmerSum)
    }
  }, []);
  // 잔액 계산
  useEffect(() => {
    setTimeout(() => {
      let sum = 0;
      const rows =
        document.querySelectorAll<HTMLTableCellElement>(".addTable tr");
      // console.log("rows >>>>>>>", rows);
      rows.forEach((row) => {
        const testCost = row.querySelectorAll<HTMLTableCellElement>("td");
        const getInputTag = row.querySelectorAll<HTMLInputElement>("input");
        let curCateCd = ""
        let curSiUid = ""
        getInputTag.forEach((input) => {
          if(input.className === "cateCd") {
            curCateCd = input.value
          }
          if(input.className === "siUid") {
            curSiUid = input.value
          }
        })
        // console.log("cateCd : ", curCateCd)
        if(Number(curCateCd) !== 3 && Number(curCateCd) !== 5 && Number(curCateCd) !== 6) {
          sum += Number(testCost[3].textContent?.split("원")[0].trim().replace(/,/g, ""))
        }
        // if (testCost[2].textContent != "-") {
        //   const testCost2 = Number(
        //     testCost[3].textContent?.split("원")[0].trim().replace(/,/g, "") ||
        //     ""
        //   );
        //   sum += testCost2;
        // }
      });
      setTotalCost(sum);
      // console.log("sum >>>", totalCost);
    }, 500);
  }, [totalCost]);
  useEffect(() => {
    if (basicValue) {
      // console.log("basicValue 있음");
      setValue("basicList", basicValue);
      setValue("basicDetail", basicValueInfo);
    }
  }, [basicValue, setValue]);
  useEffect(() => {
    if (emerCount) {
      setValue("emerDay", emerCount);
      setValue("emerBeginDt", emerBeginDt);
      setValue("emerEndDt", emerEndDt);
      setValue("emerTime", emerTime);
    }
  }, [emerCount, setValue]);

  return (
    <div className="tab_content3" data-id={0}>
      {dateLoad && <p className="date">작성일 : {moment(carePlanDate.ServiceDecisionVO.crtDt).format('yyyy-MM-DD(dd)')}</p>}
      <CarePlanTable
            basicBoolean={basicBoolean}
            basicValue={basicValue}
            basicValueInfo={basicValueInfo}
            emerBoolean={emerBoolean}
            emerCount={emerCount}
            emerChange={emerChange}
            emerCost={emerCost}
            emerTime={emerTime}
            emerBeginDt={emerBeginDt}
            emerEndDt={emerEndDt}
            gjBoolean={gjBoolean}
            gjChange={gjChange}
            carePlan={carePlan!}
            totalCost={totalCost}
            isSprtMore={isSprtMore}
            />
    </div>
  );
};

export default DollbomConfirmationPage;
