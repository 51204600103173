import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import DollbomPlanAPI, { PrevPlans } from "../../../apis/DollbomPlanAPI";
import ServicePlanAPI from "../../../apis/ServicePlanAPI";
import BackIcon from "../../../assets/ic_backlist.png";
import { useDialog } from "../../../dialogs/DialogProvider";
import BaseServiceProvider from "../../../models/BaseServiceProvider";
import CareRequest from "../../../models/CareRequest";
import ConferencePlan from "../../../models/ConferencePlan"
import DongConference from "../../../models/DongConference";
import NaEvaluationTargetInfo from "../../../models/NaEvaluationTargetInfo";
import NeedsAssessmentJoin from "../../../models/NeedsAssessmentJoin";
import ServicePlanDetails from "../../../models/ServicePlanDetails";
import ServiceTargetYear from "../../../models/ServiceTargetYear";
import CareRequestFormInput from "../../../models/form/CareRequestFormInput";
import "./CareRequestPage.scss";
import ServiceResult from "./DollbomPlanComponents/ServiceResult";
import NeedAsTabTable1 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable1";
import NeedAsTabTable2 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable2";
import NeedAsTabTable3 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable3";
import NeedAsTabTable4 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable4";
import NeedAsTabTable5 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable5";
import NeedAsTabTable6 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable6";
import NeedAsBottom from "./DollbomPlanComponents/TabTableComponents/NeedAsBottom";
import SprtMoreData from "../../../models/SprtMoreData";
import DollbomPlanSupportAPI from "../../../apis/DollbomPlanSupportAPI";
import TargetInfoBar from "./DollbomPlanComponents/TargetInfoBar";

// 작성자: 김혜정
// 작성일: 2023.02.27
// 돌봄의뢰서 페이지

/**
 * 수정자: 홍준표
 * 수정일: 2023.03.14
 * 데이터 불러오기 작성
 */
// @param spyUid 돌봄계획 일련번호
// @param onBackPressed `목록으로 돌아가기` 눌렀을 때의 동작

const CareRequestPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { page } = useParams();
  const [details, setDetails] = useState<ServicePlanDetails>();
  const [detailLoad, setDetailLoad] = useState(true)
  const [targetInfo, setTargetInfo] = useState<ServiceTargetYear>();
  const sdUid = parseInt(searchParams.get("sdUid") as string);
  const csUid = parseInt(searchParams.get("csUid") as string);
  const syUid = parseInt(searchParams.get("syUid") as string);
  const stUid = searchParams.get("stUid");
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [totalCost, setTotalCost] = useState(0);
  const [emerCost, setEmerCost] = useState(0);
  // 만 나이 계산
  const [targetBrthdy, setTargetBrthdy] = useState(0);
  const [targetMonth, setTargetMonth] = useState(0);
  const [targetDay, setTargetDay] = useState(0);
  const [evaluationData, setEvaluationData] = useState<NeedsAssessmentJoin>();
  // 돌봄계획수립 목록
  const [carePlan, setCarePlan] = useState<CareRequest>();
  // 돌봄계획 수립 목록 비교
  const [gjList, setGjList] = useState<DongConference>();
  // 광주+돌봄 잔액 State
  const [gjChange, setGjChange] = useState(1500000);
  //긴급돌봄 잔액
  const [emerCharge, setEmerCharge] = useState(600000);
  // 서비스 결과 및 비용지원 돌봄계획 여부
  const [dollbomPlanInfo, setDollbomPlanInfo] = useState(false);
  const [hasUsing, setHasUsing] = useState(false);
  const [hasEmer, setHasEmer] = useState(false);

  // 질병 및 증상 추가한거
  const [illAddList, setIllAddList] = useState<string[]>([]);

  const handleBackPressed = () => {
    navigate(-1);
  };

  const [name, setName] = useState("");
  const [selectedServiceProvider, setSelectedServiceProvider] =
    useState<BaseServiceProvider>();
  const [serviceProviders, setServiceProviders] = useState(
    [] as BaseServiceProvider[]
  );
  const [target, setTarget] = useState<NaEvaluationTargetInfo>();
  const [evalData, setEvalData] = useState<NeedsAssessmentJoin>();
  
  //이전 돌봄계획 데이터
  const [prevPlans, setPrevPlans] = useState<PrevPlans>({} as PrevPlans);
  const [prevPlansLoading, setPrevPlansLoading] = useState(false)

  const { showDialog } = useDialog();

  const [cate1, setCate1] = useState(0)
  const [cate2, setCate2] = useState(0)
  const [cate3, setCate3] = useState(0)
  const [cate4, setCate4] = useState(0)
  const [cate5, setCate5] = useState(0)
  const [cate6, setCate6] = useState(0)
  const [cate7, setCate7] = useState(0)

  const [aiCallInfo, setAiCallInfo] = useState<ConferencePlan>({} as ConferencePlan)
  const [ictCheckInfo, setIctCheckInfo] = useState<ConferencePlan>({} as ConferencePlan)

  //추가지원
  const [sprtMoreData, setSprtMoreData] = useState<SprtMoreData>()
  //추가지원 승인여부
  const [isSprtMore, setIsSprtMore] = useState<'Y' | 'N'>('N')
  
  // 대상자 정보
  useEffect(() => {
    DollbomPlanAPI.getDetails({
      sdUid: Number(sdUid),
      csUid: Number(csUid),
    }).then((res) => {
      setDetails(res);
      // console.log(res);
      // 만 나이 계산
      setTargetBrthdy(Number(res.targetInfo.brthdy.split("-")[0]));
      setTargetMonth(Number(res.targetInfo.brthdy.split("-")[1]));
      setTargetDay(Number(res.targetInfo.brthdy.split("-")[2]));
      setTargetInfo(details?.targetInfo);
      setDetailLoad(false)
    });
  }, [page]);
  // 제공기관 목록 불러오기
  useEffect(() => {
    ServicePlanAPI.getEvaluationTargetInfo({
      sdUid: Number(sdUid),
    }).then((res) => {
      // console.log("대상자 정보 : ", res);
      setTarget(res);
      setLoading(false);
    });
    // setServiceProviders(BaseServiceProvider.mockList);
    ServicePlanAPI.getEvaluationData({
      csUid: csUid,
    }).then((res) => {
      if (!res) {
        // console.log("필요도평가 정보 불러오기 실패");
      } else {
        // console.log("필요도 평가 : ", res);
        setEvalData(res as NeedsAssessmentJoin);
        setLoading2(false);
      }
    });
  }, [name]);

  // 잔액 계산
  useEffect(() => {
    setTimeout(() => {
      let sum = 0;
      const rows =
        document.querySelectorAll<HTMLTableCellElement>(".reQuestTable tr");
      // console.log("rows >>>>>>>", rows);
      rows.forEach((row) => {
        const testCost = row.querySelectorAll<HTMLTableCellElement>("td");
        const getInputTag = row.querySelectorAll<HTMLInputElement>("input");
        let curCateCd = ""
        let curSiUid = ""
        getInputTag.forEach((input) => {
          if(input.className === "cateCd") {
            curCateCd = input.value
          }
          if(input.className === "siUid") {
            curSiUid = input.value
          }
        })
        // console.log("cateCd : ", curCateCd)
        if(Number(curCateCd) !== 3 && Number(curCateCd) !== 5 && Number(curCateCd) !== 6) {
          sum += Number(testCost[3].textContent?.split("원")[0].trim().replace(/,/g, ""))
        }
        // if (testCost[2].textContent != "-") {
        //   const testCost2 = Number(
        //     testCost[3].textContent?.split("원")[0].trim().replace(/,/g, "") ||
        //     ""
        //   );
        //   sum += testCost2;
        // }
      });
      setTotalCost(sum);
      // console.log("sum >>>", totalCost);
    }, 500);
  }, [totalCost]);

  useEffect(() => {
    setTimeout(() => {
      let sum = 0;
      const rows =
        document.querySelectorAll<HTMLTableCellElement>(".reQuestTable-emer tr");
      // console.log("rows >>>>>>>", rows);
      rows.forEach((row) => {
        const testCost = row.querySelectorAll<HTMLTableCellElement>("td");
        const getInputTag = row.querySelectorAll<HTMLInputElement>("input");
        let curCateCd = ""
        let curSiUid = ""
        getInputTag.forEach((input) => {
          if(input.className === "cateCd") {
            curCateCd = input.value
          }
          if(input.className === "siUid") {
            curSiUid = input.value
          }
        })
        // console.log("cateCd : ", curCateCd)
        if(Number(curCateCd) !== 3 && Number(curCateCd) !== 5 && Number(curCateCd) !== 6) {
          sum += Number(testCost[3].textContent?.split("원")[0].trim().replace(/,/g, ""))
        }
        // if (testCost[2].textContent != "-") {
        //   const testCost2 = Number(
        //     testCost[3].textContent?.split("원")[0].trim().replace(/,/g, "") ||
        //     ""
        //   );
        //   sum += testCost2;
        // }
      });
      setEmerCost(sum);
      // console.log("sum >>>", totalCost);
    }, 500);
  }, [emerCost]);

  useEffect(() => {
    DollbomPlanAPI.getEvaluationData({
      sdUid: Number(sdUid),
      csUid: Number(csUid),
    }).then((res) => {
      setEvaluationData(res);
      setIllAddList(JSON.parse(res.jsonData.illAdd));
      // console.log(res.jsonData.illAdd);
      // console.log(res);
    });

    DollbomPlanSupportAPI.getSprtMoreData({
      syUid: Number(syUid), 
    }).then((res) => {
      if(res) {
        if(res.cfmYn === 'Y') {
          setGjChange((cur) => cur + 1500000)
          setEmerCharge((cur) => cur + 600000)
        }
        setIsSprtMore(res.cfmYn)
        setSprtMoreData(res)
      }
    })

    DollbomPlanSupportAPI.getSprtMoreData({
      syUid: Number(syUid), 
    }).then((res) => {
      if(res) {
        setIsSprtMore(res.cfmYn)
        setSprtMoreData(res)
      }
    })

    DollbomPlanAPI.prevPlans({
      stUid: Number(stUid),
      syUid: Number(syUid),
    }).then((res) => {
      // console.log("이전 돌봄계획 : ", res)
      // setSafeSprtCharge(res.safeSprtCharge)
      // setSimpleFixCount(res.simpleFixCount)
      // setCleaningCount(res.cleaningCount)
      // setPestControlCount(res.pestControlCount)
      if(res.lastSum) {
        setGjChange((cur) => cur - res.lastSum)
      }
      if(res.lastEmerSum) {
        setEmerCharge((cur) => cur - res.lastEmerSum)
      }
      if(res.prevPlanBox) {
        res.prevPlanBox.map((prevPlanList) => {
          prevPlanList.map((prevPlan) => {
            switch(Number(prevPlan.cateCd)) {
              case 1:
                setCate1((cur) => cur + prevPlan.cost)
                break;
              case 2:
                setCate2((cur) => cur + prevPlan.cost)
                break;
              case 3:
                setCate3((cur) => cur + prevPlan.cost)
                break;
              case 4:
                setCate4((cur) => cur + prevPlan.cost)
                break;
              case 5:
                setCate5((cur) => cur + prevPlan.cost)
                break;
              case 6:
                setCate6((cur) => cur + prevPlan.cost)
                break;
              case 7:
                setCate7((cur) => cur + prevPlan.cost)
                break;
            }
          })
        })
      }
      setPrevPlans(res)
      setPrevPlansLoading(true)
    })

    //이전 추가지원 여부
    DollbomPlanSupportAPI.pastSprtHistory({
      syUid: Number(syUid),
      stUid: Number(stUid),
    }).then((res) => {
      // console.log("이전 추가지원 여부 : ", res)
      if (!res.success) {
        setIsSprtMore('Y')
      }
    })
  }, []);

  // 돌봄계획 수립 목록 불러오기
  useEffect(() => {
    DollbomPlanAPI.getCareRequest({
      syUid: Number(syUid),
      // csUid: Number(csUid),
    }).then((res) => {
      setCarePlan(res);
      if(res.emerUsingList.length > 0) {
        res.emerUsingList.map((item) => {
          if(Number(item.cateCd) === 0) {  //using
            setHasUsing(true)
          } else if(Number(item.cateCd) === 8) {  //emer
            setHasEmer(true)
          }
        })
      }
      // console.log("불러온 데이터 : ", res)
    });
  }, []);
  // 돌봄계획 수립 목록 비교 데이터 불러오기
  useEffect(() => {
    DollbomPlanAPI.getDongConferencePlan({
      syUid: Number(syUid),
    }).then((res) => {
      const aiTemp = res.gjList.find((si) => si.serviceNm === "AI 안부전화")
      const ictTemp = res.gjList.find((si) => si.serviceNm === "ICT 활용 안전체크")
      setAiCallInfo(aiTemp!)
      setIctCheckInfo(ictTemp!)
      setGjList(res);
      // console.log("res.gjList >>>>>>", res);
    })
  }, [])

  // 의뢰 진행
  const handleRequest = async () => {
    if (window.confirm("의뢰를 진행하시겠습니까?")) {
      const rows =
        document.querySelectorAll<HTMLTableRowElement>(".reQuestTable tr");
      const data: CareRequestFormInput[] = [];
      rows.forEach((row) => {
        const spyUid = row.querySelector<HTMLInputElement>(".spyUid");
        const dptUid = row.querySelector<HTMLSelectElement>(".dptUid");
        const obj = {
          spyUid: Number(spyUid?.value),
          dptUid: Number(dptUid?.value),
        };
        data.push(obj);
      });
      // console.log(">>>>>>> reQuest >>>>>>", data);
      const success = await DollbomPlanAPI.insertRequest({
        syUid: Number(syUid),
        planYears: data,
      });
      if (success) {
        navigate("/service-plan", { replace: true });
      } else {
        // console.log(">>>>> failure");
      }
    }
  };
  return (
    <main className="careRequestPage">
      {loading || loading2 ? (
        <h1>Loading....</h1>
      ) : (
        <section>
          <article className="detail_top">
            <div className="nav_list">
              <p>
                돌봄계획 / <span>서비스의뢰</span>
              </p>
              <button type="button" className="mt8" onClick={handleBackPressed}>
                <span>
                  <img src={BackIcon} alt="목록으로 돌아가기 아이콘" />
                </span>
                목록으로 돌아가기
              </button>
            </div>
            <div className="btn_wrap">
              <button type="submit" className="submit" onClick={handleRequest}>
                의뢰
              </button>
            </div>
          </article>
          {!detailLoad && 
          <TargetInfoBar details={details!} targetMonth={targetMonth} targetBrthdy={targetBrthdy} targetDay={targetDay} />
          }   
          <article className="care_plan mt20">
            <NaPart evaluationData={evaluationData} illAddList={illAddList} />
            {
              prevPlansLoading &&
            <div className="right">
              <div className="care_plan_write">
                <div className="padding">
                  <p className="date">
                    작성일 : {moment(carePlan?.crtUpdDate.firstCrt).format('yyyy.MM.DD(dd)')} / 최종수정일 : {moment(carePlan?.crtUpdDate.lastUpd).format('yyyy.MM.DD(dd)')}
                  </p>
                  {hasUsing ?
                  <>
                    <h4>기존돌봄 의뢰서</h4>
                    <div className="care03">
                      <div className="input_wrap">
                        {/* 테이블 */}
                        <div className="care_plan_list">
                          <table>
                            <thead>
                              <tr>
                                <th>서비스 종류</th>
                                <th>지원시간(내용)</th>
                                <th>지원기간</th>
                                <th>비용</th>
                              </tr>
                            </thead>
                            <tbody className="reQuestTable-using">
                              {carePlan?.emerUsingList.map((plan, index) => (
                                Number(plan.cateCd) === 0 ?
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="hidden"
                                      className="cateCd"
                                      value={plan.cateCd}
                                    />
                                    <input
                                      type="hidden"
                                      className="siUid"
                                      value={plan.siUid}
                                    />
                                    <input
                                      type="hidden"
                                      className="spyUid"
                                      value={plan.spyUid}
                                    />
                                    기존돌봄
                                    &nbsp; &gt; &nbsp;
                                    {gjList?.gjList.map((list) => (
                                      plan.siUid === list.siUid && list.serviceNm
                                    ))}
                                  </td>
                                  <td>
                                      {plan.serviceDetail && plan.serviceDetail}
                                  </td>
                                  <td>
                                    -
                                  </td>
                                  <td>
                                    -
                                  </td>
                                </tr> : null
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                  : null}
                  {hasEmer ?
                  <>
                    <h4>긴급돌봄 의뢰서</h4>
                    <div className="care03">
                      <div className="input_wrap">
                        {/* 테이블 */}
                        <div className="care_plan_list">
                          <table>
                            <thead>
                              <tr>
                                <th>서비스 종류</th>
                                <th>지원시간(내용)</th>
                                <th>지원기간</th>
                                <th>비용</th>
                              </tr>
                            </thead>
                            <tbody className="reQuestTable-emer">
                              {carePlan?.emerUsingList.map((plan, index) => (
                                Number(plan.cateCd === 8) ?
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="hidden"
                                      className="cateCd"
                                      value={plan.cateCd}
                                    />
                                    <input
                                      type="hidden"
                                      className="siUid"
                                      value={plan.siUid}
                                    />
                                    <input
                                      type="hidden"
                                      className="spyUid"
                                      value={plan.spyUid}
                                    />
                                    긴급돌봄
                                    &nbsp; &gt; &nbsp;
                                    {gjList?.gjList.map((list) => (
                                      plan.siUid === list.siUid && list.serviceNm
                                    ))}
                                  </td>
                                  {Number(plan.cateCd) !== 3 && Number(plan.cateCd) !== 5 && Number(plan.cateCd) !== 6 ?
                                  <td>
                                      {plan.totalCount && plan.totalCount + "회"}
                                      {plan.cycleUnit && "/" + plan.cycleUnit}
                                      {plan.weekday && "/" + plan.weekday}
                                      {(Number(plan.cateCd) === 1) && "/" + plan.times + "시간"}
                                  </td>
                                  :
                                  <td>
                                    {plan.totalCount ? plan.totalCount + "회" : ""}{(plan.totalCount && plan.serviceDetail) ? "/" + plan.serviceDetail : plan.serviceDetail}
                                  </td>
                                  }
                                  <td>
                                    {!plan.beginDate && "-"}
                                    {plan.beginDate}
                                    {plan.endDate && ` ~ ${plan.endDate}`}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {(Number(plan.cateCd) !== 3 && Number(plan.cateCd) !== 5 && Number(plan.cateCd) !== 6) ?
                                      <span className="red">
                                        {plan.cost.toLocaleString()}
                                      </span>
                                      :
                                      <span className="gray">
                                        {plan.cost.toLocaleString()}
                                      </span>
                                      }
                                    {/* {!plan.beginDate &&
                                      <span className="gray">
                                        {plan.cost.toLocaleString()}
                                      </span>} */}
                                      {" "}
                                    원
                                  </td>
                                </tr> : null
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                  : null}
                  <h4>광주+돌봄 의뢰서</h4>
                  <div className="care03">
                    <div className="input_wrap">
                      {/* 테이블 */}
                      <div className="care_plan_list">
                        <table>
                          <thead>
                            <tr>
                              <th>서비스 종류</th>
                              <th>지원시간(내용)</th>
                              <th>지원기간</th>
                              <th>비용</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody className="reQuestTable">
                            {carePlan?.planList.map((plan, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="hidden"
                                    className="cateCd"
                                    value={plan.cateCd}
                                  />
                                  {plan.weekday && (
                                    <input
                                      type="hidden"
                                      className="weekday"
                                      value={plan.weekday}
                                    />
                                  )}
                                  {plan.cycleUnit && (
                                    <input
                                      type="hidden"
                                      className="cycleUnit"
                                      value={plan.cycleUnit}
                                    />
                                  )}
                                  <input
                                    type="hidden"
                                    className="siUid"
                                    value={plan.siUid}
                                  />
                                  <input
                                    type="hidden"
                                    className="spyUid"
                                    value={plan.spyUid}
                                  />
                                  {gjList?.gjList.map((gubun) => {
                                    if (plan.siUid === gubun.siUid) {
                                      if (Number(gubun.cateCd) === 1) {
                                        return "가사지원"
                                      } else if (Number(gubun.cateCd) === 2) {
                                        return "식사지원"
                                      } else if (Number(gubun.cateCd) === 3) {
                                        return "동행지원"
                                      } else if (Number(gubun.cateCd) === 4) {
                                        return "건강지원"
                                      } else if (Number(gubun.cateCd) === 5) {
                                        return "안전지원"
                                      } else if (Number(gubun.cateCd) === 6) {
                                        return "주거편의"
                                      } else if (Number(gubun.cateCd) === 7) {
                                        return "일시보호"
                                      }
                                    }
                                  })}
                                  &nbsp; &gt; &nbsp;
                                  {gjList?.gjList.map((list) => (
                                    plan.siUid === list.siUid && list.serviceNm
                                  ))}
                                </td>
                                {Number(plan.cateCd) !== 3 && Number(plan.cateCd) !== 5 && Number(plan.cateCd) !== 6 ?
                                <td>
                                    {plan.totalCount && plan.totalCount + "회"}
                                    {plan.cycleUnit && "/" + plan.cycleUnit}
                                    {plan.weekday && "/" + plan.weekday}
                                    {(Number(plan.cateCd) === 1) && "/" + plan.times + "시간"}
                                </td>
                                :
                                <td>
                                  {plan.totalCount ? plan.totalCount + "회" : ""}{(plan.totalCount && plan.serviceDetail) ? "/" + plan.serviceDetail : plan.serviceDetail}
                                </td>
                                }
                                {/* {plan.cycleUnit &&
                                  plan.totalCount &&
                                  plan.weekday &&
                                  plan.times && (
                                    <td>
                                      {plan.cycleUnit}&nbsp;{plan.totalCount}
                                      회&nbsp;(
                                      {plan.weekday})&nbsp;{plan.times}시간
                                    </td>
                                  )}
                                {plan.totalCount &&
                                  !plan.serviceDetail &&
                                  !plan.times && <td>{plan.totalCount}회</td>}
                                {!plan.cycleUnit &&
                                  plan.totalCount &&
                                  !plan.serviceDetail &&
                                  plan.times && (
                                    <td>
                                      {plan.totalCount}회{" "}
                                      {plan.times && plan.times}
                                      시간
                                    </td>
                                  )}
                                {plan.serviceDetail && !plan.totalCount && (
                                  <td>{plan.serviceDetail}</td>
                                )}
                                {plan.serviceDetail && plan.totalCount && (
                                  <td>
                                    {plan.totalCount}회({plan.serviceDetail})
                                  </td>
                                )}
                                {!plan.totalCount && !plan.serviceDetail && (
                                  <td style={{ color: "#9da0a8" }}>
                                    지원시간(내용) 없음
                                  </td>
                                )} */}
                                <td>
                                  {!plan.beginDate && "-"}
                                  {plan.beginDate}
                                  {plan.endDate && ` ~ ${plan.endDate}`}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  {(Number(plan.cateCd) !== 3 && Number(plan.cateCd) !== 5 && Number(plan.cateCd) !== 6) ?
                                    <span className="red">
                                      {plan.cost.toLocaleString()}
                                    </span>
                                    :
                                    <span className="gray">
                                      {plan.cost.toLocaleString()}
                                    </span>
                                    }
                                  {/* {!plan.beginDate &&
                                    <span className="gray">
                                      {plan.cost.toLocaleString()}
                                    </span>} */}
                                    {" "}
                                  원
                                </td>
                                <td style={{ width: "22%" }}>
                                  <div
                                    className="name"
                                    style={{ position: "relative" }}
                                  >
                                    {Number(plan.siUid) !== Number(aiCallInfo.siUid) && Number(plan.siUid) !== Number(ictCheckInfo.siUid) ?
                                    <select
                                      className="dptUid"
                                      id="name"
                                      style={{
                                        display: "block",
                                      }}
                                    >
                                      {plan.dptUid && plan.serviceOrgList.map(
                                        (list, dptUid) => (
                                          <option
                                            key={dptUid}
                                            value={list.dptUid}
                                            selected={list.dptUid === plan.dptUid ? true : false}
                                          >
                                            {list.departName}

                                          </option>
                                        )
                                      )}
                                      {!plan.dptUid && plan.serviceOrgList.map(
                                        (list, dptUid) => (
                                          <option
                                            key={dptUid}
                                            value={list.dptUid}
                                          >
                                            {list.departName}

                                          </option>
                                        )
                                      )}

                                    </select>
                                    : null}
                                    
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cost_list">
                  <ul>
                    <li>
                      <p>(광주+돌봄 잔액)</p>
                      <p>
                        <span>{gjChange.toLocaleString()}</span> 원
                      </p>
                    </li>
                    <li>-</li>
                    <li>
                      <p>(계획비용)</p>
                      <p>
                        <span className="red">
                          {totalCost.toLocaleString()}
                        </span>{" "}
                        원
                      </p>
                    </li>
                    <li>=</li>
                    <li>
                      <p>(예상잔액)</p>
                      <p>
                        <span className="blue">
                          {(gjChange - totalCost).toLocaleString()}
                        </span>{" "}
                        원
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>(긴급돌봄 잔액)</p>
                      <p>
                        <span>{emerCharge.toLocaleString()}</span> 원
                      </p>
                    </li>
                    <li>-</li>
                    <li>
                      <p>(계획비용)</p>
                      <p>
                        <span className="red">
                          {emerCost.toLocaleString()}
                        </span>{" "}
                        원
                      </p>
                    </li>
                    <li>=</li>
                    <li>
                      <p>(예상잔액)</p>
                      <p>
                        <span className="blue">
                          {(emerCharge - emerCost).toLocaleString()}
                        </span>{" "}
                        원
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              {
                (prevPlansLoading && prevPlans.prevPlanBox) &&
                <ServiceResult prevPlans={prevPlans} isSprtMore={isSprtMore}/>
              }
              
            </div>
            }
            
          </article>
        </section>
      )}
    </main>
  );
};

const NaPart = ({
  evaluationData,
  illAddList,
}: {
  evaluationData?: NeedsAssessmentJoin;
  illAddList: string[];
}) => {
  const [tabs, setTabs] = useState(0);
  return (
    <div className="left mr20">
      <div className="care_evaluation">
        {/* 탭 */}
        <div className="tabs">
          <button
            type="button"
            className={`tab ${tabs === 0 ? "tab_active" : "non_active"}`}
            onClick={() => setTabs(0)}
            data-id={0}
          >
            신체적 영역
          </button>
          <button
            type="button"
            className={`tab ${tabs === 1 ? "tab_active" : "non_active"}`}
            onClick={() => setTabs(1)}
            data-id={1}
          >
            정서적 영역
          </button>
          <button
            type="button"
            className={`tab ${tabs === 2 ? "tab_active" : "non_active"}`}
            onClick={() => setTabs(2)}
            data-id={2}
          >
            추가정보(생활환경)
          </button>
          <button
            type="button"
            className={`tab ${tabs === 3 ? "tab_active" : "non_active"}`}
            onClick={() => setTabs(3)}
            data-id={3}
          >
            추가정보(사전검사)
          </button>
          <button
            type="button"
            className={`tab ${tabs === 4 ? "tab_active" : "non_active"}`}
            onClick={() => setTabs(4)}
            data-id={4}
          >
            광주+돌봄 지원대상 여부
          </button>
          <button
            type="button"
            className={`tab ${tabs === 5 ? "tab_active" : "non_active"}`}
            onClick={() => setTabs(5)}
            data-id={5}
          >
            긴급돌봄 지원대상 여부
          </button>
        </div>
        {/* 신체적 영역 */}
        {tabs === 0 && <NeedAsTabTable1 evaluationData={evaluationData} illAddList={illAddList} />}
        {/* 정서적 영역 */}
        {tabs === 1 && <NeedAsTabTable2 evaluationData={evaluationData}/>}
        {/* 추가정보(생활환경) */}
        {tabs === 2 && <NeedAsTabTable3 evaluationData={evaluationData} />}
        {/* 추가정보(사전검사) */}
        {tabs === 3 && <NeedAsTabTable4 evaluationData={evaluationData} />}
        {/* 광주+돌봄 지원대상 여부 */}
        {tabs === 4 && <NeedAsTabTable5 evaluationData={evaluationData} />}
        {/* 긴급돌봄 지원대상 여부 */}
        {tabs === 5 && <NeedAsTabTable6 evaluationData={evaluationData} />}
      </div>
      <NeedAsBottom evaluationData={evaluationData!} />
    </div>
  );
};

export default CareRequestPage;
