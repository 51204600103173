import React, { FormEvent, useState } from "react";

import CloseIcon from "../../assets/ic_close.png";
import FileUtil from "../../utils/FileUtil";
import { DialogProps } from "../DialogProvider";

import axios from "axios";
import { serialize } from "class-transformer";
import { useNavigate } from "react-router-dom";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.21
 *
 * 의무대상자 등록 팝업
 * @param props
 */

export interface practice {
  fileName : String;
  downloadFileName? : String;
}

const ObiligationDialog = (props: DialogProps, callback?: Function) => {
  const [file, setFile] = useState<File>();
  const navigate = useNavigate();

  const handleClose = () => {
    props.onClose?.();
  };


  /**
   * 작성: 조규형
   * 작성일: 2023.04.03
   * 
   * 의무대상자 등록 템플릿 다운로드
   */
  const handleTemplateDownloadClick = async (param: practice) => {
    try {
      const response = await axios.get("/api/serviceTarget/attach/"+param.fileName+"/"+param.downloadFileName, {
        responseType: "blob"
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", param.downloadFileName+".xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0]);
  };

  const onSubmit = async () => {
    // alert("</k>")
    // return axios
    // .get("/resources/file/CareTargetRegisterTemplate.xlsx");
    
    // resources/file/CareTargetRegisterTemplate.xlsx



    if(file !== undefined){
      const formData = new FormData();
      formData.append("uploadFile", file)
      try {
        const response = await axios.post("/api/serviceTarget/registration", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        // console.log(response.data);
        // window.alert(response.data)
        window.alert("의무대상 등록 완료")
        navigate(0);
      } catch (error) {
        window.alert(error)
        // console.log(error);
      }
    }else{
      // console.log(error);
    }
    


    // console.log(file)
    // FileUtil.getFilePath(file as File).then((res) => {
    //   console.log(res)
    // })
    // if(callback) {
    //   callback(file)
    // }
  }

  return (
    <div className="mypage_popup popup_black" {...props}>
      <div className="obligation_popup_inner popup_white">
        <div className="popup_tit">
          <h3>의무대상자 등록</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={onSubmit}>
            <div className="template">
              <p>※ 템플릿을 다운로드 받으신 후 등록해주세요</p>
              <button
                type="button"
                className="template_download"
                onClick={() => handleTemplateDownloadClick({fileName: "CareTargetRegisterTemplate.xlsx", downloadFileName: "대상자일괄등록템플릿"})}
              >
                템플릿 다운로드
              </button>
            </div>
            <div className="template_upload">
              <label htmlFor="template_file">파일찾기</label>
              <input
                className="upload-name"
                id="upload-file"
                placeholder="선택된 파일이 없습니다"
                value={file?.name}
              />
              <input
                type="file"
                id="template_file"
                name="uploadFile"
                onChange={handleFileChange}
              />
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="button" className="submit" onClick={() => onSubmit()}>
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ObiligationDialog;
