import React, { useState } from "react";

import ArrowIcon from "../../assets/ic_arrow.png";
import Next2Icon from "../../assets/ic_next2.png";
import Next1Icon from "../../assets/ic_next.png";
import Prev2Icon from "../../assets/ic_prev2.png";
import Prev1Icon from "../../assets/ic_prev.png";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.23
 * 
 * 수정자: 홍준표
 * 수정일: 2023.02.28
 *
 * @example
 * <PaymentDataTable
 *   expandable
 *   columnTitles={['제목', '내용']}
 *   rows={[
 *     createPaymentTableRow({
 *       cells: [
 *         createPaymentTextCell({ text: '제목' })
 *         createPaymentTextCell({ text: '내용' })
 *       ],
 *       expandContent: (<div>펼쳐졌을 때 보여줄 내용</div>)
 *     })
 *   ]}
 * />
 * @end
 *
 * 한 행을 나타내는 interface
 * @param expandContent 행이 펼쳐졌을 때 보여줄 내용
 * @param cells 행이 가진 셀
 * @param spyUid 아이템 id
 * @param state 지급상태
 */
export interface PaymentDataTableRow {
  expandContent?: React.ReactNode;
  cells: PaymentDataTableCell[];
  spyUid: number;
  sbUid: number;
  status: string;
}
export const createPaymentRow = (row: PaymentDataTableRow) => row;

/**
 * 셀의 타입은 Text 또는 Button 둘 중 하나여야 함
 */
export type PaymentDataTableCell = PaymentDataTableTextCell | PaymentDataTableButtonCell;

/**
 * 셀이 텍스트인 경우에 사용하는 interface
 * @param text 셀 내용
 * @param ellipsis 생략 사용 여부
 * @param d_day 디데이
 * @param spyUid 아이템 고유식별번호
 * @param isComplete 지급완료여부
 */
export interface PaymentDataTableTextCell {
  text: string;
  ellipsis?: boolean;
  d_day?: number;
  spyUid?: number;
}
export const createPaymentTextCell = (cell: PaymentDataTableTextCell) => cell;
const isTextCell = (cell: PaymentDataTableCell): cell is PaymentDataTableTextCell =>
  "text" in cell;

/**
 * 셀이 버튼인 경우에 사용하는 interface
 * @param type underlined: 검정색 밑줄 버튼, outlined: 파란색 외곽선 버튼, undefined: -
 * @param text 버튼 제목
 * @param onClick 버튼이 클릳되었을 때 호출되는 함수
 */
export interface PaymentDataTableButtonCell {
  type: "underlined" | "outlined" | undefined;
  label: string;
  onClick: () => void;
}
export const createPaymentButtonCell = (cell: PaymentDataTableButtonCell) => cell;
const isButtonCell = (cell: PaymentDataTableCell): cell is PaymentDataTableButtonCell =>
  "label" in cell;

/**
 * 모든 목록에서 사용할 수 있는 데이터 테이블 컴포넌트
 * @param expandable 펼쳐지는 행이 있는지 여부
 * @param columnTitles 열의 제목
 * @param rows 데이터 행
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
const PaymentDataTable = ({
  expandable = false,
  columnTitles,
  rows,
  page,
  totalPageCount,
  onPageChange,
}: {
  expandable?: boolean;
  columnTitles: string[];
  rows: PaymentDataTableRow[];
  page: number;
  totalPageCount: number;
  onPageChange: (page: number) => any;
}) => {
  return (
    <article className="payment">
      <table>
        <thead>
          <tr>
            {expandable && <th className="first"></th>}
            {columnTitles.map((title, index) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
              <Row key={index} row={row} expandable={expandable} state={row.status} page={page}/>
          ))}
        </tbody>
      </table>
      <Pagination page={page} totalPageCount={totalPageCount} onPageChange={onPageChange} />
      
    </article>
  );
};

/**
 * 데이터 테이블의 한 행을 나타내는 컴포넌트
 */
const Row = ({
  row,
  expandable,
  state,
  page,
}: {
  row: PaymentDataTableRow;
  expandable: boolean;
  state: string;
  page: number;
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    if (!expandable) {
      return;
    }

    setExpanded(!expanded);
  };


  return (
    <>
      <tr
      className={`table_title ${expanded ? "on" : "off"}`}
      onClick={handleExpand}
    >
      {row.expandContent && (
        <td className="first">
          <img src={ArrowIcon} alt="접고 펼치기 아이콘" />
        </td>
      )}
      {row.cells.map((cell, index) => (
          <Cell key={index} cell={cell} spyUid={row.spyUid} sbUid={row.sbUid} page={page}/>
      ))}
    </tr>
      
        {row.expandContent && (
          <tr
            className={`table_content ${expanded ? "on" : ""}`}
            style={{ display: expanded ? "table-row" : "none" }}
          >
            <td colSpan={100}>{row.expandContent}</td>
          </tr>
        )}

      
    </>
  );
};

/**
 * 데이터 테이블의 한 셀을 나타내는 컴포넌트
 */
const Cell = ({ cell , spyUid, sbUid, page}: { cell: PaymentDataTableCell , spyUid:number, sbUid:number, page:number}) => {
  const navigate = useNavigate()
  const onClick = (spyUid:number) => {
    const params = new URLSearchParams({
      spyUid: String(spyUid),
      sbUid: String(sbUid),
      page: String(page),
    });
    navigate(`/expense/payment?${params}`)
  }
  if (isTextCell(cell)) {
    if (cell.ellipsis) {
      return (
        <td className="address">
          <div className="ellipsis">
            <p>
              {cell.text}
              {<D_Day d_day={cell.d_day} />}
            </p>
          </div>
        </td>
      );
    } else {
      return (
      <td onClick={() => onClick(spyUid)}>
        {cell.text}
        {<D_Day d_day={cell.d_day} />}
      </td>
      );
    }
  } else {
    return (
      <td>
        {cell.type ? (
          <button
            type="button"
            className={`btn_blue ${cell.type === "underlined" ? "edit" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
              cell.onClick();
            }}
          >
            {cell.label}
          </button>
        ) : (
          "-"
        )}
      </td>
    );
  }
};

/**
 * 디데이를 나타내는 컴포넌트
 */
const D_Day = ({ d_day }: { d_day?: number }) => {
  let d_dayElement = <></>;
  if (d_day) {
    if (d_day === 0) {
      d_dayElement = (
        <>
          <span className="red">D-day</span>
        </>
      );
    } else if (d_day <= 2) {
      d_dayElement = (
        <>
          <span className="yellow">D-{d_day}</span>
        </>
      );
    }
  }
  return d_dayElement;
};

/**
 * 테이블 하단 페이징
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 * @returns
 */
// const Pagination = ({
//   page,
//   onPageChange,
// }: {
//   page: number;
//   onPageChange: (page: number) => void;
// }) => {
//   return (
//     <div id="paging" className="">
//       <p>
//         <span className="numPN m_ar">
//           <a href="#" data-page="1" title="처음 페이지로 이동하기">
//             <img src={Prev2Icon} alt="처음 페이지" />
//           </a>
//         </span>
//         <span className="numPN over left">
//           <a href="#" data-page="1" title="이전 페이지로 이동하기">
//             <img src={Prev1Icon} alt="이전 페이지" />
//           </a>
//         </span>
//         <span className="Present">
//           <a className="on" href="#" data-page="1">
//             1
//           </a>
//         </span>
//         <span>
//           <a href="#" data-page="2">
//             2
//           </a>
//         </span>
//         <span>
//           <a href="#" data-page="3">
//             3
//           </a>
//         </span>
//         <span>
//           <a href="#" data-page="4">
//             4
//           </a>
//         </span>
//         <span>
//           <a href="#" data-page="5">
//             5
//           </a>
//         </span>
//         <span className="numPN  over right">
//           <a href="#" data-page="11" title="다음 페이지로 이동하기">
//             <img src={Next1Icon} alt="다음 페이지" />
//           </a>
//         </span>
//         <span className="numPN m_ar">
//           <a href="#" data-page="14" title="마지막 페이지로 이동하기">
//             <img src={Next2Icon} alt="마지막 페이지" />
//           </a>
//         </span>
//       </p>
//     </div>
//   );
// };

export default PaymentDataTable;
