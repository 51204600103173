import { listenerCount } from "process";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import CloseIcon from "../../assets/ic_close.png";
import Member from "../../models/Member";
import NaEvaluationInfo from "../../models/NaEvaluationInfo";
import { DialogProps } from "../DialogProvider";
import "./NeedsAssessmentDialog.scss";

/**
 * 작성자: 김혜정
 * 작성일: 2023.02.24
 * 필요도 평가 팝업
 *
 * 수정자: 홍준표
 * 수정일: 2023.03.14
 * @param csUid 상담일정 일련번호
 * @param onSuccess 성공했을 때 실행할 함수
 */

interface NeedsAssessmentDialog extends DialogProps {
  csUid?: number;
  naData: NaEvaluationInfo;
  evalDate: string;
  onSuccess?: () => void;
}

const ans1 = (val: string): string => {
  switch (val) {
    case "1" :
      return "완전자립"
    case "2" :
      return "부분 도움 필요"
    case "3" :
      return "완전 도움 필요"
    default :
      return val
  }
};

const ans2 = (val: string): string => {
  switch (val) {
    case "1" :
      return "그렇지 않다"
    case "2" :
      return "그렇다"
    default :
      return val
  }
};

const ans3 = (val: string): string => {
  switch (val) {
    case "1" :
      return "양호"
    case "2" :
      return "불량/없음"
    default :
      return val
  }
};

const ans4 = (val: string): string => {
  switch (val) {
    case "1" :
      return "돌볼가족 없음"
    case "2" :
      return "돌볼가족 있음"
    default :
      return val
  }
};

const ans5 = (val: string): string => {
  switch (val) {
    case "1" :
      return "기존돌봄 공백"
    case "2" :
      return "기존돌봄 중복"
    default :
      return val
  }
};

const ans6 = (val: string): string => {
  switch (val) {
    case "1" :
      return "긴급 위기상황"
    case "2" :
      return "긴급 위기상황 아님"
    default :
      return val
  }
};

interface IllArr {
  one: string,
  two?: string,
}

const NeedsAssessmentDialog = ({
  csUid,
  naData,
  evalDate,
  onSuccess,
  ...props
}: NeedsAssessmentDialog) => {
  const [tabs, setTabs] = useState(0);
  const { register, getValues } = useForm<NaEvaluationInfo>({
    defaultValues: naData,
  });
  const { jsonData } = naData;
  const handleClose = () => {
    props.onClose?.();
  };
  useEffect(() => {
    if(getValues("jsonData.illAdd")) {
      pushIllList(JSON.parse(getValues("jsonData.illAdd")))
    }
  }, [])
  const [illItems, setIllItems] = useState([] as IllArr[])
  const pushIllList = (illList : string[]) => {
    if(illList.length !== 0 && illList.length % 2 === 0) {  //0 X / 짝수, 최소 2개
      illList.map((item, idx) => {
        if((idx + 1) === illList.length) {
          return
        }
        if(idx % 2 === 0) {
          let temp:IllArr = {one: illList[idx], two: illList[idx + 1]}
        setIllItems((cur) => [...cur, temp])
        }
      })
    } else if(illList.length === 1) { //1개
      illList.map((item, idx) => {
        let temp:IllArr = {one: illList[idx]}
        setIllItems([temp])
      })
    } else {  //1개이상 홀수
      illList.map((item, idx) => {
        if(idx !== (illList.length - 1)) {
          if(idx % 2 === 0) {
            let temp:IllArr = {one: illList[idx], two: illList[idx + 1]}
          setIllItems((cur) => [...cur, temp])
          }
        } else {  //마지막
          let temp:IllArr = {one: illList[idx]}
          setIllItems((cur) => [...cur, temp])
        }
      })
    }
  }
  const RenderIllItem = ({illItem}:{illItem: IllArr}) => {
    return (
      <li>
        <div>
          <p className="w100">
            {illItem.one}
          </p>
        </div>
        {illItem.two ? 
        <div>
          <p className="w100">
            {illItem.two}
          </p>
        </div>
        : null}
      </li>
    )
  }

  return (
    <div className="needs-assessment-dialog popup_black" {...props}>
      <div
        className="assign_popup_inner popup_white"
        style={{ overflowY: "visible", width: "800px" }}
      >
        <div className="popup_tit">
          <h3>돌봄필요도 평가</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <div className="care_evaluation">
            {/* 탭 */}
            <div className="tabs2">
              <button
                type="button"
                className={`tab2 ${tabs === 0 ? "tab_active" : "non_active"}`}
                onClick={() => setTabs(0)}
                data-id={0}
              >
                신체적 영역
              </button>
              <button
                type="button"
                className={`tab2 ${tabs === 1 ? "tab_active" : "non_active"}`}
                onClick={() => setTabs(1)}
                data-id={1}
              >
                정서적 영역
              </button>
              <button
                type="button"
                className={`tab2 ${tabs === 2 ? "tab_active" : "non_active"}`}
                onClick={() => setTabs(2)}
                data-id={2}
              >
                추가정보(생활환경)
              </button>
              <button
                type="button"
                className={`tab2 ${tabs === 3 ? "tab_active" : "non_active"}`}
                onClick={() => setTabs(3)}
                data-id={2}
              >
                추가정보(사전검사)
              </button>
              <button
                type="button"
                className={`tab2 ${tabs === 4 ? "tab_active" : "non_active"}`}
                onClick={() => setTabs(4)}
                data-id={3}
              >
                광주+돌봄 지원대상 여부
              </button>
              <button
                type="button"
                className={`tab2 ${tabs === 5 ? "tab_active" : "non_active"}`}
                onClick={() => setTabs(5)}
                data-id={4}
              >
                긴급돌봄 지원대상 여부
              </button>
            </div>
            {/* 신체적 영역 */}
            {tabs === 0 && (
              <div className="tab_content2" data-id={0}>
                <p className="date">평가일 : {evalDate}</p>
                <div className="choice_result">
                  <h4>기본적 일상생활 자립 정도</h4>
                  <ul>
                    <li>
                      <div>
                        <p>세수, 양치질 하기</p>
                        <span>{ans1(jsonData.q1_1)}</span>
                      </div>
                      <div>
                        <p>머리감기</p>
                        <span>{ans1(jsonData.q1_2)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>목욕하기</p>
                        <span>{ans1(jsonData.q1_3)}</span>
                      </div>
                      <div>
                        <p>옷 벗고 입기</p>
                        <span>{ans1(jsonData.q1_4)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>식사하기</p>
                        <span>{ans1(jsonData.q1_5)}</span>
                      </div>
                      <div>
                        <p>체위 변경(일어나 앉기 등)</p>
                        <span>{ans1(jsonData.q1_6)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>집안에서 이동하기</p>
                        <span>{ans1(jsonData.q1_7)}</span>
                      </div>
                      <div>
                        <p>화장실 사용하기</p>
                        <span>{ans1(jsonData.q1_8)}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="choice_result mt20">
                  <h4>사회적 일상생활 자립 정도</h4>
                  <ul>
                    <li>
                      <div>
                        <p>식사 준비하기(음식 조리)</p>
                        <span>{ans1(jsonData.q2_1)}</span>
                      </div>
                      <div>
                        <p>세탁, 빨래하기</p>
                        <span>{ans1(jsonData.q2_2)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>청소하기</p>
                        <span>{ans1(jsonData.q2_3)}</span>
                      </div>
                      <div>
                        <p>장보기, 물건사기</p>
                        <span>{ans1(jsonData.q2_4)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>근거리 외출하기</p>
                        <span>{ans1(jsonData.q2_5)}</span>
                      </div>
                      <div>
                        <p>교통수단 이용하기</p>
                        <span>{ans1(jsonData.q2_6)}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="choice_result mt20">
                  <h4>질병 및 증상</h4>
                  <ul>
                    <li>
                      <div>
                        <p className="w100">
                          뇌경색{" "}
                          <input
                            type="checkbox"
                            disabled
                            {...register("jsonData.q3_1")}
                          />
                        </p>
                      </div>
                      <div>
                        <p className="w100">
                          시력저하{" "}
                          <input
                            type="checkbox"
                            disabled
                            {...register("jsonData.q3_2")}
                          />
                        </p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p className="w100">
                          요통, 관절염{" "}
                          <input
                            type="checkbox"
                            disabled
                            {...register("jsonData.q3_3")}
                          />
                        </p>
                      </div>
                      <div>
                        <p className="w100">
                          청력저하{" "}
                          <input
                            type="checkbox"
                            disabled
                            {...register("jsonData.q3_4")}
                          />
                        </p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p className="w100">
                          고혈압, 당뇨{" "}
                          <input
                            type="checkbox"
                            disabled
                            {...register("jsonData.q3_5")}
                          />
                        </p>
                      </div>
                      <div>
                        <p className="w100">
                          치매{" "}
                          <input
                            type="checkbox"
                            disabled
                            {...register("jsonData.q3_6")}
                          />
                        </p>
                      </div>
                    </li>
                    {illItems.map((item, idx) => (
                      <RenderIllItem key={idx} illItem={item}/>
                    ))}
                    {/* {jsonData.q3_8 ? 
                    <li>
                      <div>
                        <p className="w100">
                          {jsonData.q3_7}{" "}
                          <input
                            type="checkbox"
                            disabled
                            {...register("jsonData.q3_8")}
                          />
                        </p>
                      </div>
                    </li>
                      : null
                    } */}
                  </ul>
                </div>
              </div>
            )}
            {/* 정서적 영역 */}
            {tabs === 1 && (
              <div className="tab_content2 " data-id={1}>
                <p className="date">평가일 : {evalDate}</p>
                <div className="choice_result">
                  <h4>정신건강 수준</h4>
                  <ul>
                    <li>
                      <div>
                        <p>우울감과 불행감을 느낀 적이 많다.</p>
                        <span>{ans2(jsonData.q4_1)}</span>
                      </div>
                      <div>
                        <p>어려움을 극복할 수 없다고 느낀 적이 많다.</p>
                        <span>{ans2(jsonData.q4_2)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>걱정으로 잠을 잘 못 이루거나 설치는 적이 많다.</p>
                        <span>{ans2(jsonData.q4_3)}</span>
                      </div>
                      <div>
                        <p>미래에 대한 희망이 없다고 느낀 적이 많다.</p>
                        <span>{ans2(jsonData.q4_4)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>자신감이 없어지고 내가 쓸모없게 느껴진다.</p>
                        <span>{ans2(jsonData.q4_5)}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="choice_result mt20">
                  <h4>고립, 은둔 수준</h4>
                  <ul>
                    <li>
                      <div>
                        <p>하루 종일 혼자 있다.</p>
                        <span>{ans2(jsonData.q5_1)}</span>
                      </div>
                      <div>
                        <p>대부분 자신의 방이나 집에만 머무른다.</p>
                        <span>{ans2(jsonData.q5_2)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>가끔 외출을 하더라도 대인관계를 하지 않는다.</p>
                        <span>{ans2(jsonData.q5_3)}</span>
                      </div>
                      <div>
                        <p>일상적 어려움을 항시 의논할 사람이 없다.</p>
                        <span>{ans2(jsonData.q5_4)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>일상적 어려움을 실제 도와줄 사람이 없다.</p>
                        <span>{ans2(jsonData.q5_5)}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {/* 추가정보(생활환경) */}
            {tabs === 2 && (
              <div className="tab_content2 " data-id={2}>
                <p className="date">평가일 : {evalDate}</p>
                <div className="choice_result">
                  <h4>식사 및 영양 상태</h4>
                  <ul>
                    <li>
                      <div>
                        <p>하루에 1끼 이하 불규칙적으로 식사한다.</p>
                        <span>{ans2(jsonData.q6_1)}</span>
                      </div>
                      <div>
                        <p>건강과 영양을 고려한 좋은 음식을 먹기 어렵다.</p>
                        <span>{ans2(jsonData.q6_2)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>거의 매번 혼자서 식사하는 편이다.</p>
                        <span>{ans2(jsonData.q6_3)}</span>
                      </div>
                      <div>
                        <p>음식을 씹거나 삼키는 것이 힘들다.</p>
                        <span>{ans2(jsonData.q6_4)}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="choice_result mt20">
                  <h4>주거 환경</h4>
                  <ul>
                    <li>
                      <div>
                        <p>방바닥 미끄럼</p>
                        <span>{ans3(jsonData.q7_1)}</span>
                      </div>
                      <div>
                        <p>욕실바닥 미끄럼</p>
                        <span>{ans3(jsonData.q7_2)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>문턱 단차</p>
                        <span>{ans3(jsonData.q7_3)}</span>
                      </div>
                      <div>
                        <p>변기 등 안전손잡이</p>
                        <span>{ans3(jsonData.q7_4)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>주방 가스 기구</p>
                        <span>{ans3(jsonData.q7_5)}</span>
                      </div>
                      <div>
                        <p>청결한 주거환경(대청소 필요성)</p>
                        <span>{ans3(jsonData.q7_6)}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <p>바이러스·해충 위험(방역·방충 필요성)</p>
                        <span>{ans3(jsonData.q7_7)}</span>
                      </div>
                    </li>
                    <li>
                      <div className="w100">
                        <p style={{ width: "calc(50% - 75px)" }}>
                          기타 간단 수리가 필요한 항목
                        </p>
                        <span
                          style={{
                            marginLeft: 0,
                            textAlign: "left",
                            width: "calc(50% + 75px)",
                          }}
                        >
                          {jsonData.q7_8}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {/* 추가정보(사전검사) */}
            {tabs === 3 && (
              <div className="tab_content2 " data-id={3}>
                <p className="date">평가일 : {evalDate}</p>
                <div className="choice_result">
                  <h4>귀하의 생각과 가장 유사한 곳에 응답해주세요 - 지난 1주일동안 나는(또는 가족은)</h4>
                  <ul>
                    <li>
                      <div style={{width: "100%"}}>
                        <p style={{width: "calc(100% - 85px)"}}>식사, 가사, 외출 등 일상생활 수행하는데 어려움을 경험한 적이 있다.</p>
                        <span style={{width: "75px"}}>{ans2(jsonData.q8_1) ?? "데이터없음"}</span>
                      </div>
                    </li>
                    <li>
                      <div style={{width: "100%"}}>
                        <p style={{width: "calc(100% - 85px)"}}>나 자신 또는 가족을 돌보는 것이 부담스러운 적이 있다.</p>
                        <span style={{width: "75px"}}>{ans2(jsonData.q8_2) ?? "데이터없음"}</span>
                      </div>
                    </li>
                    <li>
                      <div style={{width: "100%"}}>
                        <p style={{width: "calc(100% - 85px)"}}>몸이 아프거나 불편한 적이 있다.</p>
                        <span style={{width: "75px"}}>{ans2(jsonData.q8_3) ?? "데이터없음"}</span>
                      </div>
                    </li>
                    <li>
                      <div style={{width: "100%"}}>
                        <p style={{width: "calc(100% - 85px)"}}>우울하다는 생각을 한 적이 있다.</p>
                        <span style={{width: "75px"}}>{ans2(jsonData.q8_4) ?? "데이터없음"}</span>
                      </div>
                    </li>
                    <li>
                      <div style={{width: "100%"}}>
                        <p style={{width: "calc(100% - 85px)"}}>힘들고 어려울 때 지역사회(주변사람, 광주광역시, 기관 등)로 부터 도움을 받을 수 있다고 생각한다.</p>
                        <span style={{width: "75px"}}>{ans2(jsonData.q8_5) ?? "데이터없음"}</span>
                      </div>
                    </li>
                    <li>
                      <div style={{width: "100%"}}>
                        <p style={{width: "calc(100% - 85px)"}}>지금까지 나의 삶에 대해 만족한다.</p>
                        <span style={{width: "75px"}}>{ans2(jsonData.q8_6) ?? "데이터없음"}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {/* 광주+돌봄 지원대상 여부 */}
            {tabs === 4 && (
              <div className="tab_content2 " data-id={4}>
                <p className="date">평가일 : {evalDate}</p>
                <div className="answer_result">
                  <div className="cont_top">
                    <div className="cont_tit">
                      <h4>① 신체적·정서적 기능 저하</h4>
                      <p>
                        혼자 거동하기 어렵거나, 독립적인 일상생활 수행이 어려운
                        정도
                      </p>
                    </div>
                    <div className="cont_input">
                      <p>
                        <span>{jsonData.g1_score}</span> 점
                      </p>
                    </div>
                  </div>
                  <div className="cont_bot mt10">
                    <textarea
                      placeholder="상세내용 입력"
                      readOnly
                      {...register("jsonData.g1_content")}
                    />
                  </div>
                </div>
                <div className="answer_result mt20">
                  <div className="cont_top">
                    <div className="cont_tit">
                      <h4>② 돌볼 수 있는 사람 부재</h4>
                      <p>
                        돌볼 수 있는 가족이 없거나, 있어도 돌볼 수 없는지 여부
                      </p>
                    </div>
                    <div className="cont_input">
                      <p>{ans4(jsonData.g2_type)}</p>
                    </div>
                  </div>
                  <div className="cont_bot mt10">
                    <textarea
                      placeholder="상세내용 입력"
                      readOnly
                      {...register("jsonData.g2_content")}
                    />
                  </div>
                </div>
                <div className="answer_result mt20">
                  <div className="cont_top">
                    <div className="cont_tit">
                      <h4>③ 서비스 공백</h4>
                      <p>
                        기존 돌봄서비스를 이용할 수 없거나, 이용을 기다리고
                        있거나, 이용 중 공백이 발생하였는지 여부
                      </p>
                    </div>
                    <div className="cont_input">
                      <p>{ans5(jsonData.g3_type)}</p>
                    </div>
                  </div>
                  <div className="cont_bot mt10">
                    <textarea
                      placeholder="상세내용 입력"
                      readOnly
                      {...register("jsonData.g3_content")}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* 긴급돌봄 지원대상 여부 */}
            {tabs == 5 && (
              <div className="tab_content2 " data-id={5}>
                <p className="date">평가일 : {evalDate}</p>
                <div className="answer_result">
                  <div className="cont_top">
                    <div className="cont_tit">
                      <h4>① 긴급 위기 상황</h4>
                      <p>
                        갑작스러운 사고·질병이나 재난(자연재난, 사회재난) 등으로
                        긴급 위기 상황에 놓였는지 여부
                      </p>
                    </div>
                    <div className="cont_input">
                      <p>{ans6(jsonData.e1_type)}</p>
                    </div>
                  </div>
                  <div className="cont_bot mt10">
                    <textarea
                      placeholder="상세내용 입력"
                      readOnly
                      {...register("jsonData.e1_content")}
                    />
                  </div>
                </div>
                <div className="answer_result mt20">
                  <div className="cont_top">
                    <div className="cont_tit">
                      <h4>② 신체적·정서적 기능 저하</h4>
                      <p>
                        혼자 거동하기 어렵거나, 독립적인 일상생활 수행이 어려운
                        정도
                      </p>
                    </div>
                    <div className="cont_input">
                      <p>
                        <span>{jsonData.e2_score}</span> 점
                      </p>
                    </div>
                  </div>
                  <div className="cont_bot mt10">
                    <textarea
                      placeholder="상세내용 입력"
                      readOnly
                      {...register("jsonData.e2_content")}
                    />
                  </div>
                </div>
                <div className="answer_result mt20">
                  <div className="cont_top">
                    <div className="cont_tit">
                      <h4>③ 돌볼 수 있는 사람 부재</h4>
                      <p>
                        돌볼 수 있는 가족이 없거나, 있어도 돌볼 수 없는지 여부
                      </p>
                    </div>
                    <div className="cont_input">
                      <p>{ans4(jsonData.e3_type)}</p>
                    </div>
                  </div>
                  <div className="cont_bot mt10">
                    <textarea
                      placeholder="상세내용 입력"
                      readOnly
                      {...register("jsonData.e3_content")}
                    />
                  </div>
                </div>
                <div className="answer_result mt20">
                  <div className="cont_top">
                    <div className="cont_tit">
                      <h4>④ 서비스 공백</h4>
                      <p>
                        즉시 돌봄이 필요하지만 기존돌봄 또는 광주+돌봄 서비스를
                        이용할 수 없거나, 이용을 기다리고 있거나, 이용 중 공백이
                        발생하였는지 여부
                      </p>
                    </div>
                    <div className="cont_input">
                      <p>{ans5(jsonData.e4_type)}</p>
                    </div>
                  </div>
                  <div className="cont_bot mt10">
                    <textarea
                      placeholder="상세내용 입력"
                      readOnly
                      {...register("jsonData.e4_content")}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="short_answer2 mt20">
              <ul>
                <li>
                  <h4>신체적 영역</h4>
                  <p>
                    {jsonData.bodyAreaPt >= 60 ? 
                      <span className="yes">{jsonData.bodyAreaPt}</span>
                    :
                      <span>{jsonData.bodyAreaPt}</span>
                    }
                     점
                  </p>
                </li>
                <li>
                  <h4>정서적 영역</h4>
                  <p>
                    {jsonData.emtAreaPt >= 60 ? 
                      <span className="yes">{jsonData.emtAreaPt}</span>
                    :
                      <span>{jsonData.emtAreaPt}</span>
                    }
                     점
                  </p>
                </li>
                <li>
                  <h4>광주+돌봄 지원대상 여부</h4>
                  <p>
                    {jsonData.gTargetYn === "지원대상" ? 
                      <span className="yes">{jsonData.gTargetYn}</span>
                    :
                    <span>{jsonData.gTargetYn}</span>
                    }
                  </p>
                </li>
                <li>
                  <h4>긴급돌봄 지원대상 여부</h4>
                  <p>
                    {jsonData.eTargetYn === "지원대상" ?
                      <span className="yes">{jsonData.eTargetYn}</span>
                    :
                    <span>{jsonData.eTargetYn}</span>
                    }
                  </p>
                </li>
              </ul>
              <div className="opinion">
                <h4>종합의견</h4>
                <p>{jsonData.general}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeedsAssessmentDialog;
