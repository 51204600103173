import axios from "axios";
import { plainToInstance } from "class-transformer";
import { serialize } from "object-to-formdata";
import EvaluationList from "../models/EvaluationList";
import ServiceTargetYear from "../models/ServiceTargetYear";
import { servicePlanYearVO } from "../models/bill/OrgDetailModel";
import ResultEvalData from "../models/form/ResultEvalData";
/**
 * 작성자: 김혜정
 * 작성일: 2023.03.21
 *
 * 평가/사후관리 API
 */


 const getCount = () => {
  return axios.get("/api/evaluation/count")
  .then((res) => res.data)
}

 type GetAllParams = {
  page?: number;
  hdong?: string;
  siUid?: number;
  resultEvalStatus?: string;
  searchType?: string;
  searchKeyword?: string;
};

//  목록
const getAll = (params: GetAllParams) => {
  return axios
    .get("/api/resultEval/", { params })
    .then((response) => plainToInstance(EvaluationList, response.data));
};

// 서비스 평가 등록
interface InsertResultEvalParams {
  spyUid?: number;
  rspndNm?: string;  // 응답자 성명
  rspndGbn?: string; // 응답자 구분(관계)
  rspndTel?: string;  // 응답자 전화번호
  srvcStsfctn?: string;  // 서비스 만족도
  srvcStsfctnRsn?: string; // 서비스 만족도 사유
  resultJson: string; // 평가 결과
  investigator: string; //평가자
}

const insertResultEval = (params: InsertResultEvalParams) => {
  return axios
    .post("/api/resultEval/resultEval", serialize(params) )
    .then((response) => response.data["success"] as boolean);
};

// 서비스 평가 등록,수정 form 조회
type getResultEvalParams = {
  spyUid: number;
}

interface UseService {
  days: number,
  serviceName: string,
  times: number,
  totalCount: number,
}

export type EvaluationTarget = {
  departName: string,
  targetInfo : ServiceTargetYear,
  evalData : EvalData,
  useService: UseService,
}

export type EvalData = {
    spyUid: number,
    // investigator : string,
    evalDt: string,
    costSupportYn: "Y" | "N",
    rspndGbn: string,
    rspndNm: string,
    rspndTel: string,
    srvcStsfctn: string,
    srvcStsfctnRsn: string,
    resultJson: string,
    servicePlanYear: servicePlanYearVO,
}

const getResultEvalData = (params: getResultEvalParams) => {
  return axios.get("/api/resultEval/getResultEval", {params})
  .then((res) => {
    return res.data as ResultEvalData
  })
}

const getResultEval = (params: getResultEvalParams) => {
  return axios
    .get("/api/resultEval/resultEval", { params })
    .then((response) => response.data as EvaluationTarget);
};

// 사후관리 등록
interface InsertAfterCareParams {
  spyUid: number;
  processId: number;
}

const insertAfterCare = (params: InsertAfterCareParams) => {
  return axios
    .post("/api/resultEval/afterCare", serialize(params))
    .then((response) => response.data["success"] as boolean);
};

const EvaluationAPI = {
  getAll,
  insertAfterCare,
  insertResultEval,
  getResultEval,
  getCount,
  getResultEvalData,
};

export default EvaluationAPI;
export type { GetAllParams };
