import { Moment } from "moment"
import GuBillModel from "./GuBillModel"

export type evaluation = {
    sbUid: number,  //비용청구서 일련번호
    syUid: number,  //사례관리 일련번호
    csUid: number,  //상담일정 일련번호
    beginDt?: Moment,
    beginGpsCd?: string,
    endDt?: string,
    endGpsCd?: string,
    resultJson: string,
    bodyAreaPt?: number,
    emtAreaPt?: number,
    incomeLevel?: string,
    homeMember?: number,
    counselSchedule?: Moment,
}

export type targetInfo = {
    syUid: number,  //사례관리 일련번호
    trgterNm: string,
    brthdy: Moment,
    gender: string,
    telno: string,
    adres: string,
    adresDtl: string,
    homeMember?: string,
    characterJson: string,
    incomeLevel?: string,
    incomeLevel2?: string,
    emgcNm?: string,
    emgcRel?: string,
    emgcTel?: string,
    evaluation: evaluation,
    counselDate?: Moment,
    hdong: string,
    serviceNm: string,
    cateCdName: string,
}

export interface OrgInfo {
    beforeOrdAll: string,
    crtDt: string,
    crtUid: number,
    delDt: string,
    delUid: number,
    departList: string,
    departName: string,
    deptCd: "SI" | "GU" | "DONG" | "ORG",
    depth: number,
    dptUid: number,
    fax: string,
    memberList: string,
    ordAll: string,
    ordNo: number,
    tel: string,
    updDt: string,
    updUid: number,
}


/**
 * 비용(구) 상세 모델
 * 
 * 작성자: 홍준표
 * 
 * 작성일: 2023.03.15
 */
class GuDetailModel {
    constructor(
        public targetInfo: targetInfo,
        public orgInfo: OrgInfo,
        public list: GuBillModel[],
    ) {}
}

export default GuDetailModel