import Gender from "./Gender";

class MoveOutListForm {
    constructor(
        public mtUid: number,
        public trgterNm: string,
        public trgterCd: string,
        public brthdy: string,
        public gender: Gender,
        public outGu: string,
        public inGu: string,
        public hdong: string,
        public moveStatus: string,
        public crtDt: string,
        public updDt: string,
        public totalCount: number,
    ) {}
}

export default MoveOutListForm