import { Moment } from "moment";
import { DepartVO } from "../apis/MemberAPI";

class Member {
  constructor(
    public mbrUid: number,  //회원 일련번호
    public dptUid: number,  //소속 기관 일련번호
    public userName: string,  //이름
    public userId?: string,  //아이디
    public userPw?: string,  //비밀번호(null값 처리)
    public tel: string | undefined = undefined,
    public phone: string | undefined = undefined,
    public email?: string, //이메일
    public lastLoginDt?: Moment,  //최근 로그인 일시 yyyy-MM-dd HH:mm:ss
    public loginFailCount?: number,  //로그인 실패 카운트
    public lastLoginIp?: string,  //로그인 IP
    public signupStatus?: "P" | "U" | "S" | "D", //가입상태 승인대기(P),사용중(U),중지(S),탈퇴(D)
    // public caseChangeYn: string = "N",  //사례관리 담당자여부(X)
    public caseChargeYn: string = "N",  //사례관리 담당자여부
    public srvcSupplyYn: string = "N",  //서비스 제공자 여부
    public srvcManageYn: string = "N",   //서비스 관리자 여부
    public role?: string,  //분류
    public crtUid?: number, //등록자 일련번호
    public crtDt?: Moment,  //등록 일시 yyyy-MM-dd HH:mm:ss
    public updUid?: number, //수정자 일련번호
    public updDt?: Moment,  //수정일시
    public delUid?: number, //삭제자 일련번호
    public delDt?: Moment,  //삭제일시
    public depart?: DepartVO
  ) {}

  static get manager() {
    return new Member(1, 1, "매니저");
  }
}

export default Member;
