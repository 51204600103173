import clsx from "clsx";
import React, { useState } from "react";

import ArrowIcon from "../../../assets/ic_arrow.png";
import Pagination from "../Pagination";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.23
 *
 * @example
 * <ServiceResult
 *   expandable
 *   columnTitles={['제목', '내용']}
 *   rows={[
 *     createTableRow({
 *       cells: [
 *         createTextCell({ text: '제목' })
 *         createTextCell({ text: '내용' })
 *       ],
 *       expandContent: (<div>펼쳐졌을 때 보여줄 내용</div>)
 *     })
 *   ]}
 * />
 * @end
 *
 * 한 행을 나타내는 interface
 * @param expandContent 행이 펼쳐졌을 때 보여줄 내용
 * @param cells 행이 가진 셀
 */
export interface ServiceResultRow {
  expandContent?: React.ReactNode;
  cells: ServiceResultCell[];
}
export const createRow = (row: ServiceResultRow) => row;

/**
 * 셀의 타입은 Text 또는 Button 둘 중 하나여야 함
 */
export type ServiceResultCell = ServiceResultTextCell | ServiceResultButtonCell;

/**
 * 셀이 텍스트인 경우에 사용하는 interface
 * @param text 셀 내용
 * @param ellipsis 생략 사용 여부
 * @param d_day 디데이
 */
export interface ServiceResultTextCell {
  text: string;
  ellipsis?: boolean;
  d_day?: number;
}
export const createTextCell = (cell: ServiceResultTextCell) => cell;
const isTextCell = (cell: ServiceResultCell): cell is ServiceResultTextCell =>
  "text" in cell;

/**
 * 셀이 버튼인 경우에 사용하는 interface
 * @param type underlined: 검정색 밑줄 버튼, outlined: 파란색 외곽선 버튼, undefined: -
 * @param text 버튼 제목
 * @param onClick 버튼이 클릳되었을 때 호출되는 함수
 */
export interface ServiceResultButtonCell {
  type: "underlined" | "outlined" | undefined;
  label: string;
  onClick: () => void;
}
export const createButtonCell = (cell: ServiceResultButtonCell) => cell;
const isButtonCell = (cell: ServiceResultCell): cell is ServiceResultButtonCell =>
  "label" in cell;

/**
 * 모든 목록에서 사용할 수 있는 데이터 테이블 컴포넌트
 * @param expandable 펼쳐지는 행이 있는지 여부
 * @param columnTitles 열의 제목
 * @param rows 데이터 행
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
const ServiceResult = ({
  expandable = false,
  columnTitles,
  rows,
}: {
  expandable?: boolean;
  columnTitles: string[];
  rows: ServiceResultRow[];
}) => {
  return (
    <article className="list">
      <table>
        <thead>
          <tr>
            {expandable && <th className="first"></th>}
            {columnTitles.map((title, index) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <Row key={index} row={row} expandable={expandable} />
          ))}
        </tbody>
      </table>
    </article>
  );
};

/**
 * 데이터 테이블의 한 행을 나타내는 컴포넌트
 */
const Row = ({
  row,
  expandable,
}: {
  row: ServiceResultRow;
  expandable: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    if (!expandable) {
      return;
    }

    setExpanded(!expanded);
  };

  return (
    <>
      <tr
        className={clsx("table_title", expanded ? "on" : "off")}
        onClick={handleExpand}
      >
        {row.expandContent && (
          <td className="first">
            <img src={ArrowIcon} alt="접고 펼치기 아이콘" />
          </td>
        )}
        {row.cells.map((cell, index) => (
          <Cell key={index} cell={cell} />
        ))}
      </tr>
      {row.expandContent && (
        <tr
          className={clsx("table_content", expanded && "on")}
          style={{ display: expanded ? "table-row" : "none" }}
        >
          <td colSpan={100}>{row.expandContent}</td>
        </tr>
      )}
    </>
  );
};

/**
 * 데이터 테이블의 한 셀을 나타내는 컴포넌트
 */
const Cell = ({ cell }: { cell: ServiceResultCell }) => {
  if (isTextCell(cell)) {
    if (cell.ellipsis) {
      return (
        <td className="address">
          <div className="ellipsis">
            <p>
              {cell.text}
              {<D_Day d_day={cell.d_day} />}
            </p>
          </div>
        </td>
      );
    } else {
      return (
        <td>
          {cell.text}
          {<D_Day d_day={cell.d_day} />}
        </td>
      );
    }
  } else {
    return (
      <td>
        {cell.type ? (
          <button
            type="button"
            className={clsx("btn_blue", cell.type === "underlined" && "edit")}
            onClick={(e) => {
              e.stopPropagation();
              cell.onClick();
            }}
          >
            {cell.label}
          </button>
        ) : (
          "-"
        )}
      </td>
    );
  }
};

/**
 * 디데이를 나타내는 컴포넌트
 */
const D_Day = ({ d_day }: { d_day?: number }) => {
  let d_dayElement = <></>;
  if (d_day) {
    if (d_day === 0) {
      d_dayElement = (
        <>
          <span className="red">D-day</span>
        </>
      );
    } else if (d_day <= 2) {
      d_dayElement = (
        <>
          <span className="yellow">D-{d_day}</span>
        </>
      );
    }
  }
  return d_dayElement;
};

export default ServiceResult;
