import React, { useEffect, useState, useRef } from "react";
import "./SettingsPage.scss";
import PlusIcon from "../../../assets/ic_plus.png";
import { useDialog } from "../../../dialogs/DialogProvider";
import ServiceRegisterDialog from "../../../dialogs/setting/ServiceRegisterDialog";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import DataTable, {
  createButtonCell,
  createRow,
  createTextCell,
} from "../../../components/common/DataTable";
import SettingList, { SearchDbGubunCd, SearchServiceGbn } from "../../../models/SettingList";
import SettingAPI from "../../../apis/SettingAPI";
import SearchBar, {
  SearchField,
  SearchFormInput,
} from "../../../components/common/SearchBar";
import useMember from "../../../hooks/useMember";
import Member from "../../../models/Member";

// 환경설정 메뉴 페이지 > 서비스 관리

export type SearchServiceParam = {
  dbGubunCd?: string
  serviceGbn?: string
  serviceNm?: string
  tel?: string
  locality?: string
  hing?: string
  keyword?: string
}


const SettingsTab3Page = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const [serviceList, setServiceList] = useState([] as SettingList[]);
  const [count, setCount] = useState(0);
  const [searchParam, setSearchParam] = useState({} as SearchFormInput)
  const {member} = useMember()

// 목록 불러오기
 useEffect(() => {
  SettingAPI.getAllService({
    page: Number(page),
    ...searchParam,
  }).then((serviceList) => {
    // console.log(serviceList);  
    setServiceList([...serviceList])
    setCount(serviceList[0].totalCount)
  });
}, [page, searchParam]);

const handleSearch = (data: SearchFormInput) => {
  let parsed:SearchServiceParam = data
    console.log(parsed)
    let params:SearchServiceParam = {}
    if(parsed.dbGubunCd?.length !== 0) {
      params.dbGubunCd = parsed.dbGubunCd 
    }
    if(parsed.serviceGbn?.length !== 0) {
      params.serviceGbn = parsed.serviceGbn 
    }
    if(parsed.hing?.length !== 0 && parsed.keyword?.length !== 0) {
      switch(parsed.hing) {
        case "serviceNm" :
          params.serviceNm = parsed.keyword
          break
      }
    }
    setSearchParam(params)
  };

const handlePageChange = (page: number) => {
  navigate(`/setting-service-mng/${page}`);
};

  return(
    <main>
      <section>
        <div className="tab_content" data-id={2}>
          <SearchBar fields={searchFields} onSubmit={handleSearch} />
          <TableTop3 count={count} member={member!}/>
          <Table3
            list={serviceList}
            page={Number(page)}
            totalPageCount={Math.ceil(count / 10)}
            onPageChange={handlePageChange}
            member={member!}
          />
        </div>
      </section>
    </main>
    
  );
}

/**
 * 검색 필드
 */
 const searchFields: SearchField[] = [
  {
    title: "서비스구분",
    name: "dbGubunCd",
    type: "select",
    items: SearchDbGubunCd.map((label) => ({ label })),
  },
  {
    title: "서비스 대분류",
    name: "serviceGbn",
    type: "select",
    items: SearchServiceGbn.map((label) => ({ label })),
  },
  {
    title: "검색어",
    name: "hing",
    type: "search",
    items: [
      { label: "서비스 소분류", value: "serviceNm" },
    ],
  },
];

const TableTop3 = ({count, member} : {count:number, member:Member}) => {
  const { showDialog } = useDialog();

  const handleServiceRegisterClick = () => {

    showDialog(ServiceRegisterDialog);
 };

  return(
    <article className="list_top">
      <div className="total">
        <p>
          총 <span>{count}</span>건
        </p>
      </div>
      {(member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU") &&
      <div className="list_btn_wrap">
        <button
          type="button"
          className="recive"
          onClick={handleServiceRegisterClick}
        >
          <img src={PlusIcon} alt="접수 아이콘" />
          서비스등록
        </button>
      </div>
      }
    </article>
  );
}

/**
 * 테이블
 * @param list 기관 목록
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
 const Table3 = ({
  list,
  page,
  totalPageCount,
  onPageChange,
  member,
}: {
  list: SettingList[];
  page: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
  member: Member
}) => {

  const navigate = useNavigate();

  // 삭제
  const handleServiceDelete = async (siUid:number) => {
    // alert(siUid);
    if(window.confirm("삭제하시겠습니까?")){
      SettingAPI.deleteService({
        siUid
      }).then((ref) => {
        if(ref.success) {
          alert(ref.message)
          navigate(0);
        } else {
          alert(ref.errMessage)
          return
        }
      });
    } else{
      return
    }
  } 

  const { showDialog } = useDialog();

  const handleServiceUpdateClick = (serviceInfo: SettingList) => {
    showDialog(ServiceRegisterDialog, {
      serviceInfo: serviceInfo,
      siUid : serviceInfo.siUid,
    })
    
  };

  return (
    <DataTable
      columnTitles={[
        "서비스구분",
        "서비스 대분류",
        "서비스 소분류",
        "상태",
        "",
        "",
      ]}
      rows={list.map((serviceInfo) =>
        createRow({
          cells: [
            createTextCell({ text: serviceInfo.dbGubunCd }),
            createTextCell({ text: serviceInfo.serviceGbn }),
            createTextCell({ text: serviceInfo.serviceNm }),
            createTextCell({ text: serviceInfo.useStatusString }),
            createButtonCell({
              type: "outlined",
              label: member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ? "수정" : "정보보기",
              onClick: () => handleServiceUpdateClick(serviceInfo),
            }),
            createButtonCell({
              type: member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ? "outlined" : undefined,
              label: "삭제",
              onClick: () => handleServiceDelete(serviceInfo.siUid),
            }),
            
          ],
        })
      )}
      page={page}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
    />
  );
};

export default SettingsTab3Page;
