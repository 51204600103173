import { GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  OAuthProvider,
  signInWithPhoneNumber,
  RecaptchaVerifier } from "firebase/auth";
import { useEffect, useState } from "react";
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import MemberAPI, { LoginBasic } from "../../../apis/MemberAPI";
import { engValid, ValidCheck } from "../../../App";

import useMember from "../../../hooks/useMember";
import Login from "../../../models/form/Login";
import Member from "../../../models/Member";
import styles from "./LoginPage.module.css";
import SelectLocation from "./SelectLocation";

export type LocationType = "GWANGSANGU" | "NAMGU" | "DONGGU" | "BUKGU" | "SEOGU"

const LoginPage = () => {
  const { member } = useMember();
  const navigate = useNavigate();
  const { login, loginBasic, debugLogin } = useMember();
  // const [displayInput, setDisplayInput] = useState(true)
  // const [phoneNumber, setPhoneNumber] = useState<string>()
  const {register, handleSubmit, formState: { errors }, getValues, setError, watch} = useForm<LoginBasic>()
  // const [logingMem, setLoginMem] = useState({} as Login)
  const paramCheck = () => {
    if(!engValid.test(getValues("userId"))) {
      setInputCheck({stat: false, msg: "올바른 아이디를 입력하세요."})
    } else {
      setInputCheck({stat: true, msg: ""})
    }
  }
  const [selectedLocation, setSelectedLocation] = useState<LocationType>("SEOGU")

  useEffect(() => {
    setError("userId", {type: "text"})
    setError("userPw", {type: "text"})
  }, [])

  useEffect(() => {
    // console.log("selectedLocation : ", selectedLocation)
  }, [selectedLocation])

  const [inputCheck, setInputCheck] = useState<ValidCheck>({stat: true, msg: "비정상적 값 입력됨"})
  const idValidation = (e:any) => {
    engValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }


  const onSubmit:SubmitHandler<LoginBasic> = async (data) => {
    paramCheck()
    if(!inputCheck.stat) {
      window.alert(inputCheck.msg)
      return
    }
    // console.log(data)
    loginBasic(data)
  }

  const onError = (e: FieldErrors<LoginBasic>) => {
    if(getValues("userId").length !== 0 && getValues("userPw").length === 0) {  //pw input x
      window.alert(errors.userPw?.message)
    } else {
      window.alert(errors.userId?.message)
    }
  }

  const onEnter = (e:any) => {
    if(e.key === 'Enter') {
      handleSubmit(onSubmit(watch()))
    }
  }

  // const testFunc = () => {
  //   console.log(logingMem)
  // }

  const handleDebugLogin = () => {
    debugLogin(Member.manager);
  };

  // const handleGoogleLogin = () => {
  //   signInWithPopup(getAuth(), new GoogleAuthProvider()).then((result) => {
  //     console.log(result)
  //     const { user } = result;
  //     login(user.uid, "G");
  //   });
  // };

  // const handleAppleLogin = () => {
  //   signInWithPopup(getAuth(), new OAuthProvider('apple.com')).then((res) => {
  //     console.log(res)
  //     const { user } = res;
  //     login(user.uid, "A");
  //   })
  // }

  // const handleInputDisplay = () => {
  //   setDisplayInput((cur) => !cur)
  // }

  // const handlePhoneLogin = (phoneNumber : string) => {
  //   const auth = getAuth();
  //   signInWithPhoneNumber(auth, phoneNumber, new RecaptchaVerifier('sign-in-button', {
  //     'size': 'invisible',
  //     'callback': (response:any) => {
  //       // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       // onSignInSubmit();
  //     }
  //   }, auth)).then((res) => {
  //     console.log(res)
  //   })
  // }

  if (member) {
    return <Navigate to="/home" replace />;
  }

  return (
    <>
    {selectedLocation ? 
    <div id={styles["Wrapper"]}>
      <div className={styles.wrapper} style={{ backgroundColor: "white" }}>
        <div className={styles.visual}>
          <div className={styles.title_inner}>
            <h1>Log In</h1>
            <p>
              {/* 원하는 로그인 방식을 */}
              아이디와 비밀번호를 입력 후
              <br />
              {/* 선택하여 로그인 해주세요. */}
              로그인 해주세요.
            </p>
          </div>
          <div className={styles.logo_inner}>
            <a
              href="#"
              aria-label="홈으로"
              className={styles.logo}
              // onClick={handleDebugLogin}
            >
              AI토탈케어플랫폼
            </a>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.content_inner}>
            <form className={styles.loginBtn_box} onKeyUp={(e) => onEnter(e)}>
              <ul>
                <li>
                  <div>
                      <h4>아이디</h4>
                      <input type="text" placeholder="아이디를 입력하세요" {...register("userId", {
                        required: "아이디를 입력해주세요.",
                        onChange: (e:any) => {
                          idValidation(e)
                          if(!engValid.test(e.target.value)) {
                            e.target.value = String(e.target.value).slice(0, -1)
                          }
                        }
                      })} />
                  </div>
                </li>
                <li>
                  <div>
                      <h4>비밀번호</h4>
                      <input type="password" placeholder="비밀번호를 입력하세요" {...register("userPw", {
                        required: "비밀번호를 입력해주세요."
                      })} />
                  </div>
                </li>
              </ul>
              <button type="button" onClick={handleSubmit(onSubmit, onError)}>로그인</button>
              <div className={styles.join_wrap}>
                  <p>아이디가 없으신가요?</p>
                  <a href="#" onClick={() => navigate('/join')}>회원가입</a>
              </div>
              <div className={styles.join_wrap}>
                <a href="#" onClick={() => {window.location.href = `https://dev.nubiz.co.kr:11160/`}}>구 선택</a>
              </div>
              {/* <a
                href="#"
                aria-label="카카오톡으로 시작하기"
                className={styles.kakao}
              >
                카카오톡으로 시작하기
              </a>
              <a
                href="#"
                aria-label="네이버로 시작하기"
                className={styles.naver}
              >
                네이버로 시작하기
              </a>
              <a
                href="#"
                aria-label="연락처로 시작하기"
                className={styles.phone}
              >
                  연락처로 시작하기
              </a>
              <a
                href="#"
                aria-label="구글로 시작하기"
                className={styles.google}
                onClick={handleGoogleLogin}
              >
                구글로 시작하기
              </a>
              <a
                href="#"
                aria-label="Apple로 시작하기"
                className={styles.apple}
                onClick={handleAppleLogin}
              >
                Apple로 시작하기
              </a>
              <a
                aria-label="일반 로그인 테스트"
                className={styles.basic}
                onClick={handleInputDisplay}
              >
                일반 로그인 테스트
              </a>
              <form
                style={{
                  display : displayInput ? "none" : "flex"
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <input 
                  type="text"
                  placeholder="Input ID"
                  {...register("userId")}
                />
                <input 
                  type="password"
                  placeholder="Input PW"
                  {...register("userPw")}
                />
                <button 
                >Submit</button>
              </form>
              <button onClick={testFunc}>테스트용 버튼</button>
              <a
                aria-label="일반 가입 테스트"
                className={styles.basic}
                onClick={() => navigate("/join")}
              >
                가입 테스트
              </a> */}
            </form>
            <div className={styles.terms_box}>
              <a href="#" aria-label="개인정보처리방침">
                개인정보처리방침
              </a>
              <a href="#" aria-label="이용약관">
                이용약관
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    :
    <SelectLocation setSelectedLocation={setSelectedLocation} />}
    
    </>
    
  );
};

export default LoginPage;
