import DollbomPlanFormInput from "../../../../models/form/DollbomPlanFormInput";

const DollbomPlanItem = ({
    plan,
    onModify,
    onDelete,
  }: {
    plan: DollbomPlanFormInput;
    onModify: () => void;
    onDelete: () => void;
  }) => {
    return (
      <tr>
        <td>
          {/* 추가버튼 누를때 추가되는 테이블 */}
          <input type="hidden" id="modifyCheck" value={0} />
          <input type="hidden" className="cateCd" value={plan.cateCd} />
          {plan.weekday && (
            <input type="hidden" className="weekday" value={plan.weekday} />
          )}
          {plan.cycleUnit && (
            <input type="hidden" className="cycleUnit" value={plan.cycleUnit} />
          )}
          <input type="hidden" className="siUid" value={plan.siUid} />
          {/* {plan.totalCount && (
          <input type="hidden" className="totalCount" value={plan.totalCount} />
          )} */}
          {Number(plan.cateCd) === 1 && "가사지원"} {Number(plan.cateCd) === 2 && "식사지원"}
          {Number(plan.cateCd) === 3 && "동행지원"} {Number(plan.cateCd) === 4 && "건강지원"}
          {Number(plan.cateCd) === 5 && "안전지원"} {Number(plan.cateCd) === 6 && "주거편의"}
          {Number(plan.cateCd) === 7 && "일시보호"} &gt;
          {plan.serviceNm}
        </td>
        {/* 지원시간(내용) 부분 새로 수정 */}
        {Number(plan.cateCd) !== 3 && Number(plan.cateCd) !== 5 && Number(plan.cateCd) !== 6 ?
        <td>
            {plan.totalCount && plan.totalCount + "회"}
            {plan.cycleUnit && "/" + plan.cycleUnit}
            {plan.weekday && "/" + plan.weekday}
            {(Number(plan.cateCd) === 1) && "/" + plan.times + "시간"}
        </td>
        :
        <td>
          {plan.totalCount ? plan.totalCount + "회" : ""}{(plan.totalCount && plan.serviceDetail) ? "/" + plan.serviceDetail : plan.serviceDetail}
        </td>
        }
        
        {/* {plan.totalCount && !plan.serviceDetail && plan.times && (
          <td>
            {plan.totalCount && plan.totalCount + "회"}
            {plan.cycleUnit && "/" + plan.cycleUnit}
            {plan.weekday && "/" + plan.weekday}
            {plan.times && "/" + plan.times}
            &nbsp;
            {plan.totalCount}회&nbsp;({plan.weekday}) {plan.times}시간
          </td>
        )}
        {plan.totalCount && !plan.serviceDetail && !plan.times && (
          <td>{plan.totalCount}회</td>
        )}
        {plan.content && <td>{plan.content}</td>}
        {plan.serviceDetail && plan.totalCount && plan.cateCd == 3 && (
          <td>
            {plan.totalCount}회({plan.serviceDetail})
          </td>
        )}
        {plan.cateCd == 5 && plan.serviceDetail && <td>{plan.serviceDetail}</td>}
        {plan.cateCd == 6 && plan.serviceDetail && <td>{plan.serviceDetail}</td>} */}
  
        {/* 지원기간 부분 새로 작성 */}
        {plan.beginDate && plan.endDate ?
        <td>
          {plan.beginDate}~{plan.endDate}
        </td>
        :
        <td>-</td> 
        }
  
        {/* <td>
          {(plan.cateCd !== 3 && plan.cateCd !== 5 && plan.cateCd !== 6) && !plan.beginDate && "-"}
          {plan.beginDate}
          {(plan.cateCd !== 3 && plan.cateCd !== 5 && plan.cateCd !== 6) && plan.endDate && ` ~ ${plan.endDate}`}
        </td> */}
  
        {/* 비용부분 새로 작성
        분기 1 : default
        분기 2 : cateCd 3,5,6
        */}
        {Number(plan.cateCd) !== 3 && Number(plan.cateCd) !== 5 && Number(plan.cateCd) !== 6 ?
        <td style={{ textAlign: "right" }}>
          <span className="red">
            {Number(plan.cateCd) === 1 ? (plan.totalCount! * plan.times! * plan.cost).toLocaleString("ko-KR") : (plan.totalCount! * plan.cost).toLocaleString("ko-KR")} 원
          </span>
        </td>
        :
        <td style={{ textAlign: "right" }}>
          <span className="gray">
            {plan.totalCount ? (plan.totalCount * plan.cost).toLocaleString("ko-KR") : (plan.cost).toLocaleString("ko-KR")} 원
          </span>
        </td>
        }
  
        {/* <td style={{ textAlign: "right" }}>
          {(plan.cateCd !== 3 && plan.cateCd !== 5 && plan.cateCd !== 6) && plan.beginDate &&
            <span className="red">
              {!plan.totalCount && !plan.times && plan.cost.toLocaleString()}
              {plan.totalCount &&
                !plan.times &&
                (plan.cost * plan.totalCount).toLocaleString()}
              {plan.totalCount &&
                plan.times &&
                (plan.cost * plan.totalCount * plan.times).toLocaleString()}
            </span>
          }
          {!(plan.cateCd !== 3 && plan.cateCd !== 5 && plan.cateCd !== 6) && !plan.beginDate &&
            <span className="gray">
              {!plan.totalCount && !plan.times && plan.cost.toLocaleString()}
              {plan.totalCount &&
                !plan.times &&
                (plan.cost * plan.totalCount).toLocaleString()}
              {plan.totalCount &&
                plan.times &&
                (plan.cost * plan.totalCount * plan.times).toLocaleString()}
            </span>
          }{" "}
          원
        </td> */}
  
        <td>
          <button
            type="button"
            className="btn_gray mr6"
            onClick={onModify}
          >
            수정
          </button>
          <button type="button" className="btn_red" onClick={onDelete}>
            삭제
          </button>
        </td>
      </tr>
    );
  };

  export default DollbomPlanItem