import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import ServiceTargetAPI from "../../apis/ServiceTargetAPI";
import CloseIcon from "../../assets/ic_close.png";
import useMember from "../../hooks/useMember";
import { DialogProps } from "../DialogProvider";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.23
 *
 * 접수취소/사례종결 팝업
 * @param syUid 사례관리 일련번호
 */
interface CancelScheduleDialogProps extends DialogProps {
  syUid: number;
}

interface CancelScheduleFormInput {
  stopGbn: string,
  stopReason: string,
}

const CancelScheduleDialog = ({
  syUid,
  ...props
}: CancelScheduleDialogProps) => {
  const navigate = useNavigate();
  const { member } = useMember();
  const { register, handleSubmit, getValues, watch } = useForm<CancelScheduleFormInput>();

  const handleClose = () => {
    props.onClose?.();
  };

  const data = watch()
  useEffect(() => {

  }, [data])

  const onSubmit: SubmitHandler<CancelScheduleFormInput> = async (data) => {
    if(!getValues("stopGbn") || getValues("stopGbn").length === 0) {
      window.alert("사유를 선택해주세요.")
      return
    }
    if(getValues("stopGbn") === "기타") {
      if(!getValues("stopReason")) {
        window.alert("취소 상세사유를 입력해주세요.")
        return
      }
    }
    // console.log(data);
    // TODO: 유효성 검사
    // window.alert("성공")

    const success = await ServiceTargetAPI.cancel({ 
      ...data,
      syUid: syUid,
     });

    if (success) {
      navigate(0);
    } else {
      console.log(">>> failure");
    }
  };

  return (
    <div className="stop_popup popup_black" {...props}>
      <div className="stop_popup_inner popup_white">
        <div className="popup_tit">
          <h3>접수취소</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="default_input">
              <h4 style={{ fontWeight: 600 }}>취소사유</h4>
              <div className="write_box">
                <select className="essential "  {...register("stopGbn")}
                  style={{
                    color: 'rgb(0, 0, 0)', height: 40, border: '1px solid #e6e8ea', backgroundColor: '#f6f7f9', fontSize: '15',
                    borderRadius: '5px', width: "340px"
                  }} >
                  <option value="">선택</option>
                  <option value="본인취소">본인취소</option>
                  <option value="이사">전출</option>
                  <option value="사망">사망</option>
                  <option value="입원">입원</option>
                  <option value="장기출타">장기출타</option>
                  <option value="기타">기타</option>
                </select>
              </div>
            </div>
            <div className="default_input">
              <h4></h4>
              <div className="write_box">
                <textarea
                  placeholder="취소사유 입력"
                  className={`mr8 ${getValues("stopGbn") === "기타" ? "essential" : ""}`}
                  disabled={getValues("stopGbn") === "기타" ? false : true}
                  {...register("stopReason")}
                ></textarea>
              </div>
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="submit" className="submit">
                저장
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CancelScheduleDialog;
