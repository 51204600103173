import CloseIcon from "../../assets/ic_close.png";
import { DialogProps } from "../DialogProvider";
import { useEffect, useState } from "react";
import BaseServiceManager from "../../models/BaseServiceManager";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import ServiceAPI, { testType } from "../../apis/ServiceAPI";
import { Chip } from "@mui/material";
import CaseManager from "../../models/CaseManager";
import "../service-target/AssignManagerDialog.scss";
import { MultiSelect, Option } from "react-multi-select-component";
import ServiceTargetAPI from "../../apis/ServiceTargetAPI";


/**
 * 작성자: 김혜정
 * 작성일: 2023.02.24
 * 서비스 담당자 배정 팝업
 * @param spyUid 돌봄계획 상세 일련번호
 * @param dptUid 기관 일련번호
 * @param syUid 돌봄계획 일련번호
 * @param initialManager (수정일 경우) 배정된 매니저 초기값
 * 
 */

interface AssignServiceManagerDialog extends DialogProps {
  spyUid: number;
  syUid: number;
  initialManagers?: CaseManager[];
  depart: string;
  dptUid: number;
}

const AssignServiceManagerDialog = ({ spyUid, syUid, initialManagers, depart,dptUid, ...props }: AssignServiceManagerDialog) => {
  const [name, setName] = useState("");
  const [selectedManagers, setSelectedManagers] = useState<CaseManager[]>(initialManagers ?? []);
  const [managers, setManagers] = useState({} as testType);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)

  // 검색 시 바로 API를 호출하는 것이 아니라,
  // 500밀리초 동안 이벤트가 없을 경우 호출
  // const debouncedName = useDebounce(name, 500);

  const {control, watch, setValue} = useForm<serviceMngOps>();
  const [mng, setMng] = useState([] as Option[]);

   interface serviceMngOps {
    serviceMng: Option[]
   }
   const data = watch()

  // 이름 변경 시 담당자 검색 API 호출
  useEffect(() => {

    ServiceAPI.findManagersByName({ name: depart, dptUid: dptUid }).then((res) => {
      console.log(res)
      setManagers(res)
      setLoading(false)
    });

    initialManagers?.map((item) => {
      setMng((cur) => {
        let temp = cur.find((e) => e.label === item.userName && e.value === item.mbrUid)
        if(temp) {
          return [...cur]
        }else {
          return [...cur, {label: item.userName, value: item.mbrUid}]
        }
      })
    });

    let tempArr = [] as Option[]
    initialManagers?.map((item) => {
      tempArr.push({label : item.userName, value: item.mbrUid})
    })
    setValue("serviceMng", tempArr)
  }, []);

  useEffect(() => {
    if(data.serviceMng) {
      setMng(data.serviceMng)
    }
   
  }, [data])

  useEffect(() => {
    if(mng.length > 0) {
      let tempArr = [] as CaseManager[]
      mng.map((mng) => {
        tempArr.push({mbrUid: mng.value, userName: mng.label})
      })
      setSelectedManagers(tempArr)
    } else {
      setSelectedManagers([])
    }
    if(mng.length > 1) {
      mng.pop()
      selectedManagers.pop()
      setValue("serviceMng", mng)
    }
  }, [mng])

  const handleManagerRemove = (manager: CaseManager) => {
    let removeArr = mng.filter((item) => item.label !== manager.userName && item.value !== manager.mbrUid)
    setValue("serviceMng", removeArr)
  };

  const handleClose = () => {
    props.onClose?.();
  };

  const handleSubmit = async () => {
    if (selectedManagers.length === 0) {
      return;
    }

    const success = await ServiceAPI.assignManager({
      spyUid, syUid, mbrUid: selectedManagers[0].mbrUid
    });

    if (success) {
      navigate(0);
    } else {
      // TODO: 실패 처리
    }
  };

  return (
    <div className="assign_manager_dialog assign_popup popup_black" {...props}>
      {loading ? null : 
        <div
        className="assign_popup_inner popup_white"
        style={{ overflowY: "visible" }}
      >
        <div className="popup_tit">
          <h3>담당자 배정</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form id="autoform">
            <div className="default_input name">
              <h4>담당자</h4>
              <div className="write_box">
                 <Controller  
                  control={control}
                  name="serviceMng"
                  render={({field : {onChange, value}}) => (
                    <MultiSelect
                      options={ManagerOptions(managers)}
                      value = {value?.map((v) => ({ label: v.label, value: v.value })) ?? []}
                      onChange={(e:{label:string, value: string}[]) => (
                        onChange(e.map((e) => {
                          return {label: e.label, value: e.value}
                        }))
                      )}
                      labelledBy=""
                      disableSearch
                      hasSelectAll={false}
                      overrideStrings={{
                        selectSomeItems: "담당자 선택",
                        allItemsAreSelected: "모두선택",
                          }}
                     /> // MultiSelect   
                  )}
                /> 

              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "70px" }} />
              {selectedManagers?.map((manager) => (
                <Chip
                  key={manager.mbrUid}
                  label={manager.userName}
                  onDelete={() => handleManagerRemove(manager)}
                  sx={{ marginTop: "8px", marginRight: "8px" }}
                />
              )) ?? []}
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="button" className="submit" onClick={handleSubmit}>
                저장
              </button>
            </div>
          </form>
        </div>
      </div>
      }
      
    </div>
  );
};

const ManagerOptions = (managers : testType) =>{
  let ops:Option[] = []
  if(managers.list) {
    managers.list.map((item) => {
        ops.push({
          label: item.userName,
          value: item.mbrUid,
        })
    })
  }
  
  // setMng([...ops])
  return ops
};

export default AssignServiceManagerDialog;
