import { instanceToPlain } from "class-transformer";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DollbomPlanAPI, { SimpleInfo } from "../../apis/DollbomPlanAPI";
import ServiceTargetAPI from "../../apis/ServiceTargetAPI";
import { nameValid, telValid, ValidCheck } from "../../App";
import CloseIcon from "../../assets/ic_close.png";
import useMember from "../../hooks/useMember";
import CaseManager from "../../models/CaseManager";
import Gender from "../../models/Gender";
import { ServiceTargetRegistrationType } from "../../models/ServiceTarget";
import ServiceTargetYear, { ServiceTargetYearStatus } from "../../models/ServiceTargetYear";
import { DialogProps, useDialog } from "../DialogProvider";
import PostCodeDialog from "../PostCodeDialog";
import moment from "moment";
/**
 * 작성자: 김혜정
 * 작성일: 2023.03.06
 *
 * 결과보고서 > 대상자 정보 수정
 * @param syUid (수정 시 필요) 사례관리 일련번호
 * @param siUid (수정 시 필요) 대상자 일련번호
 * @param onSuccess 성공했을 때 실행할 함수
 */

interface ServiceTargetSimpleInfoDialogProps extends DialogProps {
  syUid?: number;
  siUid?: number;
  serviceTarget: ServiceTargetYear; 
  onSuccess?: () => void;
}

export interface RegisterFormInput {
  // sbscWay: string; // 등록구분
  // sbscNm: string; // 신청인 명
  // sbscRel: string; // 신청인 관계
  // sbscPhone1: string; // 신청인 연락처
  // sbscPhone2: string;
  // sbscPhone3: string;
  // trgterNm: string; // 대상자 이름
  // trgterCd: string; // 대상자 코드
  // brthdy: string; // 대상자 생년월일
  // gender: string; // 성별
  // telno1: string; // 전화번호
  // telno2: string;
  // telno3: string;
  // hdong: string; // 행정동
  // adres1: string; // 주소
  // adres2: string;
  // sbscReason: string; // 신청사유
  // sbscEtc: string; // 참고사항
  // lat: number; // 위도
  // lng: number; // 경도
  syUid: number,
  stUid: number,
  csUid: number | undefined,
  trgterNm: string,
  trgterCd: string,
  hdong: string,
  brthdy: string,
  gender: Gender,
  telno: string,
  adres: string,
  adresDtl: string,
  statCd: ServiceTargetYearStatus,
  crtDt: string | undefined,
  crtUid: number | undefined,
  crtNm: string | undefined,
  sbscWay: ServiceTargetRegistrationType,
  applcntNm: string | undefined,
  applcntRel: string | undefined,
  applcntTel: string | undefined,
  applcntReason: string | undefined,
  applcntEtc: string | undefined,
  counselDate: string | undefined,
  managers: CaseManager[],
  incomeLevel: '85이하' | '100이하' | '100초과',
  incomeLevel2: '수급자' | '차상위' | '85이하' | "",
  emgcNm: string,
  emgcRel: string,
  emgcTel: string,
  homeMember: number,
  characterJson: string,
  telno1: string,
  telno2: string,
  telno3: string,
}

const ServiceTargetSimpleInfoDialog = ({onSuccess, serviceTarget , ...props }: ServiceTargetSimpleInfoDialogProps) => {
  const { member } = useMember();
  const { showDialog } = useDialog();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit, watch, setValue, setError, formState : {errors}, getValues } = useForm<SimpleInfo>({
    defaultValues: {
      ...serviceTarget,
      telno1: serviceTarget.telno.split('-')[0],
      telno2: serviceTarget.telno.split('-')[1],
      telno3: serviceTarget.telno.split('-')[2],
    }
  });
  const data = watch();

  const [visible, setVisible] = useState(true); // 다음 우편번호 컴포넌트의 노출여부 상태

  const handleClose = () => {
    props.onClose?.();
  };
  const [inputCheck, setInputCheck] = useState<ValidCheck>({stat: true, msg: "비정상적 값 입력됨"})
  const nameValidation = (e:any) => {
    nameValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }
  const telValidation = (e:any) => {
    telValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }
  const paramCheck = () => {
    if(!nameValid.test(getValues("trgterNm"))) {
      return {stat: false, msg: "올바른 대상자 이름을 입력하세요."}
    } else if(!telValid.test(getValues("telno2"))) {
      return {stat: false, msg: "올바른 대상자 연락처를 입력하세요."}
    } else if(!telValid.test(getValues("telno3"))) {
      return {stat: false, msg: "올바른 대상자 연락처를 입력하세요."}
    } else {
      return {stat: true, msg: ""}
    }
  }

  // useEffect(() => {
  //   console.log(serviceTarget)
  // }, [])

  // 주소 검색
  const handleSearchAddress = () => {
    showDialog(PostCodeDialog, {
      onComplete(data) {
        // console.log(data);

        setValue("adres", data.address);
      },
    })
  }

  const onSubmit: SubmitHandler<SimpleInfo> = async (data) => {
    let checkObj = paramCheck()
    if(!checkObj.stat) {
      window.alert(checkObj.msg)
      return
    }

    if (!member) {
      return;
    }
    const suc = await DollbomPlanAPI.updateSimpleInfo({
      ...data,
      telno: `${data.telno1}-${data.telno2}-${data.telno3}`,
      trgterCd: data.trgterNm + data.brthdy.replaceAll('-', '').substring(2)
    })
    if (suc) {
      window.alert("수정완료")
      navigate(0);
    } else {
      window.alert("수정실패")
    }
  };

  const onError = () => {
    if(errors.trgterNm) {
      window.alert(errors.trgterNm.message)
    } else if(errors.brthdy) {
      window.alert(errors.brthdy.message)
    } else if(errors.telno1) {
      window.alert(errors.telno1.message)
    } else if(errors.telno2) {
      window.alert(errors.telno2.message)
    } else if(errors.telno3) {
      window.alert(errors.telno3.message)
    } else if(errors.adres) {
      window.alert(errors.adres.message)
    }
  }

  return (
    <div className="recive_popup popup_black" {...props}>
      <div
        className="recive_popup_inner popup_white"
        style={{ overflowY: "visible"}}
      >
        <div className="popup_tit">
          <h3>돌봄대상자 인적사항</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            {/* <input type="hidden"{...register("sbscWay")}/>  */}
            <div className="default_input mt20">
              <h4>대상자</h4>
              <div className="write_box">
                <div
                  className="name mr8 w50_8"
                  style={{ position: "relative" }}
                >
                  <input
                    type="text"
                    className="essential input"
                    placeholder="성명"
                    list=""
                    role="combobox"
                    id="input1"
                    autoComplete="off"
                    // disabled={true}
                    {...register("trgterNm", {
                      required: "이름을 입력해주세요."
                    })}
                  />
                  {/* <datalist id="browsers1">
                    <option value="홍길동">홍길동</option>
                    <option value="홍길순">홍길순</option>
                    <option value="홍길">홍길</option>
                    <option value="박다혜">박다혜</option>
                  </datalist> */}
                </div>
                <div className="birth w50_8">
                  <input
                    type="date"
                    id="birth"
                    placeholder="생년월일"
                    // disabled={true}
                    // onfocus="(this.type='date')"
                    // onblur="(this.type='text')"
                    max={"9999-12-31"}
                    {...register("brthdy", {
                      required: "생년월일을 입력해주세요.",
                      onChange: (e) => {
                        if(moment().diff(moment(e.target.value), 'hours') < 24) {
                          window.alert("생년월일은 당일 일자 이전으로 설정해주세요.")
                          setValue("brthdy", moment().subtract(1, 'days').format('yyyy-MM-DD'))
                        }
                      },
                      onBlur: (e) => {
                        if(moment().diff(moment(e.target.value), 'hours') < 24) {
                          window.alert("생년월일은 당일 일자 이전으로 설정해주세요.")
                          setValue("brthdy", moment().subtract(1, 'days').format('yyyy-MM-DD'))
                        }
                      }
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="default_input">
              <h4></h4>
              <div className="write_box">
                <label htmlFor="radio1" className="mr20">
                  <input
                    type="radio"
                    id="radio1"
                    value="남"
                    // disabled={true}
                    {...register("gender")}
                  />
                  남성
                </label>
                <label htmlFor="radio2">
                  <input
                    type="radio"
                    id="radio2"
                    value="여"
                    // disabled={true}
                    {...register("gender")}
                  />
                  여성
                </label>
              </div>
            </div>
            <div className="default_input tel">
              <h4></h4>
              <div className="write_box">
                <select
                  className="essential"
                  style={{ color: data.telno1 ? "#000" : "#9da0a8" }}
                  // disabled={true}
                  {...register("telno1")}
                >
                  <option value="">선택</option>
                  <option value="010">010</option>
                  <option value="011">011</option>
                  <option value="012">017</option>
                  <option value="013">018</option>
                  <option value="014">019</option>
                </select>
                <span>-</span>
                <input type="tel" maxLength={4}
                  className="essential"
                  placeholder="연락처"
                    // disabled={true}
                    {...register("telno2", {
                    required: "연락처를 입력해주세요.",
                    onChange: (e:any) => {
                      if(!telValid.test(e.target.value)) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                      // telValidation(e)
                    }
                  })} />
                <span>-</span>
                <input type="tel" maxLength={4}
                  className="essential"
                  placeholder="연락처"
                    // disabled={true}
                    {...register("telno3", {
                    required: "연락처를 입력해주세요.",
                    onChange: (e:any) => {
                      if(!telValid.test(e.target.value)) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                      // telValidation(e)
                    }
                  })} />
              </div>
            </div>
            <div className="default_input mt8">
              <h4></h4>
              <div className="write_box">
                <div className="address">
                  <input
                    type="text"
                    className="mr8 essential"
                    placeholder="주소 입력"
                    disabled={true}
                    {...register("adres", {
                      required: "주소를 입력해주세요."
                    })}
                  />
                  <button type="button" onClick={handleSearchAddress}>주소검색</button>

                </div>
                <input
                  type="text"
                  className="mt8"
                  placeholder="상세주소 입력"
                  {...register("adresDtl")}
                />
              </div>
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="submit" className="submit">
                저장
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ServiceTargetSimpleInfoDialog;
