import Gender from "./Gender";

class MoveOutList {
    constructor(
       public stUid: number,
       public syUid: number,
       public dptUid: number,
       public gu: string,
       public trgterNm: string,
       public brthdy: string,
       public trgterCd: string,
       public gender: Gender,
       public telno: string,
       public hdong: string,
       public adres: string,
       public adresDtl: string,
       public stopGbn: string,
       public stopReason: string,
       public page: number,
       public startIndex: number,
       public pageSize: number,
       public totalCount: number,
       public searchKeyword: string,
       public searchType: string,
       public clclnSt: string,
    ){}
    get fullAddress(): string {
        return (this.adres ?? '') + ", " + (this.adresDtl ?? '');
      }
}

export default MoveOutList;