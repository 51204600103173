import React, { PropsWithChildren, useContext, useState } from "react";

import TimeUtil from "../utils/TimeUtil";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.23
 *
 * @example
 * // 1. 컴포넌트에 사용할 Props는 DialogProps를 확장한 형태로 정의
 * interface Props extends DialogProps { ... }
 *
 * // 2. 컴포넌트의 최상위 레이아웃에 props 넘겨주기
 * const Component = ({ ...props }: Props) => {
 *   return (
 *     <div {...props}>
 *     </div>
 *   )
 * }
 * @end
 *
 * 전역에서 팝업을 관리하기 위한 컴포넌트
 */
const DialogProvider = ({ children }: PropsWithChildren) => {
  const [dialogs, setDialogs] = useState([] as Dialog[]);

  const showDialog: ShowDialogFn = async (type, props) => {
    const dialog: Dialog = {
      id: dialogs.length,
      type: type as React.ComponentType<DialogProps>,
      props: props,
      isOpen: false,
    };
    const newArr = [...dialogs, dialog];
    setDialogs(newArr);

    await TimeUtil.delay(0);
    const openedDialog: Dialog = {
      ...dialog,
      isOpen: true,
    };
    setDialogs([...newArr.slice(0, -1), openedDialog]);
  };

  const handleClose = async (id: number) => {
    const dialog = dialogs.find((dialog) => dialog.id === id);
    if (!dialog) {
      return;
    }

    const closedDialog: Dialog = {
      ...dialog,
      isOpen: false,
    };
    const newArr = dialogs.filter((dialog) => dialog.id !== id);
    setDialogs([...newArr, closedDialog]);

    await TimeUtil.delay(200);
    setDialogs(newArr);
  };

  return (
    <DialogContext.Provider value={{ showDialog }}>
      {dialogs.map((dialog) => (
        <dialog.type
          key={dialog.id}
          onClose={() => handleClose(dialog.id)}
          style={{
            display: "flex",
            transition: "all .2s",
            opacity: dialog.isOpen ? 1 : 0,
            // visibility: dialog.isOpen ? "visible" : "hidden",
          }}
          {...dialog.props}
        />
      ))}
      {children}
    </DialogContext.Provider>
  );
};

export interface DialogProps {
  style?: React.CSSProperties;
  onClose?: () => void;
}

interface Dialog<P extends DialogProps = DialogProps> {
  id: number;
  type: React.ComponentType<P>;
  props?: P;
  isOpen: boolean;
}

type ShowDialogFn = <P extends DialogProps>(
  type: React.ComponentType<P>,
  props?: P
) => any;

const DialogContext = React.createContext<{
  showDialog: ShowDialogFn;
}>({ showDialog: () => {} });

export const useDialog = () => useContext(DialogContext);

export default DialogProvider;
