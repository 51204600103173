import React, { useState } from "react";

import ArrowIcon from "../../assets/ic_arrow.png";
import Next2Icon from "../../assets/ic_next2.png";
import Next1Icon from "../../assets/ic_next.png";
import Prev2Icon from "../../assets/ic_prev2.png";
import Prev1Icon from "../../assets/ic_prev.png";
import { SpyList } from "../../models/ServiceMenuList";
import EmergencyIcon from "../../assets/긴급돌봄아이콘.png";
import StopIcon from "../../assets/중단요청아이콘.png"
import { useDialog } from "../../dialogs/DialogProvider";
import OfficialPowerStopDialog from "../../dialogs/service/OfficialPowerStopDialog";


/**
 *
 * @example
 * <DataTableDetail
 *   expandable
 *   columnTitles={['제목', '내용']}
 *   rows={[
 *     createDetailTableRow({
 *       cells: [
 *         createDetailTextCell({ text: '제목' })
 *         createDetailTextCell({ text: '내용' })
 *       ],
 *       expandContent: (<div>펼쳐졌을 때 보여줄 내용</div>)
 *     })
 *   ]}
 * />
 * @end
 *
 * 한 행을 나타내는 interface
 * @param expandContent 행이 펼쳐졌을 때 보여줄 내용
 * @param cells 행이 가진 셀
 */
export interface SpyListDataTableRow {
  expandContent?: React.ReactNode;
  cells: SpyListDataTableCell[];
  isStopReq?: boolean;
  // spyList: SpyList[];
}
export const createSpyListRow = (row: SpyListDataTableRow) => row;

/**
 * 셀의 타입은 Text 또는 Button 둘 중 하나여야 함
 */
export type SpyListDataTableCell = SpyListDataTableTextCell | SpyListDataTableButtonCell;

/**
 * 셀이 텍스트인 경우에 사용하는 interface
 * @param text 셀 내용
 * @param ellipsis 생략 사용 여부
 * @param d_day 디데이
 */
export interface SpyListDataTableTextCell {
  text: string;
  ellipsis?: boolean;
  d_day?: number;
  isEmergency? : boolean;
  isStop?: boolean;
}
export const createSpyListTextCell = (cell: SpyListDataTableTextCell) => cell;
const isTextCell = (cell: SpyListDataTableCell): cell is SpyListDataTableTextCell =>
  "text" in cell;

/**
 * 셀이 버튼인 경우에 사용하는 interface
 * @param type underlined: 검정색 밑줄 버튼, outlined: 파란색 외곽선 버튼, undefined: -
 * @param text 버튼 제목
 * @param onClick 버튼이 클릳되었을 때 호출되는 함수
 */
export interface SpyListDataTableButtonCell {
  type: "underlined" | "outlined" | undefined;
  label: string;
  onClick: () => void;
}
export const createSpyListButtonCell = (cell: SpyListDataTableButtonCell) => cell;
const isButtonCell = (cell: SpyListDataTableCell): cell is SpyListDataTableButtonCell =>
  "label" in cell;

/**
 * 모든 목록에서 사용할 수 있는 데이터 테이블 컴포넌트
 * @param expandable 펼쳐지는 행이 있는지 여부
 * @param columnTitles 열의 제목
 * @param rows 데이터 행
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
const SpyListDataTable = ({
  expandable = false,
  columnTitles,
  rows,
  spyUid,
  statCd,
  // spyList, 
}: {
  expandable?: boolean;
  columnTitles: string[];
  rows: SpyListDataTableRow[];
  spyUid: number;
  statCd: string,
  // spyList: SpyList[];
}) => {
  const { showDialog } = useDialog();
  const handleStop = (spyUid: number) => {
    showDialog(OfficialPowerStopDialog, {
      spyUid : spyUid,
    });
  }
  return (
    <>
    {statCd !== "사례종결" && 
    <button type="button" className="btn_stop" onClick={() => handleStop(spyUid)}>직권중단</button>
    }
      <table>
        <thead>
          <tr>
            {expandable && <th className="first"></th>}           
            {columnTitles && columnTitles.map((title, index) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows && rows.map((row, index) => (
            <Row key={index} row={row}/>
          ))}
        </tbody>
      </table>
      </>
  );
};

/**
 * 데이터 테이블의 한 행을 나타내는 컴포넌트
 */
const Row = ({ row }: { row: SpyListDataTableRow }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <tr
        className={`table_title2 ${expanded ? "on" : "off"}`}
        onClick={handleExpand}
        style={{backgroundColor: row.isStopReq ? "#FFE4B5" : ""}}
      >
        {row.expandContent && (
          <td className="first">
            <img src={ArrowIcon} alt="접고 펼치기 아이콘" />
          </td>
        )}
        {row.cells.map((cell, index) => (
          <Cell key={index} cell={cell} />
        ))}
      </tr>
      {row.expandContent && (
        <tr
          className={`table_content2 ${expanded ? "on" : ""}`}
          style={{ display: expanded ? "table-row" : "none" }}
        >
          <td colSpan={50}>
            {row.expandContent}</td>
        </tr>
      )}
    </>
  );
};

/**
 * 데이터 테이블의 한 셀을 나타내는 컴포넌트
 */
const Cell = ({ cell }: { cell: SpyListDataTableCell }) => {
  if (isTextCell(cell)) {
    if (cell.ellipsis) {
      return (
        <td className="address">
          <div className="ellipsis">
            <p>
              {cell.isStop
                ? <img src={StopIcon}/>
                : ""
              }
              {cell.isEmergency
                ? <img src={EmergencyIcon}/>
                : ""
              }
              {cell.text}
              {<D_Day d_day={cell.d_day} />}
            </p>
          </div>
        </td>
      );
    } else {
      return (
        <td>
          {cell.isStop
                ? <img src={StopIcon}/>
                : ""
              }
          {cell.isEmergency
                ? <img src={EmergencyIcon}/>
                : ""
              }
          {cell.text}
          {<D_Day d_day={cell.d_day} />}
        </td>
      );
    }
  } else {
    return (
      <td>
        {cell.type ? (
          <button
            type="button"
            className={`btn_blue ${cell.type === "underlined" ? "edit" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
              cell.onClick();
            }}
          >
            {cell.label}
          </button>
        ) : (
          "-"
        )}
      </td>
    );
  }
};

/**
 * 디데이를 나타내는 컴포넌트
 */
const D_Day = ({ d_day }: { d_day?: number }) => {
  let d_dayElement = <></>;
  if (d_day) {
    if (d_day === 0) {
      d_dayElement = (
        <>
          <span className="red">D-day</span>
        </>
      );
    } else if (d_day <= 2) {
      d_dayElement = (
        <>
          <span className="yellow">D-{d_day}</span>
        </>
      );
    }
  }
  return d_dayElement;
};

/**
 * 테이블 하단 페이징
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 * @returns
 */
const Pagination = ({
  page,
  onPageChange,
}: {
  page: number;
  onPageChange: (page: number) => void;
}) => {
  return (
    <div id="paging" className="">
      <p>
        <span className="numPN m_ar">
          <a href="#" data-page="1" title="처음 페이지로 이동하기">
            <img src={Prev2Icon} alt="처음 페이지" />
          </a>
        </span>
        <span className="numPN over left">
          <a href="#" data-page="1" title="이전 페이지로 이동하기">
            <img src={Prev1Icon} alt="이전 페이지" />
          </a>
        </span>
        <span className="Present">
          <a className="on" href="#" data-page="1">
            1
          </a>
        </span>
        <span>
          <a href="#" data-page="2">
            2
          </a>
        </span>
        <span>
          <a href="#" data-page="3">
            3
          </a>
        </span>
        <span>
          <a href="#" data-page="4">
            4
          </a>
        </span>
        <span>
          <a href="#" data-page="5">
            5
          </a>
        </span>
        <span className="numPN  over right">
          <a href="#" data-page="11" title="다음 페이지로 이동하기">
            <img src={Next1Icon} alt="다음 페이지" />
          </a>
        </span>
        <span className="numPN m_ar">
          <a href="#" data-page="14" title="마지막 페이지로 이동하기">
            <img src={Next2Icon} alt="마지막 페이지" />
          </a>
        </span>
      </p>
    </div>
  );
};

export default SpyListDataTable;
