import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DollbomPlanAPI, { PrevPlanInfo, PrevPlan } from "../../apis/DollbomPlanAPI";

import CloseIcon from "../../assets/ic_close.png";
import NeedsAssessmentJoin from "../../models/NeedsAssessmentJoin";

import { DialogProps } from "../DialogProvider";

import "./DollbomPlanInfoDialog.scss"
import SettingAPI, { DepartSelect, ServiceSelect } from "../../apis/SettingAPI";
import EmerIcon from "../../assets/ic_emergency.png"
import NeedAsTabTable1 from "../../pages/member/service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable1";
import NeedAsTabTable2 from "../../pages/member/service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable2";
import NeedAsTabTable3 from "../../pages/member/service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable3";
import NeedAsTabTable4 from "../../pages/member/service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable4";
import NeedAsTabTable5 from "../../pages/member/service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable5";
import NeedAsTabTable6 from "../../pages/member/service-plan/DollbomPlanComponents/TabTableComponents/NeedAsTabTable6";
import NeedAsBottom from "../../pages/member/service-plan/DollbomPlanComponents/TabTableComponents/NeedAsBottom";

/**
 *
 * 이전 돌봄계획 팝업
 */
interface DollbomPlanInfoDialog extends DialogProps {
    prevPlanInfo: PrevPlanInfo,
    prevPlanList: PrevPlan[],
}

const DollbomPlanInfoDialog = ({ prevPlanInfo, prevPlanList, ...props }: DollbomPlanInfoDialog) => {
    const [evaluationData, setEvaluationData] = useState<NeedsAssessmentJoin>();
    // 질병 및 증상 추가한거
    const [illAddList, setIllAddList] = useState<string[]>([]);
    const [siList, setSiList] = useState([] as ServiceSelect[])
    const [dptList, setDptList] = useState([] as DepartSelect[])
    // 돌봄필요도 평가지 데이터 불러오는 API
    useEffect(() => {
        DollbomPlanAPI.getEvaluationData({
            sdUid: Number(prevPlanList[0].sdUid),
            csUid: Number(prevPlanInfo.csUid),
        }).then((res) => {
            setEvaluationData(res);
            // console.log("asdasdasdas", res);
            setIllAddList(JSON.parse(res.jsonData.illAdd));
            // console.log(res.jsonData.illAdd);
        });

        SettingAPI.getServiceSelectAll().then((res) => {
            setSiList(res)
        })
        SettingAPI.getDepartSelect().then((res) => {
            setDptList(res)
        })
    }, []);

    const handleClose = () => {
        props.onClose?.();
    };

    return (
        <div className="DollbomPlanInfoDialog planInfo_popup_inner popup_black" {...props}>
            <div className="schedule_popup_inner popup_white" style={{ overflowY: 'scroll' }}>
                <div className="popup_tit">
                    <h3>{moment(prevPlanInfo.prevDt).format('yyyy-MM-DD(dd)')} 돌봄계획</h3>
                    <a href="#" className="close" onClick={handleClose}>
                        <img src={CloseIcon} alt="닫기" />
                    </a>
                </div>
                <div className="care_plan_list">
                    <table>
                        <thead>
                            <tr>
                                <th>서비스 종류</th>
                                <th>제공기관</th>
                                <th>제공기간</th>
                                <th>비용</th>
                            </tr>
                        </thead>
                        <tbody>
                            {prevPlanList.map((prevPlan, idx) => (
                                <tr key={idx}>
                                    <td>
                                        {prevPlan.cateCd === 8 ?
                                        <img src={EmerIcon} />
                                        : null}
                                        {prevPlan.cateCd === 0 ? "기존돌봄" : 
                                        prevPlan.cateCd === 1 ? "가사지원" :
                                        prevPlan.cateCd === 2 ? "식사지원" :
                                        prevPlan.cateCd === 3 ? "동행지원" :
                                        prevPlan.cateCd === 4 ? "건강지원" :
                                        prevPlan.cateCd === 5 ? "안전지원" :
                                        prevPlan.cateCd === 6 ? "주거편의" :
                                        prevPlan.cateCd === 7 ? "일시보호" :
                                        "긴급돌봄"
                                        } &gt; 
                                        {siList.map((si) => {
                                            if(Number(si.siUid) === Number(prevPlan.siUid)) {
                                                return si.serviceNm
                                            }
                                        })}
                                    </td>
                                    <td>
                                        {dptList.map((dpt) => {
                                            if(Number(dpt.dptUid) === Number(prevPlan.dptUid)) {
                                                return dpt.departName
                                            }
                                        })}
                                    </td>
                                    <td>
                                        {prevPlan.beginDate ? moment(prevPlan.beginDate).format('MM-DD') : ""}{prevPlan.endDate ? " ~ " + moment(prevPlan.endDate).format('MM-DD') : ""}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        <span className={Number(prevPlan.cateCd) !== 3 && Number(prevPlan.cateCd) !== 5 && Number(prevPlan.cateCd) !== 6 ? "red" : "gray"}>
                                            {prevPlan.cost.toLocaleString("ko-KR")}
                                            </span> 원
                                    </td>
                                </tr>
                            ))}
                            {/* <tr>
                                <td>가사지원 &gt; 가사활동</td>
                                <td>oo기관</td>
                                <td>기본 12시간 / 야간 2시간</td>
                                <td style={{ textAlign: "right" }}>
                                    <span className="red">242,360</span> 원
                                </td>
                            </tr>
                            <tr>
                                <td>가사지원 &gt; 일상활동</td>
                                <td>xx기관</td>
                                <td>기본 10시간 / 심야 1시간</td>
                                <td style={{ textAlign: "right" }}>
                                    <span className="red">190,900</span> 원
                                </td>
                            </tr>
                            <tr>
                                <td>동행지원 &gt; 병원동행</td>
                                <td>xx기관</td>
                                <td>기본 4시간</td>
                                <td style={{ textAlign: "right" }}>
                                    <span className="gray">66,400</span> 원
                                </td>
                            </tr> */}
                        </tbody>
                    </table>
                    <div className="care_evaluation">
                        <div className="short_answer2">
                            <ul>
                                <li style={{width: "50%"}}>
                                    <div style={{width: "100%"}}>
                                        <p className="mt10">(긴급돌봄 총액) <b className="red">{prevPlanInfo.totalEmerSum ? prevPlanInfo.totalEmerSum.toLocaleString("ko-KR") : 0}</b> 원</p>
                                    </div>
                                </li>
                                <li style={{width: "50%"}}>
                                    <div style={{width: "100%"}}>
                                        <p className="mt10">(광주+돌봄 총액) <b className="red">{prevPlanInfo.totalSum ? prevPlanInfo.totalSum.toLocaleString("ko-KR") : 0}</b> 원</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {prevPlanInfo.csUid && 
                    <EvaluationData evaluationData={evaluationData} illAddList={illAddList} />
                }
                
            </div>
        </div>
    );
};
const EvaluationData = ({
    evaluationData, illAddList,
}: {
    evaluationData?: NeedsAssessmentJoin;
    illAddList: string[];
}) => {

    const [tabTable2, setTabTable2] = useState(0);

    return (
        <div className="padding tab_content" data-id={2}>

            {/* <div className="padding tab_content hidden" data-id={2}> */}
            <div className="care_evaluation">
                {/* 탭 */}
                <h3>{moment(evaluationData?.counselResult.endDt).format('yyyy-MM-DD(dd)')} 필요도평가</h3>
                <div className="tabs2">
                    <button
                        type="button"
                        className={`tabs2 ${tabTable2 === 0 ? "tab_active" : "non_active"} `}
                        onClick={() => setTabTable2(0)}
                        data-id={0}
                    >
                        신체적 영역
                    </button>
                    <button
                        type="button"
                        className={`tabs2 ${tabTable2 === 1 ? "tab_active" : "non_active"} `}
                        onClick={() => setTabTable2(1)}
                        data-id={1}
                    >
                        정서적 영역
                    </button>
                    <button
                        type="button"
                        className={`tabs2 ${tabTable2 === 2 ? "tab_active" : "non_active"} `}
                        onClick={() => setTabTable2(2)}
                        data-id={2}
                    >
                        추가정보(생활환경)
                    </button>
                    <button
                        type="button"
                        className={`tabs2 ${tabTable2 === 3 ? "tab_active" : "non_active"} `}
                        onClick={() => setTabTable2(3)}
                        data-id={3}
                    >
                        추가정보(사전검사)
                    </button>
                    <button
                        type="button"
                        className={`tabs2 ${tabTable2 === 4 ? "tab_active" : "non_active"} `}
                        onClick={() => setTabTable2(4)}
                        data-id={4}
                    >
                        광주+돌봄 지원대상 여부
                    </button>
                    <button
                        type="button"
                        className={`tabs2 ${tabTable2 === 5 ? "tab_active" : "non_active"} `}
                        onClick={() => setTabTable2(5)}
                        data-id={5}
                    >
                        긴급돌봄 지원대상 여부
                    </button>
                </div>
                {/* 탭 뷰 */}
                {tabTable2 === 0 && <NeedAsTabTable1 evaluationData={evaluationData} illAddList={illAddList} />}
                {tabTable2 === 1 && <NeedAsTabTable2 evaluationData={evaluationData} />}
                {tabTable2 === 2 && <NeedAsTabTable3 evaluationData={evaluationData} />}
                {tabTable2 === 3 && <NeedAsTabTable4 evaluationData={evaluationData} />}
                {tabTable2 === 4 && <NeedAsTabTable5 evaluationData={evaluationData} />}
                {tabTable2 === 5 && <NeedAsTabTable6 evaluationData={evaluationData} />}
                {/* 탭 뷰 종료 */}
                {/* 신체적 영역 */}

                {/* 정서적 영역 */}

                {/* 추가정보(생활환경) */}

                {/* 광주+돌봄 지원대상 여부 */}

                {/* 긴급돌봄 지원대상 여부 */}
                <NeedAsBottom evaluationData={evaluationData!} />
            </div>
        </div>
    );
};

export default DollbomPlanInfoDialog;;
