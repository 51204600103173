import { Type } from "class-transformer";
import ConferencePlan from "./ConferencePlan";
import Gender from "./Gender";
import ServiceTargetYear from "./ServiceTargetYear";

class CareRequest {
  @Type(() => ServiceTargetYear)
  public targetInfo: ServiceTargetYear;
  constructor(
    targetInfo: ServiceTargetYear,
    public planList: CareRequestList[],
    public emerUsingList: CareRequestList[],
    public gjList: CareRequestList[],
    public crtUpdDate: {
      firstCrt: string,
      lastUpd: string,
    }
  ) {
    this.targetInfo = targetInfo
  }
}

export class ServiceTargetInfo {
  constructor(
    public syUid: number,
    public trgterNm: string,
    public brthdy: string,
    public gender: Gender,
    public telno: string,
    public adres: string,
    public adresDtl: string,
    public homeMember: string,
    public characterJson: string,
    public incomeLevel: string,
    public incomeLevel2: string,
    public emgcNm: string,
    public emgcRel: string,
    public emgcTel: string
  ) {}
  get incomeLevelString() {
    switch (this.incomeLevel) {
      case "85이하":
        return "기준중위소득 85% 이하";
      case "100이하":
        return "기준중위소득 85% 초과 100% 이하";
      case "100초과":
        return "기준중위소득 100% 초과";
    }
  }
}
export class CareRequestList {
  constructor(
    public sdUid: number,
    public syUid: number,
    public stUid: number,
    public dbStatCd:
      | "현장방문"
      | "돌봄계획"
      | "사례회의(동)"
      | "사례회의(권역)"
      | "서비스의뢰"
      | "의뢰완료"
      | "종결",
    public preDbStatCd:
      | "현장방문"
      | "돌봄계획"
      | "사례회의(동)"
      | "사례회의(권역)"
      | "서비스의뢰"
      | "의뢰완료"
      | "종결",

    public serviceTargetYear: null,
    public serviceTarget: null,
    public spyUid: number,
    public siUid: number,
    public dptUid: number,
    public stepCd: string,
    public serviceStatCd: "진행",
    public beginDate: string,
    public endDate: string,
    public times: number,
    public cost: number,
    public serviceDetail: string,
    public chngCnt: null,
    public chngRsn: null,
    public stopYn: "N",
    public stopRsn: null,
    public providerOpinion: null,
    public serviceOrgList: ServiceOrgList[],
    public weekday: string,
    public cycleUnit: string,
    public totalCount: number,
    public cateCd: number,
    public dcaseMeetYn: null,
    public sprtMoreYn: null,
    public mbrUid: null,
    public serviceDecision: null,
    public serviceInfo: null,
    public resultEvalList: null,
    public servceBillList: null,
    public servicePlanItemList: null,
    public serviceNm : string
  ) {}
}
export class ServiceOrgList {
  constructor(
    public soUid: number,
    public siUid: number,
    public dptUid: number,
    public crtUid: number,
    public crtDt: string,
    public delUid: number,
    public delDt: string,
    public departName: string,
    public serviceInfo: string
  ) {}
}
export default CareRequest;
