import React, {
    MouseEventHandler,
    SetStateAction,
    useEffect,
    useRef,
    useState,
  } from "react";
import { PrevPlan, PrevPlanInfo, PrevPlans } from "../../../../apis/DollbomPlanAPI";
import { useDialog } from "../../../../dialogs/DialogProvider";
import DollbomPlanInfoDialog from "../../../../dialogs/service-plan/DollbomPlanInfoDialog";
import moment from "moment";
import PopupIcon from "../../../../assets/ic_popup.png";

const ServiceResult = ({
    prevPlans,
    isSprtMore,
  } : {
    prevPlans: PrevPlans
    isSprtMore: 'Y' | 'N'
  }) => {
    const [dollbomPlanInfo, setDollbomPlanInfo] = useState(false);
    const { showDialog } = useDialog();
    const [gjCharge, setGjCharge] = useState(1500000 - prevPlans.lastSum)
    const [emerCharge, setEmerCharge] = useState(600000 - prevPlans.lastEmerSum)
    const [cate1, setCate1] = useState(0)
    const [cate2, setCate2] = useState(0)
    const [cate3, setCate3] = useState(0)
    const [cate4, setCate4] = useState(0)
    const [cate5, setCate5] = useState(0)
    const [cate6, setCate6] = useState(0)
    const [cate7, setCate7] = useState(0)
  
    useEffect(() => {
      prevPlans.prevPlanBox.map((prevPlanList) => {
        prevPlanList.map((prevPlan) => {
          switch(Number(prevPlan.cateCd)) {
            case 1:
              setCate1((cur) => cur + prevPlan.cost)
              break;
            case 2:
              setCate2((cur) => cur + prevPlan.cost)
              break;
            case 3:
              setCate3((cur) => cur + prevPlan.cost)
              break;
            case 4:
              setCate4((cur) => cur + prevPlan.cost)
              break;
            case 5:
              setCate5((cur) => cur + prevPlan.cost)
              break;
            case 6:
              setCate6((cur) => cur + prevPlan.cost)
              break;
            case 7:
              setCate7((cur) => cur + prevPlan.cost)
              break;
          }
        })
      })
      if(isSprtMore === 'Y') {
        setGjCharge((cur) => cur + 1500000)
        setEmerCharge((cur) => cur + 600000)
      }
    }, [])
  
    // 돌봄계획 상세 팝업
    const handleDollbomPlanInfoDialog = (prevPlanInfo: PrevPlanInfo, prevPlanList: PrevPlan[]) => {
      showDialog(DollbomPlanInfoDialog, { prevPlanInfo, prevPlanList });
    };
  
    return (
      <div className="service_result mt20">
        <div className="padding">
          <h4>서비스 결과 및 비용 지원</h4>
          <div className="cost_cont">
            <div className="cont01">
              <h5>긴급돌봄</h5>
              <table>
                <thead>
                  <tr>
                    <th>가사지원</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="red">{prevPlans.lastEmerSum.toLocaleString("ko-KR")}</span> 원
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cont02">
              <h5>광주+돌봄</h5>
              <table>
                <thead>
                  <tr>
                    <th>가사지원</th>
                    <th>식사지원</th>
                    <th>동행지원</th>
                    <th>건강지원</th>
                    <th>안전지원</th>
                    <th>주거편의</th>
                    <th>일시보호</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="red">{cate1.toLocaleString("ko-KR")}</span> 원
                    </td>
                    <td>
                      <span className="red">{cate2.toLocaleString("ko-KR")}</span> 원
                    </td>
                    <td>
                      <span
                        className="gray"
                        style={{ fontWeight: 400, fontSize: 12 }}
                      >
                        (연간 {prevPlans.escortCount}/48회)
                      </span>
                      <br />
                      <span className="gray">{cate3.toLocaleString("ko-KR")}</span> 원
                    </td>
                    <td>
                      <span className="red">{cate4.toLocaleString("ko-KR")}</span> 원
                    </td>
                    <td>
                      <span
                        className="gray"
                        style={{ fontWeight: 400, fontSize: 12 }}
                      >
                        (연간 1,000,000원)
                      </span>
                      <br />
                      <span className="gray">{cate5.toLocaleString("ko-KR")}</span> 원
                    </td>
                    <td>
                      <ul>
                        <li>간단수리 {prevPlans.simpleFixCount}/3</li>
                        <li>대청소 {prevPlans.cleaningCount}/1</li>
                        <li>방역 방충 {prevPlans.pestControlCount}/3</li>
                      </ul>
                      <span className="gray">{cate6.toLocaleString("ko-KR")}</span> 원
                    </td>
                    <td>
                      <span className="gray">{cate7.toLocaleString("ko-KR")}</span> 원
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="plan_cont mt20">
            <h5>돌봄계획</h5>
            <ul>
              {prevPlans.prevPlanBox.map((prevPlanList, idx) => (
                <li key={idx}>
                  <a href="#" onClick={() => handleDollbomPlanInfoDialog(prevPlans.prevPlanInfos[idx], prevPlanList)}>
                    {moment(prevPlans.prevPlanInfos[idx].prevDt).format('yyyy-MM-DD(dd)')}
                    <img src={PopupIcon} alt="팝업 열기 아이콘" />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="cost_list">
          <ul>
            <li>
              <p>(광주+돌봄 한도액)</p>
              <p>
                <span>1,500,000 {isSprtMore === 'Y' && "( 추가지원 + 1,500,000 )"}</span> 원
              </p>
            </li>
            <li>-</li>
            <li>
              <p>(누적사용액)</p>
              <p>
                <span className="red">{prevPlans.lastSum.toLocaleString("ko-KR")}</span> 원
              </p>
            </li>
            <li>=</li>
            <li>
              <p>(잔액)</p>
              <p>
                <span className="blue">{gjCharge.toLocaleString("ko-KR")}</span> 원
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>(긴급돌봄 한도액)</p>
              <p>
                <span>600,000 {isSprtMore === 'Y' && "( 추가지원 + 600,000 )"}</span> 원
              </p>
            </li>
            <li>-</li>
            <li>
              <p>(누적사용액)</p>
              <p>
                <span className="red">{prevPlans.lastEmerSum.toLocaleString("ko-KR")}</span> 원
              </p>
            </li>
            <li>=</li>
            <li>
              <p>(잔액)</p>
              <p>
                <span className="blue">{emerCharge.toLocaleString("ko-KR")}</span> 원
              </p>
            </li>
          </ul>
        </div>
      </div>
    );
  };

export default ServiceResult