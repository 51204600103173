 import { Expose } from "class-transformer";
import moment, { Moment } from "moment";
import Gender from "./Gender";
import ServiceTarget from "./ServiceTarget";


// /**
//  * 작성자: 김혜정
//  * 작성일: 2023.03.21
//  *
//  * {@link SettingsPage}
//  * 테이블에 그려지는 목록에 필요한 정보를 추상화한 interface 입니다.
//  */

export type SettingListGetters =
| "fullAddress"
| "telno1"
| "telno2"
| "telno3"
| "deptCdString";

class SettingList    {
  constructor(
    
    /**  기관 key  */
    public dptUid : number,
    /**  제공기관 key  */
    public soUid : number,
    /**  서비스 key  */
    public siUid : number,
    /**  회원 key  */
    public mbrUid : number,
    /**  부서구분코드 : SI, GU, DONG, ORG  */
    public deptCd : string,
    /**  기관/부서명  */
    public departName : string,
    /**  전화번호  */
    public tel : string,
    /**  휴대전화  */
    public phone : string,
    /**  소재지  */
    public locality : string,
    /**  상태  */
    public status : SettingStatus,
    /**  대표자  */
    public owner : string,
    /**  사업자등록번호  */
    public companyNo : string,
    /**  운영형태  */
    public operType : string,
    /**    */
    public service : string,
    /** 서비스 구분   */
    public dbGubunCd : string,
     /** 서비스 대분류   */
     public serviceGbn : string,
    /** 서비스 소분류   */
    public serviceNm : string,
    /**  서비스관리 상태  */
    public useStatus : string,
    /**    */
    public org : string,
    /**    */
    public rank : string,
    /**    */
    public userName : string,
    /**    */
    public accountHolder : string,
    /**    */
    public bank : string,
    /**    */
    public accountNo : string,
    /**    */
    public crtDt : string,
    /**    */
    public crtUid : number,
    /**    */
    public updDt : string,
    /**    */
    public updUid : number,
    /**    */
    public delUid : number,
    public delDt: string,
    public serviceKeys : number[],
    /** 회원관리 상태 */
    public signupStatus : string,
    public createDt : string,
    public totalCount : number,
    public cateCd : number,
    /**추가변수 */
    public serviceDetail : string,
    public unitGbn : string,
    public unitCost : number,
    public profileImage? : File,
  ) {}

  @Expose({ toPlainOnly: true })
  get telno1(): string | undefined {
    return this.tel?.split("-")[0];
  }

  @Expose({ toPlainOnly: true })
  get telno2(): string | undefined {
    return this.tel?.split("-")[1];
  }

  @Expose({ toPlainOnly: true })
  get telno3(): string | undefined {
    return this.tel?.split("-")[2];
  }

  @Expose({ toPlainOnly: true })
  get adres(): string | undefined {
    return this.locality?.split(",")[0];
  }

  @Expose({ toPlainOnly: true })
  get adresDtl(): string | undefined {
    return this.locality?.split(",")[1];
  }


  get deptCdString() {
    switch (this.deptCd){
      case "시":
        return "SI"
      case "구":
        return "GU" 
      case "동":
        return "DONG" 
      case "기관":
        return "ORG"
      default :
        return this.deptCd
    }
  }

  get useStatusString() {
    switch (this.useStatus){
      case "Y":
        return "사용"
      case "N":
        return "미사용"
      default :
        return this.useStatus   
    }
  }


  get signupStatusString() {
    switch (this.signupStatus){
      case "P":
        return "승인대기"
      case "U":
        return "사용중" 
      case "S":
        return "중지" 
      case "D":
        return "탈퇴"
      default :
        return this.signupStatus  
    }
  }
}

 export type SettingStatus =
  | "사용"
  | "미사용";

 export const SettingStatusValues = 
  [
    "사용",
    "미사용",
  ]; 

  
  export type SettingAction = "승인";
  
  export type SignupStatus = 
  |"P"
  |"U"
  |"S"
  |"D";
  
  export type SignupProgress = "승인대기" | "승인완료";
  
  export const OrgdeptCdValues = [ 
    {label: "시", value: "SI"},
    {label: "구", value: "GU"},
    {label: "동", value: "DONG"},
    {label: "기관", value: "ORG"},
   ];

   export const OperTypeValues =
   [
    "법인","개인", "조합"
   ]

   export const SearchDbGubunCd = [
    "기존돌봄", "긴급돌봄", "광주돌봄"
   ]

   export const SearchServiceGbn = [
    "가사지원", "식사지원", "동행지원", "건강지원", "안전지원",
    "주거편의", "일시보호", "긴급돌봄"
   ]

   export const SearchDepartName = [
    "광주광역시", "동구", "남구", "서구", "북구", "광산구",
    "양동", "동천동", "무진종합 사회복지관", "쌍촌종합 사회복지관"
   ]

export default SettingList;
