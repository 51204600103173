import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import ServiceAPI from "../../../apis/ServiceAPI";
import DataTable, {
  createButtonCell,
  createRow,
  createTextCell,
} from "../../../components/common/DataTable";
import SearchBar, {
  SearchField,
  SearchFormInput,
} from "../../../components/common/SearchBar";
import { useDialog } from "../../../dialogs/DialogProvider";
import AssignServiceManagerDialog from "../../../dialogs/service/AssignServiceManagerDialog ";
import NeedsAssessmentDialog from "../../../dialogs/service/NeedsAssessmentDialog";
import ServiceList from "../../../models/ServiceList";
import "./ServicePage.scss";
import useMember from "../../../hooks/useMember";
import Member from "../../../models/Member";
import MemberAPI from "../../../apis/MemberAPI";
import moment from "moment";
import ServiceMenuList from "../../../models/ServiceMenuList";
import ServiceSpyList from "../../../components/common/ServiceSpyList";
import ServicePlanAPI from "../../../apis/ServicePlanAPI";
import NaEvaluationInfo from "../../../models/NaEvaluationInfo";
import ServiceDetailList from "../../../components/common/serviceDetailList";
/**
 * 작성자: 김혜정
 * 작성일: 2023.02.24
 *
 * 서비스 페이지
 */

export type SearchTargetParam = {
  hdong?: string
  status?: string
  dbStatCd?: string
  searchType?: string
  keyword?: string
  trgterNm?: string
  trgterCd?: string
  telno?: string
  adres?: string
  departName?: string
  serviceStatCd?: string
  searchKeyword?: string
}


const ServicePage = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const { member } = useMember();
  // const searchParams = useRef<SearchFormInput>();

  const [serviceList, setServiceList] = useState([] as ServiceList[]);
  const [serviceMenuList, setServiceMenuList] = useState([] as ServiceMenuList[]);
  const [count, setCount] = useState(0);
  const [searchParam, setSearchParam] = useState({} as SearchFormInput)
  const [departList, setDepartList] = useState([] as string[])
  const [loading, setLoading] = useState(true)

  // 서비스 목록 불러오기
  useEffect(() => {
    MemberAPI.getDeptList({deptCd: "DONG"}).then((res) => {
      let tempList:string[] = []
      res.list.map((item) => {
        tempList.push(item.departName)
      })
      setDepartList(tempList)
    })
    if (member?.depart?.deptCd === "ORG") { //기관
      ServiceAPI.getCount({
        page: Number(page),
        ...searchParam,
        dptUid: member.dptUid,
      }).then((res) => {
        // console.log("count : ", res)
        setCount(res)
      })
      ServiceAPI.getAll({
        page: Number(page),
        ...searchParam,
        dptUid: member.dptUid,
      }).then((serviceList) => {
        // console.log(serviceList);
        setServiceList([...serviceList])
        setLoading(false)
      });
    } else if (member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU") { //시 구
      ServiceAPI.getServiceMenuList({ // 새로운 api로 변경했음
        page: Number(page),
        ...searchParam,
      }).then((serviceMenuList) => {
        // console.log("serviceMenuList : ", serviceMenuList);
        if(serviceMenuList.length > 0) {
          setCount(serviceMenuList[0].totalCount)
        } else {
          setCount(0)
        }
        setServiceMenuList([...serviceMenuList])
        setLoading(false)
      });
    } else {  //동
      MemberAPI.getOneDept({
        dptUid: (member as Member).dptUid
      }).then((res) => {
        ServiceAPI.getServiceMenuList({ // 새로운 api로 변경했음
          page: Number(page),
          ...searchParam,
          hdong: res.data.departName,
        }).then((serviceMenuList) => {
          // console.log(serviceMenuList);
          setCount(serviceMenuList[0].totalCount)
          setServiceMenuList([...serviceMenuList])
          setLoading(false)
        });
      })
    }
  }, [page, searchParam]);

  const handlePageChange = (page: number) => {
    navigate(`/service/${page}`);
  };

  const handleSearch = (data: SearchFormInput) => {
    let parsed: SearchTargetParam = data
    // console.log(parsed)
    let params: SearchTargetParam = {}
    if (parsed.hdong?.length !== 0) {
      params.hdong = parsed.hdong
    }
    if (parsed.departName?.length !== 0) {
      params.departName = parsed.departName
    }
    if (parsed.serviceStatCd?.length !== 0) {
      params.serviceStatCd = parsed.serviceStatCd
    }
    if (parsed.searchType?.length !== 0 && parsed.keyword?.length !== 0) {
      params.searchType = parsed.searchType
      params.searchKeyword = parsed.keyword
    }
    setSearchParam(params)
  };

  const searchFields: SearchField[] = member?.depart?.deptCd === "ORG" ? [
    {
      title: "행정동",
      name: "hdong",
      type: "select",
      items: departList.map((label) => ({ label })),
    },
    {
      title: "검색어",
      name: "searchType",
      type: "search",
      items: [
        { label: "성명", value: "name" },
        { label: "성명+생년월일", value: "birth" },
        { label: "연락처", value: "telno" },
      ],
    },
  ] :
    member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" ?  //시 구
      [
        {
          title: "행정동",
          name: "hdong",
          type: "select",
          items: departList.map((label) => ({ label })),
        },
        // {
        //   title: "상태",
        //   name: "serviceStatCd",
        //   type: "select",
        //   items: ServiceStatusValues.map((label) => ({ label })),
        // },
        {
          title: "검색어",
          name: "searchType",
          type: "search",
          items: [
            { label: "성명", value: "name" },
            { label: "성명+생년월일", value: "birth" },
            { label: "연락처", value: "telno" },
          ],
        },
      ] : //동
      [
        {
          title: "검색어",
          name: "searchType",
          type: "search",
          items: [
            { label: "성명", value: "name" },
            { label: "성명+생년월일", value: "birth" },
            { label: "연락처", value: "telno" },
          ],
        },
      ]

  if (!member) {
    return <Navigate to="/login" />;
  }

  return (
    <main className="service_page">
        <section>
          <SearchBar fields={searchFields} onSubmit={handleSearch} />
          <TableTop count={count} />
          {loading ? null :
          <Table
            list= {member.depart?.deptCd !== "ORG" ? serviceMenuList : serviceList}
            page={Number(page)}
            totalPageCount={Math.ceil(count / 10)}
            onPageChange={handlePageChange}
            member={member as Member}
          />
          }
          
        </section>
      
    </main>
  );
};


// 테이블 상단
const TableTop = ({ count }: { count: number }) => {
  return (
    <article className="list_top">
      <div className="total">
        <p>
          총 <span>{count}</span>건
        </p>
      </div>
    </article>
  );


}


/**
 * 테이블
 * @param list 서비스 목록
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */

const Table = ({
  list,
  page,
  onPageChange,
  totalPageCount,
  member,
}: {
  list: ServiceMenuList[] | ServiceList[];
  page: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
  member: Member;
}) => {
  const { showDialog } = useDialog();
  const [stopReqSpy, setStopReqSpy] = useState([] as number[])

  const isStopReq = (spyUid: number) => {
    setStopReqSpy((cur) => [...cur, spyUid])
  }

  // useEffect(() => {
  //   console.log("Table list : ", list)
  // }, [])

  //서비스 담당자 배정
  const handleAssignServiceManager = (service: ServiceList) => {
    if(service.status === "서비스접수" || (service.status === "서비스중" && service.currentTimes === 0)) {
      showDialog(AssignServiceManagerDialog, {
        spyUid: service.spyUid,
        syUid: service.syUid,
        initialManagers: service.manager ? [service.manager] : [],
        depart: service.departName,
        dptUid: service.dptUid,
      });
    }
  };

  // 결과보고
  const navigate = useNavigate();
  const handleAction = (service: ServiceList) => {
    const params = new URLSearchParams({
      spyUid: `${service.spyUid}`,
      siUid: `${service.siUid}`,
      mbrUid: `${service.mbrUid}`,
      cateCd: `${service.cateCd}`
    })
    switch (service.status) {
      case "서비스중":
        navigate(`/service/serviceResult?${params}`);
        break;
    }
  };

    // 필요도 평가 결과 팝업
    const needsAssessment = (csUid: number, evalDate: string) => {
      ServicePlanAPI.getEvaluationData({
        csUid: csUid,
      }).then((res) => {
        if (!res) {
          console.log("필요도평가 정보 불러오기 실패");
        } else {
          // console.log("res : ", res);
          showDialog(NeedsAssessmentDialog, { naData: res as NaEvaluationInfo, evalDate: evalDate });
        }
      });
      // showDialog(NeedsAssessmentDialog);
    };

  return (
    <DataTable
      expandable
      columnTitles={
        member.depart?.deptCd !== "SI" ? member.depart?.deptCd === "ORG" ? //기관
        [
        "행정동",
        "제공기관",
        "서비스종류",
        "성명",
        "생년월일",
        "성별",
        "연락처",
        "주소",
        "서비스비용",
        "제공횟수",
        "상태",
        "담당자",
        "",
      ] :
      member.depart?.deptCd === "GU" ?  //구
      [
        "행정동",
        // "제공기관",
        // "서비스종류",
        "성명",
        "생년월일",
        "성별",
        "연락처",
        "주소",
        // "서비스비용",
        // "제공횟수",
        // "상태",
        // "담당자",
        "필요도평가일",
        "돌봄계획 확정일",
      ] : //동
      [
        // "제공기관",
        // "서비스종류",
        "성명",
        "생년월일",
        "성별",
        "연락처",
        "주소",
        // "서비스비용",
        // "제공횟수",
        // "상태",
        // "담당자",
        "필요도평가일",
        "돌봄계획 확정일",
      ] : //시
      [
        "행정동",
        // "제공기관",
        // "서비스종류",
        "성명",
        "생년월일",
        "성별",
        "연락처",
        "주소",
        // "서비스비용",
        // "제공횟수",
        // "상태",
        // "담당자",
        "필요도평가일",
        "돌봄계획 확정일",
      ]
    }
      rows={
        member.depart?.deptCd !== "SI" ? member.depart?.deptCd === "ORG" ? //기관
        (list as ServiceList[]).map((serviceManagement) =>
        createRow({
          cells: [
            createTextCell({ text: serviceManagement.hdong, isStop: stopReqSpy.find((spy) => spy === serviceManagement.spyUid) ? true : false }),
            createTextCell({ text: serviceManagement.departName }),
            createTextCell({ text: serviceManagement.serviceNm }),
            createTextCell({ text: serviceManagement.trgterNm }),
            createTextCell({ text: serviceManagement.brthdy }),
            createTextCell({ text: serviceManagement.gender }),
            createTextCell({ text: serviceManagement.telno }),
            createTextCell({
              text: serviceManagement.adres,
              ellipsis: true,
            }),
            createTextCell({ text: serviceManagement.incomeLevel }),
            createTextCell({
              text: `${serviceManagement.currentTimes} / ${serviceManagement.totalCount !== 0 ? serviceManagement.totalCount : 1}`,
            }),
            createTextCell({ text: serviceManagement.status }),
            createButtonCell({
              type:
                serviceManagement.progress === "담당자 배정 필요"
                  ? "outlined"
                  : "underlined",
              label: serviceManagement.hasManager
                ? serviceManagement.mbrName!
                : "배정",
              onClick: () => handleAssignServiceManager(serviceManagement),
            }),
            createButtonCell({
              type:
                serviceManagement.status === "서비스중"
                  ? "outlined"
                  : undefined,
              label: serviceManagement.status === "서비스중" ? "결과보고" : "",
              onClick: () => handleAction(serviceManagement),
            }),
          ],
          expandContent: (
            <ServiceDetailList
            spyUid={serviceManagement.spyUid}
            csUid={serviceManagement.csUid}
            isStopReq={isStopReq}
          />
          ),
        })
      ) :
      member.depart?.deptCd === "GU" ?  //구
      (list as ServiceMenuList[]).map((serviceMenu) =>
        createRow({
          cells: [
            createTextCell({ text: serviceMenu.hdong}),
            createTextCell({ isStop: serviceMenu.spyList.find((item) => item.status === "중단") ? true : false ,text: serviceMenu.trgterNm}),
            // createTextCell({ text: serviceMenu.trgterNm}),
            createTextCell({ text: serviceMenu.brthdy }), 
            createTextCell({ text: serviceMenu.gender }),
            createTextCell({ text: serviceMenu.telno }),
            createTextCell({
              text: serviceMenu.adres,
              ellipsis: true,
            }),
            createButtonCell({  // 필요도평가일
              type: serviceMenu.beginDt === null ? undefined : "underlined",
              label: serviceMenu.beginDt === null ? "-" : `${moment(serviceMenu.beginDt).format("yyyy-MM-DD(dd)")}`,
              onClick: () => needsAssessment(serviceMenu.spyList[0].csUid, moment(serviceMenu.beginDt).format("yyyy-MM-DD(dd)")),
            }),
            createTextCell({ text: serviceMenu.crtDt === null   // 돌봄계획확정일 
              ? "-"
              : moment(serviceMenu.crtDt).format("yyyy-MM-DD(dd)") 
            }),
          ],
          expandContent: (
            <ServiceSpyList
              spyList={serviceMenu.spyList}
              statCd={serviceMenu.statCd}
            />
          ),
        })
      ) : //동
      (list as ServiceMenuList[]).map((serviceMenu) =>
        createRow({
          cells: [
            createTextCell({ isStop: serviceMenu.spyList.find((item) => item.status === "중단") ? true : false ,text: serviceMenu.trgterNm}),
            createTextCell({ text: serviceMenu.brthdy }), 
            createTextCell({ text: serviceMenu.gender }),
            createTextCell({ text: serviceMenu.telno }),
            createTextCell({
              text: serviceMenu.adres,
              ellipsis: true,
            }),
            createButtonCell({  // 필요도평가일
              type: serviceMenu.beginDt === null ? undefined : "underlined",
              label: serviceMenu.beginDt === null ? "-" : `${moment(serviceMenu.beginDt).format("yyyy-MM-DD(dd)")}`,
              onClick: () => needsAssessment(serviceMenu.spyList[0].csUid, moment(serviceMenu.beginDt).format("yyyy-MM-DD(dd)")),
            }),
            createTextCell({ text: serviceMenu.crtDt === null   // 돌봄계획확정일 
              ? "-"
              : moment(serviceMenu.crtDt).format("yyyy-MM-DD(dd)") 
            }),     
          ],
          expandContent: (
            <ServiceSpyList
              spyList={serviceMenu.spyList}
              statCd={serviceMenu.statCd}
              />
          ),
        })
      ) : //시
      (list as ServiceMenuList[]).map((serviceManagement) =>
        createRow({
          cells: [
            createTextCell({ text: serviceManagement.hdong }),
            createTextCell({ text: serviceManagement.trgterNm, isStop: serviceManagement.spyList.find((item) => item.status === "중단") ? true : false }),
            createTextCell({ text: serviceManagement.brthdy }),
            createTextCell({ text: serviceManagement.gender }),
            createTextCell({ text: serviceManagement.telno }),
            createTextCell({
              text: serviceManagement.adres,
              ellipsis: true,
            }),
            createButtonCell({  // 필요도평가일
              type: serviceManagement.beginDt === null ? undefined : "underlined",
              label: serviceManagement.beginDt === null ? "-" : `${moment(serviceManagement.beginDt).format("yyyy-MM-DD(dd)")}`,
              onClick: () => needsAssessment(serviceManagement.spyList[0].csUid, moment(serviceManagement.beginDt).format("yyyy-MM-DD(dd)")),
            }),
            createTextCell({ text: serviceManagement.crtDt === null   // 돌봄계획확정일 
              ? "-"
              : moment(serviceManagement.crtDt).format("yyyy-MM-DD(dd)") 
            }),
          ],
          expandContent: (
          <ServiceSpyList
          spyList={serviceManagement.spyList}
          statCd={serviceManagement.statCd}
        />
          ),
        })
      )
    }
      page={page}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
    />
  );
};

export default ServicePage;
