import React, {
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import DollbomPlanAPI, { PrevPlans } from "../../../apis/DollbomPlanAPI";
import BackIcon from "../../../assets/ic_backlist.png";
import FlashMessage from "../../../components/common/FlashMessage";
import { useDialog } from "../../../dialogs/DialogProvider";
import useMember from "../../../hooks/useMember";
import DongConference from "../../../models/DongConference";
import NeedsAssessmentJoin from "../../../models/NeedsAssessmentJoin";
import ServicePlanDetails from "../../../models/ServicePlanDetails";
import ServiceTargetYear from "../../../models/ServiceTargetYear";
import DollbomConfirmationFormInput from "../../../models/form/DollbomConfirmationFormInput";
import DollbomPlanFormInput from "../../../models/form/DollbomPlanFormInput";
import "../service-plan/DollbomPlanPage.scss";
import NeedAsTabTable1 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable1";
import NeedAsTabTable2 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable2";
import NeedAsTabTable3 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable3";
import NeedAsTabTable4 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable4";
import NeedAsTabTable5 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable5";
import NeedAsTabTable6 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable6";
import NeedAsBottom from "./DollbomPlanComponents/TabTableComponents/NeedAsBottom";
import UseServiceTab from "./DollbomPlanComponents/TabTableComponents/UseServiceTab";
import ServiceResult from "./DollbomPlanComponents/ServiceResult";
import EditDollbomPlan from "./DollbomPlanComponents/EditDollbomPlan";
import InsertPlan from "./DollbomPlanComponents/InsertPlan";
import TargetInfoBar from "./DollbomPlanComponents/TargetInfoBar";
import DollbomPlanSupportAPI from "../../../apis/DollbomPlanSupportAPI";

/* 돌봄계획 - 계획수립 페이지 */
const DollbomPlanPage = () => {
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const { member } = useMember();
  const { page } = useParams();
  const [searchParams] = useSearchParams();
  // 저장된 수립한 계획목록
  const [carePlan, setCarePlan] = useState<DongConference>();
  // 동사례회의 필요 체크 여부
  const [isCaseMeetingNeeded, setCaseMeetingNeeded] = useState(true);
  // 탭 메뉴
  const [tabTable, setTabTable] = useState(0);
  // 계획 추가
  const [plans, setPlans] = useState<DollbomPlanFormInput[]>([]);
  // 대상자 정보
  const [details, setDetails] = useState<ServicePlanDetails>();
  const [detailLoad, setDetailLoad] = useState(true)
  const [targetInfo, setTargetInfo] = useState<ServiceTargetYear>();
  // 돌봄필요도평가지 데이터
  const [evaluationData, setEvaluationData] = useState<NeedsAssessmentJoin>();
  // 만 나이 계산
  const [targetBrthdy, setTargetBrthdy] = useState(0);
  const [targetMonth, setTargetMonth] = useState(0);
  const [targetDay, setTargetDay] = useState(0);
  //FlashMessage 구현(임시저장 시 알림창)
  const [showMessage, setShowMessage] = useState(false);
  // 잔액
  const [totalCost, setTotalCost] = useState(0);
  // 질병 및 증상 추가 State
  const [illAddList, setIllAddList] = useState<string[]>([]);
  //이전 돌봄계획 데이터
  const [prevPlans, setPrevPlans] = useState<PrevPlans>({} as PrevPlans);
  const [prevPlansLoading, setPrevPlansLoading] = useState(false)

  const sdUid = searchParams.get("sdUid");
  const csUid = searchParams.get("csUid");
  const syUid = searchParams.get("syUid");
  const stUid = searchParams.get("stUid");

  //안전지원 비용, 주거편의 횟수
  const [safeSprtCharge, setSafeSprtCharge] = useState(0)
  const [simpleFixCount, setSimpleFixCount] = useState(0)
  const [cleaningCount, setCleaningCount] = useState(0)
  const [pestControlCount, setPestControlCount] = useState(0)
  const [escortCount, setEscortCount] = useState(0)

  //과거 추가지원 내역
  const [pastSprtHistory, setPastSprtHistory] = useState<"Y" |  "N">('N')

  // 대상자 정보 데이터 불러오는 API
  useEffect(() => {
    DollbomPlanAPI.getDetails({
      sdUid: Number(sdUid),
      csUid: Number(csUid),
    }).then((res) => {
      setDetails(res);
      // console.log("details(이용중이 서비스, 대상자 욕구 등) : ", res);
      setTargetInfo(details?.targetInfo);
      // 만 나이 계산
      setTargetBrthdy(Number(res.targetInfo.brthdy.split("-")[0]));
      setTargetMonth(Number(res.targetInfo.brthdy.split("-")[1]));
      setTargetDay(Number(res.targetInfo.brthdy.split("-")[2]));
      setDetailLoad(false)
    });
  }, [page]);

  // 서비스 목록 및 저장한 서비스 목록 불러오는 API
  useEffect(() => {
    DollbomPlanAPI.getDongConferencePlan({
      syUid: Number(syUid),
    }).then((res) => {
      setCarePlan(res);
      // console.log("carePlan >>>>>>>>>", res);
    });
  }, []);
  // 돌봄필요도 평가지 데이터 불러오는 API
  useEffect(() => {
    DollbomPlanAPI.getEvaluationData({
      sdUid: Number(sdUid),
      csUid: Number(csUid),
    }).then((res) => {
      setEvaluationData(res);
      setIllAddList(JSON.parse(res.jsonData.illAdd));
      // console.log(res.jsonData.illAdd);
      // console.log(res);
    });

    DollbomPlanAPI.prevPlans({
      stUid: Number(stUid),
      syUid: Number(syUid),
    }).then((res) => {
      // console.log("이전 돌봄계획 : ", res)
      setSafeSprtCharge(res.safeSprtCharge)
      setSimpleFixCount(res.simpleFixCount)
      setCleaningCount(res.cleaningCount)
      setPestControlCount(res.pestControlCount)
      setEscortCount(res.escortCount)
      setPrevPlans(res)
      setPrevPlansLoading(true)
    })

    //이전 추가지원 여부
    DollbomPlanSupportAPI.pastSprtHistory({
      syUid: Number(syUid),
      stUid: Number(stUid),
    }).then((res) => {
      // console.log("이전 추가지원 여부 : ", res)
      if (!res.success) {
        setPastSprtHistory('Y')
      }
    })
  }, []);

  // 목록으로 돌아가기 이벤트
  const handleBackPressed = () => {
    navigate(-1);
  };

  // 추가버튼 클릭시 테이블에 계획추가 되는 이벤트
  const handleAddPlan = (plan: DollbomPlanFormInput) => {
    let planTemp = plan
    planTemp.times = Number(plan.times)
    planTemp.totalCount = Number(plan.totalCount)
    setPlans([planTemp, ...plans]);
    // console.log("추가되는 계획 : ", planTemp)
    // console.log("all added plans >>>", [planTemp, ...plans]);
  };

  // 새로 추가한 수립계획 수정 이벤트
  const handleModifyPlan = (item: DollbomPlanFormInput) => {
    const testPlan = plans.filter((e) => e == item);
    setPlans(plans.filter((e) => e != item));
  };

  // 새로 추가한 수립계획 삭제 이벤트
  const handleDeletePlan = (item: DollbomPlanFormInput) => {
    if (window.confirm("서비스를 삭제하시겠습니까?")) {
      setPlans(plans.filter((e) => e != item));
    }
  };

  // 저장버튼 눌렀을때 이벤트
  // 저장버튼 눌렀을때 테이블 서비스목록 저장
  const handleSubmit = async () => {
    if (window.confirm("계획을 저장 하시겠습니까?")) {
      // console.log(">>> plans", plans);
      let data: DollbomConfirmationFormInput[] = [];
      // 입력한 기존돌봄
      const basicList = document.querySelector<HTMLInputElement>(".basicList");
      const basicDetail =
        document.querySelector<HTMLInputElement>("#basicDetail");
      // 입력한 긴급돌봄
      const emerDay = document.querySelector<HTMLInputElement>("#emerDay");
      const emerBeginDt =
        document.querySelector<HTMLInputElement>(".emerBeginDt");
      const emerEndDt = document.querySelector<HTMLInputElement>(".emerEndDt");
      const emerTime = document.querySelector<HTMLInputElement>("#emerTime");
      const emerCost = document.querySelector<HTMLInputElement>("#emerCost");
      const rows =
        document.querySelectorAll<HTMLTableRowElement>(".addTable tr");
      data = InsertPlan(
        basicList,
        basicDetail,
        emerDay,
        emerBeginDt,
        emerEndDt,
        emerTime,
        emerCost,
        rows,
        carePlan!.emerList,
        carePlan!.basicList,
        carePlan!.gjList
      ) as DollbomConfirmationFormInput[]

      // console.log(">>>>> newPlan >>>>", data);
      if (data.length == 0) {
        alert("수립된 계획이 없습니다.");
        return
      }

      /**
       * 동 사례회의 미체크 -> 계획확정
       */

      const success = await DollbomPlanAPI.insert({
        syUid: Number(syUid),
        nextYn: isCaseMeetingNeeded ? "Y" : "N",
        stepCd: "돌봄계획",
        // 동 사례회의 체크x 서비스의뢰 로 넘어가야됨, 2023.04.17 현재 API 수정안됨
        // API 수정되면 "돌봄계획확정" -> "서비스의뢰" 로 수정
        stepCdNext: isCaseMeetingNeeded ? "사례회의(동)" : "돌봄계획확정",
        servicePlanYears: data,
      });

      /**
       * 동 사례회의 미체크 -> 의뢰
       */

      // let success = false
      // if(isCaseMeetingNeeded) { //사례회의
      //   success = await DollbomPlanAPI.insert({
      //     syUid: Number(syUid),
      //     nextYn: isCaseMeetingNeeded ? "Y" : "N",
      //     stepCd: "돌봄계획",
      //     // 동 사례회의 체크x 서비스의뢰 로 넘어가야됨, 2023.04.17 현재 API 수정안됨
      //     // API 수정되면 "돌봄계획확정" -> "서비스의뢰" 로 수정
      //     stepCdNext: "사례회의(동)",
      //     servicePlanYears: data,
      //   });
      // } else {  //의뢰
      //   success = await DollbomPlanAPI.insertConfirmation({
      //     syUid: Number(syUid),
      //     nextYn: "Y",
      //     stepCd: "돌봄계획확정",
      //     stepCdNext: "서비스의뢰",
      //     servicePlanYears: data,
      //   });
      // }
      
      if (success) {
        alert("계획이 수립 되었습니다.")
        navigate("/service-plan", { replace: true });
      } else {
        // console.log(">>> failure");
        alert("계획 수립 실패")
        return
      }
    }
  };
  // 저장버튼 눌렀을때 이벤트 end

  // 임시저장 버튼 이벤트
  const handleTemporary = async () => {
    if (window.confirm("계획을 임시저장 하시겠습니까?")) {
      // console.log(">>> plans", plans);
      let data: DollbomConfirmationFormInput[] = [];
      // 입력한 기존돌봄
      const basicList = document.querySelector<HTMLInputElement>(".basicList");
      const basicDetail =
        document.querySelector<HTMLInputElement>("#basicDetail");
      // 입력한 긴급돌봄
      const emerDay = document.querySelector<HTMLInputElement>("#emerDay");
      const emerBeginDt =
        document.querySelector<HTMLInputElement>(".emerBeginDt");
      const emerEndDt = document.querySelector<HTMLInputElement>(".emerEndDt");
      const emerTime = document.querySelector<HTMLInputElement>("#emerTime");
      const emerCost = document.querySelector<HTMLInputElement>("#emerCost");
      const rows =
        document.querySelectorAll<HTMLTableRowElement>(".addTable tr");
      data = InsertPlan(
        basicList,
        basicDetail,
        emerDay,
        emerBeginDt,
        emerEndDt,
        emerTime,
        emerCost,
        rows,
        carePlan!.emerList,
        carePlan!.basicList,
        carePlan!.gjList
      ) as DollbomConfirmationFormInput[]
      // console.log(">>>>> test Save newPlan >>>>", data);
      if (data.length == 0) {
        alert("수립된 계획이 없습니다.");
      }
      const success = await DollbomPlanAPI.insert({
        syUid: Number(syUid),
        nextYn: "N",
        stepCd: "돌봄계획",
        stepCdNext: "돌봄계획",
        servicePlanYears: data,
      });
      if (success) {
        setShowMessage(true);
        setTimeout(() => {

          navigate("/service-plan", { replace: true });
        }, 1000);
      } else {
        console.log(">>> failure");
      }
    }
  };
  // 임시저장 버튼 이벤트 end

  // 임시저장 알림창
  const handleDismiss = () => {
    setShowMessage(false);
  };
  return (
    <main className="dollbomPlanPage">
      <section>
        <article className="detail_top">
          <div className="nav_list">
            {/* <p>{targetNm}</p>
                <p>{brthdy}</p>
                <p>{gender}</p> */}
            <p>
              돌봄계획 / <span>계획수립</span>
            </p>
            <button
              type="button"
              className="mt8"
              onClick={handleBackPressed}
            >
              <span>
                <img src={BackIcon} alt="목록으로 돌아가기 아이콘" />
              </span>
              목록으로 돌아가기
            </button>
          </div>
          <div className="btn_wrap">
            <FlashMessage
              className="flash"
              message="임시저장 되었습니다."
              isVisible={showMessage}
              onDismiss={handleDismiss}
            />

            <label htmlFor="checkbox_111" className="mr20">
              <input
                type="checkbox"
                id="checkbox_111"
                checked={isCaseMeetingNeeded}
                onChange={(e) => setCaseMeetingNeeded(e.target.checked)}
              />
              동 사례회의 필요
            </label>
            <button
              type="button"
              style={{
                border: "1px solid",
                backgroundColor: "#5b73d8",
                color: "white",
                marginRight: "10px",
              }}
              onClick={handleTemporary}
            >
              임시저장
            </button>
            <button
              type="button"
              className="submit"
              onClick={handleSubmit}
            >
              저장
            </button>
          </div>
        </article>
        {!detailLoad && 
        <TargetInfoBar details={details!} targetMonth={targetMonth} targetBrthdy={targetBrthdy} targetDay={targetDay} />
        }        
        <article className="care_plan mt20">
          <div className="left mr20">
            <UseServiceTab details={details!}/>
            <div className="care_evaluation mt20">
              {/* 탭 */}
              <div className="tabs">
                <button
                  type="button"
                  className={`tab ${tabTable === 0 ? "tab_active" : "non_active"} tab_count6`}
                  style={{marginLeft: "1.5px", marginRight: "3px"}}
                  onClick={() => setTabTable(0)}
                  data-id={0}
                >
                  신체적 영역
                </button>
                <button
                  type="button"
                  className={`tab ${tabTable === 1 ? "tab_active" : "non_active"} tab_count6`}
                  style={{marginRight: "3px"}}
                  onClick={() => setTabTable(1)}
                  data-id={1}
                >
                  정서적 영역
                </button>
                <button
                  type="button"
                  className={`tab ${tabTable === 2 ? "tab_active" : "non_active"} tab_count6`}
                  style={{marginRight: "3px"}}
                  onClick={() => setTabTable(2)}
                  data-id={2}
                >
                  추가정보(생활환경)
                </button>
                <button
                  type="button"
                  className={`tab ${tabTable === 3 ? "tab_active" : "non_active"} tab_count6`}
                  style={{marginRight: "3px"}}
                  onClick={() => setTabTable(3)}
                  data-id={3}
                >
                  추가정보(사전검사)
                </button>
                <button
                  type="button"
                  className={`tab ${tabTable === 4 ? "tab_active" : "non_active"} tab_count6`}
                  style={{marginRight: "3px"}}
                  onClick={() => setTabTable(4)}
                  data-id={4}
                >
                  광주+돌봄 지원대상 여부
                </button>
                <button
                  type="button"
                  className={`tab ${tabTable === 5 ? "tab_active" : "non_active"} tab_count6`}
                  onClick={() => setTabTable(5)}
                  data-id={5}
                >
                  긴급돌봄 지원대상 여부
                </button>
              </div>
              {/* 탭 뷰 */}
              {tabTable === 0 && <NeedAsTabTable1 evaluationData={evaluationData} illAddList={illAddList} />}
              {tabTable === 1 && <NeedAsTabTable2 evaluationData={evaluationData} />}
              {tabTable === 2 && <NeedAsTabTable3 evaluationData={evaluationData} />}
              {tabTable === 3 && <NeedAsTabTable4 evaluationData={evaluationData} />}
              {tabTable === 4 && <NeedAsTabTable5 evaluationData={evaluationData} />}
              {tabTable === 5 && <NeedAsTabTable6 evaluationData={evaluationData} />}
              {/* 탭 뷰 종료 */}
              {/* 신체적 영역 */}

              {/* 정서적 영역 */}

              {/* 추가정보(생활환경) */}

              {/* 광주+돌봄 지원대상 여부 */}

              {/* 긴급돌봄 지원대상 여부 */}
            </div>
            <NeedAsBottom evaluationData={evaluationData!}/>
          </div>
          {prevPlansLoading && 
          <div className="right">
            <EditDollbomPlan
              services={details?.gjList ?? []}
              basicServices={details?.basicList ?? []}
              emerServices={details?.emerList ?? []}
              plans={plans}
              onPlanAdded={handleAddPlan}
              onPlanModify={handleModifyPlan}
              onPlanDeleted={handleDeletePlan}
              lastSum={prevPlans.lastSum ?? undefined}
              lastEmerSum={prevPlans.lastEmerSum ?? undefined}
              safeSprtCharge={safeSprtCharge}
              simpleFixCount={simpleFixCount}
              cleaningCount={cleaningCount}
              pestControlCount={pestControlCount}
              escortCount={escortCount}
              isSprtMore={pastSprtHistory}
            />
            {prevPlans.prevPlanBox && <ServiceResult prevPlans={prevPlans} isSprtMore={pastSprtHistory} />}
          </div>
          }
          
        </article>
      </section>
    </main>
  );
};

export default DollbomPlanPage;
