import Gender from "./Gender";

class MoveOutTarget {
    constructor(
        public mtUid: number,
        public trgterNm: string,
        public trgterCd: string,
        public brthdy: string,
        public gender: Gender,
        public outGuUid: number,
        public inGuUid: number,
        public hdong: string,
        public crtDt: string,
        public allData: string,
        public moveOutJson: string,
        public serviceTargetJson: string,
        public serviceTargetYearJson: string,
        public counselScheduleJson: string,
        public counselResultJson: string,
        public caseMeetingJson: string,
        public serviceDecisionJson: string,
        public servicePlanYearJson: string,
        public servicePlanItemJson: string,
        public serviceBillJson: string,
        public resultEvalJson: string,
        public sprtMoreJson: string,
        public totalCount: number,
        public completeYn: 'Y' | 'N',
        public updDt: string,
    ) {}
}

export default MoveOutTarget