import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import DollbomPlanSupportAPI from "../../../apis/DollbomPlanSupportAPI";
import MemberAPI, { DepartVO } from "../../../apis/MemberAPI";

import ServiceTargetAPI from "../../../apis/ServiceTargetAPI";
import PlusIcon from "../../../assets/ic_plus.png";
import DataTable, {
  createButtonCell,
  createRow,
  createTextCell,
} from "../../../components/common/DataTable";
import SearchBar, {
  SearchField,
  SearchFormInput,
} from "../../../components/common/SearchBar";
import { useDialog } from "../../../dialogs/DialogProvider";
import DollbomSupportDialog from "../../../dialogs/service-plan/DollbomSupportDialog";
import AssignManagerDialog from "../../../dialogs/service-target/AssignManagerDialog";
import EditScheduleDialog from "../../../dialogs/service-target/EditScheduleDialog";
import EditServiceTargetDialog from "../../../dialogs/service-target/EditServiceTargetDialog";
import ObiligationDialog from "../../../dialogs/service-target/ObiligationDialog";
import MoveOutDialog from "../../../dialogs/sprt-more/MoveOutDialog";
import useMember from "../../../hooks/useMember";
import MoveOutList from "../../../models/MoveOutList";
import { ServiceTargetRegistrationTypeValues } from "../../../models/ServiceTarget";
import ServiceTargetYear, {
  ServiceTargetYearHdong,
  ServiceTargetYearStatusValues,
} from "../../../models/ServiceTargetYear";
import SprtList from "../../../models/SprtList";
import MoveOutAPI from "../../../apis/MoveOutAPI";
import MoveOutTarget from "../../../models/MoveOutTarget";
import MoveOutListForm from "../../../models/MoveOutListForm";

export type SearchTargetParam = {
  sbscWay?: string;
  hdong?: string;
  inGuUid?: string;
  statCd?: string;
  dbStatCd?: string;
  hing?: string;
  keyword?: string;
  trgterNm?: string;
  trgterCd?: string;
  telno?: string;
  adres?: string;
  searchType?: string,
  searchKeyword?: string,
};

/**
 * 작성자: 김덕현
 * 작성일: 2023.04.03
 *
 * 추가지원검토 페이지
 */
const ServiceTargetPage = () => {
  const [tabs, setTabs] = useState(2);
  const { member } = useMember();
  const { page } = useParams();
  const navigate = useNavigate();
  const [serviceTargets, setServiceTargets] = useState(
    [] as ServiceTargetYear[]
  );
  const [sprtList, setSprtList] = useState<SprtList[]>([]);
  const [moveOutList, setMoveOutList] = useState<MoveOutList[]>([]);
  const [count, setCount] = useState(0);
  const [moveOutListcount, setMoveOutListCount] = useState(0);
  const [moveOutTargetListcount, setMoveOutTargetListCount] = useState(0);
  const [searchParam, setSearchParam] = useState({} as SearchFormInput);
  const [departList, setDepartList] = useState([] as string[])
  const [moveOutTargetList, setMoveOutTargetList] = useState([] as MoveOutTarget[])
  const [moveOutListFormCount, setMoveOutListFormCount] = useState(0);
  const [moveOutListForm, setMoveOutListForm] = useState([] as MoveOutListForm[])

  // 추가지원검토 목록
  useEffect(() => {
    MemberAPI.getDeptList({ deptCd: "DONG" }).then((res) => {
      let tempList: string[] = []
      res.list.map((item) => {
        tempList.push(item.departName)
      })
      setDepartList(tempList)
    })
    DollbomPlanSupportAPI.getSprtList({
      page: Number(page),
      ...searchParam,
    }).then((res) => {
      setSprtList(res);
      if (res.length > 0) {
        setCount(res[0].totalCount);
      } else {
        setCount(0);
      }
      // console.log(res);
    })
  }, [page, searchParam])

  // 전출 목록
  useEffect(() => {
    DollbomPlanSupportAPI.getMoveOutList({
      page: Number(page),
      ...searchParam,
    }).then((res) => {
      setMoveOutList(res);
      if (res.length > 0) {
        setMoveOutListCount(res[0].totalCount);
      } else {
        setMoveOutListCount(0);
      }
            // console.log("moveOutList >>", res);
    })
  }, [page, searchParam])

  useEffect(() => {
    MoveOutAPI.getCurrentGu({ deptCd: "GU" }).then((res) => {
      //전입, 전입현황 목록
      MoveOutAPI.getMoveOutTargetList({
        page: Number(page),
        inGuUid: res.dptUid,
        ...searchParam,
      }).then((res) => {
        setMoveOutTargetList(res)
        if (res.length > 0) {
          setMoveOutTargetListCount(res[0].totalCount);   
        } else {
          setMoveOutTargetListCount(0);   
        }   
      })
      //전출현황 목록
      MoveOutAPI.moveOutList({
         page: Number(page),
         outGuUid: res.dptUid,
        ...searchParam,
      }).then((res) => {
        setMoveOutListForm(res);
        if (res.length > 0) {
          setMoveOutListFormCount(res[0].totalCount);
        } else {
          setMoveOutListFormCount(0);
        }   
        // console.log(res)
      })
    })
  }, [page, searchParam])

  const handleSearch = (data: SearchFormInput) => {
    let parsed: SearchTargetParam = data;
    // console.log(parsed);
    let params: SearchTargetParam = {};
    if (parsed.sbscWay?.length !== 0) {
      params.sbscWay = parsed.sbscWay;
    }
    if (parsed.hdong?.length !== 0) {
      params.hdong = parsed.hdong;
    }
    if (parsed.inGuUid?.length !== 0) {
      params.inGuUid = parsed.inGuUid;
    }
    if (parsed.statCd?.length !== 0) {
      params.statCd = parsed.statCd;
    }
    if (parsed.searchType?.length !== 0 && parsed.keyword?.length !== 0) {
      params.searchType = parsed.searchType
      params.searchKeyword = parsed.keyword
    }
    setSearchParam(params);
  };

  const handlePageChange = (page: number) => {
    navigate(`/service-target/${page}`);
  };

  if (!member) {
    return <Navigate to="/login" />;
  }

  /**
 * 추가지원검토 검색 필드
 */
  const searchFields: SearchField[] = [
    {
      title: "행정동",
      name: "hdong",
      type: "select",
      items: departList.map((label) => ({ label })),
    },
    // {
    //   title: "상태",
    //   name: "statCd",
    //   type: "select",
    //   items: ServiceTargetYearStatusValues.map((label) => ({ label })),
    // },
    {
      title: "검색어",
      name: "searchType",
      type: "search",
      items: [
        { label: "성명", value: "name" },
        { label: "성명+생년월일", value: "brthdy" },
      ],
    },
  ];

  /**전출 현황 검색바 */
  const searchFields3: SearchField[] = [
    {
      title: "전입구",
      name: "inGuUid",
      type: "select",
      items: [
        { label: "동구", value: "2" },
        { label: "서구", value: "4" },
        { label: "남구", value: "3" },
        { label: "북구", value: "5" },
        { label: "광산구", value: "6" },
      ],
    },
    {
      title: "전입동",
      name: "hdong",
      type: "select",
      items: departList.map((label) => ({ label })),
    },
    // {
    //   title: "상태",
    //   name: "statCd",
    //   type: "select",
    //   items: ServiceTargetYearStatusValues.map((label) => ({ label })),
    // },
    {
      title: "검색어",
      name: "searchType",
      type: "search",
      items: [
        { label: "성명", value: "name" },
        { label: "성명+생년월일", value: "brthdy" },
      ],
    },
  ];

  return (
    <main className="sprt-more">
      <section>
        <div className="headerTabs">
          <button onClick={() => setTabs(0)} className={tabs == 0 ? "tab_active" : "non_active"}>전출</button>
          <button onClick={() => setTabs(3)} className={tabs == 3 ? "tab_active" : "non_active"}>전출 현황</button>
          <button onClick={() => setTabs(1)} className={tabs == 1 ? "tab_active" : "non_active"}>전입·전입현황</button>
          <button onClick={() => setTabs(2)} className={tabs == 2 ? "tab_active" : "non_active"}>추가지원검토</button>
        </div>
        {/* 전출 탭 */}
        {tabs == 0 &&
          <>
            <SearchBar fields={searchFields2} onSubmit={handleSearch} />
            <TableTop2 count={moveOutListcount} />
            <Table2
              list={serviceTargets}
              page={Number(page)}
              moveOutList={moveOutList}
              totalPageCount={Math.ceil(moveOutListcount / 10)}
              onPageChange={handlePageChange}
            />
          </>}
        {/* 전입·전입현황 탭 */}
        {tabs == 1 &&
          <>
            <SearchBar fields={searchFields} onSubmit={handleSearch} />
            <TableTop3 count={moveOutTargetListcount} />
            <Table3
              page={Number(page)}
              moveOutTargetList={moveOutTargetList}
              totalPageCount={Math.ceil(moveOutTargetListcount / 10)}
              onPageChange={handlePageChange}
            />
          </>}
        {/* 추가지원검토 탭 */}
        {tabs == 2 &&
          <>
            <SearchBar fields={searchFields2} onSubmit={handleSearch} />
            <TableTop count={count} />
            <Table
              list={serviceTargets}
              page={Number(page)}
              sprtList={sprtList}
              totalPageCount={Math.ceil(count / 10)}
              onPageChange={handlePageChange}
            />
          </>}
        {/* 전출 현황 탭 */}
        {tabs == 3 &&
          <>
            <SearchBar fields={searchFields3} onSubmit={handleSearch} />
            <TableTop4 count={moveOutListFormCount} />
            <Table4
              page={Number(page)}
              moveOutListForm={moveOutListForm}
              totalPageCount={Math.ceil(count / 10)}
              onPageChange={handlePageChange}
            />
          </>}
      </section>
    </main>
  );
};

/**
 * 전입·전출 검색 필드
 */
const searchFields2: SearchField[] = [
  {
    title: "행정동",
    name: "hdong",
    type: "select",
    items: ServiceTargetYearHdong.map((label) => ({ label })),
  },
  // {
  //   title: "사유",
  //   name: "statCd",
  //   type: "select",
  //   items: ServiceTargetYearStatusValues.map((label) => ({ label })),
  // },
  {
    title: "검색어",
    name: "searchType",
    type: "search",
    items: [
      { label: "성명", value: "name" },
      { label: "성명+생년월일", value: "brthdy" },
      { label: "연락처", value: "telno" },
      { label: "주소", value: "adres" },
    ],
  },
];
// 추가지원 테스트버튼 (사용안함)
const SprtMoreButton = () => {
  const navigate = useNavigate();

  const handleAction = (sprtPageAction: string) => {
    switch (sprtPageAction) {
      case "추가지원검토(승인)":
        navigate(`/sprt-more/sprtMoreAdmissionPage`);
        break;
      case "추가지원검토(의견작성)":
        navigate(`/sprt-more/sprtMoreOpinion`);
        break;
    }
  };
  return (
    <>
      <div
        className="btn_wrap"
        style={{ marginLeft: "50px", marginRight: "40px" }}
      >
        {/* <button
          type="button"
          className="submit"
          onClick={() => handleAction("추가지원검토(승인)")}
        >
          추가지원검토
          <br />
          (승인)
        </button> */}
        {/* <button
          type="button"
          className="submit"
          style={{ marginLeft: "10px" }}
          onClick={() => handleAction("추가지원검토(의견작성)")}
        >
          추가지원검토
          <br />
          (의견작성)
        </button> */}
      </div>
    </>
  );
};
/**
 * 테이블 상단의 버튼
 */
const TableTop = ({ count }: { count?: number }) => {
  const { showDialog } = useDialog();

  const handleDollbomSupport = () => {
    showDialog(DollbomSupportDialog);
  };

  return (
    <article className="list_top">
      <div className="total">
        <p>
          총 <span>{count}</span>건
        </p>
      </div>
      {/* <SprtMoreButton /> (사용안함) */}
      <div className="list_btn_wrap">
        <button type="button" className="recive" onClick={handleDollbomSupport}>
          <img src={PlusIcon} alt="접수 아이콘" />
          추가지원 신청
        </button>
      </div>
    </article>
  );
};
// 전출 상단
const TableTop2 = ({ count }: { count?: number }) => {
  const { showDialog } = useDialog();

  const handleDollbomSupport = () => {
    showDialog(DollbomSupportDialog);
  };

  return (
    <article className="list_top">
      <div className="total">
        <p>
          총 <span>{count}</span>건
        </p>
      </div>
      {/* <SprtMoreButton /> (사용안함) */}

    </article>
  );
};
// 전입 상단
const TableTop3 = ({ count }: { count?: number }) => {
  const { showDialog } = useDialog();

  return (
    <article className="list_top">
      <div className="total">
        <p>
          총 <span>{count}</span>건
        </p>
      </div>
    </article>
  );
};

// 전출입 현황 상단
const TableTop4 = ({ count }: { count?: number }) => {
  const { showDialog } = useDialog();

  return (
    <article className="list_top">
      <div className="total">
        <p>
          총 <span>{count}</span>건
        </p>
      </div>
    </article>
  );
};
/**
 * 테이블
 * @param list 사례관리 목록
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
const Table = ({
  list,
  sprtList,
  page,
  totalPageCount,
  onPageChange,
}: {
  list: ServiceTargetYear[];
  sprtList: SprtList[];
  page: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
}) => {
  const { showDialog } = useDialog();

  const navigate = useNavigate();

  const handleAction = (sprtList: SprtList) => {
    const params = new URLSearchParams({
      //   sdUid: `${sprtPageAction.}`,
      csUid: `${sprtList.csUid}`,
      syUid: `${sprtList.syUid}`,
      stUid: `${sprtList.stUid}`,
      //   trgterCd: `${sprtPageAction.trgterCd}`,
    });
    if(!sprtList.cfmYn) {
      if(sprtList.mtOpnn) {
        navigate(`/sprt-more/sprtMoreAdmissionPage?${params}`); // sprtMoreAdmissionPage 승인
      } else {
        navigate(`/sprt-more/sprtMoreOpinion?${params}`); // sprtMoreOpinion 의견작성
      }
    } else {
      return
    }
    
    // switch (sprtList.statCd) {
    //   case "사례종결":
    //     navigate(`/sprt-more/sprtMoreAdmissionPage?${params}`); // sprtMoreAdmissionPage 승인
    //     break;
    //   case "서비스의뢰":
    //     // navigate(`/sprt-more/sprtMoreOpinion?${params}`);
    //     navigate(`/sprt-more/sprtMoreAdmissionPage?${params}`); // sprtMoreAdmissionPage 승인
    //     break;
    //   case "현장방문":
    //     navigate(`/sprt-more/sprtMoreOpinion?${params}`); // sprtMoreOpinion 의견작성
    //     break;
    // }
  };
  // TODO: 구에서만 행정동이 보이게 구현
  return (
    <DataTable
      columnTitles={[
        "행정동",
        "성명",
        "생년월일",
        "성별",
        "연락처",
        "주소",
        "상태",
        "신청일",
        "",
      ]}
      rows={sprtList.map((sprtMore) =>
        createRow({
          cells: [
            createTextCell({ text: sprtMore.hdong }),
            createTextCell({ text: sprtMore.trgterNm }),
            createTextCell({ text: sprtMore.brthdy }),
            createTextCell({ text: sprtMore.gender }),
            createTextCell({ text: sprtMore.telno }),
            createTextCell({
              text: sprtMore.fullAddress,
              ellipsis: sprtMore.fullAddress.length > 30 ? true : false,
            }),
            createTextCell({
              text: sprtMore.statCd,
            }),
            createTextCell({
              text: sprtMore.crtDt ? sprtMore.crtDt : "-",
            }),
            createButtonCell({
              type: sprtMore.cfmYn ?
              "underlined" : "outlined",
              label:
              sprtMore.cfmYn ? sprtMore.cfmYn === 'Y' ? "지원승인" : "지원불가" :
                sprtMore.mtOpnn ? "승인" : "의견작성",
              onClick: () => handleAction(sprtMore),
            }),
          ],
        })
      )}
      page={page}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
    />
  );
};
// 전출 테이블 목록
const Table2 = ({
  list,
  moveOutList,
  page,
  totalPageCount,
  onPageChange,
}: {
  list: ServiceTargetYear[];
  moveOutList: MoveOutList[];
  page: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
}) => {
  const { showDialog } = useDialog();

  const navigate = useNavigate();

  const handleAction = (moveOutList: MoveOutList) => {
    if(moveOutList.clclnSt === "N") {
      showDialog(MoveOutDialog, {
        moveOutList: moveOutList
      })
    }
    
  };
  // TODO: 구에서만 행정동이 보이게 구현
  return (
    <DataTable
      columnTitles={[
        "행정동",
        "성명",
        "생년월일",
        "성별",
        "연락처",
        "주소",
        "사유",
        "",
      ]}
      rows={moveOutList.map((moveOutList) =>
        createRow({
          cells: [
            createTextCell({ text: moveOutList.hdong }),
            createTextCell({ text: moveOutList.trgterNm }),
            createTextCell({ text: moveOutList.brthdy }),
            createTextCell({ text: moveOutList.gender }),
            createTextCell({ text: moveOutList.telno }),
            createTextCell({
              text: moveOutList.fullAddress,
              ellipsis: moveOutList.fullAddress.length > 30 ? true : false,
            }),
            createTextCell({
              text: moveOutList.stopGbn,
            }),
            createButtonCell({
              type: moveOutList.clclnSt === "N" ?
                "outlined"
                : "underlined",
              label: moveOutList.clclnSt === "N" ?
                "전출"
                : "중단진행중",
              onClick: () => handleAction(moveOutList),
            }),
          ],
        })
      )}
      page={page}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
    />
  );
};
// 전입 테이블 목록
const Table3 = ({
  moveOutTargetList,
  page,
  totalPageCount,
  onPageChange,
}: {
  moveOutTargetList: MoveOutTarget[];
  page: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
}) => {
  const { showDialog } = useDialog();

  const navigate = useNavigate();

  const handleAction = async (moveOutTarget: MoveOutTarget) => {
    if (moveOutTarget.completeYn === 'N') {
      if (window.confirm("전입처리 하시겠습니까?")) {
        let suc = await MoveOutAPI.moveIn({
          ...moveOutTarget,
          crtDt: moment(moveOutTarget.crtDt).format("yyyy-MM-DD HH:mm:ss"),
        })
        if (suc) {
          window.alert("전입처리 완료")
          navigate(0);
        }
      } else {
        return
      }
    }
  };
  // TODO: 구에서만 행정동이 보이게 구현
  return (
    <DataTable
      columnTitles={[
        "행정동",
        "성명",
        "생년월일",
        "성별",
        "전출일자",
        "전입일자",
        "",
      ]}
      rows={moveOutTargetList.map((moveOutTarget) =>
        createRow({
          cells: [
            createTextCell({ text: moveOutTarget.hdong }),
            createTextCell({ text: moveOutTarget.trgterNm }),
            createTextCell({ text: moveOutTarget.brthdy }),
            createTextCell({ text: moveOutTarget.gender }),
            createTextCell({
              text: moveOutTarget.crtDt ? moment(moveOutTarget.crtDt).subtract(1, "months").format("yyyy-MM-DD") : "-",
              // text: "-"
            }),
            createTextCell({
              text: moveOutTarget.updDt ? moment(moveOutTarget.updDt).subtract(1, "months").format("yyyy-MM-DD") : "-",
              // text: "-"
            }),
            createButtonCell({
              type: moveOutTarget.completeYn === "N" ?
                "outlined"
                : "underlined",
              label: moveOutTarget.completeYn === "N" ?
                "전입"
                : "전입완료",
              onClick: () => handleAction(moveOutTarget),
            }),
          ],
        })
      )}
      page={page}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
    />
  );
};
// 전출입 현황 테이블 목록
const Table4 = ({
  moveOutListForm,
  page,
  totalPageCount,
  onPageChange,
}: {
  moveOutListForm: MoveOutListForm[];
  page: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
}) => {
  const { showDialog } = useDialog();

  const navigate = useNavigate();

  // TODO: 구에서만 행정동이 보이게 구현
  return (
    <DataTable
      columnTitles={[
        "성명",
        "생년월일",
        "성별",
        "전출구",
        "전입구",
        "전입동",
        "전출일자",
        "전입일자",
      ]}
      rows={moveOutListForm.map((moveOutTarget) =>
        createRow({
          cells: [
            createTextCell({ text: moveOutTarget.trgterNm }),
            createTextCell({ text: moveOutTarget.brthdy }),
            createTextCell({ text: moveOutTarget.gender }),
            createTextCell({ text: moveOutTarget.outGu }),
            createTextCell({ text: moveOutTarget.inGu }),
            createTextCell({ text: moveOutTarget.hdong }),   
            createTextCell({
              text: moveOutTarget.crtDt ? moment(moveOutTarget.crtDt).subtract(1, "months").format("yyyy-MM-DD") : "-",
            }),
            createTextCell({
              text: moveOutTarget.updDt ? moment(moveOutTarget.updDt).subtract(1, "months").format("yyyy-MM-DD") : "-",
            }),
          ],
        })
      )}
      page={page}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
    />
  );
};
export default ServiceTargetPage;
