import TopNavBar from "../components/common/TopNavBar";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.21
 *
 * {@link TopNavBar}
 * 상단 네비게이션바의 알림 리스트를 구현하기 위해
 * 알림에 필요한 정보를 추상화한 interface 입니다.
 */
interface BaseNotification {
  id: number;
  category: string;
  content: string;
  date: string;
}

namespace BaseNotification {
  export const mockList: BaseNotification[] = [
    {
      id: 1,
      category: "돌봄대상자",
      content:
        "알림이 왔습니다 내용입니다 작성중입니다알림이 왔습니다 내용입니다 작성중입니다알림이 왔습니다 내용입니다 작성중입니다",
      date: "2023.02.16(목)",
    },
    {
      id: 2,
      category: "돌봄계획",
      content: "알림이 왔습니다 내용입니다 작성중입니다",
      date: "2023.02.16(목)",
    },
    {
      id: 3,
      category: "평가/사후관리",
      content: "알림이 왔습니다 내용입니다 작성중입니다",
      date: "2023.02.16(목)",
    },
  ];
}

export default BaseNotification;
