namespace FileUtil {

  /**
   * 파일 객체의 경로를 구하는 함수
   * @param file
   * @returns {Promise<string>}
   */
  export const getFilePath = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject();
        }
      };
      reader.onabort = reject;
      reader.readAsDataURL(file);
    });
  };
}

export default FileUtil;
