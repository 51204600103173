import Gender from "./Gender";
import { Expose } from 'class-transformer';
import ServiceTarget, { ServiceTargetRegistrationType } from "./ServiceTarget";
import CaseManager from "./CaseManager";

/**
 * 작성자: 강우석
 * 작성일: 2022.02.22
 *
 * 수정자: 홍준표
 * 수정일: 2023.03.06
 * 파라미터 주석 추가 2023.03.06
 *
 * 사례관리 모델
 *
 * stUid: 대상자 일련번호
 *
 * syUid: 사례관리 일련번호
 *
 * crUid: 돌봄콜 일련번호
 *
 * sbscWay: 접수방법
 *
 * hdong: 행정동
 *
 * brthdy: 생년월일
 *
 * gender: 성별
 *
 * telno: 휴대전화
 *
 * adres: 주소지
 *
 * statCd: 상태코드
 *
 * crtDt: 등록일시
 *
 * crtUid: 등록자 일련번호
 *
 * sbscNm: 신청인 성명
 *
 * sbscRel: 신청인 관계
 *
 * sbscPhone: 신청인 전화번호
 *
 * sbscReason: 신청사유
 */
class ServiceTargetYear extends ServiceTarget {
  constructor(
    public syUid: number,
    public stUid: number,
    public csUid: number | undefined,
    public trgterNm: string,
    public trgterCd: string,
    public hdong: string,
    public brthdy: string,
    public gender: Gender,
    public telno: string,
    public adres: string,
    public adresDtl: string,
    public statCd: ServiceTargetYearStatus,
    public crtDt: string | undefined,
    public crtUid: number | undefined,
    public crtNm: string | undefined,
    public sbscWay: ServiceTargetRegistrationType,
    public applcntNm: string | undefined,
    public applcntRel: string | undefined,
    public applcntTel: string | undefined,
    public applcntReason: string | undefined,
    public applcntEtc: string | undefined,
    public counselDate: string | undefined,
    public managers: CaseManager[],
    public incomeLevel: '85이하' | '100이하' | '100초과',
    public incomeLevel2: '수급자' | '차상위' | '85이하' | "",
    public emgcNm: string,
    public emgcRel: string,
    public emgcTel: string,
    public homeMember: number,
    public characterJson: string,
    public updDt: string,

  ) {
    super(stUid, syUid, sbscWay, trgterNm, trgterCd, hdong, brthdy, gender, telno, adres, adresDtl);
  }

  get hasManager(): boolean {
    return this.managers.length !== 0;
  }

  get progress(): ServiceTargetYearProgress {
    if (!this.hasManager) {
      return "담당자 배정 필요";
    }
    if (!this.counselDate) {
      return "일정 등록 필요";
    }
    return "완료";
  }

  @Expose({ toPlainOnly: true })
  get applcntTel1(): string | undefined {
    return this.applcntTel?.split("-")[0];
  }

  @Expose({ toPlainOnly: true })
  get applcntTel2(): string | undefined {
    return this.applcntTel?.split("-")[1];
  }

  @Expose({ toPlainOnly: true })
  get applcntTel3(): string | undefined {
    return this.applcntTel?.split("-")[2];
  }

  get incomeLevelString() {
    switch (this.incomeLevel) {
      case "85이하":
        return "기준중위소득 85% 이하"
      case "100이하":
        return "기준중위소득 85% 초과 100% 이하"
      case "100초과":
        return "기준중위소득 100% 초과"
    }
  }

  get character() {
    return JSON.parse(this.characterJson) as ServiceTargetYearCharacter
  }

  get characterString() {
    const arr = [] as string[]
    const character = this.character?.demographicCharacteristics;
    if (character?.type === "영유아") {
      arr.push('영유아')
    }
    if (character?.type === "아동·청소년") {
      arr.push('아동·청소년')
    }
    if (character?.type === "1인가구") {
      if(character.singleType === ""){
        arr.push(`1인가구`)  
      }else{
        arr.push(`1인가구(${character.singleType})`)
      }
    }
    if (character?.type === "노인") {
      arr.push(`노인(${character.oldType})`)
    }
    if (character?.type === "장애인") {
      arr.push(`장애인(${character.disabledType} : ${character.disabledTypeInput})`)
    }
    if (character?.disabledAdd) {
      arr.push(`장애인(${character.disabledAddType} : ${character.disabledTypeAddInput})`)
    }
    return arr.join(', ');
  }
  get usingServiceString() {
    const arr = [] as string[]
    const usingService = this.character?.usingServices;
    if(usingService?.babysitting){
      arr.push('아이돌봄')
    }
    if(usingService?.longTermCareInsuranceForElderly){
      arr.push('노인장기요양보험')
    }
    if(usingService?.customizedElderlyCare){
      arr.push('노인맞춤돌봄')
    }
    if(usingService?.supportForDisabledActivities){
      arr.push('장애인활동지원')
    }
    if(usingService?.others){
      arr.push(`기타(${usingService.othersInput})`)
    }
    return arr.join(', ');
  }
}

export interface ServiceTargetYearCharacter{
  distress: string; // 상황
  applicnFileName: string | undefined;
  agreeFileName: string | undefined;
  demographicCharacteristics: {
    // 인구학적 특성
    type: string; //라디오 버튼 변경
    young: boolean;
    teen: boolean;
    single: boolean;
    singleType: string;
    old: boolean;
    oldType: string;
    disabled: boolean;
    disabledType: string;
    disabledTypeInput: string;
    disabledAdd: boolean;
    disabledAddType: string;
    disabledTypeAddInput: string;
  };
  usingServices?: {
    babysitting: boolean;
    longTermCareInsuranceForElderly: boolean;
    customizedElderlyCare: boolean;
    supportForDisabledActivities: boolean;
    others: boolean;
    othersInput: string;
  };
  needs?: {
    integrated: boolean;
    integratedType: string[];
    integratedReason: string;
    gwangjuCare: boolean;
    gwangjuCareType: string[];
    emergencyCare: boolean;
  };
}

export type ServiceTargetYearStatus =
  | "접수"
  | "담당배정"
  | "현장방문"
  | "사례종결"
  | "돌봄계획"
  | "사례회의(동)"
  | "사례회의(권역)"
  | "돌봄계획확정"
  | "서비스의뢰"
  | "재수립"

export const ServiceTargetYearStatusValues = [
  "접수",
  "담당배정",
  "현장방문",
  // "사례종결",
  // "돌봄계획",
  // "사례회의(동)",
  // "사례회의(권역)",
  // "돌봄계획확정",
  // "서비스의뢰",
  // "재수립",
];

export const ServiceTargetYearHdong = [
  "동천동",
  "광천동",
  "유덕동",
  "치평동",
  "상무1동",
  "상무2동",
  "화정1동",
  "화정2동",
  "화정3동",
  "화정4동",
  "농성1동",
  "농성2동",
  "양동",
  "양3동",
  "금호1동",
  "금호2동",
  "풍암동",
  "서창동",
  "쌍촌동",
]

export type ServiceTargetYearProgress =
  | "담당자 배정 필요"
  | "일정 등록 필요"
  | "완료";


export default ServiceTargetYear;
