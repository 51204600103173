import React, { ChangeEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import ServiceTargetAPI from "../../apis/ServiceTargetAPI";
import CloseIcon from "../../assets/ic_close.png";
import useMember from "../../hooks/useMember";
import { DialogProps } from "../DialogProvider";
import "./MoveOutDialog.scss";
import { DepartVO } from "../../apis/MemberAPI";
import MoveOutAPI from "../../apis/MoveOutAPI";
import axios from "axios";
import MoveOutList from "../../models/MoveOutList";
/**
 * 작성자: 김덕현
 * 작성일: 2023.04.13
 *
 * 전입·전출 팝업
 * @param syUid 사례관리 일련번호
 */
interface CancelScheduleDialogProps extends DialogProps {
    moveOutList: MoveOutList
}

type GU = 2 | 3 | 4 | 5 | 6 | 9999
type GuEnum = "DONGGU" | "SEOGU" | "NAMGU" | "BUKGU" | "GWANGSANGU" | undefined

export interface CancelScheduleFormInput {
    stUid: number,
    syUid: number,
    gu: GuEnum,
    hdong: string,
    mbrUid?: number,
    dptUid?: number,
}

const MoveOutDialog = ({
    moveOutList,
    ...props
}: CancelScheduleDialogProps) => {
    const navigate = useNavigate();
    const { member } = useMember();
    const { register, handleSubmit, setValue, getValues, formState: { errors }, setError, watch } = useForm<CancelScheduleFormInput>({
        defaultValues: {
            stUid: moveOutList.stUid,
            syUid: moveOutList.syUid,
        }
    });
    const [moveOutGuList, setMoveOutGuList] = useState([] as DepartVO[])
    const [moveOutDongList, setMoveOutDongList] = useState([] as DepartVO[])
    const [currentGu, setCurrentGu] = useState({} as DepartVO)

    useEffect(() => {
        // console.log(moveOutList)
        MoveOutAPI.moveOutGuList({deptCd: "GU"}).then((res) => {
            setMoveOutGuList(res)
        })
        MoveOutAPI.getCurrentGu({ deptCd: "GU" }).then((res) => {
            setCurrentGu(res)
        })
    }, [])

    const guData = watch("gu")

    useEffect(() => {
        setValue("hdong", "")
    }, [guData])

    const handleSelectGu = async (upperDptUid: GU) => {
        let guEnum: GuEnum
        switch (upperDptUid) {
            case 2:
                guEnum = "DONGGU"
                break
            case 3:
                guEnum = "NAMGU"
                break
            case 4:
                guEnum = "SEOGU"
                break
            case 5:
                guEnum = "BUKGU"
                break
            case 6:
                guEnum = "GWANGSANGU"
                break
            default:
                guEnum = undefined
                break
        }
        setValue("gu", guEnum)
        await MoveOutAPI.moveOutGuList({ deptCd: "DONG", upperDptUid: upperDptUid })
            .then((res) => {
            setMoveOutDongList(res)
        })
    }

    const handleClose = () => {
        props.onClose?.();
    };

    const onSubmit: SubmitHandler<CancelScheduleFormInput> = async (data) => {
        // console.log(data);
        // TODO: 유효성 검사

        const suc = await MoveOutAPI.moveOut({
            ...data,
            mbrUid: member?.mbrUid,
            dptUid: currentGu.dptUid,
        })

        if (suc) {
            window.alert("전출 처리 완료")
            navigate(0);
        }
    };

    const onError = () => {
        if (errors.hdong) {
            window.alert(errors.hdong.message)
        }
    }

    return (
        <div className="moveOutDialog stop_popup popup_black" {...props}>
            <div className="stop_popup_inner popup_white">
                <div className="popup_tit">
                    <h3>전출신청</h3>
                    <a href="#" className="close" onClick={handleClose}>
                        <img src={CloseIcon} alt="닫기" />
                    </a>
                </div>
                <div className="popup_con">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="default_input">
                            <h4 style={{ fontWeight: 600 }}>전출지역(구)</h4>
                            <div className="write_box">
                                <select className="essential"
                                    style={{
                                        color: 'rgb(0, 0, 0)', height: 40, border: '1px solid #e6e8ea', backgroundColor: '#f6f7f9', fontSize: '15',
                                        borderRadius: '5px', width: "340px"
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            if (e.target.value) {
                                                handleSelectGu(Number(e.target.value) as GU)
                                            } else {
                                                handleSelectGu(9999)
                                            }
                                        }}
                                >
                                    <option value="">선택</option>
                                    {moveOutGuList.length > 0 ? 
                                        moveOutGuList.map((gu, idx) => (
                                        <option key={idx} value={gu.dptUid}>{gu.departName}</option>
                                    ))
                                        :
                                    null}
                                </select>
                            </div>
                        </div>
                        <div className="default_input">
                            <h4>행정동</h4>
                            <div className="write_box mt10">
                                <select className="essential"
                                    style={{
                                        color: 'rgb(0, 0, 0)', height: 40, border: '1px solid #e6e8ea', backgroundColor: '#f6f7f9', fontSize: '15',
                                        borderRadius: '5px', width: "340px"
                                    }}
                                    {...register("hdong", {
                                        required: "전출할 행정동을 선택해주세요."
                                    })}
                                >
                                    <option value="">선택</option>
                                    {moveOutDongList.length > 0 ? 
                                        moveOutDongList.map((dong, idx) => (
                                            <option key={idx} value={dong.departName}>{dong.departName}</option>
                                        ))
                                    : null}
                                </select>
                            </div>
                        </div>
                        <div className="btn_wrap">
                            <button type="button" className="cancel" onClick={handleClose}>
                                취소
                            </button>
                            <button type="submit" className="submit">
                                등록
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default MoveOutDialog;
