import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { MultiSelect, Option } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";

import ServiceTargetAPI from "../../apis/ServiceTargetAPI";
import CloseIcon from "../../assets/ic_close.png";
import useDebounce from "../../hooks/useDebounce";
import useMember from "../../hooks/useMember";
import CaseManager from "../../models/CaseManager";
import { DialogProps } from "../DialogProvider";
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormRegister,
  UseFormRegisterReturn,
  useForm,
} from "react-hook-form";

import "./AssignManagerDialog.scss";


/**
 * 작성자: 강우석
 * 작성일: 2023.02.22
 * 담당자 배정 팝업
 *
 * @param syUid 사례관리 일련번호
 * @param initialManagers (수정일 경우) 배정된 매니저 초기값
 */
interface AssignManagerDialogProps extends DialogProps {
  syUid: number;
  initialManagers?: CaseManager[];
}

const AssignManagerDialog = ({
  syUid,
  initialManagers = [],
  ...props
}: AssignManagerDialogProps) => {
  const { member } = useMember();
  const navigate = useNavigate();
  const [selectedManagers, setSelectedManagers] = useState(initialManagers);
  const [managers, setManagers] = useState([] as CaseManager[]);
  const {control, getValues, watch, setValue} = useForm<mngOption>()
 // const { register, control, watch } = useForm;
 // const data = watch();
 const [mng, setMng] = useState([] as Option[]);

 interface mngOption {
  manager: Option[],
 }

 const data = watch()

  // 검색 시 바로 API를 호출하는 것이 아니라,
  // 500밀리초 동안 이벤트가 없을 경우 호출
  // const debouncedName = useDebounce(name, 500);

  // 이름 변경 시 담당자 검색 API 호출
  useEffect(() => {
    // if (!debouncedName) {
    //   return;
    // }
    ServiceTargetAPI.findManagersByName({ syUid: Number(syUid) }).then((res) =>  {
      setManagers(res)
    }
    );
    let tempArr = [] as Option[]
    initialManagers.map((item) => {
      tempArr.push({label : item.userName, value: item.mbrUid})
    })
    setValue("manager", tempArr)
  }, []);

  useEffect(() => {
    if(data.manager) {
      setMng(data.manager)
    }
   
  }, [data])

  useEffect(() => {
    if(mng.length > 0) {
      let tempArr = [] as CaseManager[]
      mng.map((mng) => {
        tempArr.push({mbrUid: mng.value, userName: mng.label})
      })
      setSelectedManagers(tempArr)
    } else {
      setSelectedManagers([])
    }
    if(mng.length > 2) {
      mng.pop()
      selectedManagers.pop()
      setValue("manager", mng)
    }
  }, [mng])

  const handleManagerRemove = (manager: CaseManager) => {
    let removeArr = mng.filter((item) => item.label !== manager.userName && item.value !== manager.mbrUid)
    setValue("manager", removeArr)
  };

  const handleClose = () => {
    props.onClose?.();
  };

  const handleSubmit = async () => {
    if (selectedManagers.length === 0) {
      return;
    }

    const success = await ServiceTargetAPI.assignManager({
      updateYn: initialManagers.length > 0 ? "Y" : "N",
      syUid,
      manager1: selectedManagers[0].mbrUid,
      manager2: selectedManagers[1]?.mbrUid,
      crtUid: 1, // TODO: 회원 키 변경
    });

    if (success) {
      navigate(0);
    } else {
      // TODO: 실패 처리
    }
  };

  const ManagerOptions = () =>{
    let ops:Option[] = []
    managers.map((item) => {
      ops.push({
        label: item.userName,
        value: item.mbrUid,
      })
    })
    return ops
  };


  return (
    <div className="assign_manager_dialog assign_popup popup_black" {...props}>
      <div
        className="assign_popup_inner popup_white"
        style={{ overflowY: "visible" }}
      >
        <div className="popup_tit">
          <h3>담당자 배정</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form id="autoform">
            <div className="default_input name">
              <h4>담당자</h4>
              <div className="write_box" >
                <Controller
                  control={control}
                  name="manager"
                  render={({field : {onChange, value}}) => (
                    <MultiSelect
                      options={ManagerOptions()}
                      value={value?.map((v) => ({ label: v.label, value: v.value })) ?? []}
                      onChange={(e:{label:string, value: string}[]) => {
                        onChange(e.map((e)=> {
                          return {label: e.label, value: e.value}
                        }))
                      }} 
                          labelledBy=""
                          disableSearch
                          hasSelectAll={false}
                          overrideStrings={{
                            selectSomeItems: "담당자 선택",
                            allItemsAreSelected: "모두선택",
                          }}
                    />
                  )}
                />
                    
               
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "70px" }} />
              {selectedManagers.map((manager) => (
                <Chip
                  key={manager.mbrUid}
                  label={manager.userName}
                  onDelete={() => handleManagerRemove(manager)}
                  sx={{ marginTop: "8px", marginRight: "8px" }}
                />
              ))}
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="button" className="submit" onClick={handleSubmit}>
                저장
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AssignManagerDialog;
