/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect, useState} from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import useMember from "../../../hooks/useMember";
import ArrowIcon from "../../../assets/ic_arrow.png";
import MapImg from "../../../assets/map.jpg";
import "./HomePage.scss";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, RadialLinearScale, BarElement, CategoryScale } from 'chart.js/auto';
import { Doughnut, Pie, PolarArea, Bar } from 'react-chartjs-2';
import { NonceProvider } from "react-select";
import moment from "moment";
import HomeAPI, { DongHome, DongStatList, GuDoughnut, GuHome, GuPie, GuPolarArea, OrgHome, ServiceStatsList, } from "../../../apis/HomeAPI";
import Member from "../../../models/Member";
import MemberAPI from "../../../apis/MemberAPI";
import { practice } from "../../../dialogs/service-target/ObiligationDialog";
import axios from "axios";
import KaKaoMapComponent from "../../../components/common/KaKaoMapComponent";

ChartJS.register(ArcElement, Tooltip, Legend, RadialLinearScale, BarElement, CategoryScale);

const HomePage = () => {
  const { member } = useMember();
  const { page } = useParams();
  const [departList, setDepartList] = useState([] as string[])
  const [guHome, setGuHome] = useState({} as GuHome)
  const [dongHome, setDongHome] = useState({} as DongHome)
  const [orgHome, setOrgHome] = useState({} as OrgHome)
  const [deptLoad, setDeptLoad] = useState(true)
  const [mapOptions, setMapOptions] = useState<{center: any, level: number}>()

  useEffect(() => {
    MemberAPI.getDeptList({deptCd: "DONG"}).then((res) => {
      let tempList:string[] = []
      res.list.map((item) => {
        tempList.push(item.departName)
      })
      // setMapOptions({
      //   center: new window.kakao.maps.LatLng(33.450701, 1236.570667),
      //   level: 3
      // })
      setDepartList(tempList)
      setDeptLoad(false)
    })
    if(member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU") {  //시, 구
      HomeAPI.getGuHome().then((res) => {
        setGuHome(res)
      })
    } else if(member?.depart?.deptCd === "DONG") {  //동
      HomeAPI.getDongHome({
        hdong: member.depart.departName,
      }).then((res) => {
        setDongHome(res)
      })
    } else {  //기관
      HomeAPI.getOrgHome({
        dptUid: member?.dptUid,
      }).then((res) => {
        setOrgHome(res)
      })
    }

  }, [])

  const handleGuideDownloadClick = async (param: practice) => {
    try {
      const response = await axios.get("/api/home/guide/"+param.fileName+"/"+param.downloadFileName, {
        responseType: "blob"
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", param.downloadFileName+".pdf");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  if (!member) {
    return <Navigate to="/login" />;
  }

  return <>
    <main className="home">
      {deptLoad ? null :
      <section>
        <article className="btn_zone" style={{width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "20px"}}>
          <button type="button" style={{display: "flex", alignItems: "center", justifyContent: "center", height: "40px", backgroundColor: "#535a78", padding: "0 20px", borderRadius: "5px", fontSize: "15px", color: "#fff", fontWeight: "600", transition: "all .2s"}}
          onClick={() => handleGuideDownloadClick({fileName: "guideGu.pdf", downloadFileName: "이용가이드(구)"})}>이용가이드(구)</button>
          <button type="button" style={{display: "flex", alignItems: "center", justifyContent: "center", height: "40px", backgroundColor: "#535a78", padding: "0 20px", borderRadius: "5px", fontSize: "15px", color: "#fff", fontWeight: "600", transition: "all .2s", marginLeft: "10px"}}
          onClick={() => handleGuideDownloadClick({fileName: "guideDong.pdf", downloadFileName: "이용가이드(동)"})}>이용가이드(동)</button>
          <button type="button" style={{display: "flex", alignItems: "center", justifyContent: "center", height: "40px", backgroundColor: "#535a78", padding: "0 20px", borderRadius: "5px", fontSize: "15px", color: "#fff", fontWeight: "600", transition: "all .2s", marginLeft: "10px"}}
          onClick={() => handleGuideDownloadClick({fileName: "guideOrg.pdf", downloadFileName: "이용가이드(제공기관)"})}>이용가이드(제공기관)</button>
        </article>
        <Today data={member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ? guHome :
         member.depart?.deptCd === "DONG" ? dongHome : orgHome} member={member}/>
        <article className="statistics mt20">
          <div className="graph mr20">
            <GraphTop departList={departList} member={member}/>
            <GraphBottom departList={departList} member={member} />
          </div>
          <Map/>
        </article>

      </section>
      }
      
    </main>
    <script>

    </script>
  </>
};

const Today = ({data, member} : {data : any, member: Member}) => {
  const navigate = useNavigate();
  const handleLink = (link: number) => {
    switch(link) {
      case 1:
        navigate(`/service-target`)
        return
      case 2:
        navigate(`/service-plan`)
        return
      case 3:
        navigate(`/service`)
        return
      case 4:
        navigate(`/expense`)
        return
      case 5:
        navigate(`/evaluation`)
        return
      default:
        return
    }
  }
  return (
    <article className="today">
      <div className="tit">
        <h3>Today</h3>
        <p>{moment().format("yyyy.MM.DD(dd)")}</p>
      </div>
      <div className="cont">
        {member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ? 
        <ul>
          <li>
            <h4>서비스 의뢰</h4>
            <p><span>{data.ServiceListCount ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(3)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>서비스 통보</h4>
            <p><span>{data.EmergencyCount ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(3)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>결과보고 제출</h4>
            <p><span>{data.ServiceResultCount ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(3)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>비용 청구</h4>
            <p><span>{data.ClaimCount ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(4)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>서비스 평가 결과</h4>
            <p><span>{data.ResultEvalCount ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(5)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
        </ul>
        :
        member.depart?.deptCd === "DONG" ? //동
        <ul>
          <li>
            <h4>창구 접수(배정)</h4>
            <p><span>{data.WindowStatus ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(1)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>방문일정 등록</h4>
            <p><span>{data.PlanStatus ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(1)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>돌봄필요도 평가</h4>
            <p><span>{data.NeedAssStatus ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(2)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>계획수립</h4>
            <p><span>{data.DbPlanStatus ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(2)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>동 사례회의</h4>
            <p><span>{data.DongConferStatus ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(2)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>권역별 사례회의</h4>
            <p><span>{data.AreaConferStatus ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(2)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>서비스 평가</h4>
            <p><span>{data.ResultEvalCount ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(5)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
        </ul>
        : //기관
        <ul>
          <li>
            <h4>서비스 접수(배정)</h4>
            <p><span>{data.ServiceReception ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(3)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>서비스 진행중</h4>
            <p><span>{data.ServiceOnGoing ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(3)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>서비스 결과보고</h4>
            <p><span>{data.ServiceComplete ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(3)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
          <li>
            <h4>비용 미청구</h4>
            <p><span>{data.BillUnClaim ?? 0}</span>건</p>
            <a href="#" onClick={() => handleLink(4)}>바로가기<img src={ArrowIcon} alt="바로가기 아이콘" /></a>
          </li>
        </ul>
        }
        
      </div>
    </article>
  );
};

const GraphTop = ({departList, member} : {departList : string[], member: Member}) => {
  const [hdong1, setHdong1] = useState("서구전체")
  const [hdong2, setHdong2] = useState("서구전체")
  const [hdong3, setHdong3] = useState("서구전체")
  const [guDoughnut, setGuDoughnut] = useState({} as GuDoughnut)
  const [guPie, setGuPie] = useState({} as GuPie)
  const [yearData, setYearData] = useState([0, 0, 0] as number[])
  useEffect(() => {
    if(member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU") {  //시, 구
      if(hdong1 === "서구전체") {
        HomeAPI.getGuDoughnut().then((res) => {
          // console.log("res : ", res)
          setGuDoughnut(res)
        })
      } else {
        HomeAPI.getGuDoughnut({
          hdong: hdong1,
        }).then((res) => {
          setGuDoughnut(res)
        })
      }
    } else if(member.depart?.deptCd === "DONG") { //동
      HomeAPI.getGuDoughnut({
        hdong: member.depart.departName,
      }).then((res) => {
        setGuDoughnut(res)
      })
    } else {  //기관
      HomeAPI.getOrgDoughnut({
        dptUid: member.dptUid,
      }).then((res) => {
        setGuDoughnut(res)
      })
    }
    
  }, [hdong1])

  useEffect(() => {
    if(member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU") {  //시, 구
      if(hdong2 === "서구전체") {
        HomeAPI.getGuPie().then((res) => {
          // console.log(res)
          setGuPie(res)
        })
      } else {
        HomeAPI.getGuPie({
          hdong: hdong2,
        }).then((res) => {
          setGuPie(res)
        })
      }
    } else if(member.depart?.deptCd === "DONG") { //동
      HomeAPI.getGuPie({
        hdong: member.depart.departName,
      }).then((res) => {
        setGuPie(res)
      })
    } else {  //기관
      HomeAPI.getOrgPie({
        dptUid: member.dptUid,
      }).then((res) => {
        setGuPie(res)
      })
    }
    
  }, [hdong2])

  const yearDataSetting = (stepCd: string, totalCount: number, temp: number[]) => {
    if(stepCd === "진행중") {
      temp[0] = totalCount
    } else if(stepCd === "종결") {
      temp[1] = totalCount
    } else {
      temp[2] = totalCount
    }
    setYearData(temp)
    return temp
  }

  useEffect(() => {
    if(member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU") {  //시, 구
      if(hdong3 === "서구전체") {
        let temp = [0, 0, 0]
        HomeAPI.getPolarArea().then((res) => {
          // console.log(res)
          res.list.map((item) => {
            temp = yearDataSetting(item.stepCd, item.totalCount, temp)
          })
        })
      } else {
        let temp = [0, 0, 0]
        HomeAPI.getPolarArea({
          hdong: hdong3,
        }).then((res) => {
          res.list.map((item) => {
            temp = yearDataSetting(item.stepCd, item.totalCount, temp)
          })
        })
      }
    } else if(member.depart?.deptCd === "DONG") { //동
      let temp = [0, 0, 0]
      HomeAPI.getPolarArea({
        hdong: member.depart.departName,
      }).then((res) => {
        res.list.map((item) => {
          temp = yearDataSetting(item.stepCd, item.totalCount, temp)
        })
      })
    } else {  //기관
      let temp = [0, 0, 0]
      HomeAPI.orgPolarArea({
        dptUid: member.dptUid,
      }).then((res) => {
        res.list.map((item) => {
          temp = yearDataSetting(item.stepCd, item.totalCount, temp)
        })
      })
    }
    
  }, [hdong3])

  return (
    <div className="top">
      {/* 그래프1 등록구분별 대상자 현황     */}
      <div className="graph01 mr20">
        {member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ?
        <select onChange={(e) => setHdong1(e.target.value)}>
          <option value="서구전체">서구 전체</option>
          {departList.map((depart, idx) => (
            <option key={idx} value={depart}>
              {depart}
            </option>
          ))}
        </select>
        : null
        }
        <h4><span>{member.depart?.departName}</span> 등록구분별 대상자 현황</h4>
        <div className="graph_area">
          <Doughnut
          // style={{ width: 200, height: 200 }}
          width={300}
          height={300}
          data={{
            labels: ['의무대상', '전입자', '재수립대상', '의무대상(사례종결)', '의무대상(계획변경)', '돌봄콜', '창구'],
            datasets: [
              {
                data: [guDoughnut.ObilStatus, guDoughnut.TransferStatus, guDoughnut.RePlanStatus, guDoughnut.ObEndStatus, guDoughnut.ObChangeStatus, guDoughnut.CallStatus, guDoughnut.WindowStatus],
                backgroundColor: ['#ff6384','#ff9f40','#ffcd56','#4bc0c0', '#36a2eb', '#9966ff', '#5a6326'],
                borderColor: ['#fff'],
              }],
          }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: true,
                  position: "bottom",
                  labels: {
                    color: 'black',
                  }
                }
              }
            }}
          />
          <p style={{paddingTop: "60px"}}><span>전체</span>{guDoughnut.TargetStatus}</p>
          <div id="legend01" />
        </div>
      </div>

      {/* 그래프2 5대영역별 대상자 현황     */}
      <div className="graph02 mr20">
        {member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ?
        <select onChange={(e) => setHdong2(e.target.value)}>
          <option value="서구전체">서구 전체</option>
          {departList.map((depart, idx) => (
            <option key={idx} value={depart}>
              {depart}
            </option>
          ))}
        </select>
        : null
        }
        <h4><span>{member.depart?.departName}</span> 5대영역별 대상자 현황</h4>
        <div className="graph_area">
          <Pie
            style={{ width: 200, height: 200 }}
            data= {{
              labels: ['영유아', '아동청소년', '노인', '장애인', '1인가구(청년)', '1인가구(장년)'],
              datasets: [{
                  data: [guPie.young, guPie.teen, guPie.old, guPie.disabled, guPie.singleY, guPie.singleO],
                  backgroundColor: ['#ff6384','#ff9f40','#ffcd56','#4bc0c0', '#36a2eb', '#9966ff'],
                  borderColor: ['#fff']
                  }],
            }}
            options={{
              plugins: {
                legend: {
                  display: true,
                  position: "bottom",
                  labels: {
                    color: 'black',
                }
              }
            }
          }}
          />
          {/* <canvas id="myChart02" style={{ width: 200, height: 200 }} /> */}
          <div id="legend02" />
        </div>
      </div>

      {/* 그래프3 사례 현황     */}
      <div className="graph03">
        {member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ?
        <select onChange={(e) => setHdong3(e.target.value)}>
          <option value="서구전체">서구 전체</option>
          {departList.map((depart, idx) => (
            <option key={idx} value={depart}>
              {depart}
            </option>
          ))}
        </select>
        : null
        }
        <h4><span>{member.depart?.departName}</span> 사례 현황</h4>
        <div className="graph_area">
          <PolarArea
          width={300}
          height={300}
            data= {{
                labels: ['진행중', '종결', '중단'],
                datasets: [{
                    data: yearData,
                    backgroundColor: ['#ffb1c1','#fdcd9d','#ffe6aa'],
                    borderColor: ['#fff']
                    }],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: true,
                      position: "bottom",
                      labels: {
                        color: 'black',
                      }
                    }
                  }
                }} 
          />
          {/* <canvas id="myChart03" style={{ width: 200, height: 200 }} /> */}
          <div id="legend03" />
        </div>
      </div>
    </div>
  );
}; // GraphTop

const GraphBottom = ({departList, member} : {departList : string[], member: Member}) => {
  const [tab1, setTab1] = useState(0);
  const [departLabels, setDepartLabels] = useState([] as string[])
  const [gjCount, setGjCount] = useState([] as number[])
  const [emCount, setEmCount] = useState([] as number[])
  const [serviceStatsList, setServiceStatsList] = useState({} as ServiceStatsList)
  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(true)
  useEffect(() => {
    if(member.depart?.deptCd === "DONG") {
      setTab1(1)
    }
    let temp = [] as string[]
    departList.map((depart) => {
      temp.push(depart)
    })
    setDepartLabels(temp)
    if(member.depart?.deptCd !== "DONG") {
      if(member.depart?.deptCd === "ORG") { //기관
        HomeAPI.getDongStatList({
          dptUid: member.dptUid,
        }).then((res) => {
          res.list.map((item) => {
            setGjCount((cur) => [...cur, item.gjCount])
            setEmCount((cur) => [...cur, item.emCount])
          })
          setLoading(false)
        })
      } else {  //시, 구
        HomeAPI.getDongStatList().then((res) => {
          res.list.map((item) => {
            setGjCount((cur) => [...cur, item.gjCount])
            setEmCount((cur) => [...cur, item.emCount])
          })
          setLoading(false)
        })
      }
    } else {
      setLoading(false)
    }
    
    if(member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU") {  //시, 구
      HomeAPI.getServiceStatsList().then((res) => {
        setServiceStatsList(res)
        setLoading2(false)
      })
    } else if(member.depart?.deptCd === "DONG") { //동
      HomeAPI.getServiceStatsList({
        hdong: member.depart?.departName,
      }).then((res) => {
        setServiceStatsList(res)
        setLoading2(false)
      })
    } else {  //기관
      HomeAPI.getServiceStatsList({
        dptUid: member.dptUid,
      }).then((res) => {
        setServiceStatsList(res)
        setLoading2(false)
      })
    }
    
  }, [])

  return (
    <div className="bottom mt20">
      <div className="graph04">
        {member.depart?.deptCd !== "DONG" ?
          <div className="tabs">
            <button 
            type="button" 
            className={`tab ${tab1 === 0 ? "tab_active" : "non_active"}`}
            onClick={() => setTab1(0)}
            data-id={0}
            >동별 현황</button>
            <button type="button" 
            className={`tab ${tab1 === 1 ? "tab_active" : "non_active"}`}
            onClick={() => setTab1(1)}
            data-id={1}
            >서비스별 현황</button>
          </div>
        : null
        }
        

        {/* 동별 현황 */}
        {tab1 === 0 && (
          member.depart?.deptCd !== "DONG" ? 
            <div className="tab_content" data-id={0}>
              <div className="graph_area" >
              {/* <canvas id="myChart05" style={{ width: '100%', height: 211 }} /> */}
              {loading ? null :
                <Bar
                  //style={{ width: 1083, height: 211 }}
                  width={1135}
                  height={211}
                  data= {{
                    labels: departLabels,
                    datasets: [{
                      label: '광주+돌봄',
                      backgroundColor: 'rgba(87, 173, 111, 1)',
                      data: gjCount,
                      barThickness: 15 
                      //borderRadius: 20
                        },
                      {
                        label: '긴급돌봄',
                        backgroundColor: 'rgba(87, 173, 111, .4)',
                        data: emCount,
                        barThickness: 15
                        //borderRadius: 20
                      }], 
                  }}
                    options={{
                      maintainAspectRatio: false, // 차트 사이즈 조절
                      plugins: {
                        legend: {
                          display: true,
                          position: "bottom",
                          labels: {
                            color: 'black',
                        }
                      }
                    },
                    responsive: false,  // 사이즈 조절됨 
                    scales: {
                      x: {
                        stacked: true,
                        grid: {
                          display: false,
                        },
                        ticks:{
                          display: true,
                        }
                      },
                      y: {
                        stacked: true,
                        grid: {
                          display: false,
                        },
                        ticks:{
                          display: false,
                        }
                      },
                    },
                  }}
                />
              }
                
                <div id="legend05" />
              </div>
            </div>
          : null       
        )}
        {/* 서비스별 현황 */}
        {member.depart?.deptCd === "DONG" ? 
            <h4><span>{member.depart?.departName} 서비스별 현황</span></h4>
            : null}
        {tab1 === 1 && (
           <div className="tab_content " data-id={1}>
            <div className="graph_area" style={{ justifyContent: 'space-evenly' }}>
            {/* <canvas id="myChart04" style="width: 500px; height: 234px;"></canvas> */}
            {loading2 ? null :
              <Bar
                //style={{ width: 200, height: 234 }}
                 width={500}
                 height={234}
                data= {{
                  labels: serviceStatsList.list.map((item) => (item.cateStr)),
                  datasets: [{
                    label: '광주+돌봄',
                    backgroundColor: 'rgba(87, 173, 111, 1)',
                    data: serviceStatsList.list.map((item) => (item.gubun === "광주돌봄" ? item.totalCount : undefined)),
                    barThickness: 15
                    //borderRadius: 20
                      },
                    {
                      label: '긴급돌봄',
                      backgroundColor: 'rgba(87, 173, 111, .4)',
                      data: serviceStatsList.list.map((item) => (item.gubun !== "광주돌봄" ? item.totalCount : undefined)),
                      barThickness: 15
                      //borderRadius: 20
                    }],
                }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                        position: "bottom",
                        labels: {
                          color: 'black',
                      }
                    }
                  },
                  responsive: false,
                
                  scales: {
                    x: {
                      stacked: true,
                      grid: {
                        display: false,
                      }
                    },
                    y: {
                      stacked: true,
                      grid: {
                        display: false,
                      },
                      ticks:{
                        display: false,
                      }
                    },
                  },
                }}
              />
            }
            {loading2 ? null :
              <div id="legend04">
               <ul>
                 <li>
                   <p>
                     <span style={{ backgroundColor: 'rgba(87, 173, 111, 1)' }} />
                     광주+돌봄
                   </p>
                   <ul>
                    {serviceStatsList.list.map((item, idx) => 
                      item.gubun === "광주돌봄" ? 
                      <li key={idx}>
                        <p>{item.cateStr}</p>
                        <span>{item.totalCount.toLocaleString('ko-KR')} 명</span>
                      </li>
                       : null
                    )}
                   </ul>
                 </li>
                 <li>
                   <p>
                     <span style={{ backgroundColor: 'rgba(87, 173, 111, .4)' }} />
                     긴급돌봄
                   </p>
                   <ul>
                   {serviceStatsList.list.map((item, idx) => 
                      item.gubun === "긴급돌봄" ? 
                      <li key={idx}>
                        <p>{item.cateStr}</p>
                        <span>{item.totalCount.toLocaleString('ko-KR')} 명</span>
                      </li>
                       : null
                    )}
                   </ul>
                 </li>
               </ul>
             </div>
            }
             
           </div>
         </div> 
        )}
      </div>
    </div>
  );
}; // GraphBottom

const Map = () => {
  return (
    <div className="map">
      <h4><span>서구</span> 대상자 분포 현황</h4>
      {/* 지도 임시 이미지 */}
      {/* <KaKaoMapComponent /> */}
      <img src={MapImg} />
      {/*임시 클러스터링*/}
      {/* <div className="clustering" style={{ position: 'absolute', top: 300, left: 120 }}>17</div>
      <div className="clustering" style={{ position: 'absolute', top: 320, left: 340 }}>8</div>
      <div className="clustering" style={{ position: 'absolute', top: 410, left: 200 }}>36</div>
      <div className="clustering" style={{ position: 'absolute', top: 390, left: 480 }}>52</div> */}
    </div>
  );
};


export default HomePage;
