import { TopNavTab } from "./TopNavBar";

const MiniTop = ({
    tab,
    setTab,
  }: {
    tab?: TopNavTab;
    setTab: (tab: TopNavTab) => void;
  }) => {
    const settingsTabs:TopNavTab[] = ["기관정보", "제공기관 관리", "서비스 관리", "회원 관리"]
    return (
        <Nav tab={tab} onChange={setTab} tabList={settingsTabs} />
    )
  }

  const Nav = ({tab, onChange, tabList} : {tab?:TopNavTab, onChange: (tab: TopNavTab) => void, tabList: TopNavTab[]}) => {
    return (
        <header className="setting" style={{height: "50px", background: "transparent"}}>
            <div className="tabs">
                {tabList.map((item, idx) => (
                <button key={idx} className={item === tab ? "tab_active" : "non_active"} onClick={() => onChange(item)}>{item}</button>
                ))}
            </div>
        </header>
      
    )
  }

  export default MiniTop