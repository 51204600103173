class CounselResult {
  constructor(
    public syUid: number,
    public csUid: number,
    public beginDt: string,
    public endDt: string,
    public resultJson: string,
    public bodyAreaPt: number,
    public emtAreaPt: number,
    public incomeLevel: string,
    public homeMember: number
  ) {}
}

export default CounselResult;
