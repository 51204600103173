import axios from "axios";

import { ServiceManagerEntity } from "../models/ServiceManager";
import BaseServiceManager from "../models/BaseServiceManager";
import CaseManager from "../models/CaseManager";
import { plainToInstance } from "class-transformer";
import ServiceList from "../models/ServiceList";
import ServiceDetail from "../models/ServiceDetail";
import { serialize } from "object-to-formdata";
import Service from "../models/ServiceDetail";
import ServiceMenuList from "../models/ServiceMenuList"; 
import ServiceInfo from "../models/ServiceInfo";

/**
 * 작성자: 김혜정
 * 작성일: 2023.02.28
 *
 * 서비스 API
 */

 type GetAllParams = {
  page?: number;
  regiType?: string;
  hdong?: string;
  status?: string;
  searchTarget?: string;
  keyword?: string;
  dptUid?: number;
  spyUid?: number;
};

const getCount = (params: GetAllParams) => {
  return axios.get("/api/serviceProvide/count", { params })
  .then((res) => res.data)
}

// 서비스 목록
const getAll = (params: GetAllParams) => {
  return axios
    .get("/api/serviceProvide", { params })
    .then((response) => plainToInstance(ServiceList, response.data));
};

type GetOne = {
  searchType?: string;
  searchKeyword?: string | undefined;
  spyUid?: number;
}

const getOne = (params: GetOne) => {
  return axios
    .get("/api/serviceProvide", { params })
    .then((response) => plainToInstance(ServiceList, response.data));
};

// 서비스 목록(구,동)
type GetServiceMenuParams = {
  page?: number;
  org?: string;
  hdong?: string;
  searchType?: string;
  searchKeyword?: string;
};

const getServiceMenuList = (params: GetServiceMenuParams) => {
  return axios
    .get("/api/serviceProvide/getServiceMenuList", { params })
    .then((response) => plainToInstance(ServiceMenuList, response.data));
};

// 직권 중단
interface suspendServiceParams {
  spyUid: number;
  stopGbn: string;
  stopReason: string;
}

const stop = (params: suspendServiceParams) => {
  return axios
    .post("/api/serviceProvide/suspendService", serialize(params))
    .then((response) => response.data as {success : boolean, message: string});
};


// 서비스 상세
type GetDetailParams = {
  spyUid: number;
};

const getDetail = (params: GetDetailParams) => {
  return axios
    .get("/api/serviceProvide/detail", { params })
    .then((response) => plainToInstance(ServiceDetail, response.data as ServiceDetail));
};

const getDetailItem = (params: GetDetailParams) => {
  return axios
    .get("/api/serviceProvide/detail", { params })
    .then((response) => response.data as Service);
};

interface InsertParams {
  siUid: number;
  serviceManager: string;
}

const insert = (params: InsertParams) => {
  return axios
    .post("/api/service/insert", { params })
    .then((response) => response.data["success"] as boolean);
};

interface UpdateParams extends InsertParams {
  syUid: number;
  siUid: number;
}

const update = (params: UpdateParams) => {
  return axios
    .post("/api/serviceTarget/update", { params })
    .then((response) => response.data["success"] as boolean);
};

// 담당자 검색
interface FindManagersByNameParams {
  name: string;
  dptUid: number;
}

export type testType = {
  list: CaseManager[],
  success: boolean,
}

const findManagersByName = (params: FindManagersByNameParams) => {
  return axios
  .get("/api/serviceProvide/findManagers", { params })
  .then((response) => response.data as testType);
};

// 담당자 배정
interface AssignServiceManagerParams {
  spyUid: number;  // 돌봄계획 key
  syUid?: number;
  //siUid: number;   //서비스 key
  mbrUid: number; 
}

const assignManager = (params: AssignServiceManagerParams) => {
  return axios
    .post("/api/serviceProvide/updateItem", serialize(params))
    .then((response) => response.data["success"] as boolean);
};

interface InsertScheduleParams {
  syUid: number;
  stUid: number;
  mbrUid: number;
  trgterNm: string;
  trgterCd: string;
  brthdy: string;
  gender: string;
  telno: string;
  adres: string;
  hdong: string;
  lat: number;
  lng: number;
  counselDate: string;
}

const insertSchedule = (params: InsertScheduleParams) => {
  return axios
    .post("/api/serviceTarget/counselScheduleRegist", { params })
    .then((response) => response.data["success"] as boolean);
};

interface UpdateScheduleParams extends InsertScheduleParams {
  csUid: number;
}

const updateSchedule = (params: UpdateScheduleParams) => {
  return axios
    .post("/api/serviceTarget/counselScheduleRegist", { params })
    .then((response) => response.data["success"] as boolean);
};

export interface RegistParams {
  spyUid: number,
  siUid: number,
  serviceDetail: string,
  stopYn: "Y" | "N",
  stopRsn1? : string,
  stopRsn?: string,
  providerOpinion: string,
  serviceStatCd: string,
}

const resultRegist = (params: RegistParams) => {
  return axios.post("/api/serviceProvide/resultRegist", serialize(params))
  .then((res) => res.data.success as boolean)
}

export interface EditCost {
  spiUid: number,
  updCost: number,
  updRsn: string,
}

const editCost = (params: EditCost) => {
  return axios.post("/api/serviceProvide/editCost", serialize(params))
  .then((res) => res.data.success as boolean)
}

const getServiceInfo = () => {
  return axios.get("/api/serviceProvide/getServiceInfo")
  .then((res) => res.data as {serviceInfo : ServiceInfo[]})
}




const ServiceAPI = {
  getAll,
  insert,
  update,
  assignManager,
  insertSchedule,
  updateSchedule,
  findManagersByName,
  getDetail,
  getCount,
  getOne,
  getDetailItem,
  resultRegist,
  getServiceMenuList,
  stop,
  editCost,
  getServiceInfo,
};

export default ServiceAPI;
export type { GetAllParams };
