import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import PlusIcon from "../../../assets/ic_plus.png";
import DataTable, {
  createButtonCell,
  createRow,
  createTextCell,
} from "../../../components/common/DataTable";
import SearchBar, {
  SearchField,
  SearchFormInput,
} from "../../../components/common/SearchBar";
import { useDialog } from "../../../dialogs/DialogProvider";
import EvaluationList, { EvaluationStatusValues } from "../../../models/EvaluationList";
import EvaluationAPI from "../../../apis/EvaluationAPI";
import ResultEvalPage from "./ResultEvalPage";
import AfterCareDialog from "../../../dialogs/evaluation/AfterCareDialog";
import { ServiceTargetYearHdong } from "../../../models/ServiceTargetYear";
import MemberAPI from "../../../apis/MemberAPI";
import useMember from "../../../hooks/useMember";
import SettingAPI, { ServiceSelect } from "../../../apis/SettingAPI";
import Member from "../../../models/Member";
import ResultEvalDialog from "../../../dialogs/evaluation/ResultEvalDialog";

export type SearchEvaluationParam = {
  hdong? : string
  serviceNm? : string
  resultEvalStatus? : string     
  searchType?: string
  searchKeyword?: string 
  trgterNm?: string
  trgterCd?: string
  telno?: string
  adres?: string
  keyword?: string
}

const EvaluationPage = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const [evaluationList, setevaluationList] = useState([] as EvaluationList[]);
  const [searchParam, setSearchParam] = useState({} as SearchFormInput)
  const [count, setCount] = useState(0);
  const [departList, setDepartList] = useState([] as string[])
  const {member} = useMember()
  const [serviceInfoList, setServiceInfoList] = useState([] as ServiceSelect[])


  // 평가/사후관리 목록 불러오기
  useEffect(() => {
    MemberAPI.getDeptList({deptCd: "DONG"}).then((res) => {
      let tempList:string[] = []
      res.list.map((item) => {
        tempList.push(item.departName)
      })
      setDepartList(tempList)
    })
    SettingAPI.getServiceSelect().then((res) => {
      setServiceInfoList(res)
    })
    if(member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU") {  //시, 구
      EvaluationAPI.getAll({
        page: Number(page),
        ...searchParam,
      }).then((evaluationList) => {
        // console.log(evaluationList);
        setevaluationList([...evaluationList])
        if(evaluationList.length !== 0) {
          setCount(evaluationList[0].totalCount)
        } else {
          setCount(0)
        }
      });
    } else {  //동
      EvaluationAPI.getAll({
        page: Number(page),
        ...searchParam,
        hdong: member?.depart?.departName,
      }).then((evaluationList) => {
        // console.log(evaluationList);
        setevaluationList([...evaluationList])
        if(evaluationList.length !== 0) {
          setCount(evaluationList[0].totalCount)
        } else {
          setCount(0)
        }
      });
    }
    
  }, [page, searchParam]);

  const handleSearch = (data: SearchFormInput) => {
    let parsed: SearchEvaluationParam = data
    // console.log(parsed);
    let params: SearchEvaluationParam = {}
    if(parsed.hdong?.length !== 0) {
      params.hdong = parsed.hdong
    }
    if(parsed.serviceNm?.length !== 0) {
      params.serviceNm = parsed.serviceNm
    }
    if(parsed.resultEvalStatus?.length !== 0) {
      params.resultEvalStatus = parsed.resultEvalStatus
    }
    if(parsed.searchType?.length !== 0 && parsed.keyword?.length !== 0) {
      params.searchType = parsed.searchType
      params.searchKeyword = parsed.keyword
    };
  setSearchParam(params)  
};

  const handlePageChange = (page: number) => {
    navigate(`/evaluation/${page}`);
  };

  const searchFields: SearchField[] = member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" ? [
    {
      title: "행정동",
      name: "hdong",
      type: "select",
      items: departList.map((label) => ({ label })),
    },
    {
      title: "서비스 종류",
      name: "serviceNm",
      type: "select",
      items: serviceInfoList.map((info) => ({ label: info.serviceNm, value: info.siUid })),
    },
    {
      title: "상태",
      name: "resultEvalStatus",
      type: "select",
      items: EvaluationStatusValues.map((item) => ({ label: item.label, value: item.value}))
    },
    {
      title: "검색어",
      name: "searchType",
      type: "search",
      items: [
        { label: "성명", value: "trgterNm" },
        { label: "성명+생년월일", value: "brthdy" },
        { label: "연락처", value: "telno" },
        { label: "주소", value: "adres" },
      ],
    },
  ] :
  [
    {
      title: "서비스 종류",
      name: "serviceNm",
      type: "select",
      items: serviceInfoList.map((info) => ({ label: info.serviceNm, value: info.siUid })),
    },
    {
      title: "상태",
      name: "resultEvalStatus",
      type: "select",
      items: EvaluationStatusValues.map((item) => ({ label: item.label, value: item.value}))
    },
    {
      title: "검색어",
      name: "searchType",
      type: "search",
      items: [
        { label: "성명", value: "trgterNm" },
        { label: "성명+생년월일", value: "brthdy" },
        { label: "연락처", value: "telno" },
        { label: "주소", value: "adres" },
      ],
    },
  ];

  if (!member) {
    return <Navigate to="/login" />;
  }

  return (
    <main>
      <section>
        <SearchBar fields={searchFields} onSubmit={handleSearch} />
        <TableTop count={count}/>
        <Table 
          list={evaluationList} 
          page={Number(page)}
          totalPageCount={Math.ceil(count / 10)}
          onPageChange={handlePageChange}
          member={member}
        />
      </section>
    </main>
  );
};

// 테이블 상단
const TableTop = ({count} : {count:number}) => {
  return(
    <article className="list_top">
      <div className="total">
        <p>
          총 <span>{count}</span>건
        </p>
      </div>
    </article>
  );
};

/**
 * 테이블
 * @param list 평가/사후관리 목록
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
const Table = ({
   list,
   page,
   totalPageCount,
   onPageChange, 
   member,
  }: { 
    list: EvaluationList[];
    page: number;
    totalPageCount: number;
    onPageChange: (page: number) => void;
    member: Member;
   }) => {
    const { showDialog } = useDialog();
    const navigate = useNavigate();

    // 사후관리
    const handleAfterCare = (evaluationInfo: EvaluationList, serviceStatCd:string, statCd: string) => {
      if(statCd === "사례종결" || statCd === "재수립") {
        return
      }
      if(serviceStatCd === "서비스제공완료") {
        showDialog(AfterCareDialog, {
          spyUid: evaluationInfo.spyUid,
        });
      } else {
          // api 바로 부르고 processId 2로 보냄
          if(window.confirm("사례종결처리 하시겠습니까?\n(해당 대상자의 사례가 전부 종결처리됩니다.)")) {
            EvaluationAPI.insertAfterCare({
              spyUid : evaluationInfo.spyUid, 
              processId:2
            }).then((res) => {
              window.alert("사례 종결처리 완료")
            })
          } else {
            return
          }
      }
  };


  const handleResultEval = (evaluationInfo: EvaluationList) => {
    const params = new URLSearchParams({
      spyUid: `${evaluationInfo.spyUid}`,
      syUid: `${evaluationInfo.syUid}`,
    })
    switch(evaluationInfo.resultEvalStatus ) {
      case "평가필요":
        navigate(`/evaluation/resultEval?${params}`);
        break;
      case "평가완료":
        showDialog(ResultEvalDialog, {
          spyUid: evaluationInfo.spyUid,
        })
    }
  };

  return (
    <DataTable
      columnTitles={[
        "행정동",
        "서비스 종류",
        "성명",
        "생년월일",
        "성별",
        "연락처",
        "주소",
        "서비스 결과",
        "상태",
        "서비스 평가일",
        "사후관리",
        "",
      ]}        
      rows={member.depart?.deptCd === "SI" || member.depart?.deptCd === "DONG" ?    //동
        list.map((evaluationInfo) =>
          createRow({
            cells: [
              createTextCell({ text: evaluationInfo.hdong }),
              createTextCell({ text: evaluationInfo.serviceNm }),
              createTextCell({ text: evaluationInfo.trgterNm }),
              createTextCell({ text: evaluationInfo.brthdy }),
              createTextCell({ text: evaluationInfo.gender }),
              createTextCell({ text: evaluationInfo.telno }),
              createTextCell({
                text: evaluationInfo.fullAddress,
                ellipsis: true,
              }),
              createTextCell({ text: evaluationInfo.serviceStatCd }),// 서비스 결과
              createTextCell({ text: evaluationInfo.resultEvalStatus }), // 상태
              createButtonCell({  // 서비스 평가일
                type:
                  evaluationInfo.resultEvalStatus === "평가불필요"
                    ?undefined
                    :evaluationInfo.resultEvalStatus === "평가필요"
                    ? "outlined"
                    : "underlined",
                label: evaluationInfo.resultEvalStatus === "평가불필요"
                  ? "-"
                  : evaluationInfo.resultEvalStatus === "평가필요"
                  ? "평가"
                  : `${moment(evaluationInfo.evalDt).format("yyyy-MM-DD(dd)")}`,
                onClick: () => handleResultEval(evaluationInfo),
              }),
              // createButtonCell({ // 사후관리
              //       type: evaluationInfo.processId === undefined || evaluationInfo.serviceStatCd === "서비스제공완료"? 
              //           evaluationInfo.serviceStatCd === "서비스제공완료"
              //             ? "outlined"
              //             : "outlined"
              //         : undefined,  
              //       label: evaluationInfo.processId === undefined || evaluationInfo.serviceStatCd === "서비스제공완료"? 
              //           evaluationInfo.serviceStatCd === "서비스제공완료"
              //             ? "사후관리"
              //             : "종결처리"
              //         : `${evaluationInfo.processId}`,    
              //       onClick: ()=> handleAfterCare(evaluationInfo, evaluationInfo.serviceStatCd),
              //     }),
                  createButtonCell({ // 사후관리
                    type: evaluationInfo.providedCnt !== undefined && evaluationInfo.providedCnt2 !== undefined &&
                    evaluationInfo.providedCnt + evaluationInfo.providedCnt2 === 0 && evaluationInfo.totalEvalCount - evaluationInfo.currentEvalCount === 0
                          ? evaluationInfo.statCd !== "사례종결" && evaluationInfo.statCd !== "재수립" ?
                           "outlined"
                          : "underlined" : undefined,
                    label: evaluationInfo.providedCnt !== undefined && evaluationInfo.providedCnt2 !== undefined &&
                    evaluationInfo.providedCnt + evaluationInfo.providedCnt2 === 0 && evaluationInfo.totalEvalCount - evaluationInfo.currentEvalCount === 0
                          ? evaluationInfo.statCd !== "사례종결" && evaluationInfo.statCd !== "재수립" ?
                          evaluationInfo.serviceStatCd === "서비스제공완료" ? "사후관리" : "사례종결" : evaluationInfo.statCd
                      : `-`,    
                    onClick: ()=> handleAfterCare(evaluationInfo, evaluationInfo.serviceStatCd, evaluationInfo.statCd),
                  }),
            ],
          })
        )
      : //구
        list.map((evaluationInfo) =>
          createRow({
            cells: [
              createTextCell({ text: evaluationInfo.hdong }),
              createTextCell({ text: evaluationInfo.serviceNm }),
              createTextCell({ text: evaluationInfo.trgterNm }),
              createTextCell({ text: evaluationInfo.brthdy }),
              createTextCell({ text: evaluationInfo.gender }),
              createTextCell({ text: evaluationInfo.telno }),
              createTextCell({
                text: evaluationInfo.fullAddress,
                ellipsis: true,
              }),
              createTextCell({ text: evaluationInfo.serviceStatCd }),// 서비스 결과
              createTextCell({ text: evaluationInfo.resultEvalStatus }), // 상태
              createButtonCell({  // 서비스 평가일
                type:
                  evaluationInfo.resultEvalStatus === "평가완료"
                    ? "underlined"
                    : undefined
                    ,
                label: evaluationInfo.resultEvalStatus === "평가완료"
                  ? `${moment(evaluationInfo.evalDt).format("yyyy-MM-DD(dd)")}`
                  : "-",
                onClick: () => handleResultEval(evaluationInfo),
              }),
              // createButtonCell({ // 사후관리
              //       type: evaluationInfo.processId === undefined || evaluationInfo.serviceStatCd === "서비스제공완료"? 
              //           evaluationInfo.serviceStatCd === "서비스제공완료"
              //             ? "outlined"
              //             : "outlined"
              //         : undefined,  
              //       label: evaluationInfo.processId === undefined || evaluationInfo.serviceStatCd === "서비스제공완료"? 
              //           evaluationInfo.serviceStatCd === "서비스제공완료"
              //             ? "사후관리"
              //             : "종결처리"
              //         : `${evaluationInfo.processId}`,    
              //       onClick: ()=> handleAfterCare(evaluationInfo, evaluationInfo.serviceStatCd),
              //     }),
                  // createButtonCell({ // 사후관리
                  //   type: evaluationInfo.providedCnt !== undefined && evaluationInfo.providedCnt === 0
                  //         ? "outlined"
                  //         : undefined,
                  //   label: evaluationInfo.providedCnt !== undefined && evaluationInfo.providedCnt === 0
                  //         ? "사후관리"
                  //     : `${evaluationInfo.processId}`,    
                  //   onClick: ()=> handleAfterCare(evaluationInfo, evaluationInfo.serviceStatCd),
                  // }),
                  createTextCell({ text: evaluationInfo.processId ? evaluationInfo.processId === 1 ? "재수립" : "사례종결" : "-" }),
            ],
          })
        )
      }
      page={page}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
    />
  );
};
export default EvaluationPage;
