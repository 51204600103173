import axios from "axios";
import { plainToInstance } from "class-transformer";
import { serialize } from "object-to-formdata";
import MoveOutList from "../models/MoveOutList";
import ServicePlanDetails from "../models/ServicePlanDetails";
import ServiceTargetYear from "../models/ServiceTargetYear";
import SprtList from "../models/SprtList";
import SprtMoreData from "../models/SprtMoreData";
import SprtMoreInfo from "../models/SprtMoreInfo";

type GetAllParams = {
  syUid?: number;
  stUid?: number,
  page?: number;
  sbscWay?: string;
  sbscWay2?: string;
  sbscWay3?: string;
  hdong?: string;
  statCd?: string;
  hing?: string;
  searchTarget?: string;
  keyword?: string;
  trgterCd?: string;
  searchType?: string;
  searchKeword?: string;
};

interface InsertParams {
  syUid?: number;
  caseContent?: string;
  sprtMoreNssr?: string;
  sprtMoreUse?: string;
  expnNss?: string;
  expnUse?: string;
  mtOpnn?: string;
  cfmYn?: string;
  cfmRsn?: string;
}
interface Keyword {
  keyword: string;
}
// 데이터 불러오기
const getSprtList = (params: GetAllParams) => {
  return axios
  .get("/api/sprtMore/sprtList", {params})
  .then((response) =>
  plainToInstance(SprtList, response.data));
}
const getMoveOutList = (params: GetAllParams) => {
  return axios
  .get("/api/moveOut/MoveOutList", {params})
  .then((response) => 
  plainToInstance(MoveOutList, response.data));
}
const getTargetList = (params: Keyword) => {
  return axios
  .get("/api/serviceTarget/findTarget", {params})
  .then((response) => 
  plainToInstance(ServiceTargetYear, response.data as ServiceTargetYear[]));
} 
/**추가지원 대상 단일조회 */
const getSprtMoreData = (params: GetAllParams) => {
  return axios
  .get("/api/sprtMore/getData", {params})
  .then((response) => 
    response.data as SprtMoreData);
}

// 등록
const insert = (params: InsertParams) => {
  // console.log(params);
  return axios
    .post("/api/sprtMore/insert", serialize(params))
    .then((response) => response.data["success"] as boolean);
};
// 업데이트
const update = (params : InsertParams) => {
  // console.log(params);
  return axios
  .post("/api/sprtMore/update", serialize(params))
  .then((response) => response.data["success"] as boolean);
}

/**
 * 이전 추가지원 내역 확인
 */
const pastSprtHistory = (params: GetAllParams) => {
  return axios.get("/api/sprtMore/pastSprtHistory", { params })
  .then((res) => res.data as {success: boolean, message: string})
}


const DollbomPlanSupportAPI = {
  insert,
  update,
  getTargetList,
  getSprtList,
  getMoveOutList,
  getSprtMoreData,
  pastSprtHistory,
};

export default DollbomPlanSupportAPI;
