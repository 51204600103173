import React, { useEffect, useState, useRef } from "react";
import "./SettingsPage.scss";
import PlusIcon from "../../../assets/ic_plus.png";
import { useDialog } from "../../../dialogs/DialogProvider";
import ProviderRegisterDialog from "../../../dialogs/setting/ProviderRegisterDialog";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import DataTable, {
  createButtonCell,
  createRow,
  createTextCell,
} from "../../../components/common/DataTable";
import SettingList, { OperTypeValues, OrgdeptCdValues } from "../../../models/SettingList";
import SettingAPI from "../../../apis/SettingAPI";
import SearchIcon from "../../../assets/ic_search.png";
import SearchBar, {
  SearchField,
  SearchFormInput,
} from "../../../components/common/SearchBar";
import useMember from "../../../hooks/useMember";
import Member from "../../../models/Member";

// 환경설정 메뉴 페이지 > 제공기관 관리

export type SearchProviderParam = {
  operType?: string
  departName?: string
  tel?: string
  locality?: string
  hing?: string
  keyword?: string
}

const SettingsTab2Page = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const [providerList, setProviderList] = useState([] as SettingList[]);
  const [count, setCount] = useState(0);
  const [searchParam, setSearchParam] = useState({} as SearchFormInput)
  const { member } = useMember()

  // 목록 불러오기
 useEffect(() => {
  SettingAPI.getAllProvider({
    page: Number(page),
    ...searchParam
  }).then((providerList) => {
    // console.log(providerList);  
    setProviderList([...providerList])
    setCount(providerList[0].totalCount)
  });
}, [page, searchParam]);

const handleSearch = (data: SearchFormInput) => {
  let parsed:SearchProviderParam = data
    console.log(parsed)
    let params:SearchProviderParam = {}
    if(parsed.operType?.length !== 0) {
      params.operType = parsed.operType
    }
    if(parsed.hing?.length !== 0 && parsed.keyword?.length !== 0) {
      switch(parsed.hing) {
        case "departName" :
          params.departName = parsed.keyword
          break
        case "tel" :
          params.tel = parsed.keyword
          break
        case "locality" :
          params.locality = parsed.keyword
          break
      }
    }
    setSearchParam(params)
  };


const handlePageChange = (page: number) => {
  navigate(`/setting-org-mng/${page}`);
};

  return(
    <main>
      <section>
        <div className="tab_content" data-id={1}>
          <SearchBar fields={searchFields} onSubmit={handleSearch} />
          <TableTop2 count={count} member={member!}/>
          <Table2
            list={providerList}
            page={Number(page)}
            totalPageCount={Math.ceil(count / 10)}
            onPageChange={handlePageChange}
            member={member!}
          />
        </div>
      </section>
    </main>
    
  );
}

/**
 * 검색 필드
 */
 const searchFields: SearchField[] = [
  {
    title: "운영형태",
    name: "operType",
    type: "select",
    items: OperTypeValues.map((label) => ({ label })),
  },
  {
    title: "검색어",
    name: "hing",
    type: "search",
    items: [
      { label: "기관명", value: "departName" },
      { label: "연락처", value: "tel" },
      { label: "소재지", value: "locality" },
    ],
  },
];

const TableTop2 = ({count , member} : {count:number, member:Member}) => {
  const { showDialog } = useDialog();

  const handleProviderRegisterClick = () => {

    showDialog(ProviderRegisterDialog);
 };

  return(
    <article className="list_top">
      <div className="total">
        <p>
          총 <span>{count}</span>건
        </p>
      </div>
      {(member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU") &&
      <div className="list_btn_wrap">
        <button
          type="button"
          className="recive"
          onClick={handleProviderRegisterClick}
        >
          <img src={PlusIcon} alt="접수 아이콘" />
          기관등록
        </button>
      </div>
      }
    </article>
  );
}

/**
 * 테이블
 * @param list 기관 목록
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
 const Table2 = ({
  list,
  page,
  totalPageCount,
  onPageChange,
  member,
}: {
  list: SettingList[];
  page: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
  // onProviderDelete: () => void;
  member: Member
}) => {

  const navigate = useNavigate();

// 삭제
    const handleProviderDelete = async (dptUid:number) => {
      // alert(dptUid);
      if(window.confirm("삭제하시겠습니까?")){
        SettingAPI.deleteProvider({
          dptUid
        }).then((ref) => {
          alert("삭제 완료");
          // navigate(0);
        });
      } else{
        alert("삭제 취소");
      }
    };

    const { showDialog } = useDialog();

    const handleProviderUpdateClick = (providerInfo: SettingList) => {
      showDialog(ProviderRegisterDialog, {
        providerInfo: providerInfo,
      })
    };

  return (
    <DataTable
      columnTitles={[
        "기관구분",
        "기관명",
        "연락처",
        "소재지",
        "사업자등록번호",
        "운영형태",
        "서비스",
        "상태",
        "",
      ]}
      rows={list.map((providerInfo) =>
        createRow({
          cells: [
            createTextCell({ text: providerInfo.deptCd }),
            createTextCell({ text: providerInfo.departName }),
            createTextCell({ text: providerInfo.tel }),
            createTextCell({ text: providerInfo.locality }),
            createTextCell({ text: providerInfo.companyNo }),
            createTextCell({ text: providerInfo.operType }),
            createTextCell({ text: providerInfo.service }),
            createTextCell({ text: providerInfo.status }),
            createButtonCell({
              type: member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ? "outlined" : undefined,
              label: "수정",
              onClick: () => handleProviderUpdateClick(providerInfo),
            }),
            // createButtonCell({
            //   type: "outlined",
            //   label: "삭제",
            //   onClick: () => handleProviderDelete(providerInfo.dptUid),
            // }),
            
          ],
        })
      )}
      page={page}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
    />
  );
};

export default SettingsTab2Page;
