import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ServiceAPI from "../../apis/ServiceAPI";

import ServiceTargetAPI from "../../apis/ServiceTargetAPI";
import CloseIcon from "../../assets/ic_close.png";
import useMember from "../../hooks/useMember";
import { DialogProps } from "../DialogProvider";


// 직권중단 팝업( 2023.04.10기준, api 아직, 중단사유도 확실하지 않음, 나중에 추가되면 붙이기)

interface OfficialPowerStopDialogProps extends DialogProps {
  spyUid: number;
}

interface OfficialPowerStopFormInput {
  stopGbn: string;
  stopReason: string;
}

const OfficialPowerStopDialog = ({
  spyUid,
  ...props
}: OfficialPowerStopDialogProps) => {
  const navigate = useNavigate();
  const { member } = useMember();
  const { register, handleSubmit } = useForm<OfficialPowerStopFormInput>();

  const handleClose = () => {
    props.onClose?.();
  };


  const onSubmit: SubmitHandler<OfficialPowerStopFormInput> = async (data) => {
    console.log(data);
    // TODO: 유효성 검사

    const success = await ServiceAPI.stop({ spyUid, ...data });

    if (success.success) {
      window.alert(success.message)
      navigate(0);
    } else {
      window.alert(success.message)
    }
  };

  return (
    <div className="stop_popup popup_black" {...props}>
      <div className="stop_popup_inner popup_white">
        <div className="popup_tit">
          <h3>직권중단</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="default_input">
              <h4 style={({fontWeight: 600})}>중단사유</h4>
              <div className="write_box">
                <select className="essential" {...register("stopGbn")}
                style={{ color: 'rgb(0, 0, 0)', height:40, border: '1px solid #e6e8ea', backgroundColor: '#f6f7f9', fontSize:'15',
                borderRadius: '5px', width: "340px"}} >
                  <option value="">선택</option>
                  <option value="이사">전출</option>
                  <option value="입원">입원</option>
                  <option value="사망">사망</option>
                  <option value="시설입소">시설입소</option>
                  <option value="계획변경">계획변경</option>
                  <option value="거부">거부</option>
                </select>
                <textarea 
                  placeholder="중단사유 입력"
                  className="mt8"
                  {...register("stopReason")}
                ></textarea>
              </div>
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="submit" className="submit">
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OfficialPowerStopDialog;
