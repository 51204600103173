import ConferencePlan from "../../../../models/ConferencePlan";
import DollbomConfirmationFormInput from "../../../../models/form/DollbomConfirmationFormInput";

const InsertPlan = (
    basicList: HTMLInputElement | null,
    basicDetail: HTMLInputElement | null,
    emerDay: HTMLInputElement | null,
    emerBeginDt: HTMLInputElement | null,
    emerEndDt: HTMLInputElement | null,
    emerTime: HTMLInputElement | null,
    emerCost: HTMLInputElement | null,
    rows: NodeListOf<HTMLTableRowElement>,
    emerSiList: ConferencePlan[],
    basicSiList: ConferencePlan[],
    gjSiList: ConferencePlan[],
) => {
    let data: DollbomConfirmationFormInput[] = []
    if (basicList?.value || basicDetail?.value) {
        //기존돌봄 필수인 경우
        // if (basicList?.value && !basicDetail?.value) {
        //   alert('기존돌봄 "사유" 를 입력해주세요.');
        //   return;
        // } else if (!basicList?.value && basicDetail?.value) {
        //   alert('기존돌봄 "서비스" 를 선택해 주세요.');
        //   return;
        // }
        const basicService = {
          siUid: basicSiList[0].siUid,
          serviceDetail: `${basicList?.value}/${basicDetail?.value}`,
          cost: 0,
          stopYn: "N",
        };
        data.push(basicService);
      }
      if (
        emerDay?.value ||
        emerBeginDt?.value ||
        emerEndDt?.value ||
        emerTime?.value
      ) {
      //긴급돌봄 지원일수 및 시간만 필수로 변경
      if (emerDay?.value && !emerTime?.value) {
        alert('긴급돌봄 "시간" 을 입력해 주세요.');
        return;
      } else if (!emerDay?.value && emerTime?.value) {
        alert('긴급돌봄 "지원일수" 을 입력해 주세요.');
        return;
      }
      //기존 필수여부
      // if (emerDay?.value && !emerBeginDt?.value) {
      //   alert('긴급돌봄 "지원시작일" 을 선택해 주세요.');
      //   return;
      // } else if (!emerDay?.value && emerBeginDt?.value) {
      //   alert('긴급돌봄 "지원일수" 를 입력해 주세요.');
      //   return;
      // } else if (emerDay?.value && !emerEndDt?.value) {
      //   alert('긴급돌봄 "지원종료일" 을 선택해 주세요.');
      //   return;
      // } else if (emerDay?.value && !emerTime?.value) {
      //   alert('긴급돌봄 "시간" 을 입력해 주세요.');
      //   return;
      // }
        const emerService = {
          siUid: emerSiList[0].siUid,
          beginDate: emerBeginDt?.value,
          endDate: emerEndDt?.value,
          totalCount: Number(emerDay?.value),
          times: Number(emerTime?.value),
          cost: Number(emerCost?.value.replace(/,/g, "")),
          stopYn: "N",
        };
        data.push(emerService);
    }

    rows.forEach((row) => {
        const cols = row.querySelectorAll<HTMLTableCellElement>("td");
        const serviceDetail = cols[1].textContent?.trim() || "";
        const siUid = row.querySelector<HTMLInputElement>(".siUid");
        const cycleUnit = row.querySelector<HTMLInputElement>(".cycleUnit");
        const weekday = row.querySelector<HTMLInputElement>(".weekday");  //요일
        const date = cols[2].textContent?.trim() || "";
        const cateCd = Number(
            row.querySelector<HTMLInputElement>(".cateCd")?.value
        );
        let beginDateEdit:string | undefined
        let endDateEdit:string | undefined

        if(date === "-") {  //-가 들어간 경우
            beginDateEdit = undefined
            endDateEdit = undefined
        } else {
            beginDateEdit = date.split('~')[0]
            endDateEdit = date.split('~')[1]
        }
        //cateCd === 1 (가사지원)
        if(cateCd === 1) {
            // 슬래시로 분할했을 때 첫번째 => 횟수, 마지막 => 시간
            let totCount = Number(cols[1].textContent?.split('/')[0].replace("회", ""))
            let contLength = cols[1].textContent?.split('/').length
            let provideTime = Number(cols[1].textContent?.split('/')[contLength! - 1].replace("시간", ""))
            //선택요일
            let selectedDays:string|undefined
            //선택주기
            let selectedCycle:string|undefined
            // console.log("제공횟수 : ", totCount)
            // console.log("제공시간 : ", provideTime)
            if(contLength === 3) {  //주기 혹은 요일 선택 시
            //요일 없으면 weekday 텍스트 undefined임
            if(weekday?.textContent) {  //요일선택됨
                selectedDays = weekday?.textContent.replaceAll(",", "")
            } else {  //주기, 배열의 중간값
                selectedCycle = cols[1].textContent?.split('/')[1]
            }
            }
            if(contLength === 4) {  //주기 요일 모두 선택 시
            if(weekday?.textContent) {  //요일선택됨
                selectedDays = weekday?.textContent.replaceAll(",", "")
            } 
            selectedCycle = cols[1].textContent?.split('/')[1]
            }
            const obj = {
            siUid: Number(siUid?.value),
            beginDate: beginDateEdit,
            endDate: endDateEdit,
            totalCount: totCount,
            times: provideTime,
            cost: Number(
                cols[3].textContent?.split("원")[0].trim().replace(/,/g, "") || ""
            ),
            serviceDetail: undefined,
            stopYn: "N",
            };
            data.push(obj)
        } else if(cateCd === 2) {//cateCd === 2 (식사지원)
            // 슬래시로 분할했을 때 첫번째 => 횟수
            let totCount = Number(cols[1].textContent?.split('/')[0].replace("회", ""))
            let contLength = cols[1].textContent?.split('/').length
            //선택요일
            let selectedDays:string|undefined
            //선택주기
            let selectedCycle:string|undefined
            if(contLength === 2) {  //주기 혹은 요일 선택 시
            //요일 없으면 weekday 텍스트 undefined임
            if(weekday?.textContent) {  //요일선택됨
                selectedDays = weekday?.textContent.replaceAll(",", "")
            } else {  //주기, 배열의 중간값
                selectedCycle = cols[1].textContent?.split('/')[1]
            }
            }
            if(contLength === 3) {  //주기 요일 모두 선택 시
            if(weekday?.textContent) {  //요일선택됨
                selectedDays = weekday?.textContent.replaceAll(",", "")
            } 
            selectedCycle = cols[1].textContent?.split('/')[1]
            }
            const obj = {
            siUid: Number(siUid?.value),
            beginDate: beginDateEdit,
            endDate: endDateEdit,
            totalCount: totCount,
            times: 1,
            cost: Number(
                cols[3].textContent?.split("원")[0].trim().replace(/,/g, "") || ""
            ),
            serviceDetail: undefined,
            stopYn: "N",
            };
            data.push(obj)
        } else if(cateCd === 3) {//cateCd === 3 (동행지원)
            // 슬래시로 분할했을 때 첫번째 => 횟수
            let totCount = Number(cols[1].textContent?.split('/')[0].replace("회", ""))
            let contLength = cols[1].textContent?.split('/').length
            //상세내용
            let detailCont:string | undefined
            if(contLength === 2) {  //상세내용 입력 시
            detailCont = cols[1].textContent?.split('/')[1]
            }
            const obj = {
            siUid: Number(siUid?.value),
            beginDate: beginDateEdit,
            endDate: endDateEdit,
            totalCount: totCount,
            times: 1,
            cost: Number(
                cols[3].textContent?.split("원")[0].trim().replace(/,/g, "") || ""
            ),
            serviceDetail: detailCont,
            stopYn: "N",
            };
            data.push(obj)
        } else if(cateCd === 4 || cateCd === 7) {//cateCd === 4,7 (건강지원, 일시보호)
            // 슬래시로 분할했을 때 첫번째 => 횟수
            let totCount = Number(cols[1].textContent?.replace("회", ""))
            const obj = {
            siUid: Number(siUid?.value),
            beginDate: beginDateEdit,
            endDate: endDateEdit,
            totalCount: totCount,
            times: undefined,
            cost: Number(
                cols[3].textContent?.split("원")[0].trim().replace(/,/g, "") || ""
            ),
            serviceDetail: undefined,
            stopYn: "N",
            };
            data.push(obj)
        } else {  //남은거
            let detailCont:string | undefined = cols[1].textContent?.replace("회", "") ?? undefined
            const obj = {
            siUid: Number(siUid?.value),
            beginDate: beginDateEdit,
            endDate: endDateEdit,
            totalCount: 1,
            times: 1,
            cost: Number(
                cols[3].textContent?.split("원")[0].trim().replace(/,/g, "") || ""
            ),
            serviceDetail: detailCont,
            stopYn: "N",
            };
            data.push(obj)
        }

    // if (
        //   !cycleUnit &&
        //   serviceDetail.includes("(") &&
        //   serviceDetail.includes("회")
        // ) {
        //   // console.log("cateCd = 3");
        //   const obj = {
        //     // sdUid: Number(sdUid),
        //     // syUid: Number(syUid),
        //     // stUid: Number(stUid),
        //     siUid: Number(siUid?.value),
        //     // stepCd: "돌봄계획",
        //     // stepCdNext: "사례회의(동)",
        //     // serviceStatCd: "진행",
        //     beginDate: undefined,
        //     endDate: undefined,
        //     totalCount: Number(
        //       cols[1].textContent?.split("회")[0].trim() || ""
        //     ),
        //     times: undefined,
        //     cost: Number(
        //       cols[3].textContent?.split("원")[0].trim().replace(/,/g, "") || ""
        //     ),
        //     serviceDetail: cols[1].textContent?.match(/\(([^)]+)\)/)?.[1] || "",
        //     stopYn: "N",
        //   };
        //   data.push(obj);
        // } else if (
        //   !cycleUnit &&
        //   serviceDetail.includes("회") &&
        //   !serviceDetail.includes("시간")
        // ) {
        //   // console.log("cateCd = 4, 7");
        //   const obj = {
        //     // sdUid: Number(sdUid),
        //     // syUid: Number(syUid),
        //     // stUid: Number(stUid),
        //     siUid: Number(siUid?.value),
        //     beginDate: cols[2].textContent?.split("~")[0].trim() || "",
        //     endDate: cols[2]?.textContent?.split("~")[1]?.trim() || "",
        //     times: undefined,
        //     totalCount: Number(
        //       cols[1].textContent?.split("회")[0].trim() || ""
        //     ),
        //     cost: Number(
        //       cols[3].textContent?.split("원")[0].trim().replace(/,/g, "") || ""
        //     ),
        //     serviceDetail: undefined,
        //     stopYn: "N",
        //   };
        //   data.push(obj);
        // } else if (
        //   cycleUnit &&
        //   serviceDetail.includes("회") &&
        //   serviceDetail.includes("시간")
        // ) {
        //   // console.log("cateCd = 1, 2");
        //   const timesStr: number = parseInt(
        //     cols[1]?.textContent?.match(/(\d+)시간/)?.[1] ?? "0"
        //   );
        //   // console.log(timesStr);
        //   const weekday = row.querySelector<HTMLInputElement>(".weekday");
        //   const obj = {
        //     // sdUid: Number(sdUid),
        //     // syUid: Number(syUid),
        //     // stUid: Number(stUid),
        //     siUid: Number(siUid?.value),
        //     serviceStatCd: "진행",
        //     beginDate: cols[2].textContent?.split("~")[0].trim() || "",
        //     endDate: cols[2]?.textContent?.split("~")[1]?.trim() || "",
        //     times: timesStr,
        //     totalCount: Number(
        //       cols[1].textContent?.match(/\d+(?=회)/)?.[0] || ""
        //     ),
        //     cost: Number(
        //       cols[3].textContent?.split("원")[0].trim().replace(/,/g, "") || ""
        //     ),
        //     serviceDetail: undefined,
        //     cycleUnit: cycleUnit?.value,
        //     weekday: weekday?.value.replace(",", ""),
        //     stopYn: "N",
        //   };
        //   data.push(obj);
        // } else if (serviceDetail.includes("지원시간(내용) 없음")) {
        //   // console.log("지원시간(내용) 없음");
        //   const obj = {
        //     // sdUid: Number(sdUid),
        //     // syUid: Number(syUid),
        //     // stUid: Number(stUid),
        //     siUid: Number(siUid?.value),
        //     beginDate: cols[2].textContent?.split("~")[0].trim() || "",
        //     endDate: cols[2]?.textContent?.split("~")[1]?.trim() || "",
        //     times: undefined,
        //     totalCount: undefined,
        //     cost: Number(
        //       cols[3].textContent?.split("원")[0].trim().replace(/,/g, "") || ""
        //     ),
        //     serviceDetail: undefined,
        //     stopYn: "N",
        //   };
        //   data.push(obj);
        // } else if (cateCd == 5 || cateCd == 6) {
        //   // console.log("cateCd = 5, 6");
        //   const obj = {
        //     // sdUid: Number(sdUid),
        //     // syUid: Number(syUid),
        //     // stUid: Number(stUid),
        //     siUid: Number(siUid?.value),

        //     // serviceStatCd: "진행",
        //     beginDate: undefined,
        //     endDate: undefined,
        //     times: undefined,
        //     // totalCount: Number(cols[1].textContent?.split("회")[0].trim() || ""),
        //     totalCount: 1,
        //     cost: Number(
        //       cols[3].textContent?.split("원")[0].trim().replace(/,/g, "") || ""
        //     ),
        //     serviceDetail: cols[1].textContent?.trim() || "",
        //     stopYn: "N",
        //   };
        //   data.push(obj);
        // }
    });
    return data
};

export default InsertPlan;