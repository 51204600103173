class NeedsAssessmentJsonData {
  constructor(
    public q1_1: string,
    public q1_2: string,
    public q1_3: string,
    public q1_4: string,
    public q1_5: string,
    public q1_6: string,
    public q1_7: string,
    public q1_8: string,
    public q2_1: string,
    public q2_2: string,
    public q2_3: string,
    public q2_4: string,
    public q2_5: string,
    public q2_6: string,
    public q3_1: boolean,
    public q3_2: boolean,
    public q3_3: boolean,
    public q3_4: boolean,
    public q3_5: boolean,
    public q3_6: boolean,
    public q3_7: string,
    public q3_8: boolean,
    public q4_1: string,
    public q4_2: string,
    public q4_3: string,
    public q4_4: string,
    public q4_5: string,
    public q5_1: string,
    public q5_2: string,
    public q5_3: string,
    public q5_4: string,
    public q5_5: string,
    public q6_1: string,
    public q6_2: string,
    public q6_3: string,
    public q6_4: string,
    public q7_1: string,
    public q7_2: string,
    public q7_3: string,
    public q7_4: string,
    public q7_5: string,
    public q7_6: string,
    public q7_7: string,
    public q7_8: string,
    public q8_1: string,
    public q8_2: string,
    public q8_3: string,
    public q8_4: string,
    public q8_5: string,
    public q8_6: string,
    public illAdd: string,
    public g1_score: number, // 광주+돌봄 지원대상 여부
    public g1_content: string,
    public g2_type: string,
    public g2_content: string,
    public g3_type: string,
    public g3_content: string,
    public e1_type: string, // 긴급돌봄 지원대상 여부
    public e1_content: string,
    public e2_score: number,
    public e2_content: string,
    public e3_type: string,
    public e3_content: string,
    public e4_type: string,
    public e4_content: string,
    public bodyAreaPt: number,
    public emtAreaPt: number,
    public gTargetYn: string,  //돌봄지원대상여부
    public eTargetYn: string,  //긴급지원대상여부
    public gSatisfy: string, //돌봄충족여부
    public eSatisfy: string, //긴급충족여부
    public general: string
  ) {}

  get disease(): string[] {
    const arr = [] as string[];
    if (this.q3_1) {
      arr.push("뇌경색");
    }
    if (this.q3_2) {
      arr.push("시력저하");
    }
    if (this.q3_3) {
      arr.push("요통, 관절염");
    }
    if (this.q3_4) {
      arr.push("청력저하");
    }
    if (this.q3_5) {
      arr.push("고혈압, 당뇨");
    }
    if (this.q3_6) {
      arr.push("치매");
    }
    return arr;
  }
}
export default NeedsAssessmentJsonData;
