import { Type } from "class-transformer";
import carePlan from "./ConferencePlan";
import ServiceInfo from "./ServiceInfo";
import ServiceTargetYear from "./ServiceTargetYear";

class ServicePlanDetails {
  @Type(() => ServiceTargetYear)
  public targetInfo: ServiceTargetYear;
  public supportMore: string;
  @Type(() => ServiceInfo)
  public usingGjService: ServiceInfo[];
  @Type(() => ServiceInfo)
  public usingEmerService: ServiceInfo[];
  @Type(() => ServiceInfo)
  public usingBasicService: ServiceInfo[];
  @Type(() => ServiceInfo)
  public basicList: ServiceInfo[];
  @Type(() => ServiceInfo)
  public gjList: ServiceInfo[];
  @Type(() => ServiceInfo)
  public emerList: ServiceInfo[];
  constructor(
    targetInfo: ServiceTargetYear, 
    supportMore: string, 
    usingGjService: ServiceInfo[], 
    usingEmerService: ServiceInfo[] , 
    usingBasicService: ServiceInfo[], 
    basicList: ServiceInfo[], 
    gjList: ServiceInfo[], 
    emerList: ServiceInfo[],
) {
    this.targetInfo = targetInfo
    this.supportMore = supportMore
    this.usingGjService = usingGjService
    this.usingEmerService = usingEmerService
    this.usingBasicService = usingBasicService
    this.basicList = basicList
    this.gjList = gjList
    this.emerList = emerList
  }  

  get usingGjServiceNames() {
    return this.usingGjService.map(e => e.serviceNm).join(', ');
  }

  get usingEmerServiceNames() {
    return this.usingEmerService.map(e => e.serviceNm).join(', ');
  }

  get usingBasicServiceNames() {
    return this.usingBasicService.map(e => e.serviceNm).join(', ');
  }
}

export default ServicePlanDetails;
