import React, { useEffect, useState } from "react";

import CloseIcon from "../assets/ic_close.png";
import DefaultProfileImage from "../assets/img_default.png";
import FileUtil from "../utils/FileUtil";
import { DialogProps } from "./DialogProvider";

/**
 * 작성자: 홍준표
 * 작성일: 2023.05.23
 *
 * 프로필 이미지 팝업
 */

interface ProfileImageDialogProps extends DialogProps {
  image? : File,
}

const ProfileImageDialog = ({image, ...props} : ProfileImageDialogProps) => {
  const [path, setPath] = useState<string>()
  useEffect(() => {
    if(image) {
      FileUtil.getFilePath(image).then(setPath)
    }
  }, [])

  const handleClose = () => {
    props.onClose?.();
  };


  const handleImageError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    e.currentTarget.src = DefaultProfileImage;
  };

  return (
    <div className="mypage_popup popup_black" {...props}>
      <div className="mypage_popup_inner popup_white">
        <div className="popup_tit">
          <h3></h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form>
            <div className="info_img">
              <div className="img_big_wrap">
                <div className="img_big">
                  <img
                    src={path ?? DefaultProfileImage}
                    alt="프로필 이미지"
                    onError={handleImageError}
                  />
                </div>
              </div>
            </div>                        
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileImageDialog;
