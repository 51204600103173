import React from 'react';
import DongConference from '../../../../models/DongConference';

const CarePlanTable = (
    {
        basicBoolean,
        basicValue,
        basicValueInfo,
        emerBoolean,
        emerCount,
        emerBeginDt,
        emerEndDt,
        emerTime,
        emerCost,
        gjBoolean,
        carePlan,
        gjChange,
        totalCost,
        emerChange,
        isSprtMore,
    }
    :
    {
        basicBoolean: boolean,
        basicValue: string,
        basicValueInfo: string,
        emerBoolean: boolean,
        emerCount: number,
        emerBeginDt: string,
        emerEndDt: string,
        emerTime: number,
        emerCost: number,
        gjBoolean: boolean,
        carePlan: DongConference,
        gjChange: number,
        totalCost: number,
        emerChange: number,
        isSprtMore: 'Y' | 'N',
    }
) => {
    return (
        <div className="care_plan_write">
          <div className="padding">
        {basicBoolean && <>
            <div className="care01">
              <div className="text_input">
                <p>기존돌봄</p>
                <div className="input_wrap">
                  <div className="care_plan_list">
                    <table>
                      <thead>
                        <tr>
                          <th>서비스 종류</th>
                          <th>사유</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input type="hidden" className="basicList" value={basicValue ?? undefined} />
                            <input type="hidden" id="basicDetail" value={basicValueInfo ?? undefined} />
                            {basicValue}
                          </td>
                          <td>{basicValueInfo}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        {emerBoolean && <>
          <div className="care02 mt20">
            <div className="text_input">
              <p>긴급돌봄</p>
              <div className="input_wrap">
                <div className="care_plan_list">
                  <table>
                    <thead>
                      <tr>
                        <th>지원일수</th>
                        <th>지원기간</th>
                        <th>지원시간</th>
                        <th>비용</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <td>
                          <input type="hidden" id="emerDay" value={emerCount ?? undefined} />
                          <input type="hidden" className="emerBeginDt" value={emerBeginDt ?? undefined} />
                          <input type="hidden" className="emerEndDt" value={emerEndDt ?? undefined} />
                          <input type="hidden" id="emerTime" value={emerTime ?? undefined} />
                          <input type="hidden" id="emerCost" value={emerCost ?? undefined} />
                          {emerCount}일</td>
                        <td>
                          {emerBeginDt} ~ {emerEndDt}
                        </td>
                        <td>{emerTime}시간</td>
                        <td style={{ textAlign: "right" }}>
                          <span className="red">
                            {emerCost.toLocaleString()}
                          </span>{" "}
                          원
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>}
        {gjBoolean &&
          <div className="care03 mt20">
            <div className="text_input">
              <p>광주+돌봄</p>
              <div className="input_wrap">
                {/* 테이블 */}
                <div className="care_plan_list">
                  {/* 여기부터 */}
                  <table>
                    <thead>
                      <tr>
                        <th>서비스 종류</th>
                        <th>지원시간(내용)</th>
                        <th>지원기간</th>
                        <th>비용</th>
                      </tr>
                    </thead>
                    <tbody className="addTable">
                      {carePlan?.carePlan
                        .filter((plan) => Number(plan.cateCd) !== 0 && Number(plan.cateCd) !== 8)
                        .map((plan, index) => (
                          <tr key={plan.cateCd}>
                            <td>
                              <input
                              type="hidden"
                              className="cateCd"
                              value={plan.cateCd}
                              />
                              {plan.weekday && (
                                <input
                                type="hidden"
                                className="weekday"
                                value={plan.weekday}
                              />
                              )}
                              {plan.cycleUnit && (
                                <input
                                type="hidden"
                                className="cycleUnit"
                                value={plan.cycleUnit}
                              />
                              )}
                              <input
                              type="hidden"
                              className="siUid"
                              value={plan.siUid}
                              />
                              {plan.totalCount && (
                                <input 
                                type="hidden"
                                className='totalCount'
                                value={plan.totalCount}
                                />
                              )}
                              {Number(plan.cateCd) === 1 && "가사지원"}
                              {Number(plan.cateCd) === 2 && "식사지원"}
                              {Number(plan.cateCd) === 3 && "동행지원"}
                              {Number(plan.cateCd) === 4 && "건강지원"}
                              {Number(plan.cateCd) === 5 && "안전지원"}
                              {Number(plan.cateCd) === 6 && "주거편의"}
                              {Number(plan.cateCd) === 7 && "일시보호"}
                              &nbsp; &gt; &nbsp;
                              {carePlan.gjList.map((list) => (
                                plan.siUid === list.siUid && list.serviceNm
                              ))}
                            </td>
                            {Number(plan.cateCd) !== 3 && Number(plan.cateCd) !== 5 && Number(plan.cateCd) !== 6 ?
                            <td>
                                {plan.totalCount && plan.totalCount + "회"}
                                {plan.cycleUnit && "/" + plan.cycleUnit}
                                {plan.weekday && "/" + plan.weekday}
                                {(Number(plan.cateCd) === 1) && "/" + plan.times + "시간"}
                            </td>
                            :
                            <td>
                              {plan.totalCount ? plan.totalCount + "회" : ""}{(plan.totalCount && plan.serviceDetail) ? "/" + plan.serviceDetail : plan.serviceDetail}
                            </td>
                            }
                            {/* {plan.cycleUnit &&
                              plan.totalCount &&
                              plan.weekday &&
                              plan.times && (
                                <td>
                                  {plan.cycleUnit}&nbsp;{plan.totalCount}회&nbsp;(
                                  {plan.weekday})&nbsp;{plan.times}시간
                                </td>
                              )}
                            {plan.totalCount &&
                              !plan.serviceDetail &&
                              !plan.times && <td>{plan.totalCount}회</td>}
                            {!plan.cycleUnit &&
                              plan.totalCount &&
                              !plan.serviceDetail &&
                              plan.times && (
                                <td>
                                  {plan.totalCount}회 {plan.times && plan.times}
                                  시간
                                </td>
                              )}
                            {plan.serviceDetail && !plan.totalCount && (
                              <td>{plan.serviceDetail}</td>
                            )}
                            {plan.serviceDetail && plan.totalCount && (
                              <td>
                                {plan.totalCount}회({plan.serviceDetail})
                              </td>
                            )}
                            {!plan.totalCount && !plan.serviceDetail && (
                              <td style={{ color: "#9da0a8" }}>
                                지원시간(내용) 없음
                              </td>
                            )} */}

                            <td>
                              {!plan.beginDate && "-"}
                              {plan.beginDate}
                              {plan.endDate && ` ~ ${plan.endDate}`}
                            </td>
                            <td style={{ textAlign: "right" }}>
                            {(Number(plan.cateCd) !== 3 && Number(plan.cateCd) !== 5 && Number(plan.cateCd) !== 6) ?
                              <span className="red">
                                {plan.cost.toLocaleString()}
                              </span>
                              :
                              <span className="gray">
                                {plan.cost.toLocaleString()}
                              </span>
                              }
                              {/* {!plan.beginDate &&
                                <span className="gray">
                                  {plan.cost.toLocaleString()}
                                </span>} */}{" "}
                              원
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>}
        </div>
        <div className="cost_list">
            <ul>
              <li>
                <p>(광주+돌봄 잔액)</p>
                <p>
                  <span>{(isSprtMore === 'Y' ? gjChange + 1500000 : gjChange).toLocaleString()}</span> 원
                </p>
              </li>
              <li>-</li>
              <li>
                <p>(계획비용)</p>
                <p>
                  <span className="red">
                    {totalCost.toLocaleString()}
                  </span>{" "}
                  원
                </p>
              </li>
              <li>=</li>
              <li>
                <p>(예상잔액)</p>
                <p>
                  <span className="blue">
                    {(isSprtMore === 'Y' ? gjChange - totalCost + 1500000 : gjChange - totalCost).toLocaleString()}
                  </span>{" "}
                  원
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p>(긴급돌봄 잔액)</p>
                <p>
                  <span>{(isSprtMore === 'Y' ? emerChange + 600000 : emerChange).toLocaleString()}</span> 원
                </p>
              </li>
              <li>-</li>
              <li>
                <p>(계획비용)</p>
                <p>
                  <span className="red">
                    {emerCost.toLocaleString()}
                  </span>{" "}
                  원
                </p>
              </li>
              <li>=</li>
              <li>
                <p>(예상잔액)</p>
                <p>
                  <span className="blue">
                    {(isSprtMore === 'Y' ? emerChange - emerCost + 600000 : emerChange - emerCost).toLocaleString()}
                  </span>{" "}
                  원
                </p>
              </li>
            </ul>
          </div>
        </div>
    );
};

export default CarePlanTable;