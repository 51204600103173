import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ExpenseModelAPI from "../../apis/ExpenseModelAPI";

import ArrowIcon from "../../assets/ic_arrow.png";
import ExpenseModel from "../../models/bill/ExpenseModel";
import OrgDetailModel from "../../models/bill/OrgDetailModel";
import DataTableDetail, { createDetailRow, createDetailTextCell } from "./DataTableDetail";
import Pagination from "./Pagination";

/**
 * 작성자: 홍준표
 * 작성일: 2023.03.22
 *
 * @example
 * <BillDataTable
 *   expandable
 *   columnTitles={['제목', '내용']}
 *   rows={[
 *     createTableRow({
 *       cells: [
 *         createBillTextCell({ text: '제목' })
 *         createBillTextCell({ text: '내용' })
 *       ],
 *       expandContent: (<div>펼쳐졌을 때 보여줄 내용</div>)
 *     })
 *   ]}
 * />
 * @end
 *
 * 한 행을 나타내는 interface
 * @param expandContent 행이 펼쳐졌을 때 보여줄 내용
 * @param cells 행이 가진 셀
 */
export interface BillDataTableRow {
  expandContent?: React.ReactNode;
  cells: BillDataTableCell[];
  caseManagement: ExpenseModel;
}
export const createBillRow = (row: BillDataTableRow) => row;

/**
 * 셀의 타입은 Text 또는 Button 둘 중 하나여야 함
 */
export type BillDataTableCell = BillDataTableTextCell | BillDataTableButtonCell;

/**
 * 셀이 텍스트인 경우에 사용하는 interface
 * @param text 셀 내용
 * @param ellipsis 생략 사용 여부
 * @param d_day 디데이
 */
export interface BillDataTableTextCell {
  text: string;
  ellipsis?: boolean;
  d_day?: number;
}
export const createBillTextCell = (cell: BillDataTableTextCell) => cell;
const isTextCell = (cell: BillDataTableCell): cell is BillDataTableTextCell =>
  "text" in cell;

/**
 * 셀이 버튼인 경우에 사용하는 interface
 * @param type underlined: 검정색 밑줄 버튼, outlined: 파란색 외곽선 버튼, undefined: -
 * @param text 버튼 제목
 * @param onClick 버튼이 클릳되었을 때 호출되는 함수
 */
export interface BillDataTableButtonCell {
  type: "underlined" | "outlined" | undefined;
  label: string;
  onClick: () => void;
}
export const createBillButtonCell = (cell: BillDataTableButtonCell) => cell;
const isButtonCell = (cell: BillDataTableCell): cell is BillDataTableButtonCell =>
  "label" in cell;

/**
 * 모든 목록에서 사용할 수 있는 데이터 테이블 컴포넌트
 * @param expandable 펼쳐지는 행이 있는지 여부
 * @param columnTitles 열의 제목
 * @param rows 데이터 행
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
const BillDataTable = ({
  expandable = false,
  columnTitles,
  rows,
  page,
  totalPageCount,
  onPageChange,
}: {
  expandable?: boolean;
  columnTitles: string[];
  rows: BillDataTableRow[];
  page: number;
  totalPageCount?: number;
  onPageChange: (page: number) => any;
}) => {
  return (
    <article className="list">
      <table>
        <thead>
          <tr>
            {expandable && <th className="first"></th>}
            {columnTitles.map((title, index) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <Row key={index} row={row} expandable={expandable} />
          ))}
        </tbody>
      </table>
      <Pagination
        page={page}
        totalPageCount={totalPageCount}
        onPageChange={onPageChange}
      />
    </article>
  );
};

/**
 * 데이터 테이블의 한 행을 나타내는 컴포넌트
 */
const Row = ({
  row,
  expandable,
}: {
  row: BillDataTableRow;
  expandable: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const [orgModel, setOrgModel] = useState({} as OrgDetailModel)
  const [loading, setLoading] = useState(false)

  const handleExpand = () => {
    if (!expandable) {
      return;
    }
    setExpanded(!expanded);
  };

  return (
    <>
      <tr
        className={clsx("table_title", expanded ? "on" : "off")}
        onClick={handleExpand}
      >
        {row.expandContent && (
          <td className="first">
            <img src={ArrowIcon} alt="접고 펼치기 아이콘" />
          </td>
        )}
        {row.cells.map((cell, index) => (
          <Cell key={index} cell={cell} />
        ))}
      </tr>
      {!loading ? 
        row.expandContent && (
          <tr
            className={clsx("table_content", expanded && "on")}
            style={{ display: expanded ? "table-row" : "none" }}
          >
            <td colSpan={100}>
              {row.expandContent}
              <ExpendDetail orgModel={row.caseManagement.detailInfo}/>
            </td>
          </tr>
        )
      :
      null
      }
      
    </>
  );
};

/**
 * 데이터 테이블의 한 셀을 나타내는 컴포넌트
 */
const Cell = ({ cell }: { cell: BillDataTableCell }) => {
  if (isTextCell(cell)) {
    if (cell.ellipsis) {
      return (
        <td className="address">
          <div className="ellipsis">
            <p>
              {cell.text}
              {<D_Day d_day={cell.d_day} />}
            </p>
          </div>
        </td>
      );
    } else {
      return (
        <td>
          {cell.text}
          {<D_Day d_day={cell.d_day} />}
        </td>
      );
    }
  } else {
    return (
      <td>
        {cell.type ? (
          <button
            type="button"
            className={clsx("btn_blue", cell.type === "underlined" && "edit")}
            onClick={(e) => {
              e.stopPropagation();
              cell.onClick();
            }}
          >
            {cell.label}
          </button>
        ) : (
          "-"
        )}
      </td>
    );
  }
};

/**
 * 디데이를 나타내는 컴포넌트
 */
const D_Day = ({ d_day }: { d_day?: number }) => {
  let d_dayElement = <></>;
  if (d_day) {
    if (d_day === 0) {
      d_dayElement = (
        <>
          <span className="red">D-day</span>
        </>
      );
    } else if (d_day <= 2) {
      d_dayElement = (
        <>
          <span className="yellow">D-{d_day}</span>
        </>
      );
    }
  }
  return d_dayElement;
};

const ExpendDetail = ({orgModel} : {orgModel : OrgDetailModel}) => {
  return (
    <li className="w100 mt8" style={{listStyleType: "none"}}>
      <p className="gray">서비스 현황</p>
      <DataTableDetail
      columnTitles={[
        "서비스 회차",
        "서비스 일자",
        "진행시간(제공시간)",
        "총금액(수정 전 금액)",
        "금액 수정사유",
      ]}
      rows={orgModel.servicePlanItemVO.map((caseDetail, idx) =>
        createDetailRow({
          cells: [
            createDetailTextCell({ text: (idx+1) + "회차" }),
            createDetailTextCell({
              text: caseDetail.serviceDate,
            }),
            createDetailTextCell({
                text: moment(caseDetail.beginDt).format("A h:mm") + "~" + 
                moment(caseDetail.endDt).format("A h:mm") + 
                " (기본 " +
                  Math.round(moment(caseDetail.endDt).diff(
                    moment(caseDetail.beginDt),
                      "hours",
                      true
                    ) * 10) / 10 +
                    "시간)",
            }),
            createDetailTextCell({
              text: caseDetail.updRsn ? caseDetail.totalCost.toLocaleString("ko-KR") + " 원 (" + caseDetail.updCost.toLocaleString("ko-KR") + " 원)"
              : caseDetail.totalCost === null ? caseDetail.totalCost : caseDetail.totalCost ? caseDetail.totalCost.toLocaleString("ko-KR") + " 원" : "0 원"
            }),
            createDetailTextCell({
              text: caseDetail.updRsn ? caseDetail.updRsn : "-"
            })
            // createDetailTextCell({
            //   text : caseDetail.useCost === null ? caseDetail.useCost : caseDetail.useCost ? caseDetail.useCost.toLocaleString("ko-KR") + " 원" : "데이터오류",
            // }),
            // createDetailTextCell({ text: caseDetail.pplCnt ? caseDetail.pplCnt + " 명" : "데이터오류" }),
            // createDetailTextCell({
            //   text : caseDetail.totalCost === null ? caseDetail.totalCost : caseDetail.totalCost ? caseDetail.totalCost.toLocaleString("ko-KR") + " 원" : "데이터오류",
            // }),
          ],
        })
      )}
      page={1}
      onPageChange={() => {}}
    />
    </li>
  )
}

export default BillDataTable;
