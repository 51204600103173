import Gender from "./Gender";
import { Expose } from 'class-transformer';

export type BaseServiceTarget = Omit<ServiceTarget, ServiceTargetGetters>;

export type ServiceTargetGetters =
  | "fullAddress"
  | "telno1"
  | "telno2"
  | "telno3"
  | "trgterCd"
  | "age";

class ServiceTarget {
  constructor(
    public stUid: number,
    public syUid: number,
    public sbscWay: ServiceTargetRegistrationType,
    public trgterNm: string,
    public trgterCd: string,
    public hdong: string,
    public brthdy: string,
    public gender: Gender,
    public telno: string,
    public adres: string,
    public adresDtl: string
  ) {}

  get fullAddress(): string {
    return (this.adres ?? '') + ", " + (this.adresDtl ?? '');
  }

  @Expose({ toPlainOnly: true })
  get telno1(): string {
    return this.telno.split("-")[0];
  }

  @Expose({ toPlainOnly: true})
  get telno2(): string {
    return this.telno.split("-")[1];
  }

  @Expose({ toPlainOnly: true })
  get telno3(): string {
    return this.telno.split("-")[2];
  }

  get age() {
    const today = new Date();
    const birthDate = new Date(this.brthdy);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}

export type ServiceTargetRegistrationType =
  | "돌봄콜"
  | "창구(전화)"
  | "창구(방문)"
  | "의무대상"
  | "전입자"
  | "의무대상(계획변경)"
  | "의무대상(사례종결)"
  | "재수립대상"

export const ServiceTargetRegistrationTypeValues = 
[ "돌봄콜"
, "창구(전화)"
, "창구(방문)"
, "의무대상"
, "전입자"
, "의무대상(계획변경)"
, "의무대상(사례종결)"
, "재수립대상" ];

export default ServiceTarget;
