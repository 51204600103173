import axios from "axios";
import { plainToInstance } from "class-transformer";
import { serialize } from "object-to-formdata";
import { DepartVO } from "./MemberAPI";
import { CancelScheduleFormInput } from "../dialogs/sprt-more/MoveOutDialog";
import MoveOutTarget from "../models/MoveOutTarget";
import MoveOutListForm from "../models/MoveOutListForm";

interface GetAllParams {
    page?: number,
    deptCd?: string,
    upperDptUid?: number,
    mbrUid?: number,
    inGuUid?: number,
    outGuUid?: number,
}

const moveOutGuList = (params: GetAllParams) => {
    return axios.get("/api/moveOut/MoveOutGuList", { params }).then((res) => {
        return res.data as DepartVO[]
    })
}

const getCurrentGu = (params: GetAllParams) => {
    return axios.get("/api/moveOut/getCurrentGu", { params })
    .then((res) => res.data as DepartVO)
}

const moveOut = (params: CancelScheduleFormInput) => {
    return axios.post("/api/moveOut/MoveOut", serialize(params))
        .then((res) => {
        return res.data as boolean
    })
}

const getMoveOutTargetList = (params: GetAllParams) => {
    return axios.get("/api/moveOut/getMoveOutTargetList", { params })
    .then((res) => plainToInstance(MoveOutTarget, res.data))
}

const moveIn = (params: MoveOutTarget) => {
    return axios.post("/api/moveOut/MoveIn", serialize(params))
    .then((res) => res.data as boolean)
}

const moveOutList = (params: GetAllParams) => {
    return axios.get("/api/moveOut/moveOutList", { params })
    .then((res) => plainToInstance(MoveOutListForm, res.data))
}

const MoveOutAPI = {
    moveOutGuList,
    moveOut,
    getCurrentGu,
    getMoveOutTargetList,
    moveIn,
    moveOutList,
}

export default MoveOutAPI