import { Moment } from "moment";

class GuBillModel {
    constructor(
        public sbUid: number,
        public spiUid: number,
        public spyUid: number,
        public telno: string,
        public billStatus: string,
        public hdong: string,
        public trgterNm: string,
        public serviceNm: string,
        public brthdy: string,
        public gender: string,
        public adres: string,
        public departName: string,
        public userName: string,
        public billDt: string,
        public payDt: string,
        public billDate: string,
        public payDate: string,
        public totalCost: number,
        public beginDt: string,
        public endDt: string,
        public pplCnt: number,
        public cost: number,
        public startTime: Moment,
        public endTime: Moment,
        public serviceStatCd: string,
        public updCost: number,
        public updRsn: string,
        public payCost: number,
        public payRsn: string,
        public useCost: number,
        public locality: string,
        public bank: string,
        public accountHolder: string,
        public accountNo: string,
        public clclnSt: string,
        public serviceGbn: string,
    ) {}
}

export default GuBillModel