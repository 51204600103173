/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";

import ArrowIcon from "../../assets/ic_arrow.png";
import useMember from "../../hooks/useMember";


/**
 * 작성자: 홍준표
 * 작성일: 2023.04.13
 * 
 *
 * @example
 * <PayItemDataTable
 *   expandable
 *   columnTitles={['제목', '내용']}
 *   rows={[
 *     createTableRow({
 *       cells: [
 *         createTextCell({ text: '제목' })
 *         createTextCell({ text: '내용' })
 *       ],
 *       expandContent: (<div>펼쳐졌을 때 보여줄 내용</div>)
 *     })
 *   ]}
 * />
 * @end
 *
 * 한 행을 나타내는 interface
 * @param expandContent 행이 펼쳐졌을 때 보여줄 내용
 * @param cells 행이 가진 셀
 * @param spyUid 고유식별자
 */

export interface PayItemDataTableRow {
  expandContent?: React.ReactNode;
  cells: PayItemDataTableCell[];
}
export const createRow = (row: PayItemDataTableRow) => row;

/**
 * 셀의 타입은 Text 또는 Button 둘 중 하나여야 함
 */
export type PayItemDataTableCell = PayItemDataTableTextCell | PayItemDataTableButtonCell;

/**
 * 셀이 텍스트인 경우에 사용하는 interface
 * @param text 셀 내용
 * @param ellipsis 생략 사용 여부
 * @param d_day 디데이
 */
export interface PayItemDataTableTextCell {
  text: string;
  ellipsis?: boolean;
  d_day?: number;
  redtext?: boolean;
}
export const createTextCell = (cell: PayItemDataTableTextCell) => cell;
const isTextCell = (cell: PayItemDataTableCell): cell is PayItemDataTableTextCell =>
  "text" in cell;

/**
 * 셀이 버튼인 경우에 사용하는 interface
 * @param type underlined: 검정색 밑줄 버튼, outlined: 파란색 외곽선 버튼, undefined: -
 * @param text 버튼 제목
 * @param onClick 버튼이 클릳되었을 때 호출되는 함수
 */
export interface PayItemDataTableButtonCell {
  type: "underlined" | "outlined" | undefined;
  label: string;
  link?: HTMLAnchorElement;
}
export const createButtonCell = (cell: PayItemDataTableButtonCell) => cell;
const isButtonCell = (cell: PayItemDataTableCell): cell is PayItemDataTableButtonCell =>
  "label" in cell;

/**
 * 모든 목록에서 사용할 수 있는 데이터 테이블 컴포넌트
 * @param expandable 펼쳐지는 행이 있는지 여부
 * @param columnTitles 열의 제목
 * @param rows 데이터 행
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */

const PayItemDataTable = ({
  expandable = false,
  columnTitles,
  rows,
}: {
  expandable?: boolean;
  columnTitles: string[];
  rows: PayItemDataTableRow[];
}) => {
const {member} = useMember()
const handleDataDownload = (link: HTMLAnchorElement) => {
  link.click()
}

  /**
 * 데이터 테이블의 한 행을 나타내는 컴포넌트
 */
const Row = ({ row }: { row: PayItemDataTableRow }) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <tr
        className={`table_title ${expanded ? "on" : "off"}`}
        onClick={handleExpand}
      >
        {row.expandContent && (
          <td className="first">
            <img src={ArrowIcon} alt="접고 펼치기 아이콘" />
          </td>
        )}
        {row.cells.map((cell, index) => (
          <Cell key={index} cell={cell} />
        ))}
      </tr>
      {row.expandContent && (
        <tr
          className={`table_content ${expanded ? "on" : ""}`}
          style={{ display: expanded ? "table-row" : "none" }}
        >
          <td colSpan={100}>{row.expandContent}</td>
        </tr>
      )}
    </>
  );
};


/**
 * 데이터 테이블의 한 셀을 나타내는 컴포넌트
 */
const Cell = ({ cell }: { cell: PayItemDataTableCell }) => {
  
  if (isTextCell(cell)) {
    if (cell.ellipsis) {
      return (
        <td className="claim_address">
          <div className="ellipsis">
            <p>
              {cell.text}
              {<D_Day d_day={cell.d_day} />}
            </p>
          </div>
        </td>
      );
    } else if(cell.redtext) {
      return (
        <td>
          <span className="red">{cell.text}</span>
          {<D_Day d_day={cell.d_day} />}
        </td>
      );
    } else {
      return (
        <td>
          {cell.text}
          {<D_Day d_day={cell.d_day} />}
        </td>
      );
    }
  } else {  //버튼
    return (
      <td>
        {cell.type ? (
          <>
          <button
            type="button"
            className={`btn_gray ${cell.type === "underlined" ? "edit" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
              handleDataDownload(cell.link as HTMLAnchorElement)
            }}
          >
            {cell.label}
          </button>
          </>
        )
         : (
          "-"
        )}
      </td>
    );
  }
};

/**
 * 디데이를 나타내는 컴포넌트
 */
const D_Day = ({ d_day }: { d_day?: number }) => {
  let d_dayElement = <></>;
  if (d_day) {
    if (d_day === 0) {
      d_dayElement = (
        <>
          <span className="red">D-day</span>
        </>
      );
    } else if (d_day <= 2) {
      d_dayElement = (
        <>
          <span className="yellow">D-{d_day}</span>
        </>
      );
    }
  }
  return d_dayElement;
};

  return (
    <>
      <table className="main_table">
        <thead>
          <tr>
            {expandable && <th className="first"></th>}
            {columnTitles.map((title, index) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
              <Row key={index} row={row} />
          ))}
        </tbody>
      </table>
    </>
  );
};


export default PayItemDataTable;
