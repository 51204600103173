import axios from "axios";
import { plainToInstance } from "class-transformer";
import { serialize } from "object-to-formdata";

import NaEvaluationInfo from "../models/NaEvaluationInfo";
import NaEvaluationTargetInfo from "../models/NaEvaluationTargetInfo";
import ServicePlan from "../models/ServicePlan";
import ServiceTargetYear from "../models/ServiceTargetYear";
import NeedsAssessmentFormInput from "../models/form/NeedsAssessmentFormInput";

/**
 * 작성자: 강우석
 * 작성일: 2023.03.08
 */

interface GetAllParams {
  page?: number;
  sdUid?: number;
  csUid?: number;
  syUid?: number;
  sbscWay?: string;
  hdong?: string;
  status?: string;
  searchTarget?: string;
  keyword?: string;
  dbStatCd?: string;
}

const getAll = (params: GetAllParams) => {
  return (
    axios
      .get("/api/servicePlan", { params })
      // .then((response) => response.data as ServicePlan[]);
      .then((response) => {
        return plainToInstance(ServicePlan, response.data);
      })
  );
};

const getCount = (params: GetAllParams) => {
  return axios
    .get("/api/servicePlan/count", { params })
    .then((res) => res.data);
};

const getEvaluationTargetInfo = (params: GetAllParams) => {
  return axios
    .get(
      `/api/servicePlan/evaluation/getEvaluationtargetInfo?sdUid=${params.sdUid}`,
      { params }
    )
    .then((res) => res.data as NaEvaluationTargetInfo);
};

export interface InsertEvaluation {
    syUid: number;
    csUid: number;
    emgcNm?: string;
    emgcRel?: string;
    emgcTel?: string;
    incomeLevel?: string;
    incomeLevel2?: string;
    homeMember?: number;
    beginDt?: string;
    beginGpsCd?: number;
    endDt?: string;
    endGpsCd?: number;
    resultJson?: string;
    bodyAreaPt?: number;
    emtAreaPt?: number;
    characterJson?: string;
    dSupportYn: string;
    eSupportYn: string;
    stepCdNext: string;
}

const getEvaluationData = (params: GetAllParams) => {
  return axios
    .get(
      `/api/servicePlan/evaluation/getEvaluationData?csUid=${params.csUid}`,
      { params }
    )
    .then((res) => {
      return res.data as NaEvaluationInfo;
      // return plainToInstance(NaEvaluationInfo, res.data)
    })
    .catch((err) => {
      console.log("error code : ", err.response.status);
      return false;
    });
};

const insertEvaluation = (params: InsertEvaluation) => {
  return axios
    .post("/api/servicePlan/evaluation/insertEvaluation", serialize(params))
    .then((res) => {
      // console.log(params);
      // console.log("status : ", res);
      return res.data["success"] as boolean;
    })
    .catch((err) => {
      console.log(err);
    });
};

interface GetServiceTargetParams {
  sdUid: number;
}

const getServiceTarget = (params: GetServiceTargetParams) => {
  return axios
    .get("/api/servicePlan/evaluation/getEvaluationtargetInfo", { params })
    .then((response) =>
      plainToInstance(ServiceTargetYear, response.data as ServiceTargetYear)
    );
};

interface InsertNeedsAssessmentParams {
  csUid: number; // 상담 키
  syUid: number; // 사례관리 키
  emgcNm: string; // 비상연락처 이름
  emgcRel: string; // 비상연락처 관계
  emgcTel: string; // 비상연락처 전화번호
  incomeLevel: string; // 소득수준
  incomeLevel2?: string; // 85이하 소득수준 2
  homeMember: number; // 가구원 수
  beginDt: string; // 시작일시
  endDt: string; // 종료일시
  resultJson: string; // 평가결과 JSON
  characterJson: string; // 인구학적 특성 외 JSON
  bodyAreaPt: number; // 신체적 영역점수
  emtAreaPt: number; // 정서적 영역점수
}

const insertNeedsAssessment = (params: InsertNeedsAssessmentParams) => {
  return axios
    .post("/api/servicePlan/evaluation/insertEvaluation", serialize(params))
    .then((response) => response.data.success as boolean);
};

const ServicePlanAPI = {
  getAll,
  getServiceTarget,
  getCount,
  insertNeedsAssessment,
  getEvaluationTargetInfo,
  getEvaluationData,
  insertEvaluation,
};

export default ServicePlanAPI;
