import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CloseIcon from "../../assets/ic_close.png";
import LogoutIcon from "../../assets/ic_logout.png";
import NodataImage from "../../assets/ic_nodata.png";
import NotifyIcon from "../../assets/ic_notify.png";
import DefaultProfileImage from "../../assets/img_default.png";
import LogoImage from "../../assets/logo.png";
import { useDialog } from "../../dialogs/DialogProvider";
import EditProfileDialog from "../../dialogs/EditProfileDialog";
import useMember from "../../hooks/useMember";
import BaseNotification from "../../models/BaseNotification";
import LinkIcon from "../../assets/ic_link.png";
import Member from "../../models/Member";
import FileUploadAPI from "../../apis/FileUploadAPI";

// 탭을 다섯 개의 문자열 중 하나로 강제
export type TopNavTab =
  | "돌봄대상자"
  | "돌봄계획"
  | "서비스"
  | "비용"
  | "평가/사후관리"
  | "추가관리"
  | "환경설정"
  | "기관정보" | "제공기관 관리" | "서비스 관리" | "회원 관리";

// 모든 탭을 배열로 선언
const tabs: TopNavTab[] = [
  "돌봄대상자",
  "돌봄계획",
  "서비스",
  "비용",
  "평가/사후관리",
  "추가관리",
  "환경설정",
];

/**
 * 상단 네비게이션 바
 * @param tab 현재 선택된 탭
 * @param onTabChange 탭의 변경을 알리는 함수
 */
const TopNavBar = ({
  tab,
  onTabChange,
}: {
  tab?: TopNavTab;
  onTabChange: (tab: TopNavTab) => void;
}): JSX.Element => {
  const navigate = useNavigate();
  const { member } = useMember()
  const menuTabs: TopNavTab[] = member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" ? //GU
    [
      "돌봄대상자",
      "돌봄계획",
      "서비스",
      "비용",
      "평가/사후관리",
      "추가관리",
      "환경설정",
    ] :
    member?.depart?.deptCd === "DONG" ?
      [
        "돌봄대상자",
        "돌봄계획",
        "서비스",
        "평가/사후관리",
        "추가관리",
        "환경설정",
      ] : //기관
      [
        "서비스",
        "비용",
        "환경설정",
      ]

  const handleLogoClick = () => {
    navigate("/home");
  };

  return (
    <header>
      <Logo onClick={handleLogoClick} />
      <NavBar tab={tab} onTabChange={onTabChange} divideTabs={menuTabs} />
      <LoginInfo />
    </header>
  );
};

/**
 * 로고
 * @param onClick
 */
const Logo = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="logo">
      <a href="#" onClick={onClick}>
        <img src={LogoImage} alt="로고" />
      </a>
    </div>
  );
};

/**
 * 네비게이션 바
 * @param tab 현재 선택된 탭
 * @param onTabChange 탭의 변경을 알리는 함수
 */
const NavBar = ({
  tab,
  onTabChange,
  divideTabs,
}: {
  tab?: TopNavTab;
  onTabChange: (tab: TopNavTab) => void;
  divideTabs: TopNavTab[]
}): JSX.Element => {
  return (
    <ul className="gnb">
      {divideTabs.map((e, index) => (
        <li key={index} className={e === tab ? "on" : ""}>
          <a href="#" onClick={() => onTabChange(e)}>
            {e}
          </a>
        </li>
      ))}
    </ul>
  );
};

/**
 * 우측 로그인 메뉴
 */
const LoginInfo = (): JSX.Element => {
  const { member, logout } = useMember();
  const { showDialog } = useDialog();

  // 알림을 다시 불러오기 위한 값
  const [key, setKey] = useState(0);

  const [isNotificationPopupOpen, setNotificationPopupOpen] = useState(false);
  const [notifications, setNotifications] = useState([] as BaseNotification[]);
  const isNotification = notifications.length !== 0;
  const [profileUrl, setProfileUrl] = useState<string>()
  useEffect(() => {
    FileUploadAPI.getFile({
      uid: member?.mbrUid as number,
      folder: "USER",
    }).then((res) => {
      // console.log("res : ", res)
      setProfileUrl(window.URL.createObjectURL(res))
    })
  }, [])

  useEffect(() => {
    // TODO: 실제 알림 목록 불러오는 로직 구현
    setNotifications(BaseNotification.mockList);
  }, [key]);

  const handleNotificationPopupOpen = () => {
    setNotificationPopupOpen(!isNotificationPopupOpen);
  };

  const handleNotificationDelete = (notification: BaseNotification) => {
    // TODO: 알림 삭제 로직

    // 알림 다시 불러오기
    setKey(key + 1);
  };

  const handleLogout = () => {
    logout();
  };

  const handleImageError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    e.currentTarget.src = DefaultProfileImage;
  };

  const handleProfileClick = () => {
    showDialog(EditProfileDialog, { member: member as Member });
  };

  return (
    <div className="login_info">
      <ul>
        <li>
          <a href="#" className="link">
            행복이음
            <img src={LinkIcon} alt="바로가기 아이콘" />
          </a>
        </li>
        {/* <li>
          <a
            href="#"
            className={`notify ${isNotification ? "on" : ""}`}
            onClick={handleNotificationPopupOpen}
          >
            <img src={NotifyIcon} alt="알림" />
          </a>
          <NotificationPopup
            isOpen={isNotificationPopupOpen}
            onClose={handleNotificationPopupOpen}
            list={notifications}
            onNotificationDelete={handleNotificationDelete}
          />
        </li> */}
        <li>
          <a href="#" className="logout" onClick={handleLogout}>
            <img src={LogoutIcon} alt="로그아웃" />
          </a>
        </li>
        <li>
          <a href="#" className="mypage" onClick={handleProfileClick}>
            <div className="img">
              {/* TODO: 프로필 이미지 추가하기 */}
              <img
                src={profileUrl ? profileUrl : DefaultProfileImage}
                alt="프로필 이미지"
                onError={handleImageError}
              />
            </div>
            <div className="txt">
              <p className="name">{member?.userName}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

/**
 * 알림 팝업
 * @param isOpen 팝업 열림 상태
 * @param onClose 팝업 닫기 버튼 클릭 시 호출되는 함수
 * @param list 알림 목록
 * @param onNotificationDelete 알림 삭제 버튼 클릭 시 호출되는 함수
 */
const NotificationPopup = ({
  isOpen,
  onClose,
  list,
  onNotificationDelete,
}: {
  isOpen: boolean;
  onClose: () => void;
  list: BaseNotification[];
  onNotificationDelete: (notification: BaseNotification) => void;
}) => {
  return (
    <div
      className="notify_popup popup_white close_immediately"
      style={{
        display: "block",
        opacity: isOpen ? 1 : 0,
        transition: "all .2s",
        visibility: !isOpen ? "hidden" : "visible",
      }}
    >
      <div className="popup_tit">
        <h3>알림</h3>
        <a href="#" className="close" onClick={onClose}>
          <img src={CloseIcon} alt="닫기" />
        </a>
      </div>
      <div className="popup_con">
        {list.length === 0 ? (
          <div className="no_notify">
            {/* TODO: Nodata 이미지 추가 */}
            <img src="" alt="데이터 없음" />
            <p>표시할 알림이 없습니다.</p>
          </div>
        ) : (
          <ul>
            {list.map((notification) => (
              <li key={notification.id}>
                <NotificationListItem
                  notification={notification}
                  onDelete={() => onNotificationDelete(notification)}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

/**
 * 알림 목록
 * @param notification 알림 객체
 * @param onDelete 삭제 버튼 클릭 시 호출되는 함수
 */
const NotificationListItem = ({
  notification,
  onDelete,
}: {
  notification: BaseNotification;
  onDelete: () => void;
}) => {
  return (
    <>
      <div className="tit">
        <p className="category">{notification.category}</p>
        <a href="#" className="del" onClick={onDelete}>
          삭제
        </a>
      </div>
      <div className="con">
        <p>{notification.content}</p>
        <p className="date">{notification.date}</p>
      </div>
    </>
  );
};

export default TopNavBar;
