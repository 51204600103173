import React, { useEffect, useState } from "react";

type FlashMessageProps = {
  className: string;
  message: string;
  isVisible: boolean;
  onDismiss: () => void;
};

const FlashMessage: React.FC<FlashMessageProps> = ({
  className,
  message,
  isVisible,
  onDismiss,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [isVisible, onDismiss]);

  return isVisible ? (
    <div className={className} onClick={onDismiss}>
      {message}
    </div>
  ) : null;
};

export default FlashMessage;
