import axios from "axios";
import { plainToInstance } from "class-transformer";
import { serialize } from "object-to-formdata";
import { RegisterFormInput } from "../dialogs/service/ServiceTargetSimpleInfoDialog";

import CaseManager from "../models/CaseManager";
import ServiceTarget from "../models/ServiceTarget";
import ServiceTargetYear from "../models/ServiceTargetYear";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.27
 *
 * 사례관리 API
 */

type GetAllParams = {
  page?: number;
  sbscWay?: string;
  sbscWay2?: string;
  sbscWay3?: string;
  hdong?: string;
  statCd?: string;
  hing?: string;
  searchTarget?: string;
  keyword?: string;
  trgterCd?: string;
};

const getAll = (params: GetAllParams) => {
  return axios
    .get("/api/serviceTarget", { params })
    .then((response) => plainToInstance(ServiceTargetYear, response.data));
};

const getOne = (params: GetAllParams) => {
  return axios
    .get("/api/serviceTarget", { params })
    .then((response) => response.data as ServiceTargetYear);
};

const getCount = (params: GetAllParams) => {
  return axios
    .get("/api/serviceTarget/count", { params })
    .then((res) => res.data);
};

interface InsertParams {
  sbscWay: string;
  applcntNm: string;
  applcntRel: string;
  applcntTel: string;
  applcntReason: string;
  applcntEtc: string;
  trgterNm: string;
  trgterCd: string;
  brthdy: string;
  gender: string;
  telno: string;
  hdong: string;
  adres: string;
  adresDtl: string;
  crtUid: number;
  year: number;
}

const insert = (params: InsertParams) => {
  return axios
    .post("/api/serviceTarget/insert", serialize(params))
    .then((response) => response.data["success"] as boolean);
};

interface UpdateParams extends InsertParams {
  syUid: number;
}

const update = (params: UpdateParams) => {
  return axios
    .post("/api/serviceTarget/update", serialize(params))
    .then((response) => response.data["success"] as boolean);
};

// const updateTarget = (params: RegisterFormInput) => {
//   return axios
//     .post("/api/serviceTarget/update", serialize(params))
//     .then((response) => response.data["success"] as boolean);
// };

interface CancelParams {
  syUid: number;
  stopGbn: string;
  stopReason: string;
}

const cancel = (params: CancelParams) => {
  return axios
    .post("/api/serviceTarget/cancel", serialize(params))
    .then((response) => response.data["success"] as boolean);
};

interface FindManagersByNameParams {
  name?: string;
  syUid: number;
}

const findManagersByName = (params: FindManagersByNameParams) => {
  return axios
    .get("/api/serviceTarget/findManagers", { params })
    .then((response) => response.data as CaseManager[]);
};

interface AssignManagerParams {
  updateYn: "Y" | "N";
  syUid: number;
  manager1: number; // 담당자1 key
  manager2?: number; // 담당자2 key
  crtUid: number;
}

const assignManager = (params: AssignManagerParams) => {
  return axios
    .post("/api/serviceTarget/managerRegist", serialize(params))
    .then((response) => response.data["success"] as boolean);
};

interface InsertScheduleParams {
  csUid?: number;
  syUid: number;
  stUid: number;
  mbrUid: number;
  trgterNm: string;
  trgterCd: string;
  brthdy: string;
  gender: string;
  telno: string;
  adres: string;
  hdong: string;
  lat: number;
  lng: number;
  counselDate: string;
}

const insertSchedule = (params: InsertScheduleParams) => {
  return axios
    .post("/api/serviceTarget/counselScheduleRegist", serialize(params))
    .then((response) => response.data["success"] as boolean);
};

interface FindServiceTargetsByNameParams {
  name: string;
}

const findServiceTargetByName = (params: FindServiceTargetsByNameParams) => {
  return axios
    .get("/api/serviceTarget/findServiceTargets", { params })
    .then((response) => plainToInstance(ServiceTarget, response.data));
};

const ServiceTargetAPI = {
  getAll,
  getCount,
  insert,
  update,
  cancel,
  findManagersByName,
  assignManager,
  insertSchedule,
  findServiceTargetByName,
  getOne,
  // updateTarget,
};

export default ServiceTargetAPI;
export type { GetAllParams };
