import axios from "axios";
import { plainToInstance } from "class-transformer";
import { serialize } from "object-to-formdata";

/**
 * 메인화면 데이터 로드 API
 * 작성자: 홍준표
 * 작성일: 2023.04.20
 */

export interface GuHome {
  ServiceListCount: number,
  EmergencyCount: number,
  ServiceResultCount: number,
  ClaimCount: number,
  ResultEvalCount: number,
}

export interface DongHome {
  WindowStatus: number,
  PlanStatus: number,
  NeedAssStatus: number,
  DnPlanStatus: number,
  DongConferStatus: number,
  AreaConferStatus: number,
  ResultEvalCount: number,
}

export interface OrgHome {
  ServiceReception: number,
  ServiceOnGoing: number,
  ServiceComplete: number,
  BillUnClaim: number,
}

export interface GuDoughnut {
  TargetStatus: number,
  ObilStatus: number,
  TransferStatus: number,
  RePlanStatus: number,
  ObEndStatus: number,
  ObChangeStatus: number,
  WindowStatus: number,
  CallStatus: number,
}

export interface GuPie {
  young: number,
  teen: number,
  singleY: number,
  singleO: number,
  old: number,
  disabled: number,
}

export interface Progress {
  stepCd: string,
  totalCount: number,
}

export interface GuPolarArea {
  success: boolean,
  list: Progress[],
}

export interface DongStatitem {
  hdong: string,
  gjCount: number,
  emCount: number,
}

export interface DongStatList {
  success: boolean,
  list: DongStatitem[],
}

export interface ServiceStatsItem {
  cateStr: string,
  cateCd: string,
  totalCount: number,
  gubun: string,
}

export interface ServiceStatsList {
  success: boolean,
  list: ServiceStatsItem[],
}

const getGuHome = () => {
  return axios.get("/api/home/getGuHome").then((res) => {
    return res.data as GuHome
  })
}

const getDongHome = (params: GraphParam) => {
  return axios.get("/api/home/getDongHome", {params}).then((res) => {
    return res.data as DongHome
  })
}

const getOrgHome = (params: GraphParam) => {
  return axios.get("/api/home/getOrgHome", {params}).then((res) => {
    return res.data as OrgHome
  })
}

export interface GraphParam {
  hdong?: string,
  dptUid?: number,
}

const getGuDoughnut = (params?: GraphParam) => {
  return axios.get("/api/home/getGuDoughnut", {params}).then((res) => {
    return res.data as GuDoughnut
  })
}

const getOrgDoughnut = (params?: GraphParam) => {
  return axios.get("/api/home/getOrgDoughnut", {params}).then((res) => {
    return res.data as GuDoughnut
  })
}

const getGuPie = (params?: GraphParam) => {
  return axios.get("/api/home/getGuPie", {params}).then((res) => {
    return res.data as GuPie
  })
}

const getOrgPie = (params?: GraphParam) => {
  return axios.get("/api/home/getOrgPie", {params}).then((res) => {
    return res.data as GuPie
  })
}

const getPolarArea = (params?: GraphParam) => {
  return axios.get("/api/main/getProgressStatsList", {params}).then((res) => {
    return res.data as GuPolarArea
  })
}

const orgPolarArea = (params?: GraphParam) => {
  return axios.get("/api/main/orgProgressStatsList", {params}).then((res) => {
    return res.data as GuPolarArea
  })
}

const getDongStatList = (params?: GraphParam) => {
  return axios.get("/api/main/getDongStatsList", {params}).then((res) => {
    return res.data as DongStatList
  })
}

const getServiceStatsList = (params?: GraphParam) => {
  return axios.get("/api/main/getServiceStatsList", {params}).then((res) => {
    return res.data as ServiceStatsList
  })
}


const HomeAPI = {
  getGuHome,
  getGuDoughnut,
  getGuPie,
  getPolarArea,
  getDongStatList,
  getServiceStatsList,
  getDongHome,
  getOrgHome,
  getOrgDoughnut,
  getOrgPie,
  orgPolarArea,
};

export default HomeAPI;
