import ServiceTarget, { ServiceTargetRegistrationType } from "./ServiceTarget";

class ServicePlan extends ServiceTarget {
  constructor(
    public stUid: number,
    public syUid: number,
    public sdUid: number,
    public csUid: number,
    public spyUid: number | undefined,
    public sbscWay: ServiceTargetRegistrationType,
    public trgterNm: string,
    public trgterCd: string,
    public hdong: string,
    public brthdy: string,
    public gender: "남" | "여",
    public telno: string,
    public adres: string,
    public adresDtl: string,
    public dbStatCd: ServicePlanStatus,
    public statCd: ServicePlanStatus,
    public updDt: string,
    public isRequest: boolean,
  ) {
    super(
      stUid,
      syUid,
      sbscWay,
      trgterNm,
      trgterCd,
      hdong,
      brthdy,
      gender,
      telno,
      adres,
      adresDtl
    );
  }

  get action(): ServicePlanAction {
    switch (this.statCd) {
      case "현장방문":
        return "필요도평가";
      case "돌봄계획":
        return "계획수립";
      case "사례회의(구)":
      case "사례회의(권역)":
      case "사례회의(동)":
        return "회의기록";
      case "서비스의뢰":
        return "의뢰";
      case "돌봄계획확정":
        return "계획확정";
      case "종료":
        return "";
    }
  }
}

export type ServicePlanStatus =
  | "현장방문"
  | "돌봄계획"
  | "사례회의(동)"
  | "사례회의(권역)"
  | "사례회의(구)"
  | "서비스의뢰"
  | "돌봄계획확정"
  | "종료";

export type ServicePlanAction =
  | "계획수립"
  | "필요도평가"
  | "회의기록"
  | "의뢰"
  | "계획확정"
  | "";

export const ServicePlanStatusValues = [
  "현장방문",
  "돌봄계획",
  "사례회의(동)",
  "사례회의(권역)",
  "서비스의뢰",
  "돌봄계획확정",
  // "종료",
];

export default ServicePlan;
