import React from "react";
import ExpenseAgencyManagement from "../../models/bill/ExpenseAgencyManagement";
import GuBillModel from "../../models/bill/GuBillModel";

/**
 * 작성자: 홍준표
 * 작성일: 2023.03.02
 * 
 * 지급페이지 상단 레이아웃
**/
const PaymentInfoArea = ({guItem} : {guItem: GuBillModel}) => {
    return (
      <article className="info">
        <ul>
          <li><p className="gray">제공기관</p><p>{guItem.departName}</p></li>
          <li className="x2"><p className="gray">소재지</p><p>{guItem.locality ? guItem.locality : "데이터없음"}</p></li>
          <li><p className="gray">담당자</p><p>{guItem.userName}</p></li>
          <li><p className="gray">담당자 연락처</p><p>{guItem.telno}</p></li>
          <li><p className="gray">지급신청일</p><p>{guItem.billDate}</p></li>
          <li></li>
          <li><p className="gray">은행명</p><p>{guItem.bank ?? "데이터없음"}</p></li>
          <li><p className="gray">예금주</p><p>{guItem.accountHolder ?? "데이터없음"}</p></li>
          <li><p className="gray">계좌번호</p><p>{guItem.accountNo ?? "데이터없음"}</p></li>
        </ul>
      </article>
    );
  
};

export default PaymentInfoArea;
