 import moment, { Moment } from "moment";

// import { Expose } from 'class-transformer';
// import CaseManager from "./CaseManager";
// import ServiceTarget from "./ServiceTarget";
import Gender from "./Gender";
import ServicePlan from "./ServicePlan";
import ServicePlanItem from "./ServicePlanItem";
import ServiceTarget from "./ServiceTarget";

// /**
//  * 작성자: 김혜정
//  * 작성일: 2023.02.24
//  *
//  * {@link ServicePage}
//  * 테이블에 그려지는 목록에 필요한 정보를 추상화한 interface 입니다.
//  */

class Service {
  constructor(
    public targetInfo: ServiceTargetInfo,
    public detail: ServiceDetail,
    public list: ServiceList[]
  ) {}
}

export class ServiceTargetInfo {
  constructor(
    public syUid: number,
    public trgterNm: string,
    public brthdy: string,
    public gender: Gender,
    public telno: string,
    public adres: string,
    public adresDtl: string,
    public homeMember: string,
    public characterJson: string,
    public incomeLevel: string,
    public incomeLevel2: string,
    public emgcNm: string,
    public emgcRel: string,
    public emgcTel: string,
  ) {}

  get age() {
    const today = new Date();
    const birthDate = new Date(this.brthdy);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}

export class ServiceDetail {
  constructor(
    public spyUid: number,
    public serviceNm: string,
    public cateCd: string,
    public weekday: string,
    public cycleUnit: string,
    public totalCount: string,
    public beginDate: string,
    public endDate: string,
    public counselDate: string,
    public serviceDetail: string,
    // 여기부터 권합별로 수정하면서 추가한 것
    public departName: string,
    public currentTimes: number,
    public times: number,
    public mbrName: string, 
    public status: string, 
    public 목표달성여부: string, 
  ) {}

}

export class ServiceList {
  constructor(
    public spiUid: number,
    public spyUid: number,
    public siUid: number,
    public syUid: number,
    public sbUid: number,
    public dptUid: number,
    public mbrUid: number,
    public beginDt: Moment,
    public beginGpsCd: string,
    public endDt: Moment,
    public endGpsCd: string,
    public serviceDetail: string,
    public exChargeGbn: string,
    public useCost: number, //이용금액
    public pplCnt: string,  //투입인원
    public totalCost: number, //총금액
    public chngCnt: string,
    public chngRsn: string,
    public clclnSt: string,
    public serviceDate: string,
    public serviceTime: Moment,
    public currentStatus: string,
    public servicePlanYear: string,
    public serviceInfo: string,
    public serviceTargetYear: string,
    public servicePlanFileList: string,
    public times: number,
    public mbrName: string,
    public updCost: number,
    public updRsn: string,
    public stopYn: string,
    public stopRsn: string,
    public stopGbn: string,
  ) {}

  get processTime() {
    return this.beginDt.toString().split(" ")[1];
  }
}

//  export type ServiceStatus =
//   | "서비스접수"
//   | "서비스중"
//   | "서비스완료";

// export type ServiceProgress =
//  | "담당자 배정 필요"
//  | "결과보고 필요"
//  | "완료";

//  class Service extends ServiceTarget {
//   constructor(
//     public syUid: number,
//     public stUid: number,
//     public csUid: number | undefined,
//     public trgterNm: string,
//     public hdong: string,
//     public brthdy: string,
//     public gender: Gender,
//     public telno: string,
//     public adres: string,
//     public adresDtl: string,
//     public statCd: ServiceTargetYearStatus,
//     public crtDt: string | undefined,
//     public crtUid: number | undefined,
//     public crtNm: string | undefined,
//     public sbscWay: ServiceTargetRegistrationType,
//     public applcntNm: string | undefined,
//     public applcntRel: string | undefined,
//     public applcntTel: string | undefined,
//     public applcntReason: string | undefined,
//     public applcntEtc: string | undefined,
//     public counselDate: string | undefined,
//     public managers: CaseManager[]
//   ) {
//     super(stUid, syUid, sbscWay, trgterNm, hdong, brthdy, gender, telno, adres, adresDtl);
//   }

 
// }

// interface BaseService {
//   siUid : number,// 서비스 일련번호
//   hdong: string; 
//   provision: string;
//   serviceNm: string;
//   name: string;
//   birthday: string;
//   gender: "남" | "여";
//   phone: string;
//   address: string;
//   serviceCost: string;
//   provisionNumTimes: string;
//   status: "서비스접수" | "서비스중" | "서비스완료";
//   manager: string;
//   serviceDetail: string;
//   supportTime: string;
//   supportStart: Moment;  
//   supportEnd: Moment;  
//   // testDate: string;
//   testDate: Moment;

//   // getters
//   hasManager: boolean;
//   progress:  "담당자 배정 필요" | "결과 보고 필요" | "완료";
//   // careType: "긴급돌봄" | "광주돌봄" | "기존돌봄";
//   action: BaseServiceAction;
// }

// namespace BaseService {
//   export const mockList: BaseService[] = [
//     {
//       siUid: 0,
//       hdong: "서남동",
//       provision: "A기관",
//       serviceNm: "가사활동",
//       name: "홍길동",
//       birthday: "1945.01.01",
//       gender: "남",
//       phone: "010-1212-3434",
//       address: "광주광역시 서구 상무평화로 89, 203호(치평동, 상무구영드림빌)",
//       serviceCost: "전액지원",
//       provisionNumTimes: "0/20",
//       status: "서비스접수",
//       manager: "",
//       serviceDetail: "가사지원>가사활동",
//       supportTime: "주2회/월,화/1시간",
//       // supportPeriod: "2023.01.09 ~ 2023.02.08",
//       supportStart: moment("2023.01.09", "yyyy.MM.DD"),
//       supportEnd: moment("2023.02.08", "yyyy.MM.DD"),
//       // testDate: "2023.01.02(월)",
//       // testDate: moment("2023.01.02(월)","yyyy.MM.dd(DD)"),
//       testDate: moment("2023.01.02","yyyy.MM.DD(dd)"),
//       hasManager: false,
//       progress: "담당자 배정 필요",
//       // careType: "긴급돌봄" 
//       action: "",
//     },
//     {
//       siUid: 1,
//       hdong: "운암동",
//       provision: "B기관",
//       serviceNm: "방문목욕(욕조O)",
//       name: "홍길동",
//       birthday: "1945.01.01",
//       gender: "남",
//       phone: "010-1212-3434",
//       address: "광주광역시 서구 상무평화로 89, 203호(치평동, 상무구영드림빌)",
//       serviceCost: "전액지원",
//       provisionNumTimes: "0/1",
//       status: "서비스중",
//       manager: "김담당",
//       serviceDetail: "가사지원>가사활동",
//       supportTime: "주2회/월,화/1시간",
//       // supportPeriod: "2023.01.09 ~ 2023.02.08",
//       supportStart: moment("2023.01.09", "yyyy.MM.DD"),
//       supportEnd: moment("2023.02.08", "yyyy.MM.DD"),
//       // testDate: moment("2023.01.02(월)","yyyy.MM.dd(DD)"),
//       testDate: moment("2023.01.02","yyyy.MM.DD(dd)"),
//       hasManager: true,
//       progress: "결과 보고 필요",
//       // careType: "광주돌봄"
//       action: "결과보고",
//     },
//   ];
// }

export default Service;
