import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import ServiceTargetAPI from "../../apis/ServiceTargetAPI";
import CloseIcon from "../../assets/ic_close.png";
import useMember from "../../hooks/useMember";
import { DialogProps } from "../DialogProvider";

// 종료 팝업

interface CancelScheduleDialogProps extends DialogProps {
  syUid: number;
}

interface CancelScheduleFormInput {
  stopGbn: string;
  stopReason: string;
}

const FinishDialog = ({
  syUid,
  ...props
}: CancelScheduleDialogProps) => {
  const navigate = useNavigate();
  const { member } = useMember();
  const { register, handleSubmit } = useForm<CancelScheduleFormInput>();

  const handleClose = () => {
    props.onClose?.();
  };

  const onSubmit: SubmitHandler<CancelScheduleFormInput> = async (data) => {
    console.log(data);
    // TODO: 유효성 검사

    const success = await ServiceTargetAPI.cancel({ syUid, ...data });

    if (success) {
      navigate(0);
    } else {
      console.log(">>> failure");
    }
  };

  return (
    <div className="stop_popup popup_black" {...props}>
      <div className="stop_popup_inner popup_white">
        <div className="popup_tit">
          <h3>종료</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="default_input">
              <h4 style={{fontWeight: 600}}>종료사유</h4>
              <div className="write_box">
                <select className="essential "  {...register("stopGbn")}
                style={{ color: 'rgb(0, 0, 0)', height:40, border: '1px solid #e6e8ea', backgroundColor: '#f6f7f9', fontSize:'15',
                        borderRadius: '5px', width: "340px"}} >
                  <option value="">선택</option>
                  <option value="돌봄 불필요 판단">돌봄 불필요 판단</option>
                  <option value="본인 거부">본인 거부(3회 이상)</option>
                  <option value="비거주">비거주</option>
                  <option value="이사">전출</option>
                  <option value="사망">사망</option>
                  <option value="시설 입소">시설 입소</option>
                  <option value="장기입원">장기입원</option>
                </select>
              </div>
            </div>
            <div className="default_input">
              <h4></h4>
              <div className="write_box">
                <textarea
                  placeholder="종료사유 입력"
                  className="mt8"
                  {...register("stopReason")}
                ></textarea>
              </div> 
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="submit" className="submit">
                저장
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FinishDialog;
