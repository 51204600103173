import moment from "moment";
import NeedsAssessmentJoin from "../../../../../models/NeedsAssessmentJoin";

const NeedAsTabTable3 = ({
    evaluationData,
  }: {
    evaluationData?: NeedsAssessmentJoin;
  }) => {
    const beginDt = moment(evaluationData?.counselResult.beginDt).format(
      "YYYY.MM.DD(dd)"
    );
    return (
      <div className="tab_content" data-id={2}>
        <p className="date">평가일 : {beginDt}</p>
        <div className="choice_result">
          <h4>식사 및 영양 상태</h4>
          <ul>
            <li>
              <div>
                <p>하루에 1끼 이하 불규칙적으로 식사한다.</p>
                <span>{evaluationData?.jsonData.q6_1}</span>
              </div>
              <div>
                <p>건강과 영양을 고려한 좋은 음식을 먹기 어렵다.</p>
                <span>{evaluationData?.jsonData.q6_2}</span>
              </div>
            </li>
            <li>
              <div>
                <p>거의 매번 혼자서 식사하는 편이다.</p>
                <span>{evaluationData?.jsonData.q6_3}</span>
              </div>
              <div>
                <p>음식을 씹거나 삼키는 것이 힘들다.</p>
                <span>{evaluationData?.jsonData.q6_4}</span>
              </div>
            </li>
          </ul>
        </div>
        <div className="choice_result mt20">
          <h4>주거 환경</h4>
          <ul>
            <li>
              <div>
                <p>방바닥 미끄럼</p>
                <span>{evaluationData?.jsonData.q7_1}</span>
              </div>
              <div>
                <p>욕실바닥 미끄럼</p>
                <span>{evaluationData?.jsonData.q7_2}</span>
              </div>
            </li>
            <li>
              <div>
                <p>문턱 단차</p>
                <span>{evaluationData?.jsonData.q7_3}</span>
              </div>
              <div>
                <p>변기 등 안전손잡이</p>
                <span>{evaluationData?.jsonData.q7_4}</span>
              </div>
            </li>
            <li>
              <div>
                <p>주방 가스 기구</p>
                <span>{evaluationData?.jsonData.q7_5}</span>
              </div>
              <div>
                <p>청결한 주거환경(대청소 필요성)</p>
                <span>{evaluationData?.jsonData.q7_6}</span>
              </div>
            </li>
            <li>
              <div>
                <p>바이러스·해충 위험(방역·방충 필요성)</p>
                <span>{evaluationData?.jsonData.q7_7}</span>
              </div>
            </li>
            <li>
              <div className="w100">
                <p style={{ width: "calc(50% - 75px)" }}>
                  기타 간단 수리가 필요한 항목
                </p>
                <span
                  style={{
                    marginLeft: 0,
                    textAlign: "left",
                    width: "calc(50% + 75px)",
                  }}
                >
                  {evaluationData?.jsonData.q7_8}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  export default NeedAsTabTable3