import moment, { Moment } from "moment";
import { format } from "path/posix";
import ServiceTarget, { ServiceTargetRegistrationType } from "./ServiceTarget";
import Gender from "./Gender";

export type EvaluationStatus =
  | "평가필요"
  | "평가불필요"
  | "평가완료";
  
// export const EvaluationStatusValues = [
//  "평가필요",
//  "평가불필요",
//  "평가완료"
// ];

export const EvaluationStatusValues = [
   {label: "평가필요", value: "11"},
   {label: "평가불필요", value: "22"},
   {label: "평가완료", value: "33"},
 ];

export type processIdStatus =
|"재수립" |"사례종결";

class EvaluationList extends ServiceTarget{
  constructor(
    public serviceTarget : ServiceTarget,
    public spyUid: number,
    public sdUid: number,
    public investigator: string,
    public evalDt: string,
    public costSupportYn: string,
    public rspndGbn : string,
    public rspndNm: string,
    public rspndTel: string,
    public srvcStsfctn : string,
    public srvcStsfctnRsn: string,
    public resultJson: string,
    public hdong: string,
    public serviceNm: string,
    public trgterNm: string,
    public brthdy: string,
    public gender: Gender,
    public telno: string,
    public adres: string,
    public adresDtl: string,
    public serviceStatCd: string ,
    public resultEvalStatus: string,
    public startIndex: string,
    public pageSize: number,
    public page: number,
    public totalCount: number,
    public syUid: number,
    public stUid: number,
    public sbscWay: ServiceTargetRegistrationType,
    public trgterCd: string,
    public processId: number,
    public status: string,
    public providedCnt: number,
    public providedCnt2: number,
    public statCd: string,
    public currentEvalCount: number,
    public totalEvalCount: number,
  ) {
    super(stUid, syUid, sbscWay, trgterNm, trgterCd, hdong, brthdy, gender, telno, adres, adresDtl);
  }
}

// export type EvaluationAction = "서비스평가";

// interface BaseEvaluation {
//     spyUid: number;
//     hdong: string;
//     serviceCase: string;
//     name: string;
//     brthdy: string;
//     gender: "남" | "여";
//     phone: string;
//     address: string;
//     serviceResult: "서비스제공완료" | "중단" ;
//     status: EvaluationStatus;
//     serviceDate: Moment;
//     followUp: "재수립" | "사후관리" | "사례종결";
//     testYn: boolean;
//     progress: "평가필요" | "평가불필요" | "평가완료";
//     followUpYn: boolean;
//     sdUid: number;
//     csUid: number;
//     syUid: number;
//     stUid: number;
// }
// namespace BaseEvaluation {
//     export const followUpList: BaseEvaluation[] = [
//         {
//             spyUid: 1,
//             hdong: "운임동",
//             serviceCase: "가사활동",
//             name: "김행배",
//             brthdy: "1948.02.15",
//             gender: "남",
//             phone: "010-4545-6565",
//             address: "광주광역시 서구 상무평화로 89, 203호(치평동, 상무구영드림빌)",
//             serviceResult: "서비스제공완료",
//             status: "평가필요",
//             serviceDate: moment(),
//             followUp: "재수립",
//             testYn: false,
//             progress: "평가필요",
//             followUpYn: true,
//             sdUid: 1,
//             csUid: 3,
//             syUid: 4,
//             stUid: 4,
//         },
//         {
//             spyUid: 2,
//             hdong: "치평동",
//             serviceCase: "병원동행",
//             name: "김광배",
//             brthdy: "1955.04.11",
//             gender: "남",
//             phone: "010-4345-6545",
//             address: "광주광역시 서구 상무평화로 89, 203호(치평동, 상무구영드림빌)",
//             serviceResult: "서비스제공완료",
//             status: "평가완료",
//             serviceDate: moment(),
//             followUp: "사후관리",
//             testYn: true,
//             progress: "평가완료",
//             followUpYn: false,
//             sdUid: 2,
//             csUid: 4,
//             syUid: 5,
//             stUid: 5,
//         },
//         {
//             spyUid: 3,
//             hdong: "치평동",
//             serviceCase: "병원동행",
//             name: "김광배",
//             brthdy: "1955.04.11",
//             gender: "남",
//             phone: "010-4345-6545",
//             address: "광주광역시 서구 상무평화로 89, 203호(치평동, 상무구영드림빌)",
//             serviceResult: "중단",
//             status: "평가불필요",
//             serviceDate: moment(),
//             followUp: "사례종결",
//             testYn: true,
//             progress: "평가불필요",
//             followUpYn: true,
//             sdUid: 3,
//             csUid: 5,
//             syUid: 6,
//             stUid: 6,
//         },
//     ];
// };
export default EvaluationList;