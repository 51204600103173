class ServiceInfo {
  constructor(
    public siUid: number,
    public dptUid: number,
    public dbGubunCd: "기존돌봄" | "광주돌봄" | "긴급돌봄",
    public cateCd: string,
    public serviceNm: string,
    public serviceDetail: string,
    public holyGbn: "매일" | "평일",
    public workTimeGbn: "24시간" | "09:00~18:00",
    public costGbn: "할증" | "고정" | "무료",
    public limitGbn: "포함" | "미포함",
    public standardGbn: "가구" | "개인",
    public unitGbn: "1시간" | "1식" | "1회" | "연간" | "1일",
    public unitCost: number,
    public useStatus: string,
    public cateCdText: string,
    public limitTimes: number,
  ) {}
}

export default ServiceInfo;
