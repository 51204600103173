import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { components } from "react-select";

import DollbomPlanAPI, { PrevPlans } from "../../../apis/DollbomPlanAPI";
import BackIcon from "../../../assets/ic_backlist.png";
import PlusIcon from "../../../assets/ic_plus.png";
import PopupIcon from "../../../assets/ic_popup.png";
import { useDialog } from "../../../dialogs/DialogProvider";
import DollbomPlanInfoDialog from "../../../dialogs/service-plan/DollbomPlanInfoDialog";
import DollbomSupportDialog from "../../../dialogs/service-plan/DollbomSupportDialog";
import DollbomTargetInfoDialog from "../../../dialogs/service-plan/DollbomTargetInfoDialog";
import useMember from "../../../hooks/useMember";
import ConferenceInfo from "../../../models/ConferenceInfo";
import DongConference from "../../../models/DongConference";
import NeedsAssessmentJoin from "../../../models/NeedsAssessmentJoin";
import ServicePlanDetails from "../../../models/ServicePlanDetails";
import ServiceTargetYear from "../../../models/ServiceTargetYear";
import DollbomAreaConferenceFormInput from "../../../models/form/DollbomAreaConferenceFormInput";
import DollbomDongConferenceFormInput from "../../../models/form/DollbomDongConferenceFormInput";
import "../service-plan/DollbomAreaConferencePage.scss";
import CarePlanTable from "./DollbomPlanComponents/CarePlanTable";
import ServiceResult from "./DollbomPlanComponents/ServiceResult";
import UseServiceTab from "./DollbomPlanComponents/TabTableComponents/UseServiceTab";
import NeedAsTabTable1 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable1";
import NeedAsTabTable2 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable2";
import NeedAsTabTable3 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable3";
import NeedAsTabTable4 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable4";
import NeedAsTabTable5 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable5";
import NeedAsTabTable6 from "./DollbomPlanComponents/TabTableComponents/NeedAsTabTable6";
import NeedAsBottom from "./DollbomPlanComponents/TabTableComponents/NeedAsBottom";
import TargetInfoBar from "./DollbomPlanComponents/TargetInfoBar";

const DollbomAreaConferencePage = () => {
  const { member } = useMember();
  const [searchParams] = useSearchParams();
  const { page } = useParams();
  const [selected, setSelected] = useState([]);
  const [details, setDetails] = useState<ServicePlanDetails>();
  const [detailLoad, setDetailLoad] = useState(true)
  const [targetInfo, setTargetInfo] = useState<ServiceTargetYear>();
  const [evaluationData, setEvaluationData] = useState<NeedsAssessmentJoin>();
  const navigate = useNavigate();
  const [tabTable, setTabTable] = useState(0);
  // 추가지원 검토서 여부
  const [sprtMore, setSprtMore] = useState(false);
  // 기존돌봄 불러오는 State
  const [basicValue, setBasicValue] = useState(String);
  const [basicValueInfo, setBasicValueInfo] = useState(String);
  // 기존돌봄 여부
  const [basicBoolean, setBasicBoolean] = useState(false);
  // 긴급돌봄 불러오는 State
  const [emerCount, setEmerCount] = useState(Number);
  const [emerBeginDt, setEmerBeginDt] = useState(String);
  const [emerEndDt, setEmerEndDt] = useState(String);
  const [emerTime, setEmerTime] = useState(Number);
  const [emerCost, setEmerCost] = useState(Number);
  // 긴급돌봄 여부
  const [emerBoolean, setEmerBoolean] = useState(false);
  // 광주 + 돌봄 여부
  const [gjBoolean, setGjBoolean] = useState(false);
  // 광주+돌봄 계획비용 State
  const [totalCost, setTotalCost] = useState(0);
  const [conferenceDate, setConferenceDate] = useState("");
  const [carePlan, setCarePlan] = useState<DongConference>();
  const [isCaseMeetingNeeded, setCaseMeetingNeeded] = useState("지원승인");
  // 긴급돌봄 잔액 State
  const [emerChange, setEmerChange] = useState(600000);
  // 광주+돌봄 잔액 State
  const [gjChange, setGjChange] = useState(1500000);
  // 질병 및 증상 추가한거
  const [illAddList, setIllAddList] = useState<string[]>([]);

  // 만 나이 계산
  const [targetBrthdy, setTargetBrthdy] = useState(0);
  const [targetMonth, setTargetMonth] = useState(0);
  const [targetDay, setTargetDay] = useState(0);

  //이전 돌봄계획 데이터
  const [prevPlans, setPrevPlans] = useState<PrevPlans>({} as PrevPlans);
  const [prevPlansLoading, setPrevPlansLoading] = useState(false)

  useEffect(() => {
    DollbomPlanAPI.getDetails({
      sdUid: Number(sdUid),
      csUid: Number(csUid),
    }).then((res) => {
      setDetails(res);
      // console.log(res);
      setTargetInfo(details?.targetInfo);
      // 만 나이 계산
      setTargetBrthdy(Number(res.targetInfo.brthdy.split("-")[0]));
      setTargetMonth(Number(res.targetInfo.brthdy.split("-")[1]));
      setTargetDay(Number(res.targetInfo.brthdy.split("-")[2]));
      setDetailLoad(false)
    });
  }, [page]);
  // useEffect(() => {}, [page]);

  useEffect(() => {
    DollbomPlanAPI.getEvaluationData({
      sdUid: Number(sdUid),
      csUid: Number(csUid),
    }).then((res) => {
      setEvaluationData(res);
      setIllAddList(JSON.parse(res.jsonData.illAdd));
      // console.log(res.jsonData.illAdd);
      // console.log(">>> evaluation", res);
    });
  }, []);

  // 잔액 계산
  useEffect(() => {
    setTimeout(() => {
      let sum = 0;
      const rows =
        document.querySelectorAll<HTMLTableCellElement>(".addTable tr");
      // console.log("rows >>>>>>>", rows);
      rows.forEach((row) => {
        const testCost = row.querySelectorAll<HTMLTableCellElement>("td");
        const getInputTag = row.querySelectorAll<HTMLInputElement>("input");
        let curCateCd = ""
          let curSiUid = ""
          getInputTag.forEach((input) => {
            if(input.className === "cateCd") {
              curCateCd = input.value
            }
            if(input.className === "siUid") {
              curSiUid = input.value
            }
          })
          // console.log("cateCd : ", curCateCd)
          if(Number(curCateCd) !== 3 && Number(curCateCd) !== 5 && Number(curCateCd) !== 6) {
            sum += Number(testCost[3].textContent?.split("원")[0].trim().replace(/,/g, ""))
          }
        
        // if (testCost[2].textContent != "-") {
        //   const testCost2 = Number(
        //     testCost[3].textContent?.split("원")[0].trim().replace(/,/g, "") ||
        //     ""
        //   );
        //   sum += testCost2;
        // }
        // console.log("aaaaa", testCost2);
      });
      setTotalCost(sum);
      // console.log("sum >>>", totalCost);
    }, 500);
  }, [totalCost]);

  useEffect(() => {
    DollbomPlanAPI.getDongConferencePlan({
      syUid: Number(syUid),
      csUid: Number(csUid),
    }).then((res) => {
      // console.log(">>> carePlan", res);
      setCarePlan(res);
      const gjList = res.carePlan.filter((service) => service.cateCd >= 1 && service.cateCd <= 7).length
      // console.log(gjList);
      if (gjList > 0) {
        //  console.log("광주돌봄");
        setGjBoolean(true);
      }
      if (res.carePlan.filter((service) => service.cateCd == 0).length >= 1) {
        setBasicBoolean(true);
        setBasicValue(
          res.carePlan
            .filter((service) => service.cateCd == 0)[0]
            .serviceDetail.split("/")[0]
        );
        setBasicValueInfo(
          res.carePlan
            .filter((service) => service.cateCd == 0)[0]
            .serviceDetail.split("/")[1]
        );
      }

      if (res.carePlan.filter((service) => service.cateCd == 8).length >= 1) {
        setEmerBoolean(true);
        setEmerCount(
          Number(
            res.carePlan.filter((service) => service.cateCd == 8)[0]
              .totalCount ?? 0
          )
        );
        setEmerBeginDt(
          res.carePlan.filter((service) => service.cateCd == 8)[0].beginDate
        );
        setEmerEndDt(
          res.carePlan.filter((service) => service.cateCd == 8)[0].endDate
        );
        setEmerTime(
          res.carePlan.filter((service) => service.cateCd == 8)[0].times
        );
        setEmerCost(
          res.carePlan.filter((service) => service.cateCd == 8)[0].cost
        );
      }
    });

    DollbomPlanAPI.prevPlans({
      stUid: Number(stUid),
      syUid: Number(syUid),
    }).then((res) => {
      // console.log("이전 돌봄계획 : ", res)
      // setSafeSprtCharge(res.safeSprtCharge)
      // setSimpleFixCount(res.simpleFixCount)
      // setCleaningCount(res.cleaningCount)
      // setPestControlCount(res.pestControlCount)
      setPrevPlans(res)
      setPrevPlansLoading(true)
    })
  }, []);

  const sdUid = searchParams.get("sdUid");
  const csUid = searchParams.get("csUid");
  const syUid = searchParams.get("syUid");
  const stUid = searchParams.get("stUid");

  const { register, watch, handleSubmit, formState:{errors}, setError, setValue, getValues } =
    useForm<DollbomDongConferenceFormInput>();

  const handleBackPressed = () => {
    navigate(-1);
  };
  const date = watch("caseMeeting.mtDt");
  useEffect(() => {
    const dateString = date;
    const resultDate = new Date(dateString);
    const formattedDate = `${resultDate.getFullYear()}-${(
      "0" +
      (resultDate.getMonth() + 1)
    ).slice(-2)}-${("0" + resultDate.getDate()).slice(-2)} ${(
      "0" + resultDate.getHours()
    ).slice(-2)}:${("0" + resultDate.getMinutes()).slice(-2)}:${(
      "0" + resultDate.getSeconds()
    ).slice(-2)}`;
    // console.log("date>>>>>>", date);
    setTimeout(() => {
      setConferenceDate(formattedDate);
      // console.log("conferenceDate>>>>>>", formattedDate);
    }, 1000);
  }, [date]);

  const [openDialog, setOpenDialog] = useState(false);
  const { showDialog } = useDialog();

  const handleDollbomSupport = () => {
    showDialog(DollbomSupportDialog);
  };
  const onSubmit: SubmitHandler<DollbomDongConferenceFormInput> = async (
    data
  ) => {
    if (window.confirm("저장 하시겠습니까?")) {
      if (!member) {
        return;
      }

      const success = await DollbomPlanAPI.insertAreaConference({
        syUid: Number(syUid),
        // sdUid: Number(sdUid),
        // nextYn: isCaseMeetingNeeded === "지원승인" ? "Y" : "N",
        stepCd: "사례회의(권역)",
        stepCdNext: "돌봄계획확정",
        caseMeeting: {
          ...data.caseMeeting,
          mtDt: conferenceDate,
          writerDept: "소속",
          writerNm: member.userName,
        },
      });

      if (success) {
        navigate("/service-plan", { replace: true });
      } else {
        console.log(">>> failure");
      }
    }
  };
  const [firstFocus, setFirstFocus] = useState(true)

  const handleFocus = (event: any) => {
    event.target.type = "datetime-local";
    if(firstFocus){
      setValue('caseMeeting.mtDt', moment().format('yyyy-MM-DD') + `T${moment().format('hh:mm')}`)
      setFirstFocus(false)
    }
  };

  const onError = () => {
    if(errors.caseMeeting?.mtPlace) {
      window.alert(errors.caseMeeting.mtPlace.message)
    } else if(errors.caseMeeting?.mtDt) {
      window.alert(errors.caseMeeting.mtDt.message)
    } else if(errors.caseMeeting?.attnd) {
      window.alert(errors.caseMeeting.attnd.message)
    } else if(errors.caseMeeting?.caseContent) {
      window.alert(errors.caseMeeting.caseContent.message)
    } else if(errors.caseMeeting?.agenda) {
      window.alert(errors.caseMeeting.agenda.message)
    } else if(errors.caseMeeting?.mtContent) {
      window.alert(errors.caseMeeting.mtContent.message)
    } else if(errors.caseMeeting?.puturePlan) {
      window.alert(errors.caseMeeting.puturePlan.message)
    }
  }

  return (
    <main className="DollbomAreaConferencePage">
      <section>
        <article className="detail_top">
          <div className="nav_list">
            <p>
              돌봄계획 / <span>사례회의(권역)</span>
            </p>
            <button
              type="button"
              className="mt8"
              onClick={handleBackPressed}
            >
              <span>
                <img src={BackIcon} alt="목록으로 돌아가기 아이콘" />
              </span>
              목록으로 돌아가기
            </button>
          </div>
          <div className="btn_wrap">
            <button
              type="submit"
              className="submit"
              onClick={handleSubmit(onSubmit, onError)}
            >
              저장
            </button>
          </div>
        </article>
        {!detailLoad && 
        <TargetInfoBar details={details!} targetMonth={targetMonth} targetBrthdy={targetBrthdy} targetDay={targetDay} />
        }   
        <article className="care_plan mt20">
          <div className="left mr20">
            <CarePlanTable
            basicBoolean={basicBoolean}
            basicValue={basicValue}
            basicValueInfo={basicValueInfo}
            emerBoolean={emerBoolean}
            emerCount={emerCount}
            emerChange={emerChange}
            emerCost={emerCost}
            emerTime={emerTime}
            emerBeginDt={emerBeginDt}
            emerEndDt={emerEndDt}
            gjBoolean={gjBoolean}
            gjChange={gjChange}
            carePlan={carePlan!}
            totalCost={totalCost}
            isSprtMore='N'
            />
            <div className="care_tab mt20">
              {/* 탭 */}
              <div className="tabs">
                {/* 탭 3개일때 tab_count3 4개일때 tab_count4 5개일때 tab_count5 */}
                <button
                  type="button"
                  className={`tab ${tabTable === 0 ? "tab_active" : "non_active"} 
                            ${sprtMore ? "tab_count5" : "tab_count4"}`}
                  onClick={() => setTabTable(0)}
                  data-id={0}
                >
                  서비스 결과 및 비용 지원
                </button>
                <button
                  type="button"
                  className={`tab ${tabTable === 1 ? "tab_active" : "non_active"} 
                  ${sprtMore ? "tab_count5" : "tab_count4"}`}
                  onClick={() => setTabTable(1)}
                  data-id={1}
                >
                  이용중인 서비스
                </button>
                <button
                  type="button"
                  className={`tab ${tabTable === 2 ? "tab_active" : "non_active"} 
                  ${sprtMore ? "tab_count5" : "tab_count4"}`}
                  onClick={() => setTabTable(2)}
                  data-id={2}
                >
                  돌봄필요도 평가
                </button>
                {sprtMore && (
                  <button
                    type="button"
                    className={`tab ${tabTable === 3 ? "tab_active" : "non_active"} tab_count4`}
                    onClick={() => setTabTable(3)}
                    data-id={3}
                  >
                    추가지원 검토서
                  </button>
                )}

                <button
                  type="button"
                  className={`tab
                                ${tabTable === 4
                      ? "tab_active" : "non_active"}  
                      ${sprtMore ? "tab_count5" : "tab_count4"
                    }`}
                  onClick={() => setTabTable(4)}
                  data-id={4}
                >
                  동 사례회의 기록지
                </button>
              </div>
              {/* 탭 뷰 */}
              {tabTable === 0 && (prevPlans.prevPlanBox ? <ServiceResult prevPlans={prevPlans} isSprtMore='N' />
               : <div className="service_result mt20">
                  <div className="padding">
                    이전 돌봄계획 없음
                  </div>
                </div>)}
              {tabTable === 1 && <TabTable2 details={details} />}
              {tabTable === 2 && <TabTable3 evaluationData={evaluationData} illAddList={illAddList} />}
              {tabTable === 3 && <TabTable4 />}
              {tabTable === 4 && <TabTable5 dongMeeting={carePlan} />}
              {/* 탭 뷰 종료 */}
              {/* 서비스 결과 및 비용 지원 */}

              {/* 이용중인 서비스 */}

              {/* 돌봄필요도 평가 */}

              {/* 추가지원 검토서 */}

              {/* 동 사례회의 기록지 */}
            </div>
          </div>
          <div className="right">
            <div className="meeting">
              <h4>사례회의 기록지</h4>
              <div className="meet01">
                <div className="text_input">
                  <p>회의 개요</p>
                  <div className="input_wrap">
                    <div
                      className="input_box mr8"
                      style={{ width: "50%" }}
                    >
                      <input
                        type="text"
                        className="essential w100"
                        placeholder="회의장소 입력"
                        {...register("caseMeeting.mtPlace", {
                          required: "회의 장소를 입력해주세요."
                        })}
                      />
                    </div>
                    <div className="input_box" style={{ width: "30%" }}>
                      <input
                        type="text"
                        id="birth"
                        className="essential w100"
                        placeholder="회의일시"
                        max={"9999-12-31T23:59"}
                        {...register("caseMeeting.mtDt", {
                          required: "회의 일시를 입력해주세요."
                        })}
                        onFocus={handleFocus}
                      />
                      {/* onfocus="(this.type='date')" onblur="(this.type='text')"  */}
                    </div>
                  </div>
                </div>
                <div className="text_input mt20">
                  <p>참석자</p>
                  <div className="input_wrap">
                    <div className="input_box" style={{ width: "100%" }}>
                      <input
                        type="text"
                        className="essential w100"
                        placeholder="참석자 입력(콤마로 구분하여 입력)"
                        {...register("caseMeeting.attnd", {
                          required: "회의 참석자를 입력해주세요."
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="text_input mt20">
                  <p>사례내용</p>
                  <div className="input_wrap">
                    <div className="input_box" style={{ width: "100%" }}>
                      <textarea
                        className="essential"
                        placeholder="돌봄필요도 평가, 돌봄계획 등에 따른 사례의 상황, 주요 욕구 등"
                        defaultValue={""}
                        {...register("caseMeeting.caseContent", {
                          required: "사례내용을 입력해주세요."
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="text_input mt20">
                  <p>
                    검토사항
                    <br />
                    (주요안건)
                  </p>
                  <div className="input_wrap">
                    <div className="input_box" style={{ width: "100%" }}>
                      <textarea
                        className="essential"
                        placeholder="사례회의에서 다룰 주요 쟁점"
                        defaultValue={""}
                        {...register("caseMeeting.agenda", {
                          required: "검토사항을 입력해주세요."
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="text_input mt20">
                  <p>
                    회의내용 및<br />
                    결정사항
                  </p>
                  <div className="input_wrap">
                    <div className="input_box" style={{ width: "100%" }}>
                      <textarea
                        className="essential"
                        placeholder="회의내용 및 결정사항 입력"
                        style={{ height: 110 }}
                        defaultValue={""}
                        {...register("caseMeeting.mtContent", {
                          required: "회의내용 및 결정사항을 입력해주세요."
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="text_input mt20">
                  <p>향후계획</p>
                  <div className="input_wrap">
                    <div className="input_box" style={{ width: "100%" }}>
                      <textarea
                        className="essential"
                        placeholder="향후계획 입력"
                        style={{ height: 90 }}
                        defaultValue={""}
                        {...register("caseMeeting.puturePlan", {
                          required: "향후계획을 입력해주세요."
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
    </main>
  );
};

const TabTable2 = ({ details }: { details?: ServicePlanDetails }) => {
  return (
    <div className="tab_content" data-id={1}>
      {/* < div className = "tab_content hidden" data-id={1}> */}
      <UseServiceTab details={details!} />
    </div>
  );
};
const TabTable3 = ({
  evaluationData,
  illAddList,
}: {
  evaluationData?: NeedsAssessmentJoin;
  illAddList: string[];
}) => {
  const [tabTable2, setTabTable2] = useState(0);
  const [subEvaluationData, setEvaluationData] =
    useState<NeedsAssessmentJoin>();
  useEffect(() => {
    setEvaluationData(evaluationData);
  }, []);
  return (
    <div className="padding tab_content" data-id={2}>
      {/* <div className="padding tab_content hidden" data-id={2}> */}
      <div className="care_evaluation">
        {/* 탭 */}
        <div className="tabs2">
          <button
            type="button"
            className={`tabs2 ${tabTable2 === 0 ? "tab_active" : "non_active"} `}
            onClick={() => setTabTable2(0)}
            data-id={0}
          >
            신체적 영역
          </button>
          <button
            type="button"
            className={`tabs2 ${tabTable2 === 1 ? "tab_active" : "non_active"} `}
            onClick={() => setTabTable2(1)}
            data-id={1}
          >
            정서적 영역
          </button>
          <button
            type="button"
            className={`tabs2 ${tabTable2 === 2 ? "tab_active" : "non_active"} `}
            onClick={() => setTabTable2(2)}
            data-id={2}
          >
            추가정보(생활환경)
          </button>
          <button
            type="button"
            className={`tabs2 ${tabTable2 === 3 ? "tab_active" : "non_active"} `}
            onClick={() => setTabTable2(3)}
            data-id={3}
          >
            추가정보(사전검사)
          </button>
          <button
            type="button"
            className={`tabs2 ${tabTable2 === 4 ? "tab_active" : "non_active"} `}
            onClick={() => setTabTable2(4)}
            data-id={4}
          >
            광주+돌봄 지원대상 여부
          </button>
          <button
            type="button"
            className={`tabs2 ${tabTable2 === 5 ? "tab_active" : "non_active"} `}
            onClick={() => setTabTable2(5)}
            data-id={5}
          >
            긴급돌봄 지원대상 여부
          </button>
        </div>
        {/* 탭 뷰 */}
        {tabTable2 === 0 && <NeedAsTabTable1 evaluationData={subEvaluationData} illAddList={illAddList} />}
        {tabTable2 === 1 && <NeedAsTabTable2 evaluationData={subEvaluationData} />}
        {tabTable2 === 2 && <NeedAsTabTable3 evaluationData={subEvaluationData} />}
        {tabTable2 === 3 && <NeedAsTabTable4 evaluationData={subEvaluationData} />}
        {tabTable2 === 4 && <NeedAsTabTable5 evaluationData={subEvaluationData} />}
        {tabTable2 === 5 && <NeedAsTabTable6 evaluationData={subEvaluationData} />}
        {/* 탭 뷰 종료 */}
        {/* 신체적 영역 */}

        {/* 정서적 영역 */}

        {/* 추가정보(생활환경) */}

        {/* 광주+돌봄 지원대상 여부 */}

        {/* 긴급돌봄 지원대상 여부 */}

        <NeedAsBottom evaluationData={subEvaluationData!} />
      </div>
    </div>
  );
};
const TabTable4 = () => {
  return (
    <div className="padding tab_content" data-id={3}>
      <h4>추가지원 검토서</h4>
      <table>
        <tbody>
          <tr>
            <th rowSpan={2} style={{ borderRight: '1px solid #e6e8ea' }}>연간 지원한도액<br />추가 지원 검토의견</th>
            <th>필요성</th>
            <td>필요성 입력된 내용 불러옴</td>
          </tr>
          <tr>
            <th>추가 지원액<br />주요 사용처</th>
            <td>추가 지원액 주요 사용처 입력된 내용 불러옴</td>
          </tr>
          <tr>
            <th rowSpan={2} style={{ borderRight: '1px solid #e6e8ea' }}>광주다움 통합돌봄<br />사례관리 사업비<br />사용 검토의견</th>
            <th>필요성</th>
            <td>필요성 입력된 내용 불러옴</td>
          </tr>
          <tr>
            <th>사례관리 사업비<br />주요 사용처</th>
            <td>사례관리 사업비 주요 사용처 입력된 내용 불러옴</td>
          </tr>
        </tbody>
      </table>
      <h4 className="mt20">권역별 사례회의 의견</h4>
      <table>
        <tbody>
          <tr>
            <th style={{ width: 280 }}>주요의견</th>
            <td>권역별 사례회의에서 작성된 의견 불러옴</td>
          </tr>
        </tbody>
      </table>
      <h4 className="mt20">승인여부</h4>
      <table>
        <tbody>
          <tr>
            <th style={{ width: 140 }}>승인결과</th>
            <td style={{ width: 140 }}>지원승인</td>
            <th style={{ width: 140 }}>사유</th>
            <td>입력된 내용 있을 시에만 불러옴</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
const TabTable5 = ({ dongMeeting }: { dongMeeting?: DongConference }) => {
  const crtDt = moment(dongMeeting?.dongMeeting.crtDt).format("YYYY.MM.DD(dd)");
  const mtDt = moment(dongMeeting?.dongMeeting.mtDt).format(
    "YYYY-MM-DD(dd) A HH:MM"
  );
  return (
    <div className="padding tab_content" data-id={4}>
      <p className="date">작성일 : {crtDt}</p>
      <div className="meeting">
        <h4>동 사례회의 기록지</h4>
        <div className="meet01">
          <div className="text_input">
            <p>회의 개요</p>
            <div className="input_wrap">
              <div
                className="input_box mr8"
                style={{ width: "50%" }}
              >
                <input
                  type="text"
                  className="w100"
                  placeholder="회의장소 입력"
                  value={dongMeeting?.dongMeeting.mtPlace}
                  disabled
                />
              </div>
              <div className="input_box" style={{ width: "30%" }}>
                <input
                  type="text"
                  id="birth"
                  className="w100"
                  placeholder="회의일시"
                  value={mtDt}
                  disabled
                />
                {/* onFocus="(this.type='date')" onblur="(this.type='text')" */}
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>참석자</p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <input
                  type="text"
                  className="w100"
                  placeholder="참석자 입력(콤마로 구분하여 입력)"
                  value={dongMeeting?.dongMeeting.attnd}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>사례내용</p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="돌봄필요도 평가, 돌봄계획 등에 따른 사례의 상황, 주요 욕구 등"
                  defaultValue={""}
                  value={dongMeeting?.dongMeeting.caseContent}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>
              검토사항
              <br />
              (주요안건)
            </p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="사례회의에서 다룰 주요 쟁점"
                  defaultValue={""}
                  value={dongMeeting?.dongMeeting.agenda}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>
              회의내용 및<br />
              결정사항
            </p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="회의내용 및 결정사항 입력"
                  style={{ height: 110 }}
                  defaultValue={""}
                  value={dongMeeting?.dongMeeting.mtContent}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text_input mt20">
            <p>향후계획</p>
            <div className="input_wrap">
              <div className="input_box" style={{ width: "100%" }}>
                <textarea
                  placeholder="향후계획 입력"
                  style={{ height: 90 }}
                  defaultValue={""}
                  value={dongMeeting?.dongMeeting.puturePlan}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DollbomAreaConferencePage;
