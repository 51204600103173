import React, { useState } from "react";
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ExpenseModelAPI from "../../apis/ExpenseModelAPI";
import { telValid } from "../../App";

import CloseIcon from "../../assets/ic_close.png";
import useMember from "../../hooks/useMember";
import { DialogProps } from "../DialogProvider";


/**
 * 서비스 비용 수정 다이얼로그
 * 작성자 : 홍준표
 * 작성일 : 2023.04.18
 */

interface PayCostBillDialogProps extends DialogProps {
  spyUid: number;
  spiUid: number;
  sbUid: number;
  dptUid: number;
}

interface FormInput {
  payCost: number;
  payRsn: string;
}

const PayCostBillDialog = ({
  spyUid,
  spiUid,
  sbUid,
  dptUid,
  ...props
}: PayCostBillDialogProps) => {
  const navigate = useNavigate();
  const { member } = useMember();
  const { register, handleSubmit, formState: {errors}, setValue } = useForm<FormInput>();
  const [inputCost, setInputCost] = useState("")

  const handleClose = () => {
    props.onClose?.();
  };


  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    console.log(data);
    if(data.payCost === undefined) {
      window.alert("변경 금액을 입력해주세요.")
      return
    }
    // TODO: 유효성 검사

    let suc = await ExpenseModelAPI.editPayCost({
      ...data,
      spyUid: spyUid,
      spiUid: spiUid,
      sbUid: sbUid,
      dptUid: dptUid,
    })

    if(suc) {
      window.alert("금액 수정 완료")
      navigate(0);
    } else {
      window.alert("금액 수정 실패")
    }
  };

  const onError = (err: FieldErrors<FormInput>) => {
    err.payCost ? window.alert(err.payCost.message) : err.payRsn ? window.alert(err.payRsn.message) : console.log(err)
  }

  return (
    <div className="recive_popup popup_black" {...props}>
      <div className="recive_popup_inner popup_white">
        <div className="popup_tit">
          <h3>금액 수정</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="default_input">
              <h4 style={({fontWeight: 600})}>금액 수정</h4>
              <div className="write_box">
                <div className="name mr8 w50_8">
                  <input type="text" className="default_input"
                    placeholder="변경 금액"
                    value={inputCost}
                    onChange={(e:any) => {
                      if(!telValid.test((e.target.value).replaceAll(',', ''))) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                      let transform = Number((e.target.value).replaceAll(',', ''))
                      setInputCost(transform.toLocaleString('ko-KR'))
                      setValue("payCost", transform)
                    }}
                     />
                </div>
                
                <textarea 
                  placeholder="변경사유 입력"
                  className="mt8"
                  {...register("payRsn", {
                    required: "변경 사유를 입력해주세요.",
                  })}
                ></textarea>
              </div>
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="submit" className="submit">
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PayCostBillDialog;
