import { instanceToPlain } from "class-transformer";
import moment from "moment";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import ServiceTargetAPI from "../../apis/ServiceTargetAPI";
import { ValidCheck, nameValid, telValid } from "../../App";
import CloseIcon from "../../assets/ic_close.png";
import useMember from "../../hooks/useMember";
import ServiceTargetYear from "../../models/ServiceTargetYear";
import { DialogProps, useDialog } from "../DialogProvider";
import PostCodeDialog from "../PostCodeDialog";
import MemberAPI, { DepartVO } from "../../apis/MemberAPI";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.22
 *
 * (돌봄콜 or 창구) 접수 팝업
 * @param type 접수 방법
 * @param caseManagement (수정 시 필요) 사례관리
 */
interface EditServiceTargetDialogProps extends DialogProps {
  type: "돌봄콜" | "창구";
  caseManagement?: ServiceTargetYear;
}

interface EditServiceTargetFormInput {
  syUid: number;
  sbscWay: string; // 등록구분
  applcntNm: string; // 신청인 명
  applcntRel: string; // 신청인 관계
  applcntTel1: string; // 신청인 연락처
  applcntTel2: string;
  applcntTel3: string;
  applcntReason: string; // 신청사유
  applcntEtc: string; // 참고사항
  trgterNm: string; // 대상자 이름
  trgterCd: string; // 대상자 코드
  brthdy: string; // 대상자 생년월일
  gender: string; // 성별
  telno1: string; // 전화번호
  telno2: string;
  telno3: string;
  hdong: string; // 행정동
  adres: string; // 주소
  adresDtl: string;
  lat: number; // 위도
  lng: number; // 경도
}

const EditServiceTargetDialog = ({
  type,
  caseManagement,
  ...props
}: EditServiceTargetDialogProps) => {
  const { member } = useMember();
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const { register, handleSubmit, watch, setValue, setError, formState : {errors}, getValues } =
    useForm<EditServiceTargetFormInput>({
      defaultValues: {
        syUid: caseManagement?.syUid,
        sbscWay: caseManagement?.sbscWay,
        trgterNm: caseManagement?.trgterNm,
        applcntNm: caseManagement?.applcntNm,
        applcntRel: caseManagement?.applcntRel,
        applcntTel1: caseManagement?.applcntTel1,
        applcntTel2: caseManagement?.applcntTel2,
        applcntTel3: caseManagement?.applcntTel3,
        brthdy: caseManagement?.brthdy,
        gender: caseManagement?.gender,
        telno1: caseManagement?.telno1,
        telno2: caseManagement?.telno2,
        telno3: caseManagement?.telno3,
        hdong: caseManagement?.hdong,
        adres: caseManagement?.adres,
        adresDtl: caseManagement?.adresDtl,
        applcntReason: caseManagement?.applcntReason,
        applcntEtc: caseManagement?.applcntEtc,
      },
    });
  const data = watch();
  const paramCheck = () => {
    // console.log("이름 2차 검증값 : ", nameValid.test(getValues("applcntNm")))
    if(!nameValid.test(getValues("applcntNm"))) {
      // setInputCheck({stat: false, msg: "올바른 신청인 이름을 입력하세요."})
      return {stat: false, msg: "올바른 신청인 이름을 입력하세요."}
    } else if(!telValid.test(getValues("applcntTel2"))) {
      // setInputCheck({stat: false, msg: "올바른 신청인 연락처를 입력하세요."})
      return {stat: false, msg: "올바른 신청인 연락처를 입력하세요."}
    } else if(!telValid.test(getValues("applcntTel3"))) {
      // setInputCheck({stat: false, msg: "올바른 신청인 연락처를 입력하세요."})
      return {stat: false, msg: "올바른 신청인 연락처를 입력하세요."}
    } else if(!nameValid.test(getValues("trgterNm"))) {
      // setInputCheck({stat: false, msg: "올바른 대상자 이름을 입력하세요."})
      return {stat: false, msg: "올바른 대상자 이름을 입력하세요."}
    } else if(moment().diff(moment(getValues("brthdy")), 'hours') < 24) {
      // setInputCheck({stat: false, msg: "생년월일은 금일 이전으로 설정하세요."})
      return {stat: false, msg: "생년월일은 금일 이전으로 설정하세요."}
    } else if(!telValid.test(getValues("telno2"))) {
      // setInputCheck({stat: false, msg: "올바른 대상자 연락처를 입력하세요."})
      return {stat: false, msg: "올바른 대상자 연락처를 입력하세요."}
    } else if(!telValid.test(getValues("telno3"))) {
      // setInputCheck({stat: false, msg: "올바른 대상자 연락처를 입력하세요."})
      return {stat: false, msg: "올바른 대상자 연락처를 입력하세요."}
    } else {
      setInputCheck({stat: true, msg: ""})
    }
  }
  const [inputCheck, setInputCheck] = useState<ValidCheck>({stat: true, msg: ""})
  const [isEdit, setIsEdit] = useState(false)
  const [hdongDiff, setHdongDiff] = useState(false)
  const [selectHdong, setSelectHdong] = useState("")
  const [departList, setDepartList] = useState([] as DepartVO[])
  const [departArr, setDepartArr] = useState([] as string[])
  const [dongLoading, setDongLoading] = useState(true)
  const nameValidation = (e:any) => {
    // console.log("이름 검증값 : ", nameValid.test(e.target.value))
    nameValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "올바른 이름을 입력하세요."})
  }
  // const telValidation = (e:any) => {
  //   telValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  // }
  useEffect(() => {
    MemberAPI.getAllDept({
      deptCd: "DONG",
    }).then((res) => {
      // console.log(res)
      setDepartList(res)
      let tempArr:string[] = []
      res.map((depart) => {
        tempArr.push(depart.departName)
      })
      setDepartArr(tempArr)
      setDongLoading(false)
      if(caseManagement?.hdong) {
        setValue("hdong", caseManagement.hdong)
      }
    })
    // console.log(data)
    if(type === "창구" && caseManagement === undefined) {
      setValue("sbscWay", "창구(전화)")
    }
    if(caseManagement) {
      if(caseManagement.applcntRel === "본인") {
        setValue("applcntNm", caseManagement.trgterNm)
        setValue("applcntTel1", caseManagement.telno1)
        setValue("applcntTel2", caseManagement.telno2)
        setValue("applcntTel3", caseManagement.telno3)
      }
      paramCheck()
      setIsEdit(true)
    }
  }, [])

  // useEffect(() => {
  //   console.log(data)
  // }, [data])

  const handleAddressSearch = () => {
    showDialog(PostCodeDialog, {
      onComplete(address) {
        // console.log("주소 검색결과 : ", address)
        if(member?.depart?.deptCd === "DONG") {
          if(address.hname) {
            setHdongDiff(false)
            setValue("adres", address.roadAddress);
            setValue("hdong", address.hname)
          } else {
            if(member?.depart?.departName === address.bname2) {
              setHdongDiff(false)
              setValue("adres", address.roadAddress);
              setValue("hdong", address.bname2);
            } else {
              if(window.confirm(`선택한 주소의 소속동과 현재 접속계정의 행정동이 일치하지 않습니다.
                              계속 진행하시겠습니까?\n
            진행 시, 현재 접속된 계정의 행정동으로 접수됩니다.\n
      (입력 주소의 소속동 : ${address.bname2}, 현재 접속계정 행정동 : ${member?.depart?.departName})`)) {
                setValue("adres", address.roadAddress);
                setValue("hdong", member?.depart?.departName!)
                setSelectHdong(address.bname2)
                setHdongDiff(true)
              } else {
                return
              }
            }
          }
        } else {  //시, 구
          setValue("adres", address.roadAddress);
          if(departArr.includes(address.hname)) {
            setValue("hdong", address.hname)
          } else if(departArr.includes(address.bname2)) {
            setValue("hdong", address.bname2);
          }
        }
        
        // setValue("hdong", address.bname2);
      },
    });
  };

  const handleClose = () => {
    props.onClose?.();
  };

  const onSubmit: SubmitHandler<EditServiceTargetFormInput> = async (data) => {
    paramCheck()
    if(!inputCheck.stat) {
      window.alert(inputCheck.msg)
      return
    }
    let success = false;
    if (caseManagement) {
      success = await ServiceTargetAPI.update({
        ...data,
        sbscWay: data.sbscWay ?? type,
        applcntTel: `${data.applcntTel1}-${data.applcntTel2}-${data.applcntTel3}`,
        telno: `${data.telno1}-${data.telno2}-${data.telno3}`,
        trgterCd:
          data.trgterNm +
          data.brthdy.split("-").reduce((acc, c) => acc + c.slice(-2), ""),
        crtUid: member?.mbrUid as number,
        year: new Date().getFullYear(),
        applcntEtc: data.applcntEtc ? data.applcntEtc.length > 0 ? data.applcntEtc : "" : "",
      });
    } else {
      success = await ServiceTargetAPI.insert({
        ...data,
        sbscWay: data.sbscWay ?? type,
        applcntTel: `${data.applcntTel1}-${data.applcntTel2}-${data.applcntTel3}`,
        telno: `${data.telno1}-${data.telno2}-${data.telno3}`,
        trgterCd:
          data.trgterNm +
          data.brthdy.split("-").reduce((acc, c) => acc + c.slice(-2), ""),
        crtUid: member?.mbrUid as number,
        year: new Date().getFullYear(),
        applcntEtc: data.applcntEtc ? data.applcntEtc.length > 0 ? data.applcntEtc : "" : "",
      });
    }

    if (success) {
      // console.log("수정 데이터 확인")
      navigate(0);
    } else {
      // console.log(">>> failure");
      window.alert("접수 실패")
    }
  };

  const onError = () => {
    if(errors.applcntNm) {
      window.alert(errors.applcntNm.message)
    } else if(errors.applcntRel) {
      window.alert(errors.applcntRel.message)
    } else if(errors.applcntTel1) {
      window.alert(errors.applcntTel1.message)
    } else if(errors.applcntTel2) {
      window.alert(errors.applcntTel2.message)
    } else if(errors.applcntTel3) {
      window.alert(errors.applcntTel3.message)
    } else if(errors.trgterNm) {
      window.alert(errors.trgterNm.message)
    } else if(errors.brthdy) {
      window.alert(errors.brthdy.message)
    } else if(errors.gender) {
      window.alert(errors.gender.message)
    } else if(errors.telno1) {
      window.alert(errors.telno1.message)
    } else if(errors.telno2) {
      window.alert(errors.telno2.message)
    } else if(errors.telno3) {
      window.alert(errors.telno3.message)
    } else if(errors.adres) {
      window.alert(errors.adres.message)
    } else if(errors.applcntReason) {
      window.alert(errors.applcntReason.message)
    }
  }

  return (
    <div className="recive_popup popup_black" {...props}>
      <div
        className="recive_popup_inner popup_white"
        style={{ overflowY: "visible" }}
      >
        <div className="popup_tit">
          <h3>{type} 접수</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            {type === "창구" && (
              <div className="default_input">
                <h4>접수방법</h4>
                <div className="write_box">
                  <label htmlFor="radio11" className="mr20">
                    <input
                      type="radio"
                      id="radio11"
                      value="창구(전화)"
                      {...register("sbscWay")}
                    />
                    전화
                  </label>
                  <label htmlFor="radio22">
                    <input
                      type="radio"
                      id="radio22"
                      value="창구(방문)"
                      {...register("sbscWay")}
                    />
                    방문
                  </label>
                </div>
              </div>
            )}
            <div className="default_input mt10">
              <h4>신청인</h4>
              <div className="write_box">
                <input
                  type="text"
                  className="essential mr8 w50_8"
                  placeholder="성명"
                  // disabled={isEdit && getValues("applcntRel") === "본인" ? true : false}
                  {...register("applcntNm", {
                    required: "신청인 이름을 입력해주세요.",
                    onChange: (e:any) => {
                      nameValidation(e)
                      getValues("applcntRel") === "본인" ? setValue("trgterNm", e.target.value) : setValue("applcntNm", e.target.value)
                    }
                  })}
                />
                <input type="hidden" name="stUid" value="1" />
                <select
                  className="w50_8 essential"
                  style={{ color: data.applcntRel ? "#000" : "#9da0a8" }}
                  // disabled={isEdit && getValues("applcntRel") === "본인" ? true : false}
                  {...register("applcntRel", {
                    required: "신청인 관계를 선택해주세요.",
                    onChange: (e:any) => {
                      if(e.target.value === "본인") {
                        setValue("trgterNm", getValues("applcntNm"))
                        setValue("telno1", getValues("applcntTel1"))
                        setValue("telno2", getValues("applcntTel2"))
                        setValue("telno3", getValues("applcntTel3"))
                      }
                    }
                  })}
                >
                  <option value="">관계 선택</option>
                  <option value="본인">본인</option>
                  <option value="가족">가족</option>
                  <option value="이웃(지인)">이웃(지인)</option>
                  <option value="시설 등 종사자">시설 등 종사자</option>
                </select>
              </div>
            </div>
            <div className="default_input tel mt8">
              <h4></h4>
              <div className="write_box">
                <select
                  className="essential"
                  style={{ color: data.applcntTel1 ? "#000" : "#9da0a8" }}
                  // disabled={getValues("applcntRel") === "본인" ? true : false}
                  {...register("applcntTel1", {
                    onChange: (e:any) => {
                      // telValidation(e)
                      getValues("applcntRel") === "본인" ? setValue("telno1", e.target.value) : setValue("applcntTel1", e.target.value)
                    },
                    required: "신청인 번호를 입력해주세요."
                  })}
                >
                  <option value="">선택</option>
                  <option value="010">010</option>
                  <option value="011">011</option>
                  <option value="017">017</option>
                  <option value="018">018</option>
                  <option value="019">019</option>
                </select>
                <span>-</span>
                <input type="tel" minLength={3} maxLength={4}
                  className="essential"
                  placeholder="연락처"
                    // disabled={getValues("applcntRel") === "본인" ? true : false}
                    {...register("applcntTel2", {
                    onChange: (e:any) => {
                      if(!telValid.test(e.target.value)) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                      // telValidation(e)
                      getValues("applcntRel") === "본인" ? setValue("telno2", e.target.value) : setValue("applcntTel2", e.target.value)
                    },
                    required: "신청인 번호를 입력해주세요."
                  })} />
                <span>-</span>
                <input type="tel" minLength={4} maxLength={4}
                  className="essential"
                  placeholder="연락처"
                    // disabled={getValues("applcntRel") === "본인" ? true : false}
                    {...register("applcntTel3", {
                    onChange: (e:any) => {
                      if(!telValid.test(e.target.value)) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                      // telValidation(e)
                      getValues("applcntRel") === "본인" ? setValue("telno3", e.target.value) : setValue("applcntTel3", e.target.value)
                    },
                    required: "신청인 번호를 입력해주세요."
                  })} />
              </div>
            </div>
            <div className="default_input mt20">
              <h4>대상자</h4>
              <div className="write_box">
                <div
                  className="name mr8 w50_8"
                  style={{ position: "relative" }}
                >
                  <input
                    type="text"
                    className="essential input"
                    placeholder="성명"
                    list=""
                    role="combobox"
                    id="input1"
                    autoComplete="off"
                    disabled = {getValues("applcntRel") === "본인" ? true : false}
                    {...register("trgterNm", {
                      onChange: (e:any) => {
                        // nameValidation(e)
                      },
                      required: "대상자 이름을 입력해주세요."
                    })}
                  />
                </div>
                <div className="birth w50_8">
                  <input
                    type="date"
                    id="birth"
                    placeholder="생년월일"
                    max={"9999-12-31"}
                    disabled={isEdit ? true : false}
                    {...register("brthdy", {
                      required: "생년월일을 입력해주세요.",
                      onChange: (e) => {
                        if(moment().diff(moment(e.target.value), 'hours') < 24) {
                          window.alert("생년월일은 당일 일자 이전으로 설정해주세요.")
                          setValue("brthdy", moment().subtract(1, 'days').format('yyyy-MM-DD'))
                        }
                      },
                      onBlur: (e) => {
                        if(moment().diff(moment(e.target.value), 'hours') < 24) {
                          window.alert("생년월일은 당일 일자 이전으로 설정해주세요.")
                          setValue("brthdy", moment().subtract(1, 'days').format('yyyy-MM-DD'))
                        }
                      }
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="default_input">
              <h4></h4>
              <div className="write_box">
                <label htmlFor="radio1" className="mr20">
                  <input
                    type="radio"
                    id="radio1"
                    value="남"
                    // disabled={isEdit ? true : false}
                    {...register("gender", {
                      required: "성별을 입력해주세요."
                    })}
                  />
                  남성
                </label>
                <label htmlFor="radio2">
                  <input
                    type="radio"
                    id="radio2"
                    value="여"
                    // disabled={isEdit ? true : false}
                    {...register("gender", {
                      required: "성별을 입력해주세요."
                    })}
                  />
                  여성
                </label>
              </div>
            </div>
            <div className="default_input tel">
              <h4></h4>
              <div className="write_box">
                <select
                className="essential"
                  disabled = {getValues("applcntRel") === "본인" ? true : false}
                  style={{ color: data.telno1 ? "#000" : "#9da0a8" }}
                  {...register("telno1", {
                    onChange: (e:any) => {
                      // telValidation(e)
                    },
                    required: "대상자 번호를 입력해주세요."
                  })}
                >
                  <option value="">선택</option>
                  <option value="010">010</option>
                  <option value="011">011</option>
                  <option value="012">017</option>
                  <option value="013">018</option>
                  <option value="014">019</option>
                </select>
                <span>-</span>
                <input type="tel" maxLength={4}
                className="essential"
                placeholder="연락처"
                disabled = {getValues("applcntRel") === "본인" ? true : false}
                {...register("telno2", {
                    onChange: (e:any) => {
                      if(!telValid.test(e.target.value)) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                      // telValidation(e)
                    },
                    required: "대상자 번호를 입력해주세요."
                  })} />
                <span>-</span>
                <input type="tel" maxLength={4}
                className="essential"
                placeholder="연락처"
                disabled = {getValues("applcntRel") === "본인" ? true : false}
                {...register("telno3", {
                    onChange: (e:any) => {
                      if(!telValid.test(e.target.value)) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                      // telValidation(e)
                    },
                    required: "대상자 번호를 입력해주세요."
                  })} />
              </div>
            </div>
            <div className="default_input mt8">
              {(member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU") && getValues("adres") && !dongLoading ? 
              <h4>
                행정동
                <select
                 style={{height: "35px", width: "65px" ,borderRadius: "5px", border: "1px solid #e6e8ea", backgroundColor: " #f6f7f9"}}
                 {...register("hdong")}
                >
                  {departList.map((depart, idx) => (
                    <option key={idx} value={depart.departName}>{depart.departName}</option>
                  ))}
                </select>
              </h4>
                :
                <h4></h4>
              }
              <div className="write_box">
                <div className="address">
                  <input
                    type="text"
                    className="mr8 essential"
                    placeholder="주소 입력"
                    disabled
                    {...register("adres", {
                      required: "주소를 입력해주세요."
                    })}
                  />
                  <button type="button" onClick={handleAddressSearch}>
                    주소검색
                  </button>
                </div>
                {hdongDiff && member?.depart?.deptCd === "DONG" ? 
                <p style={{color: "red"}}>
                  ⛔입력 주소 소속동 : {selectHdong} / 현재 계정 행정동 : {member?.depart?.departName}
                </p> : null}
                <input
                    type="text"
                    className="mt8"
                    placeholder="상세주소 입력"
                    {...register("adresDtl")}
                  />
              </div>
            </div>
            <div className="default_input mt8">
              <h4>신청사유</h4>
              <div className="write_box">
                  <textarea
                    placeholder="신청사유 입력"
                    className="essential mt8"
                    {...register("applcntReason", {
                      required: "신청사유를 입력해주세요."
                    })}
                  ></textarea>
                </div>
            </div>
            <div className="default_input mt8">
              <h4>참고사항</h4>
              <div className="write_box">
                  <textarea
                    placeholder="참고사항 입력"
                    className="mt8"
                    {...register("applcntEtc")}
                  ></textarea>
                </div>
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="submit" className="submit">
                저장
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditServiceTargetDialog;
