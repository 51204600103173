import { Expose, instanceToPlain } from "class-transformer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/ic_close.png";
import useMember from "../../hooks/useMember";
import ServiceTargetYear from "../../models/ServiceTargetYear";
import { DialogProps, useDialog } from "../DialogProvider";
import PostCodeDialog from "../PostCodeDialog";
import { MultiSelect, Option } from "react-multi-select-component";
import SettingAPI, { ServiceSelect } from "../../apis/SettingAPI";
import SettingList from "../../models/SettingList";
import React, { useEffect, useRef, useState } from "react";
import "../../pages/member/settings/SettingsPage.scss";
import { isTemplateExpression } from "typescript";
import moment from "moment";
import { bankValid, telValid } from "../../App";


/**
 * 환경설정 > 제공기관 관리 > 기관등록 팝업
 */

 interface ProviderRegisterDialogProps extends DialogProps{
  providerInfo?: SettingList;
}

interface ServiceI {
  sInfo: Option[]
}

export class ProviderRegisterFormInput{
  constructor(
    public siUid: number,
    public serviceNm: string,
    public operType : string,
    public owner : string,
    public createDt : string | undefined,
    public companyNo : string,
    public accountHolder : string,
    public bank : string,
    public accountNo : string,
    public status : string,
    public crtUid : number,
    public dptUid : number,
    public deptCd : string,
    public adres: string,
    public adresDtl: string,
    public telno1: string, // 전화번호
    public telno2: string,
    public telno3: string,
    public departName : string,
    public serviceI: ServiceI,
    public serviceItems? : string[],
    public delUid?: number,
  ) {}
}

const ProviderRegisterDialog = ( {
  providerInfo,
  ...props
}: ProviderRegisterDialogProps) => {
  const navigate = useNavigate();
  const { member } = useMember();
  const { showDialog } = useDialog();
  const { register, handleSubmit, watch, setValue, control, formState:{errors}, setError } =
    useForm<ProviderRegisterFormInput>({
      defaultValues: {
        departName : providerInfo?.departName,
        deptCd: providerInfo?.deptCdString ? providerInfo?.deptCdString : "ORG",
        telno1: providerInfo?.telno1,
        telno2: providerInfo?.telno2,
        telno3: providerInfo?.telno3,
        adres: providerInfo?.adres,
        adresDtl: providerInfo?.adresDtl,        
        // createDt : providerInfo?.createDt,
        createDt : providerInfo?.createDt !== null ? providerInfo?.createDt : undefined,
        operType : providerInfo?.operType ? providerInfo?.operType : "법인",
        owner : providerInfo?.owner,
        companyNo : providerInfo?.companyNo,
        accountHolder : providerInfo?.accountHolder,
        bank : providerInfo?.bank,
        accountNo : providerInfo?.accountNo,
        crtUid : providerInfo?.crtUid,
        // serviceItems : providerInfo?.service.split(','),
      }
    });
  const data = watch();
  const [test, setTest] = useState([] as Option[])
  const [onFocus, setOnFocus] = useState(false)

  useEffect(()=> {
    // console.log("providerInfo : ", providerInfo)
    // SettingAPI.getProvider({ dptUid: Number(providerInfo?.dptUid)}).then((res) => {
    // });
    let serviceArr = [] as string[]
    if(providerInfo !== undefined) {
      if(providerInfo.service !== null) {
        serviceArr = (providerInfo.service).split(',')
      }
    }
    SettingAPI.getServiceSelect()
      .then((res) => {
        // console.log("ss : ", res)
        let temp:Option[] = [] as Option[]
        let initTemp:Option[] = [] as Option[]
        res.map((item) => {
          temp.push({label : item.serviceNm, value: item.siUid})
          if(serviceArr.length > 0) {
            serviceArr.map((sItem) => {
              if(item.serviceNm === sItem) {
                initTemp.push({label: item.serviceNm, value: item.siUid})
                return
              }
            })
          }
        })
        setTest(temp)
        setValue("serviceI.sInfo", initTemp)
    });
    if(providerInfo !== undefined) {
      if(providerInfo.service !== null) {
        // console.log("providerInfo.service : ", providerInfo.service)
        let arr:string[] = (providerInfo.service).split(',')
        setValue('serviceItems', arr)
      }
    }
  }, []);

  const handleAddressSearch = () => {
    showDialog(PostCodeDialog, {
      onComplete(address) {
         setValue("adres", address.roadAddress);
      },
    });
  };

  useEffect(() => {
    // console.log(data)
  }, [data])

  const handleClose = () => {
    props.onClose?.();
  };

  const onSubmit: SubmitHandler<ProviderRegisterFormInput> = async (data) => {
    // console.log(data.serviceItems);
    let delUid = member?.mbrUid
    if(data.status === "미사용") {
      delUid = 999
    }

    let arr = [] as number[]
    data.serviceI.sInfo.map((info) => {
      arr.push(info.value)
    })
    if(arr.length <= 0) {
      window.alert("서비스를 선택해주세요.")
      return
    }

    // console.log("selectedService : ", selectedService)
  //   // TODO: 유효성 검사
  //   // if (!member) {
  //   //   return;
  //   // }

  const success = await SettingAPI.insertProvider({
    // ...data,
    dptUid: providerInfo?.dptUid ? providerInfo?.dptUid : undefined,
    departName: data.departName,
    deptCd: `${data.deptCd}`,
    // createDt: data.createDt,
    createDt : data.createDt ? moment(data.createDt).format('yyyyMMDD HH:mm:ss') : undefined,
    operType: data.operType,
    owner: data.owner,
    companyNo: data.companyNo,
    accountHolder: data.accountHolder,
    bank: data.bank,
    accountNo: data.accountNo,
    crtUid: data.crtUid,
    locality: `${data.adres},${data.adresDtl}`,
    tel: `${data.telno1}-${data.telno2}-${data.telno3}`,
    serviceKeys: arr,
    delUid: delUid,
  });

  if (success) {
    alert("완료");
    console.log(data);
    navigate(0);
  } else {
    console.log(">>> failure");
    alert("등록실패");
  }
};

const onError = () => {
  if(errors.departName) {
    window.alert(errors.departName.message)
  } else if(errors.telno2) {
    window.alert(errors.telno2.message)
  } else if(errors.telno3) {
    window.alert(errors.telno3.message)
  } else if(errors.adres) {
    window.alert(errors.adres.message)
  }
}

  return (
    <div className="provider_register_dialog recive_popup popup_black" {...props}>
      <div
        className="recive_popup_inner popup_white"
        style={{ overflowY: "visible" }}
      >
        <div className="popup_tit">
          <h3>{providerInfo ? "기관수정" : "기관등록"}</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="default_input">
              <h4>기관구분</h4>
              <div className="write_box">
                {/* <label htmlFor="radio11" className="mr20">
                  <input 
                      type="radio" 
                      id="radio11" 
                      value="GU"
                      {...register("deptCd")}
                      />구
                </label>
                <label htmlFor="radio22" classsName="mr20">
                  <input 
                        type="radio" 
                        id="radio11" 
                        value="DONG"
                        {...register("deptCd")}
                        />동
                </label> */}
                <label htmlFor="radio22" className="mr20">
                  <input 
                        type="radio" 
                        id="radio11" 
                        value="ORG"
                        {...register("deptCd", {
                          required: "기관구분을 선택해주세요."
                        })}
                        />기관
                </label>
              </div>
            </div>

            <div className="default_input mt20">
              <h4>기관정보</h4>
                <div className="write_box">
                  <div className="name mr8 w50_8" style={{position: 'relative'}}>
                    <input 
                          type="text" 
                          className="default_input essential" 
                          placeholder="기관명 입력"  
                          style={{width: '340px'}}
                          {...register("departName", {
                            required: "기관명을 입력해주세요."
                          })}
                          />
                  </div>
                </div>
            </div>       
            <div className="default_input tel">
              <h4>기관 연락처</h4>
              <div className="write_box" style={{marginTop: 8}}>
                <select
                  className="essential"
                  // style={{ color: data.telno1 ? "#000" : "#9da0a8" }}
                  {...register("telno1")}
                >
                  <option value="062">062</option>
                  <option value="010">010</option>
                  <option value="011">011</option>
                  <option value="012">017</option>
                  <option value="013">018</option>
                  <option value="014">019</option>
                </select>
                <span>-</span>
                <input type="tel"
                  className="essential"
                  placeholder="연락처"
                  maxLength={4} 
                  {...register("telno2", {
                    required: "기관 연락처를 입력해주세요.",
                    onChange: (e:any) => {
                      if(!telValid.test(e.target.value)) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                    }
                  })}
                />
                <span>-</span>
                <input type="tel"
                  className="essential"
                  placeholder="연락처"
                  maxLength={4} 
                  {...register("telno3", {
                    required: "기관 연락처를 입력해주세요.",
                    onChange: (e:any) => {
                      if(!telValid.test(e.target.value)) {
                        e.target.value = String(e.target.value).slice(0, -1)
                      }
                    }
                  })}
                />
              </div>
            </div>
            <div className="default_input mt8">
              <h4></h4>
                <div className="write_box">
                  <div className="address">
                    <input
                      type="text"
                      className="mr8 essential"
                      placeholder="주소 입력"
                      disabled
                      {...register("adres", {
                        required: "기관주소를 입력해주세요."
                      })}
                    />
                    <button type="button" onClick={handleAddressSearch}>
                      주소검색
                    </button>
                  </div>
                  <input
                    type="text"
                    className="mt8"
                    placeholder="상세주소 입력"
                    {...register("adresDtl")}
                  />
                </div>
            </div>
            <div className="default_input mt20">
              <h4>서비스</h4>
              <div className="write_box">
                <Controller
                  control = {control}
                  name="serviceI.sInfo"
                  render={({field: {value, onChange}}) => (
                    <MultiSelect
                    options={test}
                    value={value?.map((val) => ({ label: val.label, value: val.value })) ?? []}
                    onChange={(e: { label: string; value: string }[]) =>
                      onChange(e.map((e) => { return {label: e.label, value: e.value}}))
                    }
                    labelledBy=""
                    disableSearch
                    hasSelectAll={false}
                    overrideStrings={{
                      selectSomeItems: "서비스 선택",
                      allItemsAreSelected: "모두 선택",
                    }}
                  /> // multiSelect
                  )}
                /> 
              </div>
            </div>
            <div className="default_input mt20">
              <h4>상세정보</h4>
              <div className="write_box">
                <label htmlFor="radio11" className="mr20">
                  <input 
                      type="radio" 
                      id="radio11" 
                      value="법인"
                      {...register("operType")}
                      />법인
                </label>
                <label htmlFor="radio22" className="mr20">
                  <input 
                      type="radio" 
                      id="radio22" 
                      value="개인"
                      {...register("operType")}
                      />개인
                </label>
                <label htmlFor="radio33" className="mr20">
                  <input 
                      type="radio" 
                      id="radio33" 
                      value="조합"
                      {...register("operType")}
                      />조합
                </label>
              </div>
            </div>
            <div className="default_input ">
              <h4></h4>
              <div className="write_box">
                  <div className="name mr8 w50_8" style={{position: 'relative'}}>
                    <input 
                          type="text" 
                          className="default_input" 
                          placeholder="대표자명"  
                          {...register("owner")}
                          />
                  </div>
                  <div className="birth w50_8">
                  <input
                    type={onFocus ? "date" : "text"}
                    id="birth"
                    placeholder="설립일"
                    max={"9999-12-31"}
                    onFocus={() => {
                      setOnFocus(true)
                    }}
                    {...register("createDt")}
                  />
                </div>
              </div>
            </div>
            <div className="default_input ">
              <h4></h4>
              <div className="write_box">
                  <div className="name mr8 w50_8" style={{position: 'relative'}}> 
                    <input 
                        type="text" 
                        className="mt8" 
                        placeholder="사업자 등록번호"  
                        style={{width: '340px'}}
                        {...register("companyNo", {
                          onChange: (e:any) => {
                            if(!bankValid.test(e.target.value)) {
                              e.target.value = String(e.target.value).slice(0, -1)
                            }
                          }
                        })}
                        />
                  </div>
              </div>
            </div> 
            <div className="default_input ">
              <h4>계좌정보</h4>
              <div className="write_box">
                  <div className="name mr8 w50_8" style={{position: 'relative'}}>
                    <input 
                        type="text" 
                        className="mt8" 
                        placeholder="예금주"  
                        {...register("accountHolder")}
                        />
                  </div>
                  <div className="birth w50_8" >
                    <input 
                          type="text" 
                          className="mt8" 
                          placeholder="은행명"  
                          {...register("bank")}
                          />                  
                  </div>
              </div>
            </div>
            <div className="default_input ">
              <h4></h4>
              <div className="write_box">
                  <div className="name mr8 w50_8" style={{position: 'relative'}}>
                    <input 
                          type="text" 
                          className="mt8" 
                          placeholder="계좌번호"  
                          style={{width: '340px'}}
                          {...register("accountNo", {
                            onChange: (e:any) => {
                              if(!bankValid.test(e.target.value)) {
                                e.target.value = String(e.target.value).slice(0, -1)
                              }
                            }
                          })}
                          />                  
                  </div>
              </div>
            </div>
            <div className="default_input tel mt20">
              <h4>상태</h4>
              <div className="write_box">
                <select style={{color: "black"}}
                  className=""
                  {...register("status")}
                >
                  <option value="사용">사용</option>
                  <option value="미사용">미사용</option>
                </select>
              </div>
            </div>  

            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="submit" className="submit">
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProviderRegisterDialog;
