import moment from "moment";
import PlusIcon from "../../../../assets/ic_plus.png";
import PopupIcon from "../../../../assets/ic_popup.png";
import Example, {
  DateOptions,
} from "../../../../components/common/selectCheckbox/MultiSelect";
import { MultiSelect } from "react-multi-select-component";
import ServiceInfo from "../../../../models/ServiceInfo";
import DollbomPlanFormInput from "../../../../models/form/DollbomPlanFormInput";
import React, {
    MouseEventHandler,
    SetStateAction,
    useEffect,
    useRef,
    useState,
  } from "react";
  import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DongConference from "../../../../models/DongConference";
import { Controller, useForm } from "react-hook-form";
import DollbomConfirmationFormInput from "../../../../models/form/DollbomConfirmationFormInput";
import DollbomPlanAPI from "../../../../apis/DollbomPlanAPI";
import DollbomPlanItem from "./DollBomPlanItem";

const EditDollbomPlan = ({
    services,
    basicServices,
    emerServices,
    plans,
    onPlanAdded,
    onPlanModify,
    onPlanDeleted,
    lastSum,
    lastEmerSum,
    safeSprtCharge,
    simpleFixCount,
    cleaningCount,
    pestControlCount,
    escortCount,
    isSprtMore,
  }: {
    services: ServiceInfo[];
    basicServices: ServiceInfo[];
    emerServices: ServiceInfo[];
    plans: DollbomPlanFormInput[];
    onPlanAdded: (plan: DollbomPlanFormInput) => void;
    onPlanModify: (plan: DollbomPlanFormInput) => void;
    onPlanDeleted: (plan: DollbomPlanFormInput) => void;
    lastSum?: number,
    lastEmerSum?: number,
    safeSprtCharge: number,
    simpleFixCount: number,
    cleaningCount: number,
    pestControlCount: number,
    escortCount: number,
    isSprtMore: 'Y' | 'N'
  }) => {
    const [selected, setSelected] = useState([]);
    const [searchParams] = useSearchParams();
    const [carePlan, setCarePlan] = useState<DongConference>();
    const [newPlan, setNewPlan] = useState<DollbomConfirmationFormInput[]>([]);
    // 기존돌봄 불러오는 State
    const [basicValue, setBasicValue] = useState(String);
    const [basicValueInfo, setBasicValueInfo] = useState(String);
    // 긴급돌봄 불러오는 State
    const [emerCount, setEmerCount] = useState(Number);
    const [emerBeginDt, setEmerBeginDt] = useState(String);
    const [emerEndDt, setEmerEndDt] = useState(String);
    const [emerTime, setEmerTime] = useState(Number);
    const [emerCost, setEmerCost] = useState(Number);
    // 광주+돌봄 계획비용 State
    const [totalCost, setTotalCost] = useState(0);
    // 긴급돌봄 잔액 State
    const [emerChange, setEmerChange] = useState(600000);
    // 광주+돌봄 잔액 State
    const [gjChange, setGjChange] = useState(1500000);
    const sdUid = searchParams.get("sdUid");
    const syUid = searchParams.get("syUid");
    const stUid = searchParams.get("stUid");
    const csUid = searchParams.get("csUid");
  
    //등록 전 가격
    const [currentCost, setCurrentCost] = useState(0)
    //횟수 관련
    const [currrentEscort, setCurrentEscort] = useState(escortCount)
    const [currentSimpleFix, setCurrentSimpleFix] = useState(simpleFixCount)
    const [currentCleaning, setCurrentCleaning] = useState(cleaningCount)
    const [currentPestControl, setCurrentPestControl] = useState(pestControlCount)
    //안전생활환경
    const [currentSafe, setCurrentSafe] = useState(safeSprtCharge)
    //방문목욕
    const [shower, setShower] = useState(false)
  
    const [isCaseMeetingNeeded, setCaseMeetingNeeded] = useState(true);
    const [serviceDetail, setServiceDetail] = useState(String);
    const navigate = useNavigate();
    const { register, watch, getValues, setValue, control, reset } =
      useForm<DollbomPlanFormInput>({
        defaultValues: {
          // sdUid: Number(sdUid),
          syUid: Number(syUid),
          // stUid: Number(stUid),
          // stepCd: "돌봄계획",
          // serviceStatCd: "의뢰",
          stopYn: "N",
          contentInfo: serviceDetail,
        },
      });
    const data = watch();
    const cateCd = watch("cateCd");
    const siUid = watch("siUid");
    const beginDate = watch("beginDate");
    const endDate = watch("endDate");
    const cycleUnit = watch("cycleUnit");
    const totalCount = watch("totalCount");
    const weekday = watch("weekday");
    const times = watch("times");
    const contentInfo = watch("serviceDetail");
    const cost = watch("cost");
    const basicList = watch("basicList");
    //기존 계획 있는경우 가격 제외
    useEffect(() => {
      if(lastSum) {
        setGjChange((cur) => cur - lastSum)
      }
      if(lastEmerSum) {
        setEmerChange((cur) => cur - lastEmerSum)
      }
      if(isSprtMore === 'Y') {
        setGjChange((cur) => cur + 1500000)
        setEmerChange((cur) => cur + 600000)
      }
    }, [])
    // 첫 번째 카테고리 변경 시 두 번째 카테고리 초기화
    useEffect(() => {
      setValue("siUid", undefined);
      setValue("beginDate", undefined)
      setValue("endDate", undefined)
      setValue("cycleUnit", undefined);
      setValue("weekday", undefined)
      setValue("totalCount", undefined)
      setValue("times", undefined)
      setCurrentCost(0)
      // console.log(cateCd);
      // if(Number(cateCd) === 3 || Number(cateCd) === 5 || Number(cateCd) === 6) {
      //   setValue("beginDate", undefined)
      //   setValue("endDate", undefined)
      // }
    }, [cateCd]);

    useEffect(() => {
      setValue("beginDate", undefined)
      setValue("endDate", undefined)
      setValue("cycleUnit", undefined);
      setValue("weekday", undefined)
      setValue("totalCount", undefined)
      setValue("times", undefined)
      setCurrentCost(0)
      let shower1 = services.find((service) => service.serviceNm === "방문목욕(차량내)")
      let shower2 = services.find((service) => service.serviceNm === "방문목욕(가정내)")
      let shower3 = services.find((service) => service.serviceNm === "방문목욕(자가욕조)")
      if(Number(siUid) === Number(shower1?.siUid) || Number(siUid) === Number(shower2?.siUid) || Number(siUid) === Number(shower3?.siUid)) {
        setShower(true)
        setValue("times", 1)
      } else {
        setShower(false)
      }
    }, [siUid])
  
    useEffect(() => {
      if(data.cateCd) { //cateCd 선택
        if(data.siUid) {  //siUid 선택
          let cost = 0
          services.map((service) => {
            if(service.siUid === Number(data.siUid)) {
              if(service.unitCost) {
                cost = service.unitCost
              }
              return
            }
          })
          switch(Number(data.cateCd)) {
            case 1 :  //가사지원
              if(totalCount && times) {
                setCurrentCost(cost * totalCount * times)
              }
              break
            case 2 : //식사지원
              if(totalCount) {
                setValue("times", 1)
                setCurrentCost(cost * totalCount)
              }
              break
            case 3 : //동행지원
              if(totalCount) {
                setCurrentCost(cost * totalCount)
              }
              break
            case 4 : //건강지원
              if(totalCount) {
                setCurrentCost(cost * totalCount)
              }
              break
            case 5 : //안전지원
                setValue("times", 1)
                if(times) {
                  setCurrentCost(cost * times)
                }
              break
            case 6 : //주거편의
                setValue("times", 1)
                if(times) {
                  setCurrentCost(cost * times)
                }
              break
            case 7 : //일시보호
              if(totalCount) {
                setCurrentCost(cost * totalCount)
              }
              break
            default :
              setCurrentCost(0)
              break
          }
        } else {
          setCurrentCost(0)
        }
      }
    }, [totalCount, times])
  
    // 잔액 계산
    useEffect(() => {
      setTimeout(() => {
        let sum = 0;
        const rows =
          document.querySelectorAll<HTMLTableCellElement>(".addTable tr");
        // console.log("rows >>>>>>>", rows);
        rows.forEach((row) => {
          const testCost = row.querySelectorAll<HTMLTableCellElement>("td");
          const getInputTag = row.querySelectorAll<HTMLInputElement>("input");
          let curCateCd = ""
            let curSiUid = ""
            getInputTag.forEach((input) => {
              if(input.className === "cateCd") {
                curCateCd = input.value
              }
              if(input.className === "siUid") {
                curSiUid = input.value
              }
            })
            // console.log("cateCd : ", curCateCd)
            if(Number(curCateCd) !== 3 && Number(curCateCd) !== 5 && Number(curCateCd) !== 6) {
              sum += Number(testCost[3].textContent?.split("원")[0].trim().replace(/,/g, ""))
            }
          
          // if (testCost[2].textContent != "-") {
          //   const testCost2 = Number(
          //     testCost[3].textContent?.split("원")[0].trim().replace(/,/g, "") ||
          //     ""
          //   );
          //   sum += testCost2;
          // }
          // console.log("aaaaa", testCost2);
        });
        setTotalCost(sum);
        // console.log("sum >>>", totalCost);
      }, 500);
    }, [totalCost]);
    // 추가 버튼 클릭 시 빈값 검사 후 잔액 계산
    const handleAddPlan = () => {
      if (!siUid) {
        alert('"서비스" 를 선택해 주세요.');
      }
      //  else if (
      //   (data.cateCd == 1 && !beginDate) ||
      //   (data.cateCd == 2 && !beginDate) ||
      //   (data.cateCd == 4 && !beginDate) ||
      //   (data.cateCd == 7 && !beginDate)
      // ) {
      //   alert('"지원시작일" 을 선택해 주세요.');
      // }
      //  else if (
      //   (data.cateCd == 1 && !endDate) ||
      //   (data.cateCd == 2 && !endDate) ||
      //   (data.cateCd == 4 && !endDate) ||
      //   (data.cateCd == 7 && !endDate)
      // ) {
      //   alert('"지원종료일" 을 선택해 주세요.');
      // }
      //  else if (
      //   (data.cateCd == 1 && !cycleUnit) ||
      //   (data.cateCd == 2 && !cycleUnit)
      // ) {
      //   alert('"주기" 를 선택해 주세요.');
      // }
       else if (
        (data.cateCd == 1 && !totalCount) ||
        (data.cateCd == 2 && !totalCount) ||
        (data.cateCd == 3 && !totalCount) ||
        (data.cateCd == 4 && !totalCount) ||
        (data.cateCd == 7 && !totalCount)
      ) {
        alert('"횟수" 를 선택해 주세요.');
      }
      //  else if (
      //   (data.cateCd == 1 && !weekday) ||
      //   (data.cateCd == 2 && !weekday)
      // ) {
      //   alert('"요일" 을 선택해 주세요.');
      // }
       else if ((data.cateCd == 1 && !times) || (data.cateCd == 2 && !times)) {
        alert('"시간" 을 입력해 주세요.');
      }
      //  else if (
      //   (data.cateCd == 3 && !contentInfo) ||
      //   (data.cateCd == 5 && !contentInfo) ||
      //   (data.cateCd == 6 && !contentInfo)
      // ) {
      //   alert('"상세내용" 을 입력해 주세요.');
      // }
       else {
        if(totalCount) {
          if(totalCount <= 0) {
            alert('"횟수" 를 선택해 주세요.');
            return
          }
        }
        if(times) {
          if(times <= 0) {
            alert('"시간" 을 입력해 주세요.');
            return
          }
        }
        //지원횟수 한계 카운트 부분
        const safeSprtInfo = services.find((si) => si.serviceNm === "안전생활환경")
        if(Number(data.siUid) === safeSprtInfo?.siUid) { //안전생활환경
          if(currentSafe >= 1000000) {
            alert("안전생활환경 연간 지원비용을 초과하였습니다.")
            return
          } else {
            setCurrentSafe((cur) => cur + 1000000)
          }
        }
        if(Number(data.cateCd) === 6) { //주거편의의 경우
          const cateSix = services.filter((si) => Number(si.cateCd) === 6)
          const simpleFix = cateSix.find((si) => si.serviceNm === "간단수리")
          const cleaning = cateSix.find((si) => si.serviceNm === "대청소")
          const pestControl = cateSix.find((si) => si.serviceNm === "방역 방충")
          if(Number(data.siUid) === simpleFix?.siUid) {
            if(currentSimpleFix >= 3) {
              alert("간단수리 연간 지원횟수를 초과하였습니다.")
              return
            } else {
              setCurrentSimpleFix((cur) => cur + 1)
            }
          } else if(Number(data.siUid) === cleaning?.siUid) {
            if(currentCleaning >= 1) {
              alert("대청소 연간 지원횟수를 초과하였습니다.")
              return
            } else {
              setCurrentCleaning((cur) => cur + 1)
            }
          } else {
            if(currentPestControl >= 3) {
              alert("방역 방충 연간 지원횟수를 초과하였습니다.")
              return
            } else {
              setCurrentPestControl((cur) => cur + 1)
            }
          }
        }
        if(Number(data.cateCd) === 3) { //동행지원 제한 48회
          if(currrentEscort + Number(data.totalCount) > 48) {
            alert("연간 동행지원 횟수를 초과하였습니다.")
            return
          } else {
            setCurrentEscort((cur) => cur + Number(data.totalCount))
          }
        }
  
        setServiceDetail(contentInfo);
        const currentService = services.find((e) => e.siUid == data.siUid);
        // console.log(">>> services: ", services);
        // console.log(">>> siUid: " + data.siUid);
        // console.log(">>> currentService: ", currentService);
        // console.log(">>> contentInfo: ", serviceDetail);
  
        onPlanAdded({
          ...getValues(),
          cost: currentService?.unitCost ?? 0,
          serviceNm: currentService?.serviceNm ?? "",
        });
        // 잔액 계산
        setTimeout(() => {
          let sum = 0;
          const rows =
            document.querySelectorAll<HTMLTableCellElement>(".addTable tr");
          // console.log("rows >>>>>>>", rows);
          rows.forEach((row) => {
            const testCost = row.querySelectorAll<HTMLTableCellElement>("td");
            const getInputTag = row.querySelectorAll<HTMLInputElement>("input");
            let curCateCd = ""
            let curSiUid = ""
            getInputTag.forEach((input) => {
              if(input.className === "cateCd") {
                curCateCd = input.value
              }
              if(input.className === "siUid") {
                curSiUid = input.value
              }
            })
            // console.log("cateCd : ", curCateCd)
            if(Number(curCateCd) !== 3 && Number(curCateCd) !== 5 && Number(curCateCd) !== 6) {
              sum += Number(testCost[3].textContent?.split("원")[0].trim().replace(/,/g, ""))
            }
  
            // if (testCost[2].textContent != "-") {
            //   const testCost2 = Number(
            //     testCost[3].textContent?.split("원")[0].trim().replace(/,/g, "") ||
            //     ""
            //   );
            //   sum += testCost2;
            // }
            // console.log("aaaaa", testCost2);
          });
          setTotalCost(sum);
          // console.log("sum >>>", totalCost);
        }, 500);
        // console.log("sum >>>", totalCost);
        // 잔액 계산 end
        reset();
      }
    };
    // 새로 추가한 수립계획 삭제 버튼 이벤트
    const handleDeletePlan = (plan: DollbomPlanFormInput) => {
      const safeSprtInfo = services.find((si) => si.serviceNm === "안전생활환경")
      if(Number(plan.siUid) === safeSprtInfo?.siUid) { //안전생활환경
        setCurrentSafe((cur) => cur - 1000000)
      }
      if(Number(plan.cateCd) === 6) { //주거편의의 경우
        const cateSix = services.filter((si) => Number(si.cateCd) === 6)
        const simpleFix = cateSix.find((si) => si.serviceNm === "간단수리")
        const cleaning = cateSix.find((si) => si.serviceNm === "대청소")
        const pestControl = cateSix.find((si) => si.serviceNm === "방역 방충")
        if(Number(plan.siUid) === simpleFix?.siUid) {
          setCurrentSimpleFix((cur) => cur - 1)
        } else if(Number(plan.siUid) === cleaning?.siUid) {
          setCurrentCleaning((cur) => cur - 1)
        } else {
          setCurrentPestControl((cur) => cur - 1)
        }
      }
      if(Number(plan.cateCd) === 3) { //동행지원 제한 48회
        setCurrentEscort((cur) => cur - Number(plan.totalCount))
      }
      onPlanDeleted(plan);
      // 잔액 계산
      setTimeout(() => {
        let sum = 0;
        const rows =
          document.querySelectorAll<HTMLTableCellElement>(".addTable tr");
        // console.log("rows >>>>>>>", rows);
        rows.forEach((row) => {
          const testCost = row.querySelectorAll<HTMLTableCellElement>("td");
          const getInputTag = row.querySelectorAll<HTMLInputElement>("input");
          let curCateCd = ""
          let curSiUid = ""
          getInputTag.forEach((input) => {
            if(input.className === "cateCd") {
              curCateCd = input.value
            }
            if(input.className === "siUid") {
              curSiUid = input.value
            }
          })
          // console.log("cateCd : ", curCateCd)
          if(Number(curCateCd) !== 3 && Number(curCateCd) !== 5 && Number(curCateCd) !== 6) {
            sum += Number(testCost[3].textContent?.split("원")[0].trim().replace(/,/g, ""))
          }
          // if (testCost[2].textContent != "-") {
          //   const testCost2 = Number(
          //     testCost[3].textContent?.split("원")[0].trim().replace(/,/g, "") ||
          //     ""
          //   );
          //   sum += testCost2;
          // }
        });
        setTotalCost(sum);
        // console.log("sum >>>", totalCost);
      }, 500);
    };
    // 페이지 로딩 시 돌봄계획 데이터 불러오는 부분 !!!!!!
    useEffect(() => {
      DollbomPlanAPI.getDongConferencePlan({
        syUid: Number(syUid),
        csUid: Number(csUid),
      }).then((res) => {
        if(res.carePlan && res.carePlan.length > 0) {
          res.carePlan.map((plan) => {
            if(plan.cateCd === 8) { //긴급돌봄 데이터 넣기
              setValue("emerDay", plan.totalCount)
              setValue("emerBeginDt", plan.beginDate)
              setValue("emerEndDt", plan.endDate)
              setValue("emerTime", plan.times)
            }
            const safeSprtInfo = services.find((si) => si.serviceNm === "안전생활환경")
            if(Number(plan.siUid) === safeSprtInfo?.siUid) { //안전생활환경
              setCurrentSafe((cur) => cur + 1000000)
            }
            if(Number(plan.cateCd) === 6) { //주거편의의 경우
              const cateSix = services.filter((si) => Number(si.cateCd) === 6)
              const simpleFix = cateSix.find((si) => si.serviceNm === "간단수리")
              const cleaning = cateSix.find((si) => si.serviceNm === "대청소")
              const pestControl = cateSix.find((si) => si.serviceNm === "방역 방충")
              if(Number(plan.siUid) === simpleFix?.siUid) {
                setCurrentSimpleFix((cur) => cur + Number(plan.totalCount))
              } else if(Number(plan.siUid) === cleaning?.siUid) {
                setCurrentCleaning((cur) => cur + Number(plan.totalCount))
              } else {
                setCurrentPestControl((cur) => cur + Number(plan.totalCount))
              }
            }
            if(Number(plan.cateCd) === 3) { //동행지원 제한 48회
              setCurrentEscort((cur) => cur + Number(plan.totalCount))
            }
          })
        }
        setCarePlan(res);
        setBasicValue(
          res.carePlan
            .filter((service) => service.cateCd == 0)[0]
            .serviceDetail.split("/")[0]
        );
        setBasicValueInfo(
          res.carePlan
            .filter((service) => service.cateCd == 0)[0]
            .serviceDetail.split("/")[1]
        );
        setEmerCount(
          Number(
            res.carePlan.filter((service) => service.cateCd == 8)[0].totalCount ??
            0
          )
        );
        setEmerBeginDt(
          res.carePlan.filter((service) => service.cateCd == 8)[0].beginDate
        );
        setEmerEndDt(
          res.carePlan.filter((service) => service.cateCd == 8)[0].endDate
        );
        setEmerTime(
          res.carePlan.filter((service) => service.cateCd == 8)[0].times
        );
        setEmerCost(
          res.carePlan.filter((service) => service.cateCd == 8)[0].cost
        );
      });
    }, []);
    // 새로 추가한 수립계획 수정 버튼 이벤트
    const handleModifyPlan = (plan: DollbomPlanFormInput) => {
      if (window.confirm("서비스를 수정하시겠습니까?")) {
        reset();
        const safeSprtInfo = services.find((si) => si.serviceNm === "안전생활환경")
        if(Number(plan.siUid) === safeSprtInfo?.siUid) { //안전생활환경
          setCurrentSafe((cur) => cur - 1000000)
        }
        if(Number(plan.cateCd) === 6) { //주거편의의 경우
          const cateSix = services.filter((si) => Number(si.cateCd) === 6)
          const simpleFix = cateSix.find((si) => si.serviceNm === "간단수리")
          const cleaning = cateSix.find((si) => si.serviceNm === "대청소")
          const pestControl = cateSix.find((si) => si.serviceNm === "방역 방충")
          if(Number(plan.siUid) === simpleFix?.siUid) {
            setCurrentSimpleFix((cur) => cur - 1)
          } else if(Number(plan.siUid) === cleaning?.siUid) {
            setCurrentCleaning((cur) => cur - 1)
          } else {
            setCurrentPestControl((cur) => cur - 1)
          }
        }
        if(Number(plan.cateCd) === 3) { //동행지원 제한 48회
          setCurrentEscort((cur) => cur - Number(plan.totalCount))
        }
        const testPlan = plans.filter((e) => e == plan);
        const cateCd = testPlan[0].cateCd;
        const siUid = testPlan[0].siUid;
        const cost = testPlan[0].cost;
        const beginDate = testPlan[0].beginDate;
        // console.log(beginDate);
        const endDate = testPlan[0].endDate;
        const cycleUnit = testPlan[0].cycleUnit;
        const serviceDetail = testPlan[0].serviceDetail;
        const serviceStatCd = testPlan[0].serviceStatCd;
        const stopYn = testPlan[0].stopYn;
        const times = testPlan[0].times;
        const totalCount = testPlan[0].totalCount;
        const weekday = testPlan[0].weekday;
        // console.log(testPlan[0].modifyCheck);
        if (cateCd == 1 || cateCd == 2) {
          setValue("cateCd", cateCd);
          setValue("siUid", siUid);
          setTimeout(() => {
            setValue("siUid", siUid);
          }, 100);
          setValue("cost", cost);
          if(beginDate) {
            setValue("beginDate", beginDate);
          }
          if(endDate) {
            setValue("endDate", endDate);
          }
          setValue("cycleUnit", cycleUnit);
          setTimeout(() => {
            setValue("cycleUnit", cycleUnit);
          }, 100);
          setValue("times", times);
          setValue("totalCount", totalCount);
          setValue("weekday", weekday);
          setValue("serviceStatCd", serviceStatCd);
          setValue("stopYn", stopYn);
        } else if (cateCd == 3) {
          setValue("cateCd", cateCd);
          setValue("siUid", siUid);
          setTimeout(() => {
            setValue("siUid", siUid);
          }, 100);
          setValue("totalCount", totalCount);
          setValue("serviceDetail", serviceDetail);
          setValue("serviceStatCd", serviceStatCd);
          setValue("stopYn", stopYn);
        } else if (cateCd == 4 || cateCd == 7) {
          setValue("cateCd", cateCd);
          setValue("siUid", siUid);
          setTimeout(() => {
            setValue("siUid", siUid);
          }, 100);
          if(beginDate) {
            setValue("beginDate", beginDate);
          }
          if(endDate) {
            setValue("endDate", endDate);
          }
          setValue("totalCount", totalCount);
          setValue("serviceStatCd", serviceStatCd);
          setValue("stopYn", stopYn);
        } else if (cateCd == 5 || cateCd == 6) {
          setValue("cateCd", cateCd);
          setValue("siUid", siUid);
          setTimeout(() => {
            setValue("siUid", siUid);
          }, 100);
          setValue("serviceDetail", serviceDetail);
          setValue("serviceStatCd", serviceStatCd);
          setValue("stopYn", stopYn);
          setValue("totalCount", undefined);
        }
        onPlanModify(plan);
      }
    };
    // 불러온 수립계획 목록 수정 버튼 이벤트
    function handleModifyClick(
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
      if (window.confirm("서비스를 수정하시겠습니까?")) {
        const row = (event.target as Element).closest("tr");
        const cols = row?.querySelectorAll<HTMLTableCellElement>("td");
        const cateCd = Number(
          row?.querySelector<HTMLInputElement>(".cateCd")?.value
        );
        const siUid = Number(
          row?.querySelector<HTMLInputElement>(".siUid")?.value
        );
        let cost = 0;
        let beginDate = "";
        let endDate = "";
        let serviceDetail = "";
        let times = 0;
        let totalCount = 0;
        const cycleUnit =
          row?.querySelector<HTMLInputElement>(".cycleUnit")?.value;
        const weekday = row?.querySelector<HTMLInputElement>(".weekday")?.value;
        if (cols) {
          cost = Number(
            cols[3].textContent?.split("원")[0].trim().replace(/,/g, "") || ""
          );
          if (cateCd == 1 || cateCd == 2 || cateCd == 4 || cateCd == 7) {
            beginDate = moment(cols[2].textContent?.split("~")[0] || "").format(
              "YYYY-MM-DD"
            );
            endDate = moment(cols[2]?.textContent?.split("~")[1] || "").format(
              "YYYY-MM-DD"
            );
          }
          if (cateCd == 3) {
            serviceDetail = cols[1].textContent?.match(/\(([^)]+)\)/)?.[1] || "";
          } else if (cateCd == 5 || cateCd == 6) {
            serviceDetail = cols[1].textContent || "";
          }
          times = parseInt(cols[1].textContent?.match(/(\d+)시간/)?.[1] ?? "");
          totalCount = Number(cols[1].textContent?.match(/\d+(?=회)/)?.[0] || "");
        }
        const safeSprtInfo = services.find((si) => si.serviceNm === "안전생활환경")
        if(Number(siUid) === safeSprtInfo?.siUid) { //안전생활환경
          setCurrentSafe((cur) => cur - 1000000)
        }
        if(Number(cateCd) === 6) { //주거편의의 경우
          const cateSix = services.filter((si) => Number(si.cateCd) === 6)
          const simpleFix = cateSix.find((si) => si.serviceNm === "간단수리")
          const cleaning = cateSix.find((si) => si.serviceNm === "대청소")
          const pestControl = cateSix.find((si) => si.serviceNm === "방역 방충")
          if(Number(siUid) === simpleFix?.siUid) {
            setCurrentSimpleFix((cur) => cur - 1)
          } else if(Number(siUid) === cleaning?.siUid) {
            setCurrentCleaning((cur) => cur - 1)
          } else {
            setCurrentPestControl((cur) => cur - 1)
          }
        }
        if(Number(cateCd) === 3) { //동행지원 제한 48회
          setCurrentEscort((cur) => cur - Number(totalCount))
        }
        // const serviceStatCd = testPlan[0].serviceStatCd;
        // const stopYn = testPlan[0].stopYn;
        // console.log(testPlan[0].modifyCheck);
        if (cateCd == 1 || cateCd == 2) {
          setValue("cateCd", cateCd);
          setValue("siUid", siUid);
          setTimeout(() => {
            setValue("siUid", siUid);
          }, 100);
          setValue("cost", cost);
          if(beginDate) {
            setValue("beginDate", beginDate);
          }
          if(endDate) {
            setValue("endDate", endDate);
          }
          setValue("cycleUnit", cycleUnit);
          setTimeout(() => {
            setValue("cycleUnit", cycleUnit);
          }, 100);
          setValue("times", times);
          setValue("totalCount", totalCount);
          setValue("weekday", weekday);
        } else if (cateCd == 3) {
          setValue("cateCd", cateCd);
          setValue("siUid", siUid);
          setTimeout(() => {
            setValue("siUid", siUid);
          }, 100);
          setValue("totalCount", totalCount);
          setValue("serviceDetail", serviceDetail);
        } else if (cateCd == 4 || cateCd == 7) {
          setValue("cateCd", cateCd);
          setValue("siUid", siUid);
          setTimeout(() => {
            setValue("siUid", siUid);
            if(beginDate) {
              setValue("beginDate", beginDate);
            }
            if(endDate) {
              setValue("endDate", endDate);
            }
          }, 100);
          setValue("totalCount", totalCount);
        } else if (cateCd == 5 || cateCd == 6) {
          setValue("cateCd", cateCd);
          setValue("siUid", siUid);
          setTimeout(() => {
            setValue("siUid", siUid);
          }, 100);
          setValue("serviceDetail", serviceDetail);
          setValue("totalCount", undefined);
        }
        if (row) {
          row.remove();
        }
      }
    }
    // 불러온 수립계획 목록 삭제 버튼 이벤트
    function handleDeleteClick(
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
      if (window.confirm("서비스를 삭제하시겠습니까?")) {
        const row = (event.target as Element).closest("tr");
        if (row) {
          const els = row.querySelectorAll("input")
          els.forEach((el) => {
            if(el.className === "cateCd") {
              let targetCateCd = Number(el.value)
              if(targetCateCd === 3) {  //동행지원
                let targetCount = Number(els[els.length - 1].value)
                setCurrentEscort((cur) => cur - targetCount)
              } else if(targetCateCd === 5) { //안전생활환경
                let targetSiUid = Number(els[els.length - 2])
                const safeSprtInfo = services.find((si) => si.serviceNm === "안전생활환경")
                if(targetSiUid === safeSprtInfo?.siUid) { //안전생활환경
                  setCurrentSafe((cur) => cur - 1000000)
                }
              } else if(targetCateCd === 6) { //주거편의
                let targetSiUid = Number(els[els.length - 2])
                const cateSix = services.filter((si) => Number(si.cateCd) === 6)
                const simpleFix = cateSix.find((si) => si.serviceNm === "간단수리")
                const cleaning = cateSix.find((si) => si.serviceNm === "대청소")
                const pestControl = cateSix.find((si) => si.serviceNm === "방역 방충")
                if(targetSiUid === simpleFix?.siUid) {
                  setCurrentSimpleFix((cur) => cur - 1)
                } else if(targetSiUid === cleaning?.siUid) {
                  setCurrentCleaning((cur) => cur - 1)
                } else {
                  setCurrentPestControl((cur) => cur - 1)
                }
              }
            }
          })
          row.remove();
          setTimeout(() => {
            let sum = 0;
            const rows =
              document.querySelectorAll<HTMLTableCellElement>(".addTable tr");
            // console.log("rows >>>>>>>", rows);
            rows.forEach((row) => {
              const testCost = row.querySelectorAll<HTMLTableCellElement>("td");
              const getInputTag = row.querySelectorAll<HTMLInputElement>("input");
              let curCateCd = ""
              let curSiUid = ""
              getInputTag.forEach((input) => {
                if(input.className === "cateCd") {
                  curCateCd = input.value
                }
                if(input.className === "siUid") {
                  curSiUid = input.value
                }
              })
              // console.log("cateCd : ", curCateCd)
              if(Number(curCateCd) !== 3 && Number(curCateCd) !== 5 && Number(curCateCd) !== 6) {
                sum += Number(testCost[3].textContent?.split("원")[0].trim().replace(/,/g, ""))
              }
              // if (testCost[2].textContent != "-") {
              //   const testCost2 = Number(
              //     testCost[3].textContent?.split("원")[0].trim().replace(/,/g, "") ||
              //     ""
              //   );
              //   sum += testCost2;
              // }
            });
            setTotalCost(sum);
            // console.log("sum >>>", totalCost);
          }, 500);
        }
      }
    }
  
    useEffect(() => {
      // console.log(newPlan);
    }, [newPlan]);
  
    const basicListOption = basicServices
      .filter((service) => service.cateCd === "0")
      .map((service) => ({ label: service.serviceNm, value: service.serviceNm }));
    useEffect(() => {
      if (basicValue) {
        // console.log("basicValue 있음");
        setValue("basicList", basicValue);
        setValue("basicDetail", basicValueInfo);
      }
    }, [basicValue, setValue]);
    useEffect(() => {
      if (emerCount) {
        setValue("emerDay", emerCount);
        setValue("emerBeginDt", emerBeginDt);
        setValue("emerEndDt", emerEndDt);
        setValue("emerTime", emerTime);
      }
    }, [emerCount, setValue]);
    const handleFocus = (event: any) => {
      event.target.type = "date";
    };
    // 지원일수 유효성 검사
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const selectedDate = new Date(e.target.value);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      if (selectedDate < currentDate) {
        alert("현재 시간보다 이전의 날짜는 설정할 수 없습니다.");
        e.target.value = '';
        if(getValues('emerBeginDt')) {
          // console.log("긴급돌봄 시작일 있음")
          if(getValues('emerDay')) {
            setValue('emerEndDt', moment().add(getValues('emerDay'), 'days').format('yyyy-MM-DD'))
          } else {
            setValue("emerEndDt", moment().format('yyyy-MM-DD'))
          }
        }
        if(getValues('beginDate')) {
          // console.log("서비스 시작일 있음")
          setValue('endDate', moment().format('yyyy-MM-DD'))
        }
      }
    };
    return (
      <div className="care_plan_write">
        <div className="padding">
          {/* <button onClick={buttonClickHandler}>aa</button>
          <button onClick={testHandle}>cc</button> */}
          <div className="care01">
            <div className="text_input">
              <p>기존돌봄</p>
              <div className="input_wrap">
                <div
                  className="input_box mr8"
                  style={{ width: "27%" }}
                >
                  <div
                    style={{
                      width: "238px",
                      backgroundColor: "white",
                    }}
                  >
                    {/* {services
                      .filter((service) => service.cateCd === "2")
                      .map((service) => (
                        <option key={service.siUid} value={service.siUid}>
                          {service.serviceNm}
                        </option>
                      ))} */}
                    {/* <input type="text" value={basicServices} /> */}
                    {/* {carePlan?.carePlan.filter((service) => service.cateCd == 0) && } */}
                    <Controller
                      control={control}
                      name="basicList"
                      render={({ field: { onChange, value } }) => (
                        <MultiSelect
                          options={basicListOption}
                          className="multi-select"
                          value={
                            value
                              ?.split(",")
                              .map((v) => ({ label: v, value: v })) ?? []
                          }
                          onChange={(event: { label: string; value: string }[]) =>
                            onChange(event.map((e) => e.value).join(","))
                          }
                          labelledBy="선택"
                          hasSelectAll={false}
                          disableSearch={true}
                          overrideStrings={{
                            selectSomeItems: "선택",
                            allItemsAreSelected: value ?? "",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
  
                <input type="hidden" className="basicList" value={basicList} />
                <div
                  className="input_box"
                  style={{ width: "calc(73% - 8px)" }}
                >
                  <input
                    type="text"
                    id="basicDetail"
                    className="w100"
                    placeholder="사유 입력"
                    {...register("basicDetail")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="care02 mt20">
            <div className="text_input">
              <p>긴급돌봄</p>
              <div className="input_wrap">
                <div
                  className="input_box mr8"
                  style={{ width: "27%" }}
                >
                  <input
                    type="text"
                    id="emerDay"
                    className="mr8 w100 essential"
                    placeholder="지원일수 입력"
                    {...register("emerDay", {
                      onChange: (e:any) => {
                        if(e.target.value) {
                          setValue("emerBeginDt", moment().format('yyyy-MM-DD'))
                          setValue("emerEndDt", moment().add(Number(e.target.value), 'days').format('yyyy-MM-DD'))
                        } else {
                          setValue("emerBeginDt", "")
                          setValue("emerEndDt", "")
                        }
                      }
                    })}
                    onInput={(e) => {
                      e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                    }}
                  />
                  <p>일</p>
                </div>
                <div
                  className="input_box mr8"
                  style={{
                    width: "40%",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    type="text"
                    style={{ width: "46%" }}
                    className={`emerBeginDt`}
                    id="birth"
                    placeholder="지원시작일"
                    max={"9999-12-31"}
                    // disabled={getValues('emerDay') ? false : true}
                    onFocus={handleFocus}
                    {...register("emerBeginDt", {
                      onChange: (e:any) => {
                        if(getValues('emerDay')) {  //긴급돌봄 지원일수가 있는 경우
                          setValue('emerEndDt', moment(e.target.value).add(getValues('emerDay'), 'days').format('yyyy-MM-DD'))
                        }
                      }
                    })}
                    onBlur={handleBlur}
                  />
  
                  {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                  <p>~</p>
  
                  <input
                    type="text"
                    style={{ width: "46%" }}
                    className={`emerEndDt`}
                    id="birth"
                    placeholder="지원종료일"
                    max={"9999-12-31"}
                    // disabled={getValues('emerDay') && getValues('emerBeginDt') ? false : true}
                    onFocus={handleFocus}
                    {...register("emerEndDt", {
                      onChange: (e:any) => {
                        // console.log("시작일 종료일 차이 : ", moment(e.target.value).diff(moment(getValues('emerBeginDt')), 'days'))
                        if(moment(e.target.value).diff(moment(getValues('emerBeginDt')), 'days') < 0) { //종료일이 시작일보다 적은 경우
                          window.alert("종료일은 시작일 이후로 설정해주세요.")
                          if(getValues('emerDay')) {  //긴급돌봄 지원일수가 있는 경우
                            setValue('emerEndDt', moment(e.target.value).add(getValues('emerDay'), 'days').format('yyyy-MM-DD'))
                          } else {
                            setValue('emerEndDt', getValues('emerBeginDt'))
                          }
                        }
                      }
                    })}
                    onBlur={handleBlur}
                  />
                  {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                </div>
                <div className="input_box" style={{ width: "18%" }}>
                  <input
                    type="number"
                    className={`mr8 essential`}
                    placeholder="시간 입력"
                    id="emerTime"
                    style={{ width: "calc(100% - 31px)" }}
                    // disabled={getValues('emerDay') ? false : true}
                    {...register("emerTime")}
                    onInput={(e) => {
                      e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                    }}
                  />
                  <p style={{ width: 28 }}>시간</p>
                </div>
                <p className="cost" style={{ width: "calc(15% - 16px)" }}>
                  <input
                    type="hidden"
                    id="emerCost"
                    value={(
                      data.emerDay *
                      data.emerTime *
                      16_600
                    ).toLocaleString()}
                  />
                  <span className="red">
                    {(data.emerDay * data.emerTime * 16_600).toLocaleString()}
                  </span>{" "}
                  원
                </p>
              </div>
            </div>
          </div>
          <div className="care03 mt20">
            <div className="text_input">
              <p>광주+돌봄</p>
              <div className="input_wrap">
                <div
                  className="input_box mr8"
                  style={{ width: "27%" }}
                >
                  <select
                    className="essential w100 select2depth"
                    //   onChange={handleDollbomSelect}
                    {...register("cateCd")}
                  >
                    {/* onChange="categoryChange(this)" */}
                    <option value="">선택</option>
                    <option value={1}>가사지원</option>
                    <option value={2}>식사지원</option>
                    <option value={3}>동행지원</option>
                    <option value={4}>건강지원</option>
                    <option value={5}>안전지원</option>
                    <option value={6}>주거편의</option>
                    <option value={7}>일시보호</option>
                  </select>
                </div>
                {!data.cateCd && (
                  <>
                    <div
                      className="input_box mr8"
                      style={{ width: "27%" }}
                    >
                      <select className="essential w100" id="selectDepth">
                        <option value="">선택</option>
                      </select>
                    </div>
                    <div
                      className="input_box ifdate"
                      style={{
                        width: "calc(46% - 16px)",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        type="text"
                        style={{ width: "46%" }}
                        id="birth"
                        placeholder="지원시작일"
                        max={"9999-12-31"}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                      <p>~</p>
                      <input
                        type="text"
                        style={{ width: "46%" }}
                        id="birth"
                        placeholder="지원종료일"
                        max={"9999-12-31"}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                    </div>
                    <div className="select2death_cont mt8">
                      <div
                        className="select2death00 select2death01"
                      >
                        <div
                          className="input_box mr8"
                          style={{ width: "40%" }}
                        >
                          <select className="w50 mr8">
                            <option>주기 선택</option>
                            <option value={1}>일</option>
                            <option value={2}>주</option>
                            <option value={3}>격주</option>
                            <option value={5}>월</option>
                          </select>
                          <input
                            type="number"
                            className="mr8"
                            placeholder="지원 횟수"
                            style={{ width: "calc(50% - 28px)" }}
                            onInput={(e) => {
                              e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                            }}
                          />
                          <p style={{ width: 12 }}>회</p>
                        </div>
                        <div
                          className="input_box mr8"
                          style={{ width: "22%" }}
                        >
                          <DateOptions />
                        </div>
                        <div
                          className="input_box"
                          style={{ width: "22%" }}
                        >
                          <input
                            type="number"
                            className="mr8"
                            placeholder="시간 입력"
                            style={{ width: "calc(100% - 31px)" }}
                            onInput={(e) => {
                              e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                            }}
                          />
                          <p style={{ width: 28 }}>시간</p>
                        </div>
                      </div>
                      <button type="button" className="add">
                        <img src={PlusIcon} alt="접수 아이콘" />
                        추가
                      </button>
                    </div>
                  </>
                )}
                {data.cateCd == 1 && (
                  <>
                    <div
                      className="input_box mr8"
                      style={{ width: "27%" }}
                    >
                      <select
                        className="essential w100"
                        id="selectDepth"
                        //   onChange={handleServiceCate2}
                        {...register("siUid")}
                      >
                        <option value="">선택</option>
                        {services
                          .filter((service) => service.cateCd === "1")
                          .map((service) => (
                            <option key={service.siUid} value={service.siUid}>
                              {service.serviceNm}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div
                      className="input_box ifdate"
                      style={{
                        width: "calc(46% - 16px)",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        //   onChange={handleBiginDate}
                        type="text"
                        style={{ width: "46%" }}
                        id="birth"
                        placeholder="지원시작일"
                        max={"9999-12-31"}
                        onFocus={handleFocus}
                        {...register("beginDate", {
                          onChange: (e:any) => {
                            setValue("endDate", moment(e.target.value).format('yyyy-MM-DD'))
                          }
                        })}
                        onBlur={handleBlur}
                      />
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                      <p>~</p>
                      <input
                        //   onChange={handleEndDate}
                        type="text"
                        style={{ width: "46%" }}
                        id="birth"
                        placeholder="지원종료일"
                        max={"9999-12-31"}
                        disabled={getValues('beginDate') ? false : true}
                        onFocus={handleFocus}
                        {...register("endDate", {
                          onChange: (e:any) => {
                            if(getValues("beginDate")) {
                              if(moment(e.target.value).diff(getValues('beginDate'), 'days') < 0) {
                                window.alert("지원종료일은 지원시작일 이후로 설정해주세요.")
                                setValue("endDate", moment(getValues("beginDate")).format('yyyy-MM-DD'))
                              }
                            }
                          }
                        })}
                        onBlur={handleBlur}
                      />
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                    </div>
                    <div className="select2death_cont mt8">
                      <div
                        className="select2death00 select2death01"
                      >
                        <div
                          className="input_box mr8"
                          style={{ width: "40%" }}
                        >
                          <select
                            className="w50 mr8"
                            //   onChange={handleCycle}
                            {...register("cycleUnit")}
                          >
                            <option value="">주기 선택</option>
                            <option value="일">일</option>
                            <option value="주">주</option>
                            <option value="격주">격주</option>
                            <option value="월">월</option>
                          </select>
                          <input
                            //   onChange={handleCount}
                            type="number"
                            className="essential mr8"
                            placeholder="지원 횟수"
                            style={{ width: "calc(50% - 28px)" }}
                            {...register("totalCount")}
                            onInput={(e) => {
                              e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                            }}
                          />
                          <p style={{ width: 12 }}>회</p>
                        </div>
                        <div
                          className="input_box mr8"
                          style={{ width: "22%" }}
                        >
                          <div
                            style={{
                              width: "170px",
                              backgroundColor: "white",
                            }}
                          >
                            <Controller
                              control={control}
                              name="weekday"
                              render={({ field: { onChange, value } }) => (
                                <MultiSelect
                                  options={dateOptions}
                                  className="multi-select"
                                  value={
                                    value
                                      ?.split(",")
                                      .map((v) => ({ label: v, value: v })) ?? []
                                  }
                                  onChange={(
                                    event: { label: string; value: string }[]
                                  ) =>
                                    onChange(event.map((e) => e.value).join(","))
                                  }
                                  labelledBy="선택"
                                  hasSelectAll={false}
                                  disableSearch={true}
                                  overrideStrings={{
                                    selectSomeItems: "선택",
                                    allItemsAreSelected: "모두선택",
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div
                          className="input_box"
                          style={{ width: "22%", display: shower ? "none" : "" }}
                        >
                           <input
                              //   onChange={handleTimes}
                              type="number"
                              className="essential mr8"
                              placeholder="시간 입력"
                              style={{ width: "calc(100% - 31px)" }}
                              {...register("times")}
                              onInput={(e) => {
                                e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                              }}
                            />
                          <p style={{ width: 28 }}>시간</p>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="add"
                        onClick={handleAddPlan}
                      >
                        <img src={PlusIcon} alt="접수 아이콘" />
                        추가
                      </button>
                    </div>
                  </>
                )}
                {data.cateCd == 2 && (
                  <>
                    <div
                      className="input_box mr8"
                      style={{ width: "27%" }}
                    >
                      <select
                        className="essential w100"
                        id="selectDepth"
                        {...register("siUid")}
                      >
                        <option value="">선택</option>
                        {services
                          .filter((service) => service.cateCd === "2")
                          .map((service) => (
                            <option key={service.siUid} value={service.siUid}>
                              {service.serviceNm}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div
                      className="input_box ifdate"
                      style={{
                        width: "calc(46% - 16px)",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        //   onChange={handleBiginDate}
                        type="text"
                        style={{ width: "46%" }}
                        id="birth"
                        placeholder="지원시작일"
                        max={"9999-12-31"}
                        onFocus={handleFocus}
                        {...register("beginDate", {
                          onChange: (e:any) => {
                            setValue("endDate", moment(e.target.value).format('yyyy-MM-DD'))
                          }
                        })}
                        onBlur={handleBlur}
                      />
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                      <p>~</p>
                      <input
                        //   onChange={handleEndDate}
                        type="text"
                        style={{ width: "46%" }}
                        id="birth"
                        placeholder="지원종료일"
                        max={"9999-12-31"}
                        disabled={getValues('beginDate') ? false : true}
                        onFocus={handleFocus}
                        {...register("endDate", {
                          onChange: (e:any) => {
                            if(getValues("beginDate")) {
                              if(moment(e.target.value).diff(getValues('beginDate'), 'days') < 0) {
                                window.alert("지원종료일은 지원시작일 이후로 설정해주세요.")
                                setValue("endDate", moment(getValues("beginDate")).format('yyyy-MM-DD'))
                              }
                            }
                          }
                        })}
                        onBlur={handleBlur}
                      />
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                    </div>
                    <div className="select2death_cont mt8">
                      <div
                        className="select2death00 select2death01"
                      >
                        <div
                          className="input_box mr8"
                          style={{ width: "40%" }}
                        >
                          <select
                            className="w50 mr8"
                            {...register("cycleUnit")}
                          >
                            <option value="">주기 선택</option>
                            <option>일</option>
                            <option>주</option>
                          </select>
                          <input
                            type="number"
                            className="essential mr8"
                            placeholder="지원 횟수"
                            style={{ width: "calc(50% - 28px)" }}
                            {...register("totalCount")}
                            onInput={(e) => {
                              e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                            }}
                          />
                          <p style={{ width: 12 }}>회</p>
                        </div>
                        <div
                          className="input_box mr8"
                          style={{ width: "22%" }}
                        >
                          <div
                            style={{
                              width: "170px",
                              backgroundColor: "white",
                            }}
                          >
                            <Controller
                              control={control}
                              name="weekday"
                              render={({ field: { onChange, value } }) => (
                                <MultiSelect
                                  options={dateOptions}
                                  className="multi-select"
                                  value={
                                    value
                                      ?.split(",")
                                      .map((v) => ({ label: v, value: v })) ?? []
                                  }
                                  onChange={(
                                    event: { label: string; value: string }[]
                                  ) =>
                                    onChange(event.map((e) => e.value).join(","))
                                  }
                                  labelledBy="선택"
                                  hasSelectAll={false}
                                  disableSearch={true}
                                  overrideStrings={{
                                    selectSomeItems: "선택",
                                    allItemsAreSelected: "모두선택",
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div
                          className="input_box"
                          style={{ width: "22%", display: "none" }}
                        >
                          <input
                            type="number"
                            className="mr8"
                            placeholder="시간 입력"
                            style={{ width: "calc(100% - 31px)" }}
                            {...register("times", {
                              value: 1
                            })}
                            onInput={(e) => {
                              e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                            }}
                          />
                          <p style={{ width: 28 }}>시간</p>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="add"
                        onClick={handleAddPlan}
                      >
                        <img src={PlusIcon} alt="접수 아이콘" />
                        추가
                      </button>
                    </div>
                  </>
                )}
                {data.cateCd == 3 && (
                  <>
                    <div
                      className="input_box mr8"
                      style={{ width: "27%" }}
                    >
                      <select
                        className="essential w100"
                        id="selectDepth"
                        {...register("siUid")}
                      >
                        <option value="">선택</option>
                        {services
                          .filter((service) => service.cateCd === "3")
                          .map((service) => (
                            <option key={service.siUid} value={service.siUid}>
                              {service.serviceNm}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="select2death_cont mt8">
                      <div
                        className="select2death00 select2death01"
                      >
                        <div
                          className="input_box mr8"
                          style={{ width: "30%" }}
                        >
                          <input
                            type="text"
                            className="essential mr8"
                            placeholder="필요 횟수"
                            style={{ width: "calc(100% - 20px)" }}
                            {...register("totalCount")}
                            onInput={(e) => {
                              e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                            }}
                          />
                          <p style={{ width: 12 }}>회</p>
                        </div>
                        <div
                          className="input_box"
                          style={{ width: "calc(70% - 8px)" }}
                        >
                          <input
                            type="text"
                            className="w100"
                            placeholder="상세내용"
                            {...register("serviceDetail")}
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        className="add"
                        onClick={handleAddPlan}
                      >
                        <img src={PlusIcon} alt="접수 아이콘" />
                        추가
                      </button>
                    </div>
                  </>
                )}
                {data.cateCd == 4 && (
                  <>
                    <div
                      className="input_box mr8"
                      style={{ width: "27%" }}
                    >
                      <select
                        className="essential w100"
                        id="selectDepth"
                        {...register("siUid")}
                      >
                        <option value="">선택</option>
                        {services
                          .filter((service) => service.cateCd === "4")
                          .map((service) => (
                            <option key={service.siUid} value={service.siUid}>
                              {service.serviceNm}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div
                      className="input_box ifdate"
                      style={{
                        width: "calc(46% - 16px)",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        //   onChange={handleBiginDate}
                        type="text"
                        style={{ width: "46%" }}
                        id="birth"
                        placeholder="지원시작일"
                        max={"9999-12-31"}
                        onFocus={handleFocus}
                        {...register("beginDate", {
                          onChange: (e:any) => {
                            setValue("endDate", moment(e.target.value).format('yyyy-MM-DD'))
                          }
                        })}
                        onBlur={handleBlur}
                      />
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                      <p>~</p>
                      <input
                        //   onChange={handleEndDate}
                        type="text"
                        style={{ width: "46%" }}
                        id="birth"
                        placeholder="지원종료일"
                        max={"9999-12-31"}
                        disabled={getValues('beginDate') ? false : true}
                        onFocus={handleFocus}
                        {...register("endDate", {
                          onChange: (e:any) => {
                            if(getValues("beginDate")) {
                              if(moment(e.target.value).diff(getValues('beginDate'), 'days') < 0) {
                                window.alert("지원종료일은 지원시작일 이후로 설정해주세요.")
                                setValue("endDate", moment(getValues("beginDate")).format('yyyy-MM-DD'))
                              }
                            }
                          }
                        })}
                        onBlur={handleBlur}
                      />
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                    </div>
                    <div className="select2death_cont mt8">
                      <div
                        className="select2death00 select2death01"
                      >
                        <div
                          className="input_box mr8"
                          style={{ width: "30%" }}
                        >
                          <input
                            type="number"
                            className="essential mr8"
                            placeholder="지원 횟수"
                            style={{ width: "calc(100% - 20px)" }}
                            {...register("totalCount")}
                            onInput={(e) => {
                              e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                            }}
                          />
                          <p style={{ width: 12 }}>회</p>
                        </div>
                        <div
                          className="input_box"
                          style={{ width: "calc(70% - 8px)" }}
                        ></div>
                      </div>
                      <button
                        type="button"
                        className="add"
                        onClick={handleAddPlan}
                      >
                        <img src={PlusIcon} alt="접수 아이콘" />
                        추가
                      </button>
                    </div>
                  </>
                )}
                {data.cateCd == 5 && (
                  <>
                    <div
                      className="input_box mr8"
                      style={{ width: "27%" }}
                    >
                      <select
                        className="essential w100"
                        id="selectDepth"
                        {...register("siUid")}
                      >
                        <option value="">선택</option>
                        {services
                          .filter((service) => service.cateCd === "5")
                          .map((service) => (
                            <option key={service.siUid} value={service.siUid}>
                              {service.serviceNm}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div
                      className="input_box ifdate"
                      style={{
                        width: "calc(46% - 16px)",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                    </div>
                    <div className="select2death_cont mt8">
                      <div
                        className="select2death00 select2death01"
                      >
                        <div
                          className="input_box"
                          style={{ width: "100%" }}
                        >
                          <input
                            type="text"
                            className="w100"
                            placeholder="상세내용"
                            {...register("serviceDetail")}
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        className="add"
                        onClick={handleAddPlan}
                      >
                        <img src={PlusIcon} alt="접수 아이콘" />
                        추가
                      </button>
                    </div>
                  </>
                )}
                {data.cateCd == 6 && (
                  <>
                    <div
                      className="input_box mr8"
                      style={{ width: "27%" }}
                    >
                      <select
                        className="essential w100"
                        id="selectDepth"
                        {...register("siUid")}
                      >
                        <option value="">선택</option>
                        {services
                          .filter((service) => service.cateCd === "6")
                          .map((service) => (
                            <option key={service.siUid} value={service.siUid}>
                              {service.serviceNm}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div
                      className="input_box ifdate"
                      style={{
                        width: "calc(46% - 16px)",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                    </div>
                    <div className="select2death_cont mt8">
                      <div
                        className="select2death00 select2death01"
                      >
                        <div
                          className="input_box"
                          style={{ width: "100%" }}
                        >
                          <input
                            type="text"
                            className="w100"
                            placeholder="상세내용"
                            {...register("serviceDetail")}
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        className="add"
                        onClick={handleAddPlan}
                      >
                        <img src={PlusIcon} alt="접수 아이콘" />
                        추가
                      </button>
                    </div>
                  </>
                )}
                {data.cateCd == 7 && (
                  <>
                    <div
                      className="input_box mr8"
                      style={{ width: "27%" }}
                    >
                      <select
                        className="essential w100"
                        id="selectDepth"
                        {...register("siUid")}
                      >
                        <option value="">선택</option>
                        {services
                          .filter((service) => service.cateCd === "7")
                          .map((service) => (
                            <option key={service.siUid} value={service.siUid}>
                              {service.serviceNm}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div
                      className="input_box ifdate"
                      style={{
                        width: "calc(46% - 16px)",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        //   onChange={handleBiginDate}
                        type="text"
                        style={{ width: "46%" }}
                        id="birth"
                        placeholder="지원시작일"
                        max={"9999-12-31"}
                        onFocus={handleFocus}
                        {...register("beginDate", {
                          onChange: (e:any) => {
                            setValue("endDate", moment(e.target.value).format('yyyy-MM-DD'))
                          }
                        })}
                        onBlur={handleBlur}
                      />
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                      <p>~</p>
                      <input
                        //   onChange={handleEndDate}
                        type="text"
                        style={{ width: "46%" }}
                        id="birth"
                        placeholder="지원종료일"
                        max={"9999-12-31"}
                        disabled={getValues('beginDate') ? false : true}
                        onFocus={handleFocus}
                        {...register("endDate", {
                          onChange: (e:any) => {
                            if(getValues("beginDate")) {
                              if(moment(e.target.value).diff(getValues('beginDate'), 'days') < 0) {
                                window.alert("지원종료일은 지원시작일 이후로 설정해주세요.")
                                setValue("endDate", moment(getValues("beginDate")).format('yyyy-MM-DD'))
                              }
                            }
                          }
                        })}
                        onBlur={handleBlur}
                      />
                      {/* onFocus="(this.type='date')" onblur="(this.type='text')"  */}
                    </div>
                    <div className="select2death_cont mt8">
                      <div
                        className="select2death00 select2death01"
                      >
                        <div
                          className="input_box mr8"
                          style={{ width: "30%" }}
                        >
                          <input
                            type="number"
                            className="essential mr8"
                            placeholder="지원 횟수"
                            style={{ width: "calc(100% - 20px)" }}
                            {...register("totalCount")}
                            onInput={(e) => {
                              e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
                            }}
                          />
                          <p style={{ width: 12 }}>회</p>
                        </div>
                        <div
                          className="input_box"
                          style={{ width: "calc(70% - 8px)" }}
                        ></div>
                      </div>
                      <button
                        type="button"
                        className="add"
                        onClick={handleAddPlan}
                      >
                        <img src={PlusIcon} alt="접수 아이콘" />
                        추가
                      </button>
                    </div>
                  </>
                )}
                {/* 선택 시 바뀜 */}
                <p className="cost" style={{ width: "calc(100%)", paddingTop: "3px" }}>
                  <div style={{border: "thick double", marginLeft: "70%"}}>
                    <span style={{marginRight: "10px"}}>비용 : </span>
                    <span className={getValues('cateCd') && Number(getValues('cateCd')) !== 3 && Number(getValues('cateCd')) !== 5 && Number(getValues('cateCd')) !== 6 ? "red" : "gray" }>
                      {currentCost.toLocaleString('ko-KR')}
                    </span>{" "}
                    원
                  </div>
                  
                </p>
                {/* 테이블 */}
  
                <div className="care_plan_list">
                  <table>
                    <thead>
                      <tr>
                        <th>서비스 종류</th>
                        <th>지원시간(내용)</th>
                        <th>지원기간</th>
                        <th>비용</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody className="addTable">
                    {carePlan?.carePlan
                        .filter((plan) => Number(plan.cateCd) !== 0 && Number(plan.cateCd) !== 8)
                        .map((plan, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="hidden"
                                className="cateCd"
                                value={plan.cateCd}
                              />
                              {plan.weekday && (
                                <input
                                  type="hidden"
                                  className="weekday"
                                  value={plan.weekday}
                                />
                              )}
                              {plan.cycleUnit && (
                                <input
                                  type="hidden"
                                  className="cycleUnit"
                                  value={plan.cycleUnit}
                                />
                              )}
                              <input
                                type="hidden"
                                className="siUid"
                                value={plan.siUid}
                              />
                              {plan.totalCount && (
                                <input type="hidden" className="totalCount" value={plan.totalCount} />
                              )}
                              {Number(plan.cateCd) === 1 && "가사지원"}
                              {Number(plan.cateCd) === 2 && "식사지원"}
                              {Number(plan.cateCd) === 3 && "동행지원"}
                              {Number(plan.cateCd) === 4 && "건강지원"}
                              {Number(plan.cateCd) === 5 && "안전지원"}
                              {Number(plan.cateCd) === 6 && "주거편의"}
                              {Number(plan.cateCd) === 7 && "일시보호"}
                              &nbsp; &gt; &nbsp;
                              {carePlan.gjList.map((list) => (
                                plan.siUid === list.siUid && list.serviceNm
                              ))}
                            </td>
                            {Number(plan.cateCd) !== 3 && Number(plan.cateCd) !== 5 && Number(plan.cateCd) !== 6 ?
                            <td>
                                {plan.totalCount && plan.totalCount + "회"}
                                {plan.cycleUnit && "/" + plan.cycleUnit}
                                {plan.weekday && "/" + plan.weekday}
                                {(Number(plan.cateCd) === 1) && "/" + plan.times + "시간"}
                            </td>
                            :
                            <td>
                              {plan.totalCount ? plan.totalCount + "회" : ""}{(plan.totalCount && plan.serviceDetail) ? "/" + plan.serviceDetail : plan.serviceDetail}
                            </td>
                            }
                            {/* {plan.cycleUnit &&
                              plan.totalCount &&
                              plan.weekday &&
                              plan.times && (
                                <td>
                                  {plan.cycleUnit}&nbsp;{plan.totalCount}회&nbsp;(
                                  {plan.weekday})&nbsp;{plan.times}시간
                                </td>
                              )}
                            {plan.totalCount &&
                              !plan.serviceDetail &&
                              !plan.times && <td>{plan.totalCount}회</td>}
                            {!plan.cycleUnit &&
                              plan.totalCount &&
                              !plan.serviceDetail &&
                              plan.times && (
                                <td>
                                  {plan.totalCount}회 {plan.times && plan.times}
                                  시간
                                </td>
                              )}
                            {plan.serviceDetail && !plan.totalCount && (
                              <td>{plan.serviceDetail}</td>
                            )}
                            {plan.serviceDetail && plan.totalCount && (
                              <td>
                                {plan.totalCount}회({plan.serviceDetail})
                              </td>
                            )}
                            {!plan.totalCount && !plan.serviceDetail && (
                              <td style={{ color: "#9da0a8" }}>
                                지원시간(내용) 없음
                              </td>
                            )} */}
                            <td>
                              {!plan.beginDate && "-"}
                              {plan.beginDate}
                              {plan.endDate && ` ~ ${plan.endDate}`}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {(Number(plan.cateCd) !== 3 && Number(plan.cateCd) !== 5 && Number(plan.cateCd) !== 6) ?
                                <span className="red">
                                  {plan.cost.toLocaleString()}
                                </span>
                                :
                                <span className="gray">
                                  {plan.cost.toLocaleString()}
                                </span>
                                }
                              {/* {!plan.beginDate &&
                                <span className="gray">
                                  {plan.cost.toLocaleString()}
                                </span>} */}
                                {" "}
                              원
                            </td>
  
                            <td>
                              <button
                                type="button"
                                className="btn_gray mr6"
                                onClick={handleModifyClick}
                              >
                                수정
                              </button>
                              <button
                                type="button"
                                className="btn_red"
                                onClick={handleDeleteClick}
                              >
                                삭제
                              </button>
                            </td>
                          </tr>
                        ))}
                      {plans.map((plan, index) => (
                        <DollbomPlanItem
                          key={index}
                          plan={plan}
                          onModify={() => handleModifyPlan(plan)}
                          onDelete={() => handleDeletePlan(plan)}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
  
                {/* 테이블 end*/}
              </div>
            </div>
          </div>
        </div>
        {/* ㄴㄴ */}
        <div className="cost_list">
          <ul>
            <li>
              <p>(광주+돌봄 잔액)</p>
              <p>
                <span>{gjChange.toLocaleString()}</span> 원
              </p>
            </li>
            <li>-</li>
            <li>
              <p>(계획비용)</p>
              <p>
                <span className="red">{totalCost.toLocaleString()}</span>{" "}
                원
              </p>
            </li>
            <li>=</li>
            <li>
              <p>(예상잔액)</p>
              <p>
                <span className="blue">
                  {(gjChange - totalCost).toLocaleString()}
                </span>{" "}
                원
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>(긴급돌봄 잔액)</p>
              <p>
                <span>{emerChange.toLocaleString()}</span> 원
              </p>
            </li>
            <li>-</li>
            <li>
              <p>(계획비용)</p>
              <p>
                <span className="red">
                  {(data.emerDay * data.emerTime * 16_600).toLocaleString()}
                </span>{" "}
                원
              </p>
            </li>
            <li>=</li>
            <li>
              <p>(예상잔액)</p>
              <p>
                <span className="blue">
                  {(
                    emerChange -
                    data.emerDay * data.emerTime * 16_600
                  ).toLocaleString()}
                </span>{" "}
                원
              </p>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const dateOptions = [
    { label: "월", value: "월" },
    { label: "화", value: "화" },
    { label: "수", value: "수", disabled: false },
    { label: "목", value: "목", disabled: false },
    { label: "금", value: "금", disabled: false },
    { label: "토", value: "토", disabled: false },
    { label: "일", value: "일", disabled: false },
  ];

  export default EditDollbomPlan