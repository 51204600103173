import { instanceToPlain } from "class-transformer";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/ic_close.png";
import useMember from "../../hooks/useMember";
import { DialogProps, useDialog } from "../DialogProvider";
import PostCodeDialog from "../PostCodeDialog";
import SettingAPI from "../../apis/SettingAPI";
import SettingList from "../../models/SettingList";
import React, { useEffect, useState, useRef } from "react";
import { telValid } from "../../App";

/**
 * 환경설정 > 기관정보 > 기관등록 팝업 
 */

interface OrgRegisterDialogProps extends DialogProps{
  orgInfo?: SettingList;
  dptUid : number;
}

interface OrgRegisterFormInput{
  deptCd : string;
  dptUid : number;
  departName : string;
  // locality : string;
  adres: string; 
  adresDtl: string;
  // tel : string;
  telno1?: string; // 전화번호
  telno2?: string;
  telno3?: string;
  status : string;
  crtUid : number;
  delUid : number;
} 

const OrgRegisterDialog = ( {
  dptUid,
  orgInfo,
  ...props
}: OrgRegisterDialogProps) => {
  const navigate = useNavigate();
  const { member } = useMember();
  const { showDialog } = useDialog();
  const { register, handleSubmit, watch, setValue, getValues, formState: {errors}, setError } =
    useForm<OrgRegisterFormInput>({
      defaultValues: {
        // ...orgInfo,
        deptCd: orgInfo?.deptCdString,
        dptUid: orgInfo?.dptUid,
        departName: orgInfo?.departName ,
        adres: orgInfo?.adres,
        adresDtl: orgInfo?.adresDtl,
        telno1: orgInfo?.telno1,
        telno2: orgInfo?.telno2,
        telno3: orgInfo?.telno3,
        status: orgInfo?.status ,
        crtUid: orgInfo?.crtUid,
        delUid: orgInfo?.delUid,
      },
    });
  const data = watch();

  useEffect(( ()=> {
    // console.log("orgInfo : ", orgInfo)
    SettingAPI.getOrg({ dptUid: Number(dptUid)}).then((res) => {

    });
  }), [])

  const handleAddressSearch = () => {
    showDialog(PostCodeDialog, {
      onComplete(address) {
         setValue("adres", address.roadAddress);
      },
    });
  };

  const telno1 = watch("telno1");

  const handleClose = () => {
    props.onClose?.();
  };

  const statusList = ["사용", "미사용"];
  const [delNum, setDelNum] = useState("");

  const handleChangeStatus = (e:string) => {
    // console.log(e)
    setDelNum(e);
  };


  const onSubmit: SubmitHandler<OrgRegisterFormInput> = async (data) => {
    // console.log("data : ", data);
    let delUid = delNum === "미사용" ? 999 : member?.mbrUid

  //   // TODO: 유효성 검사
  //   // if (!member) {
  //   //   return;
  //   // }

    const success = await SettingAPI.insertOrg({
      ...data,
      locality: `${data.adres},${data.adresDtl}`,
      tel: `${data.telno1}-${data.telno2}-${data.telno3}`,
      deptCd: `${data.deptCd}`,
      crtUid: member?.mbrUid,
      delUid: delUid,
    });

    if (success) {
      alert("완료");
      console.log(data);
      navigate(0);
    } else {
      console.log(">>> failure");
      alert("등록실패");
    }
  };

  const onError = () => {
    if(errors.deptCd) {
      window.alert(errors.deptCd.message)
    } else if(errors.departName) {
      window.alert(errors.departName.message)
    } else if(errors.telno2) {
      window.alert(errors.telno2.message)
    } else if(errors.telno3) {
      window.alert(errors.telno3.message)
    } else if(errors.adres) {
      window.alert(errors.adres.message)
    }
  }

  return (
    <div className="recive_popup popup_black" {...props}>
      <div
        className="recive_popup_inner popup_white"
        style={{ overflowY: "visible" }}
      >
        <div className="popup_tit">
          <h3>{orgInfo ? "기관수정" : "기관등록"}</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
          {/* <input type="hidden"{...register("crtUid")} name="crtUid" value={1}/>  */}
          {/* <input type="hidden"{...register("delUid")} name="delUid" value={999}/>  */}
            <div className="default_input">
              <h4>기관구분</h4>
              <div className="write_box">
                {/* <label htmlFor="radio11" className="mr20">
                  <input 
                    type="radio" 
                    id="radio11" 
                    value="SI"
                    {...register("deptCd")}
                    />시
                </label> */}
                {member?.depart?.deptCd === "SI" ?
                <>
                <label htmlFor="radio22" className="mr20">
                  <input 
                    type="radio" 
                    id="radio22" 
                    value="GU" 
                    {...register("deptCd", {
                      required: "기관구분을 선택해주세요."
                    })}
                    />구
                </label>
                </>
                :
                null}
                <label htmlFor="radio33" className="mr20">
                  <input 
                      type="radio" 
                      id="radio33" 
                      value="DONG" 
                      {...register("deptCd", {
                        required: "기관구분을 선택해주세요."
                      })}
                      />동
                </label>
                <label htmlFor="radio44" className="mr20">
                  <input 
                      type="radio" 
                      id="radio44" 
                      value="ORG" 
                      {...register("deptCd", {
                        required: "기관구분을 선택해주세요."
                      })}
                      />기관
                </label>
              </div>
            </div>

            <div className="default_input mt20">
              <h4>기관정보</h4>
                <div className="write_box">
                  <div className="name mr8 w50_8" style={{position: 'relative' }}>
                    <input 
                        type="text" 
                        className="default_input essential" 
                        placeholder="기관명 입력"  
                        style={{width: '340px'}}
                        {...register("departName", {
                          required: "기관명을 입력해주세요."
                        })}
                        />
                  </div>
              </div>
            </div>       
            <div className="default_input tel mt8">
              <h4>기관 연락처</h4>
              <div className="write_box">
                <select
                  className="essential"
                  style={{ color: data.telno1 ? "#000" : "#9da0a8" }}
                  {...register("telno1")}
                >
                  <option value="062">062</option>
                  <option value="010">010</option>
                  <option value="011">011</option>
                  <option value="012">017</option>
                  <option value="013">018</option>
                  <option value="014">019</option>
                </select>
                <span>-</span>
                <input type="tel"
                  className="essential"
                  placeholder="연락처"
                  maxLength={4}
                 {...register("telno2", {
                  required: "기관 연락처를 입력해주세요.",
                  onChange: (e:any) => {
                    if(!telValid.test(e.target.value)) {
                      e.target.value = String(e.target.value).slice(0, -1)
                    }
                  }
                })}
                 />
                <span>-</span>
                <input type="tel"
                  className="essential"
                  placeholder="연락처"
                  maxLength={4}
                 {...register("telno3", {
                  required: "기관 연락처를 입력해주세요.",
                  onChange: (e:any) => {
                    if(!telValid.test(e.target.value)) {
                      e.target.value = String(e.target.value).slice(0, -1)
                    }
                  }
                })}
                 />
              </div>
            </div>
            <div className="default_input mt8">
              <h4></h4>
                <div className="write_box">
                  <div className="address">
                    <input
                      type="text"
                      className="mr8 essential"
                      placeholder="주소 입력"
                      disabled
                      {...register("adres", {
                        required: "기관주소를 입력해주세요."
                      })}
                    />
                    <button type="button" onClick={handleAddressSearch}>
                      주소검색
                    </button>
                  </div>
                  <input
                    type="text"
                    className="mt8"
                    placeholder="상세주소 입력"
                    {...register("adresDtl")}
                  />
                </div>
            </div>  
            <div className="default_input tel mt20">
              <h4>상태</h4>
              <div className="write_box">
                <select
                  className=""
                  {...register("status", {
                    onChange: () => handleChangeStatus(getValues("status"))
                  })}
                  >
                  {statusList.map((item:any) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                
                  {/* <option value="사용">사용</option>
                  <option value="미사용">미사용</option> */}
                </select>
              </div>
            </div>  
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="submit" className="submit">
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrgRegisterDialog;
