import { relative } from "path";
import React from "react";
import DaumPostcode, { Address } from "react-daum-postcode";

import CloseIcon from "../assets/ic_close.png";
import { DialogProps } from "./DialogProvider";

/**
 * 작성자: 김혜정
 * 작성일: 2023.03.06
 *
 * 주소 검색
 */

const postCodeStyle: React.CSSProperties = {
  display: "block",
  position: "absolute",
  top: "10%",
  width: "600px",
  height: "600px",
  padding: "7px",
};

interface PostCodeDialogProps extends DialogProps {
  onComplete: (address: Address) => void;
}

const PostCodeDialog = ({ onComplete, ...props }: PostCodeDialogProps) => {
  const handlePostCode = (data: Address) => {
    onComplete(data);
    props.onClose?.();
  };

  const handleClose = () => {
    props.onClose?.();
  };

  return (
    <div className="popup_black" {...props}>
      <div
        className="recive_popup_inner popup_white"
        style={{ overflowY: "visible", width: "640px", height: "680px" }}
      >
        <div className="popup_tit">
          <h3>주소검색</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <DaumPostcode
          style={postCodeStyle}
          onComplete={handlePostCode} // 값을 선택할 경우 실행되는 이벤트
          autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
        />
      </div>
    </div>
  );
};

export default PostCodeDialog;
