import axios from "axios";
import { plainToInstance } from "class-transformer";
import { serialize } from "object-to-formdata";

/**
 * 작성자: 홍준표
 * 작성일: 2023.04.11
 */

export interface FilesUploadParams {
  imageFiles: File[],
  folder: string,
  fileGbn: string,
  uid: number,
}

//다중 파일업로드
const fileUploadMulti = (params: FilesUploadParams) => {
  return axios
    .post("/api/fileUpload/fileUpload-multi", serialize(params))
    .then((res) => res.data);
};

export interface FileUploadParams {
  attachFile: File,
  folder: string,
  fileGbn: string,
  uid: number,
}

//단일 파일업로드
const fileUpload = (params: FileUploadParams) => {
  return axios
    .post("/api/fileUpload/fileUpload", serialize(params))
    .then((res) => {
      return res.data.success as boolean
    });
};

interface GetFileParams {
  uid: number,
  folder: string,
}

//파일 조회
const getFile = (params: GetFileParams) => {
  const fileData = axios.get("/api/fileUpload/getFile", {params, responseType: 'blob'})
  .then((res) => res.data as Blob)
  return fileData
}

//파일경로 조회
const getFilePath = (params: GetFileParams) => {
  return axios.get("/api/fileUpload/getFilePath", {params})
  .then((res) => {
    return res.data as string
  })
}

//파일삭제 테스트
const deleteFile = (params: {filePath?: string, uid: number, folder: string}) => {
  return axios.post("/api/fileUpload/deleteFile", serialize(params))
  .then((res) => {
    // console.log(res)
    return res.data as number
  })
}



const FileUploadAPI = {
  fileUploadMulti,
  fileUpload,
  getFile,
  getFilePath,
  deleteFile,
};

export default FileUploadAPI;
