import { Expose } from 'class-transformer';
import Gender from "./Gender";
/*
    작성자: 김덕현
    작성일: 2023.04.11

*/

class SprtList {
    constructor(
       public syUid: number,
       public stUid: number,
       public csUid: number,
       public caseContent: string,
       public hdong: string,
       public trgterNm: string,
       public trgterCd: string,
       public brthdy: string,
       public gender: Gender,
       public telno: string,
       public adres: string,
       public adresDtl: string,
       public statCd: SprtMoreStatus,
       public crtDt: string,
       public cfmYn: string,
       public startIndex: number,
       public pageSize: number,
       public page: number,
       public totalCount: number,
       public searchKeyword: string,
       public searchType: string,
       public reason: string,
       public mtOpnn: string,
    ){}

    get fullAddress(): string {
        return (this.adres ?? '') + ", " + (this.adresDtl ?? '');
      }
    get action(): SprtMoreAction {
    switch (this.statCd) {
        case "현장방문":
        return "의견작성";
        case "돌봄계획확정":
        return "의견작성";
        case "서비스의뢰":
        case "사례회의(권역)":
        return "의견작성";
        case "사례종결":
        return "승인";
    }
    }
    
}

export type SprtMoreStatus =
  | "현장방문"
  | "돌봄계획확정"
  | "서비스의뢰"
  | "사례회의(권역)"
  | "사례종결";

  export type SprtMoreAction =
  | "의견작성"
  | "승인"
  | "";
export default SprtList;
