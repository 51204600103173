import React, { useEffect, useState } from "react";
import moment from "moment";

import ServiceAPI from "../../apis/ServiceAPI";
import ServicePlanAPI from "../../apis/ServicePlanAPI";
import { useDialog } from "../../dialogs/DialogProvider";
import NeedsAssessmentDialog from "../../dialogs/service/NeedsAssessmentDialog";
import NaEvaluationInfo from "../../models/NaEvaluationInfo";
import ServiceDetail from "../../models/ServiceDetail";
import EmergencyIcon from "../../assets/긴급돌봄아이콘.png";
import useMember from "../../hooks/useMember";
import ServiceBillEditDialog from "../../dialogs/service/ServiceBillEditDialog";
import StopIcon from "../../assets/중단요청아이콘.png"

/**
 * 작성자: 김혜정
 * 작성일: 2023.03.010
 *
 * 서비스 페이지 목록 > 서비스 상세
 **/

const ServiceDetailList = ({
  spyUid,
  csUid,
  isStopReq,
}: {
  spyUid: number;
  csUid: number;
  isStopReq?: Function;
}) => {
  const [service, setService] = useState<ServiceDetail>();
  const { showDialog } = useDialog();
  const {member} = useMember()
  const [stopReq, setStopReq] = useState(false)

  // 필요도 평가 결과 팝업
  // const needsAssessment = (csUid: number) => {
  //   ServicePlanAPI.getEvaluationData({
  //     csUid: csUid,
  //   }).then((res) => {
  //     if (!res) {
  //       console.log("필요도평가 정보 불러오기 실패");
  //     } else {
  //       // console.log("res : ", res);
  //       showDialog(NeedsAssessmentDialog, { naData: res as NaEvaluationInfo });
  //     }
  //   });
  //   // showDialog(NeedsAssessmentDialog);
  // };

  //금액 수정 다이얼로그
  const handleEditBill = (spiUid: number) => {
    showDialog(ServiceBillEditDialog, {
      spiUid: spiUid,
    });
  }

  // 서비스 상세 불러오기
  useEffect(() => {
    ServiceAPI.getDetail({ spyUid }).then((res) => {
      setService(res)
      // console.log("서비스데이터 : ", res)
      if(res.list) {
        res.list.map((spi) => {
          if(spi.stopYn && spi.stopYn === "Y") {
            setStopReq(true)
            if(isStopReq) {
              isStopReq(spi.spyUid)
            }
            return
          }
        })
      }
      // console.log(`spyUid : ${spyUid}, res : `, res)
    });
    // console.log(service);
  }, [spyUid]);

  return (
      <table>
        {service?.list && service.list.length > 0 ?
        <>
        <thead>
        { member?.depart?.deptCd === "ORG" ?
        stopReq ? 
          <tr>
            <th>서비스회차</th>
            <th>서비스일자</th>
            <th>진행시간</th>
            <th>서비스내용</th>
            <th>변경사항</th>
            <th>담당자</th>
            <th>이용금액(수정 전 금액)</th>
            <th>금액 수정사유</th>
            <th>중단요청사유</th>
            <th>중단사유상세</th>
          </tr>
          :
          <tr>
            <th>서비스회차</th>
            <th>서비스일자</th>
            <th>진행시간</th>
            <th>서비스내용</th>
            <th>변경사항</th>
            <th>담당자</th>
            <th>이용금액(수정 전 금액)</th>
            <th>금액 수정사유</th>
          </tr>
        :
        stopReq ? 
          <tr>
            <th>서비스회차</th>
            <th>서비스일자</th>
            <th>진행시간</th>
            <th>서비스내용</th>
            <th>변경사항</th>
            <th>담당자</th>
            <th>중단요청사유</th>
            <th>중단사유상세</th>
          </tr>
          :
          <tr>
            <th>서비스회차</th>
            <th>서비스일자</th>
            <th>진행시간</th>
            <th>서비스내용</th>
            <th>변경사항</th>
            <th>담당자</th>
          </tr>
        }
        </thead>
        <tbody>
          {service?.list.map((item, idx) => (
            <tr key={idx} style={{backgroundColor: item.stopYn && item.stopYn === "Y" ? "#FFA07A" : ""}}>
              {/* <td className="first"></td> */}
              <td>{item.stopYn && item.stopYn === "Y" ? <img src={StopIcon}></img> : ""}{idx + 1}회차</td>
              <td>{item.serviceDate}</td>
              <td>{item.beginDt && item.endDt ? moment(item.beginDt).format("A h:mm") + "~" + moment(item.endDt).format("A h:mm"):""}</td>                      
              {/* <td>{item.processTime}</td> */}
              <td>{item.serviceDetail}</td>
              <td>{item.chngCnt}</td>
              <td>{item.mbrName}</td>
              {member?.depart?.deptCd === "ORG" ? 
              <>
              <td>
                {item.updRsn ? item.totalCost.toLocaleString("ko-KR") + " 원 (" + item.updCost.toLocaleString("ko-KR") + " 원)" : item.totalCost ? item.totalCost.toLocaleString("ko-KR") + " 원" : item.totalCost === 0 ? "0원" : "-"}
              </td>
              {item.updRsn ? 
              <td>
                {item.updRsn}
              </td>
              :
              item.endDt && !item.sbUid ?
              <td>
                <button type="button" className="btn_gray" onClick={() => handleEditBill(item.spiUid)}>수정</button>
              </td>
              :
              <td>-</td>
              }
              </>
              :
              null
              }
              {item.stopYn && item.stopYn ? 
              <>
              <td>{item.stopGbn}</td>
              <td>{item.stopRsn ? item.stopRsn : item.stopGbn}</td>
              </>
              :
              null}
            </tr>
          ))}
        </tbody>
        </>
        : <p>제공된 서비스 없음</p>}
        
      </table>

  );
};
export default ServiceDetailList;

