import axios from "axios";
import { plainToInstance } from "class-transformer";
import { serialize } from "object-to-formdata";

import ExpenseModel from "../models/bill/ExpenseModel";
import GuBillModel from "../models/bill/GuBillModel";
import GuDetailModel from "../models/bill/GuDetailModel";
import OrgBillModel from "../models/bill/OrgBillModel";
import OrgDetailModel from "../models/bill/OrgDetailModel";
import ServiceBillVO from "../models/bill/ServiceBillVO";
import FilesUpload from "../models/fileForm/filesUpload";

/**
 * 작성자: 홍준표
 * 작성일: 2023.03.13
 */

interface GetAllParams {
  page?: number;
  spyUid?: number;
  dptUid?: number;
  sbUid? : number;
  billStatus? : string;
  hdong?: string;
  searchTarget?: string;
  keyword?: string;
}

//청구된 목록조회(제공기관)
const getAll = (params: GetAllParams) => {
  return axios
    .get("/api/bill/orgList", { params })
    .then((response) => plainToInstance(ExpenseModel, response.data));
};

const getCount = (params: GetAllParams) => {
  return axios
    .get("/api/bill/orgListCount", { params })
    .then((res) =>  {
      return res.data
    });
};

const getOrgDetail = (params: GetAllParams) => {
  return axios
    .get("/api/bill/orgDetail", { params })
    .then((response) => {
      // console.log("response : ", response)
      return plainToInstance(OrgDetailModel, response.data as OrgDetailModel)
    });
};

interface BillList {
  success: boolean,
  list: OrgBillModel[],
}

//비용청구가능목록(제공기관)
const getBillList = (params: GetAllParams) => {
  return axios.get("/api/bill/getBillList", {params}).then((res) => {
    return res.data as BillList
  })
}

//(구) 청구된 목록 조회
const getGuList = (params: GetAllParams) => {
  return axios.get("/api/bill/guList", {params}).then((res) => {
    return plainToInstance(GuBillModel, res.data)
  })
}

//(구)목록 카운트
const getGuListCount = (params: GetAllParams) => {
  return axios.get("/api/bill/guListCount", {params}).then((res) => {
    return res.data as number
  })
}

//(구)상세
const getGuDetail = (params: GetAllParams) => {
  return axios.get("/api/bill/guDetail", {params}).then((res) => {
    return plainToInstance(GuDetailModel, res.data as GuDetailModel)
  })
}

//(구)상세 - 지급 후
const getGuPaidDetail = (params: GetAllParams) => {
  return axios.get("/api/bill/guPaidDetail", {params}).then((res) => {
    return plainToInstance(GuDetailModel, res.data as GuDetailModel)
  })
}

export interface BillPostModel {
  crtUid: number,
  demander: string,
  billItems: {spyUid: number}[],
}

export interface BillClaimRsp {
  success: boolean,
  list: ServiceBillVO[],
}

//기관 - 비용청구
const billClaim = (params: BillPostModel) => {
  return axios.post("/api/bill/bill", JSON.stringify(params), {headers: {"Content-Type" : `application/json`}}).then((res) => {
    // console.log(res.data)
    return res.data as BillClaimRsp
  })
}

//기관 - 다중파일첨부
const billFilesUpload = (params: FilesUpload) => {
  return axios.post("/api/fileUpload/fileUpload-multi", serialize(params)).then((res) => {
    console.log("res.data : ", res.data)
    return res.data
  })
}

export interface PayItem {
  spyUid: number,
  sbUid: number,
  payDt: string,
}

export interface PayComplete {
  payItems: PayItem []
}

//구 - 지급완료
const payComplete = (params: PayComplete) => {
  return axios.post("/api/bill/pay", JSON.stringify(params), {headers: {"Content-Type" : `application/json`}}).then((res) => {
    return res.data.success as boolean
  })
}

export interface EditPayCost {
  spyUid: number,
  spiUid: number,
  payCost: number,
  payRsn: string,
  sbUid: number,
  dptUid: number,
}

const editPayCost = (params: EditPayCost) => {
  return axios.post("/api/bill/editPayCost", serialize(params)).then((res) => {
    return res.data.success as boolean
  })
}

const ExpenseModelAPI = {
  getAll,
  getCount,
  // getOrgDetail,
  getBillList,
  getGuList,
  getGuListCount,
  getGuDetail,
  billClaim,
  billFilesUpload,
  payComplete,
  editPayCost,
  getGuPaidDetail,
};

export default ExpenseModelAPI;
