import React, { useEffect, useState } from 'react';
import { useDialog } from '../../../../dialogs/DialogProvider';
import ServiceTargetYear from '../../../../models/ServiceTargetYear';
import DollbomTargetInfoDialog from '../../../../dialogs/service-plan/DollbomTargetInfoDialog';
import ServicePlanDetails from '../../../../models/ServicePlanDetails';

const TargetInfoBar = (
    {
        details,
        targetMonth,
        targetDay,
        targetBrthdy,

    }
    :
    {
        details: ServicePlanDetails
        targetMonth: number
        targetDay: number
        targetBrthdy: number
    }
) => {
    // 만 나이 계산
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(0);

    const { showDialog } = useDialog();

    // 만 나이 계산
    useEffect(() => {
        const now = new Date();
        const year = now.getFullYear();
        const month = Number(("0" + (now.getMonth() + 1)).slice(-2));
        const day = Number(("0" + now.getDate()).slice(-2));
        setYear(year);
        setMonth(month);
        setDay(day);
        console.log(details)
    }, []);

    // 대상자 정보 수정 팝업
    const handleDollbomTargetInfoDialog = (targetInfo: ServiceTargetYear) => {
    showDialog(DollbomTargetInfoDialog, {
      targetInfo,
      syUid: Number(targetInfo.syUid),
    });
  };
    return (
        <article className="info">
          <div>
            <ul>
              <li className="btn_edit">
                {details?.targetInfo.statCd !== "서비스의뢰" &&
                <button
                  className="btn_gray"
                  onClick={() =>
                    handleDollbomTargetInfoDialog(
                      details?.targetInfo as ServiceTargetYear
                    )
                  }
                >
                  수정
                </button>
                }
              </li>
              {/* =================================================================== */}
              <li>
                <p className="gray">성명</p>
                <p>{details?.targetInfo.trgterNm}</p>
              </li>
              <li>
                <p className="gray">생년월일</p>
                <p>
                  {details?.targetInfo.brthdy}
                  {targetMonth >= month && targetDay >= day
                    ? `(만 ${year - targetBrthdy - 1}세)`
                    : `(만 ${year - targetBrthdy - 2}세)`}
                </p>
              </li>
              <li>
                <p className="gray">성별</p>
                <p>{details?.targetInfo.gender}</p>
              </li>
              <li>
                <p className="gray">연락처</p>
                <p>{details?.targetInfo.telno}</p>
              </li>
              <li className="x2">
                <p className="gray">주소</p>
                <p>{details?.targetInfo.fullAddress}</p>
              </li>
              <li>
                <p className="gray">가구원 수</p>
                <p>{details?.targetInfo.homeMember}명</p>
              </li>
              <li className="x2 mt8">
                <p className="gray">대상자 구분</p>
                <p>{details?.targetInfo.characterString}</p>
              </li>
              <li className="x2 mt8">
                <p className="gray">소득수준</p>
                <p>
                  {details?.targetInfo.incomeLevelString}
                  {details?.targetInfo.incomeLevel2 &&
                    `(${details?.targetInfo.incomeLevel2})`}
                </p>
              </li>
              <li className="x2 mt8">
                <p className="gray">비상연락</p>
                <p>
                  {details?.targetInfo.emgcNm} / {details?.targetInfo.emgcRel} /{" "}
                  {details?.targetInfo.emgcTel}
                </p>
              </li>
            </ul>
          </div>
        </article>
    );
};

export default TargetInfoBar;