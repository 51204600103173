import React from 'react';
import SprtMoreData from '../../../../../models/SprtMoreData';

const SprtMoreTable = (
    {sprtMoreData}
    :
    {sprtMoreData: SprtMoreData}
    ) => {
    return (
        <div className="padding tab_content" data-id={3}>
            <h4>추가지원 검토서</h4>
            <table>
                <tbody>
                <tr>
                    <th rowSpan={2} style={{ borderRight: '1px solid #e6e8ea' }}>연간 지원한도액<br />추가 지원 검토의견</th>
                    <th>필요성</th>
                    <td>{sprtMoreData.sprtMoreNssr}</td>
                </tr>
                <tr>
                    <th>추가 지원액<br />주요 사용처</th>
                    <td>{sprtMoreData.sprtMoreUse}</td>
                </tr>
                <tr>
                    <th rowSpan={2} style={{ borderRight: '1px solid #e6e8ea' }}>광주다움 통합돌봄<br />사례관리 사업비<br />사용 검토의견</th>
                    <th>필요성</th>
                    <td>{sprtMoreData.expnNss}</td>
                </tr>
                <tr>
                    <th>사례관리 사업비<br />주요 사용처</th>
                    <td>{sprtMoreData.expnUse}</td>
                </tr>
                </tbody>
            </table>
            <h4 className="mt20">권역별 사례회의 의견</h4>
            <table>
                <tbody>
                <tr>
                    <th style={{ width: 280 }}>주요의견</th>
                    <td>{sprtMoreData.mtOpnn}</td>
                </tr>
                </tbody>
            </table>
            <h4 className="mt20">승인여부</h4>
            <table>
                <tbody>
                <tr>
                    <th style={{ width: 140 }}>승인결과</th>
                    <td style={{ width: 140 }}>{sprtMoreData.cfmYn === 'Y' ? "지원승인" : "지원불가"}</td>
                    <th style={{ width: 140 }}>사유</th>
                    <td>{sprtMoreData.cfmRsn ?? ""}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default SprtMoreTable;