/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useRef } from "react";
import "./SettingsPage.scss";
import PlusIcon from "../../../assets/ic_plus.png";
import { useDialog } from "../../../dialogs/DialogProvider";
import OrgRegisterDialog from "../../../dialogs/setting/OrgRegisterDialog";
import SettingList, { OrgdeptCdValues } from "../../../models/SettingList";
import SettingAPI from "../../../apis/SettingAPI";
import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom";
import SearchBar, {
  SearchField,
  SearchFormInput,
} from "../../../components/common/SearchBar";
import DataTable, {
  createButtonCell,
  createRow,
  createTextCell,
} from "../../../components/common/DataTable";
import SearchIcon from "../../../assets/ic_search.png";
import { ServiceOrgList } from "../../../models/CareRequest";
import useMember from "../../../hooks/useMember";
import Member from "../../../models/Member";


// 환경설정 메뉴 페이지 > 기관정보

export type SearchOrgParam = {
  deptCd?: string
  departName?: string
  tel?: string
  locality?: string
  hing?: string
  keyword?: string
}

const SettingsTab1Page = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const [count, setCount] = useState(0);
  const [orgList, setOrgList] = useState([] as SettingList[]);
  const [searchParam, setSearchParam] = useState({} as SearchFormInput)
  const {member} = useMember()

 // 목록 불러오기
 useEffect(() => {
    SettingAPI.getAllOrg({
      page: Number(page),
      ...searchParam
    }).then((orgList) => {
      // console.log(orgList);  
      setOrgList([...orgList])
      setCount(orgList[0].totalCount)
    });
  },[page, searchParam]);

const handleSearch = (data: SearchFormInput) => {
  let parsed:SearchOrgParam = data
    // console.log(parsed)
    let params:SearchOrgParam = {}
    if(parsed.deptCd?.length !== 0) {
      params.deptCd = parsed.deptCd
    }
    if(parsed.hing?.length !== 0 && parsed.keyword?.length !== 0) {
      switch(parsed.hing) {
        case "departName" :
          params.departName = parsed.keyword
          break
        case "tel" :
          params.tel = parsed.keyword
          break
        case "locality" :
          params.locality = parsed.keyword
          break
      }
    }
    setSearchParam(params)
  };

  const handlePageChange = (page: number) => {
    navigate(`/setting-org-info/${page}`);
  };

  if(!member) {
    return <Navigate to="/login" />
  }

  return(
    <main>
      <section>
        <div className="tab_content" data-id={0}>
        <SearchBar fields={searchFields} onSubmit={handleSearch} />
        <TableTop1 count={count} member={member!}/>
        <Table1 
          list={orgList}
          page={Number(page)}
          totalPageCount={Math.ceil(count / 10)}
          onPageChange={handlePageChange}
          member={member!}
        />
        </div>
      </section>
    </main>
    
    
  );
}

/**
 * 검색 필드
 */
 const searchFields: SearchField[] = [
  {
    title: "기관구분",
    name: "deptCd",
    type: "select",
    items: OrgdeptCdValues.map((item) => ({ label: item.label, value: item.value })),
  },
 
  {
    title: "검색어",
    name: "hing",
    type: "search",
    items: [
      { label: "기관명", value: "departName" },
      { label: "연락처", value: "tel" },
      { label: "소재지", value: "locality" },
    ],
  },
];

const TableTop1 = ({count, member} : {count:number, member: Member}) => {
  const { showDialog } = useDialog();

  const handleOrgRegisterClick = () => {

     showDialog(OrgRegisterDialog);
  };

  return(
    <article className="list_top">
      <div className="total">
        <p>
          총 <span>{count}</span>건
        </p>
      </div>
      <div className="list_btn_wrap">
        {member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ?
        <button
          type="button"
          className="recive"
          onClick={handleOrgRegisterClick}
        >
          <img src={PlusIcon} alt="접수 아이콘" />
          기관등록
        </button>
        :
        null}
        
      </div>
    </article>
  );
}

/**
 * 테이블
 * @param list 기관 목록
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */


 const Table1 = ({
  list,
  page,
  totalPageCount,
  onPageChange,
  member,
}: {
  list: SettingList[];
  page: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
  member: Member;
}) => {

  const { showDialog } = useDialog();

  const handleOrgUpdateClick = (orgInfo: SettingList) => {
    showDialog(OrgRegisterDialog, {
      orgInfo: orgInfo,
      dptUid : orgInfo.dptUid,
    })
    
  };

  return (
    <DataTable
      columnTitles={[
        "기관구분",
        "기관명",
        "연락처",
        "소재지",
        "상태",
        "",
      ]}
      rows={list.map((orgInfo) =>
        createRow({
          cells: [
            createTextCell({ text: orgInfo.deptCd }),
            createTextCell({ text: orgInfo.departName }),
            createTextCell({ text: orgInfo.tel }),
            createTextCell({ text: orgInfo.locality }),
            createTextCell({ text: orgInfo.status }),
            createButtonCell({
              type: member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ?
              "outlined"
              :undefined,
              label: "수정",
              onClick: () => handleOrgUpdateClick(orgInfo),
            }),
          ],
        })
      )}
      page={page}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
    />
  );
};

export default SettingsTab1Page;
