import React, { useEffect, useState, useRef } from "react";
import "./SettingsPage.scss";
import PlusIcon from "../../../assets/ic_plus.png";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import DataTable, {
  createButtonCell,
  createRow,
  createTextCell,
} from "../../../components/common/DataTable";
import SettingList, { SearchDepartName } from "../../../models/SettingList";
import SettingAPI, { DepartSelect } from "../../../apis/SettingAPI";
import SearchBar, {
  SearchField,
  SearchFormInput,
  SearchItem,
} from "../../../components/common/SearchBar";
import useMember from "../../../hooks/useMember";
import FileUploadAPI from "../../../apis/FileUploadAPI";

// 환경설정 메뉴 페이지 > 회원 관리

export type SearchMemberParam = {
  dptUid?: string
  userName?: string
  tel?: string
  hing?: string
  keyword?: string
}


const SettingsTab4Page = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const [memberList, setMemberList] = useState([] as SettingList[]);
  const [count, setCount] = useState(0);
  const [searchParam, setSearchParam] = useState({} as SearchFormInput)
  const {member}= useMember()

  const [test, setTest] = useState([] as SearchItem[])
  const [departSelect, setDepartSelect] = useState([] as DepartSelect[])
  const [load, setLoad] = useState(true)
  const [load2, setload2] = useState(true)

  // 목록 불러오기
 useEffect(() => {
  if(member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU") { //시, 구
    SettingAPI.getAllMember({
        page: Number(page),
        ...searchParam
      }).then((memberList) => {
        // console.log(memberList);
        // memberList.map((memberInfo) => {
        //   FileUploadAPI.getFile({
        //     uid: memberInfo.mbrUid,
        //     folder: "USER",
        //   }).then((res) => {
        //     memberInfo.profileImage = new File([res], "profileFile", { type: res.type, lastModified: new Date().getTime()})
        //   })
        // })
        // console.log(memberList);
        setMemberList(memberList)
        setCount(memberList[0].totalCount)
        setload2(false)
      })
  } else {  //동, 기관
    SettingAPI.getAllMember({
      page: Number(page),
      dptUid: member?.dptUid,
      deptCd: member?.depart?.deptCd,
      ...searchParam
    }).then((memberList) => {
      // console.log(memberList);  
      // memberList.map((memberInfo) => {
      //   FileUploadAPI.getFile({
      //     uid: memberInfo.mbrUid,
      //     folder: "USER",
      //   }).then((res) => {
      //     memberInfo.profileImage = new File([res], "profileFile", { type: res.type, lastModified: new Date().getTime()})
      //   })
      // })
      setMemberList([...memberList])
      setCount(memberList[0].totalCount)
      setload2(false)
    })
  }
  
  SettingAPI.getDepartSelect()
      .then((res) => {
        // console.log(" departList: ", res)
        let temp:SearchItem[] = []
        res.map((item) => {
          temp.push({label : item.departName, value: (item.dptUid).toString()})
          setTest(temp)
        })
        // console.log(temp)
        setDepartSelect(res)
        setLoad(false)
    });
    
}, [page, searchParam]);

const handleSearch = (data: SearchFormInput) => {
  let parsed:SearchMemberParam = data
    // console.log(parsed)
    let params:SearchMemberParam = {}
    if(parsed.dptUid?.length !== 0) {
      params.dptUid = parsed.dptUid 
    }
    if(parsed.hing?.length !== 0 && parsed.keyword?.length !== 0) {
      switch(parsed.hing) {
        case "userName" :
          params.userName = parsed.keyword
          break
        case "tel" :
          params.tel = parsed.keyword
          break
      }
    }
    setSearchParam(params)
    // console.log(params)
    // console.log("params.dptUid : ", params.dptUid)
    // if(member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU") {
    //   SettingAPI.getAllMember({
    //     page: Number(page),
    //     dptUid: params.dptUid ? parseInt(params.dptUid as string) : -1,
    //     deptCd: member?.depart?.deptCd,
    //     userName : params.userName,
    //     tel : params.tel

    //   }).then((memberList) => {
    //     // console.log("memberList : ", memberList);  
    //     setMemberList([...memberList])
    //     setCount(memberList[0].totalCount)
    //   });
    // } else {
    //   SettingAPI.getAllMember({
    //     page: Number(page),
    //     dptUid: member?.dptUid,
    //     deptCd: member?.depart?.deptCd,
    //     userName : params.userName,
    //     tel : params.tel

    //   }).then((memberList) => {
    //     // console.log("memberList : ", memberList);  
    //     setMemberList([...memberList])
    //     setCount(memberList[0].totalCount)
    //   });
    // }
    
  };


const handlePageChange = (page: number) => {
  navigate(`/setting-member-mng/${page}`);
};

/**
 * 검색 필드
 */
 const searchFields: SearchField[] = member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" ?
 [
  {
    title: "소속",
    name: "dptUid",
    type: "select",
    items: test,
  },
 
  {
    title: "검색어",
    name: "hing",
    type: "search",
    items: [
      { label: "이름", value: "userName" },
      { label: "연락처", value: "tel" },
    ],
  },
] :
[
  {
    title: "검색어",
    name: "hing",
    type: "search",
    items: [
      { label: "이름", value: "userName" },
      { label: "연락처", value: "tel" },
    ],
  },
]

const TableTop4 = ({count} : {count:number}) => {
  return(
    <article className="list_top">
      <div className="total">
        <p>
          총 <span>{count}</span>건
        </p>
      </div>
    </article>
  );
}

return (
  <main>
    <section>
      {!load && !load2 ? <div className="tab_content" data-id={3}>
      <SearchBar fields={searchFields} onSubmit={handleSearch} />
        <TableTop4 count={count}/>
        <Table4
          list={memberList}
          page={Number(page)}
          totalPageCount={Math.ceil(count / 10)}
          onPageChange={handlePageChange}
        />
      </div>
      :
      null
      }
    </section>
  </main>
);

}
  
  

/**
 * 테이블
 * @param list 기관 목록
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
 const Table4 = ({
  list,
  page,
  totalPageCount,
  onPageChange,
}: {
  list: SettingList[];
  page: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
}) => {

   const navigate = useNavigate();
   const { member } = useMember();

  // 승인
  const handleApprove = async (mbrUid: number, signupStatus: string) => {
    if(signupStatus === "U") {
      return
    }
    if(window.confirm("해당 회원의 가입을 승인하시겠습니까?")) {
      let suc = await SettingAPI.approve({
        mbrUid: mbrUid,
      })

      if(suc.success) {
        window.alert(suc.message)
        navigate(0);
      } else {
        window.alert(suc.message)
      }
    } else {
      return
    }
  };

  // 삭제
  const handleMemberDelete = async (delUid:number, mbrUid:number) => {
    // alert("클릭");
    if(window.confirm("삭제하시겠습니까?")){
      SettingAPI.deleteMember({
        delUid: 1,
        mbrUid
      }).then((ref) => {
        alert("삭제 완료");
        navigate(0);
      });
    }
  };

  return (
    <DataTable
      columnTitles={[
        "소속",
        "분류",
        // "",
        "이름",
        "연락처",
        "가입일",
        "상태",
        ""
      ]}
      rows={list.map((memberInfo) =>
        createRow({
          cells: [
            createTextCell({ text: memberInfo.departName }),
            createTextCell({ text: memberInfo.rank }),
            // createTextCell({ text: "", isImg: true, imgFile: memberInfo.profileImage }),
            createTextCell({ text: memberInfo.userName }),
            createTextCell({ text: memberInfo.tel }),
            createTextCell({ text: memberInfo.crtDt }),
            createButtonCell({ 
              type:
              memberInfo.signupStatus === "U" 
                ? "underlined"
                : "outlined",
              label: memberInfo.signupStatus === "U" ? "사용중" : "승인",
              onClick: () => handleApprove(memberInfo.mbrUid, memberInfo.signupStatus),
            }),
            
            // createButtonCell({
            //   type: "outlined",
            //   label: "승인",
            //   onClick: () => handle승인(),
            // }),
            // createTextCell({ text: memberInfo.signupStatusString }),
  
            createButtonCell({
              type: member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" || member?.depart?.deptCd === "ORG" ? memberInfo.rank !== "시 담당자" ? "outlined" : undefined : member?.caseChargeYn === 'Y' ? memberInfo.rank !== "시 담당자" ? "outlined" : undefined : undefined,
              label: "삭제",
              onClick: () => handleMemberDelete(memberInfo.delUid, memberInfo.mbrUid),
            }),
          ],
        })
      )}
      page={page}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
    />
  );
};


export default SettingsTab4Page;
