import { instanceToPlain } from "class-transformer";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/ic_close.png";
import useMember from "../../hooks/useMember";
import ServiceTargetYear from "../../models/ServiceTargetYear";
import { DialogProps, useDialog } from "../DialogProvider";
import PostCodeDialog from "../PostCodeDialog";
import SettingAPI from "../../apis/SettingAPI";
import SettingList from "../../models/SettingList";
import { Hidden } from "@mui/material";
import { useEffect, useState } from "react";
import { telValid } from "../../App";

interface ServiceRegisterDialogProps extends DialogProps{
  siUid: number,
  serviceInfo?: SettingList
}

interface ServiceRegisterFormInput{
  siUid : number;
  dptUid : number;
  dbGubunCd : string;
  serviceGbn : string;
  serviceNm : string;
  useStatus : string;
  serviceDetail: string,
  unitGbn: string,
  unitCost: number,
  // cateCd : number;
}

const DepartRegisterDialog3 = ( {  
  siUid,
  serviceInfo,
  ...props
}: ServiceRegisterDialogProps) => {
  const navigate = useNavigate();
  const { member } = useMember();
  const { showDialog } = useDialog();
  const { register, handleSubmit, watch, setValue, formState:{errors}, setError, getValues } =
    useForm<ServiceRegisterFormInput>({
      defaultValues: {
        ...instanceToPlain(serviceInfo),
        // siUid: serviceInfo?.siUid,
        // dptUid: serviceInfo?.dptUid,
        // dbGubunCd: serviceInfo?.dbGubunCd,
        // serviceGbn: serviceInfo?.serviceGbn,
        // serviceNm: serviceInfo?.serviceNm,
        // useStatus: serviceInfo?.useStatus,
        // cateCd: serviceInfo?.cateCd,
      },
    });
  const data = watch();
  const [inputCost, setInputCost] = useState("")

  useEffect(( ()=> {
    // console.log("serviceInfo : ", serviceInfo)
    // console.log("siUid : ", siUid)
    if(siUid) {
      setValue("siUid", siUid)
      SettingAPI.getService({ siUid: Number(siUid)}).then((res) => {
        // console.log("res : ", res)
        setValue("dbGubunCd", res.dbGubunCd)
        setValue("serviceGbn", res.serviceGbn)
        setValue("serviceNm", res.serviceNm)
        setValue("serviceDetail", res.serviceDetail)
        setValue("unitGbn", res.unitGbn)
        if(res.unitCost) {
          setValue("unitCost", res.unitCost)
          setInputCost(res.unitCost.toLocaleString("ko-KR"))
        }
      });
    }
    
  }), [])

  const handleClose = () => {
    props.onClose?.();
  };

  const onSubmit: SubmitHandler<ServiceRegisterFormInput> = async (data) => {
    // console.log("serviceInfo : ", serviceInfo)
    // console.log(data);

  //   // TODO: 유효성 검사
  if(getValues("dbGubunCd").length === 0) {
    window.alert("서비스구분을 선택해주세요.")
    return
  }
  if(getValues("serviceGbn").length === 0) {
    window.alert("서비스 대분류를 선택해주세요.")
    return
  }
  if(getValues("unitGbn").length === 0) {
    window.alert("서비스 제공 단위를 선택해주세요.")
    return
  }
  if(!getValues("unitCost")) {
    window.alert("서비스 단가를 입력해주세요.")
    return
  }
  // window.alert("성공")

  const success = await SettingAPI.insertService({
     ...data,
    dptUid: 30,
  });

    if (success.success) {
      alert(success.message)
      navigate(0);
    } else {
      alert(success.errMessage)
      return
    }
  };

  const onError = () => {
    if(errors.dbGubunCd) {
      window.alert(errors.dbGubunCd.message)
      return
    } else if(errors.serviceGbn) {
      window.alert(errors.serviceGbn.message)
      return
    } else if(errors.serviceNm) {
      window.alert(errors.serviceNm.message)
      return
    } else if(errors.unitGbn) {
      window.alert(errors.unitGbn.message)
      return
    }
  }

  return (
    <div className="recive_popup popup_black" {...props}>
      <div
        className="recive_popup_inner popup_white"
        style={{ overflowY: "visible" }}
      >
        <div className="popup_tit">
          <h3>{serviceInfo ? "서비스수정" : "서비스등록"}</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <input type="hidden" {...register("siUid")}/>
            <input type="hidden" {...register("dptUid")}/>
            <div className="default_input mt20">
                <h4>서비스구분</h4>
                <div className="write_box">
                  <select
                    className="essential"
                    disabled={member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" ? false : true}
                    {...register("dbGubunCd", {
                      required: "서비스구분을 선택해주세요.",
                    })}
                  >
                    <option value="">선택</option>
                    <option value="기존돌봄">기존돌봄</option>
                    <option value="긴급돌봄">긴급돌봄</option>
                    <option value="광주돌봄">광주+돌봄</option>
                  </select>
                </div>
              </div>
              <div className="default_input mt20">
                <h4>대분류</h4>
                  <div className="write_box">
                    <select
                    className="essential"
                    disabled={member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" ? false : true}
                    {...register("serviceGbn", {
                      required: "서비스 대분류를 선택해주세요."
                    })}
                  >
                    <option value="">선택</option>
                    <option value="기존돌봄">기존돌봄</option>
                    <option value="가사지원">가사지원</option>
                    <option value="식사지원">식사지원</option>
                    <option value="동행지원">동행지원</option>
                    <option value="건강지원">건강지원</option>
                    <option value="안전지원">안전지원</option>
                    <option value="주거편의">주거편의</option>
                    <option value="일시보호">일시보호</option>
                    <option value="긴급돌봄">긴급돌봄</option>
                  </select>
                </div>
              </div>
              <div className="default_input mt20">
                <h4>소분류</h4>
                  <div className="write_box">
                    <div className="name " style={{position: 'relative'}}>
                      <input 
                          type="text" 
                          className="default_input essential" 
                          disabled={member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" ? false : true}
                          placeholder="소분류 입력"  
                          style={{width: '340px'}}
                          {...register("serviceNm", {
                            required: "서비스 소분류를 입력해주세요."
                          })}
                          />                    
                    </div>
                </div>
              </div>
              <div className="default_input mt20">
                <h4>서비스 상세</h4>
                  <div className="write_box">
                    <div className="name " style={{position: 'relative'}}>
                      <input 
                          type="text"
                          className="default_input"
                          disabled={member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" ? false : true}
                          placeholder="상세 입력"  
                          style={{width: '340px'}}
                          {...register("serviceDetail")}
                          />                    
                    </div>
                </div>
              </div>
              <div className="default_input mt20">
                <h4>제공 단위</h4>
                  <div className="write_box">
                    <select
                    className="essential"
                    disabled={member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" ? false : true}
                    {...register("unitGbn", {
                      required: "서비스 제공 단위를 선택해주세요."
                    })}
                  >
                    <option value="">선택</option>
                    <option value="1시간">1시간</option>
                    <option value="1회">1회</option>
                    <option value="1식">1식</option>
                    <option value="1일">1일</option>
                    <option value="연간">연간</option>
                  </select>
                </div>
              </div>
              <div className="default_input mt20">
                <h4>단가</h4>
                  <div className="write_box">
                    <div className="name " style={{position: 'relative'}}>
                      <input 
                          type="text"
                          className="default_input essential"
                          disabled={member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" ? false : true}
                          placeholder="단가 입력"  
                          style={{width: '340px'}}
                          value={inputCost}
                          onChange={(e:any) => {
                            if(!telValid.test((e.target.value).replaceAll(',', ''))) {
                              e.target.value = String(e.target.value).slice(0, -1)
                            }
                            let transform = Number((e.target.value).replaceAll(',', ''))
                            setInputCost(transform.toLocaleString('ko-KR'))
                            setValue("unitCost", transform)
                          }}
                          />                    
                    </div>
                </div>
              </div>
              <div className="default_input tel mt20">
                <h4>상태</h4>
                <div className="write_box">
                  <select
                    className=""
                    disabled={member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU" ? false : true}
                    {...register("useStatus")}
                  >
                    <option value="Y">사용</option>
                    <option value="N">미사용</option>
                  </select>
                </div>
              </div>  
              <div className="btn_wrap">
                <button type="button" className="cancel" onClick={handleClose}>
                  취소
                </button>
                {(member?.depart?.deptCd === "SI" || member?.depart?.deptCd === "GU") && 
                <button type="submit" className="submit">
                  등록
                </button>
                }                
              </div>
            </form>
          </div>
        </div>
      </div>
  );
};

export default DepartRegisterDialog3;
