import { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import MiniTop from "./components/common/MiniTop";

import TopNavBar, { TopNavTab } from "./components/common/TopNavBar";
import useMember from "./hooks/useMember";
import EvaluationPage from "./pages/member/evaluation/EvaluationPage";
import ServiceEvaluationPage from "./pages/member/evaluation/ResultEvalPage";
import ClaimPage from "./pages/member/expense/ClaimPage";
import ExpensePage from "./pages/member/expense/ExpensePage";
import PaymentPage from "./pages/member/expense/PaymentPage";
import HomePage from "./pages/member/home/HomePage";
import CareRequestPage from "./pages/member/service-plan/CareRequestPage";
import DollbomAreaConferencePage from "./pages/member/service-plan/DollbomAreaConferencePage";
import DollbomConfirmationPage from "./pages/member/service-plan/DollbomConfirmationPage";
import DollbomDongConferencePage from "./pages/member/service-plan/DollbomDongConferencePage";

import DollbomPlanPage from "./pages/member/service-plan/DollbomPlanPage";

import NeedsAssessmentPage from "./pages/member/service-plan/NeedsAssessmentPage";
import ServicePlanPage from "./pages/member/service-plan/ServicePlanPage";
import ServiceTargetPage from "./pages/member/service-target/ServiceTargetPage";
import ServicePage from "./pages/member/service/ServicePage";
import ServiceResultPage from "./pages/member/service/ServiceResultPage";
import SettingsPage from "./pages/member/settings/SettingsPage";
import SettingsTab1Page from "./pages/member/settings/SettingsTab1Page";
import SettingsTab2Page from "./pages/member/settings/SettingsTab2Page";
import SettingsTab3Page from "./pages/member/settings/SettingsTab3Page";
import SettingsTab4Page from "./pages/member/settings/SettingsTab4Page";
import SprtMoreAdmissionPage from "./pages/member/sprt-more/SprtMoreAdmissionPage";
import SprtMoreOpinionPage from "./pages/member/sprt-more/SprtMoreOpinionPage";
import SprtMorePage from "./pages/member/sprt-more/SprtMorePage";
import JoinPage from "./pages/nonmember/join/JoinPage";
import LoginPage from "./pages/nonmember/login/LoginPage";
import SelectLocation from "./pages/nonmember/login/SelectLocation";
import "./pages/member/settings/SettingsPage.scss";
import ResultEvalPage from "./pages/member/evaluation/ResultEvalPage";

function App() {
  const { member } = useMember();
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState<TopNavTab>();
  const [tab2, setTab2] = useState<TopNavTab>();

  // 주소 변경 시 탭 변경
  useEffect(() => {
    if (location.pathname.includes("/service-target")) {
      setTab("돌봄대상자");
    } else if (location.pathname.includes("/service-plan")) {
      setTab("돌봄계획");
    } else if (location.pathname.includes("/service")) {
      setTab("서비스");
    } else if (location.pathname.includes("/expense")) {
      setTab("비용");
    } else if (location.pathname.includes("/evaluation")) {
      setTab("평가/사후관리");
    } else if (location.pathname.includes("/sprt-more")) {
      setTab("추가관리");
    } else if (location.pathname.includes("/settings")) {
      setTab("환경설정");
    } else if (location.pathname.includes("/home")) {
      setTab(undefined)
    }

    if (location.pathname.includes("/setting-org-info")) {
      setTab2("기관정보");
    } else if (location.pathname.includes("/setting-org-mng")) {
      setTab2("제공기관 관리");
    } else if (location.pathname.includes("/setting-service-mng")) {
      setTab2("서비스 관리");
    } else if (location.pathname.includes("/setting-member-mng")) {
      setTab2("회원 관리");
    } else {
      setTab2(undefined);
    }
  }, [location]);

  const handleTabChange = (tab: TopNavTab) => {
    switch (tab) {
      case "돌봄대상자":
        navigate("/service-target", { replace: true });
        break;
      case "돌봄계획":
        navigate("/service-plan", { replace: true });
        break;
      case "서비스":
        navigate("/service", { replace: true });
        break;
      case "비용":
        navigate("/expense", { replace: true });
        break;
      case "평가/사후관리":
        navigate("/evaluation", { replace: true });
        break;
      case "추가관리":
        navigate("/sprt-more", { replace: true });
        break;
      case "환경설정":
        navigate("/settings", { replace: true });
        break;
      default:
        navigate("/home", { replace: true });
        break;
    }
  };

  const handleTabChange2 = (tab: TopNavTab) => {
    switch (tab) {
      case "기관정보":
        navigate("/setting-org-info", { replace: true })
        break;
      case "제공기관 관리":
        navigate("/setting-org-mng", { replace: true })
        break;
      case "서비스 관리":
        navigate("/setting-service-mng", { replace: true })
        break;
      case "회원 관리":
        navigate("/setting-member-mng", { replace: true })
        break;
      default:
        navigate("/home", { replace: true });
        break;
    }
  };

  const routes = useRoutes([
    {
      path: "*",
      element: <Navigate to="/home" replace />,
    },
    { path: "/login", element: <LoginPage /> },
    { path: "/join", element: <JoinPage /> },
    // { path: "/area", element: <SelectLocation /> },
    {
      path: "/home/*",
      children: [
        {
          path: "*",
          element: <Navigate to="/home/1" replace />,
        },
        { path: ":page", element: <HomePage /> },
      ],
    },
    {
      path: "/service-target/*",
      children: [
        {
          path: "*",
          element: <Navigate to="/service-target/1" replace />,
        },
        { path: ":page", element: <ServiceTargetPage /> },
      ],
    },
    {
      path: "/service-plan/*",
      children: [
        { path: "*", element: <Navigate to="/service-plan/1" replace /> },
        { path: ":page", element: <ServicePlanPage /> },
        { path: "needs-assessment", element: <NeedsAssessmentPage /> },
        { path: "careRequest", element: <CareRequestPage /> },
        { path: "dollbomPlan", element: <DollbomPlanPage /> },
        {
          path: "dongConference",
          element: <DollbomDongConferencePage />,
        },
        {
          path: "areaConference",
          element: <DollbomAreaConferencePage />,
        },
        // { path: "serviceRequest", element: <DollbomServiceRequest /> },
        { path: "confirmation", element: <DollbomConfirmationPage /> },
      ],
    },
    {
      path: "/service/*",
      children: [
        { path: "*", element: <Navigate to="/service/1" replace /> },
        { path: ":page", element: <ServicePage /> },
        { path: "serviceResult", element: <ServiceResultPage /> },
      ],
    },
    {
      path: "/expense/*",
      children: [
        { path: "*", element: <Navigate to="/expense/1" replace /> },
        { path: ":page", element: <ExpensePage /> },
        { path: "payment", element: <PaymentPage /> },
      ],
    },
    {
      path: "/evaluation/*",
      children: [
        { path: "*", element: <Navigate to="/evaluation/1" replace /> },
        { path: ":page", element: <EvaluationPage /> },
        { path: "resultEval", element: <ResultEvalPage /> },
      ],
    },
    {
      path: "/sprt-more/*",
      children: [
        { path: "*", element: <Navigate to="/sprt-more/1" replace /> },
        { path: ":page", element: <SprtMorePage /> },
        { path: "sprtMoreAdmissionPage", element: <SprtMoreAdmissionPage /> },
        { path: "sprtMoreOpinion", element: <SprtMoreOpinionPage /> },
      ],
    },
    {
      path: "/settings/*",
      children: [
        { path: "*", element: <Navigate to="/setting-org-info/1" replace /> },
        { path: ":page", element: <SettingsPage /> },
      ],
    },
    {
      path: "/setting-org-info/*",
      children: [
        { path: "*", element: <Navigate to="/setting-org-info/1" replace /> },
        { path: ":page", element: <SettingsTab1Page /> },
      ],
    },
    {
      path: "/setting-org-mng/*",
      children: [
        { path: "*", element: <Navigate to="/setting-org-mng/1" replace /> },
        { path: ":page", element: <SettingsTab2Page /> },
      ],
    },
    {
      path: "/setting-service-mng/*",
      children: [
        { path: "*", element: <Navigate to="/setting-service-mng/1" replace /> },
        { path: ":page", element: <SettingsTab3Page /> },
      ],
    },
    {
      path: "/setting-member-mng/*",
      children: [
        { path: "*", element: <Navigate to="/setting-member-mng/1" replace /> },
        { path: ":page", element: <SettingsTab4Page /> },
      ],
    },
    {
      path: "/expense/claim/*",
      children: [
        { path: "*", element: <Navigate to="/expense/claim/1" replace /> },
        { path: ":page", element: <ClaimPage /> }
      ]
    },
  ]);

  return (
    <div id="Wrapper">
      {member && <TopNavBar tab={tab} onTabChange={handleTabChange} />}
      {location.pathname.includes("setting") ?
        <MiniTop tab={tab2} setTab={handleTabChange2} />
        : null}
      {routes}
    </div>
  );
}

export const nameValid = /^[가-힣]+$/
export const telValid = /^[0-9]+$/
export const engValid = /^[0-9a-zA-Z]+$/
export const bankValid = /^[0-9\-]+$/

export type ValidCheck = {
  stat: boolean,
  msg: string,
}

export default App;
