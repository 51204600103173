/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";

import ArrowIcon from "../../assets/ic_arrow.png";
import Next2Icon from "../../assets/ic_next2.png";
import Next1Icon from "../../assets/ic_next.png";
import Prev2Icon from "../../assets/ic_prev2.png";
import Prev1Icon from "../../assets/ic_prev.png";
import CloseIcon from "../../assets/ic_close.png";
import { useDialog } from "../../dialogs/DialogProvider";
import ClaimFileUploadDialog from "../../dialogs/bill/ClaimFileUploadDialog";
import FileUploadAPI from "../../apis/FileUploadAPI";
import ExpenseModelAPI from "../../apis/ExpenseModelAPI";
import useMember from "../../hooks/useMember";
import Member from "../../models/Member";
import { useNavigate } from "react-router-dom";


/**
 * 작성자: 강우석
 * 작성일: 2023.02.23
 * 
 * 수정자: 홍준표
 * 수정일: 2023.02.27
 * 
 * 2차 수정일: 2023.02.28
 * 각 row를 식별하는 spyUid 전송
 *
 * @example
 * <ClaimDataTable
 *   expandable
 *   columnTitles={['제목', '내용']}
 *   rows={[
 *     createTableRow({
 *       cells: [
 *         createTextCell({ text: '제목' })
 *         createTextCell({ text: '내용' })
 *       ],
 *       expandContent: (<div>펼쳐졌을 때 보여줄 내용</div>)
 *     })
 *   ]}
 * />
 * @end
 *
 * 한 행을 나타내는 interface
 * @param expandContent 행이 펼쳐졌을 때 보여줄 내용
 * @param cells 행이 가진 셀
 * @param isCheckBox 체크박스여부
 * @param spyUid 고유식별자
 */

export interface ClaimDataTableRow {
  expandContent?: React.ReactNode;
  cells: ClaimDataTableCell[];
  isCheckBox: boolean;
  spyUid: number;
  cateCdText: string;
}
export const createRow = (row: ClaimDataTableRow) => row;

/**
 * 셀의 타입은 Text 또는 Button 둘 중 하나여야 함
 */
export type ClaimDataTableCell = ClaimDataTableTextCell | ClaimDataTableButtonCell;

/**
 * 셀이 텍스트인 경우에 사용하는 interface
 * @param text 셀 내용
 * @param ellipsis 생략 사용 여부
 * @param d_day 디데이
 */
export interface ClaimDataTableTextCell {
  text: string;
  ellipsis?: boolean;
  d_day?: number;
  redtext?: boolean;
}
export const createTextCell = (cell: ClaimDataTableTextCell) => cell;
const isTextCell = (cell: ClaimDataTableCell): cell is ClaimDataTableTextCell =>
  "text" in cell;

/**
 * 셀이 버튼인 경우에 사용하는 interface
 * @param type underlined: 검정색 밑줄 버튼, outlined: 파란색 외곽선 버튼, undefined: -
 * @param text 버튼 제목
 * @param onClick 버튼이 클릳되었을 때 호출되는 함수
 */
export interface ClaimDataTableButtonCell {
  type: "underlined" | "outlined" | undefined;
  label: string;
}
export const createButtonCell = (cell: ClaimDataTableButtonCell) => cell;
const isButtonCell = (cell: ClaimDataTableCell): cell is ClaimDataTableButtonCell =>
  "label" in cell;

export interface NeedUpd {
  spyUid: number,
  state: boolean,
}

interface UploadedFile {
  file: File, value: string, url: string, spyUid:number
}

/**
 * 모든 목록에서 사용할 수 있는 데이터 테이블 컴포넌트
 * @param expandable 펼쳐지는 행이 있는지 여부
 * @param columnTitles 열의 제목
 * @param rows 데이터 행
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 * @param isCheckBox 체크박스 여부
 */

const ClaimDataTable = ({
  expandable = false,
  columnTitles,
  rows,
  page,
  onPageChange,
  isCheckBox = false,
  needUpd,
}: {
  expandable?: boolean;
  columnTitles: string[];
  rows: ClaimDataTableRow[];
  page: number;
  onPageChange: (page: number) => any;
  isCheckBox? : boolean;
  needUpd: NeedUpd[];
}) => {
const [uploadedFile, setUploadedFile] = useState([] as UploadedFile[])
const {member} = useMember()
// const [fileState, setFileState] = useState({} as {key: number, state: boolean})

const [checkItem, setCheckItem] = useState([] as number[])
const [needUpload, setNeedUpload] = useState([] as NeedUpd[])
const { showDialog } = useDialog();
const navigate = useNavigate();

useEffect(() => {
  setNeedUpload(needUpd)
  // console.log("첨부해야할 리스트 : ", needUpd)
}, [])

const handleSingleCheck = (checked:any, id:number) => {
  if(checked) {
    setCheckItem(cur => [...cur, id])
  } else {
    setCheckItem(checkItem.filter((item) => item !== id))
  }
}

const handleAllCheck = (checked:any) => {
  if(checked) {
    let ids:number[] = []
    rows.forEach((row) => ids.push(row.spyUid))
    setCheckItem(ids)
  } else {
    setCheckItem([])
  }
}
// useEffect(() => {
//   console.log("체크된 아이템 spyUid : ", checkItem)
// }, [checkItem])

const validFileArr = (spyUid: number) => {
  needUpload.map((item) => {
    // console.log("검사부분 : ", item , " , 입력된 spyUid : ", spyUid)
    if(item.spyUid === spyUid) {
      let tempArr = needUpload.filter((item) => item.spyUid !== spyUid)
      tempArr.push({spyUid: spyUid, state: true})
      setNeedUpload(tempArr)
      // console.log("파일 첨부한 아이템 : ", tempArr)
      return false
    }
  })
}

const uploading = (file: File, url: string, spyUid: number) => {
  // console.log("업로드 파일 : ", file.name)
  // console.log("추가할 녀석 : ", {value: file.name, key: Date.now()})
  let fileValid = uploadedFile.find((item) => item.spyUid === spyUid)
  if(fileValid) { //해당 spyUid 파일 존재
    let newFiles = uploadedFile.filter((item) => item.spyUid !== spyUid)
    newFiles.push({file: file, value: file.name, url: url, spyUid: spyUid})
    setUploadedFile(newFiles)
  } else {  //새로 추가
    setUploadedFile((cur) => [...cur, {file: file, value: file.name, url: url, spyUid: spyUid}])
  }
  validFileArr(spyUid)
  // console.log("리스트 : ", uploadedFile)
}

const handleDel = (spyUid: number) => {
  // console.log("key : ", spyUid, "파일 삭제")
  setUploadedFile((cur) => cur.filter((item) => item.spyUid !== spyUid))
  needUpload.map((item) => {
    // console.log("검사부분 : ", item , " , 입력된 spyUid : ", spyUid)
    if(item.spyUid === spyUid) {
      let tempArr = needUpload.filter((item) => item.spyUid !== spyUid)
      tempArr.push({spyUid: spyUid, state: false})
      setNeedUpload(tempArr)
      // console.log("파일 첨부한 아이템 : ", tempArr)
      return false
    }
  })
}
const handleUploadDialog = (spyUid: number) => {
  showDialog(ClaimFileUploadDialog, {uploading : uploading, spyUid: spyUid})
};


const handleBtnClick = async (e:any) => {
  e.preventDefault()
  let billItems:{spyUid: number}[] = []
  checkItem.map((spyUid) => {
    billItems.push({spyUid: spyUid})
  })
  let saveFail = false
  // console.log("needUpload : ", needUpload)
  if(checkItem.length > 0) {
    if(needUpload.length !== 0) {
      checkItem.map((spyUid) => {  //citem === spyUid
        needUpload.map((uitem) => {
          if(uitem.spyUid === spyUid && uitem.state === false) {
            saveFail = true
          }
        })
      })
    }
    
    if(saveFail) {
      window.alert("증빙자료 첨부 건은 반드시 증빙자료가 첨부되어야 합니다.")
      return false
    }
    if(window.confirm("선택한 목록에 대한 청구를 저장하시겠습니까?")) {
      window.alert("청구처리 완료")
      //테스트 데이터 전용 스크립트
      // let temp = Math.max(...checkItem)
      // const itemList = ExpenseManagement.mockList
      // itemList.forEach((item, idx) => {
      //   if(idx > temp) {
      //     return false
      //   }
      //   checkItem.forEach((idx) => {
      //     if(idx === item.spyUid && item.status !== "청구") {
      //       ExpenseManagement.claimComplete(item.spyUid)
      //       console.log("청구 처리된 아이템 : ", item)
      //     }
      //   })
      // })
      let resData = await ExpenseModelAPI.billClaim({
        crtUid: (member as Member).mbrUid as number,
        demander: (member as Member).userName as string,
        billItems: billItems,
      })

      // console.log("resData : ", resData)

      // let updFiles = [] as File[]
      // uploadedFile.map((file) => {
      //   updFiles.push(file.file)
      // })
      // let postForm:FilesUploadParams = {
      //   imageFiles: updFiles,
      //   folder: "BILL",
      //   fileGbn: "IMAGE",
      //   uid: 1111,
      // }
      // console.log("보내야 하는 형태 : ", postForm)

      //체크박스 선택된 업로드필요 아이템
      // let filesPost:FileUploadParams[] = []
      if(resData.success) {
        resData.list.map((returnData) => {
          uploadedFile.map((updFile) => {
            if(returnData.spyUid === updFile.spyUid) {
              FileUploadAPI.fileUpload({
                attachFile: updFile.file,
                folder: "BILL",
                fileGbn: "IMAGE",
                uid: returnData.sbUid,
              }).then((res) => {
                console.log(`spyUid : ${updFile.spyUid}, 업로드 API 실행결과 : `, res)
              })
              
            }
          })
        })
        navigate("/expense", { replace: true });
      } else {
        window.alert("청구 처리 실패")
      }
      

    }
  } else {
    window.alert("선택된 아이템 없음")
  }
  
}

  /**
 * 데이터 테이블의 한 행을 나타내는 컴포넌트
 */
const Row = ({ row }: { row: ClaimDataTableRow }) => {
  const [expanded, setExpanded] = useState(false);
  const isCheckBox = row.isCheckBox
  const handleExpand = () => {
    setExpanded(!expanded);
  };
  // const { showDialog } = useDialog();
  // const [uploadedFile, setUploadedFile] = useState([] as UploadedFile[])

  // const validFileArr = (spyUid: number) => {
  //   needUpload.map((item) => {
  //     // console.log("검사부분 : ", item , " , 입력된 spyUid : ", spyUid)
  //     if(item.spyUid === spyUid) {
  //       let tempArr = needUpload.filter((item) => item.spyUid !== spyUid)
  //       tempArr.push({spyUid: spyUid, state: true})
  //       setNeedUpload(tempArr)
  //       // console.log("파일 첨부한 아이템 : ", tempArr)
  //       return false
  //     }
  //   })
  // }

  // const uploading = (file: File, url: string, spyUid: number) => {
  //   // console.log("업로드 파일 : ", file.name)
  //   // console.log("추가할 녀석 : ", {value: file.name, key: Date.now()})
  //   setUploadedFile((cur) => [...cur, {file: file, value: file.name, url: url, spyUid: spyUid}])
  //   validFileArr(spyUid)
  //   console.log("리스트 : ", uploadedFile)
  // }

  // const handleDel = (spyUid: number) => {
  //   // console.log("key : ", spyUid, "파일 삭제")
  //   setUploadedFile((cur) => cur.filter((item) => item.spyUid !== spyUid))
  //   needUpload.map((item) => {
  //     // console.log("검사부분 : ", item , " , 입력된 spyUid : ", spyUid)
  //     if(item.spyUid === spyUid) {
  //       let tempArr = needUpload.filter((item) => item.spyUid !== spyUid)
  //       tempArr.push({spyUid: spyUid, state: false})
  //       setNeedUpload(tempArr)
  //       // console.log("파일 첨부한 아이템 : ", tempArr)
  //       return false
  //     }
  //   })
  // }

  // const handleUploadDialog = (spyUid: number) => {
  //   showDialog(ClaimFileUploadDialog, {uploading : uploading, spyUid: spyUid})
  // };

  return (
    <>
      <tr
        className={`table_title ${expanded ? "on" : "off"}`}
        onClick={handleExpand}
      >
        {isCheckBox ? <td><input type='checkbox' key={row.spyUid} name={`select-${row.spyUid}`} onChange={(e) => handleSingleCheck(e.target.checked, row.spyUid)} checked={checkItem.includes(row.spyUid) ? true : false} /></td> : null}
        {row.expandContent && (
          <td className="first">
            <img src={ArrowIcon} alt="접고 펼치기 아이콘" />
          </td>
        )}
        {row.cells.map((cell, index) => (
          <Cell key={index} cell={cell} handleUploadDialog={handleUploadDialog} handleDel={handleDel} spyUid={row.spyUid} />
        ))}
      </tr>
      {row.expandContent && (
        <tr
          className={`table_content ${expanded ? "on" : ""}`}
          style={{ display: expanded ? "table-row" : "none" }}
        >
          <td colSpan={100}>{row.expandContent}</td>
        </tr>
      )}
    </>
  );
};


/**
 * 데이터 테이블의 한 셀을 나타내는 컴포넌트
 */
const Cell = ({ cell, handleUploadDialog, handleDel, spyUid }: { cell: ClaimDataTableCell, handleUploadDialog: Function, handleDel: Function, spyUid: number }) => {
  
  if (isTextCell(cell)) {
    if (cell.ellipsis) {
      return (
        <td className="claim_address">
          <div className="ellipsis">
            <p>
              {cell.text}
              {<D_Day d_day={cell.d_day} />}
            </p>
          </div>
        </td>
      );
    } else if(cell.redtext) {
      return (
        <td>
          <span className="red">{cell.text}</span>
          {<D_Day d_day={cell.d_day} />}
        </td>
      );
    } else {
      return (
        <td>
          {cell.text}
          {<D_Day d_day={cell.d_day} />}
        </td>
      );
    }
  } else {  //버튼
    return (
      <td>
        {cell.type ? (
          <>
          <button
            type="button"
            className={`btn_gray ${cell.type === "underlined" ? "edit" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
              handleUploadDialog(spyUid)
            }}
          >
            {cell.label}
          </button>
          {uploadedFile.map((file) => file.spyUid === spyUid ?<p>{file.value.length > 15 ? file.value.slice(0,12) + "..." + file.value.slice(-4) : file.value}<a className="delete" onClick={() => handleDel(file.spyUid)}><img src={CloseIcon} alt="삭제" /></a></p>
          :
          null)}
          </>
        )
         : (
          "-"
        )}
      </td>
    );
  }
};

/**
 * 디데이를 나타내는 컴포넌트
 */
const D_Day = ({ d_day }: { d_day?: number }) => {
  let d_dayElement = <></>;
  if (d_day) {
    if (d_day === 0) {
      d_dayElement = (
        <>
          <span className="red">D-day</span>
        </>
      );
    } else if (d_day <= 2) {
      d_dayElement = (
        <>
          <span className="yellow">D-{d_day}</span>
        </>
      );
    }
  }
  return d_dayElement;
};

/**
 * 테이블 하단 페이징
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 * @returns
 */
  return (
    <>
      {isCheckBox ? <button className='save_btn' onClick={handleBtnClick}>저장</button> : null}
      <table className="main_table">
        <thead>
          <tr>
          {isCheckBox ? <th><input type='checkbox' name="cbx_chkAll" onChange={(e) => handleAllCheck(e.target.checked)} checked={checkItem.length === rows.length ? true : false} /></th> : null}
            {expandable && <th className="first"></th>}
            {columnTitles.map((title, index) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
              <Row key={index} row={row} />
          ))}
        </tbody>
      </table>
    </>
  );
};


export default ClaimDataTable;
