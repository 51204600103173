import { async } from "@firebase/util";
import React, { ChangeEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm, UseFormRegister, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import DollbomPlanAPI from "../../../apis/DollbomPlanAPI";
import EvaluationAPI, { EvaluationTarget } from "../../../apis/EvaluationAPI";
import BackIcon from "../../../assets/ic_backlist.png";
import EvaluationList from "../../../models/EvaluationList";
import ResultEvalFormInput from "../../../models/form/ResultEvalFormInput";
import ServicePlanDetails from "../../../models/ServicePlanDetails";
import ServiceTargetYear from "../../../models/ServiceTargetYear";
import { ValidCheck, nameValid, telValid } from "../../../App";
import "./ResultEvalPage.scss";
import useMember from "../../../hooks/useMember";

const ResultEvalPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const spyUid = Number(searchParams.get('spyUid'))
    const [targetInfo, setTargetInfo] = useState<ServiceTargetYear>();
    // const [details, setDetails] = useState<ServicePlanDetails>();
    const syUid = Number(searchParams.get('syUid'))
    const {member} = useMember()
    const [evaluationInfo, setEvaluationInfo] = useState({} as EvaluationTarget)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        EvaluationAPI.getResultEval({
            spyUid: spyUid,
        }).then((res) => {
            // console.log("res : ", res)
            setEvaluationInfo(res)
            setLoading(false)
        });
    }, []);
    const [inputCheck, setInputCheck] = useState<ValidCheck>({stat: true, msg: ""})
    const paramCheck = () => {
        if(!nameValid.test(getValues("rspndNm"))) {
          setInputCheck({stat: false, msg: "올바른 응답자 이름을 입력하세요."})
        } else if(!telValid.test(getValues("rspndTel2"))) {
          setInputCheck({stat: false, msg: "올바른 응답자 연락처를 입력하세요."})
        } else if(!telValid.test(getValues("rspndTel3"))) {
          setInputCheck({stat: false, msg: "올바른 응답자 연락처를 입력하세요."})
        } else {
          setInputCheck({stat: true, msg: ""})
        }
      }    
    // console.log("evaluationInfo : ", evaluationInfo)

    const{
        register, handleSubmit, getValues, setValue, formState: {errors}, setError, watch
    } = useForm<ResultEvalFormInput>();

    const onSubmit: SubmitHandler<ResultEvalFormInput> = async(data) => {
        // console.log("data : ", data);
        paramCheck()
        if(!inputCheck.stat) {
        window.alert(inputCheck.msg)
        return
        }

        const success = await EvaluationAPI.insertResultEval({
            ...data,
            spyUid: spyUid,
            rspndTel: `${data.rspndTel1}-${data.rspndTel2}-${data.rspndTel3}`,
            resultJson: JSON.stringify(data.json),
            investigator: member!.userName,
        });

        if (success) {
            alert("서비스 평가 완료 처리되었습니다.");
            navigate("/evaluation", { replace: true });
          } else {
            alert("서비스 평가 실패!!");
          }
    }

    const onError = () => {
        paramCheck()
        if(errors.rspndNm) {
            alert(errors.rspndNm.message)
            return
        } else if(errors.rspndGbn) {
            alert(errors.rspndGbn.message)
            return
        } else if(errors.rspndTel1) {
            alert(errors.rspndTel1.message)
            return
        } else if(errors.rspndTel2) {
            alert(errors.rspndTel2.message)
            return
        } else if(errors.rspndTel3) {
            alert(errors.rspndTel3.message)
            return
        } else if(errors.json?.s2_1) {
            alert(errors.json.s2_1.message)
            return
        } else if(errors.json?.s2_2) {
            alert(errors.json.s2_2.message)
            return
        } else if(errors.json?.s2_3) {
            alert(errors.json.s2_3.message)
            return
        } else if(errors.json?.s2_4) {
            alert(errors.json.s2_4.message)
            return
        } else if(errors.json?.s2_5) {
            alert(errors.json.s2_5.message)
            return
        } else if(errors.json?.s3_1) {
            alert(errors.json.s3_1.message)
            return
        } else if(errors.json?.s3_2) {
            alert(errors.json.s3_2.message)
            return
        } else if(errors.json?.s3_3) {
            alert(errors.json.s3_3.message)
            return
        } else if(errors.json?.s3_4) {
            alert(errors.json.s3_4.message)
            return
        } else if(errors.json?.s4_1) {
            alert(errors.json.s4_1.message)
            return
        } else if(errors.json?.s4_2) {
            alert(errors.json.s4_2.message)
            return
        } else if(errors.json?.s4_3) {
            alert(errors.json.s4_3.message)
            return
        } else if(errors.json?.s4_4) {
            alert(errors.json.s4_4.message)
            return
        } else if(errors.json?.s4_5) {
            alert(errors.json.s4_5.message)
            return
        } else if(errors.json?.s5_1) {
            alert(errors.json.s5_1.message)
            return
        } else if(errors.json?.s5_2) {
            alert(errors.json.s5_2.message)
            return
        } else if(errors.json?.s5_3) {
            alert(errors.json.s5_3.message)
            return
        } else if(errors.json?.s5_4) {
            alert(errors.json.s5_4.message)
            return
        } else if(errors.json?.s5_5) {
            alert(errors.json.s5_5.message)
            return
        } else if(errors.json?.s6_1) {
            alert(errors.json.s6_1.message)
            return
        } else if(errors.json?.s6_2) {
            alert(errors.json.s6_2.message)
            return
        } else if(errors.json?.s6_3) {
            alert(errors.json.s6_3.message)
            return
        } else if(errors.json?.s6_4) {
            alert(errors.json.s6_4.message)
            return
        } else if(errors.json?.s6_5) {
            alert(errors.json.s6_5.message)
            return
        } else if(errors.json?.s6_6) {
            alert(errors.json.s6_6.message)
            return
        }
        
        if(getValues("rspndGbn").length === 0) {
            alert("응답자 관계를 선택해주세요.")
            return
        }
        if(getValues("rspndTel1").length === 0) {
            alert("응답자 연락처를 입력해주세요.")
            return
        }
    }

    const data = watch()
    useEffect(() => {
        // console.log(data)
    }, [data])
    
    return (
        <main className="ResultEvalPage">
            <section>
                {loading ? null :
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <DetailTop/>
                        <Info evaluationInfo={evaluationInfo} syUid={syUid}/>
                        <ResultEvaluation register = {register} getValues={getValues} check={setInputCheck} evaluationInfo={evaluationInfo} setValue={setValue} />
                    </form>
                }
                
            </section>
        </main>
    );
};

const DetailTop = () => {
    const navigate = useNavigate();

    const handleBackPressed = () => {
        navigate(-1);
    }

    return(
        <article className="detail_top">
            <div className="nav_list">
                <p>평가·사후관리 / <span>서비스평가</span></p>
                <button type="button" className="mt8" onClick={handleBackPressed}>
                    <span>
                        <img src={BackIcon} alt="목록으로 돌아가기 아이콘" />
                    </span>
                    목록으로 돌아가기
                </button>
            </div>
            <div className="btn_wrap">
                <button type="submit" className="submit">저장</button>
            </div>
        </article>
    );
}

const Info = ({evaluationInfo, syUid} : { evaluationInfo:EvaluationTarget
    syUid:Number}) => {

    const [details, setDetails] = useState<ServicePlanDetails>();
    const [age, setAge] = useState(0)

    useEffect(() => {
        if(evaluationInfo.targetInfo !== undefined) {
            const today = new Date()
            const birthDate = new Date(evaluationInfo.targetInfo.brthdy)
            setAge(today.getFullYear() - birthDate.getFullYear())
            const month = today.getMonth() - birthDate.getMonth();
            if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            setAge((cur) => cur - 1)
            }
        }
      }, [])
    
      useEffect(() => {
        DollbomPlanAPI.getDetails({
          syUid: Number(syUid),
        }).then((res) => {
            setDetails(res);
            // console.log("details : ", res)
        });
      }, []);

    return(
        <article className="info">
            <ul>
                <li>
                    <p className="gray">성명</p>
                    {/* <p>{evaluationInfo}</p> */}
                    <p>{evaluationInfo.targetInfo?.trgterNm}</p>
                </li>
                <li>
                    <p className="gray">생년월일</p>
                    <p>{evaluationInfo.targetInfo?.brthdy}(만 {age}세)</p>
                </li>
                <li>
                    <p className="gray">성별</p>
                    <p>{evaluationInfo.targetInfo?.gender}</p>
                </li>
                <li>
                    <p className="gray">연락처</p>
                    <p>{evaluationInfo.targetInfo?.telno}</p>
                </li>
                <li className="x2">
                    <p className="gray">주소</p>
                    <p>{evaluationInfo.targetInfo?.adres}, {evaluationInfo.targetInfo?.adresDtl}</p>
                </li>
                <li>
                    <p className="gray">가구원 수</p>
                    <p>{evaluationInfo.targetInfo?.homeMember}명</p>
                </li>
                <li className="x2 mt8">
                    <p className="gray">대상자 구분</p>
                    <p>{details?.targetInfo.characterString}</p>
                    {/* <p>노인(장기요양 1등급), 장애인(심하지 않은 장애)</p> */}
                </li>

                {/* 추후 api 나오면 수정예정 */}
                <li className="mt8">
                    <p className="gray">지원여부</p>
                    {evaluationInfo.targetInfo.incomeLevel === "85이하" ? 
                    <p>서비스 비용 지원</p>
                    :
                    <p>비용 미지원</p>
                    }
                </li>
                <li className="mt8">
                    <p className="gray">제공기관</p>
                    <p>{evaluationInfo.departName}</p>
                </li>
                <li className="mt8">
                    <p className="gray">이용서비스</p>
                    <p>{evaluationInfo.useService.serviceName}{evaluationInfo.useService.days ? " / " + evaluationInfo.useService.days + "일" : ""}
                    {evaluationInfo.useService.totalCount ? " / " + evaluationInfo.useService.totalCount + "회" : ""}
                    {evaluationInfo.useService.times ? " / " + evaluationInfo.useService.times + "시간" : ""}
                    </p>
                </li>
            </ul>
        </article>
    );
}

const ResultEvaluation = ({
    register,
    getValues,
    check,
    evaluationInfo,
    setValue,
}:{
    register: UseFormRegister<ResultEvalFormInput>;
    getValues: UseFormGetValues<ResultEvalFormInput>;
    check: Function;
    evaluationInfo:EvaluationTarget;
    setValue: UseFormSetValue<ResultEvalFormInput>;
}) => {
    const [self, setSelf] = useState(false)
    useEffect(() => {
        if(self) {
            setValue("rspndNm", evaluationInfo.targetInfo.trgterNm)
            setValue("rspndTel1", evaluationInfo.targetInfo.telno.split('-')[0])
            setValue("rspndTel2", evaluationInfo.targetInfo.telno.split('-')[1])
            setValue("rspndTel3", evaluationInfo.targetInfo.telno.split('-')[2])
        }
    }, [self])
    return(
        <article className="service_evaluation mt20">
            <div className="left">
                <div className="text_input">
                    <h4>응답자</h4>
                    <div className="input_wrap">
                        <div className="input_box mr8" style={{width: '20%'}}>
                            <input type="text" className="w100" placeholder="성명"
                            disabled={self ? true : false}
                                    {...register("rspndNm", {
                                        required: "응답자 성명을 입력해주세요.",
                                        onChange: () => {
                                            if(!nameValid.test(getValues("rspndNm"))) {
                                                check({stat: false, msg: "올바른 응답자 이름을 입력하세요."})
                                              } else if(!telValid.test(getValues("rspndTel2"))) {
                                                check({stat: false, msg: "올바른 응답자 연락처를 입력하세요."})
                                              } else if(!telValid.test(getValues("rspndTel3"))) {
                                                check({stat: false, msg: "올바른 응답자 연락처를 입력하세요."})
                                              } else {
                                                check({stat: true, msg: ""})
                                              }
                                        },
                                        onBlur: () => {
                                            if(!nameValid.test(getValues("rspndNm"))) {
                                                check({stat: false, msg: "올바른 응답자 이름을 입력하세요."})
                                              } else if(!telValid.test(getValues("rspndTel2"))) {
                                                check({stat: false, msg: "올바른 응답자 연락처를 입력하세요."})
                                              } else if(!telValid.test(getValues("rspndTel3"))) {
                                                check({stat: false, msg: "올바른 응답자 연락처를 입력하세요."})
                                              } else {
                                                check({stat: true, msg: ""})
                                              }
                                        }
                                    })} />
                        </div>
                        <div className="input_box mr8" style={{width: '20%'}}>
                            <select className="w100" style={{color: 'rgb(0, 0, 0)'}}
                                    {...register("rspndGbn", {
                                        required: "응답자 관계를 선택해주세요.",
                                        onChange: (e:any) => {
                                            if(e.target.value === "본인") {
                                                setSelf(true)
                                            } else {
                                                setSelf(false)
                                            }
                                        }
                                    })}>
                                <option value="">관계 선택</option>
                                <option value="본인">본인</option>
                                <option value="가족">가족</option>
                                <option value="이웃(지인)">이웃(지인)</option>
                                <option value="시설 등 종사자">시설 등 종사자</option>
                            </select>
                        </div>
                        <div className="input_box" style={{width: '40%'}}>
                            <select style={{width: '30%'}}
                            disabled={self ? true : false}
                            {...register("rspndTel1", {
                                required: "응답자 연락처를 입력해주세요."
                            })}>
                                <option value="">선택</option>
                                <option value="010">010</option>
                                <option value="011">011</option>
                                <option value="017">017</option>
                                <option value="018">018</option>
                                <option value="019">019</option>
                            </select>
                            <span style={{width: '5%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>-</span>
                            <input type="tel" style={{width: '30%'}} maxLength={4}
                            disabled={self ? true : false}
                            {...register("rspndTel2", {
                                required: "응답자 연락처를 입력해주세요.",
                                onChange: (e:any) => {
                                    if(!telValid.test(e.target.value)) {
                                      e.target.value = String(e.target.value).slice(0, -1)
                                    }
                                  }
                            })}/>
                            <span style={{width: '5%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>-</span>
                            <input type="tel" style={{width: '30%'}} maxLength={4}
                            disabled={self ? true : false}
                            {...register("rspndTel3", {
                                required: "응답자 연락처를 입력해주세요.",
                                onChange: (e:any) => {
                                    if(!telValid.test(e.target.value)) {
                                      e.target.value = String(e.target.value).slice(0, -1)
                                    }
                                  }
                            })}/>
                        </div>
                    </div>
                </div>
                <div className="choice_content mt20">
                    <h4>서비스 신청 경로</h4>
                    <ul>
                        <li style={{height: "auto", padding: "10px 15px"}}>
                            <p>「광주다움 통합돌봄」 서비스를 어떻게 알게 되셨습니까?</p>
                            <div className="check_area2">
                                <span>
                                    <input type="radio" id="check11-1" value="지인 추천" {...register("json.s1_1")} />
                                    <label htmlFor="check11-1">
                                        지인 추천
                                    </label>
                                </span>
                                <span>
                                    <input type="radio" id="check11-2" value="동행정복지센터 공무원 안내" {...register("json.s1_1")} />
                                    <label htmlFor="check11-2">
                                        동행정복지센터 공무원 안내
                                    </label>
                                </span>
                                <span>
                                    <input type="radio" id="check11-3" value="홍보물(현수막 등)" {...register("json.s1_1")} />
                                    <label htmlFor="check11-3">
                                        홍보물(현수막 등)
                                    </label>
                                </span>
                                <span>
                                    <input type="radio" id="check11-4" value="언론 매체(신문, 인터넷 등)" {...register("json.s1_1")} />
                                    <label htmlFor="check11-4">
                                        언론 매체(신문, 인터넷 등)
                                    </label>
                                </span>
                                <span>
                                    <input type="radio" id="check11-5" value="기타" {...register("json.s1_1")} />
                                    <label htmlFor="check11-5">
                                        기타
                                    </label>
                                    <input type="text" placeholder="기타 입력"
                                    disabled = {getValues("json.s1_1") === "기타" ? false : true}
                                    style={{width: "162px", marginLeft: "8px"}}
                                    {...register("json.s1_2")} />
                                </span>
                            </div>
                        </li>
                        
                    </ul>
                </div>
                <div className="choice_content mt20">
                    <h4>동 행정복지센터</h4>
                    <ul>
                        <li>
                            <p>사례관리 담당자는 돌봄계획 수립을 위해 귀하의 전반적인 상황을 정확히 확인하였습니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check21-1" value="전혀 그렇지 않다"
                                    {...register("json.s2_1", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check21-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check21-2" value="그렇지 않다"
                                    {...register("json.s2_1", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check21-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check21-3" value="보통이다"
                                    {...register("json.s2_1", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check21-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check21-4" value="그렇다"
                                    {...register("json.s2_1", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check21-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check21-5" value="매우 그렇다"
                                    {...register("json.s2_1", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check21-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>사례관리 담당자는 귀하의 욕구를 충분히 반영하여 돌봄계획을 수립하였습니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check22-1" value="전혀 그렇지 않다"
                                    {...register("json.s2_2", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check22-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check22-2" value="그렇지 않다"
                                    {...register("json.s2_2", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check22-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check22-3" value="보통이다"
                                    {...register("json.s2_2", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check22-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check22-4" value="그렇다"
                                    {...register("json.s2_2", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check22-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check22-5" value="매우 그렇다"
                                    {...register("json.s2_2", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check22-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>사례관리 담당자는 귀하에게 돌봄서비스 내용 및 궁금한 점을 이해하기 쉽게 설명해주었습니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check23-1" value="전혀 그렇지 않다"
                                    {...register("json.s2_3", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check23-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check23-2" value="그렇지 않다"
                                    {...register("json.s2_3", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check23-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check23-3" value="보통이다"
                                    {...register("json.s2_3", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check23-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check23-4" value="그렇다"
                                    {...register("json.s2_3", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check23-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check23-5" value="매우 그렇다"
                                    {...register("json.s2_3", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check23-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>사례관리 담당자는 귀하에게 친절하였습니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check24-1" value="전혀 그렇지 않다"
                                    {...register("json.s2_4", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check24-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check24-2" value="그렇지 않다"
                                    {...register("json.s2_4", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check24-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check24-3" value="보통이다"
                                    {...register("json.s2_4", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check24-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check24-4" value="그렇다"
                                    {...register("json.s2_4", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check24-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check24-5" value="매우 그렇다"
                                    {...register("json.s2_4", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check24-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>서비스 신청 및 접수 과정이 원활하게 진행되었습니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check25-1" value="전혀 그렇지 않다"
                                    {...register("json.s2_5", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check25-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check25-2" value="그렇지 않다"
                                    {...register("json.s2_5", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check25-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check25-3" value="보통이다"
                                    {...register("json.s2_5", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check25-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check25-4" value="그렇다"
                                    {...register("json.s2_5", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check25-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check25-5" value="매우 그렇다"
                                    {...register("json.s2_5", {
                                        required: "동 행정복지센터 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check25-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="choice_content mt20">
                    <h4>제공기관</h4>
                    <ul>
                        <li>
                            <p>서비스 제공자는 서비스 제공과정에서 귀하의 현재 상태와 요구 사항을 잘 고려하였습니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check31-1" value="전혀 그렇지 않다"
                                    {...register("json.s3_1", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check31-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check31-2" value="그렇지 않다"
                                    {...register("json.s3_1", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check31-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check31-3" value="보통이다"
                                    {...register("json.s3_1", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check31-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check31-4" value="그렇다"
                                    {...register("json.s3_1", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check31-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check31-5" value="매우 그렇다"
                                    {...register("json.s3_1", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check31-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>서비스 제공자는 귀하(또는 가족)와 원활하게 의사소통을 하였습니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check32-1" value="전혀 그렇지 않다"
                                    {...register("json.s3_2", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check32-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check32-2" value="그렇지 않다"
                                    {...register("json.s3_2", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check32-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check32-3" value="보통이다"
                                    {...register("json.s3_2", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check32-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check32-4" value="그렇다"
                                    {...register("json.s3_2", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check32-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check32-5" value="매우 그렇다"
                                    {...register("json.s3_2", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check32-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>서비스 제공자는 친절하였습니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check33-1" value="전혀 그렇지 않다"
                                    {...register("json.s3_3", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check33-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check33-2" value="그렇지 않다"
                                    {...register("json.s3_3", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check33-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check33-3" value="보통이다"
                                    {...register("json.s3_3", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check33-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check33-4" value="그렇다"
                                    {...register("json.s3_3", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check33-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check33-5" value="매우 그렇다"
                                    {...register("json.s3_3", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check33-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>서비스 제공자는 서비스 제공에 필요한 지식과 능력 등 전문성을 갖추었다고 생각하십니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check34-1" value="전혀 그렇지 않다"
                                    {...register("json.s3_4", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check34-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check34-2" value="그렇지 않다"
                                    {...register("json.s3_4", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check34-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check34-3" value="보통이다"
                                    {...register("json.s3_4", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check34-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check34-4" value="그렇다"
                                    {...register("json.s3_4", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check34-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check34-5" value="매우 그렇다"
                                    {...register("json.s3_4", {
                                        required: "제공기관 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check34-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="choice_content mt20">
                    <h4>서비스 이용과정</h4>
                    <ul>
                        <li>
                            <p>서비스 신청부터 제공까지 이용과정은 전반적으로 편리하였습니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check41-1" value="전혀 그렇지 않다"
                                    {...register("json.s4_1", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check41-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check41-2" value="그렇지 않다"
                                    {...register("json.s4_1", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check41-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check41-3" value="보통이다"
                                    {...register("json.s4_1", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check41-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check41-4" value="그렇다"
                                    {...register("json.s4_1", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check41-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check41-5" value="매우 그렇다"
                                    {...register("json.s4_1", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check41-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>신청 한 번으로 필요한 서비스를 받은 것에 대해 만족하십니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check42-1" value="전혀 그렇지 않다"
                                    {...register("json.s4_2", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check42-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check42-2" value="그렇지 않다"
                                    {...register("json.s4_2", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check42-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check42-3" value="보통이다"
                                    {...register("json.s4_2", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check42-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check42-4" value="그렇다"
                                    {...register("json.s4_2", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check42-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check42-5" value="매우 그렇다"
                                    {...register("json.s4_2", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check42-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>원하는 만큼 충분히 서비스를 받았다고 생각하십니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check43-1" value="전혀 그렇지 않다"
                                    {...register("json.s4_3", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check43-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check43-2" value="그렇지 않다"
                                    {...register("json.s4_3", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check43-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check43-3" value="보통이다"
                                    {...register("json.s4_3", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check43-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check43-4" value="그렇다"
                                    {...register("json.s4_3", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check43-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check43-5" value="매우 그렇다"
                                    {...register("json.s4_3", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check43-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>필요할 때 서비스를 제공 받았습니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check44-1" value="전혀 그렇지 않다"
                                    {...register("json.s4_4", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check44-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check44-2" value="그렇지 않다"
                                    {...register("json.s4_4", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check44-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check44-3" value="보통이다"
                                    {...register("json.s4_4", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check44-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check44-4" value="그렇다"
                                    {...register("json.s4_4", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check44-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check44-5" value="매우 그렇다"
                                    {...register("json.s4_4", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check44-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>서비스 이용금액은 적절하다고 생각하십니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check45-1" value="전혀 그렇지 않다"
                                    {...register("json.s4_5", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check45-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check45-2" value="그렇지 않다"
                                    {...register("json.s4_5", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check45-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check45-3" value="보통이다"
                                    {...register("json.s4_5", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check45-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check45-4" value="그렇다"
                                    {...register("json.s4_5", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check45-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check45-5" value="매우 그렇다"
                                    {...register("json.s4_5", {
                                        required: "서비스 이용과정 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check45-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="right">
                <div className="choice_content">
                    <h4>서비스 만족도</h4>
                    <ul>
                        <li>
                            <p>「광주다움 통합돌봄」 서비스를 다시 이용할 의향이 있으십니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check51-1" value="전혀 그렇지 않다"
                                    {...register("json.s5_1", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check51-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check51-2" value="그렇지 않다"
                                    {...register("json.s5_1", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check51-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check51-3" value="보통이다"
                                    {...register("json.s5_1", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check51-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check51-4" value="그렇다"
                                    {...register("json.s5_1", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check51-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check51-5" value="매우 그렇다"
                                    {...register("json.s5_1", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check51-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>「광주다움 통합돌봄」 서비스를 다른 사람에게 추천할 의향이 있으십니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check52-1" value="전혀 그렇지 않다"
                                    {...register("json.s5_2", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check52-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check52-2" value="그렇지 않다"
                                    {...register("json.s5_2", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check52-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check52-3" value="보통이다"
                                    {...register("json.s5_2", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check52-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check52-4" value="그렇다"
                                    {...register("json.s5_2", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check52-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check52-5" value="매우 그렇다"
                                    {...register("json.s5_2", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check52-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>「광주다움 통합돌봄」 서비스는 귀하의 어려움을 해결하는데 도움이 되었습니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check53-1" value="전혀 그렇지 않다"
                                    {...register("json.s5_3", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check53-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check53-2" value="그렇지 않다"
                                    {...register("json.s5_3", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check53-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check53-3" value="보통이다"
                                    {...register("json.s5_3", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check53-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check53-4" value="그렇다"
                                    {...register("json.s5_3", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check53-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check53-5" value="매우 그렇다"
                                    {...register("json.s5_3", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check53-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>앞으로 「광주다움 통합돌봄」 서비스를 통해 병원이나 시설이 아닌 원하는 곳(집 등)에서 생활하는데 도움을 받을 수 있다고 생각하십니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check54-1" value="전혀 그렇지 않다"
                                    {...register("json.s5_4", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check54-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check54-2" value="그렇지 않다"
                                    {...register("json.s5_4", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check54-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check54-3" value="보통이다"
                                    {...register("json.s5_4", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check54-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check54-4" value="그렇다"
                                    {...register("json.s5_4", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check54-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check54-5" value="매우 그렇다"
                                    {...register("json.s5_4", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check54-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>앞서 답변하신 여러 가지 사항을 생각할 때 「광주다움 통합돌봄」 서비스에 대해 전반적으로 만족하십니까?</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check55-1" value="전혀 그렇지 않다"
                                    {...register("json.s5_5", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check55-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check55-2" value="그렇지 않다"
                                    {...register("json.s5_5", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check55-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check55-3" value="보통이다"
                                    {...register("json.s5_5", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check55-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check55-4" value="그렇다"
                                    {...register("json.s5_5", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check55-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check55-5" value="매우 그렇다"
                                    {...register("json.s5_5", {
                                        required: "서비스 만족도 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check55-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                {getValues("json.s5_5") === "전혀 그렇지 않다" || getValues("json.s5_5") === "그렇지 않다" ||
                getValues("json.s5_4") === "전혀 그렇지 않다" || getValues("json.s5_4") === "그렇지 않다" ||
                getValues("json.s5_3") === "전혀 그렇지 않다" || getValues("json.s5_3") === "그렇지 않다" ||
                getValues("json.s5_2") === "전혀 그렇지 않다" || getValues("json.s5_2") === "그렇지 않다" ||
                getValues("json.s5_1") === "전혀 그렇지 않다" || getValues("json.s5_1") === "그렇지 않다"
                ?
                <div className="choice_content mt20">
                    <h4>전반적인 서비스 만족도 ‘전혀 그렇지 않다’, ‘그렇지 않다’ 응답자</h4>
                    <ul>
                        <li style={{height: "auto", padding: "10px 15px"}}>
                            <p>서비스에 대해 만족하지 않는 이유는 무엇입니까? (중복체크 가능)</p>
                            <div className="check_area2">
                                <span>
                                    <input type="checkbox" id="chk1" {...register("json.chkList.chk1")} />
                                    <label htmlFor="chk1">
                                        동 사례관리 담당자의 태도 및 전문성
                                    </label>
                                </span>
                                <span>
                                    <input type="checkbox" id="chk2" {...register("json.chkList.chk2")} />
                                    <label htmlFor="chk2">
                                        서비스 제공인력의 태도 및 전문성
                                    </label>
                                </span>
                                <span>
                                    <input type="checkbox" id="chk3" {...register("json.chkList.chk3")} />
                                    <label htmlFor="chk3">
                                        서비스 이용 절차의 불편함
                                    </label>
                                </span>
                                <span>
                                    <input type="checkbox" id="chk4" {...register("json.chkList.chk4")} />
                                    <label htmlFor="chk4">
                                        서비스 이용 비용
                                    </label>
                                </span>
                                <span>
                                    <input type="checkbox" id="chk5" {...register("json.chkList.chk5")} />
                                    <label htmlFor="chk5">
                                        서비스에 대한 정보 접근성
                                    </label>
                                </span>
                                <span>
                                    <input type="checkbox" id="chk6" {...register("json.chkList.chk6")} />
                                    <label htmlFor="chk6">
                                        서비스 제공 수준(시간, 횟수 등) 
                                    </label>
                                </span>
                                <span>
                                    <input type="checkbox" id="chk7" {...register("json.chkList.chk7")} />
                                    <label htmlFor="chk7">
                                        기타
                                    </label>
                                    <input type="text" placeholder="기타 입력"
                                    disabled = {getValues("json.chkList.chk7") ? false : true}
                                    style={{width: "200px", marginLeft: "8px"}}
                                    {...register("json.chkList.chkInput")} />
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                :
                null
                }
                
                <div className="choice_content mt20">
                    <h4>사후 검사 - 귀하의 생각과 가장 유사한 곳에 응답해주세요.</h4>
                    <ul>
                        <li>
                            <p>식사, 가사, 외출 등 일상생활 수행하는데 어려움을 경험한 적이 있다.</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check61-1" value="전혀 그렇지 않다"
                                    {...register("json.s6_1", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check61-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check61-2" value="그렇지 않다"
                                    {...register("json.s6_1", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check61-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check61-3" value="보통이다"
                                    {...register("json.s6_1", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check61-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check61-4" value="그렇다"
                                    {...register("json.s6_1", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check61-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check61-5" value="매우 그렇다"
                                    {...register("json.s6_1", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check61-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>나 자신 또는 가족을 돌보는 것이 부담스러운 적이 있다.</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check62-1" value="전혀 그렇지 않다"
                                    {...register("json.s6_2", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check62-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check62-2" value="그렇지 않다"
                                    {...register("json.s6_2", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check62-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check62-3" value="보통이다"
                                    {...register("json.s6_2", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check62-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check62-4" value="그렇다"
                                    {...register("json.s6_2", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check62-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check62-5" value="매우 그렇다"
                                    {...register("json.s6_2", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check62-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>몸이 아프거나 불편한 적이 있다.</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check63-1" value="전혀 그렇지 않다"
                                    {...register("json.s6_3", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check63-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check63-2" value="그렇지 않다"
                                    {...register("json.s6_3", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check63-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check63-3" value="보통이다"
                                    {...register("json.s6_3", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check63-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check63-4" value="그렇다"
                                    {...register("json.s6_3", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check63-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check63-5" value="매우 그렇다"
                                    {...register("json.s6_3", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check63-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>우울하다는 생각을 한 적이 있다.</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check64-1" value="전혀 그렇지 않다"
                                    {...register("json.s6_4", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check64-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check64-2" value="그렇지 않다"
                                    {...register("json.s6_4", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check64-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check64-3" value="보통이다"
                                    {...register("json.s6_4", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check64-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check64-4" value="그렇다"
                                    {...register("json.s6_4", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check64-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check64-5" value="매우 그렇다"
                                    {...register("json.s6_4", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check64-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>힘들고 어려울 때 지역사회(주변사람, 광주광역시, 기관 등)로부터 도움을 받을 수 있다고 생각한다.</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check65-1" value="전혀 그렇지 않다"
                                    {...register("json.s6_5", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check65-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check65-2" value="그렇지 않다"
                                    {...register("json.s6_5", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check65-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check65-3" value="보통이다"
                                    {...register("json.s6_5", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check65-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check65-4" value="그렇다"
                                    {...register("json.s6_5", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check65-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check65-5" value="매우 그렇다"
                                    {...register("json.s6_5", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check65-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                        <li>
                            <p>지금까지 나의 삶에 대해 만족한다.</p>
                            <div className="check_area">
                                <span>
                                    <input type="radio" id="check66-1" value="전혀 그렇지 않다"
                                    {...register("json.s6_6", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check66-1">전혀 그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check66-2" value="그렇지 않다"
                                    {...register("json.s6_6", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check66-2">그렇지 않다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check66-3" value="보통이다"
                                    {...register("json.s6_6", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check66-3">보통이다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check66-4" value="그렇다"
                                    {...register("json.s6_6", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check66-4">그렇다</label>
                                </span>
                                <span>
                                    <input type="radio" id="check66-5" value="매우 그렇다"
                                    {...register("json.s6_6", {
                                        required: "사후 검사 항목을 모두 선택해주세요."
                                    })} />
                                    <label htmlFor="check66-5">매우 그렇다</label>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="choice_content mt20">
                    <h4>기타 의견</h4>
                    <ul>
                        <li style={{height: "auto", padding: "10px 15px"}}>
                            <p>더 나은 서비스를 위해 불편사항 및 개선해야 할 사항 등에 대해 자유롭게 말씀해주세요.</p>
                            <textarea placeholder="응답 입력" className="mt8" {...register("json.etc")}></textarea>
                        </li>
                    </ul>
                </div>
            </div>
        </article>

    );
}


export default ResultEvalPage;