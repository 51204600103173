import moment from "moment";
import NeedsAssessmentJoin from "../../../../../models/NeedsAssessmentJoin";

const NeedAsTabTable2 = ({
    evaluationData,
  }: {
    evaluationData?: NeedsAssessmentJoin;
  }) => {
    const beginDt = moment(evaluationData?.counselResult.beginDt).format(
      "YYYY.MM.DD(dd)"
    );
    return (
      <div className="tab_content" data-id={1}>
        <p className="date">평가일 : {beginDt}</p>
        <div className="choice_result">
          <h4>정신건강 수준</h4>
          <ul>
            <li>
              <div>
                <p>우울감과 불행감을 느낀 적이 많다.</p>
                <span>{evaluationData?.jsonData.q4_1}</span>
              </div>
              <div>
                <p>어려움을 극복할 수 없다고 느낀 적이 많다.</p>
                <span>{evaluationData?.jsonData.q4_2}</span>
              </div>
            </li>
            <li>
              <div>
                <p>걱정으로 잠을 잘 못 이루거나 설치는 적이 많다.</p>
                <span>{evaluationData?.jsonData.q4_3}</span>
              </div>
              <div>
                <p>미래에 대한 희망이 없다고 느낀 적이 많다.</p>
                <span>{evaluationData?.jsonData.q4_4}</span>
              </div>
            </li>
            <li>
              <div>
                <p>자신감이 없어지고 내가 쓸모없게 느껴진다.</p>
                <span>{evaluationData?.jsonData.q4_5}</span>
              </div>
            </li>
          </ul>
        </div>
        <div className="choice_result mt20">
          <h4>고립, 은둔 수준</h4>
          <ul>
            <li>
              <div>
                <p>하루 종일 혼자 있다.</p>
                <span>{evaluationData?.jsonData.q5_1}</span>
              </div>
              <div>
                <p>대부분 자신의 방이나 집에만 머무른다.</p>
                <span>{evaluationData?.jsonData.q5_2}</span>
              </div>
            </li>
            <li>
              <div>
                <p>가끔 외출을 하더라도 대인관계를 하지 않는다.</p>
                <span>{evaluationData?.jsonData.q5_3}</span>
              </div>
              <div>
                <p>일상적 어려움을 항시 의논할 사람이 없다.</p>
                <span>{evaluationData?.jsonData.q5_4}</span>
              </div>
            </li>
            <li>
              <div>
                <p>일상적 어려움을 실제 도와줄 사람이 없다.</p>
                <span>{evaluationData?.jsonData.q5_5}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  export default NeedAsTabTable2