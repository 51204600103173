import { listenerCount } from "process";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import CloseIcon from "../../assets/ic_close.png";
import Member from "../../models/Member";
import { DialogProps } from "../DialogProvider";
import "./ResultEvalDialog.scss";
import EvaluationAPI from "../../apis/EvaluationAPI";
import ResultEvalData from "../../models/form/ResultEvalData";
import moment from "moment";

/**
 * 작성자: 홍준표
 * 작성일: 2023.04.28
 * 서비스평가 팝업
 *
 * @param spyUid
 * @param onSuccess 성공했을 때 실행할 함수
 */

interface ResultEvalDialog extends DialogProps {
  spyUid: number;
  onSuccess?: () => void;
}

const ResultEvalDialog = ({
  spyUid,
  onSuccess,
  ...props
}: ResultEvalDialog) => {
  const [tabs, setTabs] = useState(0);
  const handleClose = () => {
    props.onClose?.();
  };
  const [evalData, setEvalData] = useState({} as ResultEvalData)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    // console.log("spyUid: ", spyUid)
    EvaluationAPI.getResultEvalData({
      spyUid: spyUid,
    }).then((res) => {
      // console.log("평가데이터 : ", res)
      setEvalData(res)
      setLoading(false)
    })
  }, [])

  if(loading) {
    return (
      <>Loading...</>
    )
  } else {
    return (
      <div className="result-eval-dialog popup_black" {...props}>
        <div
          className="assign_popup_inner popup_white"
          style={{ overflowY: "visible", width: "800px" }}
        >
          <div className="popup_tit">
            <h3>서비스 평가</h3>
            <a href="#" className="close" onClick={handleClose}>
              <img src={CloseIcon} alt="닫기" />
            </a>
          </div>
          <div className="popup_con">
            <div className="care_evaluation">
              {/* 탭 */}
              <div className="tabs2">
                <button
                  type="button"
                  className={`tab2 ${tabs === 0 ? "tab_active" : "non_active"}`}
                  onClick={() => setTabs(0)}
                  data-id={0}
                >
                  동 행정복지센터
                </button>
                <button
                  type="button"
                  className={`tab2 ${tabs === 1 ? "tab_active" : "non_active"}`}
                  onClick={() => setTabs(1)}
                  data-id={1}
                >
                  제공기관
                </button>
                <button
                  type="button"
                  className={`tab2 ${tabs === 2 ? "tab_active" : "non_active"}`}
                  onClick={() => setTabs(2)}
                  data-id={2}
                >
                  서비스 이용과정
                </button>
                <button
                  type="button"
                  className={`tab2 ${tabs === 3 ? "tab_active" : "non_active"}`}
                  onClick={() => setTabs(3)}
                  data-id={3}
                >
                  서비스 만족도
                </button>
                <button
                  type="button"
                  className={`tab2 ${tabs === 4 ? "tab_active" : "non_active"}`}
                  onClick={() => setTabs(4)}
                  data-id={4}
                >
                  사후 검사
                </button>
              </div>
              {/* 동 행정복지센터 */}
              {tabs === 0 && (
                <div className="tab_content2" data-id={0}>
                  <p className="date">평가일 : {moment(evalData.resultEval.evalDt).format('yyyy.MM.DD(dd)')}</p>
                  <div className="choice_result">
                    <h4>동 행정복지센터</h4>
                    <ul>
                      <li>
                        <div>
                          <p>사례관리 담당자는 돌봄계획 수립을 위해 귀하의 전반적인 상황을 정확히 확인하였습니까?</p>
                          <span>{evalData.jsonData.s2_1}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>사례관리 담당자는 귀하의 욕구를 충분히 반영하여 돌봄계획을 수립하였습니까?</p>
                          <span>{evalData.jsonData.s2_2}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>사례관리 담당자는 귀하에게 돌봄서비스 내용 및 궁금한 점을 이해하기 쉽게 설명해주었습니까?</p>
                          <span>{evalData.jsonData.s2_3}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>사례관리 담당자는 귀하에게 친절하였습니까?</p>
                          <span>{evalData.jsonData.s2_4}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>서비스 신청 및 접수 과정이 원활하게 진행되었습니까?</p>
                          <span>{evalData.jsonData.s2_5}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {/* 제공기관 */}
              {tabs === 1 && (
                <div className="tab_content2 " data-id={1}>
                  <p className="date">평가일 : {moment(evalData.resultEval.evalDt).format('yyyy.MM.DD(dd)')}</p>
                  <div className="choice_result">
                    <h4>제공기관</h4>
                    <ul>
                      <li>
                        <div>
                          <p>서비스 제공자는 서비스 제공과정에서 귀하의 현재 상태와 요구 사항을 잘 고려하였습니까?</p>
                          <span>{evalData.jsonData.s3_1}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>서비스 제공자는 귀하(또는 가족)와 원활하게 의사소통을 하였습니까?</p>
                          <span>{evalData.jsonData.s3_2}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>서비스 제공자는 친절하였습니까?</p>
                          <span>{evalData.jsonData.s3_3}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>서비스 제공자는 서비스 제공에 필요한 지식과 능력 등 전문성을 갖추었다고 생각하십니까?</p>
                          <span>{evalData.jsonData.s3_4}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {/* 서비스 이용과정 */}
              {tabs === 2 && (
                <div className="tab_content2 " data-id={2}>
                  <p className="date">평가일 : {moment(evalData.resultEval.evalDt).format('yyyy.MM.DD(dd)')}</p>
                  <div className="choice_result">
                    <h4>서비스 이용과정</h4>
                    <ul>
                      <li>
                        <div>
                          <p>서비스 신청부터 제공까지 이용과정은 전반적으로 편리하였습니까?</p>
                          <span>{evalData.jsonData.s4_1}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>신청 한 번으로 필요한 서비스를 받은 것에 대해 만족하십니까?</p>
                          <span>{evalData.jsonData.s4_2}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>원하는 만큼 충분히 서비스를 받았다고 생각하십니까?</p>
                          <span>{evalData.jsonData.s4_3}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>필요할 때 서비스를 제공 받았습니까?</p>
                          <span>{evalData.jsonData.s4_4}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>서비스 이용금액은 적절하다고 생각하십니까?</p>
                          <span>{evalData.jsonData.s4_5}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {/* 서비스 만족도 */}
              {tabs === 3 && (
                <div className="tab_content2 " data-id={3}>
                  <p className="date">평가일 : {moment(evalData.resultEval.evalDt).format('yyyy.MM.DD(dd)')}</p>
                  <div className="choice_result">
                    <h4>서비스 만족도</h4>
                    <ul>
                      <li>
                        <div>
                          <p>「광주다움 통합돌봄」 서비스를 다시 이용할 의향이 있으십니까?</p>
                          <span>{evalData.jsonData.s5_1}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>「광주다움 통합돌봄」 서비스를 다른 사람에게 추천할 의향이 있으십니까?</p>
                          <span>{evalData.jsonData.s5_2}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>「광주다움 통합돌봄」 서비스는 귀하의 어려움을 해결하는데 도움이 되었습니까?</p>
                          <span>{evalData.jsonData.s5_3}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>앞으로 「광주다움 통합돌봄」 서비스를 통해 병원이나 시설이 아닌 원하는 곳(집 등)에서 생활하는데 도움을 받을 수 있다고 생각하십니까?</p>
                          <span>{evalData.jsonData.s5_4}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>앞서 답변하신 여러 가지 사항을 생각할 때 「광주다움 통합돌봄」 서비스에 대해 전반적으로 만족하십니까?</p>
                          <span>{evalData.jsonData.s5_5}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {evalData.jsonData.s5_5 === "전혀 그렇지 않다" || evalData.jsonData.s5_5 === "그렇지 않다" || evalData.jsonData.s5_5 === "전혀그렇지않다" || evalData.jsonData.s5_5 === "그렇지않다" ||
                  evalData.jsonData.s5_4 === "전혀 그렇지 않다" || evalData.jsonData.s5_4 === "그렇지 않다" || evalData.jsonData.s5_4 === "전혀그렇지않다" || evalData.jsonData.s5_4 === "그렇지않다" ||
                  evalData.jsonData.s5_3 === "전혀 그렇지 않다" || evalData.jsonData.s5_3 === "그렇지 않다" || evalData.jsonData.s5_3 === "전혀그렇지않다" || evalData.jsonData.s5_3 === "그렇지않다" ||
                  evalData.jsonData.s5_2 === "전혀 그렇지 않다" || evalData.jsonData.s5_2 === "그렇지 않다" || evalData.jsonData.s5_2 === "전혀그렇지않다" || evalData.jsonData.s5_2 === "그렇지않다" ||
                  evalData.jsonData.s5_1 === "전혀 그렇지 않다" || evalData.jsonData.s5_1 === "그렇지 않다" || evalData.jsonData.s5_1 === "전혀그렇지않다" || evalData.jsonData.s5_1 === "그렇지않다"
                   ?
                  <div className="choice_result mt20">
                    <h4>서비스에 대해 만족하지 않는 이유는 무엇입니까?</h4>
                    {evalData.jsonData.chkList ? 
                    <ul>
                      {evalData.jsonData.chkList.chk1 ?
                      <li>
                        <div>
                          <p>
                          동 사례관리 담당자의 태도 및 전문성
                          </p>
                        </div>
                      </li>
                      :
                      null
                      }
                      {evalData.jsonData.chkList.chk2 ?
                      <li>
                        <div>
                          <p>
                          서비스 제공인력의 태도 및 전문성
                          </p>
                        </div>
                      </li>
                      :
                      null
                      }
                      {evalData.jsonData.chkList.chk3 ?
                      <li>
                        <div>
                          <p>
                          서비스 이용 절차의 불편함
                          </p>
                        </div>
                      </li>
                      :
                      null
                      }
                      {evalData.jsonData.chkList.chk4 ?
                      <li>
                        <div>
                          <p>
                          서비스 이용 비용
                          </p>
                        </div>
                      </li>
                      :
                      null
                      }
                      {evalData.jsonData.chkList.chk5 ?
                      <li>
                        <div>
                          <p>
                          서비스에 대한 정보 접근성
                          </p>
                        </div>
                      </li>
                      :
                      null
                      }
                      {evalData.jsonData.chkList.chk6 ?
                      <li>
                        <div>
                          <p>
                          서비스 제공 수준(시간, 횟수 등)
                          </p>
                        </div>
                      </li>
                      :
                      null
                      }
                      {evalData.jsonData.chkList.chk7 ?
                      <li>
                        <div>
                          <p>
                          기타 : {evalData.jsonData.chkList.chkInput ? evalData.jsonData.chkList.chkInput : ""}
                          </p>
                        </div>
                      </li>
                      :
                      null
                      }
                    </ul>
                      :
                      null
                    }
                    
                  </div>
                  :
                  null
                  }
                  
                </div>
              )}
              {/* 사후 검사 */}
              {tabs == 4 && (
                <div className="tab_content2 " data-id={4}>
                  <p className="date">평가일 : {moment(evalData.resultEval.evalDt).format('yyyy.MM.DD(dd)')}</p>
                  <div className="choice_result">
                    <h4>사후 검사</h4>
                    <ul>
                      <li>
                        <div>
                          <p>식사, 가사, 외출 등 일상생활 수행하는데 어려움을 경험한 적이 있다</p>
                          <span>{evalData.jsonData.s6_1}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>나 자신 또는 가족을 돌보는 것이 부담스러운 적이 있다.</p>
                          <span>{evalData.jsonData.s6_2}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>몸이 아프거나 불편한 적이 있다.</p>
                          <span>{evalData.jsonData.s6_3}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>우울하다는 생각을 한 적이 있다.</p>
                          <span>{evalData.jsonData.s6_4}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>힘들고 어려울 때 지역사회(주변사람, 광주광역시, 기관 등)로부터 도움을 받을 수 있다고 생각한다.</p>
                          <span>{evalData.jsonData.s6_5}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <p>지금까지 나의 삶에 대해 만족한다.</p>
                          <span>{evalData.jsonData.s6_6}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <div className="short_answer2 mt20">
                <ul>
                  <li>
                    <h4>서비스 신청경로</h4>
                    {evalData.jsonData.s1_1 ? evalData.jsonData.s1_1 === "기타" ? evalData.jsonData.s1_2 ?? "" : evalData.jsonData.s1_1 : ""}
                  </li>
                </ul>
                <div className="opinion">
                  <h4>기타의견</h4>
                  <p>{evalData.jsonData.etc}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

};

export default ResultEvalDialog;
