import axios from "axios";
import { plainToInstance } from "class-transformer";
import { serialize } from "object-to-formdata";
import Join from "../models/form/Join";
import Login from "../models/form/Login";

import Member from "../models/Member";

interface LoginParams {
  userId: string;
  fcmToken?: string;
}

export type LoginBasic = {
  userId: string;
  userPw: string;
}

const login = (params: LoginParams): Promise<Member> => {
  return axios
    .post("/api/member/login", serialize(params))
    .then((response) => plainToInstance(Member, response.data as Member));
};

const loginGet = () => {
  return axios
    .get("/api/member/loginSuccess")
    .then((res) => {
      // console.log(response)
      return res.data as Login
      // plainToInstance(Member, response.data as Member)
    });
};

const loginPost = (params: LoginBasic) => {
  return axios
    .post("/api/member/login", serialize(params))
    .then((res) => {
      // console.log("response : ", response)
      return res.data
    })
};

export type IdCheck = {
  userId : string
}

const idCheck = (params: IdCheck) => {
  return axios.get("/api/member/isAvailableId", {params})
  .then((res) => {
    // console.log(res)
    return res.data as {success : boolean, message: string}
  })
}

class JoinForm extends Join {
  constructor(
    public userId: string,
    public userPw: string,
    public certPw: string,
    public userName: string,
    public tel1: string,
    public tel2: string,
    public tel3: string,
    public phone: string,
    public gender: string,
    public role: string,
    public dptUidString: string,
    public cateCd: string,
    public dptUid: number,
    public caseChargeYn: "Y" | "N",
    public srvcSupplyYn: "Y" | "N",
    public srvcManageYn: "Y" | "N",
  ) {
    super(userId, userPw, certPw, userName, tel1, tel2, tel3, gender, cateCd, role, dptUidString)
  }
}

export interface MemResp {
  success: boolean,
  message: string,
  data: Member,
}

const join = (params: JoinForm) => {
  return axios.post("/api/member/join", serialize(params))
  .then((res) => {
    return res.data as MemResp
  })
}

type GetDept = {
  deptCd: string,
}

export interface DeptData {
  success: boolean,
  list: DepartVO[],
}

export interface DepartVO {
  dptUid: number,
  upperDptUid: number,
  departName: string,
  deptCd: "SI" | "GU" | "DONG" | "ORG",
  ordNo: number,
  ordAll: string,
  upperAll: string,
  upperTop: number,
  depth: number,
  tel: string,
  fax: string,
  crtUid: number,
  crtDt: string,
  updUid: number,
  updDt: string,
  delUid: number,
  delDt: string,
  beforeOrdAll: string,
}

const getDeptList = (params : GetDept) => {
  return axios.get("/api/member/getDeptList", {params}).then((res) => {
    return res.data as DeptData
  })
}

type OneDept = {
  dptUid: number,
}

const getOneDept = (params: OneDept) => {
  return axios.get("/api/member/getOne", {params}).then((res) => {
    return res.data as {success : boolean, data : DepartVO}
  })
}

type DeptList = {
  dptUid?: number,
  deptCd?: "SI" | "GU" | "DONG" | "ORG",
}
const getAllDept = (params?: DeptList) => {
  return axios.get("/api/preferences/orgDataList", {params}).then((res) => {
    return res.data as DepartVO[]
  })
}

type EditProfile = {
  // files: FileList;
  mbrUid: number;
  dptUid: number;
  userName: string;
  // caseChargeYn: string;
  // tel1: string;
  // tel2: string;
  // tel3: string;
  phone: string;
  tel: string;
  // depart: DepartVO;
}

export type UpdResult = {
  success: boolean,
  message: string,
}

const update = (params: EditProfile) => {
  return axios.post("/api/member/update", serialize(params)).then((res) => {
    return res.data as UpdResult
  })
}

const MemberAPI = {
  login,
  loginGet,
  loginPost,
  idCheck,
  join,
  getDeptList,
  getOneDept,
  update,
  getAllDept,
};

export default MemberAPI;
