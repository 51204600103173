import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import ServiceAPI, { RegistParams } from "../../../apis/ServiceAPI";
import ServiceTargetAPI from "../../../apis/ServiceTargetAPI";

import BackIcon from "../../../assets/ic_backlist.png";
import DataTable, { createRow, createTextCell } from "../../../components/common/DataTable";
import ServiceResultTable from "../../../components/common/serviceResult/ServiceResultTable";
import { useDialog } from "../../../dialogs/DialogProvider";
import ServiceTargetSimpleInfoDialog from "../../../dialogs/service/ServiceTargetSimpleInfoDialog";
import serviceResultReportInputForm from "../../../models/form/serviceResultReportInputForm";
import Service, { ServiceDetail } from "../../../models/ServiceDetail";
import ServiceList from "../../../models/ServiceList";
import ServiceTargetYear from "../../../models/ServiceTargetYear";

import "./ServiceResultPage.scss";
import ServiceInfo from "../../../models/ServiceInfo";

/**
 * 작성자: 김혜정
 * 작성일: 2023.02.27
 *
 * 결과보고서 페이지
 * @param spyUid 서비스 일련번호
 * @param onBackPressed `목록으로 돌아가기` 눌렀을 때의 동작
 */

// 서비스 종류 셀렉트 옵션
const depth01 = [
  "가사활동",
  "신체활동",
  "일상활동",
  "방문목욕(욕조O)",
  "방문목욕(욕조X)",
];
const depth02 = ["맞춤형 영양설계", "영양음식 조리배달"];
const depth03 = ["병원동행", "관공서 외출동행"];
const depth04 = ["방문진료", "방문맞춤운동"];
const depth05 = ["AI 안부전화", "ICT 활용 안전체크", "안전생활환경"];
const depth06 = ["간단수리", "대청소", "방역 방충"];
const depth07 = ["시설 단기 입소"];
const depthMerge = ["맞춤형 영양설계", "영양음식 조리배달", "병원동행", "관공서 외출동행", "방문진료", "방문맞춤운동", "AI 안부전화", "ICT 활용 안전체크", "안전생활환경", "간단수리", "대청소", "방역 방충", "시설 단기 입소"]
const depthArr = [
  [],
  depth01,
  depth02,
  depth03,
  depth04,
  depth05,
  depth06,
  depth07,
];

// 중단 사유 셀렉트 옵션
const stop01 = ["이사", "입원", "사망", "거부", "기타"];
const stop02 = ["이용자 상황 호전 판단", "이용자 대응 어려움"];
const stopArr = [[], stop01, stop02];

const ServiceResultPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const spyUid = searchParams.get("spyUid");
  const siUid = searchParams.get("siUid")
  const mbrUid = searchParams.get("mbrUid")
  const cateCd = searchParams.get("cateCd")
  const [specify, setSpecify] = useState({} as ServiceList)
  const [details, setDetails] = useState({} as Service)
  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(true)
  const [serviceTarget, setServiceTarget] = useState({} as ServiceTargetYear)
  const [serviceInfoList, setServiceInfoList] = useState([] as ServiceInfo[])

  const [serviceType, setServiceType] = useState(0);
  const [serviceType2, setServiceType2] = useState(0);

  const [stopType, setStopType] = useState(0);
  const [stopType2, setStopType2] = useState(0);

  const { register, handleSubmit, getValues, watch } = useForm<RegistParams>()

  useEffect(() => {
    ServiceAPI.getServiceInfo().then((res) => setServiceInfoList(res.serviceInfo))

    ServiceAPI.getOne({
      spyUid: Number(spyUid),
    }).then((res) => {
      // console.log("item : ", JSON.parse(JSON.stringify(res).replaceAll('[', '').replaceAll(']', '')))
      setSpecify(JSON.parse(JSON.stringify(res).replaceAll('[', '').replaceAll(']', '')))
      setLoading(false)
    })

    ServiceAPI.getDetail({
      spyUid: Number(spyUid),
    }).then((res) => {
      // console.log("details : " ,res)
      // console.log(JSON.stringify(res))
      setDetails(res)
      setLoading2(false)
    })

    // ServiceTargetAPI.getOne({
    //   trgterCd: trgterCd,
    // }).then((res) => {
    //   console.log(res)
    //   // console.log("serviceTargetYear : ", JSON.parse(JSON.stringify(res).slice(1, JSON.stringify(res).length - 1)))
    //   setServiceTarget(JSON.parse(JSON.stringify(res).slice(1, JSON.stringify(res).length - 1)) as ServiceTargetYear)
    // })
  }, [])

  const handleBackPressed = () => {
    navigate(-1);
  };

  // 서비스 종류
  const handleServiceTypeChange = (index: number) => {
    console.log(index);
    setServiceType(index);
  };

  const handleServiceType2Change = (index: number) => {
    setServiceType2(index);
  };

  // 중단 사유
  const handleStopTypeChange = (index: number) => {
    console.log(index);
    setStopType(index);
  };

  const handleStopType2Change = (index: number) => {
    setStopType2(index);
  };
  const data = watch()
  // useEffect(() => {
  //   console.log(data)
  // }, [data])

  const onSubmit:SubmitHandler<RegistParams> = async (data) => {
    let serviceStatCd = "서비스제공완료"
    if(data.stopYn === "Y") {
      serviceStatCd = "중단"
    }
    let suc = await ServiceAPI.resultRegist({
      ...data,
      spyUid: Number(spyUid),
      siUid: Number(siUid),
      serviceStatCd: serviceStatCd,
    })
    if(suc) {
      console.log("성공여부 : ", suc);
      navigate("/service", {replace: true});
    } else {
      console.log("Fail");
      window.alert("평가 결과 전송 실패")
    }
  };

  // 제공기간 설정
  const dateElement = useRef<HTMLInputElement>(null);

  return (
    <main className="serviceResult">
      {loading || loading2 ? null :
        <section>
        <article className="detail_top">
          <div className="nav_list">
            <p>
              서비스 / <span>결과보고</span>
            </p>
            <button type="button" className="mt8" onClick={handleBackPressed}>
              <span>
                <img src={BackIcon} alt="목록으로 돌아가기 아이콘" />
              </span>
              목록으로 돌아가기
            </button>
          </div>
          <div className="btn_wrap">
            <button type="submit" className="submit" onClick={handleSubmit(onSubmit)}>
              저장
            </button>
          </div>
        </article>
        <Info detail={details} />
        <article className="service_result mt20">
          <CarePerson spy={specify} />
          <div className="service_cont">
            <h4>서비스 결과</h4>
            <div className="text_input">
              <p>서비스 종류</p>
              <div className="input_wrap">
                <div className="input_box mr8" style={{ width: "22%" }}>
                  <select
                    className="w100 select2depth"
                    style={{ color: "rgb(0, 0, 0)" }}
                    disabled
                    value={Number(cateCd)}
                    // onChange={(e) =>
                    //   handleServiceTypeChange(Number(e.target.value))
                    // }
                  >
                    {/* <select className="w100 select2depth" onchange="categoryChange(this)" style={{color: 'rgb(0, 0, 0)'}}> */}
                    <option value="">선택</option>
                    <option value="1">가사지원</option>
                    <option value="2">식사지원</option>
                    <option value="3">동행지원</option>
                    <option value="4">건강지원</option>
                    <option value="5">안전지원</option>
                    <option value="6">주거편의</option>
                    <option value="7">일시보호</option>
                  </select>
                </div>
                <div className="input_box" style={{ width: "22%" }}>
                  <select
                    className="w100"
                    id="selectDepth"
                    style={{ color: "rgb(0, 0, 0)" }}
                    disabled
                    value={Number(siUid)}
                  >
                    <option value="">선택</option>
                    {serviceInfoList.map((info, idx) => (
                      <option key={idx} value={info.siUid}>
                        {info.serviceNm}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="text_input mt20">
              <p>제공기간</p>
              <div className="input_wrap">
                <div
                  className="input_box ifdate"
                  style={{ width: "40%", justifyContent: "space-between" }}
                >
                  {/* <input type="text" style={{width: '47%'}} id="birth" placeholder="지원시작일" style={{width: '40%', justifyContent: 'space-between'}}onFocus="(this.type='date')" onblur="(this.type='text')" /> */}
                  <input
                    type="date"
                    id="supportStart"
                    placeholder="지원시작일"
                    style={{ width: "40%", justifyContent: "space-between" }}
                    disabled
                    value={details.detail.beginDate ?? "0000-00-00"}
                  />
                  <p>~</p>
                  {/* <input type="text" style={{width: '47%'}} id="birth" placeholder="지원종료일" onfocus="(this.type='date')" onblur="(this.type='text')" /> */}
                  <input
                    type="date"
                    id="supportEnd"
                    placeholder="지원종료일"
                    style={{ width: "47%" }}
                    disabled
                    value={details.detail.endDate ?? "0000-00-00"}
                  />
                </div>
              </div>
            </div>
            <div className="text_input mt20">
              <p>서비스 내용</p>
              <div className="input_wrap">
                <div className="input_box w100">
                  <input type="text" className="w100" placeholder="내용 입력" {...register("serviceDetail")}/>
                </div>
              </div>
            </div>
            <div className="text_input mt20">
              <p>서비스 상세</p>
              <div className="input_wrap">
                <div className="service_plan_list">
                  <ServiceResultTable 
                  columnTitles={[
                    "서비스 회차",
                    "서비스 일자",
                    "진행시간(제공시간)",
                    "서비스 내용",
                    "변경사항",
                  ]}
                  rows = {details.list.map((service, idx) => 
                      createRow({
                        cells: [
                          createTextCell({text: (idx + 1).toString() + "회차"}),
                          createTextCell({text: service.serviceDate ?? "데이터 없음"}),
                          createTextCell({text: service.beginDt && service.endDt ? moment(service.beginDt).format('A HH:mm') + "~" + moment(service.endDt).format('A HH:mm') + " (" + 
                          Math.round(moment(service.endDt).diff(moment(service.beginDt), 'hours')) + "시간)" : "데이터 없음"
                        }),
                          createTextCell({text: service.serviceDetail}),
                          createTextCell({text: service.chngCnt}),
                        ]
                      })
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="text_input mt20">
              <p>목표 달성 여부</p>
              <div className="input_wrap">
                <div className="input_box mr8 " style={{ width: "22%" }}>
                  <select
                    className="w100 selectShow1"
                    style={{ color: "rgb(0, 0, 0)" }}
                    {...register("stopYn")}
                  >
                    <option value="">선택</option>
                    <option value="N">의뢰된 서비스 지원 완료</option>
                    <option value="Y">중단</option>
                  </select>
                </div>
                <div
                  className="input_box mr8 selectUl1"
                  style={{ width: "22%" }}
                >
                  <select
                    className="w100 selectdepth"
                    style={{ color: "rgb(0, 0, 0)" }}
                    disabled={getValues("stopYn") === "Y" ? false : true}
                    {...register("stopRsn1", {
                      onChange: (e) =>
                      handleStopTypeChange(Number(e.target.value))
                    })}
                    // disabled={true}
                  >
                    <option value="">선택</option>
                    <option value={1}>이용자 사유</option>
                    <option value={2}>제공기관 사유</option>
                  </select>
                </div>
                <div
                  className="input_box mr8 selectUl1"
                  style={{ width: "22%" }}
                >
                  <select
                    className="w100 selectShow2"
                    id="selectDepth2"
                    style={{ color: "rgb(0, 0, 0)" }}
                    disabled= {getValues("stopRsn1") === "" || getValues("stopRsn1") === undefined ? true : false}
                    {...register("stopRsn")}
                  >
                    <option value="">선택</option>
                    {stopArr[stopType].map((e, i) => (
                      <option
                        key={1}
                        value={e}
                        onClick={() => handleStopType2Change(i + 1)}
                      >
                        {e}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className="input_box selectUl2"
                  style={{ width: "calc(34% - 24px)", display: "none" }}
                >
                  <input type="text" className="w100" placeholder="내용 입력" />
                </div>
              </div>
            </div>
            <div className="text_input mt20">
              <p>의견</p>
              <div className="input_wrap">
                <div className="input_box w100">
                  <textarea
                    placeholder="향후 돌봄대상자 지원에 참고할 만한 정보나 의견"
                    {...register("providerOpinion")}
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
      }
      
    </main>
  );
};

const Info = ({detail} : {detail: Service}) => {
  // const { showDialog } = useDialog();
    // 돌봄대상자 인적사항 수정 팝업
  // const ServiceTargetSimpleInfo = () => {
  //   showDialog(ServiceTargetSimpleInfoDialog, {serviceTarget : serviceTarget});
  // };
  const [age, setAge] = useState(0)
  useEffect(() => {
    const today = new Date()
    const birthDate = new Date(detail.targetInfo.brthdy)
    setAge(today.getFullYear() - birthDate.getFullYear())
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      setAge((cur) => cur - 1)
    }
  }, [])

  return (
    <article className="info">
          <ul>
            <li className="btn_edit">
              {/* <button className="btn_gray" onClick={ServiceTargetSimpleInfo}>
                수정
              </button> */}
            </li>
            <li>
              <p className="gray">성명</p>
              <p>{detail.targetInfo.trgterNm}</p>
            </li>
            <li>
              <p className="gray">생년월일</p>
              <p>{detail.targetInfo.brthdy}(만 {age}세)</p>
            </li>
            <li>
              <p className="gray">성별</p>
              <p>{detail.targetInfo.gender}</p>
            </li>
            <li>
              <p className="gray">연락처</p>
              <p>{detail.targetInfo.telno}</p>
            </li>
            <li className="x2">
              <p className="gray">주소</p>
              <p>{detail.targetInfo.adres} {detail.targetInfo.adresDtl}</p>
            </li>
          </ul>
    </article>
  )
}

const CarePerson = ({spy} : {spy : ServiceList}) => {
  return (
    <div className="care_person mr20">
            <h4>제공기관 및 제공인력</h4>
            <div className="default_input">
              <h4>제공기관</h4>
              <div className="write_box">
                <input
                  type="text"
                  className="essential mr8 w100"
                  placeholder="제공기관 입력"
                  disabled
                  defaultValue={spy.departName}
                />
              </div>
            </div>
            <div className="default_input mt20">
              <h4>담당자</h4>
              <div className="write_box">
                <input
                  type="text"
                  className="essential mr8 w100"
                  placeholder="성명"
                  disabled
                  defaultValue={spy.mbrName}
                />
              </div>
            </div>
            <div className="default_input tel mt8">
              <h4 />
              <div className="write_box">
                {/* <select className="essential">
                  <option value="">선택</option>
                  <option value={1}>010</option>
                  <option value={2}>011</option>
                  <option value={3}>017</option>
                  <option value={4}>018</option>
                  <option value={5}>019</option>
                </select> */}
                <input type="tel"
                  defaultValue={spy.telno.split('-')[0]}
                  disabled
                 />
                <span>-</span>
                <input type="tel"
                  defaultValue={spy.telno.split('-')[1]}
                  disabled
                 />
                <span>-</span>
                <input type="tel"
                  defaultValue={spy.telno.split('-')[2]}
                  disabled
                 />
              </div>
            </div>
            <div className="default_input mt20">
              <h4>제공인력</h4>
              <div className="write_box">
                <input
                  type="text"
                  className="essential mr8 w50_8"
                  placeholder="성명"
                />
                <span className="mr8">외</span>
                <input
                  type="text"
                  className="mr8"
                  placeholder="숫자"
                  style={{ width: 80 }}
                />
                <span>명</span>
              </div>
            </div>
            <div className="default_input tel mt8">
              <h4 />
              <div className="write_box">
                <select className="essential">
                  <option value="">선택</option>
                  <option value={1}>010</option>
                  <option value={2}>011</option>
                  <option value={3}>017</option>
                  <option value={4}>018</option>
                  <option value={5}>019</option>
                </select>
                <span>-</span>
                <input type="tel" />
                <span>-</span>
                <input type="tel" />
              </div>
            </div>
    </div>
  )
}

export default ServiceResultPage;
