/* eslint-disable react-hooks/rules-of-hooks */
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useForm,
  FieldErrors,
  UseFormWatch,
} from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useSearchParams } from "react-router-dom";

import ServicePlanAPI, { InsertEvaluation } from "../../../apis/ServicePlanAPI";
import { useDialog } from "../../../dialogs/DialogProvider";
import NaEvaluationTargetInfo from "../../../models/NaEvaluationTargetInfo";
import NeedsAssessmentFormInput from "../../../models/form/NeedsAssessmentFormInput";
import "./NeedsAssessmentPage.scss";
import CloseIcon from "../../../assets/ic_close.png"
import NeedsAssessmentJoin from "../../../models/NeedsAssessmentJoin";
import NaEvaluationInfo from "../../../models/NaEvaluationInfo";
import ServiceTargetYear, { ServiceTargetYearCharacter } from "../../../models/ServiceTargetYear";
import ServiceTargetAPI from "../../../apis/ServiceTargetAPI";
import ServiceTargetSimpleInfoDialog from "../../../dialogs/service/ServiceTargetSimpleInfoDialog";
import { nameValid, telValid, ValidCheck } from "../../../App";
import FileUtil from "../../../utils/FileUtil";
import BackIcon from "../../../assets/ic_backlist.png";

export interface NaParams {
  sdUid: number,
  csUid: number,
  syUid: number,
}

export interface IllItem {
  key: number,
  value: string,
}

/**
 * 작성자: 강우석
 * 작성일: 2023.02.27
 *
 * 필요도평가 페이지
 * @param sdUid 돌봄계획 일련번호
 * @param onBackPressed `목록으로 돌아가기` 눌렀을 때의 동작
 */

const NeedsAssessmentPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { showDialog } = useDialog();
  //필요도평가 대상자 정보 조회
  const beginDt = useRef(moment());
  const sdUid = parseInt(searchParams.get("sdUid") as string);
  const csUid = parseInt(searchParams.get("csUid") as string);
  const syUid = parseInt(searchParams.get("syUid") as string);
  const trgterCd = searchParams.get("trgterCd") as string

  const [target, setTarget] = useState<NaEvaluationTargetInfo>();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true)
  const [evalData, setEvalData] = useState<NeedsAssessmentJoin>({} as NeedsAssessmentJoin)
  const [serviceTarget, setServiceTarget] = useState({} as ServiceTargetYear)

  useEffect(() => {
    ServicePlanAPI.getEvaluationTargetInfo({
      sdUid: Number(sdUid),
    }).then((res) => {
      // console.log("대상자 정보 : ", res);

        let demographicCharacteristics = JSON.parse(res.characterJson as string) as ServiceTargetYearCharacter
        // console.log("demographicCharacteristics : ", demographicCharacteristics)
        if(res.homeMember !== null) {
          setValue("homeMember", res.homeMember as number)
        }
        setValue("emgcNm", res.emgcNm as string)
        setValue("emgcRel", res.emgcRel as string)
        if(res.emgcTel !== null) {
          setValue("emgcTel1", (res.emgcTel as string).split('-')[0])
          setValue("emgcTel2", (res.emgcTel as string).split('-')[1])
          setValue("emgcTel3", (res.emgcTel as string).split('-')[2])
        }
        setValue("incomeLevel", res.incomeLevel as string)
        setValue("serviceTargetInfo", demographicCharacteristics)
        if(res.incomeLevel === "85이하") {
          setValue("incomeLevel2", res.incomeLevel2 as string)
        }

      setTarget(res);
      setLoading(false);
    });
    ServicePlanAPI.getEvaluationData({
      csUid: csUid,
    }).then((res) => {
      if (!res) {
        // console.log("필요도평가 정보 불러오기 실패");
      } else {
        // console.log("필요도평가 데이터 : ", res);
        setEvalData(res as NeedsAssessmentJoin);
        setValue("json", (res as NaEvaluationInfo).jsonData)
        setLoading2(false);
      }
    })
    ServiceTargetAPI.getOne({
      trgterCd: trgterCd,
    }).then((res) => {
      // console.log("serviceTargetYear : ", res)
      setServiceTarget(JSON.parse(JSON.stringify(res).slice(1, JSON.stringify(res).length - 1)) as ServiceTargetYear)
    })
  }, []);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm<NeedsAssessmentFormInput>();
  const data = watch();
  const [bodyPt, setBodyPt] = useState(0);
  const [emtPt, setEmtPt] = useState(0);
  const [bodyPoints, setBodyPoints] = useState<number[] | null[]>([
    null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null,
  ]);
  const [emtPoints, setEmtPoints] = useState<number[] | null[]>([null, null, null, null, null, null, null, null, null, null]);

  const topBodyPt = (list: number[], addScore?: number) => {
    let addIllList:string[] = []
    if (getValues("json.illAdd")) {
      addIllList = JSON.parse(getValues("json.illAdd")) as string[]
    }
    setBodyPoints(list);
    setBodyPt(list.reduce((acc, c) => acc + c) + (addScore ?? 0) + addIllList.length * 5);
    setValue("json.bodyAreaPt", list.reduce((acc, c) => acc + c) + (addScore ?? 0) + addIllList.length * 5)
  };

  const topEmtPt = (list: number[]) => {
    setEmtPoints(list);
    setEmtPt(list.reduce((acc, c) => acc + c));
    setValue("json.emtAreaPt", list.reduce((acc, c) => acc + c))
  };

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  const [inputCheck, setInputCheck] = useState<ValidCheck>({stat: true, msg: ""})
  const paramCheck = () => {
    if(!nameValid.test(getValues("emgcNm"))) {
      // setInputCheck({stat: false, msg: "올바른 비상연락인 이름을 입력하세요."})
      return {stat: false, msg: "올바른 비상연락인 이름을 입력하세요."}
    } else if(!telValid.test(getValues("emgcTel2"))) {
      // setInputCheck({stat: false, msg: "올바른 비상연락인 연락처를 입력하세요."})
      return {stat: false, msg: "올바른 비상연락인 연락처를 입력하세요."}
    } else if(!telValid.test(getValues("emgcTel3"))) {
      // setInputCheck({stat: false, msg: "올바른 비상연락인 연락처를 입력하세요."})
      return {stat: false, msg: "올바른 비상연락인 연락처를 입력하세요."}
    } else {
      // setInputCheck({stat: true, msg: ""})
      return {stat: true, msg: ""}
    }
  }
  const nameValidation = (e:any) => {
    nameValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }
  const telValidation = (e:any) => {
    telValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }

  const onSubmit: SubmitHandler<NeedsAssessmentFormInput> = async (data) => {
    if(getValues("incomeLevel") === "85이하" && getValues("incomeLevel2") === null) {
      window.alert("상세 소득수준을 선택해주세요.")
      return
    }
    if(getValues("serviceTargetInfo.demographicCharacteristics.type") === "1인가구") {
      if(!getValues("serviceTargetInfo.demographicCharacteristics.singleType") || getValues("serviceTargetInfo.demographicCharacteristics.singleType").length === 0) {
        window.alert("1인 가구 유형을 선택해주세요.")
        return
      }
    }
    if(getValues("serviceTargetInfo.demographicCharacteristics.type") === "노인") {
      if(!getValues("serviceTargetInfo.demographicCharacteristics.oldType") || getValues("serviceTargetInfo.demographicCharacteristics.oldType").length === 0) {
        window.alert("장기요양 등급을 선택해주세요.")
        return
      }
    }
    if(getValues("serviceTargetInfo.demographicCharacteristics.type") === "장애인") {
      if(!getValues("serviceTargetInfo.demographicCharacteristics.disabledType") || getValues("serviceTargetInfo.demographicCharacteristics.disabledType").length === 0) {
        window.alert("장애 정도를 선택해주세요.")
        return
      }
    }
    if(getValues("serviceTargetInfo.demographicCharacteristics.disabledAdd")) {
      if(!getValues("serviceTargetInfo.demographicCharacteristics.disabledAddType") || getValues("serviceTargetInfo.demographicCharacteristics.disabledAddType").length === 0) {
        window.alert("장애 정도를 선택해주세요.")
        return
      }
    }
    // let checkObj = paramCheck()
    // if(!checkObj.stat) {
    //   window.alert(checkObj.msg)
    //   return
    // }
    // console.log(data.json)
    if(data.json.q1_1 === null || data.json.q1_2 === null || data.json.q1_3 === null || data.json.q1_4 === null || data.json.q1_5 === null
      || data.json.q1_6 === null || data.json.q1_7 === null || data.json.q1_8 === null || data.json.q2_1 === null || data.json.q2_2 === null
      || data.json.q2_3 === null || data.json.q2_4 === null || data.json.q2_5 === null || data.json.q2_6 === null) {
        window.alert("신체적 영역 항목을 모두 선택해주세요.")
        return
      }
    if(data.json.q4_1 === null || data.json.q4_2 === null || data.json.q4_3 === null || data.json.q4_4 === null || data.json.q4_5 === null
      || data.json.q5_1 === null || data.json.q5_2 === null || data.json.q5_3 === null || data.json.q5_4 === null || data.json.q5_5 === null) {
        window.alert("정서적 영역 항목을 모두 선택해주세요.")
        return
      }
    if(data.json.q6_1 === null || data.json.q6_2 === null || data.json.q6_3 === null || data.json.q6_4 === null || data.json.q7_1 === null
      || data.json.q7_2 === null || data.json.q7_3 === null || data.json.q7_4 === null || data.json.q7_5 === null || data.json.q7_6 === null
      || data.json.q7_7 === null) {
        window.alert("추가정보(생활환경) 항목을 모두 선택해주세요.")
        return
      }
    if(data.json.q8_1 === null || data.json.q8_2 === null || data.json.q8_3 === null || data.json.q8_4 === null || data.json.q8_1 === null) {
        window.alert("추가정보(사전검사) 항목을 모두 선택해주세요.")
        return
      }
    if(!data.json.e1_content) {
      window.alert("긴급 위기 상황 상세를 입력해주세요.")
      return
    }
    if(!data.json.g1_content) {
      window.alert("신체적, 정서적 기능 저하 상세를 입력해주세요.")
      return
    }
    if(!data.json.g2_content) {
      window.alert("돌볼 수 있는 사람 부재 여부 상세를 입력해주세요.")
      return
    }
    if(!data.json.g3_content) {
      window.alert("기존 돌봄 서비스 공백 여부 상세를 입력해주세요.")
      return
    }
    if(!data.json.e4_content) {
      window.alert("즉시 돌봄 서비스 공백 여부 상세를 입력해주세요.")
      return
    }
    let stepCdNext:string = "돌봄계획"
    if (
      getValues("json.gSatisfy") === "미충족" && getValues("json.eSatisfy") === "미충족"
    ) {
      window.alert("미충족으로 인한 종결처리");
      stepCdNext = "사례종결"
    }
    let dSupportYn = getValues("json.gSatisfy") !== "충족" ? "N" : "Y";
    let eSupportYn = getValues("json.eSatisfy") !== "충족" ? "N" : "Y";
    // console.log(data);
    // console.log(JSON.stringify(data.json))
    let success = await ServicePlanAPI.insertEvaluation({
      ...data,
      csUid: csUid,
      syUid: syUid,
      beginDt: beginDt.current.format("yyyyMMDDHH:mm"),
      beginGpsCd: 1.1,
      endDt: moment().format("yyyyMMDDHH:mm"),
      endGpsCd: 1.1,
      resultJson: JSON.stringify(data.json),
      bodyAreaPt: bodyPt,
      emtAreaPt: emtPt,
      characterJson: JSON.stringify(data.serviceTargetInfo),
      emgcTel: `${data.emgcTel1}-${data.emgcTel2}-${data.emgcTel3}`,
      dSupportYn: dSupportYn,
      eSupportYn: eSupportYn,
      stepCdNext: stepCdNext,
    });

    if (success) {
      navigate("/service-plan", { replace: true });
    } else {
      window.alert("필요도평가 저장 실패")
    }
  };

  const onTestSave = async () => {
    if(window.confirm("임시저장 하시겠습니까?")) {
      let dSupportYn = getValues("json.gSatisfy") !== "충족" ? "N" : "Y";
      let eSupportYn = getValues("json.eSatisfy") !== "충족" ? "N" : "Y";
      let success = await ServicePlanAPI.insertEvaluation({
        ...data,
        csUid: csUid,
        syUid: syUid,
        beginDt: beginDt.current.format("yyyyMMDDHH:mm"),
        beginGpsCd: 1.1,
        endDt: moment().format("yyyyMMDDHH:mm"),
        endGpsCd: 1.1,
        resultJson: JSON.stringify(data.json),
        bodyAreaPt: bodyPt,
        emtAreaPt: emtPt,
        characterJson: JSON.stringify(data.serviceTargetInfo),
        emgcTel: `${data.emgcTel1}-${data.emgcTel2}-${data.emgcTel3}`,
        dSupportYn: dSupportYn,
        eSupportYn: eSupportYn,
        stepCdNext: "현장방문",
      });

      if (success) {
        window.alert("임시 저장 성공")
        navigate("/service-plan", { replace: true });
      } else {
        window.alert("임시 저장 실패")
      }
    }

    
  }

  const onError = (e: FieldErrors<NeedsAssessmentFormInput>) => {
    if(errors.homeMember) {
      window.alert(errors.homeMember.message)
      return
    }
    //  else if(errors.emgcNm) {
    //   window.alert(errors.emgcNm.message)
    //   return
    // } else if(errors.emgcRel) {
    //   window.alert(errors.emgcRel.message)
    //   return
    // } else if(errors.emgcTel1) {
    //   window.alert(errors.emgcTel1.message)
    //   return
    // } else if(errors.emgcTel2) {
    //   window.alert(errors.emgcTel2.message)
    //   return
    // } else if(errors.emgcTel3) {
    //   window.alert(errors.emgcTel3.message)
    //   return
    // }
     else if(errors.serviceTargetInfo?.distress) {
      window.alert(errors.serviceTargetInfo?.distress.message)
      return
    } else if(errors.serviceTargetInfo?.demographicCharacteristics?.type) {
      window.alert("인구학적 특성을 선택해주세요.")
      return
    } else if(errors.incomeLevel) {
      window.alert(errors.incomeLevel.message)
      return
    }
  };

  const ServiceTargetDetailInfo = (target: NaEvaluationTargetInfo, params: NaParams) => {
    // showDialog(ServiceTargetDetailInfoDialog, {
    //   infoData: target as NaEvaluationTargetInfo,
    //   params: params
    // });
    showDialog(ServiceTargetSimpleInfoDialog, {serviceTarget : serviceTarget});
  };
  const handleBackPressed = () => {
    navigate(-1);
  };

  return (
    <main className="need-assessment">
      <section>
        {loading || loading2 ? (
          <h1>Loading....</h1>
        ) : (
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            {/* <DetailsTopNeedAssess listName="돌봄계획" detailsName="필요도평가" /> */}
            <article className="detail_top">
              <div className="nav_list">
                <p>
                  돌봄계획 / <span>필요도평가</span>
                </p>
                <button type="button" className="mt8" onClick={handleBackPressed}>
                  <span>
                    <img src={BackIcon} alt="목록으로 돌아가기 아이콘" />
                  </span>
                  목록으로 돌아가기
                </button>
              </div>
              <div className="btn_wrap">
                <button type="button" className="submit" onClick={onTestSave} style={{marginRight: "10px"}}>
                  임시저장
                </button>
                <button type="submit" className="submit">
                  저장
                </button>
              </div>
            </article>
            <Info
              target={target as NaEvaluationTargetInfo}
              dialogFunc={ServiceTargetDetailInfo}
              params={{sdUid: sdUid, csUid: csUid, syUid: syUid}}
            />
            <article className="care_evaluation mt20">
              <LeftPanel register={register} control={control} data={data}
              nameValidation={nameValidation} telValidation={telValidation}
              setValue={setValue} getValues={getValues}
              watch={watch}
              />
              <MiddlePanel
                register={register}
                bodyList={bodyPoints}
                midBodyPt={topBodyPt}
                emtList={emtPoints}
                midEmtPt={topEmtPt}
                getValues={getValues}
                setValue={setValue}
              />
              <RightPanel
                register={register}
                setValue={setValue}
                getValues={getValues}
                bodyPt={bodyPt}
                emtPt={emtPt}
              />
            </article>
          </form>
        )}
      </section>
    </main>
  );
};

const Info = ({
  target,
  dialogFunc,
  params,
}: {
  target: NaEvaluationTargetInfo;
  dialogFunc: Function;
  params: NaParams;
}) => {
  const today = new Date()
  const birth = new Date(moment(target.brthdy).format())
  let age = today.getFullYear() - birth.getFullYear()
  const month = today.getMonth() - birth.getMonth()
  if (month < 0 || (month === 0 && today.getDate() < birth.getDate())) {
    age = age - 1
  }
  return (
    <article className="info">
      <ul>
        <li className="btn_edit">
          <button
            type="button"
            onClick={() => dialogFunc(target, params)}
            className="btn_gray"
          >
            수정
          </button>
        </li>
        <li>
          <p className="gray">성명</p>
          <p>{target.trgterNm}</p>
        </li>
        <li>
          <p className="gray">생년월일</p>
          <p>{moment(target.brthdy).format("yyyy-MM-DD")} (만{age}세)</p>
        </li>
        <li>
          <p className="gray">성별</p>
          <p>{target.gender}</p>
        </li>
        <li>
          <p className="gray">연락처</p>
          <p>{target.telno}</p>
        </li>
        <li className="x2">
          <p className="gray">주소</p>
          <p>
            {target.adres} {target.adresDtl}
          </p>
        </li>
        <li>
          <p className="gray">방문일시</p>
          <p>{moment(target.counselDate).format('yyyy-MM-DD (dd)')}</p>
        </li>
      </ul>
    </article>
  );
};

const LeftPanel = ({
  register,
  control,
  data,
  nameValidation,
  telValidation,
  setValue,
  getValues,
  watch,
}: {
  register: UseFormRegister<NeedsAssessmentFormInput>;
  control: Control<NeedsAssessmentFormInput, any>;
  data: NeedsAssessmentFormInput;
  nameValidation: Function,
  telValidation: Function,
  setValue: UseFormSetValue<NeedsAssessmentFormInput>,
  getValues: UseFormGetValues<NeedsAssessmentFormInput>,
  watch: UseFormWatch<NeedsAssessmentFormInput>,
}) => {
  const [fileApply, setFileApply] = useState<File>();
  const [fileAgree, setFileAgree] = useState<File>();
  const [urlApply, setUrlApply] = useState<string>()
  const [urlAgree, setUrlAgree] = useState<string>()

  const integrated = watch("serviceTargetInfo.needs.integrated")  //기존돌봄 연계여부
  const gwangjuCare = watch("serviceTargetInfo.needs.gwangjuCare")  //광돌 선택여부
  const integratedType = watch("serviceTargetInfo.needs.integratedType")  //기존돌봄 연계 선택아이템
  const gwangjuCareType = watch("serviceTargetInfo.needs.gwangjuCareType")  //광돌 선택아이템

  useEffect(() => {
    // console.log(integratedType)
    if(integratedType && integratedType.length > 0) {
      setValue("serviceTargetInfo.needs.integrated", true)
    }
    if(integratedType && integratedType.length === 0) {
      setValue("serviceTargetInfo.needs.integrated", false)
      setValue("serviceTargetInfo.needs.integratedReason", "")
    }
  }, [integratedType])

  useEffect(() => {
    if(gwangjuCareType && gwangjuCareType.length > 0) {
      setValue("serviceTargetInfo.needs.gwangjuCare", true)
    }
    if(gwangjuCareType && gwangjuCareType.length === 0) {
      setValue("serviceTargetInfo.needs.gwangjuCare", false)
    }
  }, [gwangjuCareType])

  useEffect(() => {
    if(!integrated) {
      setValue("serviceTargetInfo.needs.integratedType", [])
      setValue("serviceTargetInfo.needs.integratedReason", "")
    }
  }, [integrated])

  useEffect(() => {
    if(!gwangjuCare) {
      setValue("serviceTargetInfo.needs.gwangjuCareType", [])
    }
  }, [gwangjuCare])
  

  const disabledAddFalse = (e:any) => {
    if(e.target.value === "장애인") {
      setValue("serviceTargetInfo.demographicCharacteristics.disabledAdd", false)
      setValue("serviceTargetInfo.demographicCharacteristics.disabledTypeAddInput", "")
    } else {
      setValue("serviceTargetInfo.demographicCharacteristics.disabledTypeInput", "")
    }
  }

  const handleApplyFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files?.[0]) {
      FileUtil.getFilePath(e.target.files?.[0]).then((res) => {
        // console.log(res)
        setUrlApply(res)
      })
    }
    setValue("serviceTargetInfo.applicnFileName", e.target.files?.[0].name!)
    setFileApply(e.target.files?.[0]);
  }

  const handleAgreeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files?.[0]) {
      FileUtil.getFilePath(e.target.files?.[0]).then((res) => {
        // console.log(res)
        setUrlAgree(res)
      })
    }
    setValue("serviceTargetInfo.agreeFileName", e.target.files?.[0].name!)
    setFileAgree(e.target.files?.[0]);
  }

  const delApllyFile = () => {
    setUrlApply(undefined)
    setFileApply(undefined);
    setValue("serviceTargetInfo.applicnFileName", undefined)
  }

  const delAgreeFile = () => {
    setUrlAgree(undefined)
    setFileAgree(undefined);
    setValue("serviceTargetInfo.agreeFileName", undefined)
  }

  return (
    <div className="care_person mr20">
      <div className="default_input">
        <h4>가구원 수</h4>
        <div className="write_box">
          <input
            type="number"
            className="essential mr8"
            placeholder="가구원 수"
            style={{ width: 120 }}
            {...register("homeMember", {
              required: "가구원 수를 입력해주세요.",
              onChange: (e:any) => {
                if(!telValid.test(e.target.value)) {
                  e.target.value = String(e.target.value).slice(0, -1)
                }
              }
            })}
          />
          <span>명</span>
        </div>
      </div>
      <div className="default_input mt20">
        <h4>비상연락</h4>
        <div className="write_box">
          <input
            type="text"
            className="mr8 w50_8"
            placeholder="성명"
            {...register("emgcNm"
            // , {
            //   required: "비상연락인 성명을 입력해주세요.",
            //   onChange: (e:any) => {
            //     // nameValidation(e)
            //   }
            // }
            )}
          />
          <select
            className="w50_8"
            style={{ color: data.emgcRel ? "#000" : "#9da0a8" }}
            {...register("emgcRel"
            // , {
            //   required: "비상연락인 관계를 선택해주세요.",
            // }
            )}
          >
            <option value="">관계 선택</option>
            <option>가족</option>
            <option>이웃(지인)</option>
            <option>시설 등 종사자</option>
          </select>
        </div>
      </div>
      <div className="default_input tel mt8">
        <h4 />
        <div className="write_box">
          <select
            style={{ color: data.emgcTel1 ? "#000" : "#9da0a8" }}
            {...register("emgcTel1"
            // , {
            //   required: "비상연락처를 입력해주세요.",
            // }
            )}
          >
            <option value="">선택</option>
            <option>010</option>
            <option>011</option>
            <option>017</option>
            <option>018</option>
            <option>019</option>
          </select>
          <span>-</span>
          <input
            type="tel"
            maxLength={4}
            {...register("emgcTel2", {
              // required: "비상연락처를 입력해주세요.",
              onChange: (e:any) => {
                if(!telValid.test(e.target.value)) {
                  e.target.value = String(e.target.value).slice(0, -1)
                }
                telValidation(e)
              }
            })}
          />
          <span>-</span>
          <input
            type="tel"
            maxLength={4}
            {...register("emgcTel3", {
              // required: "비상연락처를 입력해주세요.",
              onChange: (e:any) => {
                if(!telValid.test(e.target.value)) {
                  e.target.value = String(e.target.value).slice(0, -1)
                }
                telValidation(e)
              }
            })}
          />
        </div>
      </div>
      <div className="default_input mt20">
        <h4>상황</h4>
        <div className="write_box">
          <textarea
            placeholder="현재 어려움을 겪고 있는 상황"
            className="essential"
            {...register("serviceTargetInfo.distress", {
              required: "현재 상황을 입력해주세요.",
            })}
          />
        </div>
      </div>
      <div className="default_input mt20">
        <h4>
          인구학적
          <br />
          특성
        </h4>
        <div className="write_box">
          <label htmlFor="check1-1" className="mr20">
            <input
              type="radio"
              id="check1-1"
              value="영유아"
              {...register(
                "serviceTargetInfo.demographicCharacteristics.type", {
                  required: "인구학적 특성을 선택해주세요."
                }
              )}
            />
            영유아
          </label>
          <label htmlFor="check1-2" className="mr20">
            <input
              type="radio"
              id="check1-2"
              value="아동청소년"
              {...register(
                "serviceTargetInfo.demographicCharacteristics.type", {
                  required: "인구학적 특성을 선택해주세요.",
                  onChange: (e:any) => disabledAddFalse(e)
                }
              )}
            />
            아동·청소년
          </label>
          <label htmlFor="check1-3">
            <input
              type="radio"
              id="check1-3"
              value="1인가구"
              {...register(
                "serviceTargetInfo.demographicCharacteristics.type", {
                  required: "인구학적 특성을 선택해주세요.",
                  onChange: (e:any) => disabledAddFalse(e)
                }
              )}
            />
            1인가구
            <select
              className={`${getValues("serviceTargetInfo.demographicCharacteristics.type") === "1인가구" ? "essential" : ""}`}
              style={{ width: 89, marginLeft: 8, color: getValues("serviceTargetInfo.demographicCharacteristics.type") === "1인가구" ? "black" : "" }}
              {...register(
                "serviceTargetInfo.demographicCharacteristics.singleType"
              )}
              disabled={getValues("serviceTargetInfo.demographicCharacteristics.type") === "1인가구" ? false : true}
            >
              <option value="">1인 유형</option>
              <option value="청년">청년</option>
              <option value="장년">장년</option>
            </select>
          </label>
          <label htmlFor="check1-4" className="w100 mt12">
            <input
              type="radio"
              id="check1-4"
              value="노인"
              {...register(
                "serviceTargetInfo.demographicCharacteristics.type", {
                  required: "인구학적 특성을 선택해주세요.",
                  onChange: (e:any) => disabledAddFalse(e)
                }
              )}
            />
            노인
            <select
              className={`${getValues("serviceTargetInfo.demographicCharacteristics.type") === "노인" ? "essential" : ""}`}
              style={{ width: 275, marginLeft: 21, color: getValues("serviceTargetInfo.demographicCharacteristics.type") === "노인" ? "black" : "" }}
              {...register(
                "serviceTargetInfo.demographicCharacteristics.oldType"
              )}
              disabled={getValues("serviceTargetInfo.demographicCharacteristics.type") === "노인" ? false : true}
            >
              <option value="">장기요양 등급</option>
              <option value="장기요양 등급 없음">장기요양 등급 없음</option>
              <option value="장기요양 1등급">장기요양 1등급</option>
              <option value="장기요양 2등급">장기요양 2등급</option>
              <option value="장기요양 3등급">장기요양 3등급</option>
              <option value="장기요양 4등급">장기요양 4등급</option>
              <option value="장기요양 5등급">장기요양 5등급</option>
              <option value="인지지원등급">인지지원등급</option>
            </select>
          </label>
          <label htmlFor="check1-5" className="w100 mt12">
            <input
              type="radio"
              id="check1-5"
              value="장애인"
              {...register(
                "serviceTargetInfo.demographicCharacteristics.type", {
                  required: "인구학적 특성을 선택해주세요.",
                  onChange: (e:any) => disabledAddFalse(e)
                }
              )}
            />
            장애인
            <select
              style={{ width: "133.5px", marginLeft: 8, color: getValues("serviceTargetInfo.demographicCharacteristics.type") === "장애인" ? "black" : "" }}
              className={`mr8 ${getValues("serviceTargetInfo.demographicCharacteristics.type") === "장애인" ? "essential" : ""}`}
              {...register(
                "serviceTargetInfo.demographicCharacteristics.disabledType"
              )}
              disabled={getValues("serviceTargetInfo.demographicCharacteristics.type") === "장애인" ? false : true}
            >
              <option value="">장애 정도</option>
              <option value="심한 장애">심한 장애</option>
              <option value="심하지 않은 장애">심하지 않은 장애</option>
            </select>
            <input
              type="text"
              className={getValues("serviceTargetInfo.demographicCharacteristics.type") === "장애인" ? "essential" : ""}
              style={{ width: "133.5px", opacity: getValues("serviceTargetInfo.demographicCharacteristics.type") === "장애인" ? "1" : "0.5" }}
              placeholder="장애유형 입력"
              {...register(
                "serviceTargetInfo.demographicCharacteristics.disabledTypeInput"
              )}
              disabled={getValues("serviceTargetInfo.demographicCharacteristics.type") === "장애인" ? false : true}
            />
          </label>
          <label htmlFor="check1-6" className="w100 mt12" style={{opacity: getValues("serviceTargetInfo.demographicCharacteristics.type") ? getValues("serviceTargetInfo.demographicCharacteristics.type") === "장애인" ? "0.5" : "1" : "0.5"}}>
            <input
                type="checkbox"
                id="check1-6"
                {...register(
                  "serviceTargetInfo.demographicCharacteristics.disabledAdd"
                )}
                disabled={getValues("serviceTargetInfo.demographicCharacteristics.type") !== "장애인" &&
                 getValues("serviceTargetInfo.demographicCharacteristics.type") !== null  ? false : true}
            />
            추가장애여부
          </label>
          <select
              style={{ width: "133.5px", marginLeft: 8, opacity: getValues("serviceTargetInfo.demographicCharacteristics.disabledAdd") ? "1" : "0.5",
              color: getValues("serviceTargetInfo.demographicCharacteristics.disabledAdd") ? "black" : "" }}
              className={`mr8 ${getValues("serviceTargetInfo.demographicCharacteristics.disabledAdd") ? "essential" : ""}`}
              {...register(
                "serviceTargetInfo.demographicCharacteristics.disabledAddType"
              )}
              disabled={getValues("serviceTargetInfo.demographicCharacteristics.type") ? getValues("serviceTargetInfo.demographicCharacteristics.disabledAdd") ? false : true : true}
            >
              <option value="">장애 정도</option>
              <option value="심한 장애">심한 장애</option>
              <option value="심하지 않은 장애">심하지 않은 장애</option>
          </select>
          <input
              type="text"
              className={getValues("serviceTargetInfo.demographicCharacteristics.disabledAdd") ? "essential" : ""}
              style={{ width: "133.5px", opacity: getValues("serviceTargetInfo.demographicCharacteristics.disabledAdd") ? "1" : "0.5" }}
              placeholder="장애유형 입력"
              {...register(
                "serviceTargetInfo.demographicCharacteristics.disabledTypeAddInput"
              )}
              disabled={getValues("serviceTargetInfo.demographicCharacteristics.disabledAdd") ? false : true}
          />
        </div>
      </div>
      <div className="default_input mt20">
        <h4>소득수준</h4>
        <div className="write_box">
          <select className="essential selectShow" {...register("incomeLevel", {
            required: "소득수준을 선택해주세요."
          })}>
            <option value="">선택</option>
            <option value="85이하">기준중위소득 85% 이하</option>
            <option value="100이하">기준중위소득 85% 초과 ~ 100% 이하</option>
            <option value="100초과">기준중위소득 100% 초과</option>
          </select>
          <ul
            className="selectUl"
            style={{
              display: data.incomeLevel === "85이하" ? "" : "none",
            }}
          >
            <li>
              <label htmlFor="radio1-1" className="mr20">
                <input
                  type="radio"
                  id="radio1-1"
                  value="수급자"
                  {...register("incomeLevel2")}
                />
                수급자
              </label>
            </li>
            <li>
              <label htmlFor="radio1-2" className="mr20">
                <input
                  type="radio"
                  id="radio1-2"
                  value="차상위"
                  {...register("incomeLevel2")}
                />
                차상위
              </label>
            </li>
            <li>
              <label htmlFor="radio1-3">
                <input
                  type="radio"
                  id="radio1-3"
                  value="85이하"
                  {...register("incomeLevel2")}
                />
                기준중위소득 85% 이하
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div className="default_input mt20">
        <h4>
          이용중인
          <br />
          기존돌봄
        </h4>
        <div className="write_box">
          <label htmlFor="check2-1" className="mr20">
            <input
              type="checkbox"
              id="check2-1"
              {...register("serviceTargetInfo.usingServices.babysitting")}
            />
            아이돌봄
          </label>
          <label htmlFor="check2-2" className="mr20">
            <input
              type="checkbox"
              id="check2-2"
              {...register(
                "serviceTargetInfo.usingServices.longTermCareInsuranceForElderly"
              )}
            />
            노인장기요양보험
          </label>
          <label htmlFor="check2-3">
            <input
              type="checkbox"
              id="check2-3"
              {...register(
                "serviceTargetInfo.usingServices.customizedElderlyCare"
              )}
            />
            노인맞춤돌봄
          </label>
          <label htmlFor="check2-4" className="mr20 mt12">
            <input
              type="checkbox"
              id="check2-4"
              {...register(
                "serviceTargetInfo.usingServices.supportForDisabledActivities"
              )}
            />
            장애인활동지원
          </label>
          <label htmlFor="check2-5" className="mt12">
            <input
              type="checkbox"
              id="check2-5"
              {...register("serviceTargetInfo.usingServices.others", {
                onChange: (e:any) => {
                  if(!e.target.checked) {
                    setValue("serviceTargetInfo.usingServices.othersInput", "")
                  }
                }
              })}
            />
            기타
            <input
              type="text"
              placeholder="기타 입력"
              style={{ width: 162, marginLeft: 8 }}
              disabled={getValues("serviceTargetInfo.usingServices.others") ? false : true}
              {...register("serviceTargetInfo.usingServices.othersInput")}
            />
          </label>
        </div>
      </div>
      <div className="default_input mt20">
        <h4>
          대상자
          <br />
          욕구
        </h4>
        <div className="write_box">
          <div style={{ display: "flex", width: "100%" }}>
            <label htmlFor="check3-1" style={{ width: 120 }}>
              <input
                type="checkbox"
                id="check3-1"
                {...register("serviceTargetInfo.needs.integrated")}
              />
              <span>기존돌봄 연계</span>
            </label>
            <Controller
              control={control}
              name="serviceTargetInfo.needs.integratedType"
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  options={[
                    { label: "아이돌봄", value: "아이돌봄" },
                    { label: "노인장기요양보험", value: "노인장기요양보험" },
                    { label: "노인맞춤돌봄", value: "노인맞춤돌봄" },
                    { label: "장애인활동지원", value: "장애인활동지원" },
                  ]}
                  value={value ? value?.length >= 2 ? value?.map((v) => ({ label: v.slice(0,3) + "…", value: v })) ?? [] : value?.map((v) => ({ label: v, value: v })) ?? []
                  : []
                  }
                  onChange={(event: { label: string; value: string }[]) =>
                    onChange(event.map((e) => e.value))
                  }
                  labelledBy=""
                  disableSearch
                  hasSelectAll={false}
                  overrideStrings={{
                    selectSomeItems: "다중선택",
                    allItemsAreSelected: "모두선택",
                  }}
                />
              )}
            />
          </div>
          <input
            type="text"
            className="mt12"
            placeholder="사유 입력"
            disabled={getValues("serviceTargetInfo.needs.integrated") ? false : true}
            {...register("serviceTargetInfo.needs.integratedReason")}
          />
          <div
            className="mt12"
            style={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <label htmlFor="check3-2" style={{ width: 120 }}>
              <input
                type="checkbox"
                id="check3-2"
                {...register("serviceTargetInfo.needs.gwangjuCare")}
              />
              <span>광주+돌봄 지원</span>
            </label>
            <Controller
              control={control}
              name="serviceTargetInfo.needs.gwangjuCareType"
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  options={[
                    { label: "가사지원", value: "가사지원" },
                    { label: "식사지원", value: "식사지원" },
                    { label: "동행지원", value: "동행지원" },
                    { label: "건강지원", value: "건강지원" },
                    { label: "안전지원", value: "안전지원" },
                    { label: "주거편의", value: "주거편의" },
                    { label: "일시보호", value: "일시보호" },
                  ]}
                  value={value ? value?.length >= 6 ? value?.map((v) => ({ label: v.slice(0,1) + "..", value: v })) ?? [] : value?.length >= 3 ? value?.map((v) => ({ label: v.slice(0,2), value: v })) ?? [] : value?.map((v) => ({ label: v, value: v })) ?? []
                  : []
                  }
                  onChange={(event: { label: string; value: string }[]) =>
                    onChange(event.map((e) => e.value))
                  }
                  labelledBy=""
                  disableSearch
                  hasSelectAll={false}
                  overrideStrings={{
                    selectSomeItems: "다중선택",
                    allItemsAreSelected: "모두선택",
                  }}
                />
              )}
            />
          </div>
          <label htmlFor="check3-3" className="mt12">
            <input
              type="checkbox"
              id="check3-3"
              {...register("serviceTargetInfo.needs.emergencyCare")}
            />
            <span>긴급돌봄 지원</span>
          </label>
        </div>
      </div>
      <div className="default_input mt20">
        <h4>신청서</h4>
          <div className="write_box" style={{alignItems: "center"}}>
              <label htmlFor="img_register1" className="btn_file">
                  <input type="file" id="img_register1" style={{display: "none"}} onChange={handleApplyFile} accept="image/*" />
                  파일첨부
              </label>
              <div className="file_list">
                  <ul>
                      <li>
                          <p>{getValues("serviceTargetInfo.applicnFileName") ? getValues("serviceTargetInfo.applicnFileName") : "(파일 없음)"}</p>
                          {urlApply ? <img src={urlApply} style={{width: "60%", height: "60%"}}/> : null}
                          <button type="button"><img src={CloseIcon} alt="닫기" onClick={delApllyFile}/></button>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <div className="default_input mt20">
        <h4>동의서</h4>
          <div className="write_box" style={{alignItems: "center"}}>
              <label htmlFor="img_register2" className="btn_file">
                  <input type="file" id="img_register2" style={{display: "none"}} onChange={handleAgreeFile} accept="image/*" />
                  파일첨부
              </label>
              <div className="file_list">
                  <ul>
                      <li>
                          <p>{getValues("serviceTargetInfo.agreeFileName") ? getValues("serviceTargetInfo.agreeFileName") : "(파일 없음)"}</p>
                          {urlAgree ? <img src={urlAgree} style={{width: "60%", height: "60%"}}/> : null}
                          <button type="button"><img src={CloseIcon} alt="닫기" onClick={delAgreeFile}/></button>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
    </div>
  );
};

const MiddlePanel = ({
  register,
  bodyList,
  midBodyPt,
  emtList,
  midEmtPt,
  getValues,
  setValue,
}: {
  register: UseFormRegister<NeedsAssessmentFormInput>;
  bodyList: number[] | null[];
  midBodyPt: Function;
  emtList: number[] | null[];
  midEmtPt: Function;
  getValues: UseFormGetValues<NeedsAssessmentFormInput>;
  setValue: UseFormSetValue<NeedsAssessmentFormInput>;
}) => {
  let list = bodyList;
  let list2 = emtList;
  const [tab, setTab] = useState(0);
  const [addScore, setAddScore] = useState(0);
  const bodyLoadChange = (val : string , idx: number) => {
    switch (val) {
      case "완전 자립":
        list[idx] = 0;
        midBodyPt(list, addScore);
        break;
      case "부분 도움 필요":
        list[idx] = 3;
        midBodyPt(list, addScore);
        break;
      case "완전 도움 필요":
        list[idx] = 5;
        midBodyPt(list, addScore);
        break;
      default:
        list[idx] = 0;
        midBodyPt(list, addScore);
        break;
    }
  }
  const illLoadChange = (val : any , idx: number) => {
    // console.log(val)
    switch (val) {
      case false:
        list[idx] = 0;
        midBodyPt(list, addScore);
        break;
      case undefined:
        list[idx] = 0;
        midBodyPt(list, addScore);
        break;
      default:
        list[idx] = 5;
        midBodyPt(list, addScore);
        break;
    }
  }
  const emtLoadChange = (val : string , idx: number) => {
    switch (val) {
      case "그렇지 않다":
        list2[idx] = 0;
        midEmtPt(list2);
        break;
      case "그렇다":
        list2[idx] = 10;
        midEmtPt(list2);
        break;
      default:
        list2[idx] = 0;
        midEmtPt(list2);
        break;
    }
  }
  const onBodyChange = (e: any, idx: number) => {
    switch (e.target.value) {
      case "완전 자립":
        list[idx] = 0;
        midBodyPt(list, addScore);
        break;
      case "부분 도움 필요":
        list[idx] = 3;
        midBodyPt(list, addScore);
        break;
      case "완전 도움 필요":
        list[idx] = 5;
        midBodyPt(list, addScore);
        break;
      default:
        list[idx] = 0;
        midBodyPt(list, addScore);
        break;
    }
  };
  const onIllChange = (e: any, idx: number) => {
    // console.log("e.target.checked : ", e.target.checked);
    switch (e.target.checked) {
      case false:
        list[idx] = 0;
        midBodyPt(list, addScore);
        break;
      case true:
        list[idx] = 5;
        midBodyPt(list, addScore);
        break;
      default:
        list[idx] = 0;
        midBodyPt(list, addScore);
        break;
    }
  };

  const onIllAddScore = (illList: IllItem[]) => {
    midBodyPt(list);
  }

  const onEmtChange = (e: any, idx: number) => {
    switch (e.target.value) {
      case "그렇지 않다":
        list2[idx] = 0;
        midEmtPt(list2);
        break;
      case "그렇다":
        list2[idx] = 10;
        midEmtPt(list2);
        break;
      default:
        list2[idx] = 0;
        midEmtPt(list2);
        break;
    }
  };

  const [illList, setIllList] = useState([] as IllItem[]);
  const [addItem, setAddItem] = useState(false)
  const [itemName, setItemName] = useState("")

  const valueSetting = (illList : IllItem[]) => {
    let itemListString:string[] = []
    illList.map((item) => {
      itemListString.push(item.value)
    })
    setValue("json.illAdd", JSON.stringify(itemListString))
  }

  const handleAddIll = (e:any) => {
    e.preventDefault()
    setItemName("")
    setAddItem(!addItem)
  }

  const handleAddItem = (e:any, itemName: string) => {
    e.preventDefault()
    let tempList = illList
    setAddItem(!addItem)
    let ill:IllItem = {key : Date.now(), value: itemName}
    tempList.push(ill)
    setIllList(tempList)
    valueSetting(tempList)
    onIllAddScore(tempList)
  }

  const handleRemoveItem = (e:any, key: number) => {
    e.preventDefault()
    let tempList = illList.filter((item) => item.key !== key)
    setIllList(tempList)
    valueSetting(tempList)
    onIllAddScore(tempList)
  }

  const onNaLoad = () => {
    if (getValues("json.illAdd")) {
      let illNameList: string[] = JSON.parse(getValues("json.illAdd")) as string[]
      let addList: IllItem[] = []
      if (illNameList) {
        illNameList.map((item, idx) => {
          addList.push({key: idx, value: item})
        })
        console.log(addList)
        setIllList(addList)
        onIllAddScore(addList)
      }
    }
    
    
    bodyLoadChange(getValues("json.q1_1"), 0)
    bodyLoadChange(getValues("json.q1_2"), 1)
    bodyLoadChange(getValues("json.q1_3"), 2)
    bodyLoadChange(getValues("json.q1_4"), 3)
    bodyLoadChange(getValues("json.q1_5"), 4)
    bodyLoadChange(getValues("json.q1_6"), 5)
    bodyLoadChange(getValues("json.q1_7"), 6)
    bodyLoadChange(getValues("json.q1_1"), 7)
    bodyLoadChange(getValues("json.q2_1"), 8)
    bodyLoadChange(getValues("json.q2_2"), 9)
    bodyLoadChange(getValues("json.q2_3"), 10)
    bodyLoadChange(getValues("json.q2_4"), 11)
    bodyLoadChange(getValues("json.q2_5"), 12)
    bodyLoadChange(getValues("json.q2_6"), 13)
    illLoadChange(getValues("json.q3_1"), 14)
    illLoadChange(getValues("json.q3_2"), 15)
    illLoadChange(getValues("json.q3_3"), 16)
    illLoadChange(getValues("json.q3_4"), 17)
    illLoadChange(getValues("json.q3_5"), 18)
    illLoadChange(getValues("json.q3_6"), 19)
    illLoadChange(getValues("json.q3_8"), 20)
    emtLoadChange(getValues("json.q4_1"), 0)
    emtLoadChange(getValues("json.q4_2"), 1)
    emtLoadChange(getValues("json.q4_3"), 2)
    emtLoadChange(getValues("json.q4_4"), 3)
    emtLoadChange(getValues("json.q4_5"), 4)
    emtLoadChange(getValues("json.q5_1"), 5)
    emtLoadChange(getValues("json.q5_2"), 6)
    emtLoadChange(getValues("json.q5_3"), 7)
    emtLoadChange(getValues("json.q5_4"), 8)
    emtLoadChange(getValues("json.q5_5"), 9)
  }
  useEffect(() => {
    onNaLoad()
  }, [])

  return (
    <div className="multiple_choice mr20">
      {/* 탭 */}
      <div className="tabs">
        <button
          type="button"
          className={`tab ${tab === 0 ? "tab_active" : "non_active"}`}
          onClick={() => setTab(0)}
          data-id={0}
        >
          신체적 영역
        </button>
        <button
          type="button"
          className={`tab ${tab === 1 ? "tab_active" : "non_active"}`}
          onClick={() => setTab(1)}
          data-id={1}
        >
          정서적 영역
        </button>
        <button
          type="button"
          className={`tab ${tab === 2 ? "tab_active" : "non_active"}`}
          onClick={() => setTab(2)}
          data-id={2}
        >
          추가정보(생활환경)
        </button>
        <button
          type="button"
          className={`tab ${tab === 3 ? "tab_active" : "non_active"}`}
          onClick={() => setTab(3)}
          data-id={3}
        >
          추가정보(사전검사)
        </button>
      </div>
      {/* 신체적 영역 */}
      {tab === 0 && (
        <div className="tab_content" data-id={0}>
          <div className="choice_content">
            <h4>기본적 일상생활 자립 정도</h4>
            <ul>
              <li>
                <p>세수, 양치질 하기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check11-1"
                      value="완전 자립"
                      {...register("json.q1_1", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 0),
                      })}
                    />
                    <label htmlFor="check11-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check11-2"
                      value="부분 도움 필요"
                      {...register("json.q1_1", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 0),
                      })}
                    />
                    <label htmlFor="check11-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check11-3"
                      value="완전 도움 필요"
                      {...register("json.q1_1", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 0),
                      })}
                    />
                    <label htmlFor="check11-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>머리감기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check12-1"
                      value="완전 자립"
                      {...register("json.q1_2", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 1),
                      })}
                    />
                    <label htmlFor="check12-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check12-2"
                      value="부분 도움 필요"
                      {...register("json.q1_2", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 1),
                      })}
                    />
                    <label htmlFor="check12-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check12-3"
                      value="완전 도움 필요"
                      {...register("json.q1_2", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 1),
                      })}
                    />
                    <label htmlFor="check12-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>목욕하기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check13-1"
                      value="완전 자립"
                      {...register("json.q1_3", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 2),
                      })}
                    />
                    <label htmlFor="check13-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check13-2"
                      value="부분 도움 필요"
                      {...register("json.q1_3", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 2),
                      })}
                    />
                    <label htmlFor="check13-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check13-3"
                      value="완전 도움 필요"
                      {...register("json.q1_3", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 2),
                      })}
                    />
                    <label htmlFor="check13-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>옷 벗고 입기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check14-1"
                      value="완전 자립"
                      {...register("json.q1_4", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 3),
                      })}
                    />
                    <label htmlFor="check14-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check14-2"
                      value="부분 도움 필요"
                      {...register("json.q1_4", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 3),
                      })}
                    />
                    <label htmlFor="check14-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check14-3"
                      value="완전 도움 필요"
                      {...register("json.q1_4", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 3),
                      })}
                    />
                    <label htmlFor="check14-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>식사하기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check15-1"
                      value="완전 자립"
                      {...register("json.q1_5", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 4),
                      })}
                    />
                    <label htmlFor="check15-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check15-2"
                      value="부분 도움 필요"
                      {...register("json.q1_5", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 4),
                      })}
                    />
                    <label htmlFor="check15-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check15-3"
                      value="완전 도움 필요"
                      {...register("json.q1_5", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 4),
                      })}
                    />
                    <label htmlFor="check15-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>체위변경(일어나 앉기 등)</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check16-1"
                      value="완전 자립"
                      {...register("json.q1_6", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 5),
                      })}
                    />
                    <label htmlFor="check16-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check16-2"
                      value="부분 도움 필요"
                      {...register("json.q1_6", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 5),
                      })}
                    />
                    <label htmlFor="check16-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check16-3"
                      value="완전 도움 필요"
                      {...register("json.q1_6", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 5),
                      })}
                    />
                    <label htmlFor="check16-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>집안에서 이동하기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check17-1"
                      value="완전 자립"
                      {...register("json.q1_7", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 6),
                      })}
                    />
                    <label htmlFor="check17-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check17-2"
                      value="부분 도움 필요"
                      {...register("json.q1_7", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 6),
                      })}
                    />
                    <label htmlFor="check17-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check17-3"
                      value="완전 도움 필요"
                      {...register("json.q1_7", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 6),
                      })}
                    />
                    <label htmlFor="check17-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>화장실 사용하기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check18-1"
                      value="완전 자립"
                      {...register("json.q1_8", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 7),
                      })}
                    />
                    <label htmlFor="check18-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check18-2"
                      value="부분 도움 필요"
                      {...register("json.q1_8", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 7),
                      })}
                    />
                    <label htmlFor="check18-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check18-3"
                      value="완전 도움 필요"
                      {...register("json.q1_8", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 7),
                      })}
                    />
                    <label htmlFor="check18-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="choice_content mt20">
            <h4>사회적 일상생활 자립 정도</h4>
            <ul>
              <li>
                <p>식사 준비하기(음식 조리)</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check21-1"
                      value="완전 자립"
                      {...register("json.q2_1", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 8),
                      })}
                    />
                    <label htmlFor="check21-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check21-2"
                      value="부분 도움 필요"
                      {...register("json.q2_1", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 8),
                      })}
                    />
                    <label htmlFor="check21-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check21-3"
                      value="완전 도움 필요"
                      {...register("json.q2_1", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 8),
                      })}
                    />
                    <label htmlFor="check21-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>세탁, 빨래하기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check22-1"
                      value="완전 자립"
                      {...register("json.q2_2", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 9),
                      })}
                    />
                    <label htmlFor="check22-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check22-2"
                      value="부분 도움 필요"
                      {...register("json.q2_2", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 9),
                      })}
                    />
                    <label htmlFor="check22-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check22-3"
                      value="완전 도움 필요"
                      {...register("json.q2_2", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 9),
                      })}
                    />
                    <label htmlFor="check22-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>청소하기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check23-1"
                      value="완전 자립"
                      {...register("json.q2_3", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 10),
                      })}
                    />
                    <label htmlFor="check23-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check23-2"
                      value="부분 도움 필요"
                      {...register("json.q2_3", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 10),
                      })}
                    />
                    <label htmlFor="check23-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check23-3"
                      value="완전 도움 필요"
                      {...register("json.q2_3", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 10),
                      })}
                    />
                    <label htmlFor="check23-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>장보기, 물건사기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check24-1"
                      value="완전 자립"
                      {...register("json.q2_4", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 11),
                      })}
                    />
                    <label htmlFor="check24-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check24-2"
                      value="부분 도움 필요"
                      {...register("json.q2_4", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 11),
                      })}
                    />
                    <label htmlFor="check24-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check24-3"
                      value="완전 도움 필요"
                      {...register("json.q2_4", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 11),
                      })}
                    />
                    <label htmlFor="check24-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>근거리 외출하기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check25-1"
                      value="완전 자립"
                      {...register("json.q2_5", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 12),
                      })}
                    />
                    <label htmlFor="check25-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check25-2"
                      value="부분 도움 필요"
                      {...register("json.q2_5", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 12),
                      })}
                    />
                    <label htmlFor="check25-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check25-3"
                      value="완전 도움 필요"
                      {...register("json.q2_5", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 12),
                      })}
                    />
                    <label htmlFor="check25-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
              <li>
                <p>교통수단 이용하기</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check26-1"
                      value="완전 자립"
                      {...register("json.q2_6", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 13),
                      })}
                    />
                    <label htmlFor="check26-1">완전자립</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check26-2"
                      value="부분 도움 필요"
                      {...register("json.q2_6", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 13),
                      })}
                    />
                    <label htmlFor="check26-2">부분 도움 필요</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check26-3"
                      value="완전 도움 필요"
                      {...register("json.q2_6", {
                        // required: "신체적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onBodyChange(e, 13),
                      })}
                    />
                    <label htmlFor="check26-3">완전 도움 필요</label>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="choice_content mt20">
            <h4>질병 및 증상(있을 시 체크)
              <button className={addItem ? "btn_gray" : "btn_blue"} style={{marginLeft: "400px"}}
              onClick= {(e) => handleAddIll(e)}
              >
                {addItem ? "취소" : "추가"}
            </button>
            </h4>
            <ul className="half_check">
              <li>
                <label htmlFor="check31-1">뇌경색</label>
                <input
                  type="checkbox"
                  id="check31-1"
                  value="뇌경색"
                  {...register("json.q3_1", {
                    onChange: (e) => onIllChange(e, 14),
                  })}
                />
              </li>
              <li>
                <label htmlFor="check31-2">시력저하</label>
                <input
                  type="checkbox"
                  id="check31-2"
                  value="시력저하"
                  {...register("json.q3_2", {
                    onChange: (e) => onIllChange(e, 15),
                  })}
                />
              </li>
              <li>
                <label htmlFor="check31-3">요통, 관절염</label>
                <input
                  type="checkbox"
                  id="check31-3"
                  value="요통, 관절염"
                  {...register("json.q3_3", {
                    onChange: (e) => onIllChange(e, 16),
                  })}
                />
              </li>
              <li>
                <label htmlFor="check31-4">청력저하</label>
                <input
                  type="checkbox"
                  id="check31-4"
                  value="청력저하"
                  {...register("json.q3_4", {
                    onChange: (e) => onIllChange(e, 17),
                  })}
                />
              </li>
              <li>
                <label htmlFor="check31-5">고혈압, 당뇨</label>
                <input
                  type="checkbox"
                  id="check31-5"
                  value="고혈압, 당뇨"
                  {...register("json.q3_5", {
                    onChange: (e) => onIllChange(e, 18),
                  })}
                />
              </li>
              <li>
                <label htmlFor="check31-6">치매</label>
                <input
                  type="checkbox"
                  id="check31-6"
                  value="치매"
                  {...register("json.q3_6", {
                    onChange: (e) => onIllChange(e, 19),
                  })}
                />
              </li>
              {illList.length !== 0 ? 
              illList.map((item) => (
                <AddedItem item={item} handleRemoveItem={handleRemoveItem} />
              ))
              :
              null
              }
              {addItem ? <AddIllItem itemName={itemName} setItemName={setItemName} handleAddItem={handleAddItem} /> : null}
              {/* <li style={{ height: "auto", padding: "10px 15px" }}>
                <label htmlFor="check31-7">
                  <input
                    type="text"
                    placeholder="기타 입력"
                    style={{ width: "180px" }}
                    {...register("json.q3_7")}
                  />
                </label>
                <input
                  type="checkbox"
                  id="check31-8"
                  {...register("json.q3_8", {
                    onChange: (e) => onIllChange(e, 20),
                  })}
                />
              </li> */}
            </ul>
          </div>
        </div>
      )}
      {/* 정서적 영역 */}
      {tab === 1 && (
        <div className="tab_content" data-id="그렇지 않다">
          <div className="choice_content two_check">
            <h4>정신건강 수준</h4>
            <ul>
              <li>
                <p>우울감과 불행감을 느낀 적이 많다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check41-1"
                      value="그렇지 않다"
                      {...register("json.q4_1", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 0),
                      })}
                    />
                    <label htmlFor="check41-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check41-2"
                      value="그렇다"
                      {...register("json.q4_1", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 0),
                      })}
                    />
                    <label htmlFor="check41-2">그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>어려움을 극복할 수 없다고 느낀 적이 많다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check42-1"
                      value="그렇지 않다"
                      {...register("json.q4_2", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 1),
                      })}
                    />
                    <label htmlFor="check42-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check42-2"
                      value="그렇다"
                      {...register("json.q4_2", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 1),
                      })}
                    />
                    <label htmlFor="check42-2">그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>걱정으로 잠을 잘 못 이루거나 설치는 적이 많다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check43-1"
                      value="그렇지 않다"
                      {...register("json.q4_3", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 2),
                      })}
                    />
                    <label htmlFor="check43-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check43-2"
                      value="그렇다"
                      {...register("json.q4_3", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 2),
                      })}
                    />
                    <label htmlFor="check43-2">그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>미래에 대한 희망이 없다고 느낀 적이 많다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check44-1"
                      value="그렇지 않다"
                      {...register("json.q4_4", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 3),
                      })}
                    />
                    <label htmlFor="check44-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check44-2"
                      value="그렇다"
                      {...register("json.q4_4", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 3),
                      })}
                    />
                    <label htmlFor="check44-2">그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>자신감이 없어지고 내가 쓸모없게 느껴진다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check45-1"
                      value="그렇지 않다"
                      {...register("json.q4_5", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 4),
                      })}
                    />
                    <label htmlFor="check45-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check45-2"
                      value="그렇다"
                      {...register("json.q4_5", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 4),
                      })}
                    />
                    <label htmlFor="check45-2">그렇다</label>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="choice_content mt20 two_check">
            <h4>고립, 은둔 수준</h4>
            <ul>
              <li>
                <p>하루 종일 혼자 있다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check51-1"
                      value="그렇지 않다"
                      {...register("json.q5_1", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 5),
                      })}
                    />
                    <label htmlFor="check51-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check51-2"
                      value="그렇다"
                      {...register("json.q5_1", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 5),
                      })}
                    />
                    <label htmlFor="check51-2">그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>대부분 자신의 방이나 집에만 머무른다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check52-1"
                      value="그렇지 않다"
                      {...register("json.q5_2", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 6),
                      })}
                    />
                    <label htmlFor="check52-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check52-2"
                      value="그렇다"
                      {...register("json.q5_2", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 6),
                      })}
                    />
                    <label htmlFor="check52-2">그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>가끔 외출을 하더라도 대인관계를 하지 않는다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check53-1"
                      value="그렇지 않다"
                      {...register("json.q5_3", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 7),
                      })}
                    />
                    <label htmlFor="check53-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check53-2"
                      value="그렇다"
                      {...register("json.q5_3", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 7),
                      })}
                    />
                    <label htmlFor="check53-2">그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>일상적 어려움을 항시 의논할 사람이 없다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check54-1"
                      value="그렇지 않다"
                      {...register("json.q5_4", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 8),
                      })}
                    />
                    <label htmlFor="check54-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check54-2"
                      value="그렇다"
                      {...register("json.q5_4", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 8),
                      })}
                    />
                    <label htmlFor="check54-2">그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>일상적 어려움을 실제 도와줄 사람이 없다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check55-1"
                      value="그렇지 않다"
                      {...register("json.q5_5", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 9),
                      })}
                    />
                    <label htmlFor="check55-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check55-2"
                      value="그렇다"
                      {...register("json.q5_5", {
                        // required: "정서적 영역 항목을 모두 선택해주세요.",
                        onChange: (e) => onEmtChange(e, 9),
                      })}
                    />
                    <label htmlFor="check55-2">그렇다</label>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
      {/* 추가정보(생활환경) */}
      {tab === 2 && (
        <div className="tab_content" data-id="그렇다">
          <div className="choice_content two_check">
            <h4>식사 및 영양 상태</h4>
            <ul>
              <li>
                <p>하루에 1끼 이하 불규칙적으로 식사한다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check61-1"
                      value="그렇지 않다"
                      {...register("json.q6_1", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check61-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check61-2"
                      value="그렇다"
                      {...register("json.q6_1", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check61-2">그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>건강과 영양을 고려한 좋은 음식을 먹기 어렵다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check62-1"
                      value="그렇지 않다"
                      {...register("json.q6_2", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check62-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check62-2"
                      value="그렇다"
                      {...register("json.q6_2", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check62-2">그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>거의 매번 혼자서 식사하는 편이다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check63-1"
                      value="그렇지 않다"
                      {...register("json.q6_3", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check63-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check63-2"
                      value="그렇다"
                      {...register("json.q6_3", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check63-2">그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>음식을 씹거나 삼키는 것이 힘들다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check64-1"
                      value="그렇지 않다"
                      {...register("json.q6_4", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check64-1">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check64-2"
                      value="그렇다"
                      {...register("json.q6_4", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check64-2">그렇다</label>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="choice_content mt20 two_check">
            <h4>주거 환경</h4>
            <ul>
              <li>
                <p>방바닥 미끄럼</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check71-1"
                      value="양호"
                      {...register("json.q7_1", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check71-1">양호</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check71-2"
                      value="불량/없음"
                      {...register("json.q7_1", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check71-2">불량/없음</label>
                  </span>
                </div>
              </li>
              <li>
                <p>욕실바닥 미끄럼</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check72-1"
                      value="양호"
                      {...register("json.q7_2", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check72-1">양호</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check72-2"
                      value="불량/없음"
                      {...register("json.q7_2", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check72-2">불량/없음</label>
                  </span>
                </div>
              </li>
              <li>
                <p>문턱 단차</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check73-1"
                      value="양호"
                      {...register("json.q7_3", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check73-1">양호</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check73-2"
                      value="불량/없음"
                      {...register("json.q7_3", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check73-2">불량/없음</label>
                  </span>
                </div>
              </li>
              <li>
                <p>변기 등 안전손잡이</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check74-1"
                      value="양호"
                      {...register("json.q7_4", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check74-1">양호</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check74-2"
                      value="불량/없음"
                      {...register("json.q7_4", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check74-2">불량/없음</label>
                  </span>
                </div>
              </li>
              <li>
                <p>주방 가스 기구</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check75-1"
                      value="양호"
                      {...register("json.q7_5", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check75-1">양호</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check75-2"
                      value="불량/없음"
                      {...register("json.q7_5", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check75-2">불량/없음</label>
                  </span>
                </div>
              </li>
              <li>
                <p>청결한 주거환경(대청소 필요성)</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check76-1"
                      value="양호"
                      {...register("json.q7_6", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check76-1">양호</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check76-2"
                      value="불량/없음"
                      {...register("json.q7_6", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check76-2">불량/없음</label>
                  </span>
                </div>
              </li>
              <li>
                <p>바이러스·해충 위험(방역·방충 필요성)</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check77-1"
                      value="양호"
                      {...register("json.q7_7", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check77-1">양호</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check77-2"
                      value="불량/없음"
                      {...register("json.q7_7", {
                        // required: "추가정보(생활환경) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check77-2">불량/없음</label>
                  </span>
                </div>
              </li>
              <li style={{ height: "auto", padding: "10px 15px" }}>
                <p>기타 간단 수리가 필요한 항목</p>
                <input
                  type="text"
                  placeholder="항목 입력"
                  className="mt8"
                  {...register("json.q7_8")}
                />
              </li>
            </ul>
          </div>
        </div>
      )}
      {tab === 3 && (
        <div className="tab_content" data-id="그렇다">
          <div className="choice_content five_check">
            <h4>귀하의 생각과 가장 유사한 곳에 응답해주세요 - 지난 1주일동안 나는(또는 가족은)</h4>
            <ul>
              <li>
                <p>식사, 가사, 외출 등 일상생활 수행하는데 어려움을 경험한 적이 있다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check81-1"
                      value="전혀 그렇지 않다"
                      {...register("json.q8_1", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check81-1">전혀 그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check81-2"
                      value="그렇지 않다"
                      {...register("json.q8_1", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check81-2">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check81-3"
                      value="보통이다"
                      {...register("json.q8_1", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check81-3">보통이다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check81-4"
                      value="그렇다"
                      {...register("json.q8_1", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check81-4">그렇다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check81-5"
                      value="매우 그렇다"
                      {...register("json.q8_1", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check81-5">매우 그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>나 자신 또는 가족을 돌보는 것이 부담스러운 적이 있다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check82-1"
                      value="전혀 그렇지 않다"
                      {...register("json.q8_2", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check82-1">전혀 그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check82-2"
                      value="그렇지 않다"
                      {...register("json.q8_2", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check82-2">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check82-3"
                      value="보통이다"
                      {...register("json.q8_2", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check82-3">보통이다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check82-4"
                      value="그렇다"
                      {...register("json.q8_2", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check82-4">그렇다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check82-5"
                      value="매우 그렇다"
                      {...register("json.q8_2", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check82-5">매우 그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>몸이 아프거나 불편한 적이 있다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check83-1"
                      value="전혀 그렇지 않다"
                      {...register("json.q8_3", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check83-1">전혀 그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check83-2"
                      value="그렇지 않다"
                      {...register("json.q8_3", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check83-2">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check83-3"
                      value="보통이다"
                      {...register("json.q8_3", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check83-3">보통이다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check83-4"
                      value="그렇다"
                      {...register("json.q8_3", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check83-4">그렇다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check83-5"
                      value="매우 그렇다"
                      {...register("json.q8_3", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check83-5">매우 그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>우울하다는 생각을 한 적이 있다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check84-1"
                      value="전혀 그렇지 않다"
                      {...register("json.q8_4", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check84-1">전혀 그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check84-2"
                      value="그렇지 않다"
                      {...register("json.q8_4", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check84-2">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check84-3"
                      value="보통이다"
                      {...register("json.q8_4", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check84-3">보통이다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check84-4"
                      value="그렇다"
                      {...register("json.q8_4", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check84-4">그렇다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check84-5"
                      value="매우 그렇다"
                      {...register("json.q8_4", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check84-5">매우 그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>힘들고 어려울 때 지역사회(주변사람, 광주광역시, 기관 등)로 부터 도움을 받을 수 있다고 생각한다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check85-1"
                      value="전혀 그렇지 않다"
                      {...register("json.q8_5", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check85-1">전혀 그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check85-2"
                      value="그렇지 않다"
                      {...register("json.q8_5", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check85-2">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check85-3"
                      value="보통이다"
                      {...register("json.q8_5", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check85-3">보통이다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check85-4"
                      value="그렇다"
                      {...register("json.q8_5", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check85-4">그렇다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check85-5"
                      value="매우 그렇다"
                      {...register("json.q8_5", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check85-5">매우 그렇다</label>
                  </span>
                </div>
              </li>
              <li>
                <p>지금까지 나의 삶에 대해 만족한다.</p>
                <div className="check_area">
                  <span>
                    <input
                      type="radio"
                      id="check86-1"
                      value="전혀 그렇지 않다"
                      {...register("json.q8_6", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check86-1">전혀 그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check86-2"
                      value="그렇지 않다"
                      {...register("json.q8_6", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check86-2">그렇지 않다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check86-3"
                      value="보통이다"
                      {...register("json.q8_6", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check86-3">보통이다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check86-4"
                      value="그렇다"
                      {...register("json.q8_6", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check86-4">그렇다</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="check86-5"
                      value="매우 그렇다"
                      {...register("json.q8_6", {
                        // required: "추가정보(사전검사) 항목을 모두 선택해주세요.",
                      })}
                    />
                    <label htmlFor="check86-5">매우 그렇다</label>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

const RightPanel = ({
  register,
  setValue,
  getValues,
  bodyPt,
  emtPt,
}: {
  register: UseFormRegister<NeedsAssessmentFormInput>;
  setValue: UseFormSetValue<NeedsAssessmentFormInput>;
  getValues: UseFormGetValues<NeedsAssessmentFormInput>;
  bodyPt: number;
  emtPt: number;
}) => {
  const [tab, setTab] = useState(0);
  const [emgcTarget1Yn, setEmgcTarget1Yn] = useState(false);
  const [emgcTarget2Yn, setEmgcTarget2Yn] = useState(false);
  const [emgcTarget3Yn, setEmgcTarget3Yn] = useState(false);
  const [dbTarget1Yn, setDbTarget1Yn] = useState(false);
  const [dbTarget2Yn, setDbTarget2Yn] = useState(false);

  const trgterSetting = () => {
    if (getValues("json.g1_score") >= 60 && dbTarget1Yn && dbTarget2Yn) {
      setValue("json.gTargetYn", "지원대상");
    } else {
      setValue("json.gTargetYn", "지원대상 아님");
    }

    if (getValues("json.g1_score") >= 60) {
      setValue("json.gSatisfy", "충족")
      setValue("json.eSatisfy", "충족")
    } else {
      setValue("json.gSatisfy", "미충족")
      setValue("json.eSatisfy", "미충족")
    }

    if (
      getValues("json.e2_score") >= 60 &&
      emgcTarget1Yn &&
      emgcTarget2Yn &&
      emgcTarget3Yn
    ) {
      setValue("json.eTargetYn", "지원대상");
    } else {
      setValue("json.eTargetYn", "지원대상 아님");
    }

    if (
      getValues("json.e2_score") >= 60
    ) {
      setValue("json.gSatisfy", "충족")
      setValue("json.eSatisfy", "충족")
    } else {
      setValue("json.gSatisfy", "미충족")
      setValue("json.eSatisfy", "미충족")
    }
  };

  const dbTrgValid1 = (e: any) => {
    switch (e.target.value) {
      case "돌볼가족 없음":
        setDbTarget1Yn(true);
        break;
      case "돌볼가족 있음":
        setDbTarget1Yn(false);
        break;
    }
  };
  const dbTrgValid2 = (e: any) => {
    switch (e.target.value) {
      case "기존돌봄 공백":
        setDbTarget2Yn(true);
        break;
      case "기존돌봄 중복":
        setDbTarget2Yn(false);
        break;
    }
  };
  const emgcTrgValid1 = (e: any) => {
    switch (e.target.value) {
      case "긴급 위기상황":
        setEmgcTarget1Yn(true);
        break;
      case "긴급 위기상황 아님":
        setEmgcTarget1Yn(false);
        break;
    }
  };
  const emgcTrgValid2 = (e: any) => {
    switch (e.target.value) {
      case "돌볼가족 없음":
        setEmgcTarget2Yn(true);
        break;
      case "돌볼가족 있음":
        setEmgcTarget2Yn(false);
        break;
    }
  };
  const emgcTrgValid3 = (e: any) => {
    switch (e.target.value) {
      case "돌봄 공백":
        setEmgcTarget3Yn(true);
        break;
      case "돌봄 중복":
        setEmgcTarget3Yn(false);
        break;
    }
  };

  const targetInitSetting = () => {
    if (getValues("json.g2_type")) {
      if (getValues("json.g2_type") === "돌볼가족 없음") {
        setDbTarget1Yn(true)
      }
    }
    if (getValues("json.g3_type")) {
      if (getValues("json.g3_type") === "기존돌봄 공백") {
        setDbTarget2Yn(true)
        setEmgcTarget2Yn(true)
      }
    }
    if (getValues("json.e1_type")) {
      if (getValues("json.e1_type") === "긴급 위기상황") {
        setEmgcTarget1Yn(true)
      }
    }
    if (getValues("json.e4_type")) {
      if (getValues("json.e4_type") === "돌봄 공백") {
        setEmgcTarget3Yn(true)
      }
    }
  }
  useEffect(() => {
    setValue("json.g1_score", bodyPt > emtPt ? bodyPt : emtPt);
    setValue("json.e2_score", bodyPt > emtPt ? bodyPt : emtPt);
    trgterSetting();
    targetInitSetting();
  }, [
    bodyPt,
    emtPt,
    dbTarget1Yn,
    dbTarget2Yn,
    emgcTarget1Yn,
    emgcTarget2Yn,
    emgcTarget3Yn,
  ]);

  return (
    <div className="short_answer">
      <div className="short_answer1">
        {/* 탭 */}
        <div className="tabs2">
          <button
            type="button"
            className={`tab2 ${tab === 0 ? "tab_active" : "non_active"}`}
            onClick={() => setTab(0)}
            data-id={0}
          >
            광주+돌봄 지원대상 여부(①+②+③ 모두 충족)
          </button>
          <button
            type="button"
            className={`tab2 ${tab === 1 ? "tab_active" : "non_active"}`}
            onClick={() => setTab(1)}
            data-id={1}
          >
            긴급돌봄 지원대상 여부(①+②+③+④ 모두 충족)
          </button>
        </div>
        {/* 광주+돌봄 지원대상 여부 */}
        {tab === 0 && (
          <div className="tab_content2" data-id={0}>
            <div className="answer_content">
              <div className="cont_top">
                <div className="cont_tit">
                  <h4>① 신체적·정서적 기능 저하</h4>
                  <p>
                    혼자 거동하기 어렵거나, 독립적인 일상생활 수행이 어려운 정도
                  </p>
                </div>
                <div className="cont_input">
                  <p>신체적 <span className={bodyPt >= 60 ? "yes" : ""}>{bodyPt}</span>점 / 정서적 <span className={emtPt >= 60 ? "yes" : ""}>{emtPt}</span>점</p>
                  <div className="input_inner">
                      <span>
                          <input type="radio" id="radio111-1" value="충족"
                            {...register("json.gSatisfy", {
                              onChange: (e:any) => {
                                setValue("json.eSatisfy", e.target.value)
                                if(e.target.value === "미충족") {
                                  setValue("json.gTargetYn", "지원대상 아님")
                                  setValue("json.eTargetYn", "지원대상 아님")
                                } else {
                                  if(getValues("json.g1_score") >= 60 && dbTarget1Yn && dbTarget2Yn) {
                                    setValue("json.gTargetYn", "지원대상")
                                  }
                                  if(getValues("json.g1_score") >= 60 &&
                                      emgcTarget1Yn &&
                                      emgcTarget2Yn &&
                                      emgcTarget3Yn) {
                                    setValue("json.eTargetYn", "지원대상")
                                  }
                                }
                              }
                            })}
                          />
                          <label htmlFor="radio111-1">
                          충족
                          </label>
                      </span>
                      <span>
                          <input type="radio" id="radio111-2" value="미충족"
                            {...register("json.gSatisfy", {
                              onChange: (e:any) => {
                                setValue("json.eSatisfy", e.target.value)
                                if(e.target.value === "미충족") {
                                  setValue("json.gTargetYn", "지원대상 아님")
                                  setValue("json.eTargetYn", "지원대상 아님")
                                } else {
                                  if(getValues("json.g1_score") >= 60 && dbTarget1Yn && dbTarget2Yn) {
                                    setValue("json.gTargetYn", "지원대상")
                                  }
                                  if(getValues("json.g1_score") >= 60 &&
                                      emgcTarget1Yn &&
                                      emgcTarget2Yn &&
                                      emgcTarget3Yn) {
                                    setValue("json.eTargetYn", "지원대상")
                                  }
                                }
                              }
                            })}
                          />
                          <label htmlFor="radio111-2">
                          미충족
                          </label>
                      </span>
                  </div>
                  {/* <input
                    type="text"
                    placeholder="점수"
                    className="mr8"
                    disabled
                    style={{ textAlign: "right" }}
                    {...register("json.g1_score")}
                  />
                  <span>점</span> */}
                </div>
              </div>
              <div className="cont_bot mt10">
                <textarea
                  className="essential"
                  placeholder="상세내용 입력"
                  {...register("json.g1_content", {
                    // required: "신체적, 정서적 기능 저하 상세내용을 입력해주세요.",
                    onChange: () => {
                      setValue('json.e2_content', getValues("json.g1_content"))
                    }
                  })}
                />
              </div>
            </div>
            <div className="answer_content mt20">
              <div className="cont_top">
                <div className="cont_tit">
                  <h4>② 돌볼 수 있는 사람 부재</h4>
                  <p>돌볼 수 있는 가족이 없거나, 있어도 돌볼 수 없는지 여부</p>
                </div>
                <div className="cont_input">
                  <select
                    {...register("json.g2_type", {
                      onChange: (e) => {
                        setValue("json.e3_type", e.target.value)
                        dbTrgValid1(e)
                        emgcTrgValid2(e)
                      },
                    })}
                  >                    
                    <option value={"돌볼가족 있음"}>돌볼가족 있음</option>
                    <option value={"돌볼가족 없음"}>돌볼가족 없음</option>
                  </select>
                </div>
              </div>
              <div className="cont_bot mt10">
                <textarea
                  className="essential"
                  placeholder="상세내용 입력"
                  {...register("json.g2_content", {
                    // required: "돌볼 수 있는 사람 부재 여부 상세내용을 입력해주세요.",
                    onChange: () => {
                      setValue('json.e3_content', getValues("json.g2_content"))
                    }
                  })}
                />
              </div>
            </div>
            <div className="answer_content mt20">
              <div className="cont_top">
                <div className="cont_tit">
                  <h4>③ 서비스 공백</h4>
                  <p>
                    기존 돌봄서비스를 이용할 수 없거나, 이용을 기다리고 있거나,
                    이용 중 공백이 발생하였는지 여부
                  </p>
                </div>
                <div className="cont_input">
                  <select
                    {...register("json.g3_type", {
                      onChange: (e) => {
                        dbTrgValid2(e)
                      },
                    })}
                  >                    
                    <option value={"기존돌봄 중복"}>기존돌봄 중복</option>
                    <option value={"기존돌봄 공백"}>기존돌봄 공백</option>
                  </select>
                </div>
              </div>
              <div className="cont_bot mt10">
                <textarea
                  className="essential"
                  placeholder="상세내용 입력"
                  {...register("json.g3_content", {
                    // required: "기존 돌봄 서비스 공백 여부 상세를 입력해주세요.",
                  })}
                />
              </div>
            </div>
          </div>
        )}
        {/* 긴급돌봄 지원대상 여부 */}
        {tab === 1 && (
          <div className="tab_content2" data-id={0}>
            <div className="answer_content">
              <div className="cont_top">
                <div className="cont_tit">
                  <h4>① 긴급 위기 상황</h4>
                  <p>
                    갑작스러운 사고·질병이나 재난(자연재난, 사회재난) 등으로
                    긴급 위기 상황에 놓였는지 여부
                  </p>
                </div>
                <div className="cont_input">
                  <select
                    {...register("json.e1_type", {
                      onChange: (e) => emgcTrgValid1(e),
                    })}
                  >                    
                    <option value={"긴급 위기상황 아님"}>
                      긴급 위기상황 아님
                    </option>
                    <option value={"긴급 위기상황"}>긴급 위기상황</option>
                  </select>
                </div>
              </div>
              <div className="cont_bot mt10">
                <textarea
                  className="essential"
                  placeholder="상세내용 입력"
                  {...register("json.e1_content", {
                    // required: "긴급 위기 상황 여부 상세를 입력해주세요.",
                  })}
                />
              </div>
            </div>
            <div className="answer_content mt20">
              <div className="cont_top">
                <div className="cont_tit">
                  <h4>② 신체적·정서적 기능 저하</h4>
                  <p>
                    혼자 거동하기 어렵거나, 독립적인 일상생활 수행이 어려운 정도
                  </p>
                </div>
                <div className="cont_input">
                  <p>신체적 <span className={bodyPt >= 60 ? "yes" : ""}>{bodyPt}</span>점 / 정서적 <span className={emtPt >= 60 ? "yes" : ""}>{emtPt}</span>점</p>
                  <div className="input_inner">
                      <span>
                          <input type="radio" id="radio111-1" value="충족"
                            {...register("json.eSatisfy", {
                              onChange: (e:any) => {
                                setValue("json.gSatisfy", e.target.value)
                                if(e.target.value === "미충족") {
                                  setValue("json.gTargetYn", "지원대상 아님")
                                  setValue("json.eTargetYn", "지원대상 아님")
                                } else {
                                  if(getValues("json.g1_score") >= 60 && dbTarget1Yn && dbTarget2Yn) {
                                    setValue("json.gTargetYn", "지원대상")
                                  }
                                  if(getValues("json.g1_score") >= 60 &&
                                      emgcTarget1Yn &&
                                      emgcTarget2Yn &&
                                      emgcTarget3Yn) {
                                    setValue("json.eTargetYn", "지원대상")
                                  }
                                }
                              }
                            })}
                          />
                          <label htmlFor="radio111-1">
                          충족
                          </label>
                      </span>
                      <span>
                          <input type="radio" id="radio111-2" value="미충족"
                            {...register("json.eSatisfy", {
                              onChange: (e:any) => {
                                setValue("json.gSatisfy", e.target.value)
                                if(e.target.value === "미충족") {
                                  setValue("json.gTargetYn", "지원대상 아님")
                                  setValue("json.eTargetYn", "지원대상 아님")
                                } else {
                                  if(getValues("json.g1_score") >= 60 && dbTarget1Yn && dbTarget2Yn) {
                                    setValue("json.gTargetYn", "지원대상")
                                  }
                                  if(getValues("json.g1_score") >= 60 &&
                                      emgcTarget1Yn &&
                                      emgcTarget2Yn &&
                                      emgcTarget3Yn) {
                                    setValue("json.eTargetYn", "지원대상")
                                  }
                                }
                              }
                            })}
                          />
                          <label htmlFor="radio111-2">
                          미충족
                          </label>
                      </span>
                  </div>
                  {/* <input
                    type="text"
                    placeholder="점수"
                    className="mr8"
                    disabled
                    style={{ textAlign: "right" }}
                    {...register("json.e2_score")}
                  />
                  <span>점</span> */}
                </div>
              </div>
              <div className="cont_bot mt10">
                <textarea
                  className="essential"
                  placeholder="상세내용 입력"
                  {...register("json.e2_content", {
                    // required: "신체적, 정서적 기능 저하 상세내용을 입력해주세요.",
                    onChange: () => {
                      setValue('json.g1_content', getValues("json.e2_content"))
                    }
                  })}
                />
              </div>
            </div>
            <div className="answer_content mt20">
              <div className="cont_top">
                <div className="cont_tit">
                  <h4>③ 돌볼 수 있는 사람 부재</h4>
                  <p>돌볼 수 있는 가족이 없거나, 있어도 돌볼 수 없는지 여부</p>
                </div>
                <div className="cont_input">
                  <select
                    {...register("json.g2_type", {
                      onChange: (e) => {
                        setValue("json.e3_type", e.target.value)
                        dbTrgValid1(e)
                        emgcTrgValid2(e)
                      },
                    })}
                  >                    
                    <option value={"돌볼가족 있음"}>돌볼가족 있음</option>
                    <option value={"돌볼가족 없음"}>돌볼가족 없음</option>
                  </select>
                </div>
              </div>
              <div className="cont_bot mt10">
                <textarea
                  className="essential"
                  placeholder="상세내용 입력"
                  {...register("json.e3_content", {
                    // required: "돌볼 수 있는 사람 부재 여부 상세내용을 입력해주세요.",
                    onChange: () => {
                      setValue('json.g2_content', getValues("json.e3_content"))
                    }
                  })}
                />
              </div>
            </div>
            <div className="answer_content mt20">
              <div className="cont_top">
                <div className="cont_tit">
                  <h4>④ 서비스 공백</h4>
                  <p>
                    즉시 돌봄이 필요하지만 기존돌봄 또는 광주+돌봄 서비스를
                    이용할 수 없거나, 이용을 기다리고 있거나, 이용 중 공백이
                    발생하였는지 여부
                  </p>
                </div>
                <div className="cont_input">
                  <select
                    {...register("json.e4_type", {
                      onChange: (e) => {
                        emgcTrgValid3(e)
                      },
                    })}
                  >                    
                    <option value={"돌봄 중복"}>돌봄 중복</option>
                    <option value={"돌봄 공백"}>돌봄 공백</option>
                  </select>
                </div>
              </div>
              <div className="cont_bot mt10">
                <textarea
                  className="essential"
                  placeholder="상세내용 입력"
                  {...register("json.e4_content", {
                    // required: "즉시 돌봄 서비스 공백 여부 상세를 입력해주세요.",
                  })}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="short_answer2 mt20">
        <ul>
          {/* <li>
            <h4>신체적 영역</h4>
            {bodyPt >= 60 ? (
              <p>
                <span className="yes">{bodyPt}</span> 점
              </p>
            ) : (
              <p>
                <span>{bodyPt}</span> 점
              </p>
            )}
          </li>
          <li>
            <h4>정서적 영역</h4>
            {emtPt >= 60 ? (
              <p>
                <span className="yes">{emtPt}</span> 점
              </p>
            ) : (
              <p>
                <span>{emtPt}</span> 점
              </p>
            )}
          </li> */}
          <li>
            <h4>광주+돌봄 지원대상 여부</h4>
            {getValues("json.g1_score") >= 60 && dbTarget1Yn && dbTarget2Yn && getValues("json.gSatisfy") === "충족" ? (
              <p>
                <span className="yes">
                  <input
                    type="text"
                    disabled
                    {...register("json.gTargetYn", {
                      value: "지원대상",
                    })}
                  />
                </span>
              </p>
            ) : (
              <p>
                <span>
                  <input
                    type="text"
                    disabled
                    {...register("json.gTargetYn", {
                      value: "지원대상 아님",
                    })}
                  />
                </span>
              </p>
            )}
          </li>
          <li>
            <h4>긴급돌봄 지원대상 여부</h4>
            {getValues("json.g1_score") >= 60 &&
            emgcTarget1Yn &&
            emgcTarget2Yn &&
            emgcTarget3Yn && getValues("json.eSatisfy") === "충족"
             ? (
              <p>
                <span className="yes">
                  <input
                    type="text"
                    disabled
                    {...register("json.eTargetYn", {
                      value: "지원대상",
                    })}
                  />
                </span>
              </p>
            ) : (
              <p>
                <span>
                  <input
                    type="text"
                    disabled
                    {...register("json.eTargetYn", {
                      value: "지원대상 아님",
                    })}
                  />
                </span>
              </p>
            )}
          </li>
        </ul>
        <div className="opinion">
          <h4>종합의견</h4>
          <textarea placeholder="종합의견 입력" {...register("json.general")} />
        </div>
      </div>
    </div>
  );
};

const AddIllItem = ({itemName, setItemName, handleAddItem} : {
  itemName: string,
  setItemName: React.Dispatch<React.SetStateAction<string>>,
  handleAddItem: Function,
}) => {
  return (
    <li>
        <input
          type="text"
          placeholder="기타 입력"
          style={{ width: "180px" }}
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
        />
      <button className="btn_blue" onClick={(e) => {
        e.preventDefault()
        if(itemName.length === 0) {
          window.alert("질병 및 증상 명을 입력해주세요.")
        } else
          handleAddItem(e, itemName)
        }}>+</button>
    </li>
  )
}

const AddedItem = ({item, handleRemoveItem} : {item: IllItem, handleRemoveItem: Function}) => {
  return (
    <li>
        {item.value}<button className="btn_gray" onClick={(e) => {handleRemoveItem(e, item.key)}}>x</button>
    </li>
  )
}

export default NeedsAssessmentPage;
