import moment from "moment";
import NeedsAssessmentJoin from "../../../../../models/NeedsAssessmentJoin";

const NeedAsTabTable1 = ({
    evaluationData,
    illAddList,
  }: {
    evaluationData?: NeedsAssessmentJoin;
    illAddList: string[];
  }) => {
    const beginDt = moment(evaluationData?.counselResult.beginDt).format(
      "YYYY.MM.DD(dd)"
    );
    return (
      <div className="tab_content" data-id={0}>
        <p className="date">
          평가일 : {beginDt}
        </p>
        <div className="choice_result">
          <h4>기본적 일상생활 자립 정도</h4>
          <ul>
            <li>
              <div>
                <p>세수, 양치질 하기</p>
                <span>{evaluationData?.jsonData.q1_1}</span>
              </div>
              <div>
                <p>머리감기</p>
                <span>{evaluationData?.jsonData.q1_2}</span>
              </div>
            </li>
            <li>
              <div>
                <p>목욕하기</p>
                <span>{evaluationData?.jsonData.q1_3}</span>
              </div>
              <div>
                <p>옷 벗고 입기</p>
                <span>{evaluationData?.jsonData.q1_4}</span>
              </div>
            </li>
            <li>
              <div>
                <p>식사하기</p>
                <span>{evaluationData?.jsonData.q1_5}</span>
              </div>
              <div>
                <p>체위 변경(일어나 앉기 등)</p>
                <span>{evaluationData?.jsonData.q1_6}</span>
              </div>
            </li>
            <li>
              <div>
                <p>집안에서 이동하기</p>
                <span>{evaluationData?.jsonData.q1_7}</span>
              </div>
              <div>
                <p>화장실 사용하기</p>
                <span>{evaluationData?.jsonData.q1_8}</span>
              </div>
            </li>
          </ul>
        </div>
        <div className="choice_result mt20">
          <h4>사회적 일상생활 자립 정도</h4>
          <ul>
            <li>
              <div>
                <p>식사 준비하기(음식 조리)</p>
                <span>{evaluationData?.jsonData.q2_1}</span>
              </div>
              <div>
                <p>세탁, 빨래하기</p>
                <span>{evaluationData?.jsonData.q2_2}</span>
              </div>
            </li>
            <li>
              <div>
                <p>청소하기</p>
                <span>{evaluationData?.jsonData.q2_3}</span>
              </div>
              <div>
                <p>장보기, 물건사기</p>
                <span>{evaluationData?.jsonData.q2_4}</span>
              </div>
            </li>
            <li>
              <div>
                <p>근거리 외출하기</p>
                <span>{evaluationData?.jsonData.q2_5}</span>
              </div>
              <div>
                <p>교통수단 이용하기</p>
                <span>{evaluationData?.jsonData.q2_6}</span>
              </div>
            </li>
          </ul>
        </div>
        {evaluationData?.jsonData.disease &&
          evaluationData.jsonData.disease.length >= 0 && (
            <div className="choice_result mt20">
              <h4>질병 및 증상</h4>
              <ul>
                <Disease key={evaluationData.jsonData.disease.length} disease={evaluationData?.jsonData.disease ?? []}
                  illAdd={illAddList}
                />
              </ul>
            </div>
          )}
      </div>
    );
  };
  
  const Disease = ({ disease, illAdd }: { disease: string[]; illAdd: string[]; }) => {
    return (
      <ul>
        {disease
          .reduce((acc: string[][], cur: string, i: number) => {
            if (i % 2 === 0) {
              acc.push([cur, disease[i + 1]]);
            }
            return acc;
          }, [])
          .map((e) => (
            <>
              <li>
                <div>
                  <p className="w100">{e[0]}</p>
                </div>
                <div>
                  <p className="w100">{e[1]}</p>
                </div>
              </li>
            </>
          ))}
        {illAdd
          .reduce((acc: string[][], cur: string, i: number) => {
            if (i % 2 === 0) {
              acc.push([cur, illAdd[i + 1]]);
            }
            return acc;
          }, [])
          .map((e) => (
            <>
              <li>
                <div>
                  <p className="w100">{e[0]}</p>
                </div>
                <div>
                  <p className="w100">{e[1]}</p>
                </div>
              </li>
            </>
          ))}
      </ul>
    );
  };

  export default NeedAsTabTable1