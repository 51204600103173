import React from "react";
import { LoginBasic } from "../apis/MemberAPI";

import Member from "../models/Member";

interface MemberState {
  member?: Member;
  login: (snsId: string, snsType: string) => any;
  loginBasic: (data:LoginBasic) => any;
  logout: () => any;
  debugLogin: (member: Member) => any;
  selectLocation: () => any
  setMember: (member: Member) => any;
}

const initialState: MemberState = {
  login() {},
  logout() {},
  debugLogin() {},
  loginBasic() {},
  selectLocation() {},
  setMember() {},
};

const MemberContext = React.createContext<MemberState>(initialState);

export default MemberContext;
