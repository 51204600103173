import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

import "./MultiSelect.css";

const basicOptions = [
  { label: "아이돌봄", value: "아이돌봄" },
  { label: "노인장기요양보험", value: "노인장기요양보험" },
  { label: "노인맞춤돌봄", value: "노인맞춤돌봄", disabled: false },
  { label: "장애인활동지원", value: "장애인활동지원", disabled: false },
];
const dateOptions = [
  { label: "월", value: "월" },
  { label: "화", value: "화" },
  { label: "수", value: "수", disabled: false },
  { label: "목", value: "목", disabled: false },
  { label: "금", value: "금", disabled: false },
  { label: "토", value: "토", disabled: false },
  { label: "일", value: "일", disabled: false },
];

export const Example = () => {
  const [selected, setSelected] = useState([]);

  return (
    <div style={{ width: "238px", backgroundColor: "white" }}>
      {/* <pre>{JSON.stringify(selected)}</pre> */}
      <MultiSelect
        options={basicOptions}
        value={selected}
        onChange={setSelected}
        labelledBy="선택"
        hasSelectAll={false}
        disableSearch={true}
        overrideStrings={{ selectSomeItems: "선택" }}
      />
    </div>
  );
};

export const DateOptions = () => {
  const [selected, setSelected] = useState([]);

  return (
    <div style={{ width: "238px", backgroundColor: "white" }}>
      <MultiSelect
        options={dateOptions}
        value={selected}
        onChange={setSelected}
        labelledBy="선택"
        hasSelectAll={false}
        disableSearch={true}
        overrideStrings={{ selectSomeItems: "선택" }}
      />
    </div>
  );
};

export default Example;
