import moment from "moment";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ExpenseModelAPI from "../../../apis/ExpenseModelAPI";

import BackIcon from "../../../assets/ic_backlist.png";
import ClaimDataTable, {
  createButtonCell,
  createRow,
  createTextCell,
  NeedUpd,
} from "../../../components/common/ClaimDataTable";
import DataTableDetail, {
  createDetailRow,
  createDetailTextCell,
} from "../../../components/common/DataTableDetail";
import useMember from "../../../hooks/useMember";
// import ExpenseDetail, { Unique } from "../../../models/bill/ExpenseDetail";
// import ExpenseManagement from "../../../models/bill/ExpenseManagement";
import OrgBillModel from "../../../models/bill/OrgBillModel";
import ServicePlanItem from "../../../models/ServicePlanItem";

import "./ClaimPage.scss";

/**
 * 작성자: 홍준표
 * 작성일: 2023.02.27
 *
 * 청구 페이지
 */

export type UploadedFile = {
  itemUid: number,
  fileName: string,
}

const ClaimPage = () => {
  const { page } = useParams()
  // const [caseManagements, setCaseManagements] = useState(
  //   [] as ExpenseManagement[]
  // );
  // const [caseDetails, setCaseDetails] = useState([] as Unique[]);

  const [orgBillList, setOrgBillList] = useState([] as OrgBillModel[])
  const {member} = useMember()
  const [load, setLoad] = useState(true)
  const [totalCost, setTotalCost] = useState(0)
  const [needUpload, setNeedUpload] = useState([] as NeedUpd[])
  // const [needUploadTest, setNeedUploadTest] = useState([] as NeedUpd[])
  useEffect(() => {
    //테스트용 데이터
    // setCaseManagements(ExpenseManagement.mockList);
    // setCaseDetails(Unique.mockList);
    // let updList = [] as NeedUpd[]
    // ExpenseManagement.mockList.map((item) => {
    //   if((item.serviceNm === "주거편의" || item.serviceNm === "안전지원") && item.status === "미청구") {
    //     updList.push({spyUid: item.spyUid, state: false})
    //   }
    // })
    // setNeedUploadTest(updList)
    //테스트용 데이터

    
    //청구가능 목록 조회
    ExpenseModelAPI.getBillList({
      dptUid: member?.dptUid,
      page: Number(page),
    }).then((res) => {
      console.log(res)
      res.list.map((item) => {
        if(item.totalCost) {
          setTotalCost((cur) => cur + item.totalCost)
        }
      })
      
      setOrgBillList(res.list)
      res.list.map((caseManagement) => {
        if(caseManagement.serviceInfo.cateCdText === "주거편의" || 
        caseManagement.serviceInfo.cateCdText === "안전지원") {
          setNeedUpload((cur) => [...cur, {spyUid: caseManagement.spyUid, state: false}])
        }
      })
      setLoad(false)
    })
  }, [page]);

  /**
   * 테이블 상단
   */
  const TableTop = () => {
    const navigate = useNavigate();
    const onClick = () => {
      navigate(-1);
    };

    return (
      <article className="detail_top">
        <div className="nav_list">
          <p>
            비용 / <span>청구</span>
          </p>
          <button type="button" className="mt8" onClick={onClick}>
            <span>
              <img src={BackIcon} alt="목록으로 돌아가기" />
            </span>
            목록으로 돌아가기
          </button>
        </div>
      </article>
    );
  };

  if (!member) {
    return <Navigate to="/login" />;

  }

  // const Table = ({ list, needUploadTest }: { list: ExpenseManagement[], needUploadTest: NeedUpd[] }) => {
  //   const ListDetail = ({
  //     detail,
  //     spyUid,
  //   }: {
  //     detail: Unique[];
  //     spyUid: number;
  //   }) => {
  //     const [items, setItems] = useState([] as ExpenseDetail[]);
  //     useEffect(() => {
  //       detail.forEach(({ unique }) => {
  //         if (unique.spyUid === spyUid) {
  //           setItems(unique.items);
  //           return false;
  //         }
  //       });
  //     }, []);

  //     return (
  //       <DataTableDetail
  //         columnTitles={[
  //           "서비스 회차",
  //           "서비스 일자",
  //           "진행시간(제공시간)",
  //           "이용금액",
  //           "투입인원",
  //           "총금액",
  //         ]}
  //         rows={items.map((caseDetail) =>
  //           createDetailRow({
  //             cells: [
  //               createDetailTextCell({ text: caseDetail.serviceTurn + "회차" }),
  //               createDetailTextCell({
  //                 text: caseDetail.serviceDate.format("YYYY.MM.DD(ddd)"),
  //               }),
  //               createDetailTextCell({
  //                 text:
  //                   caseDetail.progressStart.format("A h:mm") +
  //                   " ~ " +
  //                   caseDetail.progressEnd.format("A h:mm") +
  //                   " (기본 " +
  //                   caseDetail.progressEnd.diff(
  //                     caseDetail.progressStart,
  //                     "hours",
  //                     true
  //                   ) +
  //                   "시간)",
  //               }),
  //               createDetailTextCell({
  //                 text: caseDetail.servicePrice.toLocaleString("ko-KR") + " 원",
  //               }),
  //               createDetailTextCell({ text: caseDetail.personnel + " 명" }),
  //               createDetailTextCell({
  //                 text: caseDetail.totalCost.toLocaleString("ko-KR") + " 원",
  //               }),
  //             ],
  //           })
  //         )}
  //         page={Number(page)}
  //         onPageChange={() => {}}
  //       />
  //     );
  //   };
  //   //미청구 상태의 아이템만 가져온다.
  //   const nonClaim = list.filter((item) => item.status === "미청구");
  //   const [totalCost, setTotalCost] = useState(0);
  //   useEffect(() => {
  //     nonClaim.forEach((row) => {
  //       setTotalCost((cur) => cur + row.totalCost);
  //     });
  //   }, []);
  //   return (
  //     <article className="claim">
  //       <div className="claim_cont">
  //         <h4>비용청구서</h4>
  //         <ClaimDataTable
  //           expandable
  //           columnTitles={[
  //             "행정동",
  //             "서비스 종류",
  //             "성명",
  //             "생년월일",
  //             "성별",
  //             "연락처",
  //             "주소",
  //             "서비스 결과",
  //             "총액",
  //             "증빙자료",
  //           ]}
  //           rows={nonClaim.map((caseManagement) =>
  //             createRow({
  //               cells: [
  //                 createTextCell({ text: caseManagement.hdong }),
  //                 createTextCell({ text: caseManagement.serviceNm }),
  //                 createTextCell({ text: caseManagement.name }),
  //                 createTextCell({ text: caseManagement.brthdy }),
  //                 createTextCell({ text: caseManagement.gender }),
  //                 createTextCell({ text: caseManagement.telno }),
  //                 createTextCell({
  //                   text: caseManagement.adres,
  //                   ellipsis: true,
  //                 }),
  //                 createTextCell({
  //                   text: `${
  //                     caseManagement.serviceResult +
  //                     (caseManagement.resultReason === ""
  //                       ? ""
  //                       : "(" + caseManagement.resultReason + ")")
  //                   }`,
  //                 }),
  //                 createTextCell({
  //                   text:
  //                     caseManagement.totalCost.toLocaleString("ko-KR") + " 원",
  //                   redtext: true,
  //                 }),
  //                 // createTextCell({text : caseManagement.evidenceData}),
  //                 createButtonCell({
  //                   type:
  //                     caseManagement.serviceNm === "주거편의" ||
  //                     caseManagement.serviceNm === "안전지원"
  //                       ? "outlined"
  //                       : undefined,
  //                   label: "첨부",
  //                 }),
  //               ],
  //               expandContent: (
  //                 <ListDetail
  //                   detail={caseDetails}
  //                   spyUid={caseManagement.spyUid}
  //                 />
  //               ),
  //               isCheckBox: true,
  //               spyUid: caseManagement.spyUid,
  //               cateCdText: caseManagement.serviceNm,
  //             })
  //           )}
  //           page={1}
  //           onPageChange={() => {}}
  //           isCheckBox={true}
  //           needUpd={needUploadTest}
  //         />
  //       </div>

  //       <div className="cost_list">
  //         <ul>
  //           <li>
  //             <p>(비용청구 총액)</p>
  //             <p>
  //               <span className="red">
  //                 {totalCost.toLocaleString("ko-KR")}
  //               </span>{" "}
  //               원
  //             </p>
  //           </li>
  //         </ul>
  //       </div>
  //     </article>
  //   );
  // };

  return (
    <main className="claim_page">
      {/* <section>
        <TableTop />
        <Table list={caseManagements} needUploadTest={needUploadTest} />
      </section> */}
      {load ? null : 
      <section>
        <TableTop />
        <TableApi list={orgBillList} needUpload={needUpload} totalCost={totalCost} page={Number(page)}/>
      </section>
      }
    </main>
  );
};

const TableApi = ({ list, needUpload, totalCost, page }: { list: OrgBillModel[], needUpload: NeedUpd[], totalCost:number, page: number }) => {
  const navigate = useNavigate();

  const handlePageChange = (page: number) => {
    navigate(`/expense/claim${page}`);
  };
  return (
    <article className="claim">
      <div className="claim_cont">
        <h4>비용청구서</h4>
        <ClaimDataTable
          expandable
          columnTitles={[
            "행정동",
            "서비스 종류",
            "성명",
            "생년월일",
            "성별",
            // "연락처",
            "주소",
            "서비스 결과",
            "총액",
            "증빙자료",
          ]}
          rows={list.map((caseManagement) =>
            createRow({
              cells: [
                createTextCell({ text: caseManagement.hdong }),
                createTextCell({ text: caseManagement.serviceInfo.serviceNm }),
                createTextCell({ text: caseManagement.trgterNm }),
                createTextCell({ text: moment(caseManagement.brthdy).format('yyyy-MM-DD') }),
                createTextCell({ text: caseManagement.gender }),
                // createTextCell({ text: caseManagement.telno }),
                createTextCell({
                  text: caseManagement.adres + ", " + caseManagement.adresDtl,
                  ellipsis: true,
                }),
                createTextCell({
                  text: `${
                    caseManagement.serviceStatCd
                    //  +
                    // (caseManagement.resultReason === ""
                    //   ? ""
                    //   : "(" + caseManagement.resultReason + ")")
                  }`,
                }),
                createTextCell({
                  text:
                  caseManagement.totalCost ? caseManagement.totalCost.toLocaleString("ko-KR") + " 원" : "0원",
                  redtext: true,
                }),
                // createTextCell({text : caseManagement.evidenceData}),
                createButtonCell({
                  type:
                    caseManagement.serviceInfo.cateCdText === "주거편의" ||
                    caseManagement.serviceInfo.cateCdText === "안전지원"
                      ? "outlined"
                      : undefined,
                  label: "첨부",
                }),
              ],
              expandContent: (
                <ClaimDetail list={caseManagement.servicePlanItemList}/>
              ),
              isCheckBox: true,
              spyUid: caseManagement.spyUid,
              cateCdText: caseManagement.serviceInfo.cateCdText
            })
          )}
          page={page}
          onPageChange={handlePageChange}
          isCheckBox={true}
          needUpd={needUpload}
        />
      </div>

      <div className="cost_list">
        <ul>
          <li>
            <p>(비용청구 총액)</p>
            <p>
              <span className="red">
                {totalCost.toLocaleString("ko-KR")}
              </span>{" "}
              원
            </p>
          </li>
        </ul>
      </div>
    </article>
  );
}

const ClaimDetail = ({list} : {list : ServicePlanItem[]}) => {
  return (
    <DataTableDetail
      columnTitles={[
        "서비스 회차",
        "서비스 일자",
        "진행시간(제공시간)",
        "이용금액(수정 전 금액)",
        "금액 수정사유",
      ]}
      rows={list.map((caseDetail, idx) =>
        createDetailRow({
          cells: [
            createDetailTextCell({ text: (idx + 1) + "회차" }),
            createDetailTextCell({
              text: moment(caseDetail.serviceDate).format("YYYY.MM.DD(ddd)"),
            }),
            createDetailTextCell({
              text:
                moment(caseDetail.beginDt).format("A h:mm") +
                " ~ " +
                moment(caseDetail.endDt).format("A h:mm") +
                " (기본 " +
                Math.round(moment(caseDetail.endDt).diff(
                  moment(caseDetail.beginDt),
                  "hours",
                  true
                ) * 10) / 10 +
                "시간)",
            }),
            createDetailTextCell({
              text: caseDetail.updRsn ? caseDetail.totalCost.toLocaleString("ko-KR") + " 원 (" + caseDetail.updCost.toLocaleString("ko-KR") + " 원)"
              : caseDetail.totalCost ? caseDetail.totalCost.toLocaleString("ko-KR") + " 원" : "0 원"
            }),
            createDetailTextCell({
              text: caseDetail.updRsn ? caseDetail.updRsn : "-"
            })
            // createDetailTextCell({
            //   text: caseDetail.useCost ? caseDetail.useCost.toLocaleString("ko-KR") + " 원" : "0 원",
            // }),
            // createDetailTextCell({ text: caseDetail.pplCnt + " 명" }),
            // createDetailTextCell({
            //   text: caseDetail.totalCost.toLocaleString("ko-KR") + " 원",
            // }),
          ],
        })
      )}
      page={1}
      onPageChange={() => {}}
    />
  );
}

export default ClaimPage;
