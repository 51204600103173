import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import SearchIcon from "../../assets/ic_search.png";

/**
 * @param title 셀렉트 박스 왼쪽에 표시되는 이름
 * @param name API에 요청하기 위한 파라미터 이름
 * @param type
 * @param items
 */
export interface SearchField {
  title: string;
  name: string;
  type: "select" | "search" | "searchType";
  items: SearchItem[];
}

/**
 * @param label 표시되는 이름
 * @param value 실제 값, undefined일 경우 label과 동일
 */
export interface SearchItem {
  label: string;
  value?: string;
}

export type SearchFormInput = { [K in SearchField["name"]]: string };

/**
 *
 * @param fields 검색 필드
 * @param onSubmit 검색 시 동작
 */
const SearchBar = ({
  fields,
  onSubmit,
}: {
  fields: SearchField[];
  onSubmit: SubmitHandler<SearchFormInput>;
}) => {
  const { register, handleSubmit, watch } = useForm<SearchFormInput>();
  const data = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <article className="search_area">
        {fields.map((field, index) =>
          field.type === "select" ? (
            <div key={index}>
              <h4>{field.title}</h4>
              <div className="search_box">
                <select
                  style={{ color: data[field.name] ? "#000" : "#9da0a8" }}
                  {...register(field.name)}
                >
                  <option value="">전체</option>
                  {field.items.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : (
            <div key={index} className="search">
              <h4>검색어</h4>
              <div className="search_box2">
                <div className="search_box">
                  <select
                    style={{ color: data[field.name] ? "#000" : "#9da0a8" }}
                    {...register(field.name)}
                  >
                    {field.items.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="search_box">
                  <input
                    type="search"
                    placeholder="검색어를 입력하세요"
                    {...register("keyword")}
                  />
                </div>
              </div>
            </div>
          )
        )}
        <button type="submit" className="btn_search">
          <img src={SearchIcon} alt="검색 아이콘" />
          검색
        </button>
      </article>
    </form>
  );
};

export default SearchBar;
