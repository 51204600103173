import moment from "moment";
import NeedsAssessmentJoin from "../../../../../models/NeedsAssessmentJoin";

const NeedAsTabTable4 = ({
    evaluationData,
  }: {
    evaluationData?: NeedsAssessmentJoin;
  }) => {
    const beginDt = moment(evaluationData?.counselResult.beginDt).format(
      "YYYY.MM.DD(dd)"
    );
    return (
      <div className="tab_content" data-id={3}>
        <p className="date">평가일 : {beginDt}</p>
        <div className="choice_result">
          <h4>귀하의 생각과 가장 유사한 곳에 응답해주세요 - 지난 1주일동안 나는(또는 가족은)</h4>
            <ul>
              <li>
                <div style={{width: "100%"}}>
                  <p style={{width: "calc(100% - 85px)"}}>식사, 가사, 외출 등 일상생활 수행하는데 어려움을 경험한 적이 있다.</p>
                  <span style={{width: "75px"}}>{evaluationData?.jsonData.q8_1 ?? "데이터없음"}</span>
                </div>
              </li>
              <li>
                <div style={{width: "100%"}}>
                  <p style={{width: "calc(100% - 85px)"}}>나 자신 또는 가족을 돌보는 것이 부담스러운 적이 있다.</p>
                  <span style={{width: "75px"}}>{evaluationData?.jsonData.q8_2 ?? "데이터없음"}</span>
                </div>
              </li>
              <li>
                <div style={{width: "100%"}}>
                  <p style={{width: "calc(100% - 85px)"}}>몸이 아프거나 불편한 적이 있다.</p>
                  <span style={{width: "75px"}}>{evaluationData?.jsonData.q8_3 ?? "데이터없음"}</span>
                </div>
              </li>
              <li>
                <div style={{width: "100%"}}>
                  <p style={{width: "calc(100% - 85px)"}}>우울하다는 생각을 한 적이 있다.</p>
                  <span style={{width: "75px"}}>{evaluationData?.jsonData.q8_4 ?? "데이터없음"}</span>
                </div>
              </li>
              <li>
                <div style={{width: "100%"}}>
                  <p style={{width: "calc(100% - 85px)"}}>힘들고 어려울 때 지역사회(주변사람, 광주광역시, 기관 등)로 부터 도움을 받을 수 있다고 생각한다.</p>
                  <span style={{width: "75px"}}>{evaluationData?.jsonData.q8_5 ?? "데이터없음"}</span>
                </div>
              </li>
              <li>
                <div style={{width: "100%"}}>
                  <p style={{width: "calc(100% - 85px)"}}>지금까지 나의 삶에 대해 만족한다.</p>
                  <span style={{width: "75px"}}>{evaluationData?.jsonData.q8_6 ?? "데이터없음"}</span>
                </div>
              </li>
            </ul>
        </div>
      </div>
    );
  };

export default NeedAsTabTable4