import moment from "moment";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ExpenseModelAPI from "../../../apis/ExpenseModelAPI";
import FileUploadAPI from "../../../apis/FileUploadAPI";

import BackIcon from "../../../assets/ic_backlist.png";
import PayItemDataTable, {
  createRow,
  createTextCell,
  createButtonCell,
} from "../../../components/common/PayItemDataTable";
import DataTableDetail, {
  createDetailRow,
  createDetailTextCell,
  createDetailButtonCell,
} from "../../../components/common/DataTableDetail";
import PaymentInfoArea from "../../../components/common/PaymentInfoArea";
import ExpenseAgencyManagement from "../../../models/bill/ExpenseAgencyManagement";
import { Unique } from "../../../models/bill/ExpenseDetail";
import ExpenseManagement from "../../../models/bill/ExpenseManagement";
import GuBillModel from "../../../models/bill/GuBillModel";
import GuDetailModel from "../../../models/bill/GuDetailModel";

import "./PaymentPage.scss";
import useMember from "../../../hooks/useMember";
import { useDialog } from "../../../dialogs/DialogProvider";
import PayCostEditDialog from "../../../dialogs/bill/PayCostEditDialog";
import DateIcon from "../../../assets/ic_date.png"

/**
 * 작성자: 홍준표
 * 작성일: 2023.02.28
 *
 * 지급 페이지
 */

const PaymentPage = () => {
  const [searchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") as string);
  const spyUid =  parseInt(searchParams.get("spyUid") as string);
  const sbUid =  parseInt(searchParams.get("sbUid") as string);
  //이후에 조회 API 적용
  // let agencyList = ExpenseAgencyManagement.mockList;
  // let expenseList = ExpenseManagement.mockList;
  // let detailList = Unique.mockList;
  // let agencyItem: ExpenseAgencyManagement = agencyList[0];
  // let expenseItem:ExpenseManagement = expenseList[0]
  // let detailItem: Unique = detailList[0];
  const [guDetailItem, setGuDetailItem] = useState({} as GuDetailModel)
  const [guBillItem, setGuBillItem] = useState({} as GuBillModel)
  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(true)
  const [isFileExist, setIsFileExist] = useState(false)
  const [fileLink, setFileLink] = useState<HTMLAnchorElement>()
  const {member} = useMember()
  useEffect(() => {
    // console.log("spyUid: ", spyUid)
    // console.log("page: ", page)
    ExpenseModelAPI.getGuList({
      page: page,
      sbUid: sbUid,
    }).then((res) => {
      res.map((item) => {
        setGuBillItem(item)
        if(item.spyUid === spyUid) {
          setGuBillItem(item)
          if(item.billStatus === "지급대기") {
            ExpenseModelAPI.getGuDetail({
              spyUid: spyUid,
            }).then((res) => {
              setGuDetailItem(res)
              // console.log("detail : ", res)
              setLoading(false)
            })
          } else {
            ExpenseModelAPI.getGuPaidDetail({
              spyUid: spyUid,
            }).then((res) => {
              setGuDetailItem(res)
              // console.log("detail : ", res)
              setLoading(false)
            })
          }
          // console.log("item : ", item)
          FileUploadAPI.getFile({
            uid: sbUid,
            folder: "BILL",
          }).then((res) => {
            setIsFileExist(true)
            // console.log("res : ", res)
            let dataType = res.type.split('/')[1]
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(res)
            link.style.display = "none"
            link.setAttribute("download", `${spyUid}_증빙자료.${dataType}`)
            setFileLink(link)
            // document.body.appendChild(link)
            // link.click()
          }).catch((err) => {
            console.log("업로드된 파일 없음")
            setIsFileExist(false)
          })
          setLoading2(false)
          return
        }
      })
    })
  }, [])
  // agencyList.forEach((item) => {
  //   if (item.spyUid === spyUid) {
  //     agencyItem = item;
  //     return false;
  //   }
  // });
  // expenseList.forEach((item) => {
  //     if(item.spyUid === spyUid) {
  //         expenseItem = item
  //         return false
  //     }
  // })
  // detailList.forEach((item) => {
  //   if (item.unique.spyUid === spyUid) {
  //     detailItem = item;
  //     return false;
  //   }
  // });
  //동기처리

  /**
   * 테이블 상단
   */
  const TableTop = () => {
    const navigate = useNavigate();
    const onClick = () => {
      navigate(-1);
    };
    const [payDt, setPayDt] = useState(moment().format('yyyy-MM-DD'))
    const handlePaymentComplete = async (spyUid: number, sbUid: number, payDt: string) => {
      if(moment(payDt).diff(moment(), 'days') < 0) {
        window.alert("지급예정일을 당일 이전일로 설정할 수 없습니다.")
        return
      }
      // if (
      //   agencyItem.status !== "지급완료" &&
      //   window.confirm("청구된 비용의 지급이 모두 완료되었습니까?")
      // ) {
      //   ExpenseAgencyManagement.paymentComplete(spyUid);
      //   window.alert("지급완료 상태로 변경되었습니다.");
      // } else {
      //   return false;
      // }
      if(window.confirm("청구된 비용의 지급이 모두 완료되었습니까?")) {
        let suc = await ExpenseModelAPI.payComplete({
          payItems: [
            {
              spyUid: spyUid,
              sbUid: sbUid,
              payDt: payDt,
            }
          ]
        })

        if(suc) {
          window.alert("지급완료 처리되었습니다.")
          navigate("/expense", { replace: true });
        } else {
          window.alert("지급완료 처리 실패")
        }
      } else {
        return
      }
    };
    return (
      <article className="detail_top">
        <div className="nav_list">
          <p>
            비용 / <span>지급</span>
          </p>
          <button type="button" className="mt8" onClick={onClick}>
            <span>
              <img
                src={BackIcon}
                className="previcon"
                alt="목록으로 돌아가기"
              />
            </span>
            목록으로 돌아가기
          </button>
        </div>
        {guBillItem.billStatus === "지급대기" ? (
          <div className="btn_wrap">
            <input type="date" max="9999-12-31" id="birth"
            style={{width: "200px", height: "40px", padding: "0 15px", borderRadius: "5px", border: "1px solid #e6e8ea", fontSize: "15px"
            , marginRight: "10px"}}
            value={payDt}
            onChange={(e:any) => {
              setPayDt(e.target.value)
            }}/>
            <button
              type="submit"
              onClick={() => handlePaymentComplete(spyUid, sbUid, payDt)}
              className="submit"
            >
              지급완료
            </button>
          </div>
        ) : null}
      </article>
    );
  };

  const Table = () => {
    // const filterList = detailList.filter(({unique}) =>
    //     unique.spyUid === spyUid)
    // const filterItem = filterList[0].unique.items
    const DetailTable = () => {
      const { showDialog } = useDialog();
      //금액 수정 다이얼로그
      const handleEditBill = (spiUid: number, sbUid: number, spyUid: number, dptUid: number,  payRsn: string) => {
        if(payRsn) {
          return
        }
        showDialog(PayCostEditDialog, {
          spyUid: spyUid,
          spiUid: spiUid,
          sbUid: sbUid,
          dptUid: dptUid,
        });
      }
      return (
        <DataTableDetail
          columnTitles={[
            "서비스 회차",
            "서비스 일자",
            "진행시간(제공시간)",
            "이용금액(수정 전 금액)",
            "금액 수정사유",
            "총금액(수정 전 금액)",
            "지급금액 수정사유",
          ]}
          rows={guDetailItem.list.map((caseDetail, idx) =>
            createDetailRow({
              cells: [
                createDetailTextCell({ text: (idx + 1) + "회차" }),
                createDetailTextCell({
                  text: caseDetail.beginDt ?? "시작일자 없음",
                }),
                createDetailTextCell({
                  text: caseDetail.startTime && caseDetail.endTime ?
                    moment(caseDetail.startTime).format("A h:mm") +
                    " ~ " +
                    moment(caseDetail.endTime).format("A h:mm") +
                    " (기본 " +
                    Math.round(moment(caseDetail.endTime).diff(
                      moment(caseDetail.startTime),
                      "hours",
                      true
                    ) * 10) / 10 +
                    "시간)" : "진행시간 없음",
                }),
                createDetailTextCell({
                  text: caseDetail.updRsn === null ? caseDetail.totalCost.toLocaleString("ko-KR") + " 원" : caseDetail.totalCost.toLocaleString("ko-KR") + " 원 ("
                  + caseDetail.updCost.toLocaleString("ko-KR") + " 원)"
                }),
                createDetailTextCell({
                  text: caseDetail.updRsn === null ? "-" : caseDetail.updRsn
                }),
                createDetailTextCell({
                  text: caseDetail.payRsn ? caseDetail.totalCost.toLocaleString("ko-KR") + " 원 (" + caseDetail.payCost.toLocaleString("ko-KR") + " 원)"
                   : caseDetail.totalCost.toLocaleString("ko-KR") + " 원"
                }),
                createDetailButtonCell({
                  type: caseDetail.payRsn ? "none" : caseDetail.clclnSt ? "none" : "outlined",
                  label: caseDetail.payRsn ? caseDetail.payRsn : caseDetail.clclnSt ? "-" : "수정",
                  onClick: () => caseDetail.clclnSt ? {} : handleEditBill(caseDetail.spiUid, caseDetail.sbUid, caseDetail.spyUid, guDetailItem.orgInfo.dptUid, caseDetail.payRsn)
                }),
                
                // createDetailTextCell({
                //   text: caseDetail.cost.toLocaleString("ko-KR") + " 원",
                // }),
                // createDetailTextCell({ text:  caseDetail.pplCnt.toString() }),
                // createDetailTextCell({ text:  caseDetail.userName }),
                // createDetailTextCell({
                //   text: caseDetail.totalCost.toLocaleString("ko-KR") + " 원",
                // }),
              ],
            })
          )}
          page={1}
          onPageChange={() => {}}
        />
      );
    };

    if (!member) {
      return <Navigate to="/login" />;
  
    }

    // const expenseFilter = expenseList.filter((item) => item.spyUid === spyUid);
    return (
      <article className="claim mt20">
        <div className="claim_cont">
          <h4>비용청구서</h4>
          <PayItemDataTable
            expandable
            columnTitles={[
              "행정동",
              "서비스 종류",
              "성명",
              "생년월일",
              "성별",
              "연락처",
              "주소",
              "서비스 결과",
              "총액",
              "증빙자료",
            ]}
            rows={[
              createRow({
                cells: [
                  createTextCell({ text: guDetailItem.targetInfo.hdong }),
                  createTextCell({ text: guDetailItem.targetInfo.serviceNm }),
                  createTextCell({ text: guDetailItem.targetInfo.trgterNm }),
                  createTextCell({ text: moment(guDetailItem.targetInfo.brthdy).format('yyyy-MM-DD') }),
                  createTextCell({ text: guDetailItem.targetInfo.gender }),
                  createTextCell({ text: guDetailItem.targetInfo.telno }),
                  createTextCell({
                    text: guDetailItem.targetInfo.adres + guDetailItem.targetInfo.adresDtl,
                    ellipsis: true,
                  }),
                  createTextCell({ text: guBillItem.serviceStatCd }),
                  createTextCell({
                    text: guBillItem.totalCost.toLocaleString() + " 원",
                    redtext: true,
                  }),
                  // createTextCell({ text: "증빙자료란" }),
                  createButtonCell({
                    type: isFileExist ? "underlined" : undefined,
                    label: isFileExist ? `${guBillItem.spyUid}_증빙자료` : '-',
                    link: isFileExist ? fileLink as HTMLAnchorElement : undefined
                  })
                ],
                expandContent: <DetailTable />,
              })]
            }
          />
        </div>
      </article>
    );
  };

  return (
    <main className="payment_page">
      {loading || loading2 ? null :
      <section>
        <TableTop />
        <PaymentInfoArea guItem={guBillItem} />
        <Table />
      </section>
    }
    </main>
  );
};

export default PaymentPage;
