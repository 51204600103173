import { instanceToPlain } from "class-transformer";
import moment from "moment";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import ServiceTargetAPI from "../../apis/ServiceTargetAPI";
import { nameValid, telValid, ValidCheck } from "../../App";
import CloseIcon from "../../assets/ic_close.png";
import useDebounce from "../../hooks/useDebounce";
import useMember from "../../hooks/useMember";
import ServiceTargetYear from "../../models/ServiceTargetYear";
import { DialogProps, useDialog } from "../DialogProvider";
import PostCodeDialog from "../PostCodeDialog";
import CancelScheduleDialog from "./CancelScheduleDialog";
import FinishDialog from "./FinishDialog";

/**
 * 작성자: 강우석
 * 작성일: 2023.02.23
 *
 * 일정 등록/수정 팝업
 * @param caseManagement 사례관리
 */
interface EditScheduleDialogProps extends DialogProps {
  caseManagement: ServiceTargetYear;
}

interface EditScheduleFormInput {
  csUid?: number;
  stUid: number;
  syUid: number;
  trgterNm: string;
  trgterCd: string;
  brthdy: string;
  gender: string;
  telno1: string;
  telno2: string;
  telno3: string;
  adres: string;
  adresDtl: string;
  hdong: string;
  lat: number;
  lng: number;
  counselDate: string;
}

const EditScheduleDialog = ({
  caseManagement,
  ...props
}: EditScheduleDialogProps) => {
  const navigate = useNavigate();
  const { member } = useMember();
  const { showDialog } = useDialog();
  const { register, handleSubmit, watch, setValue, getValues, setError, formState : {errors} } =
    useForm<EditScheduleFormInput>({
      defaultValues: {
        ...instanceToPlain(caseManagement),
        counselDate: moment(caseManagement.counselDate).format('yyyy-MM-DD')
      },
    });
  const telno1 = watch("telno1");
  const paramCheck = () => {
    if(!nameValid.test(getValues("trgterNm"))) {
      // setInputCheck({stat: false, msg: "올바른 대상자 이름을 입력하세요."})
      return {stat: false, msg: "올바른 대상자 이름을 입력하세요."}
    } else if(moment().diff(moment(getValues("brthdy")), 'hours') < 24) {
      // setInputCheck({stat: false, msg: "생년월일은 금일 이전으로 설정하세요."})
      return {stat: false, msg: "생년월일은 금일 이전으로 설정하세요."}
    } else if(!telValid.test(getValues("telno2"))) {
      // setInputCheck({stat: false, msg: "올바른 대상자 연락처를 입력하세요."})
      return {stat: false, msg: "올바른 대상자 연락처를 입력하세요."}
    } else if(!telValid.test(getValues("telno3"))) {
      // setInputCheck({stat: false, msg: "올바른 대상자 연락처를 입력하세요."})
      return {stat: false, msg: "올바른 대상자 연락처를 입력하세요."}
    } else {
      // setInputCheck({stat: true, msg: ""})
      return {stat: true, msg: ""}
    }
  }
  const [inputCheck, setInputCheck] = useState<ValidCheck>({stat: true, msg: "비정상적 값 입력됨"})
  const nameValidation = (e:any) => {
    nameValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }
  const telValidation = (e:any) => {
    telValid.test(e.target.value) ? setInputCheck({stat: true, msg: ""}) : setInputCheck({stat: false, msg: "비정상적 값 입력됨"})
  }

  const targetName = watch("trgterNm")
  const targetBrith = watch("brthdy")

  useEffect(() => {
    let trgterCd = targetName + targetBrith.replaceAll('-', '').slice(2)
    setValue("trgterCd", trgterCd)
  }, [targetName, targetBrith])

  const handleClose = () => {
    props.onClose?.();
  };

  const handleAddressSearch = () => {
    showDialog(PostCodeDialog, {
      onComplete(address) {
        setValue("adres", address.roadAddress);
        setValue("hdong", address.bname2);
      },
    });
  };

  const handleCancel = () => {
    showDialog(CancelScheduleDialog, {
      syUid: caseManagement.syUid,
    });
  };

  const handleFinish = () => {
    showDialog(FinishDialog, {
      syUid: caseManagement.syUid,
    });
  };

  const onSubmit: SubmitHandler<EditScheduleFormInput> = async (data) => {
    // console.log(data);
    let checkObj = paramCheck()
    if(!checkObj.stat) {
      window.alert(checkObj.msg)
      return
    }

    let trgterCd = data.trgterNm + data.brthdy.replaceAll('-', '').slice(2)
    setValue("trgterCd", trgterCd)
    // console.log(getValues("trgterCd"))

    // if (!member) {
    //   return;
    // }
    
    const success = await ServiceTargetAPI.insertSchedule({
      ...data,
      mbrUid: member ? member.mbrUid : 1, // TODO: 작성자 회원 키 입력
      telno: `${data.telno1}-${data.telno2}-${data.telno3}`,
    });

    if (success) {
      navigate(0);
    } else {
      console.log(">>> failure");
    }
  };

  const onError = () => {
    if(errors.trgterNm) {
      window.alert(errors.trgterNm.message)
    } else if(errors.telno2) {
      window.alert(errors.telno2.message)
    } else if(errors.telno3) {
      window.alert(errors.telno3.message)
    } else if(errors.adres) {
      window.alert(errors.adres.message)
    } else if(errors.counselDate) {
      window.alert(errors.counselDate.message)
    }
  }

  return (
    <div className="schedule_popup popup_black" {...props}>
      <div
        className="schedule_popup_inner popup_white"
        style={{ overflowY: "visible" }}
      >
        <div className="popup_tit">
          <h3>현장방문 일정 등록</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="default_input mt20">
              <h4>대상자</h4>
              <div className="write_box">
                <div
                  className="name mr8 w50_8"
                  style={{ position: "relative" }}
                >
                  <input
                    type="text"
                    className="essential input"
                    placeholder="성명"
                    list=""
                    role="combobox"
                    id="input1"
                    autoComplete="off"
                    // disabled={true}
                    {...register("trgterNm", {
                      required: "대상자 이름을 입력해주세요.",
                      onChange: (e:any) => {
                        nameValidation(e)
                      }
                    })}
                  />
                  {/* <datalist id="browsers1" style={{ display: "none" }}>
                    <option value="홍길동">홍길동</option>
                    <option value="홍길순">홍길순</option>
                    <option value="홍길">홍길</option>
                    <option value="박다혜">박다혜</option>
                  </datalist> */}
                </div>
                <div className="birth w50_8">
                  <input
                    type="date"
                    id="birth"
                    placeholder="생년월일"
                    max={"9999-12-31"}
                    // disabled={true}
                    {...register("brthdy", {
                      required: "생년월일을 입력해주세요."
                    })}
                    // onfocus="(this.type='date')"
                    // onblur="(this.type='text')"
                  />
                </div>
              </div>
            </div>
            <div className="default_input">
              <h4></h4>
              <div className="write_box">
                <label htmlFor="radio1" className="mr20">
                  <input
                    type="radio"
                    id="radio1"
                    value="남"
                    // disabled={true}
                    {...register("gender")}
                  />
                  남성
                </label>
                <label htmlFor="radio2">
                  <input
                    type="radio"
                    id="radio2"
                    value="여"
                    // disabled={true}
                    {...register("gender")}
                  />
                  여성
                </label>
              </div>
            </div>
            <div className="default_input tel">
              <h4></h4>
              <div className="write_box">
                <select
                className="essential"
                // disabled={true}
                  style={{ color: telno1 ? "#000" : "#9da0a8" }}
                  {...register("telno1")}
                >
                  <option value="">선택</option>
                  <option>010</option>
                  <option>011</option>
                  <option>017</option>
                  <option>018</option>
                  <option>019</option>
                </select>
                <span>-</span>
                <input type="tel"
                className="essential"
                placeholder="연락처"
                // disabled={true}
                {...register("telno2", {
                  required: "연락처를 입력해주세요.",
                  onChange: (e:any) => {
                    if(!telValid.test(e.target.value)) {
                      e.target.value = String(e.target.value).slice(0, -1)
                    }
                    telValidation(e)
                  }
                })} />
                <span>-</span>
                <input type="tel"
                className="essential"
                placeholder="연락처"
                // disabled={true}
                {...register("telno3", {
                  required: "연락처를 입력해주세요.",
                  onChange: (e:any) => {
                    if(!telValid.test(e.target.value)) {
                      e.target.value = String(e.target.value).slice(0, -1)
                    }
                    telValidation(e)
                  }
                })} />
              </div>
            </div>
            <div className="default_input mt8">
              <h4></h4>
              <div className="write_box">
                <div className="address">
                  <input
                    type="text"
                    className="mr8 essential"
                    placeholder="주소 입력"
                    disabled
                    {...register("adres", {
                      required: "주소를 입력해주세요."
                    })}
                  />
                  <button type="button" onClick={handleAddressSearch}>
                    주소검색
                  </button>
                </div>
                <input
                  type="text"
                  className="mt8"
                  placeholder="상세주소 입력"
                  {...register("adresDtl")}
                />
              </div>
            </div>
            <div className="default_input mt20">
              <h4>방문예정일</h4>
              <div className="write_box">
                <input
                  type="date"
                  id="birth"
                  placeholder="방문예정일"
                  max={"9999-12-31"}
                  {...register("counselDate", {
                    required: "방문일정을 등록해주세요.",
                    onChange: (e) => {
                      console.log(moment(e.target.value).diff(moment(caseManagement.crtDt), 'days'))
                      if(moment(e.target.value).diff(moment(caseManagement.crtDt), 'days') < 0) {
                        window.alert("방문예정일을 등록일 이후로 설정해주십시오.")
                        setValue("counselDate", moment(caseManagement.crtDt).add(0, 'days').format('yyyy-MM-DD'))
                      }
                    }
                  })}
                />
              </div>
            </div>
            
          {/* 등록 의무대상자 일때 종료 버튼*/}
          
            <div className="btn_wrap">
              {caseManagement.sbscWay !== "의무대상"
                  ? <button type="button" className="stop btn_stop" onClick={handleCancel}>
                        접수취소
                    </button>
                  : <button type="button" className="stop btn_stop" onClick={handleFinish}>
                        종료
                    </button>  
              }
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="submit" className="submit">
                저장
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditScheduleDialog;
