// import Gender from "./Gender";
// import { Expose } from 'class-transformer';
import { Expose } from "class-transformer";
import { constants } from "fs";

import CaseManager from "./CaseManager";
import Gender from "./Gender";
import ServiceTarget, { ServiceTargetRegistrationType } from "./ServiceTarget";

//export type BaseServiceTarget = Omit<ServiceTarget, ServiceTargetGetters>;

// type ServiceTargetGetters =
//   | "fullAddress"
//   | "telno1"
//   | "telno2"
//   | "telno3"
//   | "trgterCd";

export type ServiceStatus = "서비스접수" | "서비스중" | "서비스완료" | "중단";

export const ServiceStatusValues = ["서비스접수", "서비스중", "서비스완료", "중단"];

export type ServiceProgress = "담당자 배정 필요" | "결과보고 필요" | "완료";

export type ServiceAction = "결과보고";

class ServiceList {
  constructor(
    public stUid: number,
    public syUid: number,
    public spyUid: number,
    public csUid: number,
    public dptUid: number,
    public hdong: string,
    public trgterNm: string,
    public brthdy: string,
    public gender: Gender,
    public telno: string,
    public adres: string,
    public incomeLevel: string,
    public currentTimes: number,
    public times: number,
    public status: ServiceStatus,
    public mbrName: string | undefined,
    public mbrUid: number | undefined,
    public action: ServiceAction,
    public siUid: number,
    public departName: string,
    public serviceNm: string,
    public crtDt: string,
    public counselDate: string,
    public totalCount: number,
    public cateCd: string,
  ) {}

  @Expose({ toPlainOnly: true })
  get trgterCd(): string {
    const birthday = this.brthdy.split('-').reduce((acc, c) => acc + c.slice(-2), '');
    return this.trgterNm + birthday;
  }

  // get caseMng(): CaseManager[] {
  //   const mbrNameStr = this.mbrName.split(' ')
  //   let cMng:CaseManager[] = []
  //   mbrNameStr.map((item, idx) => {
  //     cMng.push(
  //       {
  //         mbrUid: idx,
  //         userName: item,
  //       }
  //       )
  //   })
  //   return cMng
  // }

  get hasManager(): boolean {
    return Boolean(this.mbrName);
  }

  get manager(): CaseManager | undefined {
    if (this.mbrName && this.mbrUid) {
      return { mbrUid: this.mbrUid, userName: this.mbrName };
    }
  }

  get progress(): ServiceProgress {
    if (!this.hasManager) {
      return "담당자 배정 필요";
    }
    return "완료";
  }

}

export const ServiceDepartName = [
  "시영종합 사회복지관",
  "쌍촌종합 사회복지관",
  "금호종합 사회복지관",
  "무진종합 사회복지관",
  "농성빛여울채 종합 사회복지관",
]
export default ServiceList;
