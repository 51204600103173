import React from 'react';
import ServicePlanDetails from '../../../../../models/ServicePlanDetails';

const UseServiceTab = (
    {
        details,
    }
    :
    {
        details: ServicePlanDetails,
    }
) => {
    return (
        <div className="use_service">
              <h4>이용중인 서비스</h4>
              <ul>
                {details?.targetInfo.usingServiceString &&
                  <li className="w100">
                    <p className="gray">기존돌봄</p>
                    <p>{details?.targetInfo.usingServiceString}</p>
                  </li>}
                {!details?.targetInfo.usingServiceString &&
                  <li className="w100">
                    <p className="gray">기존돌봄</p>
                    <p>-</p>
                  </li>}
                {details?.usingGjServiceNames &&
                  <li className="w100 mt8">
                    <p className="gray">광주+돌봄</p>
                    <p>{details?.usingGjServiceNames}</p>
                  </li>}
                {!details?.usingGjServiceNames &&
                  <li className="w100 mt8">
                    <p className="gray">광주+돌봄</p>
                    <p>-</p>
                  </li>}
                {details?.usingEmerServiceNames &&
                  <li className="w100 mt8">
                    <p className="gray">긴급돌봄</p>
                    <p>{details?.usingEmerServiceNames}</p>
                  </li>}

                {!details?.usingEmerServiceNames &&
                  <li className="w100 mt8">
                    <p className="gray">긴급돌봄</p>
                    <p>-</p>
                  </li>}
              </ul>
            </div>
    );
};

export default UseServiceTab;