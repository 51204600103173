import React, { useState } from "react";

import CloseIcon from "../../assets/ic_close.png";
import FileUtil from "../../utils/FileUtil";
import { DialogProps } from "../DialogProvider";

interface ClaimFileUploadDialog extends DialogProps {
  uploading: Function;
  spyUid: number;
}

/**
 * 청구페이지 파일 첨부
 * @param props
 */
const ClaimFileUploadDialog = ({uploading, spyUid, ...props} : ClaimFileUploadDialog) => {
  const [file, setFile] = useState<File>();
  const [url, setUrl] = useState("")

  const handleClose = () => {
    props.onClose?.();
  };

  // const handleTemplateDownloadClick = () => {
  //   // TODO: 템플릿 다운로드 기능 구현
  // };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("e.target.files : ", e.target.files)
    // console.log("e.target.files string : ", JSON.stringify({name : "이름", type: "타입"}))
    // let stringtest : "{\"name\": \"이름\", \"type\": \"타입\"}"
    if(e.target.files?.[0]) {
      FileUtil.getFilePath(e.target.files?.[0]).then((res) => {
        // console.log(res)
        setUrl(res)
      })
      
    }
    // console.log(e.target.files?.[0])
    setFile(e.target.files?.[0]);
  };

  const handleFileUpload = () => {
    if(file?.name) {
      uploading(file, url, spyUid)
      props.onClose?.();
    }
  }

  return (
    <div className="mypage_popup popup_black" {...props}>
      <div className="obligation_popup_inner popup_white">
        <div className="popup_tit">
          <h3>증빙자료 첨부</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="template_upload">
              <label htmlFor="template_file">파일찾기</label>
              <input
                className="upload-name"
                id="upload-file"
                placeholder="선택된 파일이 없습니다"
                value={file?.name}
              />
              <input
                type="file"
                id="template_file"
                name="uploadFile"
                onChange={handleFileChange}
              />
              {url !== "" ? <img src={url} style={{width: "30%", height: "30%"}}/> : null}
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="button" className="submit" onClick={handleFileUpload}>
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClaimFileUploadDialog;
