import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import ServiceTargetAPI from "../../../apis/ServiceTargetAPI";
import PlusIcon from "../../../assets/ic_plus.png";
import DataTable, {
  createButtonCell,
  createRow,
  createTextCell,
} from "../../../components/common/DataTable";
import SearchBar, {
  SearchField,
  SearchFormInput,
} from "../../../components/common/SearchBar";
import { useDialog } from "../../../dialogs/DialogProvider";
import AssignManagerDialog from "../../../dialogs/service-target/AssignManagerDialog";
import EditScheduleDialog from "../../../dialogs/service-target/EditScheduleDialog";
import EditServiceTargetDialog from "../../../dialogs/service-target/EditServiceTargetDialog";
import ObiligationDialog from "../../../dialogs/service-target/ObiligationDialog";
import useMember from "../../../hooks/useMember";
import { ServiceTargetRegistrationTypeValues } from "../../../models/ServiceTarget";
import ServiceTargetYear, {
  ServiceTargetYearHdong,
  ServiceTargetYearStatusValues,
} from "../../../models/ServiceTargetYear";
import moment, { Moment } from "moment";
import Member from "../../../models/Member";
import MemberAPI, { DepartVO } from "../../../apis/MemberAPI";

export type SearchTargetParam = {
  sbscWay?: string
  hdong?: string
  statCd?: string
  dbStatCd?: string
  hing?: string
  keyword?: string
  trgterNm?: string
  trgterCd?: string
  telno?: string
  adres?: string
}

/**
 * 작성자: 강우석
 * 작성일: 2023.02.21
 *
 * 돌봄대상자 페이지
 */
const ServiceTargetPage = () => {
  const { member } = useMember();
  const { page } = useParams();
  const navigate = useNavigate();
  const [serviceTargets, setServiceTargets] = useState(
    [] as ServiceTargetYear[]
  );
  const [count, setCount] = useState(0)
  const [searchParam, setSearchParam] = useState({} as SearchFormInput)
  const [departList, setDepartList] = useState([] as string[])

  useEffect(() => {
    MemberAPI.getDeptList({deptCd: "DONG"}).then((res) => {
      let tempList:string[] = []
      res.list.map((item) => {
        tempList.push(item.departName)
      })
      setDepartList(tempList)
    })
    if(member?.depart?.deptCd === "SI") {  //SI
      ServiceTargetAPI.getCount({
        page: Number(page),
        ...searchParam
      }).then((res) => {
      // console.log("count : ", res)
      setCount(res)
      })
    } else if (member?.depart?.deptCd === "GU") {  //GU
      ServiceTargetAPI.getCount({
        page: Number(page),
        ...searchParam
      }).then((res) => {
        // console.log("돌봄콜 의무대상 전입자 카운트 : ", res)
        setCount(res)
      })
    } else {  //DONG
      MemberAPI.getOneDept({
        dptUid : (member as Member).dptUid
      }).then((res) => {
        // console.log("기관정보 : ", res.data)
        ServiceTargetAPI.getCount({
          page: Number(page),
          hdong: res.data.departName,
          ...searchParam
        }).then((res) => {
          // console.log("창구(방,전) 전입자 카운트 : ", res)
          setCount(res)
        })
      })
    }


    if(member?.depart?.deptCd === "SI") {  //SI
      ServiceTargetAPI.getAll({
            page: Number(page),
            ...searchParam
          }).then((res) => {
            res.sort((a,b) => {
              if(a.statCd === "접수" && b.statCd !== "접수") {
                return -1
              } else if (a.statCd === "접수" && b.statCd === "접수") {
                let aCrt:Moment = moment(a.crtDt)
                let bCrt:Moment = moment(b.crtDt)
                return aCrt.diff(bCrt)
              }
              return 0
            })
            // console.log(res)
            setServiceTargets(res)
          });
    } else if (member?.depart?.deptCd === "GU") {  //GU
      ServiceTargetAPI.getAll({
        page: Number(page),
        ...searchParam,
      }).then((res) => {
        res.sort((a,b) => {
          if(a.statCd === "접수" && b.statCd !== "접수") {
            return -1
          } else if (a.statCd === "접수" && b.statCd === "접수") {
            let aCrt:Moment = moment(a.crtDt)
            let bCrt:Moment = moment(b.crtDt)
            return aCrt.diff(bCrt)
          }
          return 0
        })
        // console.log(res)
        setServiceTargets(res)
      });
    } else {  //DONG
      MemberAPI.getOneDept({
        dptUid : (member as Member).dptUid
      }).then((res) => {
        // console.log("기관정보 : ", res.data)
        ServiceTargetAPI.getAll({
          page: Number(page),
          hdong: res.data.departName,
          ...searchParam
        }).then((res) => {
          res.sort((a,b) => {
            if(a.statCd === "접수" && b.statCd !== "접수") {
              return -1
            } else if (a.statCd === "접수" && b.statCd === "접수") {
              let aCrt:Moment = moment(a.crtDt)
              let bCrt:Moment = moment(b.crtDt)
              return aCrt.diff(bCrt)
            }
            return 0
          })
          // console.log(res)
          setServiceTargets(res)
        });
      })
    }
    
    // console.log("member : ", member)
  }, [page, searchParam]);

  const handleSearch = (data: SearchFormInput) => {
    let parsed:SearchTargetParam = data
    // console.log(parsed)
    let params:SearchTargetParam = {}
    if(parsed.sbscWay?.length !== 0) {
      params.sbscWay = parsed.sbscWay
    }
    if(parsed.hdong?.length !== 0) {
      params.hdong = parsed.hdong
    }
    if(parsed.statCd?.length !== 0) {
      params.statCd = parsed.statCd
    }
    if(parsed.hing?.length !== 0 && parsed.keyword?.length !== 0) {
      switch(parsed.hing) {
        case "name" :
          params.trgterNm = parsed.keyword
          break
        case "brthdy" :
          params.trgterCd = parsed.keyword
          break
        case "telno" :
          params.telno = parsed.keyword
          break
        case "adres" :
          params.adres = parsed.keyword
          break
      }
    }
    setSearchParam(params)
    
  };

  const handlePageChange = (page: number) => {
    navigate(`/service-target/${page}`);
  };

  if (!member) {
    return <Navigate to="/login" />;
  }

  /**
 * 검색 필드
 */
const searchFields: SearchField[] = member.depart?.deptCd === "DONG" ?  //DONG
[
  {
    title: "등록구분",
    name: "sbscWay",
    type: "select",
    items: ServiceTargetRegistrationTypeValues.map((label) => ({ label })),
    // items: [
    //   {label : "창구(전화)", value: "창구(전화)"},
    //   {label : "창구(방문)", value: "창구(방문)"},
    //   {label : "전입자", value: "전입자"},
    // ] 
  },
  {
    title: "상태",
    name: "statCd",
    type: "select",
    items: ServiceTargetYearStatusValues.map((label) => ({ label })),
  },
  {
    title: "검색어",
    name: "hing",
    type: "search",
    items: [
      { label: "성명", value: "name" },
      { label: "성명+생년월일", value: "brthdy" },
      { label: "연락처", value: "telno" },
      { label: "주소", value: "adres" },
    ],
  },
] :
member.depart?.deptCd === "GU" ? //GU
[
  {
    title: "등록구분",
    name: "sbscWay",
    type: "select",
    items: ServiceTargetRegistrationTypeValues.map((label) => ({ label })),
    // items: 
    // [
    //   {label : "돌봄콜", value: "돌봄콜"},
    //   {label : "의무대상", value: "의무대상"},
    //   {label : "전입자", value: "전입자"},
    // ]
  },
  {
    title: "행정동",
    name: "hdong",
    type: "select",
    items: departList.map((label) => ({ label })),
  },
  {
    title: "상태",
    name: "statCd",
    type: "select",
    items: ServiceTargetYearStatusValues.map((label) => ({ label })),
  },
  {
    title: "검색어",
    name: "hing",
    type: "search",
    items: [
      { label: "성명", value: "name" },
      { label: "성명+생년월일", value: "brthdy" },
      { label: "연락처", value: "telno" },
      { label: "주소", value: "adres" },
    ],
  },
] :
[ //SI
  {
    title: "등록구분",
    name: "sbscWay",
    type: "select",
    items: ServiceTargetRegistrationTypeValues.map((label) => ({ label })),
  },
  {
    title: "행정동",
    name: "hdong",
    type: "select",
    items: departList.map((label) => ({ label })),
  },
  {
    title: "상태",
    name: "statCd",
    type: "select",
    items: ServiceTargetYearStatusValues.map((label) => ({ label })),
  },
  {
    title: "검색어",
    name: "hing",
    type: "search",
    items: [
      { label: "성명", value: "name" },
      { label: "성명+생년월일", value: "brthdy" },
      { label: "연락처", value: "telno" },
      { label: "주소", value: "adres" },
    ],
  },
]


  return (
    <main>
      <section>
        <SearchBar fields={searchFields} onSubmit={handleSearch} />
        <TableTop count={count} member={member} />
        <Table
          list={serviceTargets}
          page={Number(page)}
          totalPageCount={Math.ceil(count / 10)}
          onPageChange={handlePageChange}
          member={member}
        />
      </section>
    </main>
  );
};

/**
 * 테이블 상단의 버튼
 */
const TableTop = ({count, member} : {count:number, member:Member}) => {
  const { showDialog } = useDialog();

  const handleCounterReceiptClick = () => {
    showDialog(EditServiceTargetDialog, { type: "창구" });
  };

  const handleCallReceiptClick = () => {
    showDialog(EditServiceTargetDialog, { type: "돌봄콜" });
  };

  const handleObiligationClick = () => {
    showDialog(ObiligationDialog);
  };

  return (
    <article className="list_top">
      <div className="total">
        <p>
          총 <span>{count}</span>건
        </p>
      </div>
      <div className="list_btn_wrap">
        {(member.depart?.deptCd === "DONG" || member.depart?.deptCd === "SI") ?
        <button
          type="button"
          className="recive"
          onClick={handleCounterReceiptClick}
        >
          <img src={PlusIcon} alt="접수 아이콘" />
          창구 접수
        </button>
        : 
        null
        }
        {(member.depart?.deptCd === "GU" || member.depart?.deptCd === "SI") ? 
        <>
          <button
              type="button"
              className="recive"
              onClick={handleCallReceiptClick}
            >
              <img src={PlusIcon} alt="접수 아이콘" />
              돌봄콜 접수
          </button>
          <button
              type="button"
              className="recive btn_obligation"
              onClick={handleObiligationClick}
            >
              <img src={PlusIcon} alt="접수 아이콘" />
              의무대상자 등록
          </button>
        </>
        :  
        null
        }
      </div>
    </article>
  );
};

/**
 * 테이블
 * @param list 사례관리 목록
 * @param page 현재 페이지
 * @param onPageChange 페이지가 변경되었을 때 호출되는 함수
 */
const Table = ({
  list,
  page,
  totalPageCount,
  onPageChange,
  member,
}: {
  list: ServiceTargetYear[];
  page: number;
  totalPageCount: number;
  onPageChange: (page: number) => void;
  member: Member;
}) => {
  const { showDialog } = useDialog();

  const handleAssignManager = (caseManagement: ServiceTargetYear) => {
    if(caseManagement.statCd !== "돌봄계획" && caseManagement.statCd !== "사례회의(동)" && caseManagement.statCd !== "사례회의(권역)" && caseManagement.statCd !== "돌봄계획확정"
    && caseManagement.statCd !== "서비스의뢰" && caseManagement.statCd !== "재수립") {
      if(caseManagement.counselDate && caseManagement.statCd === "사례종결") {
        return
      } else {
          showDialog(AssignManagerDialog, {
          syUid: caseManagement.syUid,
          initialManagers: caseManagement.managers,
        });
      }
    }
    
  };

  const handleEditSchedule = (caseManagement: ServiceTargetYear) => {
    if(caseManagement.counselDate && caseManagement.statCd === "사례종결") {
      return
    } else {
      showDialog(EditScheduleDialog, {
        caseManagement: caseManagement,
      });
    }
  };

  const handleEditServiceTarget = (caseManagement: ServiceTargetYear) => {
    showDialog(EditServiceTargetDialog, {
      type: caseManagement.sbscWay === "돌봄콜" ? "돌봄콜" : "창구",
      caseManagement,
    });
  };

  // TODO: 구에서만 행정동이 보이게 구현
  return (
    <DataTable
      expandable
      columnTitles={member.depart?.deptCd === "SI" || member.depart?.deptCd === "GU" ?
        [
        "등록구분",
        "행정동",
        "성명",
        "생년월일",
        "성별",
        "연락처",
        "주소",
        "상태(처리기한)",
        "담당자",
        "방문(예정)일",
      ] :
      [
        "등록구분",
        "성명",
        "생년월일",
        "성별",
        "연락처",
        "주소",
        "상태(처리기한)",
        "담당자",
        "방문(예정)일",
      ] 
    }
      rows={list.map((caseManagement) =>
        createRow({
          cells: member.depart?.deptCd !== "SI" ? member.depart?.deptCd === "GU" ? //구
          [
            createTextCell({ text: caseManagement.sbscWay }),
            createTextCell({ text: caseManagement.hdong }),
            createTextCell({ text: caseManagement.trgterNm }),
            createTextCell({ text: caseManagement.brthdy }),
            createTextCell({ text: caseManagement.gender }),
            createTextCell({ text: caseManagement.telno }),
            createTextCell({
              text: caseManagement.fullAddress,
              ellipsis: true,
            }),
            createTextCell({ text: caseManagement.statCd, d_day: caseManagement.statCd === "접수" && !caseManagement.sbscWay.includes("의무대상") ? moment().diff(moment(caseManagement.crtDt), 'days') : -1 }),
            createTextCell({text : caseManagement.hasManager ? caseManagement.managers.map((manager) => manager.userName).join(",") : "담당자 배정 필요"}),
            createTextCell({text : caseManagement.progress === "일정 등록 필요" ? caseManagement.progress : caseManagement.counselDate ? moment(caseManagement.counselDate).format("yyyy-MM-DD(dd)") : "-"}),
          ] : //동
          [
            createTextCell({ text: caseManagement.sbscWay }),
            createTextCell({ text: caseManagement.trgterNm }),
            createTextCell({ text: caseManagement.brthdy }),
            createTextCell({ text: caseManagement.gender }),
            createTextCell({ text: caseManagement.telno }),
            createTextCell({
              text: caseManagement.fullAddress,
              ellipsis: true,
            }),
            createTextCell({ text: caseManagement.statCd, d_day: caseManagement.statCd === "접수" && !caseManagement.sbscWay.includes("의무대상") ? moment().diff(moment(caseManagement.crtDt), 'days') : -1 }),
            createButtonCell({
              type:
                caseManagement.progress === "담당자 배정 필요"
                  ? "outlined"
                  : "underlined",
              label: caseManagement.hasManager
                ? caseManagement.managers
                    .map((manager) => manager.userName)
                    .join(",")
                : "배정",
              onClick: () => handleAssignManager(caseManagement),
            }),
            createButtonCell({
              type:
                caseManagement.progress === "담당자 배정 필요"
                  ? undefined
                  : caseManagement.progress === "일정 등록 필요"
                  ? "outlined"
                  : "underlined",
              label:
                caseManagement.progress === "담당자 배정 필요"
                  ? "-"
                  : caseManagement.progress === "일정 등록 필요"
                  ? "일정등록"
                  : `${moment(caseManagement.counselDate).format("yyyy-MM-DD(dd)")}`,
              onClick: () => handleEditSchedule(caseManagement),
            }),
          ] : //시
          [
            createTextCell({ text: caseManagement.sbscWay }),
            createTextCell({ text: caseManagement.hdong }),
            createTextCell({ text: caseManagement.trgterNm }),
            createTextCell({ text: caseManagement.brthdy }),
            createTextCell({ text: caseManagement.gender }),
            createTextCell({ text: caseManagement.telno }),
            createTextCell({
              text: caseManagement.fullAddress,
              ellipsis: true,
            }),
            createTextCell({ text: caseManagement.statCd, d_day: caseManagement.statCd === "접수" && !caseManagement.sbscWay.includes("의무대상") ? moment().diff(moment(caseManagement.crtDt), 'days') : -1 }),
            createButtonCell({
              type:
                caseManagement.progress === "담당자 배정 필요"
                  ? "outlined"
                  : "underlined",
              label: caseManagement.hasManager
                ? caseManagement.managers
                    .map((manager) => manager.userName)
                    .join(",")
                : "배정",
              onClick: () => handleAssignManager(caseManagement),
            }),
            createButtonCell({
              type:
                caseManagement.progress === "담당자 배정 필요"
                  ? undefined
                  : caseManagement.progress === "일정 등록 필요"
                  ? "outlined"
                  : "underlined",
              label:
                caseManagement.progress === "담당자 배정 필요"
                  ? "-"
                  : caseManagement.progress === "일정 등록 필요"
                  ? "일정등록"
                  : `${moment(caseManagement.counselDate).format("yyyy-MM-DD(dd)")}`,
              onClick: () => handleEditSchedule(caseManagement),
            }),
          ]
          ,
          expandContent: (
            <ul>
              <li className="btn_edit">
                <button
                  className="btn_gray"
                  onClick={() => handleEditServiceTarget(caseManagement)}
                >
                  수정
                </button>
              </li>
              <li>
                <p className="gray">접수일시</p>
                <p>{caseManagement.crtDt}</p>
              </li>
              {caseManagement.sbscWay !== "의무대상" ? 
              <>
                <li>
                  <p className="gray">접수담당</p>
                  <p>{caseManagement.crtNm}</p>
                </li>
                <li>
                  <p className="gray">신청인</p>
                  <p>{caseManagement.applcntNm}</p>
                </li>
                <li>
                  <p className="gray">대상자와 관계</p>
                  <p>{caseManagement.applcntRel}</p>
                </li>
                <li>
                  <p className="gray">신청인 연락처</p>
                  <p>{caseManagement.applcntTel}</p>
                </li>
                <li className="w100 mt8">
                  <p className="gray">신청사유</p>
                  <p>{caseManagement.applcntReason}</p>
                </li>
                {caseManagement.applcntEtc ? 
                caseManagement.applcntEtc.length > 0 ?
                <li>
                  <p className="gray">참고사항</p>
                  <p>{caseManagement.applcntEtc}</p>
                </li> : null
                :
                null}
              </>
              : null}
              
            </ul>
          ),
        })
      )}
      page={page}
      totalPageCount={totalPageCount}
      onPageChange={onPageChange}
    />
  );
};

export default ServiceTargetPage;
