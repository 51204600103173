import { Type } from "class-transformer";
import ConferenceInfo from "./ConferenceInfo";
import ConferencePlan from "./ConferencePlan";

class DongConference {
    @Type(()=> ConferencePlan)
    public carePlan: ConferencePlan[];
    public dongMeeting : ConferenceInfo;
    public guMeeting : ConferenceInfo;
    public gjList : ConferencePlan[];
    public emerList : ConferencePlan[];
    public basicList : ConferencePlan[];
    constructor(
        carePlan: ConferencePlan[],
        dongMeeting : ConferenceInfo,
        guMeeting : ConferenceInfo,
        gjList : ConferencePlan[],
        emerList : ConferencePlan[],
        basicList : ConferencePlan[],
    ){
        this.carePlan = carePlan
        this.dongMeeting = dongMeeting
        this.guMeeting = guMeeting
        this.gjList = gjList
        this.emerList = emerList
        this.basicList = basicList
    }
}
export default DongConference;