import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import EvaluationAPI from "../../apis/EvaluationAPI";
import CloseIcon from "../../assets/ic_close.png";
import { DialogProps } from "../DialogProvider";

interface AfterCareDialogProps extends DialogProps {
  spyUid: number;
}

const AfterCareDialog = ({ spyUid, ...props }: AfterCareDialogProps) => {
  const handleClose = () => {
    props.onClose?.();
  };
  const navigate = useNavigate();

  const [processId, setProcessId] = useState(1)
  
  // spyUid랑 processId 보내주면 됨
  const handleSubmit = async() => {
    if(processId === 1) {
      if(window.confirm("돌봄계획을 재수립하시겠습니까?")) {
        const success = await EvaluationAPI.insertAfterCare({
          spyUid, processId
        });
    
        if(success){
          console.log("processId : " ,processId);
          alert("재수립 처리 완료");
          navigate(0);
        }else{
          alert("재수립 처리 실패");
        }
      } else {
        return
      }
    } else {
      if(window.confirm("사례종결 처리하시겠습니까?")) {
        const success = await EvaluationAPI.insertAfterCare({
          spyUid, processId
        });
    
        if(success){
          console.log("processId : " ,processId);
          alert("사례종결 처리 완료");
          navigate(0);
        }else{
          alert("사례종결 처리 실패");
        }
      } else {
        return
      }
    }
  };

  return (
    <div className="assign_popup popup_black" {...props}>
      <div
        className="assign_popup_inner popup_white"
        style={{ overflowY: "visible" }}
      >
        <div className="popup_tit">
          <h3>사후관리</h3>
          <a href="#" className="close" onClick={handleClose}>
            <img src={CloseIcon} alt="닫기" />
          </a>
        </div>
        <div className="popup_con">
          <form >
            <div className="default_input">
              <h4 style={{fontSize:13, fontWeight: 400}}>상태</h4>
              
              <div className="write_box">
                <select className="w100" 
                onChange={(e) => setProcessId(Number(e.target.value))}
                style={{ color: 'rgb(0, 0, 0)', height:40, border: '1px solid #e6e8ea', backgroundColor: '#f6f7f9', fontSize:'15'}} >
                  <option value="1">돌봄계획 재수립</option>
                  <option value="2">사례종결</option>
                </select>
              </div>
            </div>
            <div className="btn_wrap">
              <button type="button" className="cancel" onClick={handleClose}>
                취소
              </button>
              <button type="submit" className="submit" onClick={handleSubmit}>
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AfterCareDialog;
