import axios from "axios";

import { ServiceManagerEntity } from "../models/ServiceManager";
import BaseServiceManager from "../models/BaseServiceManager";
import CaseManager from "../models/CaseManager";
import { plainToInstance } from "class-transformer";
import ServiceList from "../models/ServiceList";
import ServiceDetail from "../models/ServiceDetail";
import { serialize } from "object-to-formdata";
import SettingList from "../models/SettingList";
/**
 * 작성자: 김혜정
 * 작성일: 2023.03.21
 *
 * 환경설정 API
 */

 const getCount = () => {
  return axios.get("/api/service/count")
  .then((res) => res.data)
}

//------------------------------------------------------목록 조회
 type GetAllParams = {
  page?: number;
  // regiType?: string;
  // hdong?: string;
  // status?: string;
  // searchTarget?: string;
  keyword?: string;
  
  totalCount?: number;
  deptCd?: string
  departName?: string
  tel?: string
  locality?: string
  dptUid?: number
  userName? : string;
};

//  기관정보 목록 조회
const getAllOrg = (params: GetAllParams) => {
  return axios
    .get("/api/preferences/orgList", { params })
    .then((response) => {
      // console.log("response : ", response)
      return plainToInstance(SettingList, response.data)
    });
};

//  제공기관 관리 목록 조회
const getAllProvider = (params: GetAllParams) => {
  return axios
    .get("/api/preferences/providerList", { params })
    .then((response) => plainToInstance(SettingList, response.data));
};

//  서비스 목록 조회
const getAllService = (params: GetAllParams) => {
  return axios
    .get("/api/preferences/serviceList", { params })
    .then((response) => plainToInstance(SettingList, response.data));
};

//  회원 목록 조회
const getAllMember = (params: GetAllParams) => {
  return axios
    .get("/api/preferences/memberList", { params })
    .then((response) => plainToInstance(SettingList, response.data));
};

// 회원 관리 > 검색 > 소속 리스트 조회
type GetDepartSelectParams = {
  dptUid : number;
  departName : string;
  
};

export class DepartSelect {
  constructor (
    public dptUid: number,
    public departName: string,
  ) {}
  
}

const getDepartSelect = () => {
  return axios
    .get("/api/preferences/getDepartSelectBoxList")
    .then((response) => {
      // console.log("response : ", response)
      return plainToInstance(DepartSelect, response.data)
    });
};

//-----------------------------------------------------------등록
// 기관정보 > 기관 등록
interface InsertOrgParams {
  deptCd? : string;
  departName? : string;
  tel? : string;
  locality? : string;
  crtUid? : number;
  adres?: string;
  adresDtl?: string;
  delUid?: number;
}

const insertOrg = (params: InsertOrgParams) => {
  return axios
    .post("/api/preferences/org", serialize(params))
    .then((response) => response.data["success"] as boolean);
};

// 제공기관 관리 > 기관 등록
interface InsertProviderParams {
  dptUid : number | undefined;
  departName : string;
  deptCd? : string;
  tel? : string;
  locality? : string;
  createDt : string | undefined;
  operType : string;
  owner : string;
  companyNo : string;
  accountHolder : string;
  bank : string;
  accountNo : string;
  crtUid :number;
  serviceKeys: number[];
  delUid?: number;
}

const insertProvider = (params: InsertProviderParams) => {
  return axios
    .post("/api/preferences/provider", serialize(params))
    .then((response) => response.data["success"] as boolean);
};

// 제공기관 관리 > 기관 등록 > 서비스 목록 조회
type GetServiceSelectParams = {
  siUid : number;
  serviceNm : string;
};

export class ServiceSelect {
  constructor (
    public siUid: number,
    public serviceNm: string,
  ) {}
  
}

const getServiceSelect = () => {
  return axios
    .get("/api/preferences/getServiceSelectBoxList")
    .then((response) => {
      // console.log("response : ", response)
      return plainToInstance(ServiceSelect, response.data)
    });
};

const getServiceSelectAll = () => {
  return axios
    .get("/api/preferences/getServiceSelectBoxListAll")
    .then((response) => {
      // console.log("response : ", response)
      return plainToInstance(ServiceSelect, response.data)
    });
};

// 서비스 관리 > 서비스 등록
interface InsertServiceParams {
  siUid? : number;
  dptUid? : number;
  dbGubunCd? : string;
  serviceGbn? : string;
  serviceNm? : string;
  useStatus? : string;
  // cateCd? : number;
}

const insertService = (params: InsertServiceParams) => {
  return axios
    .post("/api/preferences/service", serialize(params))
    .then((response) => response.data as DeleteResult);
};

//-----------------------------------------------------단일 조회

// 기관 단일조회
interface GetOrgParams {
  dptUid : number;
}

const getOrg = (params: GetOrgParams) => {
  return axios
    .get("/api/preferences/org", { params })
    .then((response) => {
      // console.log("response : ", response)
      return plainToInstance(SettingList, response.data)
    });
};

// 제공기관 단일조회
interface GetProviderParams {
  dptUid : number;
}

const getProvider = (params: GetProviderParams) => {
  return axios
    .get("/api/preferences/provider", { params })
    .then((response) => {
      // console.log("response : ", response)
      return plainToInstance(SettingList, response.data)
    });
};

// 서비스 단일조회
interface GetServiceParams {
  siUid : number;
}

const getService = (params: GetServiceParams) => {
  return axios
    .get("/api/preferences/service", { params })
    .then((response) => {
      // console.log("response : ", response)
      return response.data as SettingList
    })

};

// 회원 단일조회
interface GetMemberParams {
  mbrUid : number;
}

const getMember = (params: GetMemberParams) => {
  return axios
    .get("/api/preferences/member", { params })
    .then((response) => {
      // console.log("response : ", response)
      return plainToInstance(SettingList, response.data)
    })

};


//-----------------------------------------------------삭제
// 기관정보 > 삭제
interface DeleteOrgParams {
  dptUid?: number;
  delUid?: number;
}

const deleteOrg = (params: DeleteOrgParams) => {
  return axios
  .post("/api/preferences/delete-org", serialize(params))
  .then((response) => response.data["success"] as boolean);
};

// 제공기관 관리 > 삭제
interface DeleteProviderParams {
  // soUid?: number;
  dptUid?: number;
}

const deleteProvider = (params: DeleteProviderParams) => {
  return axios
  .post("/api/preferences/delete-provider", serialize(params))
  .then((response) => response.data["success"] as boolean);
};

// 서비스 관리 > 삭제
interface DeleteServiceParams {
  siUid?: number;
}

export interface DeleteResult {
  success: boolean,
  errMessage: string,
  message: string,
}

const deleteService = (params: DeleteServiceParams) => {
  return axios
  .post("/api/preferences/delete-service", serialize(params))
  .then((response) => {
    // console.log(response)
    return response.data as DeleteResult
  });
};

// 회원 관리 > 삭제
interface DeleteMemberParams {
  mbrUid?: number;
  delUid?: number;
}

const deleteMember = (params: DeleteMemberParams) => {
  return axios
  .post("/api/preferences/delete-member", serialize(params))
  .then((response) => response.data["success"] as boolean);
};



interface InsertScheduleParams {
  syUid: number;
  stUid: number;
  mbrUid: number;
  trgterNm: string;
  trgterCd: string;
  brthdy: string;
  gender: string;
  telno: string;
  adres: string;
  hdong: string;
  lat: number;
  lng: number;
  counselDate: string;
}

const insertSchedule = (params: InsertScheduleParams) => {
  return axios
    .post("/api/serviceTarget/counselScheduleRegist", { params })
    .then((response) => response.data["success"] as boolean);
};

interface UpdateScheduleParams extends InsertScheduleParams {
  csUid: number;
}

const updateSchedule = (params: UpdateScheduleParams) => {
  return axios
    .post("/api/serviceTarget/counselScheduleRegist", { params })
    .then((response) => response.data["success"] as boolean);
};

type ApproveMember = {
  mbrUid: number,
}
const approve = (params: ApproveMember) => {
  return axios.post("/api/member/approve", serialize(params))
  .then((res) => {
    return res.data as {success: boolean, message: string}
  })
}

const SettingAPI = {
  getAllOrg,
  getAllProvider,
  getAllService,
  getAllMember,
  getDepartSelect,
  insertOrg,
  insertProvider,
  getServiceSelect,
  insertService,
  getOrg,
  getProvider,
  getService,
  getMember,
  deleteOrg,
  deleteProvider,
  deleteService,
  deleteMember,
  insertSchedule,
  updateSchedule,
  getCount,
  approve,
  getServiceSelectAll,
};

export default SettingAPI;
export type { GetAllParams };
