import moment, { Moment } from "moment";

// import { Expose } from 'class-transformer';
// import CaseManager from "./CaseManager";
// import ServiceTarget from "./ServiceTarget";
import Gender from "./Gender";
import ServicePlan from "./ServicePlan";
import ServicePlanItem from "./ServicePlanItem";
import ServiceTarget from "./ServiceTarget";

class ServiceMenuList {
  constructor(
    public stUid: number,
    public syUid: number,
    public hdong: string,
    public org: string,
    public trgterNm: string,
    public brthdy: string,
    public trgterCd: string,
    public gender: string,
    public telno: string,
    public adres: string,
    public adresDtl: string,
    public beginDt: string,
    public startIndex: number,
    public pageSize: number,
    public page: number,
    public totalCount: number,
    public searchType: string,
    public searchKeyword: string,
    public spyList: SpyList[],  
    public crtDt: string,
    public statCd: string,
  ){} 
}

export class SpyList {
  static spyUid(spyUid: any): number {
    throw new Error("Method not implemented.");
  }
  constructor(
      public stUid: number,
      public syUid: number,
      public spyUid: number,
      public spiUid: number,
      public siUid: number,
      public csUid: number,
      public dptUid: number,
      public hdong: string,
      public departName: string,
      public serviceNm: string,
      public trgterNm: string,
      public brthdy: string,
      public gender: string,
      public telno: string,
      public adres: string,
      public adresDtl: string,
      public incomeLevel: string,
      public currentTimes: number,
      public times: number,
      public status: string,
      public mbrName: string,
      public mbrUid: number,
      public beginDt: string,
      public endDt: string,
      public crtDt: string,
      public cateCd: string,
      public totalCount: number,
      public cycleUnit?: string,
      public weekDay?: string,
      public stopRsn?: string,
  ) {}
}

export default ServiceMenuList;
