import moment from "moment";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ExpenseModelAPI from "../../../apis/ExpenseModelAPI";
import ServiceAPI from "../../../apis/ServiceAPI";
import ServicePlanAPI from "../../../apis/ServicePlanAPI";

import PlusIcon from "../../../assets/ic_plus.png";
import BillDataTable, {
  createBillButtonCell,
  createBillRow, createBillTextCell,
} from "../../../components/common/BillDataTable";
import { createTextCell, } from "../../../components/common/DataTable";
import PaymentDataTable, {
  createPaymentRow,
  createPaymentTextCell,
} from "../../../components/common/PaymentDataTable";
import SearchBar, { SearchField, SearchFormInput } from "../../../components/common/SearchBar";
import { useDialog } from "../../../dialogs/DialogProvider";
import NeedsAssessmentDialog from "../../../dialogs/service/NeedsAssessmentDialog";
import useMember from "../../../hooks/useMember";
import ExpenseModel from "../../../models/bill/ExpenseModel";
import GuBillModel from "../../../models/bill/GuBillModel";
import OrgDetailModel from "../../../models/bill/OrgDetailModel";
import NaEvaluationInfo from "../../../models/NaEvaluationInfo";
import ServiceList from "../../../models/ServiceList";

import "./ExpensePage.scss";
import MemberAPI from "../../../apis/MemberAPI";

export type BillSearchParam = {
  hdong?: string
  billStatus?: string
  serviceGbn?: string
  searchType?: string
  keyword?: string
  trgterNm?: string
  trgterCd?: string
  telno?: string
  departName?: string
  serviceStatCd?: string
  searchKeyword?: string
}


/**
 * 작성자: 홍준표
 * 작성일: 2023.02.24
 *
 * 비용 페이지
 */
const ExpensePage = () => {
  const { page } = useParams();
  const {member} = useMember()

  //시, 구
  const [guBillList, setGuBillList] = useState([] as GuBillModel[])
  //기관
  const [expenseModel, setExpenseModel] = useState([] as ExpenseModel[])
  const [count, setCount] = useState(0)
  const [hdongList, setHdongList] = useState([] as string[])
  const [orgList, setOrgList] = useState([] as {departName: string, dptUid: number}[])
  //검색
  const [searchParam, setSearchParam] = useState({} as SearchFormInput)

  //청구가능 목록(제공기관)
  // const [orgBillList, setOrgBillList] = useState([] as OrgBillModel[])

  const [loading, setLoading] = useState(true)

  const handlePageChange = (page: number) => {
    navigate(`/expense/${page}`);
  };

  useEffect(() => {
    MemberAPI.getDeptList({deptCd: "DONG"}).then((res) => {
      let tempList:string[] = []
      res.list.map((item) => {
        tempList.push(item.departName)
      })
      setHdongList(tempList)
    })
    MemberAPI.getDeptList({deptCd: "ORG"}).then((res) => {
      let tempList:{departName: string, dptUid: number}[] = []
      res.list.map((item) => {
        tempList.push({departName: item.departName, dptUid: item.dptUid})
      })
      setOrgList(tempList)
    })

    if(member?.depart?.deptCd === "ORG") {
      // 비용 목록조회 api for 기관
      ExpenseModelAPI.getCount({
        page: Number(page),
        dptUid: member?.dptUid,
        ...searchParam,
      }).then((res) => {
        setCount(res)
      })
      ExpenseModelAPI.getAll({
        page: Number(page),
        dptUid: member?.dptUid,
        ...searchParam,
      }).then((res) => {
        setExpenseModel(res)
        // console.log(res)
        setLoading(false)
      })
    } else {  //구
      ExpenseModelAPI.getGuListCount({
        page: Number(page),
        ...searchParam,
      }).then((res) => {
        setCount(res)
      })
      ExpenseModelAPI.getGuList({
        page: Number(page),
        ...searchParam,
      }).then((res) => {
        setGuBillList(res)
        // console.log("list : ",res)
        setLoading(false)
      })
    }
    
  }, [page, searchParam]);

  const handleSearch = (data: SearchFormInput) => {
    let parsed: BillSearchParam = data
    // console.log(parsed)
    let params: BillSearchParam = {}
    if (parsed.hdong?.length !== 0) {
      params.hdong = parsed.hdong
    }
    if (parsed.departName?.length !== 0) {
      params.departName = parsed.departName
    }
    if (parsed.billStatus?.length !== 0) {
      params.billStatus = parsed.billStatus
    }
    if (parsed.searchType?.length !== 0 && parsed.keyword?.length !== 0) {
      params.searchType = parsed.searchType
      params.searchKeyword = parsed.keyword
    }
    // console.log("params : ", params)
    setSearchParam(params)
  };


  const navigate = useNavigate();
  const onClick = () => {
    navigate("/expense/claim");
  };

  /**
   * 테이블 상단
   */
  const TableTop = () => {
    return (
      <article className="list_top">
        <div className="total">
          <p>
            총 <span>{count}</span>건
          </p>
        </div>
        {member?.depart?.deptCd === "ORG" ? (
          <div className="list_btn_wrap">
            <button type="button" className="recive" onClick={onClick}>
              <img src={PlusIcon} alt="청구 아이콘" />
              청구
            </button>
          </div>
        ) : null}
      </article>
    );
  };

   /**
   * 테이블
   * @param list 사례관리 목록
   */
   const TableAPI = ({ list }: { list: ExpenseModel[] }) => {
    return (
      <BillDataTable
        expandable
        columnTitles={[
          "행정동",
          "서비스 종류",
          "성명",
          "생년월일",
          "성별",
          "연락처",
          "주소",
          "상태",
        ]}
        rows={list.map((caseManagement) =>
          createBillRow({
            caseManagement: caseManagement,
            cells: [
              createBillTextCell({ text: caseManagement.hdong }),
              createBillTextCell({ text: caseManagement.serviceNm }),
              createBillTextCell({ text: caseManagement.trgterNm }),
              createBillTextCell({ text: moment(caseManagement.brthdy).format("yyyy.MM.DD") }),
              createBillTextCell({ text: caseManagement.gender }),
              createBillTextCell({ text: caseManagement.telno }),
              createBillTextCell({ text: caseManagement.adres + (caseManagement.adresDtl ? caseManagement.adresDtl : ""), ellipsis: true }),
              createTextCell({ text: caseManagement.billStatus }),
            ],
            expandContent: <Expended caseManagement={caseManagement}/>,
          })
        )}
        page={Number(page)}
        totalPageCount={Math.ceil(count / 10)}
        onPageChange={handlePageChange}
      />
    );
  };

  
  // const [belong, setBelong] = useState("제공기관");
  // const changeBelong = () => {
  //   switch (belong) {
  //     case "제공기관":
  //       setBelong("구");
  //       break;
  //     case "구":
  //       setBelong("제공기관");
  //       break;
  //   }
  // };

  const searchFields: SearchField[] = member?.depart?.deptCd === "ORG" ? [
    {
      title: "행정동",
      name: "hdong",
      type: "select",
      items: hdongList.map((label) => ({ label })),
    },
    {
      title: "상태",
      name: "billStatus",
      type: "select",
      items: [
        { label: "미청구", value: "미청구" },
        { label: "청구", value: "청구" },
        { label: "수가수령", value: "수가수령" },
      ],
    },
    {
      title: "검색어",
      name: "searchType",
      type: "search",
      items: [
        { label: "성명", value: "name" },
        { label: "성명+생년월일", value: "birth" },
        { label: "연락처", value: "telno" },
      ],
    },
  ] :
      //시 구
      [
        {
          title: "기관",
          name: "departName",
          type: "select",
          items: orgList.map((label) => ({ label: label.departName, value: label.departName })),
        },
        {
          title: "행정동",
          name: "hdong",
          type: "select",
          items: hdongList.map((label) => ({ label })),
        },
        {
          title: "상태",
          name: "billStatus",
          type: "select",
          items: [
            { label: "지급대기", value: "지급대기" },
            { label: "지급완료", value: "지급완료" },
          ],
        },
        {
          title: "검색어",
          name: "searchType",
          type: "search",
          items: [
            { label: "대상자", value: "name" },
            { label: "대상자+생년월일", value: "birth" },
          ],
        },
      ]
  if (!member) {
    return <Navigate to="/login" />;
  }

  return (
    <main className="expense_page">
      <section>
        <SearchBar fields={searchFields} onSubmit={handleSearch} />
        <TableTop />
        {/* {belong === "제공기관" ? (
          <Table list={caseManagements} />
        ) : (
          <TableAgency list={agencyManagements} />
        )}
        <button className="change_btn" onClick={changeBelong}>
          소속 변경 (현재 소속 : {belong})
        </button> */}
        {loading ? null : 
        member?.depart?.deptCd === "ORG" ?
          <TableAPI list={expenseModel} /> :
          <TableAgency list={guBillList} page={Number(page)} totalCount={count} />
        }
      </section>
    </main>
  );
};

const Expended = ({caseManagement} : {caseManagement: ExpenseModel}) => {
  const { showDialog } = useDialog();
    const handleNADialog = (csUid:number, evalDate: string) => {
      // console.log(`csUid === ${csUid}`)
      // console.log(service)
      if(csUid === undefined) {
        // console.log(`csUid === ${csUid}`)
        return false
      }
      ServicePlanAPI.getEvaluationData({
        csUid: csUid
      }).then((res) => {
        if(!res) {
          // console.log("필요도평가 정보 불러오기 실패")
        } else {
          // console.log("res : ", res)
          showDialog(NeedsAssessmentDialog, { naData : res as NaEvaluationInfo, evalDate: evalDate });
        }
      })
    };
    const [orgDetail, setOrgDetail] = useState({} as OrgDetailModel)
    const [service, setService] = useState({} as ServiceList);
    const [dLoad, setDLoad] = useState(false)
    const [dLoad2, setDLoad2] = useState(false)
    

    // useEffect(() => {
    //   // console.log("expenseModel : ", caseManagement.billStatus)
    //   ExpenseModelAPI.getOrgDetail({
    //     spyUid: caseManagement.spyUid,
    //     billStatus: caseManagement.billStatus,
    //   }).then((res) => {
    //     setOrgDetail(res)
    //     // console.log("detail : ", res)
    //     // setDLoad(false)
    //   })
    //   ServiceAPI.getOne({
    //     spyUid: caseManagement.spyUid,
    //   }).then((res) => {
    //     // console.log("service : ",res)
    //     setService(res.pop() as ServiceList)
    //     setDLoad2(false)
    //   })
    // }, [])

  return (
    <ul>
      {dLoad || dLoad2 ? null : 
      <>
        <li>
          <p className="gray">서비스 종류</p>
          <p>
            {caseManagement.detailInfo.detail.cateCdText}{">"}
            {
              caseManagement.serviceNm
            }
          </p>
        </li>
        <li>
          <p className="gray">지원시간</p>
          <p>
            {
              caseManagement.detailInfo.servicePlanYearVO.cycleUnit ?
              caseManagement.detailInfo.servicePlanYearVO.cycleUnit
              : ""
            } {
              caseManagement.detailInfo.servicePlanYearVO.totalCount ?
              caseManagement.detailInfo.servicePlanYearVO.totalCount + "회 "
              : ""
            }{
              caseManagement.detailInfo.servicePlanYearVO.weekday ? "/ " +
              caseManagement.detailInfo.servicePlanYearVO.weekday + " / "
                : ""
            }{
              caseManagement.detailInfo.servicePlanYearVO.times ? 
              caseManagement.detailInfo.servicePlanYearVO.times + "시간"
              : ""
            }
          </p>
        </li>
        <li>
          <p className="gray">지원기간</p>
          <p>
            {
              moment(caseManagement.detailInfo.servicePlanYearVO.beginDate)?.format("yyyy.MM.DD")
            } ~ {
              moment(caseManagement.detailInfo.servicePlanYearVO.endDate)?.format("yyyy.MM.DD")
            }
          </p>
        </li>
        <li>
          <p className="gray">필요도 평가일</p>
          <p className="underline" onClick={() => handleNADialog(caseManagement.detailInfo.detail.counselResultVO.csUid, moment(caseManagement.detailInfo.detail.counselDate).format("yyyy.MM.DD(dd)"))}>
            {moment(caseManagement.detailInfo.detail.counselDate).format("yyyy.MM.DD(dd)")}
          </p>
        </li>
      </>
      }
      
    </ul>
  )
}

/**
   * 테이블
   * @param list 사례관리 목록
   */
const TableAgency = ({ list, page, totalCount }: { list:GuBillModel[], page: number, totalCount: number }) => {
  const navigate = useNavigate();
  const handlePageChange = (page: number) => {
    navigate(`/expense/${page}`);
  };
  return (
    <PaymentDataTable
      columnTitles={[
        "제공기관",
        "담당자",
        "연락처",
        "대상자",
        "행정동",
        "서비스구분",
        "지급신청일",
        "지급예정일",
        "상태",
        "청구금액",
      ]}
      rows={list.map((caseManagement) =>
        createPaymentRow({
          cells: [
            createPaymentTextCell({ text: caseManagement.departName }),
            createPaymentTextCell({ text: caseManagement.userName }),
            createPaymentTextCell({ text: caseManagement.telno }),
            createPaymentTextCell({ text: caseManagement.trgterNm }),
            createPaymentTextCell({ text: caseManagement.hdong }),
            createPaymentTextCell({ text: `${caseManagement.serviceGbn}${caseManagement.serviceNm ? ">" : ""}${caseManagement.serviceNm ?? ""}` }),
            createPaymentTextCell({
              text: caseManagement.billDate,
            }),
            createPaymentTextCell({
              text: `${
                caseManagement.billStatus === "지급완료"
                  ? caseManagement.payDate
                  : "-"
              }`,
            }),
            createPaymentTextCell({ text: caseManagement.billStatus }),
            createPaymentTextCell({
              text: caseManagement.totalCost.toLocaleString("ko-KR") + " 원",
            }),
          ],
          spyUid: caseManagement.spyUid,
          sbUid: caseManagement.sbUid,
          status: `${caseManagement.billStatus}`,
        })
      )}
      page={page}
      totalPageCount={Math.ceil(totalCount / 10)}
      onPageChange={handlePageChange}
    />
  );
};



export default ExpensePage;
